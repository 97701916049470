import React,{Component} from 'react'
import Routes from '../src/routes'
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
class App extends Component{
render(){
  return(
    <Routes/>  
  )
}

}

export default App