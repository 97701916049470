import React, {Component} from 'react';
import './index.css'
import { Layout,Card,Row,Rate,Button,Alert,Result} from 'antd';
import ADS from '../imagen/ADS.png'
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import swal from 'sweetalert'

const { Header} = Layout;

class LayoutSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            value:'',
            cuestion1:'', 
            cuestion2:'', 
            cuestion3:'', 
            cuestion4:'',    
            cuestion5:'', 
            cuestion6:'', 
            cuestion7:'', 
            encuestaAplicada:''
         }
         this.onChange=this.onChange.bind(this)
    }
    
    componentWillMount =  async()=>{
        let str = window.location.href
        var mySubString = await str.substring(
            str.indexOf("&") + 1, 
        );
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                    query{
                    getSupportById(data:"${[mySubString]}"){  
                        id_soporte
                        id_cliente
                        fechaSoporte
                        consola
                        numeroPoliza
                        asunto
                        idTeamviewer
                        passTeamviewer
                        folio
                        status
                        fk_contacto
                        ejecutivo
                        rfc
                        razonSocial
                        fechaFinalizacion
                        statusEncuesta
                        } 
                    }
                `
            }
            }).then((response) => {  
                let data = response.data.data.getSupportById;
                console.log("Dat6a",data)
                if(data[0].statusEncuesta === "No aplicada"){
                    this.setState({data:data})
                    this.setState({encuestaAplicada:"false"})
                }if(data[0].statusEncuesta === "Aplicada"){
                    this.setState({encuestaAplicada:"true"})
                }if(data[0].statusEncuesta === null){
                    this.setState({encuestaAplicada:"sin datos"})
                }
            }).catch(err=>{
                console.log("error",err.response)
                console.log("error",err)
            })
    }

    onChange = async(e,param)=>{
        if(param === 5){
            this.setState({cuestion1: e})
        }else if(param === 15){
            this.setState({cuestion2: e})
        }else if(param === 25){
            this.setState({cuestion3: e})
        }else if(param === 35){
            this.setState({cuestion4: e})
        }else if(param === 45){
            this.setState({cuestion5: e})
        }else if(param === 55){
            this.setState({cuestion6: e})
        }else if(param === 65){
            this.setState({cuestion7: e})
        }
    }

    evaluar(){
            let cuestion1 = this.state.cuestion1
            let cuestion2 = this.state.cuestion2
            let cuestion3 = this.state.cuestion3
            let cuestion4 = this.state.cuestion4
            let cuestion5 = this.state.cuestion5
            let cuestion6 = this.state.cuestion6
            let cuestion7 = this.state.cuestion7
            let año  = new Date().getFullYear()
            function generateUUID() {
                var d = new Date().getTime();
                var uuid = 'Axyxxyx'.replace(/[xy]/g, function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            }
            let folio = (año + generateUUID()).toUpperCase();
            let data = this.state.data
            var currentdate = new Date(); 
            var datetime =  currentdate.getDate() + "/"
                            + (currentdate.getMonth()+1)  + "/" 
                            + currentdate.getFullYear() + " "+ 
                            + currentdate.getHours() + ":"  
                            + currentdate.getMinutes() + ":" 
                            + currentdate.getSeconds();
            if(cuestion1 && cuestion2 && cuestion3 && cuestion4 && cuestion5 && cuestion6 && cuestion7 ){
            if(cuestion1 !== 0 && cuestion2 !== 0 && cuestion3 !== 0 && cuestion4 !== 0 && cuestion5 !== 0 && cuestion6 !== 0 && cuestion7 !== 0){
                axios({
                    url:API,
                    method:'post',
                    data:{
                        query:`
                        mutation{
                          insertSurveyQuality(data:"${[cuestion1,cuestion2,cuestion3,cuestion4,cuestion5,cuestion6,cuestion7, folio, data[0].id_soporte,data[0].id_cliente,datetime]}"){
                            message                             
                           } 
                        }
                        `
                    }   
                     }).then(response=>{
                        let message = response.data.data.insertSurveyQuality
                        if(message.message === "encuesta realizada"){
                            swal({
                                text:"Encuesta enviada correctamente, gracias por su colaboración.",
                                buttons: false,
                                icon:'success'
                            }); 
                            window.location.reload();
                        }else{
                            swal({
                                text:"Error al enviar la encuesta, por favor inténtelo nuevamente.",
                                buttons: false,
                                icon:'error'
                            });
                        }
                      })
                     .catch(err=>{
                         console.log('error',err)
                         console.log('error',err.response)
                     }) 
            }else{
                swal({
                    text:"No puede enviar el cuestionario sin antes asignar calificación válida a todas las preguntas",
                    buttons: false,
                    icon:'warning'
                });  
            }
            
                 
        }else{
            swal({
                text:"Las preguntas deben estar evaluadas correctamente",
                buttons: false,
                icon:'warning'
            }); 
        }
    }
    redirect(){
        this.props.history.push("/")

    }
    render() { 
        const desc1 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];
        const desc2 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];
        const desc3 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];
        const desc4 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];
        const desc5 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];
        const desc6 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];
        const desc7 = ['Pesimo', 'Malo', 'normal', 'Bueno', 'Excelente'];


        let card;
        if(this.state.data[0] && this.state.encuestaAplicada === "false"){
            let cuestion1 = this.state.cuestion1
            let cuestion2 = this.state.cuestion2
            let cuestion3 = this.state.cuestion3
            let cuestion4 = this.state.cuestion4
            let cuestion5 = this.state.cuestion5
            let cuestion6 = this.state.cuestion6
            let cuestion7 = this.state.cuestion7
    
            let alerta1;
            let alerta2;
            let alerta3;
            let alerta4;
            let alerta5;
            let alerta6;
            let alerta7;
    
            if(cuestion1){
                if(cuestion1 !== 0){
                    alerta1 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta1 = <Alert message="No evaluado" type="warning" showIcon/>
            }if(cuestion2){
                if(cuestion2 !== 0){
                    alerta2 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta2 = <Alert message="No evaluado" type="warning" showIcon/>
            }if(cuestion3){
                if(cuestion3 !== 0){
                    alerta3 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta3 = <Alert message="No evaluado" type="warning" showIcon/>
            }if(cuestion4){
                if(cuestion4 !== 0){
                    alerta4 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta4 = <Alert message="No evaluado" type="warning" showIcon/>
            }if(cuestion5){
                if(cuestion5 !== 0){
                    alerta5 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta5 = <Alert message="No evaluado" type="warning" showIcon/>
            }if(cuestion6){
                if(cuestion6 !== 0){
                    alerta6 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta6 = <Alert message="No evaluado" type="warning" showIcon/>
            }if(cuestion7){
                if(cuestion7 !== 0){
                    alerta7 = <Alert message="Evaluado" type="success" showIcon />
                }
            }else{
                alerta7 = <Alert message="No evaluado" type="warning" showIcon/>
            }
            card =
            <Card style={{width:"80%",marginTop:"2%",marginLeft:"10%"}} type="inner" title={<div>Encuesta de calidad para &nbsp;<strong>{this.state.data[0].ejecutivo}</strong> </div>}>
                <Row>
                <div style={{width:"30%", marginTop:"2%"}}>
                <p><strong>Empresa: {this.state.data[0].razonSocial}</strong></p>
                <p><strong>RFC: {this.state.data[0].rfc}</strong></p>
                <p><strong>Consola: {this.state.data[0].consola}</strong></p>
                <p><strong>Asunto: {this.state.data[0].asunto}</strong></p>
                <p><strong>Fecha de solicitud: {this.state.data[0].fechaSoporte}</strong></p>
                <p><strong>Fecha de finalización: {this.state.data[0].fechaFinalizacion}</strong></p>

                </div>
                <div style={{width:"70%"}}>
                <Card title = "Calidad del servicio" type='inner'>
                 
                <strong>Llene las estrellas de acuerdo a su experiencia en el servicio que le brindó el ejecutivo de soporte.</strong> &nbsp;&nbsp;&nbsp;
                <div><br/>
                <strong>1.- ¿Qué tan satisfecho está usted con la calidad general del servicio que recibió?<br/></strong>
                <span>
                    <Rate tooltips={desc1}onChange={e=>this.onChange(e,5)} defaultValue={this.state.value || 1}   /> &nbsp; <br/>{alerta1}<br/>
                </span>
                </div>    
                <div>
                <strong>2.- ¿Se considera resuelto el problema?</strong><br/>
                <span>
                    <Rate tooltips={desc2} onChange={e=>this.onChange(e,15)} defaultValue={this.state.value || 2}   /> &nbsp; <br/>{alerta2}<br/>
                </span>
                </div>
                <div>
                <strong>3.- ¿Cómo valora usted el ejecutivo asignado a su empresa?</strong><br/>
                <span>
                    <Rate tooltips={desc3} onChange={e=>this.onChange(e,25)} defaultValue={this.state.value || 3}   /> &nbsp; <br/>{alerta3}<br/>
                </span>
                </div>
                <div>
                <strong>4.- ¿Que tan satisfecho del tiempo de respuesta desde el inicio hasta el final de la instalación?</strong><br/>
                <span>
                    <Rate tooltips={desc4} onChange={e=>this.onChange(e,35)} defaultValue={this.state.value || 4}   /> &nbsp; <br/>{alerta4}<br/>
                </span>
                </div>
                <div>
                <strong>5.- ¿Qué tan satisfecho quedó con esta experiencia de soporte en línea?</strong><br/>
                <span>
                    <Rate tooltips={desc5} onChange={e=>this.onChange(e,45)} defaultValue={this.state.value || 5}   /> &nbsp; <br/>{alerta5}<br/>
                </span>
                </div>
                <div>
                <strong>6.- Valore el tiempo de respuesta desde la solicitud de servicio hasta la llegada del ejecutivo</strong><br/>
                <span>
                    <Rate tooltips={desc6} onChange={e=>this.onChange(e,55)} defaultValue={this.state.value || 1}   /> &nbsp; <br/>{alerta6}<br/>
                </span>
                </div>
                <div>
                <strong>7.- ¿Volveria a solicitar nuestro servicio de soporte con el ejecutivo asignado?</strong><br/>
                <span>
                    <Rate tooltips={desc7} onChange={e=>this.onChange(e,65)} defaultValue={this.state.value || 2}   /> &nbsp; <br/>{alerta7}<br/>
                </span>
                </div>
                <div>                 
                </div>
                <Button type="primary" onClick={e=>this.evaluar()}>Enviar encuesta</Button>
                </Card>    
                </div>
                </Row>
            </Card>
        }else if(this.state.encuestaAplicada === "true"){
            card =  <Result
            status="success"
            title="Encuesta realizada satisfactoriamente"
            subTitle="Gracias por su colaboración, esto nos ayudará a brindarle un mejor servicio"
            extra={[
              <Button type="primary" onClick={e=>this.redirect()}>
                Ir a plataforma adsContigo
              </Button>
            ]}
          />
        }else if(this.state.encuestaAplicada === "sin datos"){
                card =  <Result
                status="error"
                title="No se pudo acceder a la encuesta"
                subTitle="Por favor haganos saber el error en su proceso."
                extra={[
                <Button type="primary" onClick={e=>this.redirect()}>
                    Ir a plataforma adsContigo
                </Button>
                ]}
            />
            
        }
        return ( 
            <div>
              <Layout>     
                    <Layout className="site-layout" >       
                        <Header className="site-layout-background11" style={{ padding: 0 , color:"#fff"}}>   
                            <font color="#fff" size="4"><img src={ADS} style={{width:"10%", marginLeft:"4%"}}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Encuesta de calidad  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Evaluación al servicio de soporte técnico</font>           
                        </Header>
                            {card}
                    </Layout>
                </Layout>
               
            </div>
         );
    }
}
 
export default LayoutSurvey;