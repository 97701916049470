import React,{Component} from 'react'
import MUIDataTable from "mui-datatables";
import {MDBBtn,MDBIcon} from 'mdbreact'
import {API} from '../Graphql/Graphql'
import axios from 'axios'
import { Button,Modal,message, Card} from 'antd';
import 'antd/dist/antd.css';
import './index.css'
import Report from './ReportVentas'

const PageTemplate = (props) => {
  return (
    <div style={{position:"absolute",bottom:"10px"}}>
    <font  className= "piePagina text-center" color="black" style = {{marginBottom:25}}>Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México  Información, soporte y ventas:
    Conmutador:55 3603 9970 - 55 5553 2049</font>
    </div>
  );
};

class TablaVentas extends Component{
  pdfExportComponent
  constructor(props){
    super(props)
    this.state = {
          datos:[],
          modal: false,
          detallesCotizaciones:[],
          detallesIdCotizaciones:[],
          modal12: false,
          tablaInicial:true,
          tablaCotizaciones:false,
          botonPdfExport:false,
          cotizacionesTotales:[],
          cotizacionesPorCliente:[],
          peticionCotizaciones:[],
          isModalVisible:false,
          clienteCotizacion:[],
          productoServicioByFolio:[],
          totales:[],
          statusCotizacion:'',
          isModalVisible2:false,
          folioAEditar:'',
          statusActual:'',
          dataContacto:[],
          fechaExpiracion:'',
          report:false,
          dataPdf:[]
        }
    } 
    toggle12 = () => {
      this.setState({
        modal12:!this.state.modal12
      })
    }
   

   async componentWillMount(){ 
          const fk_adminalfa  = localStorage.getItem("id_admin");  
          let array = []
       await axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                    getVentasTabla(data:"${[fk_adminalfa]}"){
                        id_venta
                        numFolio
                        cantidad
                        descuento
                        descuentoAplicado
                        TotalPrecioProducto
                        ProductoPrecioUnitario
                        TotalPrecioProductoIVA
                        fechaPago
                        hora
                        banco
                        referenciaPago
                        tipoPago
                        importe
                        fechaEmisionVenta
                        statusPoliza
                        fk_productoServicio
                        fk_cliente
                        fk_adminalfa
                        fk_empresa
                        fk_contacto
                        rfc
                        razonSocial
                        tamanoEmpresa
                        giroEmpresarial
                        paginaWeb
                        domicilioFiscal
                        message
                   } 
                }
                `
            }   
             }).then(response=>{
                let cotizaciones = response.data.data.getVentasTabla
               this.setState({peticionCotizaciones:cotizaciones}) 
               function getUniqueListBy(arr, key) {
                return [...new Map(arr.map(item => [item[key], item])).values()]
               }
                const arr1 = getUniqueListBy(cotizaciones, 'numFolio')
                this.setState({cotizacionesTotales:arr1})
               
          ////////////////////////////////////////////////////
                response.data.data.getVentasTabla.map(rows=>{
                  array.push(rows.fk_cliente)
                })
                function onlyUnique(value, index, self) { 
                  return self.indexOf(value) === index;
                }
              
              // ejemplo de uso:
              var unique = array.filter( onlyUnique ); 
              let arrayClientes = []
              unique.map(rows=>{

             axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                    getClienteId(data:"${[rows]}"){
                      id_cliente
                      razonSocial
                      rfc
                     } 
                    }
                    `
                }   
                }).then(response=>{
                  arrayClientes.push(response.data.data.getClienteId[0])
                  this.setState({detallesCotizaciones:arrayClientes})
                })
                .catch(err=>{
                    console.log('error',err)
                })
              })
               
             })
             .catch(err=>{
                 console.log('error',err)
             })
    }

    datosIndividuales(id,num){ 
       axios({
        url:API,
        method:'post',
        data:{
            query:`
            query{
               getIdVenta(data:"${[id]}"){   
                id_venta
                    numFolio
                    cantidad
                    descuento
                    descuentoAplicado
                    TotalPrecioProducto
                    ProductoPrecioUnitario
                    TotalPrecioProductoIVA
                    fechaPago
                    hora
                    banco
                    referenciaPago
                    tipoPago
                    importe
                    statusPoliza
                    fk_productoServicio
                    fk_cliente
                    fk_adminalfa
                    fk_empresa
                    fk_contacto
                    rfc
                    razonSocial
                    tamanoEmpresa
                    giroEmpresarial
                    paginaWeb
                    domicilioFiscal
                    fechaEmisionVenta
                    message
              } 
            }
            `
        }   
         })
       .then(response=>{
           
         let cotizaciones = response.data.data.getIdVenta
         localStorage.setItem("rfc",cotizaciones[0].rfc)
         function getUniqueListBy(arr, key) {
          return [...new Map(arr.map(item => [item[key], item])).values()]
         }
          const arr1 = getUniqueListBy(cotizaciones, 'numFolio')
          this.setState({cotizacionesPorCliente:arr1})

          if(num===1){
            this.showModal();
          }
    })
     .catch(err=>{
              console.log('error',err.response)
      }) 
      let filter = this.state.detallesCotizaciones.filter(function(hero){  
        return hero.id_cliente === id
      })
      this.setState({clienteCotizacion:filter})
    }

 
  mostratTablaCotizaciones(){
    this.setState({tablaInicial:false})
    this.setState({tablaCotizaciones:true})
  }
  cerrarTablaCotizaciones(){
    this.setState({tablaInicial:true})
    this.setState({tablaCotizaciones:false})
  }
   showModal = () => {
     this.setState({IsModalVisible:true})
  };

   handleOk = () => {
    this.setState({IsModalVisible:false})
  };

  handleCancel = () => {
    this.setState({IsModalVisible:false})
  };
  showModal2 = () => {
    this.setState({IsModalVisible2:true})
 };


 handleCancel2 = () => {
   this.setState({IsModalVisible2:false})
 };

  pdfView = async(folios) => {
    let folio = folios.numFolio
    let id_contacto = folios.fk_contacto; 
    this.handleCancel();
    let filter = this.state.peticionCotizaciones.filter(function(hero){
      return hero.numFolio === folio      
    })  

    this.setState({dataPdf:filter}) 

  axios({
      url:API,
      method:'post',
      data:{
          query:`
          query{
            getProductoServicioByFolioVentas(data:"${[folio]}"){   
              id_productoServicio
              tipo
              concepto
              precio 
              consecutivo
              tipoLicenciamiento
              lineaProducto
              fk_empresa
              id_venta
              numFolio
              cantidad
              descuento
              descuentoAplicado
              TotalPrecioProducto
              ProductoPrecioUnitario
              TotalPrecioProductoIVA
              fechaPago
              hora
              banco
              referenciaPago
              tipoPago
              importe              
              statusPoliza
              fk_productoServicio
              fk_cliente
              fk_adminalfa              
              fk_contacto  
              fechaEmisionVenta
   
   
    
            } 
          }
          `
      }   
       })
     .then(response=>{
      this.setState({productoServicioByFolio:response.data.data.getProductoServicioByFolioVentas})
     })
   .catch(err=>{
    console.log('error',err)
    }) 
    
   axios({
      url:API,
      method:'post',
      data:{
          query:`
          query{
            getTotalesByFolioVenta(data:"${[folio]}"){   
                id_totalVenta
                subTotal
                IVA
                total
                numFolioVenta
                message
            } 
          }
          `
      }   
       })
     .then(response=>{
      let totales =  response.data.data.getTotalesByFolioVenta
      function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
       }
        const array1 = getUniqueListBy(totales, 'numFolio')
        this.setState({totales:array1[0]})
     })
   .catch(err=>{
      console.log('error',err.response)
    }) 
    
    await axios({
      url:API,
      method:'post',
      data:{
          query:`
          query{
            getCotizacionFk_Contactos(data:"${[id_contacto]}"){   
              id_contacto
              nombre
              apellidos
              correo1
              correo2
              telefono1
              extensionTelefonica
              telefono2
              puesto
              fk_clientesads
            } 
          }
          `
      }   
       })
     .then(response=>{
      this.setState({dataContacto:response.data.data.getCotizacionFk_Contactos})
     })
   .catch(err=>{
    console.log('error',err)
    }) 
    this.setState({tablaInicial:false})
    this.setState({tablaCotizaciones:false})
    this.setState({report:true})
  }

  consultarCotizaciones (numFolio)  {
    let filter = this.state.peticionCotizaciones.filter(function(hero){
      return hero.numFolio === numFolio
    })
    
    this.datosIndividuales(filter[0].fk_cliente,2)
    if(this.state.cotizacionesPorCliente[0]){
        
      let filtrado = this.state.cotizacionesPorCliente.filter(function(data){
        return data.numFolio === numFolio
      })  
    
      let objeto = {};
      objeto.numFolio = numFolio
      objeto.fk_contacto = filter[0].fk_contacto
      this.pdfView(objeto)
    }
    
  }
 
    handleButtonClick(e) {
    message.info('Click on left button.');
    }  
   
    render(){
    
      const options={ 
        print:false,
        download:false,
        filterType:"drowpdawn",
        responsive: "stacked",
        elevation:0,
        selectableRows:"none",
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      }
    };
    let nombre_admin = localStorage.getItem("nombre");
    let apellidos_admin = localStorage.getItem("apellido"); 
    let puesto_admin = localStorage.getItem("puesto");
    let correo_admin = localStorage.getItem("correo");
    let telefono_admin = localStorage.getItem("telefono");
    let ext = localStorage.getItem("extensionTelefonica"); 

    let report;
    if(this.state.report === true){      
      let array = [];
      array.push({"nombre":nombre_admin,"apellido":apellidos_admin,"puesto":puesto_admin,"correo":correo_admin,"telefono":telefono_admin,"extensionTelefonica":ext})
     report = <Report adminAlfa = {array[0]} dataContacto = {this.state.dataContacto[0]} datosCliente = {this.state.clienteCotizacion[0]} totales = {this.state.totales} botonesAdmin={false} productoServicioByFolio = {this.state.productoServicioByFolio} cotizaciones = {this.state.dataPdf}/>
    }

    let modal;
    if(this.state.cotizacionesPorCliente[0]){

      modal = <Modal okText="Aceptar"  cancelText="Cancelar" width={850} title="Historial de Ventas Realizadas" visible={this.state.IsModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
     <table className="table table-borderless table table-small table table-stripe table table-responsive text-bold">
            <tr>
            <th><strong>ID</strong></th>
            <th><strong>Núm. de Orden</strong></th>
            <th><strong>Núm. de Referencia</strong></th>
            <th><strong>Fecha de Pago</strong></th>
            <th><strong>Importe</strong></th>
            </tr>
      {this.state.cotizacionesPorCliente.map(rows=>{
        let importeDosCeros;
        let importe;
         let fechaPago=rows.fechaPago       
         const fechaDeposito = new Date(fechaPago); 
         var letrasMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
         let diaDeposito = fechaDeposito.getDate()
         let letraMesSubstring = fechaDeposito.getMonth() +1
         let numeroPosicionMes = parseInt(letraMesSubstring, 10)
         let mesDeposito  = letrasMeses[numeroPosicionMes - 1] 
         let añoDeposito = fechaDeposito.getFullYear()  
        let boton = <MDBBtn size = "sm" color="primary" onClick={e=> this.pdfView(rows)}>Ver</MDBBtn>
      

        importeDosCeros = rows.importe.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        importe = importeDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          return(
            <tr>
            <td>{rows.id_venta}</td>
            <td>{rows.numFolio}</td>
            <td>{rows.referenciaPago}</td>      
            <td>{diaDeposito+"/"+mesDeposito+"/"+añoDeposito}</td>      
            <td>$&nbsp;{importe}</td>
            <td>{boton}</td>            
            </tr>
          ) 
        })}
      </table>

    </Modal>  
    }
    // ******************************************
      let tablaCotizaciones;
      if(this.state.tablaCotizaciones === true){
      const columnsCotizaciones= ["Id","Núm. referencia","fecha de pago","Importe","Visualizar"];
      let boton;
      let dataCotizaciones;
      if(this.state.cotizacionesTotales[0]){
        dataCotizaciones = this.state.cotizacionesTotales.map(rows=>{  
        let fechaPago=rows.fechaPago       
        const fechaDeposito = new Date(fechaPago); 
        var letrasMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
        let diaDeposito = fechaDeposito.getDate()
        let letraMesSubstring = fechaDeposito.getMonth() +1
        let numeroPosicionMes = parseInt(letraMesSubstring, 10)
        let mesDeposito  = letrasMeses[numeroPosicionMes - 1] 
        let añoDeposito = fechaDeposito.getFullYear()  
        //   let botonCotizacion = <MDBBtn className = "text-white" color="warning" size="md" onClick={e=>this.editarStatus(rows.numFolio}>Editar</MDBBtn>
              boton = <Button type="primary" shape="circle" size="large" onClick={e=> this.consultarCotizaciones(rows.numFolio)}><MDBIcon far icon="file-pdf" /></Button>
                    return([rows.id_venta,rows.numFolio,diaDeposito+"/"+mesDeposito+"/"+añoDeposito,rows.importe,boton])
              })
        }
        let tituloListado = <h6><strong>Listado de ventas Cerradas </strong></h6>
        let botonListado = <Button type="danger" onClick={e=>this.cerrarTablaCotizaciones()}>Cancelar</Button>

        tablaCotizaciones=<div>
        <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >
          <Card title = {tituloListado} extra = {botonListado}>               
          <MUIDataTable  
            data={dataCotizaciones} 
            columns={columnsCotizaciones} 
            options={options} 
          />  
          </Card>
        </div>
    </div>
      }
      // ****************************

      let tablaClientes;
      let pdf;
      if(this.state.tablaInicial===true){
      const columnsClientes= ["id","Empresa","RFC","Ver"];
      let boton;
      let dataClientes;
      // if(this.state.detallesCotizaciones[0]){
      dataClientes = this.state.detallesCotizaciones.map(rows=>{
      boton = <Button type="primary" shape="circle" size="large" onClick={e=> this.datosIndividuales(rows.id_cliente,1)}><MDBIcon far icon="file-pdf" /></Button>
               return([rows.id_cliente,rows.razonSocial,rows.rfc,boton])
       } )
    //  }
    let titulo = <h6><strong>Historial de Ventas por Clientes</strong></h6>
    let botonCotizaciones = <Button  type="success" onClick={e=>this.mostratTablaCotizaciones()}>Ver por Historial de Ventas</Button>

     tablaClientes=<div>
    <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >   
  
    <Card title = {titulo} extra = {<div>{botonCotizaciones}</div>}>            
      <MUIDataTable  
        data={dataClientes} 
        columns={columnsClientes} 
        options={options} 
      />    
    </Card>  
    </div>
</div>
      }
        return(
            <React.Fragment>            
              {tablaClientes} 
              {report}
              {tablaCotizaciones}
              {pdf}        
              {modal}
              
        </React.Fragment>
        )
    }
} export default TablaVentas