import axios from "axios";
import React, { Component } from "react";
import { Form } from "reactstrap";
import "./index.css";
import { API } from "../Graphql/Graphql";
import { Modal, Button as Boton, Input } from "antd";
import swal from "sweetalert";
import image from '../imagen/ADS.png'
import uno from '../imagen/1.jpg'


class loginAdminAlfa extends Component {
    constructor(props) {
        super(props);
        this.state = {
        user: "", 
        pass: "",
        isModalVisible: false,
        };
    }
    componentWillMount() {
        localStorage.removeItem("id_admin");
        localStorage.removeItem("nombre");
        localStorage.removeItem("apellido");
        localStorage.removeItem("correo");
        localStorage.removeItem("fk_empresa");
        localStorage.removeItem("TokenAdministradorAlfa");
        localStorage.removeItem("extencionTelefonica");
        localStorage.removeItem("puesto");
        localStorage.removeItem("rolAdministrador");
    }

    onChangeInput = (e) => {
        const { id, value } = e.target;
        this.setState({
        [id]: value,
        });
    };

    onSubmitBtn = (e) => {
        e.preventDefault();
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                    query{
                        loginAdminAlfa(data:"${[
                        this.state.user,
                        this.state.pass,
                        ]}"){
                        message
                        id_admin
                        nombre  
                        apellido   
                        correo  
                        telefono
                        extensionTelefonica 
                        razonSocial                      
                        puesto                                      
                        token 
                        fk_empresa       
                        fk_rolAdministrador                                  
                    } 
                    }
                    `,
        },
        })
        .then((response) => {
            let mensaje = response.data.data.loginAdminAlfa.message;
            if (mensaje === "login exitoso") {
            localStorage.setItem(
                "id_admin",
                response.data.data.loginAdminAlfa.id_admin
            );
            localStorage.setItem(
                "nombre",
                response.data.data.loginAdminAlfa.nombre
            );
            localStorage.setItem(
                "apellido",
                response.data.data.loginAdminAlfa.apellido
            );
            localStorage.setItem(
                "correo",
                response.data.data.loginAdminAlfa.correo
            );
            localStorage.setItem(
                "telefono",
                response.data.data.loginAdminAlfa.telefono
            );
            localStorage.setItem(
                "extensionTelefonica",
                response.data.data.loginAdminAlfa.extensionTelefonica
            );
            localStorage.setItem(
                "puesto",
                response.data.data.loginAdminAlfa.puesto
            );
            localStorage.setItem(
                "fk_empresa",
                response.data.data.loginAdminAlfa.fk_empresa
            );
            localStorage.setItem(
                "TokenAdministradorAlfa",
                response.data.data.loginAdminAlfa.token
            );
            localStorage.setItem(
                "razonSocialEmpresa",
                response.data.data.loginAdminAlfa.razonSocial
            );
            localStorage.setItem(
                "rolAdministrador",
                response.data.data.loginAdminAlfa.fk_rolAdministrador
            );
            this.props.history.push("/sidenavbar");
            swal({
                title: "Bienvenido!",
                text: "Inicio de sesión exitoso",
                buttons: false,
            });
            } else if (
            response.data.data.loginAdminAlfa.message ===
            "usuario y contraseña incorrecto"
            ) {
            swal({
                text: "Email o Contraseña incorrecto",
                icon: "error",
                buttons: false,
            });
            } else {
            swal({
                text: "Algo salio mal, por favor vuelva a intentarlo",
                icon: "warning",
                buttons: false,
            });
            }
        })
        .catch((err) => {
            console.log("error", err);
        });
        this.setState({ user: "" });
        this.setState({ pass: "" });
    };
    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };

    render() {
        let modal;
        if (this.state.isModalVisible === true) {
        modal = (
        <Modal
            title="Aviso!"
            visible={this.state.isModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            >
            <h6>Inicio de sesión exitoso</h6>
        </Modal>
        );
        }
        return (
        <div id="apppages">
        {modal}
        <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div class="bg-blue py-4" >
            <div class="row px-3">
                <small class="ml-4 ml-sm-5 mb-1"><img src={image} style={{width:120}}></img></small>
            </div>
        </div>
            <div class="card card0 border-2">
                <div class="row d-flex">
                    <div class="col-lg-6">
                        <div class="card2 card border-0 px-4 py-5">
                            <div class="row mb-4 px-3">
                                <h6 class="mb-0 mr-4 mt-2 text-center">Bienvenido a Plataforma ADSContigo</h6>
                            </div>
                            <div class="row px-3 mb-4">
                                <div class="line"></div>
                                <small class="or text-center">Ingresar</small>
                                <div class="line"></div>
                            </div>
                            <Form onSubmit={this.onSubmitBtn}>
                            <div class="row px-3">
                                <label class="mb-1"><h6 class="mb-0 text-sm">Correo electrónico</h6></label>
                                <input class="mb-4"  
                                id="user"
                                type="email"
                                name="user"
                                placeholder="Correo"
                                onChange={this.onChangeInput}
                                value={this.state.user}/>
                            </div>
                            <div class="row px-3">
                                <label class="mb-1"><h6 class="mb-0 text-sm">Contraseña</h6></label>
                                <input  type="password"
                                        placeholder="Contraseña"
                                        id="pass"
                                        name="contrasena"
                                        onChange={this.onChangeInput}
                                        value={this.state.pass}/>
                            </div>
                            <div class="row px-3 mb-4">

                                <a href="https://plataforma.adscontigo.com" class="ml-auto mb-0 text-sm">Sistema de clientes</a>
                            </div>
                            <div class="row mb-3 px-3">
                                <button type="submit" class="btn btn-blue text-center">Iniciar sesión</button>
                            </div>
                            </Form>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card1">
                            <div class="row px-3 justify-content-end mt-0 mb-0 ">
                                <img src={uno} class="image" style={{width:"59%",height:"45%"}}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        
        </div>
    );
  }
}
export default loginAdminAlfa;
