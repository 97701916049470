import React, { Component } from 'react';
import {Card, Input} from 'antd';
import image1 from '../imagen/ADS.png';
const { TextArea } = Input;

class configuraCorreo extends Component {
    constructor(props) {
        super(props);
        this.state = { 

        }

    }
    onChange1(e){
        console.log("e",e.target.value)
    }
    render() { 
        return ( 
            <div>
                <center>
                    <Card style={{marginTop:"2%", width:"90%"}} type="inner" title={<div><h6>Configuración de correo del sistema</h6></div>}>
                        <div>
                            <div className='correoSalida'>
                                    <div style={{width:"50%"}}>
                                        <Card type="inner">
                                            <strong>Encabezado1</strong>    
                                            <TextArea
                                            showCount
                                            maxLength={100}
                                            style={{ height: 120, marginBottom: 24 }}
                                            onChange={this.onChange1}
                                            placeholder={this.state.encabezado1 || "Maximo 300 caracteres"}
                                            />
                                        </Card>
                                    </div>
                                    <div style={{width:"50%",marginLeft:"2%"}}>
                                        <Card type="inner">
                                            <strong>Imagen 1</strong>
                                           {this.state.image || <div><img src = {image1} width="100px"></img><input disabled={this.state.disabledInput}  style={{width:"60%",marginTop:"2%"}} type="file"  accept="application/pdf" onChange={this.handleChange5} /></div>}
                                        </Card>
                                    </div>
                            </div>
                            <div style={{marginTop:"2%"}} className='correoSalida'>
                                    <div  style={{width:"50%"}}>
                                        <Card type="inner">
                                            <strong>Encabezado2</strong>    
                                            <TextArea
                                            showCount
                                            maxLength={100}
                                            style={{ height: 120, marginBottom: 24 }}
                                            onChange={this.onChange}
                                            placeholder={this.state.encabezado2 || "Encbezado 2"}
                                            />
                                        </Card>
                                    </div>
                                    <div style={{width:"50%",marginLeft:"2%"}}>
                                        <Card type="inner">
                                            <strong>Imagen 2</strong>
                                           {this.state.image2 || <div><img src = {image1} width="100px"></img><input disabled={this.state.disabledInput}  style={{width:"60%",marginTop:"2%"}} type="file"  accept="application/pdf" onChange={this.handleChange5} /></div>}
                                        </Card>
                                    </div>
                                    
                            </div>
                            <div style={{width:"70%",marginLeft:"2%",marginTop:"2%"}}>
                                        <strong>Firma</strong>    
                                        <Card type="inner">
                                           {this.state.image2 || <div><img src = {image1} width="100px"></img><input disabled={this.state.disabledInput}  style={{width:"60%",marginTop:"2%"}} type="file"  accept="application/pdf" onChange={this.handleChange5} /></div>}
                                        </Card>
                            </div>
                        </div>
                    </Card>
                </center>
            </div>

         );
    }
}
 
export default configuraCorreo;