import React,{Component} from 'react';
import {BrowserRouter as Router , Switch ,Route, Redirect} from 'react-router-dom'
import './App.css';
import checkTokenAdministradorAlfa  from '../src/components/resolvers/checkTokenAdministradorAlfa'
import checkTokenCliente from '../src/components/resolvers/checkTokenCliente'
import checkTokenUsersPlataform from '../src/components/resolvers/checkTokenUsersPlataform'
import checkTokenAdminPlataform from '../src/components/resolvers/checkTokenAdminPlataform'

import loginAdmin from './components/administradorAlfa/login'
import sidenavbar from './components/administradorAlfa/sidenavbar'
import tablaClientes from './components/administradorAlfa/tablaClientes'
import cliente from './components/administradorAlfa/signupClientes'
import Cotizaciones from './components/administradorAlfa/cotizaciones'
import tablaCotizaciones from './components/administradorAlfa/TablaCotizaciones'
import eventosEvenbrite from './components/administradorAlfa/eventosEvenbrite'
import comprasCarrito from './components/administradorAlfa/comprasCarrito'
import videosPrivados from './components/administradorAlfa/videos'
import RegistrarEmpresa from './components/empresas/signupEmpresa'
import LoginEmpresa from './components/empresas/loginEmpresa'
import registrarAdministrador from './components/administrador/registraAdminsitrador'
import loginAdministrador  from './components/administrador/loginadministrador'
import Dashboard from './components/paneldeConection/dashboard'
import LoginCliente from './components/panelClientes/loginCliente'
import ChangePassword from './components/panelClientes/changePAssword'
import DashboardClientes from './components/panelClientes/dashboardClientes'
import listaVideos from './components/panelClientes/viewVideosPrivados'
import index from './components/depuracionBasa/index'
import ejemplo from './components/administradorAlfa/prue/ejemplo1'
import ejemplo2 from './components/administradorAlfa/prue/ejemplo2'
import Agregar from './components/paneldeConection/botonAgregar'
import checkbox from './components/paneldeConection/check'
import Depuracion from './components/depuracion/depuracion'
import CalidadSurvey from './components/administradorAlfa/calidadSurvey'
import LoginCourses from './components/demo/loginCourses'
import Courses from './components/demo/courses'
import DashboardCourses from './components/demo/dashboardCurses'
import Plataform_Curses from './components/demo/signup_plataform_curses'
import Signup_Curse from './components/demo/signup_curse'
import Login_Plataform from './components/demo/login_plataform';
import Users_Plataform from './components/demo/users_plataform';
import NewCourses from './components/demo/newCourses'
import News from './components/demo/news/index'

class App extends Component{

render(){
  const PrivateRouteAdminAlfa = ({component:Component,...rest})=>(
    <Route {...rest  } render={(props) => checkTokenAdministradorAlfa() === true ? <Component {...props}/> : <Redirect to="/loginAdmin"/>}/>
      )
  const PrivateRouteCliente = ({component:Component,...rest})=>(
    <Route {...rest  } render={(props) => checkTokenCliente() === true ? <Component {...props}/> : <Redirect to="/"/>}/>
      )
      const PrivateRouteUsersPlataform = ({component:Component,...rest})=>(
        <Route {...rest  } render={(props) => checkTokenUsersPlataform() === true ? <Component {...props}/> : <Redirect to="/login_plataform"/>}/>
          )
          const PrivateRouteAdminPlataform = ({component:Component,...rest})=>(
            <Route {...rest  } render={(props) => checkTokenAdminPlataform() === true ? <Component {...props}/> : <Redirect to="/login_courses"/>}/>
              )
  return(
    <Router>
    <Switch>
      <main>
      <Route exact path = "/index" component={index}/>
      <Route exact path = "/ejemplo" component={ejemplo}/>
      <Route exact path = "/ejemplo2" component={ejemplo2}/>
      <Route  exact path = "/boton" component={Agregar}/>
      <Route  exact path = "/check" component={checkbox}/>
      <Route  exact path = "/loginAdmin" component={loginAdmin}/>        
      <PrivateRouteAdminAlfa exact path = "/sidenavbar" component={sidenavbar}/>
      <PrivateRouteAdminAlfa exact path = "/tablaClientes" component={tablaClientes}/>
      <PrivateRouteAdminAlfa exact path = "/cliente" component={cliente}/>
      <PrivateRouteAdminAlfa exact path = "/Cotizaciones" component={Cotizaciones}/>
      <PrivateRouteAdminAlfa exact path = "/TablaCotizaciones" component={tablaCotizaciones}/>
      <PrivateRouteAdminAlfa exact path = "/TablaEventos" component={eventosEvenbrite}/>
      <PrivateRouteAdminAlfa exact path = "/ComprasCarrito" component={comprasCarrito}/>  
      <PrivateRouteAdminAlfa exact path = "/videosPrivados" component={videosPrivados}/>
      <Route exact path = "/qualitySurvey:id" component={CalidadSurvey}>
      </Route>
      <Route  exact path = "/menu" component={Dashboard}/>  
      <Route exact path = "/loginEmpresa" component={LoginEmpresa}/>      
      <Route  exact path = "/RegistrarEmpresa" component={RegistrarEmpresa}/>
      <Route exact path = "/loginAdministrador" component={loginAdministrador}/>
      <Route exact path = "/registrarAdministrador" component={registrarAdministrador}/>
      <Route  exact path = "/" component={LoginCliente}/>
      <Route  exact path = "/passwordUpdate" component={ChangePassword}/>
      <PrivateRouteCliente  exact path = "/dashboardClientes" component={DashboardClientes}/>
      <Route path='/chatSupport' component={() => { 
            window.location.href = 'https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=0&onpage=livechatimagelink&title=Live+Chat+Direct+Link'; 
              return null;
          }}/>
      <Route exact path= "/listaVideos" component={listaVideos}/>
      <Route exact path= "/depuration" component={Depuracion}/>
      <Route exact path= "/login_courses" component={LoginCourses}/>
      {/* <Route exact path= "/courses" component={Courses}/> */}
      <Route exact path= "/signup_course" component={Signup_Curse}/>
      <PrivateRouteAdminPlataform exact path= "/dashboardCourses" component={DashboardCourses}/>
      <Route exact path= "/signup_plataform_curses" component={Plataform_Curses}/>
      <Route exact path= "/login_plataform" component={Login_Plataform}/>
      <PrivateRouteUsersPlataform exact path= "/users_plataform" component={Users_Plataform}/>
      <Route exact path= "/newCourses" component={NewCourses}/>
      <Route exact path= "/news" component={News}/>
      </main>  
    </Switch>          
    </Router>
  )
}
}
export default App