import React, { Component } from 'react'
import actionsAPI from './actionsAPI';
import MUIDataTable from "mui-datatables";
import{Card,Button,Layout,Tabs,Menu,Tag, Modal, Input, notification,Select,DatePicker} from 'antd'
import swal from 'sweetalert';
import {DesktopOutlined,FormOutlined} from '@ant-design/icons'
import options from '../administradorAlfa/tableMuiOptions';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,

} from "@ant-design/icons";
import App from './charts'
import VideoPromocional from './addVideoPromocional';
import { TimePicker } from 'antd';
import Users_Plataform from './users_plataform';
import Cursos_Anteriores from './cursos_anteriores';
import ads from '../imagen/logo_color_azul.png'
import ReactFirebaseFileUpload from './upload_image_courses';
import actions from '../administradorAlfa/transactionsAPI'
import Solicitudes_Cotizaciones_cliente from './solicitudes_cotizaciones_cliente';
import Requisitos from '../demo/componentes_dashboard/registro_requisitos'
import Vacante from '../demo/uploadpdf'
const { Header, Sider } = Layout;
class DashboardCourses extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataUser:[],
            data_courses:[],
            visible:false,
            tabla:false,
            tabla_register:true,
            disabled:true,
            disabled_modo:true,
            boton_editar:true,
            boton_aceptar:false,
            boton_editar_modo:true,
            boton_aceptar_modo:false,
            aref:false,
            input_indice:'',
            input_modo:'',
            tipo:'',
            modo:'',
            concepto:'',
            descripcion:'',
            url:'',
            encabezado1:'',
            encabezado2:'',
            encabezado3:'',
            indice:'',
            instructor:'',
            fb:'',
            ig:'',
            tw:'',
            li:'',
            yt:'',
            rs:'',
            modal_expositor:false,
            nombre_expositor:'',
            apellidos_expositor:'',
            correo_expositor:'',
            telefono_expositor:'',
            expositores:[],
            nuevo_expositor:'',
            disabled_instructor:true,
            modal_editar_expositor:false,
            id_curso_editar_expositor:'',
            metrincas:false,
            hora1:'',
            hora2:'',
            users_plataform:false,
            cuestion_image:true,
            url_image:false,
            image_upload:false,
            user_registered:[],
            detalle_empresa_cliente:[],
            costo:'',
            cotizaciones_cliente:false,
            requisitos:false,
            vacante:false

         }
         this.onOk = this.onOk.bind(this)
         this.handleChangeTipo = this.handleChangeTipo.bind(this) 
        this.handleChangeModo = this.handleChangeModo.bind(this)
        this.handleChangeExpositor = this.handleChangeExpositor.bind(this)
    }
    async componentWillMount(){
        localStorage.removeItem("url_image")
        await actionsAPI.get_expositor().then(response=>{
          this.setState({expositores:response.data.data.getExpositor})
        }).catch(err=>{
          console.log(err.response)
        })
        await actionsAPI.get_courses_register().then(response=>{
            this.setState({dataUser:response.data.data.get_courses_register})
        }).catch(err=>{
            console.log(err)
        })
        await actionsAPI.getCourses().then(response=>{
          this.setState({data_courses:response.data.data.getCourses})
          response.data.data.getCourses.map(rows=>{
            let indice = parseInt(rows.indice)
            let user_min = parseInt(rows.user_min)
            if(indice >= user_min){
              notification.open({ 
                    message: 'Curso disponible para habilitar', 
                    description: 
                    `El curso ${rows.concepto} ha alcanzado el numero permitido de registros y está disponible para su activación, Actívelo ahora`, 
                    onClick: (e) =>this.registerCourses(2)
                    , 
              })
              setTimeout(()=>{
                notification.destroy()
          
              },2000)
            }
          })          
      }).catch(err=>{
          console.log(err)
      })
      
    }
    showModal(param){
        this.setState({visible:true});
        let course = this.state.dataUser.filter(function(hero){
            return hero.id_register_courses === param.id_register_courses
        })

        actions.getIdClientesAlfa(param.fk_cliente).then(response=>{
          this.setState({detalle_empresa_cliente:response.data.data.getIdClientesAlfa})
        }).catch(err=>{
          console.log("err",err.response)
        })
        this.setState({showData:course});
    }
    onOk(){
        this.setState({visible:false})
    }
    registerCourses(param){
      if(param === 2){
        this.setState({tabla:false})
        this.setState({tabla_register:true})
        this.setState({aref:true})
        this.setState({add_course:false})
        this.setState({metrincas:false})
        this.setState({users_plataform:false})     
        this.setState({cotizaciones_cliente:false})
        this.setState({requisitos:false})
        this.setState({vacante:false})
      }
      if(param === 1){
        this.setState({tabla:false})
        this.setState({aref:false})
        this.setState({tabla_register:true})
        this.setState({add_course:false})
        this.setState({metrincas:false})
        this.setState({users_plataform:false})
        this.setState({cotizaciones_cliente:false})
        this.setState({cotizaciones_cliente:false})
        this.setState({vacante:false})
      }
    }
    add_course(){
      this.setState({tabla:false})
      this.setState({tabla_register:false})
      this.setState({add_course:true})
      this.setState({aref:false})
      this.setState({metrincas:false})
      this.setState({users_plataform:false})
      this.setState({cotizaciones_cliente:false})
      this.setState({requisitos:false})
      this.setState({vacante:false})
    }
    metricas(){
      this.setState({tabla:false})
      this.setState({tabla_register:false})
      this.setState({add_course:false})
      this.setState({aref:false})
      this.setState({metrincas:true})
      this.setState({users_plataform:false})
      this.setState({cotizaciones_cliente:false})
      this.setState({requisitos:false})
      this.setState({vacante:false})
    }
    show_registers(){
      this.setState({tabla:true})
      this.setState({tabla_register:false})
      this.setState({add_course:false})
      this.setState({aref:false})
      this.setState({metrincas:false})
      this.setState({users_plataform:false})
      this.setState({cotizaciones_cliente:false})
      this.setState({requisitos:false})
      this.setState({vacante:false})
    }

    users_plataform(){
      this.setState({tabla:false})
      this.setState({tabla_register:false})
      this.setState({add_course:false})
      this.setState({aref:false})
      this.setState({metrincas:false})
      this.setState({users_plataform:true})
      this.setState({cotizaciones_cliente:false})
      this.setState({requisitos:false})
      this.setState({vacante:false})
    }
    cotizaciones_cliente(){
      this.setState({tabla:false})
      this.setState({tabla_register:false})
      this.setState({add_course:false})
      this.setState({aref:false})
      this.setState({metrincas:false})
      this.setState({users_plataform:false})
      this.setState({cotizaciones_cliente:true})
      this.setState({requisitos:false})
      this.setState({vacante:false})

    }
    requisitos(){
      this.setState({tabla:false})
      this.setState({tabla_register:false})
      this.setState({add_course:false})
      this.setState({aref:false})
      this.setState({metrincas:false})
      this.setState({users_plataform:false})
      this.setState({cotizaciones_cliente:false})
      this.setState({requisitos:true})
      this.setState({vacante:false})

    }
    vacante(){
      this.setState({tabla:false})
      this.setState({tabla_register:false})
      this.setState({add_course:false})
      this.setState({aref:false})
      this.setState({metrincas:false})
      this.setState({users_plataform:false})
      this.setState({cotizaciones_cliente:false})
      this.setState({requisitos:false})
      this.setState({vacante:true})

    }

    editar_param(){
      this.setState({disabled:false})
      this.setState({boton_editar:false})
      this.setState({boton_aceptar:true})
    }
    editar_param_modo(){
      this.setState({disabled_modo:false})
      this.setState({boton_editar_modo:false})
      this.setState({boton_aceptar_modo:true})
    }

    onChangeInputForm = (e) => {
      const { id, value } = e.target; 
      this.setState({
        [id]: value
      });
    };
    onChangeInputForm_expositor = (e) => {
      const { id, value } = e.target; 
      this.setState({
        [id]: value
      });
    };
    update_indice(param){
      let indice = this.state.input_indice;
      actionsAPI.update_indice(param,indice).then(response=>{
        console.log(response)
        if(response.data.data.update_indice.message==="indice actualizado"){
          swal({
            text:"El índice fue actualizado",               
            icon:"success",
          }); 
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }else{
          swal({
            text:"Ocurrió un error, por favor inténtelo nuevamente",               
            icon:"error",
          });
        }
      }).catch(err=>{
        console.log(err)
        console.log(err.response)
      })
    }
    cancel_indice(){
      this.setState({boton_aceptar:false})
      this.setState({boton_editar:true})
      this.setState({disabled:true})
    }

    cancel_modo(){
      this.setState({boton_aceptar_modo:false})
      this.setState({boton_editar_modo:true})
      this.setState({disabled_modo:true})
    }
    onChangeInput(e){
      this.setState({input_indice: e.target.value})
    }
    onChangeInputModo(e){
      this.setState({input_modo: e})
    }
    update_modo(id_courses){
      let modo = this.state.input_modo;
      actionsAPI.editar_modo(modo,id_courses).then(response=>{
        if(response.data.data.update_modo.message==="modo actualizado"){
          swal({
            text:"El modo fue actualizado",               
            icon:"success",
          }); 
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }else{
          swal({
            text:"Ocurrió un error, por favor inténtelo nuevamente",               
            icon:"error",
          });
        }
      }).catch(err=>{
        console.log(err)
        console.log(err.response)
      })
    }
    activar_curso(param){
      actionsAPI.activar_curso(param).then(response=>{
        if(response.data.data.activar_curso.message==="curso activado"){
          swal({
            text:"El curso fue activado",               
            icon:"success",
          }); 
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }else{
          swal({
            text:"Ocurrió un error, por favor inténtelo nuevamente",               
            icon:"error",
          });
        }
      }).catch(err=>{
        console.log(err)
        console.log(err.response)
      })
    }
    handleChangeTipo(e){
      if(e.target.value ){
        this.setState({tipo:e.target.value})
      }
    }
     handleChangeModo(e){
      if(e.target.value ){
        this.setState({modo:e.target.value})
      }
    }
    handleChangeExpositor(e){
      if(e.target.value ){
        this.setState({nuevo_expositor:e.target.value})
      }
    }
    onSubmitBtn = (e) => {    
      e.preventDefault();
      let concepto = this.state.concepto.replace(/[\n\r]/g, '');
      let descripcion = this.state.descripcion.replace(/[\n\r]/g, '');
      let url = this.state.url.replace(/[\n\r]/g, '');
      let encabezado1 = this.state.encabezado1.replace(/[\n\r]/g, '');
      let encabezado2 = this.state.encabezado2.replace(/[\n\r]/g, '');
      let encabezado3 = this.state.encabezado3.replace(/[\n\r]/g, '');
      let tipo = this.state.tipo
      let modo = this.state.modo
      let indice = this.state.indice
      let instructor = this.state.instructor
      let ig = this.state.ig.replace(/[\n\r]/g, '');
      let fb = this.state.fb.replace(/[\n\r]/g, '');
      let tw = this.state.tw.replace(/[\n\r]/g, '');
      let li = this.state.li.replace(/[\n\r]/g, '');
      let yt = this.state.yt.replace(/[\n\r]/g, '');
      let rs = this.state.rs.replace(/[\n\r]/g, '');
      let fecha = this.state.fecha
      let hora1 = this.state.hora1
      let hora2 = this.state.hora2
      let url_upload_image = localStorage.getItem("url_image");
      let url_main;
      let costo = this.state.costo;
      let precio;
      if(costo){
        precio = costo;
      }else{
        precio = 0;
      }

      if(url){
        url_main = url;
      }
      if(url_upload_image){
        url_main = url_upload_image;
      }
      console.log("url",url_main)

      let array = [];

      if(concepto){

      }else{
        array.push("Concepto ");
      } 
      if(descripcion){

      }else{
        array.push("Descripcion ");
      }if(instructor){

      }else{
        array.push("Expositor ")
      }
      if(fecha){

      }else{
        array.push("Fecha del curso ")
      }if(hora1){

      }else{
        array.push("Hora inicial ")
      }if(hora2){

      }else{
        array.push("Hora final ")
      }if(tipo){

      }else{
        array.push("Tipo ")
      }if(indice){

      }else{
        array.push("Número mínimo ")
      }if(modo){

      }else{
        array.push("Modo de publicación ")
      }
      if(url_main){

      }else{
        array.push("Imagen no encontrada ")
      }

      if(concepto && url_main  && descripcion && instructor && tipo && modo && indice && fecha && hora1 && hora2){
        console.log(concepto,descripcion,url,encabezado1,encabezado2,encabezado3,instructor,tipo,modo,indice,ig,fb,tw,li,yt,fecha,rs,hora1,hora2,precio)

      actionsAPI.registrar_curso(concepto,descripcion,url,encabezado1,encabezado2,encabezado3,instructor,tipo,modo,indice,ig,fb,tw,li,yt,fecha,rs,hora1,hora2,precio).then(response=>{
        console.log(response)
        if(response.data.data.registrar_curso.message==="curso registrado"){
          swal({
            text:"El curso fue registrado",               
            icon:"success",
          }); 
          localStorage.removeItem("url_image")
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }else{
          swal({
            text:"Ocurrió un error, por favor inténtelo nuevamente",               
            icon:"error",
          });
        }
      }).catch(err=>{
        console.log(err)
        console.log(err.response)
      })
      }else{
        console.log("array",array)
        swal({
          text:`No deje espacios sin completar. Campos que faltan: ${array.map(rows=>{
            return(rows + " " )
          })}
          `,               
          icon:"error",
        });
      }

    }
    dateOnChange = (date, dateString) => {
      this.setState({fecha:dateString})
    };
    dateOnChangeHour1 = (date, dateString) => {
      this.setState({hora1:dateString})
    };
    dateOnChangeHour2 = (date, dateString) => {
      this.setState({hora2:dateString})
    };
    onCancel_expositor(){
      this.setState({modal_expositor:false})
    }
    onCancel_editar_expositor(){
      this.setState({nuevo_expositor:''})
      this.setState({modal_editar_expositor:false})
    }
    register_expositor(){
      this.setState({modal_expositor:true})
    }
    add_expositor(){
      let nombre = this.state.nombre_expositor;
      let apellidos = this.state.apellidos_expositor;
      let telefono= this.state.telefono_expositor;
      let correo = this.state.correo_expositor;
      console.log(nombre,apellidos,telefono,correo)
      if(nombre,apellidos,telefono,correo){
        actionsAPI.add_expositor(nombre,apellidos,telefono,correo).then(response=>{
          console.log(response)
          if(response.data.data.add_expositor.message === "registro exitoso"){
            swal({
              text:"registro exitoso",               
              icon:"success",
            });
            setTimeout(()=>{
              window.location.reload()
            },2000)
            this.setState({modal_expositor:false})
            this.setState({nombre_expositor:''})
            this.setState({apellidos_expositor:''})
            this.setState({correo_expositor:''})
            this.setState({telefono_expositor:''})

          }else{
            swal({
              text:"Álgo salió mal, por favor inténtelo nuevamente",               
              icon:"error",
            });
          }
  
        }).catch(err=>{
          console.log(err)
        })
      }else{
        swal({
          text:"Complete todos los campos",               
          icon:"error",
        });
      }
      
    }
    open_update_expositor(param){
      this.setState({modal_editar_expositor:true})
      this.setState({id_curso_editar_expositor:param})
    }

    editar_expositor(){
      let id_expositor = this.state.nuevo_expositor;
      let expositor = this.state.expositores.filter(function(hero){
        return hero.id_expositores === id_expositor
      })
      let nombre = expositor[0].nombre + " " + expositor[0].apellidos
      let id_curso = this.state.id_curso_editar_expositor;
      if(id_expositor){
        swal({
          title: '¿Está seguro de cambiar al expositor?',
          text: "Se le notificará por correo electronico a todos los participantes del curso que el instructor ha sido modificado por la institución.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Estóy seguro'
        }).then(function(ok) {   
          if(ok){
            actionsAPI.editar_expositor(nombre,id_curso).then(response=>{
              swal({
                text:"Se actualizó el expositor",               
                icon:"success",
              });
              console.log("response",response)
              let data = response.data.data.editar_expositor.map(rows=>{
                actionsAPI.sendMailChangeExpositor(rows.correo,rows.concepto).then(response=>{
                  console.log("response",response)
                })
              })
            }).catch(err=>{
              console.log(err.response)
            })
          }     
            
        })

      }else{
        swal({
          text:"Seleccione un expositor",               
          icon:"error",
        });
      }

      }

    finalizar_curso(param){
      console.log("param",param)
      actionsAPI.finalizar_curso(param).then(response=>{
        if(response.data.data.finalizar_curso.message === "curso finalizado"){
          swal({
            text:"Curso finalizado",               
            icon:"success",
          });
        }
      })
    }
    onChange = (key) => {
      console.log(key);
    };
    show_input_url(){
      this.setState({cuestion_image:false})
      this.setState({url_image:true})
      this.setState({image_upload:false})
    }
    show_input_upload(){
      this.setState({cuestion_image:false})
      this.setState({url_image:false})
      this.setState({image_upload:true})
    }
    cancel_inputs_options(){
      this.setState({cuestion_image:true})
      this.setState({url_image:false})
      this.setState({image_upload:false})
    }
    render() { 
        const format = 'HH:mm';
        let showData
        let modal;

        let requisitos;
        if(this.state.requisitos === true){
          requisitos = <Requisitos/>
        }

        if(this.state.showData){
        showData = this.state.showData[0];

        let detalle_empresa_cliente;

        if(this.state.detalle_empresa_cliente[0]){
          detalle_empresa_cliente = <font color="violet">{this.state.detalle_empresa_cliente[0].razonSocial} &nbsp;&nbsp;&nbsp; {this.state.detalle_empresa_cliente[0].rfc}</font>
        }else{
          detalle_empresa_cliente = <font color="violet">Nuevo usuario</font>
        }
        let titulo = <center><h4 className="strong">Detalles del registro</h4></center>

        modal = 
        <Modal style={{padding:"3em",top: 10 }} open={this.state.visible} title = {titulo} onOk={this.onOk} onCancel={this.onOk}>
            <div className="h5 text-center">
                <center>
                {detalle_empresa_cliente}
                <div className='column' style={{marginTop:"2em"}}>
                  <img src ={showData.imagen} style={{width:180,height:110}}/>
                </div>
                </center>
            </div>
                <div class="input-group input-group-icon">
                    <label className='strong'>Nombre</label>
                    <input
                        value={showData.nombre}
                        disabled
                    />
            
                </div>
                <div class="input-group input-group-icon">
                <label className='strong'>Apellidos</label>
                    <input
                        value={showData.apellidos}
                        disabled
                    />
                </div>
                <div class="input-group input-group-icon">
                <label className='strong'>Correo</label>
                    <input
                        value={showData.correo}
                        disabled
                    />
                </div>
                <div class="input-group input-group-icon">
                <label className='strong'>Código postal</label>
                     <input
                        value={showData.cp}
                        disabled
                    />
                </div>
                <div class="input-group input-group-icon">
                <label className='strong'>Curso</label>
                    <input
                        value={showData.concepto}
                        disabled
                    />
                </div>
                <div class="input-group input-group-icon">
                <label className='strong'>Fecha de registro</label>
                    <input
                        value={showData.fecha_registro}
                        disabled
                    />
                </div>
                <div class="input-group input-group-icon">
                <label className='strong'>Status</label>
                    <input
                        value={showData.estatus}
                        disabled
                    />
                </div>
        </Modal>
        }
        let menuItem = (
            <Menu style={{backgroundColor:"rgb(16,47,91)"}} mode="inline" defaultSelectedKeys={["1"]}>
              
              <Menu.Item
               className=' strong text-black text-white'
                key="1"
                onClick={(e) => this.registerCourses(1)}
              >
                <DesktopOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Cursos Disponibles
              </Menu.Item>
              <Menu.Item
                className=' strong text-black text-white'
                key="2"
                onClick={(e) => this.show_registers()}
              >
                <DesktopOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Usuarios registrados
              </Menu.Item>
              <Menu.Item
               className=' strong text-black text-white'
                key="3"
                onClick={(e) => this.add_course(1)}
              >
                <DesktopOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Registrar cursos
              </Menu.Item>
              <Menu.Item
               className='strong text-black text-white'
                key="4"
                onClick={(e) => this.users_plataform()}
              >
                <DesktopOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Plataforma de cursos
              </Menu.Item>
              <Menu.Item
               className='strong text-black text-white'
                key="5"
                onClick={(e) => this.cotizaciones_cliente()}
              >
                <FormOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Cotizaciones solicitadas
              </Menu.Item>
              <Menu.Item
               className='strong text-black text-white'
                key="6"
                onClick={(e) => this.requisitos()}
              >
                <FormOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Semblanza
              </Menu.Item>
              <Menu.Item
               className='strong text-black text-white'
                key="7"
                onClick={(e) => this.vacante()}
              >
                <FormOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; Vacante
              </Menu.Item>
              <Menu.Item
               className='strong text-black text-white'
                key="8"
                onClick={(e) => this.props.history.push("/login_courses")}
              >
                Salir
              </Menu.Item>
            </Menu>
          );
        const items = [
          {
            key: '1',
            label: <span className='strong'>Usuarios registrados</span>,
            children:<Users_Plataform/>,
          },
          
        ];
          
        
        const columns= ["Usuario","Correo","Teléfono","CP","Curso", "Status del registro","Detalles","Tipo de cliente"];
        let boton;
        let data;
        data = this.state.dataUser.map(rows=>{
          let tipo_cliente
          console.log("rows",rows)
          console.log(rows.fk_clientesads !== '0')
          if(rows.fk_clientesads !== '0'){
            tipo_cliente = <font className="strong" color="green">Cliente</font>
          }if(rows.fk_clientesads === '0'){
            tipo_cliente = <font className="strong" color= "gray">Nuevo usuario</font>
          }

          let boton = <Button className = "text-white" style={{backgroundColor:"rgb(38,90,159)"}} onClick={e=>this.showModal(rows)}>Detalles</Button>
              return([rows.nombre + " " + rows.apellidos,rows.correo,rows.telefono,rows.cp,<Tag color="blue">{ rows.concepto}</Tag>, "Activo",boton, tipo_cliente])
        })
        const columns_courses= ["Sistema", "Usuarios registrados","Usurio mínimo","Modo","Expositor","Activar","Finalizar"];
        let data_courses;
        let botonCancelar;
        let botonCancelarModo;
            data_courses = this.state.data_courses.map(rows=>{
              let boton_terminar = <Button onClick={e=>this.finalizar_curso(rows.id_courses)} type="danger"><i class="fa fa-exclamation"></i></Button>
              let boton_editar;
              let boton_aceptar;
              let boton_editar_modo;
              let boton_aceptar_modo;
              if(this.state.boton_editar === true){
                boton_editar =  <Button type="warning" onClick={e=>this.editar_param()}><i class="fa fa-pen"></i></Button>
              }if(this.state.boton_aceptar === true){
                botonCancelar = <Button type='danger' onClick={e=>this.cancel_indice()}><i class="fa fa-ban"></i></Button>
                boton_aceptar = <Button onClick={e=>this.update_indice(rows.id_courses,)} className='text-white' style={{backgroundColor:"rgb(38,90,159)"}}><i class="fa fa-check"></i></Button>
              }
              if(this.state.boton_editar_modo === true){
                boton_editar_modo =  <Button style={{backgroundColor:"rgb(38,90,159)"}} className='text-white' onClick={e=>this.editar_param_modo()}><i class="fa fa-pen"></i></Button>
              }if(this.state.boton_aceptar_modo === true){
                botonCancelarModo = <Button type='danger' onClick={e=>this.cancel_modo()}><i class="fa fa-ban"></i></Button>
                boton_aceptar_modo = <Button onClick={e=>this.update_modo(rows.id_courses)} className="text-white"style={{backgroundColor:"rgb(38,90,159)"}}><i class="fa fa-check"></i></Button>
              }
              
              let indice = parseInt(rows.indice)
              let user_min = parseInt(rows.user_min)
              let descripcion;
              let boton_activar;
              if(indice >= user_min && rows.estatus === "inactivo"){
                boton_activar = <Button className='text-white'  style={{backgroundColor:"rgb(38,90,159)"}}  onClick={e=>this.activar_curso(rows.id_courses)}>Activar </Button>
                descripcion = <font color='green'>{rows.descripcion}</font>
              }else if(rows.estatus === "activo"){
                boton_activar = <Tag color="blue">Curso activado</Tag>
                descripcion =  <font color='blue'>{rows.descripcion}</font>
              }
                else{
                boton_activar = <font color="red">No disponible</font>
                descripcion = rows.descripcion  
              }
              let sistema;
              if(rows.estatus === "activo"){
                sistema = <Tag color='blue'>{rows.concepto}</Tag>
              }if(rows.estatus === "inactivo"){
                  sistema = <Tag color='red'>{rows.concepto}</Tag>
              }
              let editar_expositor = <Button type="success" onClick={e=>this.open_update_expositor(rows.id_courses)}><i class="fa fa-pen"></i></Button>
              return([sistema,<center>{rows.indice}</center>
              ,<div className='rows'><Input className='rows' onChange={e=>this.onChangeInput(e)} defaultValue={this.state.input_indice || rows.user_min}disabled = {this.state.disabled}/>
              {boton_editar}{boton_aceptar}{botonCancelar}</div>,
              <div className='rows'>
              <Select
                onChange={e=>this.onChangeInputModo(e)}
                defaultValue={this.state.input_modo || rows.habilitar}                
                style={{
                  width: 120,
                }}
                disabled = {this.state.disabled_modo}
                options={[
                  {
                    value: 'Automatico',
                    label: 'Automatico',
                  },
                  {
                    value: 'Manual',
                    label: 'Manual',
                  },
                ]}
              />
              {boton_editar_modo}{boton_aceptar_modo}{botonCancelarModo}</div>,<div className='rows'><Input className='rows' onChange={e=>this.onChangeInput_instructor(e)} defaultValue={rows.instructor} disabled = {this.state.disabled_instructor}/>{editar_expositor}</div>,boton_activar,boton_terminar])
        })
        let metricas;
        if(this.state.metrincas === true){
          metricas = <Card>
            <App/>  
          </Card>
        }
        let tabla;        
        if(this.state.tabla === true){
        tabla=
        <div>
        <div  style={{width:"95%",marginTop:"1%",marginBottom:"2%"}} >
          <Card title ={ <h4 className=''>Listado de usuarios registrados a los cursos impartidos</h4> }>               
          <MUIDataTable  
            data={data} 
            columns={columns} 
            options={options} 
          />  
          </Card>
        </div>
      </div>        
      }  

      let users_plataform;
      if(this.state.users_plataform === true){
        users_plataform =
            <Tabs style={{marginLeft:"2em"}} defaultActiveKey="1" items={items} onChange={this.onChange} />
      }  

      let cotizaciones_cliente;
      if(this.state.cotizaciones_cliente === true){
        cotizaciones_cliente =
            <Solicitudes_Cotizaciones_cliente />
      }  
      let vacante;
      if(this.state.vacante === true){
        vacante =
            <Vacante/>
      }  

      let tabla_register;        
        if(this.state.tabla_register === true){
          let aref;
          if(this.state.aref === true){
            aref = <a onClick={e=>window.location.reload()}><font color="green">Enlazado por notificación, revertir click aquí?</font></a>
          }
        tabla_register=
        <div>
        <div  style={{width:"95%",marginTop:"1%",marginBottom:"2%"}} >
          <Card title ={ <h4 className=''>Listado de cursos disponibles</h4> } extra={aref}>               
          <MUIDataTable 
            title={
            <ul>
              <li className='text-left'><font color="red">Sistema inactivo</font></li>
              <li className='text-left'><font color="blue">Sistema activo</font></li>
              <li className='text-left'><font color="green">Activación disponible</font></li>
            </ul>
          } 
            data={data_courses} 
            columns={columns_courses} 
            options={options} 
          />  
          </Card>
        </div>
      </div>        
      }  
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '/' + mm + '/' + yyyy;
        let logon_form;
        let modal_expositor;
        if(this.state.modal_expositor === true){
          modal_expositor = <Modal  footer={null}  onCancel={e=>this.onCancel_expositor()} onOk={e=>this.onCancel_expositor()} open={this.state.modal_expositor} title ={<h4>Registrar nuevo expositor</h4>}>
            <form onSubmit={this.add_expositor}>
                <div className='container33'>
                <div class="input-group input-group-icon">
                  <input onChange={this.onChangeInputForm_expositor} id="nombre_expositor" type="text" placeholder="Nombre *" required/>
                  <div class="input-icon"><i class="fa fa-check"></i></div>
                </div>&nbsp;&nbsp;
                 &nbsp;&nbsp;
                
              </div>
              <div className='container33'>
              <div class="input-group input-group-icon">
                  <input onChange={this.onChangeInputForm_expositor} id="telefono_expositor" type="text" placeholder="Teléfono"/>
                  <div class="input-icon"><i class="fa fa-check"></i></div>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                  <input onChange={this.onChangeInputForm_expositor} id="correo_expositor" type="text" placeholder="Correo" required/>
                  <div class="input-icon"><i class="fa fa-check"></i></div>
                </div>&nbsp;&nbsp;
              </div>
             
              <center>
              <div className='container33'>
                 <Button
                 className='text-white'
                  style={{backgroundColor:"rgb(38,90,159)",width:"26rem"}}
                  onClick={e=>this.add_expositor(e)}
                >
                  Registrar
                </Button>               
            </div>
            </center>
          </form>

          </Modal>
        }

        let modal_editar_expositor;
        if(this.state.modal_editar_expositor === true){
          modal_editar_expositor = <Modal  footer={null}  onCancel={e=>this.onCancel_editar_expositor()} onOk={e=>this.onCancel_editar_expositor()} open={this.state.modal_editar_expositor} title ={<h4>Editar expositor</h4>}>
            <form onSubmit={this.editar_expositor}>
              <center>
                <div style={{padding:"3rem"}}>
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="expositor"
                        id="expositor"
                        onChange={this.handleChangeExpositor}
                        required
                        style={{width:"21rem"}}
                      >
                        <option>Seleccione un nuevo expositor</option>
                        {this.state.expositores.map(rows=>{
                          return(
                            <option value={rows.id_expositores}>{rows.nombre + ' ' + rows.apellidos}</option>
                          )
                        })}
                      </select>
                    </div>
                </div>
                <Button

                  type="info"
                  onClick={e=>this.editar_expositor(e)}
                  style={{width:"17rem"}}
                >
                  Aceptar
                </Button>   
                </center>  
          </form>
          </Modal>
        }

        let cuestion_image;
        let buton_cuestion_image;
        if(this.state.cuestion_image === true){
          cuestion_image = <div>
          <span className='strong'>¿Como desea registrar la imagen?</span><br/><br/>
          <div className='rows'><Button type='link' onClick={e=>this.show_input_url()}>Con una url</Button>&nbsp;<Button type='link' onClick={e=>this.show_input_upload()}>Archivo adjuntado</Button></div>
          </div>
        }else{
          buton_cuestion_image = <Button type='link' danger onClick={e=>this.cancel_inputs_options()}>Cancelar</Button>
        }
        let url_image;
        if(this.state.url_image=== true){
          url_image = <div class="input-group input-group-icon">
          <input onChange={this.onChangeInputForm} id="url" type="text" placeholder="Url de la imagen *" required/>{buton_cuestion_image}
          <div class="input-icon"><i class="fa fa-check"></i></div>
        </div>
        }
        let image_upload;
        if(this.state.image_upload === true){
          image_upload = <div><ReactFirebaseFileUpload/>{buton_cuestion_image}</div>
        }
        if(this.state.add_course === true){
          logon_form = <div className='container99' style={{marginTop:"3%"}}>
            <center>
              <h4 className=''> Registrar nuevo curso</h4><br/>
              </center>
            <div style={{marginTop:"2%",width:"50%"}}>
            <form onSubmit={this.onSubmitBtn}>
                <div className=''>
                <div className='rows'> 
                <VideoPromocional/>&nbsp;<br/>
                <Button type="primary" onClick={e=>this.register_expositor()}>Registrar expositor</Button>&nbsp;
                </div> 
                <div style={{marginTop:"2em"}} class="input-group input-group-icon">
                  <span className='strong'>Título <font color="red">*</font></span>
                  <input onChange={this.onChangeInputForm} id="concepto" type="text"required />
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Descripción <font color="red">*</font></span>
                  <textarea style={{height:"300px"}} onChange={this.onChangeInputForm} id="descripcion" type="text"required/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Costo</span>
                  <input type="number" defaultValue={this.state.costo || 0} onChange={this.onChangeInputForm} id="costo" required/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Razón Social</span>
                  <input onChange={this.onChangeInputForm} id="rs" type="text"/>
                </div>
                <div class="input-group input-group-icon">
                <span className='strong'>Encabezado1</span>
                  <input onChange={this.onChangeInputForm} id="encabezado1" type="text"/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Encabezado2</span>
                  <input  onChange={this.onChangeInputForm} id="encabezado2" type="text"/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Encabezado 3</span>
                  <input  onChange={this.onChangeInputForm} id="encabezado3" type="text"/>
                </div>
                {cuestion_image}
                {url_image}
                &nbsp;&nbsp;{image_upload}
                &nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Link de Youtube</span>
                  <input  onChange={this.onChangeInputForm} id="yt" type="text" />
                </div>
                &nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Link de FB</span>
                  <input  onChange={this.onChangeInputForm} id="fb" type="text" />
                </div>
                <div class="input-group input-group-icon">
                <span className='strong'>Link de Instagram</span>
                  <input  onChange={this.onChangeInputForm} id="ig" type="text" />
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Link de twiter</span>
                  <input  onChange={this.onChangeInputForm} id="tw" type="text"/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Link de Linkedin</span>
                  <input  onChange={this.onChangeInputForm} id="li" type="text"/>
                </div>
                    <span className='strong'>Expositor <font color="red">*</font></span>
                    <div class="input-group input-group-icon">
                      <select
                        className="browser-default custom-select "
                        type="select"
                        name="instructor"
                        id="instructor"
                        onChange={this.onChangeInputForm}
                        required
                        style={{width:"21rem"}}
                      >
                        <option>Seleccione un expositor</option>
                        {this.state.expositores.map(rows=>{
                          return(
                            <option value={rows.nombre + ' ' + rows.apellidos}>{rows.nombre + ' ' + rows.apellidos}</option>
                          )
                        })}
                      </select>
                    </div>
                      <Card>
                      <span className='strong'>Fecha del curso <font color="red">*</font></span>
                      <div className="input-group">
                      <DatePicker className="browser-default custom-select" onChange={this.dateOnChange} style={{height:"2.7rem",width:"21rem"}} required />
                      </div>
                      <span className='strong '>Hora inicial <font color="red">*</font></span>
                      <div className="input-group">
                          <TimePicker className="browser-default custom-select" defaultValue={false} onChange={this.dateOnChangeHour1} format={format} />
                      </div>
                      <span className='strong'>Hora final <font color="red">*</font></span>
                      <div className="input-group">
                          <TimePicker className="browser-default custom-select" defaultValue={false} onChange={this.dateOnChangeHour2} format={format} /></div>
                      </Card>
                      <span className='strong'>Tipo de sistema <font color="red">*</font></span>    
                      <div className="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="tipo"
                        id="tipo"
                        onChange={this.handleChangeTipo}
                        required
                      >
                        <option value="1">Seleccione una opción</option>
                        <option value="Presencial">Presencial</option>
                        <option value="Remoto">En Línea</option> 
                      </select><br/> <br/>
                      </div>
                      <div className="input-group">
                      <span className='strong'>Número mínimo <font color="red">*</font></span>
                      <input  onChange={this.onChangeInputForm} id="indice" type="number" required/>
                      </div>
                      <div class="input-group">
                      <span className='strong'>Modo de publicación <font color="red">*</font></span>
                          <div class="input-group">
                            <select
                              className="browser-default custom-select"
                              type="select"
                              name="modo"
                              id="modo"
                              required
                              onChange={this.handleChangeModo}
                            >
                              <option value="1">Seleccione una opción</option>
                              <option value="automatico">Automático</option>
                              <option value="manual">Manual</option> 
                            </select>
                          </div>
                          </div>
                    </div>
                    <center>
                    <div className='container33'>
                      <Button style={{width:"26rem"}} onClick={e=>this.cancelar()} type="danger">Cancelar</Button>&nbsp;&nbsp;&nbsp;
                      <Button
                        type="info"
                        onClick={e=>this.onSubmitBtn(e)}
                        style={{width:"26rem"}}
                      >
                        Registrar curso
                      </Button>               
                  </div>
                  </center>
                </form>
                </div>
              </div>
        }

        return ( 
        <div>
        <Layout>
        <Sider  style={{backgroundColor:"rgb(16,47,91)", width:"13em"}} trigger={null} collapsible collapsed={this.state.collapsed}>
          <div style={{ marginTop: "6%" }}>
            <a>
              <img src={ads} style={{ width: "100%",padding:"2em" }} />
            </a>
          </div>
          <br></br>
          {menuItem}
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background11"
            style={{ padding: 0, color: "black" }}
          >
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
            <font color="black" className="text-white" size="4">
              {"Empresa"}&nbsp;&nbsp;&nbsp;&nbsp;Bienvenido
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {localStorage.getItem("nombre_admin_courses") + " " + localStorage.getItem("apellidos_admin_courses")}{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              
              &nbsp;{formattedToday} 
            </font>
          </Header>
          <center>
          {tabla}
          {tabla_register}
          {modal}
          {logon_form}
          {modal_expositor}
          {modal_editar_expositor}
          {metricas}
          {users_plataform}
          {cotizaciones_cliente}
          {requisitos}
          {vacante}
          </center>
        </Layout>

        </Layout>
            </div>
         );
    }
}
 
export default DashboardCourses;