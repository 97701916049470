import { PDFExport } from '@progress/kendo-react-pdf';
import { Button } from 'antd';
import React, { Component } from 'react'
import titulo1 from  '../imagen/logo_color_azul.png';
import QRCode from "react-qr-code";

const PageTemplate = (props) => {
    return (
      <div  style={{position:"absolute",bottom:"10px",width:550}}>
      <center>  
      <p  className = "strong textabla" color="black" style = {{marginBottom:10,marginTop: 60}}>Copyright © 2024 Instituto Mexicano de Auditores Internos, A.C. <br/>
      Todos los derechos reservados.</p> <br/><p className = "strong textabla" >Montecito No. 38 Piso 28 Oficina 22 Col. Nápoles, Del. Benito Juárez, CP. 03810 Tels: 55 5514-7908 / 55 5525-4110</p>
      </center>
      </div>
    );
  };
class Report_Cotizacion extends Component {
    pdfExportComponent
    constructor(props) {
        super(props);
        this.state = { 


         }

    }
    render() { 

            
        let {data} = this.props; 
        console.log("data",data)
        let {concepto,estado,fecha_curso,fecha_emision,fecha_expiracion,folio,id_cotizaciones_curses,imagen,instructor,iva,piezas,precio,precio_unitario,tipo,tipo_cotizacion,total_global,nombre,apellidos,correo} = data[0]
        let dia;
        let mes;
        let año;
        if(fecha_emision){
             dia = fecha_emision.substring(8,10)
             mes = fecha_emision.substring(5,7)
             año = fecha_emision.substring(0,4)
        }
        

        let pdfExport = <div style={{ position: "absolute",left:"-5000px",top: 0 }}>               
        <PDFExport
            paperSize="letter"
            margin="1cm"
            // scale="0.8"
            forcePageBreak=".page-break"
            fileName={"Curso" + " " + concepto + " " + folio + ".pdf"}
            pageTemplate = {PageTemplate}  
            multiPage= "true "         
            ref={(component) => this.pdfExportComponent = component}>
            <div> 
              <img className='logoReportDown' src={titulo1} alt="imagen" />
            <div className='container10' style={{marginTop:"6%"}}>
              <div className="datos-generales-report textabla">
              <ul>
              <li className='strong text-left'>IMAI México</li>
              <li className='strong text-left'>COTIZACIÓN NO. {folio}</li>
              <li className='strong text-left'>PARTICIPANTE: {nombre + " " +  apellidos}</li>
              <li className='strong text-left'>CORREO DE IDENTIFICACIÓN: {correo}</li>
              </ul>
              </div>
              <div>
              <li className='strong text-left textabla'>CIUDAD DE MEXICO A {dia+ " DEL " + mes + " DEL " + año}</li>
              <table style={{width:"17em",marginTop:"2%"}} className='table-bordered'>
                <thead>
                  <tr>
                    <td bgcolor="rgb(16,47,91)" align='center' className='text-white textabla'><strong>EMISIÓN</strong></td>
                    <td bgcolor="rgb(16,47,91)" align='center' className='text-white textabla'><strong>EXPIRACIÓN</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td bgcolor="#ffffff" align='center' className='textabla'>{fecha_emision}</td>
                    <td bgcolor="#ffffff" align='center' className='textabla'>{fecha_expiracion}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
            <div className='container10'>
            <table style={{width:"25em"}}>
                <thead>
                  <tr>
                    <td colspan="2" bgcolor="rgb(16,47,91)" align='center' className='text-white textabla'><strong>DATOS GENERALES</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Curso:<h6>{concepto}</h6> </td>
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left'><img src={imagen} width={90}></img></td>
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Expositor: {instructor}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Costo ${total_global}.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <center>
            <p className='strong textabla' style={{marginTop:"2%"}}>El Instituto Mexicano de Auditores Internos(IMAI) agradece su participación a nuestro curso y nos permitimos presentar nuestra propuesta referente a los evento de su interés.</p>
            </center>
            <div style={{marginTop:"2%"}}>

            <center>
            <div style={{width:555}}>               
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <td  style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="rgb(16,47,91)" align='center' className='text-white strong textabla'><strong>TOTALES</strong></td>
                </tr>
              </thead>
              <tbody>
              <tr>
              <td className='strong textabla' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong textabla' colSpan={1}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">SUBTOTAL</td>                        
              <td className='strong textabla' colSpan={1}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">$&nbsp;{precio_unitario}</td>
              </tr> 
              <tr>
              <td className='strong textabla' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">IVA</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">$&nbsp;{iva}</td>
              </tr> 
              <tr>
              <td className='strong textabla' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">TOTAL</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">$&nbsp;{total_global}</td>
              </tr> 
            </tbody> 
            </table>
            <center> 
            <p className='strong textabla'>Escanea el código QR para ver los distintos métodos de pago: </p> 
            <QRCode style={{width:100,height:100}} value={"DATOS BANCARIOS QUE SE VAN A INDEXAR DENTRO DEL DOCUMENTO :)"} />  
            </center>
            </div>
            </center>   
           
        </div>  
        </div>
        </PDFExport>
        </div>
        return ( 
        <div>
            <Button type="primary" onClick={e=>this.pdfExportComponent.save()}>Descargar cotización</Button>
            {pdfExport}
        </div> 
        );
    }
}
 
export default Report_Cotizacion;