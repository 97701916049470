import React from 'react';
import './index.css';
import { ArrowLeftOutlined, ArrowRightOutlined, HomeOutlined, AppstoreOutlined, FileTextOutlined, PhoneOutlined, GlobalOutlined, AuditOutlined, TeamOutlined } from '@ant-design/icons';
import logo2 from '../../imagen/logo2.png'
import logo3 from '../../imagen/logo3.png'
import logo4 from '../../imagen/logo4.png'
import logo5 from '../../imagen/logo5.png'
import logo6 from '../../imagen/logo6.png'
import logo7 from '../../imagen/logo7.png'
import logo8 from '../../imagen/logo8.png'

import captura3 from '../../imagen/captura3.png'
import captura4 from '../../imagen/captura4.png'
import captura5 from '../../imagen/captura5.png'
import captura6 from '../../imagen/captura6.png'
import image1 from '../../imagen/image1.png'
import image2 from '../../imagen/image2.png'
import image3 from '../../imagen/image3.png'
import logo from '../../imagen/logoImai.jpg'
import banderas from '../../imagen/banderas.png'

import representante_imai from '../../imagen/representante_imai.png'

class VerticalNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
      isModalOpen: false,
      modalClosing: false,
      isModalOpen2: false,
      modalClosing2: false,
      isModalOpen3: false,
      modalClosing3: false,
      isModalOpen4: false,
      modalClosing4: false,
      isModalOpen5: false,
      modalClosing5: false,
      isModalOpen6: false,
      modalClosing6: false
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleModal2 = this.toggleModal2.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
    this.toggleModal3 = this.toggleModal3.bind(this);
    this.closeModal3 = this.closeModal3.bind(this);
    this.toggleModal4 = this.toggleModal4.bind(this);
    this.closeModal4 = this.closeModal4.bind(this);
    this.toggleModal5 = this.toggleModal5.bind(this);
    this.closeModal5 = this.closeModal5.bind(this);
    this.toggleModal6 = this.toggleModal6.bind(this);
    this.closeModal6 = this.closeModal6.bind(this);
  }

  toggleNavbar() {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  }

  toggleModal() {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      modalClosing: false
    }));
  }

  closeModal() {
    this.setState({ modalClosing: true });
    setTimeout(() => {
      this.setState({ isModalOpen: false, modalClosing: false });
    }, 500); // Match the duration of the zoomOut animation
  }

  toggleModal2() {
    this.setState(prevState => ({
      isModalOpen2: !prevState.isModalOpen2,
      modalClosing2: false
    }));
  }

  closeModal2() {
    this.setState({ modalClosing2: true });
    setTimeout(() => {
      this.setState({ isModalOpen2: false, modalClosing2: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  toggleModal3() {
    this.setState(prevState => ({
      isModalOpen3: !prevState.isModalOpen3,
      modalClosing3: false
    }));
  }

  closeModal3() {
    this.setState({ modalClosing3: true });
    setTimeout(() => {
      this.setState({ isModalOpen3: false, modalClosing3: false });
    }, 500); // Match the duration of the zoomOut animation
  }

  toggleModal4() {
    this.setState(prevState => ({
      isModalOpen4: !prevState.isModalOpen4,
      modalClosing4: false
    }));
  }

  closeModal4() {
    this.setState({ modalClosing4: true });
    setTimeout(() => {
      this.setState({ isModalOpen4: false, modalClosing4: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  
  toggleModal5() {
    this.setState(prevState => ({
      isModalOpen5: !prevState.isModalOpen5,
      modalClosing5: false
    }));
  }

  closeModal5() {
    this.setState({ modalClosing5: true });
    setTimeout(() => {
      this.setState({ isModalOpen5: false, modalClosing5: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  toggleModal6() {
    this.setState(prevState => ({
      isModalOpen6: !prevState.isModalOpen6,
      modalClosing6: false
    }));
  }

  closeModal6() {
    this.setState({ modalClosing6: true });
    setTimeout(() => {
      this.setState({ isModalOpen6: false, modalClosing6: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  render() {

    let modal1 = this.state.isModalOpen && (
      <div className={`modal-overlay ${this.state.modalClosing ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal}>
        <div className={`modal-content ${this.state.modalClosing ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
          
          <div style={{padding:"4em"}}>
            <h2>Gobernanza</h2>
            <h4 className='strong'>Documentos para la Buena Gobernanza</h4>
            <p>
              Toda persona perteneciente o relacionada con el Instituto Mexicano de Auditores Internos, A.C. (IMAI, IIA-México), incluyendo a su membresía activa; personal ejecutivo y operativo; integrantes de los órganos de gobierno corporativo; consejos, comités y demás órganos que actúen en su nombre y representación, (todas ellas, denominadas en conjunto como “integrantes del IMAI” en sus distintas posiciones), rigen su comportamiento con base en valores y principios éticos y de buena gobernanza.
              <br /><br />
              Lo anterior, a fin de ratificar el profesionalismo y compromiso de los integrantes del IMAI de promover principios, normas y reglas de conducta que abonen a la eficacia, eficiencia, economía, transparencia y rendición de cuentas de las organizaciones.
              <br /><br />
              Para dar claridad y certeza a esos valores, prácticas y principios, el IMAI emite para sus integrantes el Código de Ética y el Código de Conducta respectivos.
              <br /><br />
              <span className='strong'>Instituto Mexicano de Auditores Internos, A.C.</span>
            </p>
          </div>
          
          <div style={{padding:"4em"}}>
            <h4 className='strong'>CÓDIGO DE ÉTICA</h4>
            <p>
              El Instituto Mexicano de Auditores Internos, A.C. (IMAI) adopta en su totalidad los preceptos establecidos en el Código de Ética del Instituto de Auditores Internos global (The IIA) como parte de los documentos rectores que rigen la actuación de sus miembros. A continuación, se presenta dicho Código.
            </p>
          </div>
          
          <div style={{padding:"4em"}}>
            <h4 className='strong'>INTRODUCCIÓN</h4>
            <p>
              El propósito del Código de Ética del Instituto es promover una cultura ética en la profesión de auditoría interna.
              <br /><br />
              La Auditoría interna es una actividad independiente y objetiva de aseguramiento y consulta, concebida para agregar valor y mejorar las operaciones de una organización. Ayuda a una organización a cumplir sus objetivos aportando un enfoque sistemático y disciplinado para evaluar y mejorar la eficacia de los procesos de gestión de riesgos, control y gobierno.
              <br /><br />
              Es necesario y apropiado contar con un código de ética para la profesión de auditoría interna, ya que ésta se basa en la confianza que se imparte a su aseguramiento objetivo sobre la gestión de riesgos, control y dirección. El Código de Ética del Instituto abarca mucho más que la definición de auditoría interna, llegando a incluir dos componentes esenciales:
              <ul>
                <li>Principios que son relevantes para la profesión y práctica de la auditoría interna.</li>
                <li>Reglas de Conducta que describen las normas de comportamiento que se espera sean observadas por los auditores internos. Estas reglas son una ayuda para interpretar los Principios en aplicaciones prácticas. Su intención es guiar la conducta ética de los auditores internos.</li>
              </ul>
              <br /><br />
              El Código de Ética junto al Marco internacional para la Práctica Profesional y otros pronunciamientos emitidos por el Instituto, proveen orientación a los auditores internos para servir a los demás. La mención a los "auditores internos" se refiere a los socios del Instituto, a quienes han recibido o son candidatos a recibir certificaciones profesionales del Instituto, y a aquellos que proveen servicios de auditoría interna.
            </p>
          </div>
          
          <div style={{padding:"4em"}}>
            <h4 className='strong'>APLICACIÓN Y CUMPLIMIENTO</h4>
            <p>
              Este Código de Ética se aplica tanto a los individuos como a las entidades que proveen servicios de auditoría interna.
              <br /><br />
              En el caso de los miembros del Instituto y de aquellos que han recibido o son candidatos a recibir certificaciones profesionales del Instituto, el incumplimiento del Código de Ética será evaluado y administrado de conformidad con los Estatutos y Reglamentos Administrativos del Instituto. El hecho de que una conducta particular no se halle contenida en las Reglas de Conducta no impide que ésta sea considerada inaceptable o como un descrédito, y en consecuencia, puede hacer que se someta a acción disciplinaria al socio, poseedor de una certificación o candidato a la misma.
            </p>
          </div>
          
          <div style={{padding:"4em"}}>
            <h4 className='strong'>PRINCIPIOS</h4>
            <p>
              Se espera que los auditores internos apliquen y cumplan los siguientes principios:
              <br /><br />
              <strong>Integridad</strong>
              <br />
              La integridad de los auditores internos establece confianza y, consiguientemente, provee la base para confiar en su juicio.
              <br /><br />
              <strong>Objetividad</strong>
              <br />
              Los auditores internos exhiben el más alto nivel de objetividad profesional al reunir, evaluar y comunicar información sobre la actividad o proceso a ser examinado. Los auditores internos hacen una evaluación equilibrada de todas las circunstancias relevantes y forman sus juicios sin dejarse influir indebidamente por sus propios intereses o por otras personas.
              <br /><br />
              <strong>Confidencialidad</strong>
              <br />
              Los auditores internos respetan el valor y la propiedad de la información que reciben y no divulgan información sin la debida autorización a menos que exista una obligación legal o profesional para hacerlo.
              <br /><br />
              <strong>Competencia</strong>
              <br />
              Los auditores internos aplican el conocimiento, aptitudes y experiencia necesarios al desempeñar los servicios de auditoría interna.
            </p>
          </div>
          
          <div style={{padding:"4em"}}>
            <h4 className='strong'>REGLAS DE CONDUCTA</h4>
            <p>
              <strong>Integridad</strong>
              <br />
              Los auditores internos:
              <ul>
                <li>Desempeñarán su trabajo con honestidad, diligencia y responsabilidad.</li>
                <li>Respetarán las leyes y divulgarán lo que corresponda de acuerdo con la ley y la profesión.</li>
                <li>No participarán a sabiendas en una actividad ilegal o de actos que vayan en detrimento de la profesión de auditoría interna o de la organización.</li>
                <li>Respetarán y contribuirán a los objetivos legítimos y éticos de la organización.</li>
              </ul>
              <br /><br />
              <strong>Objetividad</strong>
              <br />
              Los auditores internos:
              <ul>
                <li>No participarán en ninguna actividad o relación que pueda perjudicar o aparente perjudicar su evaluación imparcial. Esta participación incluye aquellas actividades o relaciones que puedan estar en conflicto con los intereses de la organización.</li>
                <li>No aceptarán nada que pueda perjudicar o aparente perjudicar su juicio profesional.</li>
                <li>Divulgarán todos los hechos materiales que conozcan y que, de no ser divulgados, pudieran distorsionar el informe de las actividades sometidas a revisión.</li>
              </ul>
              <br /><br />
              <strong>Confidencialidad</strong>
              <br />
              Los auditores internos:
              <ul>
                <li>Serán prudentes en el uso y protección de la información adquirida en el transcurso de su trabajo.</li>
                <li>No utilizarán información para lucro personal o que de alguna manera fuera contraria a la ley o en detrimento de los objetivos legítimos y éticos de la organización.</li>
              </ul>
              <br /><br />
              <strong>Competencia</strong>
              <br />
              Los auditores internos:
              <ul>
                <li>Participarán sólo en aquellos servicios para los cuales tengan los suficientes conocimientos, aptitudes y experiencia.</li>
                <li>Desempeñarán todos los servicios de auditoría interna de acuerdo con las Normas para la Práctica Profesional de Auditoría Interna.</li>
                <li>Mejorarán continuamente sus habilidades y la efectividad y calidad de sus servicios.</li>
              </ul>
            </p>
          </div>
          
          <div style={{padding:"4em"}}>
            <h4 className='strong'>CÓDIGO DE CONDUCTA</h4>
            <p>
              <strong>Presentación</strong>
              <br />
              Además de las Reglas de Conducta contenidas en el Código de Ética de The IIA (el cual el IMAI ha adoptado en su totalidad como parte de sus documentos rectores para todos los integrantes del IIA-México), nuestro Instituto presenta a sus integrantes un Código de Conducta, con el fin de generar mayor certidumbre en cuanto a la actuación de sus miembros y de su personal.
              <br /><br />
              Las normas del Código de Conducta tienen como propósito actualizar en acciones específicas los principios y valores establecidos en el Código de Ética, y abonan a generar y mantener entre los integrantes del IMAI y sus partes interesadas, el mayor grado de confianza posible respecto de           la actuación de todos ellos.
          <br /><br />
          <strong>Objetivo</strong>
          <br />
          El presente Código de Conducta establece marcos de actuación y de comportamiento para los integrantes del Instituto Mexicano de Auditores Internos, A.C. (IMAI), así como para terceros relacionados con el Instituto, en lo aplicable. El Código tiene como propósito:
          <ul>
            <li>Promover un ambiente de trabajo basado en la integridad personal y profesional.</li>
            <li>Actualizar mediante acciones específicas los principios establecidos en el Código de Ética de The IIA, el cual el IMAI ha adoptado como propio.</li>
            <li>Resguardar la confianza que el IMAI ha construido a lo largo de los años ante sus miembros, las organizaciones públicas y privadas a las que brinda servicios, y la sociedad en general.</li>
          </ul>
          <br /><br />
          <strong>Aplicación</strong>
          <br />
          Los dispuesto en este Código es aplicable para todos los integrantes del IMAI.
          <br /><br />
          El Código es una guía de referencia a ser considerada por sus integrantes, a fin de promover una actuación profesional, apropiada y alineada a las mejores prácticas globales en las organizaciones en las que sus integrantes prestan sus servicios.
          <br /><br />
          De igual modo, los proveedores de bienes y prestadores de servicios externos que establezcan relaciones contractuales o de cualquier otro tipo con el IMAI, deberán tener conocimiento de este Código, y observarán los preceptos que les resulten aplicables de acuerdo con las actividades que realizan.
          <br /><br />
          <strong>Alcance</strong>
          <br />
          La aplicación de este Código no limita el alcance de las disposiciones contenidas en otros códigos profesionales que en su caso resulten aplicables para los integrantes del IMAI, como los relativos a la profesión de Contaduría Pública, Derecho o Examinación de Fraudes, entre otros.
          <br /><br />
          Su aplicación tampoco limita el alcance de las disposiciones legales y normativas contenidas en el marco jurídico que resulten aplicables.
          <br /><br />
          <strong>VALORES Y PRINCIPIOS DEL CÓDIGO DE CONDUCTA</strong>
          <br />
          <strong>Institucionalidad</strong>
          <br />
          Los integrantes del IMAI están obligados a:
          <ul>
            <li>Conocer y apegarse a las políticas, lineamientos y directrices internas emitidas por el Instituto.</li>
            <li>Sustentar su actuación en los principios éticos y las normas de conducta emitidas por el Instituto.</li>
            <li>Desarrollar con calidad, economía, eficiencia y oportunidad las tareas y deberes que tienen encomendados, así como cualquier actividad profesional de Auditoría Interna y de sus disciplinas relacionadas que lleven a cabo.</li>
            <li>Atender y procurar el interés del Instituto, por encima de intereses personales o particulares que operen en contra del Instituto, bajo la premisa de que el interés principal del Instituto es promover la profesión de Auditoría Interna, así como profesionalizar con altos niveles de calidad técnica a sus practicantes, a fin de fortalecer su ejercicio de manera eficaz, eficiente e íntegra en el país.</li>
            <li>Conducirse en todo momento con apego a la verdad, sin distorsionar u omitir información relevante para el conocimiento de los hechos.</li>
            <li>Informar al Instituto sobre cualquier acto u omisión del que tenga conocimiento, y que sea contrario a las leyes, reglamentos o principios éticos que rigen al Instituto, incluyendo los preceptos de este Código y del Código de Ética.</li>
          </ul>
          <br /><br />
          <strong>Imparcialidad</strong>
          <br />
          Es obligación de los integrantes del IMAI:
          <ul>
            <li>Ejercer sus funciones y desarrollar sus actividades de manera objetiva, en forma ajena a prejuicios personales y sin permitir la influencia indebida de terceros.</li>
            <li>Actuar de manera neutral sin conceder privilegios o preferencias a persona o grupo alguno.</li>
            <li>Abstenerse de cualquier acción que impida el ejercicio de los derechos y la igualdad de oportunidades de las personas.</li>
            <li>Excluir cualquier actitud, motivación o interés que tenga como fin lesionar de cualquier manera al Instituto o a su membresía, con objeto de obtener beneficios indebidos o personales.</li>
          </ul>
          <br /><br />
          <strong>Economía y eficacia</strong>
          <br />
          Es obligación de los integrantes del IMAI:
          <ul>
            <li>Hacer uso racional de los bienes y recursos institucionales, contribuyendo a su utilización eficiente, eficaz y económica.</li>
            <li>Informar de manera precisa y oportuna sobre el ejercicio de los recursos del Instituto que les sean entregados para cumplir con un fin específico, y hacer las devoluciones correspondientes cuando proceda.</li>
            <li>Abstenerse de utilizar bienes y servicios del Instituto para fines de carácter privado, o con el propósito de obtener una ganancia o ventaja indebida.</li>
            <li>En su caso, restituir las cantidades que resulten por negligencia, daño, abuso o uso irracional de los bienes del Instituto.</li>
            <li>Contribuir a la preservación del patrimonio cultural y a la sustentabilidad del medio ambiente y de los recursos naturales, de acuerdo con las funciones que realiza.</li>
          </ul>
          <br /><br />
          <strong>Respeto y tolerancia</strong>
          <br />
          Es obligación de los integrantes del IMAI:
          <ul>
            <li>Conducirse con un trato cordial hacia toda persona, tanto al interior del Instituto como con partes externas, que propicie el entendimiento, la armonía y el diálogo respetuoso.</li>
            <li>Abstenerse de manifestaciones, sean verbales, escritas o de cualquier otro tipo, que resulten notoriamente ofensivas o degradantes para otra persona.</li>
            <li>Evitar cualquier acto que resulte discriminatorio, incluyendo los motivados por el género, edad, condición social o económica, preferencia sexual, estado civil, lengua, origen étnico, discapacidad, preferencia política o religión.</li>
            <li>Evitar en el desarrollo de sus funciones el proselitismo o la inducción de preferencias e ideas personales, sean políticas, religiosas o de cualquier otro tipo, que resulten ajenas a las labores técnicas que tienen a su cargo.</li>
            <li>Evitar cualquier tipo de acoso (sexual, físico, psicológico o laboral), o cualquier otra conducta que atente contra la confianza que una persona tiene en su propia dignidad.</li>
            <li>Promover que las actividades del Instituto se realicen en un efectivo ambiente de integridad, armonía, profesionalismo y basado en el respeto mutuo.</li>
          </ul>
          <br /><br />
          <strong>Fortaleza e imagen institucional</strong>
          <br />
          Es obligación de los integrantes del IMAI:
          <ul>
            <li>Contribuir a preservar y elevar la buena imagen del Instituto ante cualquier tercero, cuidando para tal fin que sus expresiones, actuación y lenguaje sean profesionales y apropiados.</li>
            <li>Abstenerse de emitir afirmaciones u opiniones infundadas, incompletas, tergiversadas o de cualquier manera faltas a la verdad, que lesionen la buena marcha o reputación del Instituto.</li>
            <li>Mantenerse actualizados en materia de normas, guías, lineamientos o cualquier otra disposición que rija de manera global a la profesión de auditoría interna y a sus disciplinas relacionadas, con el propósito de incorporar dichas actualizaciones a los cursos y seminarios que ofrece el instituto, así como a su práctica profesional de la Auditoría Interna.</li>
            <li>Procurar una capacitación, especialización y certificación técnica continua.</li>
          </ul>
          <strong>Probidad</strong>
          <br />
          Es obligación de los integrantes del IMAI:
          <ul>
            <li>Desarrollar sus funciones sin esperar ni solicitar más de lo que les corresponde en el ámbito profesional, y ser sinceros y veraces en sus declaraciones.</li>
            <li>Apegarse a las directrices institucionales y a toda normativa aplicable que tenga por objeto fortalecer una actuación ética, íntegra y transparente.</li>
            <li>Abstenerse de cualquier acción u omisión que implique:</li>
            <ul>
              <li>Un acto deshonesto o fraudulento;</li>
              <li>La falsificación o alteración de registros de información, documentos o cuentas;</li>
              <li>El ejercicio indebido de fondos, suministros o cualquier bien o activo;</li>
              <li>El uso deliberado de información falsa;</li>
              <li>El uso del cargo o posición dentro del Instituto para obtener beneficios indebidos;</li>
              <li>El uso o divulgación de la información o de las actividades institucionales para obtener ventajas indebidas; o que atenten contra la confidencialidad de información sensible o privada, o que ponga en riesgo la buena marcha y/o ventajas comerciales del instituto;</li>
            </ul>
          </ul>
          <strong>Acciones en caso de incumplimiento</strong>
          <p>Cualquier interesado puede denunciar ante el Instituto la transgresión por parte de un integrante del IMAI a las normas éticas y de conducta establecidas. Para tal efecto, deberá presentar toda la información que resulte competente y relevante que sustenta las afirmaciones hechas.

La presentación de dicha información podrá hacerla llegar al Instituto mediante los correos electrónicos disponibles en el sitio web del IMAI, o acudiendo personalmente al Instituto para hacer el planteamiento y la entrega de la documentación respectiva.</p>

        </p>
      </div>
      
      <button className='close-modal' onClick={this.closeModal}>Cerrar</button>
      
    </div>
  </div>
)

    
    let modal2 = this.state.isModalOpen2 && (
      <div className={`modal-overlay ${this.state.modalClosing2 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal2}>
        <div className={`modal-content ${this.state.modalClosing2 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
          <div style={{padding:"4em"}}>
          <h2>IMAI</h2>
          <h4 className='strong'>Que es el IMAI</h4>
          <center>
            <img width={90} src={logo2}></img>
            </center>
            <p>
              El Instituto Mexicano de Auditores Internos, A.C. (IMAI) legalmente constituido en 1984, es un foro abierto a la capacitación y a la investigación en las áreas de Auditoría Interna, Control, Riesgos, TI y tema afines.

              En ese entonces la Auditoría Interna en México, era una profesión nueva pero que comenzaba a tener una fuerte tendencia a crecer, por lo que el C.P. Juan Manuel Portal Martínez, empezó a concebir la idea de agrupar a los profesionales de la auditoría interna, interesados en apoyarlo a promover su difusión y aplicación con mayor orden y hacer técnicas reconocidas a nivel internacional para lo cual se decidió afiliarse a The Institute of Internal Auditors, Inc. para obtener mayor respaldo.

              Sus fundadores fueron los Contadores Públicos Juan Manuel Portal Martínez, Fernando Vera Smith, Jesús Alvarado Roberti, Armando Diaz Flores y Benjamín Antonio Vázquez Olvera, quienes iniciaron con este proyecto, el cual integra actualmente a más de 2,500 socios en alrededor de 100 empresas en México, tanto del Sector Público como del Privado.</p>
          </div>
          <div  style={{padding:"4em"}}>
          <h4 className='strong'>VISIÓN</h4>
          <center>
            <img width={150} src={captura3}></img>
            </center>
            <br/>
            <p>
            Ser la institución líder en la regulación y aplicación de la normatividad sobre temas de Auditoría Interna asi como en capacitación y servicios de Auditoría Interna y temas relacionados, a fin de acrecentar el prestigio de la actividad de Auditoría Interna y el desarrollo profesional de quienes la practican a fin de coadyuvar a mejorar la eficacia de los procesos de gestión de riesgos, control y gobierno, y consecuentemente, al cumplimiento de los objetivos y metas de la organización.</p>
          </div>
          
          <div style={{padding:"4em"}}>
          <h4 className='strong'>MISIÓN</h4>
          <center>
            <img width={150} src={captura4}></img>
            </center>
            <br/>
            <p>
              Promover entre los profesionales de Auditoría Interna la adopción de las normas internacionales promulgadas por The IIA y el mejoramiento constante de la Práctica Profesional de la Auditoría Interna y contribuir a mejorar los procesos, la gestión de negocios y el gobierno corporativo, para fortalecer el prestigio de esta profesión y de quienes la practican.</p>
          </div>
          <div style={{padding:"4em"}}>
          <h4 className='strong'>OBJETIVOS</h4>
          <ul className="objectives-list">
            <li>El mejoramiento de la Práctica Profesional de la Auditoría Interna.</li>
            <li>Desarrollar y mantener la unión y cooperación efectiva entre los profesionales de la Auditoría Interna.</li>
            <li>Promover la difusión de las normas de actuación profesional, a través de las cuales los auditores internos puedan medir y regular su propio desempeño y las organizaciones puedan esperar servicios de calidad.</li>
            <li>Propugnar la unificación de criterios y entendimiento, por parte de sus asociados, de las normas y principios básicos de actuación y ética profesional.</li>
            <li>Establecer y mantener el prestigio de la Auditoría Interna, a través de la investigación y la divulgación de conocimientos técnicos de enfoques conceptuales, relativos al ejercicio profesional de esta disciplina y materias afines.</li>
            <li>Establecer y mantener vínculos con otros organismos profesionales o docentes y entidades públicas o privadas, para identificación y desarrollo de aspectos que permitan elevar la calidad de la práctica de la Auditoría Interna y el Control en general, dentro de las organizaciones.</li>
          </ul>
        </div>

        <div style={{padding:"4em"}}>
          <center><img src={logo2} width={90}/></center>
          <h4 className='strong'>ETAPAS IMPORTANTES DEL IMAI</h4>

          <ul className="objectives-list">
            <li><strong>- 1985 -</strong> Afiliación a The Institute of Internal Auditors, Inc. (The IIA)</li>
            <p>Desde sus inicios el IMAI se encuentra afiliado a The IIA, el cual agrupa a la red de profesionales de Auditoría Interna más grande del mundo, con aproximadamente 185,000 miembros en 170 países; además cuenta con convenios de divulgación, capacitación y certificaciones con el IIA, por lo cual los cursos y seminarios son de clase y reconocimiento Mundial.</p>
             <center><img width={150} src ={logo3}/></center> 
            <li><strong>- 1991 -</strong> Participa en la creación de la Fundación Latinoamericana de Auditores Internos (FLAI).</li>
            <p>El IMAI es socio fundador e integrante de la FLAI, organismo que agrupa a profesionistas de Gobierno Corporativo, Auditoría Interna, Control y Riesgos en 16 países de América Latina, a fin de consolidar la profesión de auditor interno y cumplir cabalmente con sus responsabilidades ante la sociedad mediante la profesionalización de los auditores internos de América Latina.</p>

            <li><strong>- 1995 -</strong> Organiza el Primer Congreso Latinoamericano de Auditores Internos (CLAI) en Cancún, México.</li>
            <p>Evento que reúne a profesionales de América Latina para compartir temas actuales relativos a Auditoría Interna y sobre cambios tecnológicos, nuevas regulaciones globalizadas, la divulgación de estándares y metodologías, actuales que ayudan a derribar paradigmas.</p>
            <center><img src={logo4} width={150}/></center>

            <li><strong>- 1997 -</strong> Inicia el programa Certified Internal Auditor (CIA).</li>
            <p>Programa dirigido a todos los profesionales que practican la auditoría interna y que desean certificar sus conocimientos y habilidades en esta materia y temas que giran a su alrededor.</p>

            <li><strong>- 2004 -</strong> Organiza el IX CLAI.</li>
            <p>En 2004 se organiza el IX Congreso latinoamericano de auditores internos, en la Cd de México con la asistencia de más de 800 participantes de centro y sudamérica.</p>

            <li><strong>- 2009 -</strong> Surgimiento de las Certificaciones en Auditoría Gubernamental, Certified Government Auditing Professional (CGAP), Auto Evaluación de Control, Certified Control Self-Assessment (CCSA) y Auditor Certificado en Servicios Financieros, Certified Financial Services Auditor (CFSA).</li>
            <p>Contando con aproximadamente 1986 miembros, y la profesión con una creciente responsabilidad para los auditores internos, por los constantes cambios que se están presentando por la globalización, la dinámica de la sociedad y desarrollo tecnológico. Surgen tres nuevas certificaciones:</p>
            <center><img src={logo5} width={150}/></center>

            <ul>
              <li>Certificación de Auditoría Gubernamental (CGAP), diseñada para los auditores que desarrollan sus actividades en el sector público internacional y provee conocimiento para desempeñarse en ese ambiente.</li>
              <center><img src={logo7} width={150}/></center>
              <li>Certificación en Auto Evaluación de Control (CCSA), dirigido a la auto evaluación y establecimiento de control en las organizaciones, el CCSA es ideal para los practicantes con cualquier nivel de experiencia en los fundamentos y procesos de auto evaluación.</li>
              <center><img src={logo6} width={150}/></center>
              <li>Auditor Certificado en Servicios Financieros (CFSA), es la certificación ideal para profesionales que trabajan en instituciones bancarias, de ahorro u organizaciones de ahorro y préstamo, cooperativas de crédito, compañías de seguros y casas de bolsa.</li>
            </ul>
            <center><img src={captura5} width={150}/></center>

            <h4><strong>- 2013 -</strong> Programa de Certificación en Aseguramiento de Gestión de Riesgos (Certified Risk Management Assurance CRMA)</h4>
            <p>La Certificación en Aseguramiento de Gestión de Riesgos (CRMA), está dirigida a los auditores internos y los profesionales de la gestión de riesgos, los procesos de gobierno, aseguramiento de la calidad, teoría de control y su aplicación, así como objetivos del negocio y su desempeño organizacional.</p>


            <p>La excelente labor del IMAI, se ve reflejada en la creciente membresía, que para éste año ya agrupa a cerca de 2,000 auditores internos de todo el país, de los sectores público y privado.</p>
            <center><img src={captura6} width={250}/></center>

            <li><strong>- 2015 -</strong> Diplomado de Control Interno.</li>
            <p>Dadas las necesidades crecientes en las organizaciones y la emisión del COSO 2013, el IMAI desarrolla y ofrece a los profesionales de la auditoría interna, control y riesgos, su Diplomado de Control Interno y Riesgos.</p>
            <center><img src={logo8} width={250}/></center>

            <li><strong>- 2017 -</strong> XXXIII Encuentro Nacional de Auditores Internos.</li>
            <p>Cada año, el IMAI organiza el Encuentro Nacional de Auditores Internos, hasta 2017 se han realizado 33 encuentros, con un promedio de 500 participantes en años anteriores, sin embargo, la asistencia se ha ido incrementando en los últimos 3 años, de aproximadamente 800 a 1,000 asistentes que se reunieron en el evento más reciente.</p>

            <p>La necesidad de coadyuvar con la capacitación a profesionales de la Auditoría Interna para generar recursos humanos más calificados en Auditoría Interna y en Control, constituye el mayor reto para nuestro Instituto, que se ha propuesto difundir las técnicas más avanzadas y las mejores prácticas en esas áreas. Al transcurso de estos años, el IMAI a través de sus cursos y seminarios, ha sido el medio a través del cual los profesionales de la auditoría interna han permanecido actualizados en sus conocimientos para cumplir de mejor manera con las responsabilidades que tienen encomendadas en los diferentes sectores de la industria, el comercio y los servicios, tanto en el sector público como privado y social.</p>
          </ul>

          <div style={{marginLeft:"3em",padding:"4em"}}>
            <h4 className='strong'>EXPRESIDENTES DEL IMAI</h4>
            <p>Desde la creación del IMAI, se ha contado con el respaldo de profesionales sobresalientes en materia de Auditoría Interna, para mantenerse a la vanguardia que cumpla con la visión y misión del Instituto. Estos son algunos de los Expresidentes que han aportado sus conocimientos, experiencia y prestigio al Instituto Mexicano de Auditores Internos, A. C.:</p>
            <ul>
              <li>C. P. Jesús Antonio Serrano Nava.</li>
              <li>C. P. Fernando Ortíz Pérez.</li>
              <li>C. P. Mario Gómez Ayala.</li>
              <li>C. P. José Manuel Pintado.</li>
              <li>C. P. Juan Manuel Portal Martínez.</li>
              <li>C. P. Gilberto Palacio Villareal.</li>
              <li>C.P. José Manuel Pintado.</li>
              <li>C. P. Fernando Cervantes Flores.</li>
              <li>C. P. Severino Piña Norzagaray.</li>
              <li>C. P. Jesús Cano Benítez.</li>
              <li>C. P. Jorge Barajas Palomo.</li>
              <li>C. P. Fernando Vera Smith.</li>
              <li>Lic. Ricardo Saúl Gutiérrez Calderón.</li>
              <li>C. P. Estanislao Sánchez y López.</li>
              <li>Lic. Gabriel Alberto Benavides Ramírez.</li>
            </ul>
          </div>
        </div>

          <button className="close-modal" onClick={this.closeModal}>Cerrar</button>

        </div>
      </div>
    )

    let modal3 = this.state.isModalOpen3 && (
      <div className={`modal-overlay ${this.state.modalClosing3 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal3}>
        <div className={`modal-content ${this.state.modalClosing3 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
          <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
            <h2>Normas Globales de Auditoría Interna</h2>
            <h4 className='strong'>¡Las nuevas Normas Globales de Auditoría Interna ya están aquí!</h4>
            <center>
              <img width={670} src={image1} alt="Logo"></img>
            </center>
              El 9 de enero de 2024, The IIA Global publicó las nuevas Normas Internacionales de Auditoría Interna tras un proceso minucioso de investigación y recopilación de aportaciones y perspectivas de las partes interesadas, además de un período de comentarios públicos sobre el borrador.
              <br/><br/>
              Es fundamental para los Auditores Internos conocer a fondo la estructura y el contenido de las nuevas Normas, y el modo en que impactarán a la función de Auditoría Interna y al trabajo de sus profesionales.
              <br/><br/>
              Las Normas rigen y guían la práctica de la Auditoría Interna alrededor del mundo, y son la base para evaluar y elevar la calidad de esta imprescindible función profesional en organizaciones de todo tipo. Al centro de las nuevas Normas se encuentran 15 principios rectores que permiten una Auditoría Interna eficaz.
              <br/><br/>
              La versión anterior de las normas emitidas por The IIA, las Normas Internacionales para el Ejercicio Profesional de la Auditoría Interna publicadas en 2017, sigue aprobada y vigente para su uso durante un período de transición de un año.
              <br/><br/>
              De tal forma, las nuevas Normas Globales de Auditoría Interna entrarán en vigor el 9 de enero de 2025, si bien tanto The IIA como el IMAI recomiendan a los Auditores Internos adoptarlas desde ahora para el ejercicio profesional de sus funciones. Para tal efecto, es necesario:
              <ul>
                <li>Comprender la estructura del nuevo Marco Internacional y de las Normas Globales de Auditoría Interna.</li>
                <li>Conocer a fondo los principales cambios entre las Normas de 2017 y las Nuevas Normas.</li>
                <li>Tener dominio del contenido de cada sección de las Nuevas Normas.</li>
                <li>Comprender a cabalidad el modo en que las Nuevas Normas afectarán a las y los Auditores Internos, y a las funciones de la Auditoría interna y sus disciplinas relacionadas.</li>
              </ul>
              Si usted es parte de la comunidad IMAI, su membresía le ofrece entre otros múltiples beneficios, información oportuna, especializada y sin costo sobre los principales cambios de las Normas, los retos que se vislumbran en el futuro de nuestra práctica profesional, y el modo de enfrentarlos y aprovecharlos para hacer más eficaz nuestras funciones. Visite nuestra sección Membresía.
              <br/><br/>
              De igual modo, nuestros miembros gozan de cuotas preferenciales y exclusivas en los diversos recursos especializados de capacitación y actualización que hemos diseñado, para llevar sus conocimientos, competencias y habilidades al nivel más alto y de mayor vanguardia en esta nueva etapa de la Auditoría Interna y de su nuevo marco metodológico.
              <br/><br/>
              <h4>Cronología de la evolución del IPPF</h4>
              <center>
              <img width={670} src={image2} alt="Logo"></img>
            </center>
              <h4 className='strong'>Estructura y Puntos Clave de las Nuevas Normas Globales de Auditoría Interna</h4>
              Las "Nuevas Normas Globales de Auditoría Interna" han sido traducidas al español por el Instituto de Auditores Internos de España, en alianza y colaboración con la Fundación Latinoamericana de Auditores Internos (FLAI), organización regional de la que el IMAI es parte. Publicadas en 2024 por The IIA, estas Normas están diseñadas para elevar la práctica profesional de la auditoría interna a nivel mundial.

              A continuación, encontrará una síntesis sobre la estructura y puntos clave de las Nuevas Normas, elaborada por el IMAI para nuestros miembros y colegas.

              Adicionalmente, el IMAI ofrece capacitación especializada para elevar su práctica profesional y el de su equipo de auditoría, mediante el entendimiento y aplicación de las Nuevas Normas. Envíenos un correo a aud_calidad@imai.org.mx para expresarnos sus necesidades específicas en la materia, y brindarle opciones de recursos educativos hechos a su medida.

              El IMAI pone a su disposición la experiencia de 40 años en el fortalecimiento de habilidades y conocimientos en Auditoría Interna, y a su gran equipo de instructores profesionales y especializados, para ayudarle a garantizar que su Actividad de Auditoría Interna opere de manera más eficiente, ética y segura, conforme a los nuevos requerimientos del Marco Internacional para la Práctica Profesional.

              <h4 className='strong'>Marco Internacional para la Práctica Profesional</h4>
              El Marco Internacional para la Práctica Profesional (MIPP) ofrece una estructura coherente y un modelo estructural para la práctica profesional de la auditoría interna, que abarca las Normas Globales de Auditoría Interna, los Requerimientos Temáticos y las Guías Globales de Orientación.
              <ul>
                <li><b>Normas Globales de Auditoría Interna:</b> Guían la práctica profesional mundial y sirven como base para evaluar la calidad de la función de auditoría interna.</li>
                <li><b>Requerimientos Temáticos:</b> Mejoran la coherencia y calidad de los servicios de auditoría interna en áreas específicas de riesgo.</li>
                <li><b>Guías Globales:</b> Proporcionan información no obligatoria, asesoramiento y prácticas destacadas.</li>
              </ul>

              <h4 className='strong'>Fundamentos de las Normas Globales de Auditoría Interna</h4>
              Las Normas Globales de Auditoría Interna se centran en 15 principios rectores que permiten una auditoría interna eficaz. Cada principio está respaldado por normas que contienen requerimientos, consideraciones para la implementación y ejemplos de evidencia de conformidad.

              <ul>
                <li><b>Importancia de la Auditoría Interna:</b> Mejora la capacidad de las organizaciones para servir al interés público, fortalece los procesos de gobierno, gestión de riesgos y control, y aumenta la confianza pública en las organizaciones.</li>
              </ul>

              <h4 className='strong'>Dominio I: Propósito de la Auditoría Interna</h4>
              <ul>
                <li>La auditoría interna fortalece la capacidad de la organización para crear, proteger y sostener su valor al proporcionar aseguramiento, asesoramiento y previsiones de manera independiente, objetiva y basada en riesgos. Mejora el logro de objetivos, procesos de gobierno, gestión de riesgos, toma de decisiones y capacidad para servir al interés público.</li>
              </ul>

              <h4 className='strong'>Dominio II: Ética y Profesionalidad</h4>
              <ul>
                <li>Este dominio sustituye al anterior Código de Ética del IIA y establece las expectativas de comportamiento para los auditores internos. Incluye los principios de integridad, objetividad, competencia, debido cuidado profesional y confidencialidad.</li>
                <ul>
                  <li><b>Integridad:</b> Actuar con honestidad y valentía profesional.</li>
                  <li><b>Objetividad:</b> Mantener una actitud imparcial y libre de sesgo.</li>
                  <li><b>Competencia:</b> Aplicar conocimientos, aptitudes y habilidades necesarias.</li>
                  <li><b>Debido cuidado profesional:</b> Conformidad con las normas y escepticismo profesional.</li>
                  <li><b>Confidencialidad:</b> Uso y protección adecuada de la información.</li>
                </ul>
              </ul>

              <h4 className='strong'>Dominio III: Gobierno de la Función de Auditoría Interna</h4>
              <ul>
                <li>Este dominio aborda la estructura y gobernanza de la función de auditoría interna, asegurando su independencia y el apoyo del consejo y la alta dirección. Incluye principios como la autorización del consejo, posicionamiento independiente y supervisión del consejo.</li>
                <ul>
                  <li><b>Mandato de Auditoría Interna:</b> Definición clara de la autoridad y responsabilidades.</li>
                  <li><b>Estatuto de Auditoría Interna:</b> Documento formal que establece el alcance y los servicios de auditoría.</li>
                  <li><b>Apoyo del Consejo y de la Alta Dirección:</b> Garantizar recursos adecuados y apoyo.</li>
                </ul>
              </ul>

              <h4 className='strong'>Dominio IV: Gestión de la Función de Auditoría Interna</h4>
              <ul>
                <li>Este dominio se enfoca en la planificación estratégica, gestión de recursos y comunicación eficaz dentro de la función de auditoría interna.</li>
                <ul>
                  <li><b>Planificación Estratégica:</b> Comprender los procesos de gobierno, gestión de riesgos y control.</li>
                  <li><b>Gestión de Recursos:</b> Optimización de los recursos financieros, humanos y tecnológicos.</li>
                  <li><b>Comunicación Eficaz:</b> Establecer relaciones y comunicar resultados de manera clara y efectiva.</li>
                </ul>
              </ul>

              <h4 className='strong'>Dominio V: Desempeño de los Servicios de Auditoría Interna</h4>
              <ul>
                <li>Este dominio trata sobre la planificación y ejecución efectiva de los trabajos de auditoría interna, la recopilación y análisis de información, y la comunicación de resultados.</li>
                <ul>
                  <li><b>Planificación Eficaz de los Trabajos:</b> Definir objetivos, alcance y criterios de evaluación.</li>
                  <li><b>Ejecución de los Trabajos:</b> Recopilación y análisis de información, evaluación de hallazgos y formulación de recomendaciones.</li>
                  <li><b>Comunicación de Conclusiones:</b> Presentación de informes y monitoreo de planes de acción.</li>
                </ul>
              </ul>

              <h4 className='strong'>Aplicación en Funciones de Auditoría Interna de Pequeña Dimensión y Sector Público</h4>
              <ul>
                <li>Las normas se aplican a todas las funciones de auditoría interna, aunque se reconocen las diferencias en las funciones de pequeña dimensión y en el sector público. Se proporcionan estrategias específicas para asegurar la conformidad en estos contextos.</li>
              </ul>

              <h4 className='strong'>Conclusión</h4>
              <p>Las "Nuevas Normas Globales de Auditoría Interna" establecen un marco integral para la práctica profesional de la auditoría interna, promoviendo la ética, la competencia y la objetividad. A través de estos principios y normas, los auditores internos pueden fortalecer la capacidad de sus organizaciones para crear, proteger y sostener su valor, asegurando la confianza pública y el cumplimiento de los objetivos organizacionales.</p>
              <br/><br/>
              <p>Incentivamos a nuestra membresía y colegas a conocer las Nuevas Normas Globales, que son un fundamento crítico para el ejercicio profesional exitoso.</p>
            </div>
          </div>
        </div>
    )


    let modal4 = this.state.isModalOpen4 && (
      <div className={`modal-overlay ${this.state.modalClosing4 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal4}>
        <div className={`modal-content ${this.state.modalClosing4 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
          <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
          <h2>IPPF y Normas 2017</h2>  
          <center>
              <img width={670} src={image3} alt="Logo"></img>
            </center>
            <p>El Marco Internacional de Prácticas Profesionales (IPPF) es el marco conceptual que organiza las directrices, principios y normas autorizadas y promulgadas por The IIA en 2017 para el ejercicio profesional, eficaz y eficiente de la Auditoría Interna y sus disciplinas relacionadas.

              El IPPF incorpora, de manera interrelacionada, elementos específicos y fundamentales para el adecuado ejercicio profesional, como se muestra en la ilustración.

              Imagende de Guias
              Los miembros de la comunidad IMAI pueden acceder a la información detallada de cada uno de los elementos del IPPF, incluyendo la totalidad de las Normas Internacionales de Auditoría Interna en español, en las siguientes secciones:</p>
            <h4>El IPPF y las Normas Internacionales de Auditoría Interna</h4>
            <p>De acuerdo con la definición oficial que utiliza The IIA Global, y aplicable a todos sus profesionales alrededor del mundo, la Auditoría Interna es:Una actividad independiente y objetiva de aseguramiento y asesoría, diseñada para agregar valor y mejorar las operaciones de una organización. Ayuda a la organización a lograr sus objetivos, al aportar un enfoque sistemático y disciplinado para evaluar y mejorar la eficacia de los procesos de administración de riesgos, control interno y gobernanza.</p>
            <h4>Normas Internacionales para el Ejercicio Profesional de la Auditoría Interna – 2017</h4>
            <p>
              En el entorno tecnológico, interdependiente y dinámico actual, contar con una función de auditoría interna eficiente es una verdadera necesidad. La auditoría interna es una de las piedras angulares de una gobernanza organizacional eficaz, lo que genera valor para todas las partes interesadas de la auditoría interna.
            </p>
            <p>
              Las partes interesadas de la auditoría interna son todas aquellas, tanto internas como externas a la organización, que directa o indirectamente se ven impactadas por la práctica profesional de los auditores internos.
            </p>
            <p>
              De tal forma, el propósito de la auditoría interna es mejorar y proteger el valor de todo tipo de organizaciones, al brindar a todas sus partes interesadas seguridad, asesoramiento y conocimientos objetivos y basados en el análisis de riesgos.
            </p>
            <p>
              Generalmente bajo la supervisión de un Comité de Auditoría, los auditores internos revisan los procesos, las operaciones y los objetivos de la organización; contribuyen al logro eficaz, eficiente, económico e íntegro de las organizaciones, y brindan a la Alta Dirección y al Consejo o la Junta Directiva la información necesaria para tomar decisiones informadas sobre cuestiones críticas.
            </p>
            <p>
              Debido a que la auditoría interna es fundamental para la solidez de las organizaciones públicas y privadas, una responsabilidad esencial del Consejo o la Junta Directiva es garantizar que los auditores internos ejerzan de manera óptima sus funciones. Pero, ¿cómo puede asegurarse que los auditores internos presten servicios a la organización de manera eficaz?
            </p>
            <p>
              La respuesta está en las Normas Internacionales para el Ejercicio Profesional de la Auditoría Interna (Normas), promulgadas por The IIA y respaldadas por sus Institutos locales en cada país, como es el caso del IMAI en México.
            </p>
            <ul>
              <li>Las Normas son el mecanismo principal para garantizar que los auditores internos de una organización proporcionan servicios esenciales de manera profesional, consistente, precisa, de forma oportuna, y con base en metodologías y criterios técnicos y especializados.</li>
              <li>Proporcionan un marco para realizar una amplia gama de auditorías internas de valor agregado.</li>
              <li>Definen los principios básicos que representan la práctica de la auditoría interna.</li>
              <li>Establecen las bases para la evaluación del desempeño de la auditoría interna.</li>
              <li>Contribuyen a la mejora continua de los procesos y operaciones organizacionales.</li>
            </ul>
            <h4>Misión de la Auditoría Interna</h4>
            <p>
              La Misión de la Auditoría Interna articula lo que ésta, como disciplina especializada, aspira a lograr dentro de una organización. Su lugar dentro del Marco Internacional de Prácticas Profesionales (IPPF, por sus siglas en inglés) es deliberado, lo que demuestra cómo los profesionales deben aprovechar todo el Marco para facilitar su capacidad de lograr la Misión.
            </p>
            <p>
              Así, la misión de la auditoría interna es:
            </p>
            <blockquote>
              “Mejorar y proteger el valor de la organización al proporcionar aseguramiento, asesoría y análisis objetivos y basados en riesgos”.
            </blockquote>
            <h4>Principios Fundamentales de la Profesión de Auditoría Interna</h4>
            <p>
              La integridad es fundamental para el ejercicio profesional de la auditoría interna. Los principios fundamentales promulgados por The IIA contribuyen a garantizar que la función de Auditoría Interna se encuentre alineada y centrada en el futuro.
            </p>
            <p>
              Así, la aplicación efectiva de los Principios Fundamentales para la Profesión de Auditoría Interna determina de manera tangible la eficacia y eficiencia de ésta. Cuando todos los Principios se encuentran presentes y operan de manera coherente e interrelacionada, la función de auditoría interna logra la máxima eficiencia.
            </p>
            <ul>
              <li>La actividad de Auditoría Interna:
                <ul>
                  <li>Demuestra <strong>integridad</strong>.</li>
                  <li>Demuestra <strong>competencia y debido cuidado profesional</strong>.</li>
                  <li>Es <strong>objetiva y está libre de influencias indebidas (es independiente)</strong>.</li>
                  <li>Se alinea con las estrategias, objetivos y riesgos de la organización.</li>
                  <li>Está en una posición apropiada dentro de la jerarquía organizacional y cuenta con los recursos adecuados para ejercer sus funciones.</li>
                  <li>Demuestra calidad y mejora continua.</li>
                  <li>Se comunica de manera efectiva.</li>
                  <li>Proporciona aseguramiento con base en la administración de riesgos.</li>
                  <li>Agrega valor, es perspicaz, proactiva y centrada en el futuro.</li>
                  <li>Promueve la mejora organizacional.</li>
                </ul>
              </li>
            </ul>
            <h4>Código de Ética</h4>
            <p>
              El Código de Ética establece los principios y expectativas que rigen el comportamiento de las personas y organizaciones en la ejecución de trabajos de auditoría interna. En lugar de actividades específicas, el Código describe los requisitos mínimos de conducta y las expectativas de comportamiento.
            </p>
            <p>
              Su propósito es promover una cultura ética en la profesión de Auditoría Interna, y respalda la confianza depositada en ella para dar un aseguramiento objetivo sobre los procesos de gobernanza, administración de riesgos y control interno.
            </p>
            <ul>
              <li>Principios que son relevantes para la profesión y práctica de la Auditoría Interna.</li>
              <li>Reglas de Conducta que describen las normas de comportamiento que se esperan de sus profesionales. Estas reglas son una ayuda para interpretar los Principios en aplicaciones prácticas, y tienen como objetivo guiar la actuación de las y los auditores internos.</li>
            </ul>
            <p>
              "Auditores internos" se refiere a los miembros del Instituto, receptores o candidatos a certificaciones profesionales del IIA y aquellos que realizan servicios de auditoría interna dentro de la Definición de Auditoría Interna.
            </p>
            <h4>Aplicabilidad y Cumplimiento del Código de Ética</h4>
            <p>
              Este Código de Ética aplica tanto a entidades como a personas físicas que realizan servicios de Auditoría Interna.
            </p>
            <p>
              Para los miembros del IIA, como son los miembros del IMAI, así como para los destinatarios o candidatos de certificaciones profesionales del IIA, las infracciones del Código de Ética se evaluarán y administrarán de acuerdo con los Estatutos del IIA, el Proceso para la resolución de infracciones del Código de ética y el Proceso para la resolución de infracciones de certificación.
            </p>
            <p>
              El hecho de que una conducta particular no se mencione en las Reglas de Conducta no impide que sea inaceptable o desacreditable y, por lo tanto, el miembro, titular de la certificación o candidato puede ser responsable de medidas disciplinarias.
            </p>
            <h4>Principios del Código de Ética</h4>
            <p>
              Se espera que los auditores internos apliquen y mantengan los siguientes principios:
            </p>
            <ol>
              <li><strong>Integridad</strong>
                <ul>
                  <li>La integridad de los auditores internos genera confianza y, por lo tanto, proporciona la base para confiar en su juicio.</li>
                </ul>
              </li>
              <li><strong>Objetividad</strong>
                <ul>
                  <li>Los auditores internos demuestran el más alto nivel de objetividad profesional al recopilar, evaluar y comunicar información sobre la actividad o proceso que se examina.</li>
                  <li>Los auditores internos realizan una evaluación equilibrada de todas las circunstancias relevantes y no se dejan influenciar indebidamente por sus propios intereses o por los de otros al formar y emitir sus juicios.</li>
                </ul>
              </li>
              <li><strong>Confidencialidad</strong>
                <ul>
                  <li>Los auditores internos respetan el valor y la propiedad de la información que reciben y no la divulgan sin la autorización adecuada, a menos que exista una obligación legal o profesional de hacerlo.</li>
                </ul>
              </li>
              <li><strong>Competencia</strong>
                <ul>
                  <li>Los auditores internos aplican el conocimiento, las habilidades y la experiencia necesarios en el desempeño de los servicios de auditoría interna.</li>
                </ul>
              </li>
            </ol>
            <h4>Guías de Implementación</h4>
            <p>
              Las Guías de implementación ayudan a los Auditores Internos a aplicar las Normas y el Código de Ética de 2017.
            </p>
            <p>
              Tienen como propósito ser un marco integral, que en conjunto aborda el enfoque, las metodologías y diversas consideraciones respecto de los trabajos profesionales de Auditoría Interna, pero no detallan procesos o procedimientos.
            </p>
            <p>
              Son de gran ayuda para implementar aspectos específicos de las Normas y dar claridad sobre su contenido en situaciones prácticas.
            </p>
            <p>
              Los miembros del IMAI cuentan con acceso exclusivo a las Guías de Implementación.
            </p>
            <a href=''>Guías de Implementación (en inglés)</a>
            <h4>Guías Complementarias</h4>
            <p>
              Las Guías Complementarias proporcionan orientación detallada de gran valor para realizar actividades de Auditoría Interna. Incluyen áreas de actualidad, cuestiones específicas por sector o industria, así como procesos, procedimientos, técnicas, programas, enfoques paso a paso y ejemplos de entregables.
            </p>
          </div>
        </div>
      </div>
    )

    let modal5 = this.state.isModalOpen5 && (
      <div className={`modal-overlay ${this.state.modalClosing5 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal5}>
        <div className={`modal-content ${this.state.modalClosing5 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
          <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
            <h2>IIA Global</h2>
            <center>
              <img width={670} src={logo} alt="Logo"></img>
            </center>
            <div>
              <h4>Establecido en 1941</h4>
              <p>El Instituto de Auditores Internos, Inc (The IIA) es una asociación profesional internacional con sede central en Lake Mary, Florida, EE. UU. El IIA es la voz global de la profesión de auditoría interna, autoridad y líder reconocido, principal defensor y educador. En general, sus miembros ejercen su profesión en auditoría interna, gestión de riesgos, gobierno, control interno, auditoría de tecnología de la información, educación y seguridad.</p>
              
              <h4>Misión</h4>
              <p><strong>Proporcionar un liderazgo dinámico para la profesión global de auditoría interna.</strong> Las actividades en apoyo de esta misión incluyen:</p>
              <ul>
                <li><strong>Abogar y promover el valor que los profesionales de auditoría interna agregan a sus organizaciones.</strong></li>
                <li><strong>Brindar oportunidades profesionales integrales de educación y desarrollo, estándares y otros lineamientos de práctica profesional y programas de certificación.</strong></li>
                <li><strong>Investigar, difundir y promover el conocimiento sobre la auditoría interna y su función apropiada en el control, la gestión del riesgo y la gobernanza para los profesionales y las partes interesadas.</strong></li>
                <li><strong>Educar a los profesionales y otras audiencias relevantes sobre las mejores prácticas en auditoría interna.</strong></li>
                <li><strong>Reunir a auditores internos de todos los países para compartir información y experiencias.</strong></li>
              </ul>
              
              <h4>A nivel mundial</h4>
              <p>El IIA tiene más de 185,000 miembros. El IIA en América del Norte comprende 160 capítulos que atienden a más de 70,000 miembros en los Estados Unidos, Canadá, el Caribe (Aruba, Bahamas, Barbados, Islas Caimán, Curazao, Jamaica, Puerto Rico y Turks & Caicos), Bermuda, Guyana y Trinidad y Tobago.</p>
              
              <h4>Certificaciones</h4>
              <p>El IIA, comprometido con la Auditoría Interna, creó certificaciones en distintas materias, todas con reconocimiento internacional y excelente valor agregado, dichas certificaciones son: Certified Internal Auditor (CIA), Certificación en Autoevaluación de Control (CCSA), Certificación en Servicios Financieros (CFSA), Certificación en Auditoría Gubernamental (CGAP), Certificación en Aseguramiento de Gestión de Riesgos (CRMA), Calificación en Liderazgo de Auditoría Interna (QIAL), Certificación para Auditores en materia Ambiental, Salud y Seguridad (BEAC).</p>
              <h4>El actual presidente de THE IIA:</h4>
              <center>
              <img width={400} src={representante_imai} alt="Logo"></img>
            </center>
            <p>Anthony J. Pugliese, CIA, CPA, CGMA, CITP</p>
            <a className='strong' href='https://www.theiia.org'>www.theiia.org</a>
            </div>
          </div>
        </div>
      </div>
    )
    let modal6 = this.state.isModalOpen6 && (
      <div className={`modal-overlay ${this.state.modalClosing6 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal6}>
      <div className={`modal-content ${this.state.modalClosing6 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
        <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
          <center>
              <img width={670} src={logo} alt="Logo"></img>
          </center>
          <h2>FLAI</h2>
          <h4>Introducción</h4>
          <p><strong>Nace de un deseo de muchos auditores internos latinoamericanos de contar con un foro exclusivo propio en el cual se puedan discutir los problemas del quehacer diario, intercambiar experiencias comunes e ir creando una comunidad importante de colegas de esta profesión, con intereses similares a fin de adquirir mayor importancia y protagonismo en el mundo de los negocios, académico y de otra índole, tanto del sector público como privado.</strong></p>

          <p>Este proyecto se inicia en Quito, Ecuador y se consolida en Cancún, México en 1995.</p>

          <h4>¿Qué es la FLAI?</h4>
          <p><strong>Es una organización profesional no lucrativa, autónoma e independiente que tiene como misión pugnar por la profesionalización de los Auditores internos en América Latina para, de manera participativa y homogénea, consolidar una profesión que cumpla cabalmente con sus responsabilidades ante la sociedad.</strong></p>

          <h4>Congreso Latinoamericano de Auditoría Interna (CLAI)</h4>
          <p>Luego de constituida en 1995, la FLAI se aboca a la organización y realización de los Congresos Latinoamericanos de Auditores Internos (CLAI's), promoviendo y coordinando con el país sede designado la celebración de un Congreso Latinoamericano Anual.</p>

          <p>El CLAI es el logro más importante de FLAI, ya que permite reunir anualmente a los auditores internos de Latinoamérica para la exposición y tratamiento de conocimientos y experiencias, permitiendo mejorar la práctica profesional y dar un mejor servicio a los clientes. El país sede del CLAI es designado por la Asamblea de la FLAI.</p>

          <h4>Países Miembros</h4>
          <center>
              <img width={670} src={banderas} alt="Logo"></img>
            </center>
        </div>
      </div>
    </div>
    )
    
    return (
      <div className={`navbarN ${this.state.isExpanded ? 'collapsed' : 'expanded'}`}>
         <a href="#home">
          
        </a>
        <button className="toggle-button" onClick={this.toggleNavbar}>
          {this.state.isExpanded ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
        </button>
        <a href="#home" onClick={this.toggleModal2}>
          <HomeOutlined className="icon" />
          <span className="link-text">Que es el IMAI</span>
        </a>
        <a href="#services" onClick={this.toggleModal}>
          <AuditOutlined className="icon" />
          <span className="link-text">Gobernanza</span>
        </a>
        <a href="#clients" onClick={this.toggleModal3}>
          <FileTextOutlined className="icon"  />
          <span className="link-text">Nuevas Normas Globales de Auditoría Interna</span>
        </a>
        <a href="#contact" onClick={this.toggleModal4}>
          <FileTextOutlined className="icon" />
          <span className="link-text">IPPF y Normas 2017</span>
        </a>
        <a href="#iia"  onClick={this.toggleModal5}>
          <GlobalOutlined className="icon"/>
          <span className="link-text" >IIA Global</span>
        </a>
        <a href="#flai" onClick={this.toggleModal6}>
          <TeamOutlined className="icon" />
          <span className="link-text">FLAI</span>
        </a>
        
      {modal1}
      {modal2}
      {modal3}
      {modal4}
      {modal5}
      {modal6}
      </div>
    );
  }
}

export default VerticalNavbar;
