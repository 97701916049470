import React from 'react';
import './Navbares.css';

class Navbar extends React.Component {
  render() {
    return (
      <nav className="navbar">
        <div className="navbar-logo">IMAI Noticias</div>
        <ul className="navbar-links">
          <li><a href="#home">Inicio</a></li>
          <li><a href="#news">Noticias</a></li>
          <li><a href="#about">Sobre Nosotros</a></li>
          <li><a href="#contact">Contacto</a></li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;