import React, { Component } from 'react'
import axios from 'axios'
import {API} from '../Graphql/Graphql';
import {Card,Button,Tag,Popconfirm} from 'antd'
import {createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { DialogUtility } from '@syncfusion/ej2-popups';


class MisCotizaciones extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            solicitud:[],
            solicitudUnique:[],
            detallesSolicitud:[],
            tablaInicial:true,
            tablaDetalles:false
         }
    }

    componentWillMount(){
        let fk_empresa =  localStorage.getItem("id_cliente");
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                getSolicitudes(data:"${[fk_empresa]}"){   
                    id_solicitudCotizacion
                    folioSolicitud
                    asesorAsignado
                    fechaEmision
                    fechaValidacion
                    fechaExpiracion
                    statusSolicitud
                    fk_productoServicio
                    id_productoServicio
                    tipo
                    tipoLicenciamiento
                    lineaProducto
                    concepto
                    precio 
                } 
                }
                `
            }   
            })
            .then(response=>{
                let solicitudes = response.data.data.getSolicitudes;
                console.log("response",response)
                function getUniqueListBy(arr, key) {
                    return [...new Map(arr.map(item => [item[key], item])).values()]
                }
                const array1 = getUniqueListBy(solicitudes, 'folioSolicitud');
                this.setState({solicitud:solicitudes})
                this.setState({solicitudUnique:array1})

            }).catch(err=>{
                console.log("err",err.response)
            })
    }
    cerrarDetalles(){
        this.setState({tablaInicial:true})
        this.setState({tablaDetalles:false})
    }

    detalles(param){
        console.log("param",param)
        let data = this.state.solicitud;
        let filtrado = data.filter(function(hero){
            return hero.folioSolicitud === param
        })
        this.setState({detallesSolicitud:filtrado})
        this.setState({tablaInicial:false})
        this.setState({tablaDetalles:true})
    }

    cancelarSolicitud(param){
        let fecha = new Date();
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                mutation{
                cancelSolicitud(data:"${[param,fecha]}"){   
                    message
                } 
                }
                `
            }   
            })
            .then(response=>{
                if(response.data.data.cancelSolicitud.message === "solicitud cancelada"){
                    DialogUtility.alert({    
                        okButton: {  text: 'aceptar', click: e=> window.location.reload()},        
                        title:'Notificación del sistema!' ,
                        content:`Solicitud cancelada. \n El folio ${param} ya no está disponible, contacte a su ejecutivo para más información.`    
                    }); 
                }
            }).catch(err=>{
                console.log("err",err.response)
            })
        console.log("param",param)
    }
    render() {  
        const options={ 
        filterType:"drowpdawn",
        responsive: "stacked",
        print:false,
        download:false,
        filter:false,
        caseSensitive:false,
        selectableRows:"none",
        elevation:0,
        viewColumns:false,  
        search:false,    
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      }        
      } 
      const formatDateUTC = (dateString) => {
        let fecha = new Date(dateString);
        const dia = fecha.getUTCDate().toString().padStart(2,'0');
        const mes = (fecha.getUTCMonth() + 1).toString().padStart(2,'0');
        const year = fecha.getUTCFullYear().toString();
        return `${dia}/${mes}/${year}`;
      }   
      let tablaDetalles;
      if(this.state.tablaDetalles === true){
        tablaDetalles = <div  style={{marginTop:"5%",width:"90%"}} >
           <h6><strong>Productos y servicios solicitados</strong></h6>
           <table className='table table-bordered table table-small table table-striped'>

           <tr className='table-success'>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Folio : <strong>{this.state.detallesSolicitud[0].folioSolicitud}</strong></td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Fecha de emisión : <strong> {formatDateUTC(this.state.detallesSolicitud[0].fechaEmision)}</strong></td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Fecha de expiración: <strong>{formatDateUTC(this.state.detallesSolicitud[0].fechaExpiracion)}</strong></td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Status de solicitud : <strong> {this.state.detallesSolicitud[0].statusSolicitud}</strong></td>
            </tr>
            </table>
            <table className='table table-bordered table table-small table table-striped'>
            <tr className='table-primary'>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Serie</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Tipo</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Concepto</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>L. del Producto</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Licenciamiento</td>
            </tr>
            {this.state.detallesSolicitud.map(rows=>{
                return(
                    <tr className='table-striped'>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.id_solicitudCotizacion}</td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.tipo}</td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.concepto}</td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.lineaProducto}</td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.tipoLicenciamiento}</td>
                </tr>
                )
            })}
        </table>
        <Button type="danger" onClick={e=>this.cerrarDetalles()}>Cerrar detalles</Button>    
        </div>
      }
      let tablaInicial;
      if(this.state.tablaInicial === true){
        let columns = ["ID", "Folio", "Asesor", "Emisión", "Expiración", "Status","Detalles","Cancelar solicitud"]
        let data = this.state.solicitudUnique.map(rows=>{
           
            let fechaEmision = formatDateUTC(rows.fechaEmision);
            let fechaExpiracion =  formatDateUTC(rows.fechaExpiracion)
            let botonDetalles = <Button type = "primary" onClick = {e=> this.detalles(rows.folioSolicitud)}>Detalles</Button> 
            let botonCancelar;
            if(rows.statusSolicitud === "Cancelada"){
                botonCancelar = <Tag color="red"><strong>Solicitud cancelada</strong></Tag>
            }else if(rows.statusSolicitud === "Validada"){
                botonCancelar = <Tag color="green"><strong>Solicitud validada</strong></Tag>
            }else{
                const text = <font color="red">¿Desea cancelar la solicitud?.<br/>Si continúa ya no será valido su folio de transacción<br/><br/></font>;

                botonCancelar =  <Popconfirm placement="top" title={text} onConfirm={e=> this.cancelarSolicitud(rows.folioSolicitud)} okText="Cancelar solicitud" cancelText="Cerrar">
                     <Button type = "danger" >Cancelar</Button>  
                </Popconfirm>
            }
            let status;
            let folio =  <font color = "#0666A4"><strong>{rows.folioSolicitud}</strong></font>
            if(rows.statusSolicitud === "En proceso"){
                status = <Tag color="blue"><font color = "#4188A2"><strong>En proceso</strong></font></Tag>
            }else  if(rows.statusSolicitud === "Cancelada"){
                status = <Tag color="red"><font color = "#4188A2"><strong>Cancelada</strong></font></Tag>
            }if(rows.statusSolicitud === "Validada"){
                status = <Tag color="green"><font color = "#4188A2"><strong>Validada</strong></font></Tag>
            }
            return([rows.id_solicitudCotizacion, folio, rows.asesorAsignado, fechaEmision, fechaExpiracion, status, botonDetalles, botonCancelar]);
        })
        let titulo = <h6><strong>Solicitudes realizadas</strong></h6>
        tablaInicial = <div  style={{width:"95%",marginLeft:"2%",marginTop:"2%"}} >  
        <Card title = {titulo}>        
           <MUIDataTable  
             data={data}
             columns={columns} 
             options={options} 
           /> 
       </Card> 
      </div> 
      }
        
        return ( 
            <div>
                <center>
                {tablaDetalles}
                {tablaInicial}
                </center>
            </div>
         );
    }
}
 
export default MisCotizaciones;