  import React, { Component } from "react";
  import "bootstrap/dist/css/bootstrap.css";
  import "@fortawesome/fontawesome-free/css/all.min.css";
  import "bootstrap-css-only/css/bootstrap.min.css";
  import { API } from "../Graphql/Graphql";
  import MUIDataTable from "mui-datatables";
  import { Card, Modal, DatePicker, Button } from "antd";
  import "./index.css";
  import { Input, Select } from "antd";
  import Report from "./Report.jsx";
  import swal from "sweetalert";
  import options from "./tableMuiOptions";
  import actions from './transactionsAPI.js';
  const { Search } = Input;

  const { Option } = Select;

  class Cotizaciones extends Component {
    pdfExportComponent;
    constructor(props) {
      super(props);
      this.state = {
        inputFields: [],
        rfc: "",
        razonSocial: "",
        correoCliente: "",
        apellidos: "",
        correo1: "",
        correo2: "",
        telefono1: "",
        telefono2: "",
        vendedor: "",
        form: true,
        pdfview: false,
        botonPdfExport: false,
        datos: [],
        Datos: [],
        id_productoServicio: " ",
        arrayProductoServicio: [],
        tablaProductoServicio: [],
        array: [],
        arrayFilter: [],
        subtotal: [],
        arrayInputFields: [],
        cantidadMasiva: [],
        descuentoMasivo: [],
        busqueda: "",
        tablaBusqueda: [],
        inputs: [],
        multArray: [],
        idGlobal: [],
        descuentos: [],
        subtotalGlobal: "",
        ivaGlobal: "",
        totalGlobal: "",
        isModalVisible: false,
        isModalVisible2: false,
        camposObligatorios: [],
        enviarEmail: false,
        folio: "",
        fechaExpiracion: "",
        datosContactos: [],
        rfClientes: false,
        tablaContactos: false,
        tablaContactoSleccionado: false,
        contactoSeleccionado: [],
        nombreCotizacion: " ",
        tipoSolicitud: "",
        report: false,
        productoSeleccionado: false,
        precioUnitario: [],
      };

      this.onChange = this.onChange.bind(this);
      this.cerrarCotizacion = this.cerrarCotizacion.bind(this);
      this.capturarFecha = this.capturarFecha.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }

    onSearch = (value) => console.log(value);

    componentDidMount() {
      this.setState({ tablaBusqueda: this.state.tablaProductoServicio });
    }

    showModal = () => {
      this.setState({ isModalVisible: true });
    };
    handleOk = () => {
      this.setState({ isModalVisible: false });
    };
    handleCancel = () => {
      this.setState({ isModalVisible: false });
    };
    showModal2 = () => {
      this.setState({ isModalVisible2: true });
    };
    handleOk2 = () => {
      this.setState({ isModalVisible2: false });
    };
    handleCancel2 = () => {
      this.setState({ isModalVisible2: false });
    };

    handleChange(value) {
      this.setState({ tipoSolicitud: value.target.value });
      console.log(value.target.value)
    }
    onChangeInput = (e) => {
      const { id, value } = e.target;
      this.setState({
        [id]: value,
      });
    };
    filtrarElementos = () => {
      var search = this.state.array.filter((item) => {
        if (
          item.consecutivo.toString().includes(this.state.busqueda) ||
          item.concepto
            .replace(/[.*`+\-?^${}()|[\]\\]/g, "\\$&")
            .includes(this.state.busqueda) ||
          item.concepto.toUpperCase().includes(this.state.busqueda) ||
          item.concepto.toLowerCase().includes(this.state.busqueda)
        ) {
          return item;
        }
        // const tipoTrimestral = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchTipoTrimestral) || val.includes(searchTipoTrimestral2) || val.includes(searchTipoTrimestral3)));
      });
      this.setState({ tablaBusqueda: search });
    };
    componentWillMount = async () => {
      localStorage.removeItem("id");
      localStorage.removeItem("tipo");
      localStorage.removeItem("concepto");
      localStorage.removeItem("precio");
      localStorage.removeItem("consecutivo");
      let fk_empresa = localStorage.getItem("fk_empresa");
      var data;
      await actions.getTablaProductoServicio(fk_empresa)
        .then((datos) => {
          data = datos.data.data.getTablaProductoServicio;
          let dataArray = [];
          data.map((rows) => {
            if (rows.asignacion && rows.id_actualizacion) {
              dataArray.push(rows);
            }
          });

          let filtered = dataArray.reduce((acc, item) => {
            if (
              !acc.some((e, i) => {
                if (item.asignacion === e.asignacion) {
                  if (item.id_actualizacion > e.id_actualizacion) {
                    acc.splice(i, 1, item);
                  }
                  return true;
                }
              })
            ) {
              acc.push(item);
            }
            return acc;
          }, []);
          this.setState({ array: filtered });
        })
        .catch((err) => {
          console.log("err", err.response);
          console.log("err", err);
        });
    };

    handleSubmit = (e) => {
      let array1 = [];
      this.state.inputFields.map((rows) => {
        array1.push(rows);
      });
    };
    handleAddFields = async (id) => {
      this.setState({ productoSeleccionado: true });
      this.setState({ busqueda: "" });
      const values = [...this.state.inputFields];
      let valor2 = [];
      values.push({ id });
      values.map((rows) => {
        valor2.push(rows);
        this.setState({ Datos: valor2 });
      });
      await this.setState({ inputFields: values });
      this.capturar();
    };

    capturar() {
      let filter;
      let arrayFilter = [];
      this.setState({ arrayFilter: "" });
      this.setState({ cantidad: "" });
      let idProd = this.state.inputFields;
      if (idProd) {
        this.state.inputFields.map((rows) => {
          filter = this.state.array.filter(function (hero) {
            return hero.id_productoServicio == rows.id;
          });
          arrayFilter.push(filter[0]);
        });
        this.setState({ arrayFilter: filter[0] });
        this.setState({ arrayInputFields: arrayFilter });
      } else {
        swal({
          text: "Seleccione un producto o servicio",
          buttons: false,
          icon: "warning",
        });
      }
    }

    renderTabla() {
      this.setState({ renderTabla: true });
    }

    cerrarCotizacion() {
      // window.location.reload();
      this.setState({ form: true });
      this.setState({ report: false });
      this.setState({ rfClientes: false });
      this.setState({ tablaContactos: false });
    }

    pdfView = async () => {
      let rs, nombre, apellido, correo1, correo2, telefono1, tipoSolicitud;
      let estado = this.state.contactoSeleccionado;
      let fechaExpiracion;

      if (this.state.razonSocial) {
        rs = this.state.razonSocial;
      } else {
        rs = this.state.datos.empresa;
      }
      if (this.state.nombre) {
        nombre = this.state.nombre;
      } else {
        nombre = this.state.datos.nombre;
      }
      if (this.state.apellidos) {
        apellido = this.state.apellidos;
      } else {
        apellido = this.state.datos.apellido;
      }
      if (this.state.correo1) {
        correo1 = this.state.correo1;
      } else {
        correo1 = this.state.datos.correo1;
      }
      if (this.state.correo2) {
        correo2 = this.state.correo2;
      } else {
        correo2 = this.state.datos.correo2;
      }
      if (this.state.telefono1) {
        telefono1 = this.state.telefono1;
      } else {
        telefono1 = this.state.datos.telefono1;
      }
      if (this.state.fechaExpiracion) {
        fechaExpiracion = this.state.fechaExpiracion;
      }
      if (this.state.tipoSolicitud) {
        tipoSolicitud = this.state.tipoSolicitud;
      }
      let array = [];
      let texto, texto7, texto8;
      let objeto;

      if (fechaExpiracion === undefined) {
        texto7 = "Fecha Vigencia";
        array.push(texto7);
      }
      if (this.state.contactoSeleccionado.id_contacto === undefined) {
        texto = "Contacto de la cotización no seleccionado";
        array.push(texto);
      }
      if (this.state.tipoSolicitud === undefined) {
        texto8 = "Tipo de cotización no seleccionado";
        array.push(texto8);
      }
      console.log("totalGlobal", this.state.totalGlobal);

      if (this.state.totalGlobal || this.state.totalGlobal === 0) {
      } else {
        objeto = "Debe tener al menos 1 producto seleccionado y calculado";
        array.push(objeto);
      }
      this.setState({ camposObligatorios: array });

      if (!array[0]) {
        this.setState({ report: true });
        this.setState({ form: false });
        this.setState({ pdfview: true });
        let año = new Date().getFullYear();
        function generateUUID() {
          var d = new Date().getTime();
          var uuid = "xAxxyx".replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          });
          return uuid;
        }
        let folio = (año + generateUUID()).toUpperCase();
        let nombreCotizacion = this.state.datos.rfc + folio;
        this.setState({ folio: folio });
        this.setState({ nombreCotizacion: nombreCotizacion });
      } else {
        this.showModal();
      }
    };

    consultarDatos = async () => {
      this.setState({ contactoSeleccionado: [] });
      let rfc = this.state.rfc;
      let id_cliente;
      await actions.getClienteRFC(rfc).then((response) => {
          if (response.data.data.getClienteRFC[0]) {
            this.setState({ datos: response.data.data.getClienteRFC[0] });
            let peticion = response.data.data.getClienteRFC[0];
            id_cliente = peticion.id_cliente;
            localStorage.setItem("rfc", response.data.data.getClienteRFC[0].rfc);
            this.setState({ rfClientes: true });
            this.setState({ tablaContactos: true });
          } else {
            swal({
              text: "El RFC no fue encontrado",
              buttons: false,
              icon: "warning",
            });
          }
        })
        .catch((err) => {
          console.log("error", err.response);
        });
        actions.getContactosId(id_cliente)
        .then((response) => {
          this.setState({ datosContactos: response.data.data.getContactosId });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    onChange = async (e) => {
      e.persist();
      await this.setState({ busqueda: e.target.value });
      this.filtrarElementos();
    };

    calcular = (datosTabla) => {
      this.setState({ productoSeleccionado: false });
      let array2 = [];
      let array = [];
      let array3 = [];
      let inputFields = this.state.inputFields;
      let idGlobal = [];

      datosTabla.map((rows) => {
        array.push(rows.precio);
        idGlobal.push(rows.id_productoServicio);
      });

      inputFields.map((row) => {
        if (row.cantidad) {
          array2.push(row.cantidad);
        } else {
          array2.push(1);
        }
        if (row.descuento) {
          array3.push(row.descuento);
        } else {
          array3.push(0);
        }
      });
      array.push(datosTabla);
      let multArray = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i] === "0") {
          multArray[i] = parseInt(array[i]) * parseInt(array2[i]);
        } else {
          multArray[i] = parseFloat(array[i]) * parseFloat(array2[i]);
        }
      }
      let subtotal = [];
      if (multArray[0] || multArray[0] === 0) {
        for (let i = 0; i < multArray.length; i++) {
          if (array[i] === 0) {
            subtotal[i] =
              multArray[i] - (parseInt(multArray[i]) * parseInt(array3[i])) / 100;
          } else {
            subtotal[i] =
              multArray[i] -
              (parseFloat(multArray[i]) * parseFloat(array3[i])) / 100;
          }
        }
      }

      let arrayDescuentos = [];
      if (multArray[0] || multArray[0] === 0) {
        for (let i = 0; i < multArray.length; i++) {
          arrayDescuentos[i] =
            (parseFloat(multArray[i]) * parseFloat(array3[i])) / 100;
        }
      }
      const nuevoSubtotal = subtotal.filter(function (value) {
        return !Number.isNaN(value);
      });

      const descuentos = arrayDescuentos.filter(function (value) {
        return !Number.isNaN(value);
      });
      const precioTotal = multArray.filter(function (value) {
        return !Number.isNaN(value);
      });

      var subtotalGlobal = nuevoSubtotal.reduce((x, y) => x + y);
      const totalProducto = subtotal.filter(function (value) {
        return !Number.isNaN(value);
      });

      var ivaGlobal = subtotalGlobal * 0.16;
      var totalGlobal = subtotalGlobal + ivaGlobal;
      this.setState({ subtotal: totalProducto });
      this.setState({ subtotalGlobal: subtotalGlobal });
      this.setState({ ivaGlobal: ivaGlobal });
      this.setState({ totalGlobal: totalGlobal });
      this.setState({ descuentos: descuentos });
      this.setState({ multArray: precioTotal });
      this.setState({ idGlobal: idGlobal });
    };

    handleInputChange = async (index, event) => {
      const values = [...this.state.inputFields];
      if (event.target.name === "input") {
        values[index].cantidad = event.target.value;
      } else {
        values[index].descuento = event.target.value;
      }
      this.setState({ inputFields: values });
    };

    capturarFecha(e) {
      if (e) {
        let fechaExpiracion = e._d.toString();
        this.setState({ fechaExpiracion: fechaExpiracion });
      }
    }

    agregarContacto = async (data) => {
      await this.setState({ contactoSeleccionado: data });
      this.setState({ tablaContactoSleccionado: true });
      this.setState({ tablaContactos: false });
    };
    cancelarContactoSeleccionado() {
      this.setState({ tablaContactoSleccionado: false });
      this.setState({ tablaContactos: true });
      this.setState({ contactoSeleccionado: [] });
    }
    borrarProductos() {
      this.setState({ inputFields: [] });
      this.setState({ idGlobal: [] });
      this.setState({ multArray: [] });
      this.setState({ subtotalGlobal: "" });
      this.setState({ ivaGlobal: "" });
      this.setState({ totalGlobal: "" });
      this.setState({ arrayInputFields: [] });
      this.setState({ descuentos: [] });
      this.setState({ subtotal: [] });
    }
    editarCalculo() {
      this.setState({ productoSeleccionado: true });
    }
    render() {
      const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

      let report;
      if (this.state.report === true) {
        let array = [];
        let array2 = [];
        let array3 = [];
        let array4 = [];
        let array5 = [];
        let array6 = [];
        let array26 = [];
        let array7 = [];
        let array8 = [];
        let array9 = [];
        let array10 = [];
        let array11 = [];
        let array12 = [];
        let array13 = [];
        let array14 = [];
        let array15 = [];
        let array16 = [];
        let array17 = [];
        let array18 = [];
        let array19 = [];

        this.state.descuentos.map((rows) => {
          let descuentoAplicado;
          if (rows) {
            descuentoAplicado = rows;
          } else {
            descuentoAplicado = 0;
          }
          array3.push({ descuentoAplicado: descuentoAplicado });
        });
        this.state.inputFields.map((param) => {
          let cantidad;
          if (param.cantidad) {
            cantidad = param.cantidad;
          } else {
            cantidad = 1;
          }
          array.push({ cantidad: cantidad });
        });
        this.state.inputFields.map((param) => {
          let descuento;
          if (param.descuento) {
            descuento = param.descuento;
          } else {
            descuento = 0;
          }
          array2.push({ descuento: descuento });
        });

        this.state.subtotal.map((rows) => {
          array16.push({ TotalPrecioProducto: rows });
        });
        let arr = array.map((item, i) => Object.assign({}, item, array2[i]));
        let arr1 = arr.map((item, i) => Object.assign({}, item, array3[i]));

        var d = new Date();
        var hh = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        if (hh >= 12) {
          hh = hh - 12;
        }
        if (hh == 0) {
          hh = 12;
        }
        let horas = hh + ":" + m + ":" + s;
        var f = new Date();
        var dd = f.getDate();
        var mm = f.getMonth() + 1;
        var yyyy = f.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        let fecha = dd + "/" + mm + "/" + yyyy + "/" + horas;
        array4.push({ fechaEmision: fecha });
        array5.push({ fechaExpiracion: this.state.fechaExpiracion });
        array17.push({ NumFolio: this.state.folio });
        array19.push({ tipoSolicitud: this.state.tipoSolicitud });
        let arr2 = array4.map((item, i) => Object.assign({}, item, array5[i]));
        let arr3 = arr1.map((item, i) => Object.assign({}, item, arr2[i]));
        let arr11 = arr3.map((item, i) => Object.assign({}, item, array16[i]));
        let arr12 = arr11.map((item, i) => Object.assign({}, item, array17[i]));

        array26.push({ rfc: this.state.datos.rfc });
        array6.push({ razonSocial: this.state.datos.razonSocial });
        let array233 = array26.map((item, i) =>
          Object.assign({}, item, array6[i])
        );
        array18.push({ id_cliente: this.state.datos.id_cliente });
        let arr13 = array233.map((item, i) =>
          Object.assign({}, item, array18[i])
        );
        let arr14 = arr12.map((item, i) => Object.assign({}, item, array19[i]));

        let subtotal = this.state.subtotalGlobal;
        let iva = this.state.ivaGlobal;
        let total = this.state.totalGlobal;
        array7.push({ subtotal: subtotal });
        array8.push({ iva: iva });
        array9.push({ total: total });

        let arr4 = array7.map((item, i) => Object.assign({}, item, array8[i]));
        let arr5 = arr4.map((item, i) => Object.assign({}, item, array9[i]));

        let nombre_admin = localStorage.getItem("nombre");
        let apellidos_admin = localStorage.getItem("apellido");
        let puesto_admin = localStorage.getItem("puesto");
        let correo_admin = localStorage.getItem("correo");
        let telefono_admin = localStorage.getItem("telefono");
        let ext = localStorage.getItem("extensionTelefonica");

        array10.push({ nombre: nombre_admin });
        array11.push({ apellido: apellidos_admin });
        array12.push({ puesto: puesto_admin });
        array13.push({ correo: correo_admin });
        array14.push({ telefono: telefono_admin });
        array15.push({ extensionTelefonica: ext });

        let arr6 = array10.map((item, i) => Object.assign({}, item, array11[i]));
        let arr7 = arr6.map((item, i) => Object.assign({}, item, array12[i]));
        let arr8 = arr7.map((item, i) => Object.assign({}, item, array13[i]));
        let arr9 = arr8.map((item, i) => Object.assign({}, item, array14[i]));
        let arr10 = arr9.map((item, i) => Object.assign({}, item, array15[i]));

        report = (
          <div>
            <center>
              <button
                onClick={(e) => this.cerrarCotizacion()}
                class="button"
                style={{ width: "100%", margin: "2px" }}
              >
                <span class="text text-center">Cancelar</span>
                <span class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                  </svg>
                </span>
              </button>
            </center>
            <Report
              botonesAdmin={true}
              adminAlfa={arr10[0]}
              totales={arr5[0]}
              dataContacto={this.state.contactoSeleccionado}
              datosCliente={arr13[0]}
              cotizaciones={arr14}
              productoServicioByFolio={this.state.arrayInputFields}
            />
          </div>
        );
      }

      let modal;
      if (this.state.camposObligatorios[0]) {
        modal = (
          <Modal
            title="Aviso!"
            visible={this.state.isModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <p>Por favor complete los siguientes campos obligatorios ...</p>
            {this.state.camposObligatorios.map((rows) => {
              return (
                <table>
                  <tr>
                    <td>
                      <strong>{rows}</strong>
                    </td>
                  </tr>
                </table>
              );
            })}
          </Modal>
        );
      }

      let modalEmail = (
        <Modal
          title="Aviso!"
          visible={this.state.isModalVisible2}
          onOk={this.handleOk2}
          onCancel={this.handleCancel2}
        >
          <p>Su cotización ha sido enviada.</p>
          <p>
            Por favor verifique su copia en su bandeja de correo y en caso de no
            recibir el adjunto inténtelo nuevamente
          </p>
        </Modal>
      );
      //////////////////// Sistema de cálculo //////////////////////////

      let tdDescuentos;
      this.state.descuentos.map((rows) => {
        if (rows !== 0) {
          tdDescuentos = (
            <td scope="col" className="datosTabla">
              <center>Descuento aplicado</center>
            </td>
          );
        } else {
          tdDescuentos = (
            <td scope="col" className="datosTabla">
              <center>Descuento no aplicado</center>
            </td>
          );
        }
      });

      let botonProductoServicio;
      let botonBorrar;
      let tablaProductos;
      let dataTablaProductos;
      let bontoGenerar = (
        <div class="input-group input-group-icon">
          <button
            style={{ width: "190px", height: "27px", margin: "4px" }}
            className="button2"
            onClick={(e) => this.pdfView()}
          >
            <span className="text">Generar Cotización</span>
            <span className="icon text-white">--</span>
          </button>
        </div>
      );

      let input1 = this.state.inputFields.map((inputField, index) => {
        let concepto;
        if (this.state.arrayInputFields[index]) {
          concepto = this.state.arrayInputFields[index].concepto;
        }
        return (
          <div class="input-group input-group-icon">
            <input
              size="20"
              style={{ marginTop: "3%", marginLeft: "1%" }}
              type="number"
              id="input"
              name="input"
              value={inputField.productos}
              placeholder={"Id" + " " + inputField.id + " " + concepto}
              onChange={(event) => this.handleInputChange(index, event)}
            />
          </div>
        );
      });

      let input2 = this.state.inputFields.map((inputField, index) => {
        let concepto;
        if (this.state.arrayInputFields[index]) {
          concepto = this.state.arrayInputFields[index].concepto;
        }
        return (
          <div class="input-group input-group-icon">
            <input
              size="20"
              style={{ marginTop: "3%", marginLeft: "1%" }}
              type="number"
              id="input2"
              name="input2"
              value={inputField.productos}
              onChange={(event) => this.handleInputChange(index, event)}
              placeholder={"Id" + " " + inputField.id + " " + concepto}
            />
          </div>
        );
      });

      let tablaCalculo;
      if (this.state.idGlobal[0] && this.state.productoSeleccionado === false) {
        let array1 = [];
        let array2 = [];
        let array3 = [];
        let array4 = [];
        let array5 = [];
        let array6 = [];
        let array7 = [];
        let array8 = [];
        let array9 = [];

        this.state.idGlobal.map((rows) => {
          array1.push({ id: rows });
        });

        this.state.multArray.map((rows) => {
          array2.push({ precio: rows });
        });
        this.state.inputFields.map((param) => {
          let cantidad;
          if (param.cantidad) {
            cantidad = param.cantidad;
          } else {
            cantidad = 1;
          }
          array3.push({ cantidad: cantidad });
        });
        this.state.inputFields.map((param) => {
          let descuento;
          if (param.descuento) {
            descuento = param.descuento;
          } else {
            descuento = 0;
          }
          array4.push({ descuento: descuento });
        });

        this.state.descuentos.map((rows) => {
          let descuentoAplicado;
          if (rows) {
            descuentoAplicado = rows;
          } else {
            descuentoAplicado = 0;
          }
          array5.push({ descuentoAplicado: descuentoAplicado.toFixed(2) });
        });
        this.state.subtotal.map((rows) => {
          array6.push({ TotalPrecioProducto: rows.toFixed(2) });
        });
        this.state.arrayInputFields.map((rows) => {
          array7.push({ concepto: rows.concepto });
          array8.push({ tipo: rows.tipo });
          array9.push({ precioUnitario: rows.precio });
        });

        let toFixedsubtotal = parseFloat(this.state.subtotalGlobal.toFixed(2));
        let subtotalDosCeros = toFixedsubtotal.toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        });
        let subtotal = subtotalDosCeros
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        let toFixedivaGlobal = parseFloat(this.state.ivaGlobal.toFixed(2));
        let ivaGlobalDosCeros = toFixedivaGlobal.toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        });
        let iva = ivaGlobalDosCeros
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        let toFixedtotalGlobal = parseFloat(this.state.totalGlobal.toFixed(2));
        let toFixedtotalGlobalDosCeros = toFixedtotalGlobal.toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        });
        let total = toFixedtotalGlobalDosCeros
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        let arr = array1.map((item, i) => Object.assign({}, item, array2[i]));
        let arr1 = arr.map((item, i) => Object.assign({}, item, array3[i]));
        let arr2 = arr1.map((item, i) => Object.assign({}, item, array4[i]));
        let arr3 = arr2.map((item, i) => Object.assign({}, item, array5[i]));
        let arr4 = arr3.map((item, i) => Object.assign({}, item, array6[i]));
        let arr5 = arr4.map((item, i) => Object.assign({}, item, array7[i]));
        let arr6 = arr5.map((item, i) => Object.assign({}, item, array8[i]));
        // let arr7 =
        let dataCalculo = arr6.map((item, i) =>
          Object.assign({}, item, array9[i])
        );
        let botonEditarCalculo = (
          <Button
            type="warning"
            style={{ height: "30px", marginTop: "1%" }}
            onClick={(e) => this.editarCalculo()}
          >
            Editar cálculo
          </Button>
        );
        let ti = (
          <h6>
            <strong>Productos y servicios calculados</strong>
          </h6>
        );
        tablaCalculo = (
          <Card
            style={{ width: "100%" }}
            title={ti}
            extra={
              <div className="rows">
                {bontoGenerar} {botonEditarCalculo}
              </div>
            }
          >
            <table
              style={{ width: "100%", marginTop: "5%" }}
              class="table table-striped table table-bordered table table-small"
            >
              <thead>
                <tr>
                  <th scope="col" className="datosTabla">
                    Concepto
                  </th>
                  <th scope="col" className="datosTabla">
                    Tipo
                  </th>
                  <th scope="col" className="datosTabla">
                    Cantidad
                  </th>
                  <th scope="col" className="datosTabla">
                    P.Unitario
                  </th>
                  <th scope="col" className="datosTabla">
                    P.Global
                  </th>
                  <th scope="col" className="datosTabla">
                    Descuento
                  </th>
                  {tdDescuentos}
                  <th scope="col" className="datosTabla">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataCalculo.map((rows, i) => {
                  let celdaDescuentoAplicado;
                  if (rows.descuentoAplicado !== 0) {
                    let descuentoAplicadoDosCeros =
                      rows.descuentoAplicado.toLocaleString("en", {
                        useGrouping: false,
                        minimumFractionDigits: 2,
                      });
                    let descuentoAplicado = descuentoAplicadoDosCeros
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

                    celdaDescuentoAplicado = (
                      <td align="right">{descuentoAplicado}</td>
                    );
                  }
                  if (rows.descuentoAplicado === 0) {
                    celdaDescuentoAplicado = (
                      <td className="text-center">
                        <font color="#F45602">No aplica</font>
                      </td>
                    );
                  }
                  let precioDosCeros = rows.precio.toLocaleString("en", {
                    useGrouping: false,
                    minimumFractionDigits: 2,
                  });
                  let precio = precioDosCeros
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

                  let TotalPrecioProductoDosCeros =
                    rows.TotalPrecioProducto.toLocaleString("en", {
                      useGrouping: false,
                      minimumFractionDigits: 2,
                    });
                  let TotalPrecioProducto =
                    TotalPrecioProductoDosCeros.toString().replace(
                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                      ","
                    );

                  let precioUnitarioDosCeros = rows.precioUnitario.toLocaleString(
                    "en",
                    { useGrouping: false, minimumFractionDigits: 2 }
                  );
                  let precioUnitario = precioUnitarioDosCeros
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                  return (
                    <tr id={i}>
                      <td className="datosTabla">{rows.concepto}</td>
                      <td className="datosTabla">{rows.tipo}</td>
                      <td align="center" className="datosTabla">
                        {rows.cantidad}
                      </td>
                      <td align="right" className="datosTabla">
                        ${precioUnitario}
                      </td>
                      <td align="right" className="datosTabla">
                        ${precio}
                      </td>
                      <td align="center" className="datosTabla">
                        {rows.descuento + "%"}
                      </td>
                      {celdaDescuentoAplicado}
                      <td align="right" className="datosTabla">
                        {TotalPrecioProducto}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} bgcolor="#D6DBDF" align="left">
                    Subtotal
                  </td>
                  <td style={{ padding: "2px" }} bgcolor="#D6DBDF" align="right">
                    $&nbsp;&nbsp;&nbsp;&nbsp;{subtotal}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} bgcolor="#D6DBDF" align="left">
                    IVA
                  </td>
                  <td style={{ padding: "2px" }} bgcolor="#D6DBDF" align="right">
                    $&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{iva}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} align="center"></td>
                  <td style={{ padding: "2px" }} bgcolor="#D6DBDF" align="left">
                    Total
                  </td>
                  <td style={{ padding: "2px" }} bgcolor="#D6DBDF" align="right">
                    $&nbsp;&nbsp;&nbsp;&nbsp;{total}
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        );
      }
      if (
        this.state.arrayInputFields[0] &&
        this.state.productoSeleccionado === true
      ) {
        dataTablaProductos = (
          <div>
            <table className="table table-small table table-bordered table table-striped">
              {this.state.arrayInputFields.map((rows, i) => {
                let id = rows.id_productoServicio;
                let tipo = rows.tipo;
                let concepto = rows.concepto;
                let precioUnitario = rows.precio;
                let precio;
                let arrayDatos = [];
                let filter;
                let cantidadDatos;
                let arrayDescuento = [];
                let descuentoProducto;
                let filterDescuento;
                arrayDatos.push(this.state.cantidadMasiva);
                arrayDescuento.push(this.state.descuentoMasivo);
                if (this.state.cantidadMasiva[0]) {
                  filter = arrayDatos.filter(function (hero) {
                    return hero[0] == id;
                  });
                  if (filter[0]) {
                    cantidadDatos = filter[0][1];
                    precio = parseFloat(rows.precio) * parseFloat(cantidadDatos);
                  }
                }
                if (this.state.descuentoMasivo[0]) {
                  filterDescuento = arrayDescuento.filter(function (hero) {
                    return hero[0] == id;
                  });
                  if (filterDescuento[0]) {
                    descuentoProducto = filterDescuento[0][1];
                  }
                }
                return (
                  <tbody>
                    <tr id={i}>
                      <td>Tipo</td>
                      <td>Concepto</td>
                      <td>Precio unitario</td>
                    </tr>
                    <tr>
                      <td>{tipo}</td>
                      <td>{concepto}</td>
                      <td>{precioUnitario}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        );
        let tituloProdServ = (
          <h6>
            <strong>Productos y servicios seleccionados</strong>
          </h6>
        );
        tablaProductos = (
          <div>
            <div style={{ width: "100%" }}>
              {tituloProdServ}
              {dataTablaProductos}
              <table style={{ marginTop: "2%", width: "80%" }}>
                <tr>
                  <td className="datosTabla">
                    <center>
                      {" "}
                      <strong>Cantidad de artículos</strong>
                    </center>
                  </td>
                  <td className="datosTabla">
                    <center>% Descuento en porcentaje</center>
                  </td>
                </tr>
                <tr>
                  <td>{input1}</td>
                  <td>{input2}</td>
                </tr>
              </table>
            </div>
          </div>
        );
        botonProductoServicio = (
          <div class="input-group input-group-icon">
            <button
              style={{ width: "170px", height: "27px", margin: "2px" }}
              class="button2"
              onClick={(e) => this.calcular(this.state.arrayInputFields)}
            >
              <span class="text">Calcular</span>
              <span class="icon">--</span>
            </button>
          </div>
        );
        botonBorrar = (
          <button
            onClick={(e) => this.borrarProductos()}
            class="button"
            style={{ width: "170px", height: "27px", margin: "2px" }}
          >
            <span class="text">Cancelar</span>
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
        );
      }

      let searchRFC;
      let form;
      let data;
      let tabla;

      searchRFC = (
        <div className="rows">
          <input
            className="form-control form-control-sm"
            type="text"
            id="rfc"
            value={this.state.rfc}
            name="rfc"
            onChange={this.onChangeInput}
          />
          <Button
            outline
            type="primary"
            rounded
            size="sm"
            className="mr-auto"
            onClick={(e) => this.consultarDatos()}
          >
            <i class="fas fa-search"></i>
          </Button>
        </div>
      );

      let vendedor =
        localStorage.getItem("nombre") + " " + localStorage.getItem("apellido");
      if (this.state.busqueda) {
        const columns = [
          "Id",
          "Tipo",
          "Concepto",
          "Precio",
          "Consecutivo",
          "Agregar",
        ];
        data = this.state.tablaBusqueda.map((rows, i) => {
          let boton = (
            <Button
              shape="circle"
              size="large"
              type="success"
              onClick={(e) => this.handleAddFields(rows.id_productoServicio)}
            >
              <i class="fas fa-plus"></i>{" "}
            </Button>
          );

          return [
            rows.id_productoServicio,
            rows.tipo,
            rows.concepto,
            "$" + rows.precio,
            rows.consecutivo,
            boton,
          ];
        });
        let tituloCatalogo = (
          <h4 className="h4tag">
            <strong>Catálogo de producto y servicio</strong>
          </h4>
        );
        tabla = (
          <div title={tituloCatalogo}>
            <MUIDataTable data={data} columns={columns} options={options} />
          </div>
        );
      }
      let columns3;
      let data3;
      let contactos;
      if (this.state.tablaContactos === true) {
        columns3 = ["Id", "Nombre", "Puesto", "Agregar"];

        if (this.state.datosContactos) {
          contactos = (
            <table className="table table-small table table-bordered table table-striped">
              <thead className="textabla">Contactos disponibles</thead>
              {this.state.datosContactos.map((rows) => {
                let boton = (
                  <Button
                    type="success"
                    shape="circle"
                    size="large"
                    onClick={(e) => this.agregarContacto(rows)}
                  >
                    <i size="9x" class="fas fa-check-circle"></i>
                  </Button>
                );
                return (
                  <tbody>
                    <tr>
                      <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                        {" "}
                        {rows.id_contacto}
                      </td>
                      <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                        {rows.nombre + " " + rows.apellidos}
                      </td>
                      <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                        {rows.puesto}
                      </td>
                      <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                        {boton}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          );
        }
      }

      let cancelarContactoSeleccionado;
      if (
        this.state.contactoSeleccionado.id_contacto &&
        this.state.tablaContactoSleccionado === true
      ) {
        cancelarContactoSeleccionado = (
          <button
            onClick={(e) => this.cancelarContactoSeleccionado()}
            class="button"
            style={{ width: "170px", height: "27px", margin: "2px" }}
          >
            <span class="text">Cancelar</span>
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
        );
      }

      let clientes;
      let rfcCliente;
      let rfc;
      let nombreContacto;
      let rs;
      let razonSocialCliente;
      let verify;
      if (this.state.rfClientes === true) {
        if (this.state.contactoSeleccionado.nombre) {
          verify = (
            <font color="green">
              <strong>
                Contacto seleccionado <i class="fas fa-check-circle"></i>
              </strong>
            </font>
          );
          nombreContacto =
            this.state.contactoSeleccionado.nombre +
            " " +
            this.state.contactoSeleccionado.apellidos;
          rs = "";
          rfc = this.state.datos.rfc;
        } else {
          rfc = "";
          rs = this.state.datos.razonSocial;
          rfcCliente = this.state.datos.rfc;
        }
        clientes = <div className="container">{contactos}</div>;
      }

      if (this.state.form == true) {
        let titulo = (
          <h4 className="h4tag" style={{ marginTop: "2%" }}>
            <strong>Generar cotización </strong>
          </h4>
        );
        let forms = (
          <div>
            <div class="input-group">
              <div class="col-half">
                <label className="textabla ">RFC de la empresa</label>
                {searchRFC}
              </div>
            </div>
            <div class="input-group input-group-icon">
              <div class="col-half">
                <label className="textabla">Fecha de vigencia</label>
                <div class="input-group">
                  <DatePicker
                    onChange={this.capturarFecha}
                    format={dateFormatList}
                    style={{ width: 545 }}
                  />
                  <div class="input-icon">
                    <i class="fa fa-grip-lines"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group input-group-icon">
              <div class="col-half">
                <label className="textabla">Tipo de Cotización</label>
                <div class="input-group">
                  <select style={{ width: 545 }} onChange={this.handleChange}>
                    <option value="SOLICITADA">Seleccione una opción</option>
                    <option value="SOLICITADA">Solicitada</option>
                    <option value="PROSPECTO">Prospecto</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="input-group input-group-icon">
              <div class="col-half">
                <label className="textabla">Buscar clave del producto:</label>
                <div class="input-group">
                  <Search
                    type="text"
                    name="busqueda"
                    required
                    className="textField"
                    value={this.state.busqueda}
                    onChange={this.onChange}
                    style={{ width: 545 }}
                  />
                  <div class="input-icon">***</div>
                </div>
              </div>
            </div>
            <center>
              <div class="input-group input-group-icon">
                <div class="col-half">
                  <div class="input-group">{verify}</div>
                </div>
              </div>
            </center>
            <center>
              <div class="input-group input-group-icon">
                <div class="col-half">
                  <div class="input-group">{cancelarContactoSeleccionado}</div>
                </div>
              </div>
            </center>
          </div>
        );
        form = (
          <div>
            <center>
              {titulo}
              <Card
                title={
                  <h4 className="h4tag">
                    <strong>
                      {nombreContacto}
                      {rs}&nbsp;&nbsp;&nbsp;{rfcCliente}{" "}
                      {this.state.contactoSeleccionado.puesto} &nbsp;&nbsp;
                      {this.state.contactoSeleccionado.correo1}
                    </strong>
                  </h4>
                }
                style={{ width: "95%" }}
                extra={
                  <div>
                    <h6>
                      <strong> Vendedor</strong>
                    </h6>{" "}
                    <label>{vendedor}</label>
                  </div>
                }
              >
                <div>
                  <div class="col-half2">{forms}</div>
                  <div class="col-half3">
                    {clientes}
                    {tabla}
                    {tablaProductos}
                    {tablaCalculo}
                    <div className="rows container30">
                      {botonProductoServicio}
                      {botonBorrar}
                    </div>
                  </div>
                </div>
              </Card>
            </center>
          </div>
        );
      }
      return (
        <React.Fragment>
          {form}
          {modal}
          {modalEmail}
          {report}
        </React.Fragment>
      );
    }
  }
  export default Cotizaciones;
