import React, {Component} from 'react'
import { Form,Row} from 'reactstrap';
import { MDBRow, MDBCol, MDBBtn, MDBInput,MDBCard,MDBCardImage,MDBAlert,MDBIcon } from 'mdbreact';
import {Card} from 'antd';
import Navbar from '../paneldeConection/navbar'
import swal from "sweetalert";
import actions from '../administradorAlfa/transactionsAPI'

class signupEmpresas extends Component{
    constructor(props){
        super(props)
        this.state ={
            rfc:"",
            razonSocial:"",      
            correo:"",
            telefono:"",
            paginaWeb:"",
            domicilioFiscal:"",
            contrasena:"" 
        } 
        this.onClear = this.onClear.bind(this)
    } 

    onClear = () => {
      this.setState({
        rfc:"",
        razonSocial:"",      
        correo:"",
        telefono:"",
        paginaWeb:"",
        domicilioFiscal:"",        
      });
    } 
    onChangeInput =(e)=>{
        const {id,value} = e.target;
        this.setState({
            [id]:value
        })
    }   
    componentWillMount(){
      console.log("first")
    }
    onSubmitBtn = (e)=>{
        e.preventDefault();  
        let rfc = this.state.rfc.toUpperCase();
        let razonSocial = this.state.razonSocial.toUpperCase().replace(/,/g, "");
        let correo = this.state.correo;
        let telefono = this.state.telefono;
        let paginaWeb = this.state.paginaWeb;
        let domicilioFiscal = this.state.domicilioFiscal.toUpperCase().replace(/,/g, "");       
        if(rfc && razonSocial && correo && telefono ){
        actions.signupEmpresa(
          rfc,razonSocial,correo,telefono,paginaWeb,domicilioFiscal
        ).then(response=>{
            if(response.data.data.signupEmpresas.message==="el registro en signup fue exitoso"){
              swal({
                text: "Registro exitoso",
                title: "",
                icon: "success",
              });
            this.setState({rfc:[],razonSocial:[],correo:[],telefono:[],paginaWeb:[],domicilioFiscal:[] })              
        } else{
          swal({
            text: "Algo salió mal, por favor inténtelo nuevamente",
            title: "",
            icon: "error",
          });
        }  
        }).catch(err=>{
          console.log(err)
        })
    }
  }
render(){
  let titulo = <strong><h5>Registrar Empresa</h5></strong>
    return(
        <React.Fragment>  
          <Navbar/>     
            
           <div style={{width:"90%",marginTop:"1%",marginLeft:"5%"}}>
           <div style={{ width: "90%", marginLeft: "5%" }}>
          <div className="container23">
          <div className="column">
              <img
                className="container26"
                src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              />
            </div>
            <div className="column">
              <div>
                <h6 className="container24">
                  {this.state.idCliente.razonSocial}
                </h6>
              </div>
              <div class="container22">
                <h4>{titulo}</h4>
                <form onSubmit={this.onSubmitBtn}>
                  <div class="row">
                    <div class="input-group input-group-icon">
                      <input
                        id="nombre"
                        type="text"
                        name="nombres"
                        onChange={this.onChangeInput}
                        value={this.state.nombre}
                        required
                        placeholder="Nombre (s) *"
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="apellidos *"
                        id="apellido"
                        type="text"
                        name="apellido"
                        onChange={this.onChangeInput}
                        value={this.state.apellido}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        type="email"
                        placeholder="Correo *"
                        id="correo1"
                        name="correo1"
                        onChange={this.onChangeInput}
                        value={this.state.correo1}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Correo alterno"
                        id="correo2"
                        type="email"
                        name="correo2"
                        onChange={this.onChangeInput}
                        value={this.state.correo2}
                      />
                      <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Teléfono *"
                        id="telefono1"
                        type="number"
                        name="telefono1"
                        onChange={this.onChangeInput}
                        value={this.state.telefono1}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Ext."
                        id="ext"
                        type="number"
                        name="ext"
                        onChange={this.onChangeInput}
                        value={this.state.ext}
                      />
                      <div class="input-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Celular"
                        id="telefono2"
                        type="number"
                        name="telefono2"
                        onChange={this.onChangeInput}
                        value={this.state.telefono2}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-mobile"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Puesto"
                        id="puesto"
                        type="text"
                        name="puesto"
                        onChange={this.onChangeInput}
                        value={this.state.puesto}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-address-card"></i>
                      </div>
                    </div>
                    <div class="col-half">
                      <div class="input-group">
                        <select
                          className="browser-default custom-select"
                          type="select"
                          name="tipoContacto"
                          id="tipoContacto"
                          onChange={this.onChangeInput}
                          value={this.state.tipoContacto}
                        >
                          <option value="disable">Tipo de Contacto *</option>
                          <option value="PAGO">Pago</option>
                          <option value="ADMINISTRATIVO">Administrativo</option>
                          <option value="TÉCNICO">Técnico</option>
                        </select>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <button
                        class="button2"
                        onClick={(e) => this.onSubmitBtn(e)}
                      >
                        <span class="text">Agregar</span>
                        <span class="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <button onClick={(e) => this.cerrar()} class="button">
                      <span class="text">Cancelar</span>
                      <span class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
            <Card title={titulo}>
            <MDBRow >           
              <MDBCol size="5">
              <MDBCard style={{ width: "20rem"}}>
                <MDBCardImage className="img-fluid" src="https://images.pexels.com/photos/8062272/pexels-photo-8062272.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" waves />
              </MDBCard>
              </MDBCol>
              <MDBCol size="6" style ={{marginTop:"2%"}}>

              <Form  onSubmit={this.onSubmitBtn}>                
              <Row > 
              <MDBCol md="6">   
                <MDBInput 
                  label="RFC *" 
                  icon="pencil-alt"
                  id="rfc"
                  type="text"
                  name="rfc"
                  onChange={this.onChangeInput}
                  value={this.state.rfc}
                  required
                />
                </MDBCol>
                <MDBCol md="6">
                <MDBInput 
                  label="Razón Social *" 
                  icon="user-tie"	
                  id="razonSocial"
                  type="text"
                  name="razonSocial"
                  onChange={this.onChangeInput}
                  value={this.state.razonSocial}
                  required
                />
                </MDBCol>
                <MDBCol md="6">
                <MDBInput 
                  label="Correo *" 
                  icon="envelope"
                  id="correo"
                  type="email"
                  name="correo"
                  onChange={this.onChangeInput}
                  value={this.state.correo}
                  required
                />
                </MDBCol>
                <MDBCol md="6">
                <MDBInput 
                  label="Telefono *" 
                  icon="phone"
                  id="telefono"
                  type="number"
                  name="telefono"
                  onChange={this.onChangeInput}
                  value={this.state.telefono}
                  required
                />
                </MDBCol> 
                <MDBCol md="6">
                <MDBInput 
                  label="Domicilio Fiscal"
                  icon="building" 	
                  id="domicilioFiscal"
                  type="text"
                  name="domicilioFiscal"
                  onChange={this.onChangeInput}
                  value={this.state.domicilioFiscal}
                  validate                   
                />
                </MDBCol>
                <MDBCol md="6">
                <MDBInput 
                  label="Pagina Web"
                  icon="globe" 		
                  id="paginaWeb"
                  type="text"
                  name="paginaWeb"
                  onChange={this.onChangeInput}
                  value={this.state.paginaWeb}
                  validate                   
                />
                </MDBCol>              
                {/* <MDBCol md="6">
                <MDBInput 
                  label="Contraseña"
                  icon="lock" 		
                  id="contrasena"
                  type="password"
                  name="contrasena"
                  onChange={this.onChangeInput}
                  value={this.state.contrasena}
                  validate 
                  required
                />
                </MDBCol>   */}
                </Row >
                    <div className="text-center">
                      <MDBBtn   color="info"   type="submit"> Guardar</MDBBtn>
                      <MDBBtn  color="danger"   onClick={e=>this.onClear()} >Cancelar</MDBBtn>
                    </div>    
            </Form>
              </MDBCol>           
            </MDBRow>
            </Card> 
            </div>
          </React.Fragment>
    )
}

}export default signupEmpresas