import axios from 'axios'
import React,{Component} from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
 import'bootstrap-css-only/css/bootstrap.min.css'; 
import'mdbreact/dist/css/mdb.css';
import {  MDBRow, MDBCol, MDBInput, MDBBtn, MDBCardBody, MDBView} from 'mdbreact';
import { Form } from 'reactstrap';
import './index.css'
import {API} from '../Graphql/Graphql'
import { Avatar,Card } from 'antd';
import swal from 'sweetalert'

class loginAdminAlfa extends Component{
    constructor(props){
        super(props)
        this.state ={
            user:"",
            pass:"",

        }
    }
     componentWillMount(){
     localStorage.removeItem("tokenCliente")
     localStorage.removeItem("id_cliente")
    localStorage.removeItem("rfc")
    localStorage.removeItem("razonSocial")
    localStorage.removeItem("domicilioFiscal")
    localStorage.removeItem("giroEmpresarial")
    localStorage.removeItem("paginaWeb")
    localStorage.removeItem("correo")
    localStorage.removeItem("nombreRepresantante")
    localStorage.removeItem("apellidosRepresantante")
    localStorage.removeItem("telefono")
    }
    
    onChangeInput =(e)=>{
        const {id,value} = e.target;
        this.setState({
            [id]:value
        })
    }
    
    onSubmitBtn = ()=>{     
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                    loginClientes(data:"${[this.state.user,this.state.pass]}"){
                        id_contacto
                       id_cliente
                       rfc
                       razonSocial  
                       nombreRepresantante
                       apellidosRepresantante
                       tamanoEmpresa   
                       giroEmpresarial  
                       telefono
                       paginaWeb
                       domicilioFiscal 
                       fk_empresa
                       message
                       correo1     
                       token                           
                   } 
                }
                `
            }   
             }).then(response=>{
                let mensaje = response.data.data.loginClientes.message
                let data = response.data.data.loginClientes;       
                if(mensaje ==="login exitoso"){ 
                let rfc = data.rfc
                let id_cliente = data.id_cliente
                var date = new Date();
                var hours = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                var dd = String(date.getDate()).padStart(2, '0');
                var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = date.getFullYear();
                date = mm + '/' + dd + '/' + yyyy;
                var texto = "";
                var ahora=new Date(); 
                var hora=ahora.getHours();
                console.log("hora" , hora)
                let nombre  = data.nombreRepresantante + " " +  data.apellidosRepresantante
                if (hora>=6 && hora<13) {
                    texto="Buenos días";  
                } else if (hora>=13 && hora<21) { 
                    texto="Buenas tardes";
                } else { 
                    texto="Buenas noches";
                }
                swal({
                    text:`${texto} ${nombre} su sesión ha iniciado exitosamente `,
                    buttons: false,
                    icon:"success"
                }); 

                    axios({
                        url:API,
                        method:'post',
                        data:{
                            query:`
                            mutation{
                                transactionClientes(data:"${[rfc,id_cliente,date,hours]}"){
                                   message                                 
                               } 
                            }
                            `
                        }   
                         }).then(datos=>{
                         })
                         .catch(err=>{                            
                             console.log('error',err.response)
                         })
                         localStorage.setItem("id_contacto",data.id_contacto)
                         localStorage.setItem("tokenCliente",data.token)
                         localStorage.setItem("id_cliente",data.id_cliente)
                         localStorage.setItem("nombreRepresantante",data.nombreRepresantante)
                         localStorage.setItem("apellidosRepresantante",data.apellidosRepresantante)
                         localStorage.setItem("rfc",data.rfc)
                         localStorage.setItem("razonSocial",data.razonSocial)
                         localStorage.setItem("domicilioFiscal",data.domicilioFiscal)
                         localStorage.setItem("giroEmpresarial",data.giroEmpresarial)
                         localStorage.setItem("paginaWeb",data.paginaWeb)
                         localStorage.setItem("correo",data.correo)
                         localStorage.setItem("telefono",data.telefono)
                         localStorage.setItem("fk_empresa",data.fk_empresa)
                         this.props.history.push("/dashboardClientes")

                }
                if(mensaje === "usuario y contraseña incorrecto"){
                    alert("usuario y contraseña incorrectos")
                 }else if(mensaje === "sin resultados"){
                    alert("El correo ingresado no fue encontrado")
                 }
             })
             .catch(err=>{
                 console.log('error',err)
                 console.log('error',err.response)
             })
             this.setState({user:""})
             this.setState({pass:""})
    }
  

     render(){
         let titulo = <center><strong><h3>iniciar sesión</h3></strong></center>
         return(
            <React.Fragment>
                <div id="apppages5">
                    <MDBView>
                <div style={{marginTop:"5%", marginLeft:"8%"}} >
                <MDBCol md="5">
                <Card title = {titulo} style={{width:"70%"}}>       
                          
                <MDBCardBody >
                <div className="h5 text-center mb-2">
                    <Avatar size={80} style={{ backgroundColor: '#043D76' }} ><font size="20" >ADS</font></Avatar>     
                </div>                                
                <Form onSubmit={this.onSubmitBtn}>                 
                <MDBRow style={{ marginLeft:"3%"}}>
                    <MDBCol md="11">
                        <MDBInput                          
                        icon="user"
                        id="user"
                        type="email"
                        name="user"
                        onChange={this.onChangeInput}
                        value={this.state.user}
                        required
                        label="ejemplo@gmail.com"
                        />                       
                        <MDBInput                         
                          icon="unlock-alt"
                          id="pass"
                          type="password"
                          name="contrasena"
                          onChange={this.onChangeInput}
                          value={this.state.pass}
                          required                         
                          label="contraseña"    
                        />                        
                        <div className="text-center">
                        <MDBBtn style= {{marginTop:"3%"}} color='success' size="sm" onClick={e=> this.onSubmitBtn()}>
                        iniciar sesión                            
                        </MDBBtn>
                        </div> 
                        <br/> 
                        <center>¿No recuerdas tu contraseña?<a href="/passwordUpdate">Cambiar contraseña</a> </center>               
                    </MDBCol>
                </MDBRow>                             
                </Form> 
                </MDBCardBody>
            </Card>
            </MDBCol>        
            </div>
            </MDBView>
            </div>               
        </React.Fragment>
        )
    }
}
export default loginAdminAlfa