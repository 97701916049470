import axios from 'axios'
import React,{Component} from 'react'
import { MDBBtn,MDBFormInline,MDBIcon } from 'mdbreact';
import { MDBRow, MDBCol} from 'mdbreact';
import {API} from '../Graphql/Graphql'
import MUIDataTable from "mui-datatables";
import titulo1 from  '../imagen/imagent1.jpg';
import { createMuiTheme} from '@material-ui/core/styles';
import { Card,Button,DatePicker,Input,Select,Space,InputNumber } from 'antd';
import { Button as Boton } from 'reactstrap';
import {MDBTable,MDBTableHead,MDBTableBody} from "mdbreact";
import { Paper } from '@material-ui/core';
import { Form, Row,Col,} from "reactstrap";
import { PDFExport } from '@progress/kendo-react-pdf';
import swal from 'sweetalert'
const { Search } = Input;


const PageTemplate = (props) => {
  return (
    <div  style={{position:"absolute",bottom:"10px",width:550}}>
    <center>  
    <p  className = "textabla3" color="black" style = {{marginBottom:10}}>Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México.  <br/>
   Información, soporte y ventas: Conmutador:55 3603 9970 - 55 5553 2049</p>
    </center>
    </div>
  );
};

class comprasCarrito extends Component{
  pdfExportComponent
    constructor(props){
        super(props)
        this.state = {
            numFolio:"",
            datos:[],
            fechaEmision:"",
            fechaExpiracion: "",
            folio:"",
            dataDetalles:[],
            idCotizacion:" ",
            tablaDetalles:false,
            tablaInicio:false,
            tablaProductosAlmacenados:false,
            productos:[],
            // hora:"",
            banco:"",
            referencia:"",
            importe:"",
            capturarFecha:"",           
            tipoPago:"",
            infoClientes:false,
            nombreCliente:"",
            apellidoCliente:"",
            razonSocial:"",
            RFC:"",
            correo1:"",
            telefono1:"",
            puesto:"",
            sumaTotal:"",
            pdfview:false,
            buscadorFolio:true,
            productoSelecto:[],
            Subtotal:"",
            fecha:"",
            capturarDatos:false,
            pdfExport:false,
            fk_contacto:"",
            hora:"",
            time:"",
            selectedDate:new Date(),
            isModalVisible:false,
            array:[],
            busqueda:" ",
            tablaBusqueda:[],
            tablaProductoServicio:[],
        } 
        this.capturarFecha = this.capturarFecha.bind(this);
        this.onChange = this.onChange.bind(this)
        this.handleChange1 = this.handleChange1.bind(this) 
        // this.onChangeTime = this.onChangeTime.bind(this)      
       }   

       componentDidMount(){
        this.setState({tablaBusqueda:this.state.tablaProductoServicio});        
      }   

    onChangeInput =(e)=>{
        const {id,value} = e.target;
        this.setState({
            [id]:value
        })
    }  
    
    toggle = nr => () => {
      let modalNumber = 'modal' + nr
      this.setState({
        [modalNumber]: !this.state[modalNumber]
      });
    }

    handleDateChange = (date) => {
     this.setState({
       selectedDate:date
      });
    };
   
    cerrar(){
      this.setState({tablaInicio:true})
      this.setState({tablaDetalles:false})
      this.setState({tablaProductosAlmacenados:true})
      this.setState({infoClientes:true}) 
      this.setState({pdfview:false})
      this.setState({buscadorFolio:true}) 
      this.setState({capturarDatos:true})    
    }
    cerrarPDF(){
      this.setState({tablaInicio:false})
      this.setState({tablaDetalles:false})
      this.setState({tablaProductosAlmacenados:false})
      this.setState({infoClientes:false}) 
      this.setState({pdfview:false})
      this.setState({buscadorFolio:true}) 
      this.setState({capturarDatos:false}) 
      this.setState({numFolio:''})
      this.setState({razonSocial:''})
      this.setState({RFC:''})
      this.setState({nombreCliente:''}) 
      this.setState({apellidoCliente:''})
      this.setState({puesto:''})
      this.setState({telefono1:''})     
    }

    filtrarElementos  = () => {
      var search = this.state.array.filter((item)=>{
        if(item.consecutivo.toString().includes(this.state.busqueda) || item.concepto.toString().includes(this.state.busqueda)){
          return item
        }
      })
      this.setState({tablaBusqueda: search})
    }
    
    async componentWillMount(){
      let fk_empresa= localStorage.getItem("fk_empresa")
      await axios({
       url:API,
       method:'post',
       data:{
           query:`
           query{
             getTablaProductoServicio(data:"${[fk_empresa]}"){
               id_productoServicio
               tipo
               concepto
               precio
               consecutivo                                    
              } 
           }
           `
       } 
        }).then(response=>{
        this.setState({array:response.data.data.getTablaProductoServicio})
        })
        .catch(err=>{
          console.log("err",err.response)
        })
        localStorage.removeItem("id")
        localStorage.removeItem("tipo")
        localStorage.removeItem("concepto")
        localStorage.removeItem("precio") 
        localStorage.removeItem("consecutivo")
    }

    // onChange = async (e) => {
    //   e.persist();
    //   await this.setState({busqueda:e.target.value})
    //   this.filtrarElementos()
    // }

    handleAddFields = async (id) => {
      this.setState({productoSeleccionado:true})
      this.setState({busqueda:''})
      const values = [...this.state.inputFields];
      let valor2=[];
      values.push({ id });
      values.map(rows=>{
        valor2.push(rows)
        this.setState({Datos:valor2})
      } );
       await this.setState({inputFields:values}) 
      this.capturar()  
    };

   onSubmitBtn = async(e)=>{    
  let array0 = []
  let array1 = []
  let array2 = []
  let array3 = []
  let array4 = []
  let array5 = []
  let array6 = []
  let array7 = [] 
  let array8 = []
  let array9 = []
  let array10 = []
  let activacion=[]
  let IVA= .16;  
  let calculoIVAProducto=1.16
  let fecha= this.state.fecha
  let banco = this.state.banco.replace(/,/g, "");
  let referencia = this.state.referencia;
  let importe = this.state.importe;
  let tipoPago = this.state.tipoPago;
  let NumFolio = this.state.folio +"ADS"
  let fk_empresa = localStorage.getItem("fk_empresa")
  let fk_contacto = this.state.fk_contacto
  let fk_admin = localStorage.getItem("id_admin")
  let cantidad;
  let descuento;
  let descuentoAplicado;
  let TotalPrecioProducto;
  let fk_productoServicio;
  let fk_cliente;
  let fk_adminalfa;
  let concepto;
  let poliza;  
  let horas;  
  let ProductoPrecioUnitario
  let calculodeIVAMoreTotalPrecio
  let calIVA
  let statusPoliza 
  let fechaEmisionVenta = new Date();
if(this.state.time){
  horas = this.state.time  + " " + "horas"
}else{
  horas = " "
}

let productos = this.state.productos
  productos.map(rows=>{   
   array0.push({"cantidad":rows.id.cantidad})
  })
  productos.map(rows=>{   
    array1.push({"descuento":rows.id.descuento})
  })
  productos.map(rows=>{   
    array2.push({"descuentoAplicado":rows.id.descuentoAplicado})
  })
  productos.map(rows=>{   
    array3.push({"id_productoServicio": rows.id.fk_productoServicio})
  })
  productos.map(rows=>{   
    array4.push({"fk_cliente":rows.id.fk_cliente})
  })
  productos.map(rows=>{   
    array5.push({"fk_adminalfa":rows.id.fk_adminalfa})
  })
  productos.map(rows =>{
     array6.push({"TotalPrecioProducto":rows.id.TotalPrecioProducto})
  })
  productos.map(rows=>{
    array7.push({"Precio":rows.id.precio}) 
  })
  productos.map(rows=>{   
    array8.push({"TotalPrecioProducto":rows.id.TotalPrecioProducto})
    let TprecioP = rows.id.TotalPrecioProducto
    calIVA =((calculoIVAProducto)*(TprecioP)).toFixed(2)
    array9.push({"calculodeIVA":calIVA})  
  })
  
  productos.map(rows =>{
    array10.push({"concepto":rows.id.concepto})
 }) 

productos.map(rows =>{
  
  //este es id_productoServicio 1145,1215,1225,1615 de fk_empresa
 if(rows.id.fk_productoServicio === "955" || rows.id.fk_productoServicio === "965" || rows.id.fk_productoServicio === "975" || rows.id.fk_productoServicio === "985" || 
  rows.id.fk_productoServicio ==="995" || rows.id.fk_productoServicio ==="1005" ||rows.id.fk_productoServicio === "1015"|| rows.id.fk_productoServicio === "1025" ||
  rows.id.fk_productoServicio === "1035" || rows.id.fk_productoServicio === "1115" ||  rows.id.fk_productoServicio === "1125" || rows.id.fk_productoServicio === "1135" ||
  rows.id.fk_productoServicio ==="1335" ||  rows.id.fk_productoServicio ==="1345" || rows.id.fk_productoServicio === "1355" || rows.id.fk_productoServicio ==="1365" ||
  rows.id.fk_productoServicio === "1375" || rows.id.fk_productoServicio === "1385" || rows.id.fk_productoServicio ==="1395" || rows.id.fk_productoServicio === "1225" ||
   rows.id.fk_productoServicio ==="1145" || rows.id.fk_productoServicio === "1215" || rows.id.fk_productoServicio === "1615"){
 let valor1 = "true";
 activacion.push({"filtroLicencia":valor1})
 }else{
   let valor2 = "false";
   activacion.push({"filtroLicencia":valor2})
 }
})


  let suma = 0;  
        for (let i = 0; i < array6.length; i++) {
          suma += parseFloat(array6[i].TotalPrecioProducto);    
      }
  let sumaDosCeros =  suma.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
  let calcularIVA =((suma) * (IVA)).toFixed(2) 
  let sumarValor=suma + parseFloat(calcularIVA)        
  let sumarValorDosCeros = sumarValor.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 

  let arr = array0.map((item,i) => Object.assign({}, item, array1[i]));
      let arr1 = array2.map((item,i) => Object.assign({}, item, arr[i]));
      let arr2 = array3.map((item,i) => Object.assign({}, item, arr1[i]));
      let arr3 = array4.map((item,i) => Object.assign({}, item, arr2[i]));
      let arr4 = array5.map((item,i) => Object.assign({}, item, arr3[i]));
      let arr5 = array6.map((item,i) => Object.assign({}, item, arr4[i]));
      let arr6 = array7.map((item,i) => Object.assign({}, item, arr5[i])); 
      let arr7 = array8.map((item,i) => Object.assign({}, item, arr6[i]));
      let arr8 = array9.map((item,i) => Object.assign({}, item, arr7[i]));
      let arr9 = array10.map((item,i) => Object.assign({}, item, arr8[i]));
      let arr10 = activacion.map((item,i) => Object.assign({}, item, arr9[i]));
     

   
if(fecha  && banco  && tipoPago && importe){
   arr10.map(rows=>{ 
    cantidad = rows.cantidad  
    descuento= rows.descuento
    descuentoAplicado = rows.descuentoAplicado
    TotalPrecioProducto = rows.TotalPrecioProducto
    fk_productoServicio = rows.id_productoServicio
    fk_cliente = rows.fk_cliente
    ProductoPrecioUnitario = rows.Precio
    statusPoliza = rows.filtroLicencia
    calculodeIVAMoreTotalPrecio = rows.calculodeIVA
    axios({
        url:API,
        method:'post',
        data:{
            query:`
            mutation{              
              ventas(data:"${[NumFolio,cantidad,descuento,descuentoAplicado,TotalPrecioProducto,ProductoPrecioUnitario,calculodeIVAMoreTotalPrecio,fecha,horas,banco,referencia,tipoPago,importe,fechaEmisionVenta,statusPoliza,fk_productoServicio,fk_cliente,fk_admin,fk_empresa,fk_contacto]}"){
                 message
                } 
            }
            `
        }              
      }).then(response=>{
        }).catch(err=>{
          console.log('error',err.response)  
      }) 
      })

      axios({
        url:API,
        method:'post',
        data:{
            query:`
            mutation{
            insertTotalesVenta(data:"${[sumaDosCeros,calcularIVA,sumarValorDosCeros,NumFolio]}"){
                 message
                } 
            }
            `
        }   
        }).then(response=>{  
          swal({
            text:"Registro exitoso",
            buttons: false,
            icon:'success'
          });          
        }).catch(err=>{
            console.log('error',err)    
        })  
  
        let filtro  = productos.filter(function(param){
          //este es id_productoServicio 1145,1215,1225, de fk_empresa
          let idProd = param.id.fk_productoServicio
          return  idProd === "955" || idProd === "965" || idProd === "975" || idProd === "985" || idProd === "995" || idProd === "1005" || idProd === "1015" || idProd === "1025" || 
          idProd === "1035" || idProd === "1115" || idProd === "1125" || idProd === "1135" || idProd === "1335" || idProd === "1345" || idProd === "1355" || idProd === "1365" || 
          idProd === "1375" || idProd === "1385" || idProd === "1395" || idProd === "1215" || idProd === "1145" || idProd === "1225" || idProd === "1615" 
        })

        filtro.map(rows=>{          
              axios({
              url:API,
              method:'post',
              data:{
                  query:`
                  mutation{
                  registerPoliza(data:"${["no vigente","no asignado","inactiva",rows.id.fk_productoServicio,rows.id.fk_cliente,rows.id.fk_contacto]}"){
                      message
                      } 
                  }
                  `
              }   
              }).then(response=>{  
                    
              }).catch(err=>{
                  console.log('error',err)    
              })      
        })

        this.setState({pdfview:true})
        this.setState({buscadorFolio:false})    
        this.setState({tablaInicio:false})
        this.setState({tablaDetalles:false})
        this.setState({tablaProductosAlmacenados:false})
        this.setState({infoClientes:false}) 
        this.setState({capturarDatos:false})
        this.setState({pdfExport:true})      
    }else{
      swal({
        text:"Complete los campos de Fecha, Hora, Banco, Número de referencia, importe, tipo de pago",
        buttons: false,
        icon:'warning'
      });
      } 
  }

getMuiTheme = () =>
createMuiTheme({
  overrides: {

    MUIDataTable: {
      root: {
        backgroundColor: '#AAF',
      },
      responsiveScroll: {
        maxHeight: "580px"
        // overflowY: 'scroll',
      },
      paper: {
        boxShadow: 'none',
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#f00',
      },
    },
    MuiTableCell: {
      head: {
        color: 'white',
        backgroundColor:'#fffff !important'
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: 'blue',
      },
    },
    MuiTableFooter: {
      root: {
        '& .MuiToolbar-root': {
          backgroundColor: 'white',
        },
      },
    },
    
  },
});   
  

consultarDatos = async()=>{    
    let folio=this.state.numFolio;
    await axios({
      url:API,
      method:'post',
      data:{
          query:`
          query{
            getCotizacionByFolio(data:"${[folio]}"){
              id_cotizaciones
              fechaEmision
              NumFolio
              cantidad
              descuento
              descuentoAplicado
              TotalPrecioProducto
              fk_cliente
              fk_productoServicio
              fk_adminalfa
              fk_empresa   
              fechaExpiracion 
              fk_contacto
              tipoSolicitud
              id_productoServicio
              tipo
              tipoLicenciamiento
              LineaProducto
              concepto
              precio
              consecutivo
              id_contacto
              nombre
              apellidos
              correo1
              correo2
              telefono1
              extensionTelefonica
              telefono2
              puesto 
              razonSocial
              rfc
             } 
          }
          `
      }   
       }).then(response=>{         
         if(response.data.data.getCotizacionByFolio[0]){  
         let dataCotizacion=response.data.data.getCotizacionByFolio
         function getUniqueListBy(arr,key){          
             return[...new Map(arr.map(item =>[item[key],item])).values()]
         }  
         const array = getUniqueListBy(dataCotizacion,'dataCotizacion')   
         this.setState({ datos:dataCotizacion})
         this.setState({nombreCliente:array[0].nombre})
         this.setState({apellidoCliente:array[0].apellidos})
         this.setState({razonSocial:array[0].razonSocial})
         this.setState({RFC:array[0].rfc})
         this.setState({correo1:array[0].correo1})
         this.setState({telefono1:array[0].telefono1})
         this.setState({puesto:array[0].puesto})
         this.setState({fechaEmision:array[0].fechaEmision})    
         this.setState({fechaExpiracion:array[0].fechaExpiracion})
         this.setState({folio:array[0].NumFolio})  
         this.setState({fk_contacto:array[0].fk_contacto})
         this.setState({tablaInicio:true})  
         this.setState({tablaDetalles:false})
         this.setState({tablaProductosAlmacenados:true}) 
         this.setState({infoClientes:true}) 
         this.setState({pdfview:false})  
         this.setState({buscadorFolio:true})    
         this.setState({capturarDatos:true})     
     }else{
      swal({
        text:"El número de folio no fue encontrado",
        buttons: false,
        icon:'error'
      });
      this.setState({nombreCliente:''})
      this.setState({apellidoCliente:''})
      this.setState({razonSocial:''})
      this.setState({RFC:''})
      this.setState({telefono1:''})
      this.setState({puesto:''})
      this.setState({tablaInicio:false})  
      this.setState({infoClientes:false}) 
      this.setState({tablaProductosAlmacenados:false})
      this.setState({capturarDatos:false})      
     }
      })       
       .catch(err=>{
           console.log('error',err)
       })         
  } 

  getDetalles(id){   
    this.setState({idCotizacion:id})
    this.setState({buscadorFolio:false})
    this.setState({tablaInicio:false})
    this.setState({tablaDetalles:true})
    this.setState({tablaProductosAlmacenados:false})
    this.setState({infoClientes:false}) 
    this.setState({pdfview:false}) 
    this.setState({buscadorFolio:false})
    this.setState({capturarDatos:false})
  }
  
  agregarProducto(id){  
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
    let idCotizaciones = id.id_cotizaciones;
    const values = [...this.state.productos];   
    values.push( {id} );
   
    let productos = this.state.productos
    let filtrado = productos.filter(function(param){
      let idCotizacion = param.id.id_cotizaciones     
      return idCotizacion === idCotizaciones       
    }) 
    if(filtrado[0]){
      swal({
        text:"El producto ya fue agregado",
        buttons: false,
        icon:'warning'
      }); 
    }else{
      this.setState({productos:values}) 
    }  
    

  }
  capturarFecha(e){
    if(e){  
    let fechaInicio = e._d.toString();   
      this.setState({fecha:fechaInicio})                       
    }
  } 
 
   onChange(value) {    
    this.setState({importe:value})
  }
  handleChange1(value) { 
    this.setState({tipoPago:value}) 
    }

    render(){
      const option ={ 
        elevation:0,
        filterType:"drowpdawn",
        responsive: "stacked",
        responsive:"standard",
        pagination:false,
        search:false,
        print:false,
        download:false,
        filter:false,
        caseSensitive:false,
        selectableRows:"none",
        viewColumns:false,      
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      
      }            
      } 
     
      const { Search } = Input;
      const { Option } = Select;
      const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
      let fechaEmision;
      let fechaVigencia;
      let folio;
      let tablaDetalles;
      let searchFolio;
      let datosCliente;
      let pdf; 
      let numFolio= this.state.numFolio   
      let productosAlmacenados;
      let column;
      let data;
      let productos;
      let detalles;
      let agregar;      
      let capturarDatos; 
      let dataProductos;
      let tabla;
      let tablaProductos;
     
     
    const fecha = new Date();    
    var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
    let dia=fecha.getDate()
    let mesSubstring = fecha.getMonth() +1
    let posicionMes = parseInt(mesSubstring, 10)
    let mes  = meses[posicionMes - 1] 
    let año = fecha.getFullYear()  
 
  //  *****************DATOS BANCARIOS******************
  let banco = this.state.banco
  let referencia = this.state.referencia
  let fechaPago = this.state.fecha
  let tipoPago = this.state.tipoPago
  let importe = this.state.importe     
  let horas; 
 
  

if(this.state.time){
  horas = this.state.time  + " " + "horas"
}else{
  horas = " "
}

  // ****************cliente****************
  let razonSocialEmpresaContacto = this.state.razonSocial    
  let RFCEmpresaContacto = this.state.RFC
  let NombreContacto = this.state.nombreCliente
  let ApellidosContacto = this.state.apellidoCliente
  let PuestoContacto = this.state.puesto
  let telefonoContacto = this.state.telefono1
  let correoContacto = this.state.correo1
 

  // ***************Provedor*****************
  let razonSocialEmpresaProvedor = localStorage.getItem ("razonSocialEmpresa")
  let nombreProvedor = localStorage.getItem("nombre")
  let apellidoProvedor = localStorage.getItem("apellido")
   let puestoProvedor = localStorage.getItem("puesto")
   let correoProvedor = localStorage.getItem("correo")
   let telefonoProvedor = localStorage.getItem("telefono")
   let extensionTelefonicaProvedor = localStorage.getItem("extensionTelefonica")
  
  // ***********Fecha de Deposito*****************
  const fechaDeposito = new Date(fechaPago);
    var letrasMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
    let diaDeposito = fechaDeposito.getDate()
    let letraMesSubstring = fechaDeposito.getMonth() +1
    let numeroPosicionMes = parseInt(letraMesSubstring, 10)
    let mesDeposito  = letrasMeses[numeroPosicionMes - 1] 
    let añoDeposito = fechaDeposito.getFullYear()  
// *************************************  

        if(this.state.fechaEmision && this.state.fechaExpiracion){
           fechaEmision  = this.state.fechaEmision.substring(0,10);
           fechaVigencia = this.state.fechaExpiracion.substring(3,15);
        }  

        searchFolio=   
                <MDBFormInline className="md-form mr-auto-sm " style={{ display:"flex", alignItems:"baseline"}}>
                  <input className="form-control form-control-sm"  style={{width:230}} type="text" id="numFolio" value={this.state.numFolio} name="numFolio"  onChange={this.onChangeInput}   placeholder="Folio de Cotización"/>
                    <MDBBtn outline color="warning" rounded size="sm" className="mr-auto" onClick={(e)=> this.consultarDatos()}>                        
                    <i class="fas fa-search"></i>
                    </MDBBtn>
                </MDBFormInline>             
        
        if(this.state.buscadorFolio === true){          
              datosCliente =
              <Card title={searchFolio}
               bordered={true} style={{ width:"90%",marginLeft:"5%",marginTop:"1%" }} 
               extra={<div ><strong>{razonSocialEmpresaContacto}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>{RFCEmpresaContacto}</strong></div>}
               >            
               <Col>
                <center><p><strong>{NombreContacto +" " + ApellidosContacto}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>{PuestoContacto}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>{telefonoContacto}</strong></p></center>
                </Col>                 
              </Card>          
         }
         if(this.state.capturarDatos === true){
           capturarDatos =
           <Card style={{ width:"90%",marginLeft:"5%" }}>
             <Form onSubmit={this.onSubmitBtn}>
            <Row>    
            <Col xs={4} md={4}>
              <label><strong>Fecha de Deposito *</strong></label>
                <br></br>
                 <Space  >
                  <DatePicker 
                  onChange={this.capturarFecha} 
                  // defaultValue={moment(new Date(), dateFormatList[0])}
                  placeholder="Fecha"
                   format={dateFormatList} />                    
                 </Space>
              </Col>              
              <Col xs={4} md={4}>
              <label><strong>Hora</strong></label>   
              <br></br>   
              <input
               id="time"
               type="time"
               name="time"
               onChange={this.onChangeInput}
               value={this.state.time}
               style={{width:150, height:32}}
               >
              </input>
              </Col>             
              <Col xs={4} md={4}>
              <label><strong>Banco/Entidad *</strong></label>
                    <input 
                        class="form-control form-control-sm" 
                        list="datalistOptions" 
                        type="select"
                        name="banco"
                        id="banco"
                        onChange={this.onChangeInput}
                        value={this.state.banco} 
                        placeholder="Seleccione su banco..."                  
                        >
                    </input>
                <datalist id="datalistOptions">
                        <option value="(BANAMEX) Banco Nacional de México, S.A."></option>
                        <option value="(SERFIN) Banca Serfin, S.A."></option>
                        <option value="(ATLÁNTICO) Banco del Atlántico, S.A."></option>
                        <option value="(CITIBANK) Citibank México, S.A."></option>
                        <option value="(UNIÓN) Banco Unión, S.A."></option>
                        <option value="(CONFÍA) Confía, S.A."></option>
                        <option value="(BBVA BANCOMER) BBVA Bancomer, S.A."></option>
                        <option value="(INDUSTRIAL) Banco Industrial, S.A."></option>
                        <option value="(SANTANDER) Banco Santander (México), S.A."></option>
                        <option value="(INTERBANCO) Banco Interestatal, S.A."></option>
                        <option value="(BBVA SERVICIOS) BBVA Bancomer Servicos, S.A."></option>
                        <option value="(HSBC) HSBC México, S.A."></option>
                        <option value="(GE MONEY) GE Money Bank S.A."></option>
                        <option value="(SURESTE) Banco del Sureste, S. A."></option>
                        <option value="(CAPITAL) Banco Capital, S.A."></option>
                        <option value="(BAJÍO) Banco del Bajío, S.A."></option>
                        <option value="(IXE) Ixe Banco, S.A."></option>
                        <option value="(INBURSA) Banco Inbursa, S.A."></option>
                        <option value="(INTERACCIONES) Banco Interacciones, S.A."></option>
                        <option value="(MIFEL) Banca Mifel, S.A."></option>
                        <option value="(SCOTIABANK INVERLAT) Scotiabank Inverlat, S.A."></option>
                        <option value="(PRONORTE) Banco Promotor del Norte, S.A."></option>
                        <option value="(QUADRUM) Banca Quadrum, S.A."></option>
                        <option value="(BANREGIO) Banco Regional de Monterrey, S.A."></option>
                        <option value="(INVEX) Banco Invex, S.A."></option>
                        <option value="(BANSI) Bansi, S.A"></option>
                        <option value="(AFIRME) Banca Afirme, S.A"></option>
                        <option value="(ANÁHUAC) Banco Anáhuac, S.A."></option>
                        <option value="(PROMEX) Banca Promex, S.A"></option>
                        <option value="(BANPAÍS) Banpaís, S.A."></option>
                        <option value="(BANORTE/IXE) Banco Mercantil del Norte, S.A."></option>
                        <option value="(ORIENTE) Banco de Oriente, S.A."></option>
                        <option value="(BANCEN) Banco del Centro, S. A."></option>
                        <option value="(CREMI) Banca Cremi, S.A."></option>
                        <option value="(INVESTA BANK) Investa Bank, S.A."></option>
                        <option value="(AMERICAN EXPRESS) American Express Bank (México), S.A."></option>
                        <option value="(SANTANDER) Banco Santander de Negocios México, S.A"></option>
                        <option value="(BAMSA) Bank of America México, S.A."></option>
                        <option value="(BOSTON) BankBoston, S.A."></option>
                        <option value="(TOKYO) Bank of Tokyo-Mitsubishi UFJ (México), S.A."></option>
                        <option value="(BNP) BNP (México), S.A."></option>
                        <option value="(JP MORGAN) Banco JP Morgan, S.A."></option>
                        <option value="(MONEX) Banco Monex, S.A."> </option>
                        <option value="(VE POR MÁS) Banco Ve por Más, S.A."></option>
                        <option value="(BANK ONE) Bank One(México), S.A."></option>
                        <option value="(FUJI) Fuji Bank (México), S.A."></option>
                        <option value="(ING) ING Bank (México), S.A."></option>
                        <option value="(NATIONSBANK) Nationsbank de México, S.A."></option>
                        <option value="(REPUBLIC NY)HSBC Bank México, S.A."></option>
                        <option value="(SOCIÉTÉ) Société Générale México, S.A"></option>
                        <option value="(DEUTSCHE) Deutsche Bank México, S.A."></option>
                        <option value="(Credit Suisse First Boston) Banco Credit Suisse (México), S.A."></option>
                        <option value="(AZTECA) Banco Azteca, S.A."></option>
                        <option value="(AUTOFIN) Banco Autofin México, S.A."></option>
                        <option value="(BARCLAYS) Barclays Bank México, S.A."></option>
                        <option value="(COMPARTAMOS) Banco Compartamos, S.A."></option>
                        <option value="(BANCO FAMSA) Banco Ahorro Famsa, S.A."></option>
                        <option value="(BANCO MULTIVA) Banco Multiva, S.A."></option>
                        <option value="(BM ACTINVER) Banco Actinver, S.A"></option>
                        <option value="(WAL-MART) Banco Wal-Mart de México Adelante, S.A."></option>
                        <option value="(INTERCAM BANCO) Intercam Banco, S.A."></option>
                        <option value="(BANCOPPEL) BanCoppel, S.A."></option>
                        <option value="(ABC CAPITAL) ABC CAPITAL"></option>
                        <option value="(UBS BANK) UBS Bank México, S.A"></option>
                        <option value="(CONSUBANCO) Consubanco, S.A."></option>
                        <option value="(VOLKSWAGEN) Volkswagen Bank, S.A."></option>
                        <option value="(-) El Banco Deuno, S.A"></option>
                        <option value="(CIBANCO) CIBanco, S.A."></option>
                        <option value="(BANK NEW YORK) The Bank of New York Mellon, S.A."></option>
                        <option value="(BM BASE) Banco Base, S.A."></option>
                        <option value="(BICENTENARIO) Banco Bicentenario, S.A."></option>
                        <option value="(BANKAOOL) Bankaool, S.A."></option>
                        <option value="(PAGATODO) Banco Pagatodo, S.A."></option>
                        <option value="(FORJADORES) Banco Forjadores, S.A."></option>
                        <option value="(INMOBILIARIO) Banco Inmobiliario Mexicano, S.A."></option>
                        <option value="(DONDÉ) Fundación Dondé Banco, S.A."></option>
                        <option value="(BANCREA) Banco Bancrea, S.A."></option>
                        <option value="(CHIHUAHUA) Banco Progreso Chihuahua, S.A."></option>
                        <option value="(FINTERRA) Banco Finterra, S.A."></option>
                        <option value="(BANK OF CHINA) Industrial and Commercial Bank of China México, S.A."></option>
                        <option value="(Bancrecer, S.A) Bancrecer, S.A."></option>
                        <option value="(OBRERO) Banco Obrero, S.A."></option>
                  </datalist>
              </Col>
            
            </Row>
            <br></br>
            <Row>
            <Col xs={4} md={4}>
              <label><strong>Número de referencia</strong></label>  
                  <br/>             
                  <Input                                               
                    name = "referencia"
                    id= "referencia"
                    placeholder = "Número de referencía"                    
                    className="textField"
                    value = {this.state.referencia}
                    onChange={this.onChangeInput}                    
                    />
              </Col>
             
              <Col xs={4} md={4}>
              <label><strong>Importe *</strong></label> 
                <br></br>
                  <Space>
                    <InputNumber
                      defaultValue={1000}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value  => value.toString().replace(/\$\s?|(,*)/g, '')}
                      onChange={this.onChange}
                      size="middle"  
                      style ={{ width: 180 }}                        
                    />
                    </Space>
              </Col> 
              <Col xs={4} md={4}>
              <label><strong>Tipo de pago *</strong></label>    
                 <br></br>               
                  <Select                          
                    style={{ width: 220, }}
                    onChange={this.handleChange1}                          
                    placeholder="Tipo de pago"
                    size="middle"
                    >                         
                    <Option value="TRANSFERENCIA">Transferencia</Option>
                    <Option value="DEPOSITO EN VENTANILLA">Deposito en ventanilla</Option>
                    <Option value="PAGO CON TARJETA DE CREDITO">Pago con Tarjeta de credito</Option>                         
                  </Select>
              </Col>              
            </Row>            
            <br></br>  
            <Row>
            <Col  xs={4} md={4}>
            {/* <label><strong>Buscar Producto</strong></label>
            <br></br> 
              <Search 
                type="text"            
                name = "busqueda"
                placeholder = "Buscar clave del producto"
                required
                className="textField"
                value = {this.state.busqueda}
                onChange ={this.onChange}
                style={{ width: 250}}
                />    */}
              </Col> 
            </Row>            
          </Form>
         </Card>
       }
      
       
      
      

         if(this.state.tablaInicio === true){
          column = [ "Concepto","Cantidad","Precio u.","Descuento Aplicado","Total","Agregar","Detalles"];  
        if(this.state.datos){            
          data= this.state.datos.map((rows,index)=>{   
            detalles = <MDBBtn size = "sm"  color ="warning" className="text-white" onClick={e=>this.getDetalles(rows)}>Detalles</MDBBtn>
            agregar = <Button type="success" shape="circle" size="large"  onClick={e=>this.agregarProducto(rows)}><MDBIcon icon="plus"/></Button>
            return([rows.concepto,rows.cantidad,"$"+rows.precio,rows.descuento+"%","$"+rows.TotalPrecioProducto,agregar,detalles])
          })
        }   
        
        if(this.state.busqueda){
          const columns = ["Id_ProductoServicio", "Tipo", "Concepto", "Precio U.", "Consecutivo","Agregar"];
          dataProductos= this.state.tablaBusqueda.map((rows,i)=>{
            let boton = <Button shape="circle" size="large" type="success" onClick={(e) => this.handleAddFields(rows.id_productoServicio)}><i class="fas fa-plus"></i>    </Button>
       
            return([rows.id_productoServicio,rows.tipo,rows.concepto,"$" + rows.precio,rows.consecutivo,boton])
          })

          let tituloCatalogo = <h6><strong>Catálogo de producto y servicio</strong></h6>
          tabla= 
             
          <Card title = {tituloCatalogo} style={{ width:"98%", marginLeft:"1%", marginTop:"2%"}}>  
          {/* <div style={{width:"90%",marginTop:"2%"}}>     */}
            <MUIDataTable  
              data={dataProductos}
              columns={columns} 
              options={option}                     
            /> 
          </Card>    
        }

        let titulo1 = <h6><strong>Cotización Generada</strong></h6>        
        productos =        
        <center>
        <Card  title={titulo1} bordered={true} style={{ width:"90%", marginTop:"2%"}} extra = {<div><h6><strong>Folio:</strong>&nbsp;&nbsp;&nbsp;&nbsp;{numFolio}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Emisión:</strong>&nbsp;{fechaEmision}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Vencimiento:</strong>&nbsp;{fechaVigencia}</h6></div> }> 
        <div style={{width:"95%",marginLeft:"5%"}}> 
        <MUIDataTable  
        data={data}
        columns={column} 
        options={option}                     
        /> 
        </div>        
        </Card>
        </center>  
        }

        if(this.state.tablaDetalles === true){  
          if(this.state.idCotizacion){ 
          let detallesid = this.state.idCotizacion 
          let titulo1 = <h6><strong>informacion de cotización</strong></h6>

            tablaDetalles=
            <center>              
            <Card style={{width:"800px",marginTop:"1%"}} title={titulo1} >
            <Boton close  style={{color:"red"}}  onClick={e=>this.cerrar()}/>
         <table className="table table-bordered">
           <tr>
             <td>Tipo</td>
             <td>{detallesid.tipo}</td>
           </tr>
           <tr>
             <td>Concepto</td>
             <td>{detallesid.concepto}</td>
           </tr>
           <tr>
             <td>Tipo Licenciamiento</td>
             <td>{detallesid.tipoLicenciamiento || "Sin datos"}</td>
           </tr>
           <tr>
             <td>tipo de solicitud</td>
             <td>{ detallesid.tipoSolicitud|| "Sin datos"}</td>
           </tr>
           <tr>
             <td>Clave</td>
             <td>{ detallesid.consecutivo || "Sin datos"}</td>
           </tr>
           <tr>
             <td> Linea de Productos</td>
             <td>{ detallesid.LineaProducto || "Sin datos"}</td>
           </tr>
           <tr>
             <td > Precio</td>
             <td>{"$"+ detallesid.precio || "Sin datos"}</td>
           </tr>
         </table> 
        </Card>
            </center>  
        }
      }

     if(this.state.tablaProductosAlmacenados === true){        
        if(this.state.productos[0]){        
          let array=[];
          let SumaSubtotal;
          let totalIVA; 
          let sumaTotal;         
          productosAlmacenados = <div >
           <center> 
            <Button style={{marginBottom:"1%",marginTop:"1%",width:"10%"}} type="danger" onClick={e=>{this.setState({productos:[]})}}>Cancelar</Button> &nbsp;&nbsp;&nbsp;
            <Button style={{marginBottom:"1%",marginTop:"1%",width:"10%"}}  type="primary" onClick={e=>{this.onSubmitBtn()}}>Aceptar</Button> 
            <MDBTable small style={{width:"80%",marginTop:"1%"}}>
              <MDBTableHead>
           
              <tr>
                <td align="center">Concepto</td>               
                <td align="left">Tipo</td>
                <td align="center">Tipo de licencia</td>
                <td align="center">Total</td>
              </tr>
              
              {this.state.productos.map(rows=>{     
                        
                let totalDosCeros;
                let total;
                let sumaDosCeros;
                let suma;
                let tofixed2
                let sumarValor;
                let sumarValorDosCeros;
                let sumIVA;
                array.push(rows.id.TotalPrecioProducto)
                let sum = 0;

                for (let i = 0; i < array.length; i++) {
                    sum += parseFloat(array[i]);
                }         
                     
                totalDosCeros = rows.id.TotalPrecioProducto.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
                total = totalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
               
                tofixed2=sum.toFixed(2)
                sumaDosCeros = tofixed2.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
                suma = sumaDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  SumaSubtotal=suma
              
                let IVA= .16
                let calcularIVA =((sum) * (IVA)).toFixed(2)                    
                totalIVA=calcularIVA

               sumarValor=sum + parseFloat(calcularIVA)
               sumarValorDosCeros =sumarValor.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
               sumIVA = sumarValorDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
                sumaTotal=sumIVA   
                
                return(
                  <tr>
                  <td align="left">{rows.id.concepto}</td>
                  <td align="left">{rows.id.tipo}</td>
                  <td align="center">{rows.id.tipoLicenciamiento}</td>
                  <td align="right">{total}</td>                
                </tr>              
                             
                )})
              }
              <tr>
                <td></td>     
                <td></td>               
                <td align="right">Subtotal:</td>
                <td align="right">{SumaSubtotal}</td>                
              </tr>  
              <tr>
                <td></td>     
                <td></td>               
                <td align="right">IVA:</td>
                <td align="right">{totalIVA}</td>                
              </tr> 
              <tr>
                <td ></td>     
                <td ></td>               
                <td align="right" ><strong>Total:</strong></td>
                <td align="right">{sumaTotal}</td>                
              </tr>                  
            
               </MDBTableHead>         
            </MDBTable> 
            </center>
          </div>
        }
      }     
      
 if(this.state.pdfview === true){  
   
   let tablaPDF;
  
     if(this.state.productos){
       let array=[];   
       let Subtotal;
       let totalIVA;
       let sumaTotal
     
      tablaPDF= 
      <div style={{width:"95%",marginTop:"5%"}}>    
     
      <MDBTable striped small>
      <MDBTableHead>
        <tr>
          <th width="10%" bgcolor="#05387A" className="text-white textabla10Center">Producto</th>
          <th width="42%" bgcolor="#05387A" className="text-white textabla10Center">Descripción</th>
          <th width="6%" bgcolor="#05387A" className="text-white textabla10Center">QTY</th>
          <th width="8%" bgcolor="#05387A" className="text-white textabla10Center">Precio</th>
          <th width="8%" bgcolor="#05387A" className="text-white textabla10Center">%</th>
          <th width="8%" bgcolor="#05387A" className="text-white textabla10Center">Descuento</th>
          <th width="8%" bgcolor="#05387A" className="text-white textabla10Center">Total</th>
        </tr>
      </MDBTableHead>     
      <MDBTableBody small>
      {this.state.productos.map(rows=>{
        let precioDosCeros = rows.id.precio.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2})         
        let precio =  precioDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        let descuentoAplicadoDosCeros = rows.id.descuentoAplicado.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2})         
        let descuentoAplicado =  descuentoAplicadoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        let TotalPrecioProductoDosCeros = rows.id.TotalPrecioProducto.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2})         
        let TotalPrecioProducto =  TotalPrecioProductoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")        
        array.push(rows.id.TotalPrecioProducto)
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
            sum += parseFloat(array[i]);
        }   
        let sumaDosCeros = sum.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2})         
        let subTotal =  sumaDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
         Subtotal=subTotal

        let IVA= .16
        let calcularIVA =((sum) * (IVA)).toFixed(2)                    
        totalIVA=calcularIVA

        let sumarValor=sum + parseFloat(calcularIVA)
        let sumarValorDosCeros =sumarValor.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let sumIVA = sumarValorDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
         sumaTotal=sumIVA 

        return(
        <tr>
          <td className="textabla10" align="left">{rows.id.tipo}</td>
          <td className="textabla10" align="left">{rows.id.concepto}</td>
          <td className="textabla10" align="center">{rows.id.cantidad}</td>
          <td className="textabla10" align="right">{precio}</td>
          <td className="textabla10" align="center">{rows.id.descuento+"%"}</td>
          <td className="textabla10" align="right">{descuentoAplicado}</td>
          <td className="textabla10" align="right">{TotalPrecioProducto}</td>
        </tr>        
       ) })}       
      </MDBTableBody> 
   
           <tr>
             <td width="10%" style={{padding:"2px"}} align="center"></td>
             <td width="42%" style={{padding:"2px"}} align="center"></td>
             <td width="6%" style={{padding:"2px"}} align="center"></td>
             <td width="8%" style={{padding:"2px"}} align="center"></td>
             <td width="8%" style={{padding:"2px"}} align="center"></td>                       
             <td width="8%" style={{padding:"2px"}} color="#D6DBDF" align="left">Subtotal</td>
             <td width="8%"style={{padding:"2px"}}  color="#D6DBDF" align="right">$&nbsp;&nbsp;&nbsp;&nbsp;{Subtotal}</td>
           </tr>
           <tr>
             <td width="10%" style={{padding:"2px"}} align="center"></td>
             <td width="42%" style={{padding:"2px"}} align="center"></td>
             <td width="6%" style={{padding:"2px"}} align="center"></td>
             <td width="8%" style={{padding:"2px"}} align="center"></td>
             <td width="8%" style={{padding:"2px"}} align="center"></td> 
             <td width="8%" style={{padding:"2px"}} bgcolor="#D6DBDF" align="left">IVA</td>
             <td width="8%" style={{padding:"2px"}} bgcolor="#D6DBDF" align="right">$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{totalIVA}</td>
           </tr>
           <tr>
             <td width="10%" style={{padding:"2px"}} align="center"></td>
             <td width="42%" style={{padding:"2px"}} align="center"></td>
             <td width="6%" style={{padding:"2px"}} align="center"></td>
             <td width="8%" style={{padding:"2px"}} align="center"></td>
             <td width="8%" style={{padding:"2px"}} align="center"></td> 
             <td width="8%" style={{padding:"2px"}} bgcolor="#D6DBDF" align="left">Total</td>
             <td width="8%" style={{padding:"2px"}} bgcolor="#D6DBDF" align="right">$&nbsp;&nbsp;&nbsp;&nbsp;{sumaTotal}</td>
           </tr> 
    </MDBTable>  
    </div> 
  } 
  let titulo = <h6><strong>Orden de Venta</strong></h6>
  pdf=<div style={{marginLeft:"3%"}}>
         <Card title={titulo} extra = {<div><MDBBtn color="danger" danger  onClick = {e=>this.cerrarPDF()} size="sm">Cerrar</MDBBtn>&nbsp;&nbsp;<MDBBtn color="success" ghost onClick={(e) => { this.pdfExportComponent.save();}} size="sm">Descargar reporte</MDBBtn> </div>}>

          <Paper  style={{padding:"1%", width:1100,height:1400, marginBottom:"2%",marginLeft:"1%",marginTop:"2%"}}>
       
            <center>
              <MDBRow style={{marginTop:"3%"}} >
                    <MDBCol size = "6">
                      <img src={titulo1} alt="imagen" />
                    </MDBCol>  
                    <MDBCol size = "6">
                      <p style={{marginTop:"3%"}}>NO. DE ORDEN <strong>{numFolio+"ADS"}</strong></p> 
                    </MDBCol> 
              </MDBRow>             
              </center>
              <div  style={{marginLeft:"63%",marginTop:"4%"}}>
                Ciudad de Mexico a {dia + " de " + mes + " de " + año}
              </div> 
               
              <br></br>          
              <div style={{width:"92%", marginLeft:"4%"}}> 
                       <Row xs="2">
                       <Col  className="border text-white" style={{backgroundColor:"#05387A", padding:"4px"}}>
                         <fort>REMITENTE O EXPENDIDOR</fort>
                         </Col>
                       <Col className="border text-white" style={{backgroundColor:"#05387A", padding:"4px"}}>
                        <fort>DESTINATARIO</fort>
                         </Col>
                          <Col className="border">
                          <fort> 
                              <p>   
                              <fort><strong>{razonSocialEmpresaProvedor}</strong></fort> 
                              <br></br>                           
                                {nombreProvedor}&nbsp;{apellidoProvedor}  
                              <br></br>                                 
                               {puestoProvedor} 
                               <br></br>
                               {correoProvedor}
                                  <br></br>
                              {"Tel." + " " + telefonoProvedor + " " + "Ext." + extensionTelefonicaProvedor }                
                              </p>                                          
                          </fort>                             
                          </Col>  
                          <Col className="border">
                            <fort>
                              <p>                              
                               <fort><strong>{razonSocialEmpresaContacto}</strong></fort> 
                              <br></br>
                               {RFCEmpresaContacto}
                              <br></br>                 
                              {NombreContacto + " " + ApellidosContacto}                 
                                  <br></br> 
                              {PuestoContacto}
                                  <br></br>
                              {correoContacto}
                               <br></br>
                               {"Tel." + " " +telefonoContacto}
                            </p>
                            </fort>                           
                         </Col>              
                        </Row>        
              </div>
              <div style={{marginTop:"2%"}}>            
              <center>
              {tablaPDF} 
            </center>
            <br/>
            <div style={{width:"95%", marginLeft:"2%"}} >    
                
                <MDBTable bordered  small  striped >
                <tr>
                    <td className="textabla10" width="15%">Fecha y Hora </td>  
                    <td className="textabla10" width="15%">{diaDeposito + " de " + mesDeposito + " del " + añoDeposito + "  " + horas}</td>                  
                  </tr>
                  <tr>
                    <td className="textabla10" width="15%">Entidad bancaria</td>  
                    <td className="textabla10" width="15%">{banco}</td>                  
                  </tr>
                  <tr>
                    <td className="textabla10" width="15%">Número de referencia</td>  
                    <td className="textabla10" width="15%">{referencia}</td>                  
                  </tr> 
                  <tr>
                    <td className="textabla10" width="15%">Monto</td>  
                    <td className="textabla10" width="15%">$&nbsp;&nbsp;{importe}</td>                  
                  </tr> 
                  <tr>
                    <td className="textabla10" width="15%">Tipo de pago</td>  
                    <td className="textabla10" width="15%">{tipoPago}</td>                                   
                  </tr>
                 
                                   
                  
                </MDBTable >
            </div>
            <fort> 
             <p  position= "absolute" className="footert text-center py-3">Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México <br></br> Información, soporte y ventas:
                 Conmutador:55 3603 9970 - 55 5553 2049</p>             
            </fort  > 
            </div>
            </Paper>  
            </Card>          
            </div> 
          }

  let array = [];
  let subtotal1;
  let iva1;
  let total1;
        
         let pdfExport =
         <div style={{ position: "absolute", left: "-5000px", top: 0 }}>
                    {/* <div style={{ position: "absolute",  top: 0 }}>    */}
          <PDFExport
              paperSize="letter"
              margin="1cm"
              forcePageBreak=".page-break"
              fileName={numFolio+"ADS "+ razonSocialEmpresaContacto+".pdf"}
              pageTemplate = {PageTemplate}
  
              ref={(component) => this.pdfExportComponent = component}
              >
          <div> 
          <center>
          <MDBRow style={{marginTop:"2%"}} >
                              <MDBCol size="8">
                              <img src={titulo1} alt="imagen" style={{width:"40%",marginLeft:"-53%"}} />
                              </MDBCol>  
                              <MDBCol size="4">                      
                              <b className="textabla3" style={{marginTop:"2%"}}>NO. DE ORDEN <strong>{numFolio+"ADS"}</strong></b> <br/>
                              </MDBCol> 
                      </MDBRow>  
              </center>

               <div className="textabla" style={{marginLeft:"50%",marginTop:"5%"}}>
                 Ciudad de Mexico a {dia + " de " + mes + " de " + año}
                </div>
                <br></br>
                  {/* <center> */}
                   <div style={{width:"95%", marginLeft:"2%"}}> 
                       <Row xs="2">
                       <Col  className="border text-white textabla3" style={{backgroundColor:"#05387A", padding:"4px"}}>
                         <fort>REMITENTE O EXPENDIDOR</fort>
                         </Col>
                       <Col className="border text-white textabla3" style={{backgroundColor:"#05387A", padding:"4px"}}>
                        <fort>DESTINATARIO</fort>
                         </Col>
                          <Col className="border">
                          <fort className="textabla1"><strong>{razonSocialEmpresaProvedor}</strong></fort>
                          <fort className= "textabla3"> 
                              <p>  
                                {nombreProvedor}&nbsp;{apellidoProvedor}  
                              <br></br>                                 
                               {puestoProvedor} 
                               <br></br>
                               {correoProvedor}
                                  <br></br>
                              {"Tel." + " " + telefonoProvedor + " " + "Ext." + extensionTelefonicaProvedor }                
                              </p>                                          
                          </fort>                             
                          </Col>  
                          <Col className="border">
                          <fort className="textabla1" ><strong>{razonSocialEmpresaContacto}</strong></fort> 
                          <fort className="textabla3">
                              <p> 
                             {RFCEmpresaContacto}
                              <br></br>                 
                           {NombreContacto + " " + ApellidosContacto}            
                                  <br></br> 
                            {PuestoContacto}
                                  <br></br>
                              {correoContacto}
                               <br></br>
                              {"Tel." + " " +telefonoContacto}
                            </p>
                            </fort>                           
                         </Col>              
                        </Row>        
              </div>              
                <div style={{marginTop:"2%"}}>
                <center>
                <div>  
                <MDBTable class="table" striped bordered  small style={{width:555}} >
                       <thead>
                           <tr>
                           <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Producto</th>          
                           <th width="43%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Descripción</th>
                           <th width="6%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Cant.</th>
                           <th width="11%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente" >PU.</th>
                           <th width="8%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">%</th>
                           <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Descuento</th>
                           <th width="12%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Total</th>
                           </tr>
                       </thead>
                       <tbody>
                           {this.state.productos.map(rows=>{ 
                             let totalDosCeros;
                             let total;
                             let tofixed2
                             let sumaDosCeros;
                             let suma;
                             
                             let sumarValor;
                             let sumarValorDosCeros;
                             let sumIVA;
                                array.push(rows.id.TotalPrecioProducto)
                             let sum = 0;
             
                             for (let i = 0; i < array.length; i++) {
                                 sum += parseFloat(array[i]);
                             }         
                                  
                             totalDosCeros = rows.id.TotalPrecioProducto.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
                             total = totalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

                             tofixed2=sum.toFixed(2)
                             sumaDosCeros = tofixed2.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
                             suma = sumaDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                               subtotal1=suma
                           
                             let IVA= .16
                             let calcularIVA =((sum) * (IVA)).toFixed(2)          
                             iva1=calcularIVA
             
                            sumarValor=sum + parseFloat(calcularIVA)
                            sumarValorDosCeros =sumarValor.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
                            sumIVA = sumarValorDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
                            total1=sumIVA                     
                               return(
                                  <tr>
                                      <td style={{padding:"2px"}} align="left" className="textabla3">{rows.id.tipo}</td>
                                      <td style={{padding:"2px"}} align="left" className="textabla3">{rows.id.concepto}</td>
                                      <td style={{padding:"2px"}} align="center" className="textabla3">{rows.id.cantidad}</td>
                                      <td style={{padding:"2px"}} align="right"  className="textabla3" >$ {rows.id.precio}</td>
                                      <td style={{padding:"2px"}} align="center"className="textabla3" >{rows.id.descuento} %</td>
                                      <td style={{padding:"2px"}} align="right" className="textabla3">$ {rows.id.descuentoAplicado}</td>
                                      <td style={{padding:"2px"}} align="right" className="textabla3" >$&nbsp;{rows.id.TotalPrecioProducto}</td>
                                  </tr> 
                                                                    
                               )                      
                            }
                            )}             
  
                      <tr>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="left" className="textabla3">Subtotal</td>
                        <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="right" className="textabla3">$&nbsp;&nbsp;{subtotal1}</td>
                      </tr>
                      <tr>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center" ></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="left" className="textabla3">IVA</td>
                        <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="right" className="textabla3">$&nbsp;&nbsp;{iva1}</td>
                      </tr>
                      <tr>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} align="center"></td>
                        <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="left" className="textabla3">Total</td>
                        <td style={{padding:"2px"}} bgcolor="#D6DBDF"align="right" className="textabla3">$&nbsp;&nbsp;{total1}</td>
                      </tr>            
                       </tbody>
                </MDBTable >
                </div> 
              </center>
                 <div> 
                <MDBTable class="table" striped bordered  small style={{width:500,aling:"center"}}>
                <tr>
                    <td style={{padding:"2px"}} className="textabla3" width="40%">Fecha y Hora </td>  
                    <td style={{padding:"2px"}} className="textabla3" width="90%">{diaDeposito + " de " + mesDeposito + " del " + añoDeposito + "  " + horas}</td>                  
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} className="textabla3" width="40%">Entidad bancaria</td>  
                    <td style={{padding:"2px"}} className="textabla3" width="60%">{banco}</td>                  
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} className="textabla3" width="40%">Número de referencia</td>  
                    <td style={{padding:"2px"}} className="textabla3" width="60%">{referencia}</td>                  
                  </tr> 
                  <tr>
                    <td style={{padding:"2px"}} className="textabla3" width="40%">Monto</td>  
                    <td style={{padding:"2px"}} className="textabla3" width="60%">$&nbsp;{importe}</td>                  
                  </tr> 
                  <tr>
                    <td style={{padding:"2px"}} className="textabla3" width="40%">Tipo de pago</td>  
                    <td style={{padding:"2px"}} className="textabla3" width="60%">{tipoPago}</td>                                   
                  </tr> 
                </MDBTable >
            </div>
           </div>  
        </div>
        </PDFExport>
        </div>     

        return(
            <React.Fragment> 
              {datosCliente}
              {capturarDatos}
              {productos} 
              {/* {tabla} */}
              {tablaDetalles}                    
              {productosAlmacenados}               
              {pdf} 
              {pdfExport}                  
</React.Fragment>
             )
        }     
              

}export default comprasCarrito



