import React, { Component } from 'react';
import { Card, Button, Modal, Upload, Progress } from 'antd';
import { UploadOutlined ,FileExclamationOutlined} from '@ant-design/icons';
import swal from 'sweetalert';
import Carrusel from './carrusell/Membresia';
import Inmersion_consideraciones from './complementoCursos/Inmersion_consideracion.js';
import NavbarN from '../demo/complementoCursos/Navbar_vertical';
import actionsAPI from './actionsAPI.js';
import { storage } from '../administradorAlfa/firebase'; // Asegúrate de que la configuración de Firebase esté correctamente exportada desde firebaseConfig.js
import './index.css';
import pdf_pending from '../imagen/pdf_pending.png'
import pdf_not from '../imagen/pdf_not.png'
import pdf_ok from '../imagen/pdf_ok.png'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requisitosVisible: false,
      modal: false,
      button_cargar: false,
      uploading: false,
      url: '',
      progress: 0,
      vacantes: [],
      file: null,
      showUpload: false,
      modalProgress:false,
      status_cv:[]
    };
    this.viewRequisitos = this.viewRequisitos.bind(this);
  }

  enConstruccion = () => {
    swal({
      text: 'Apartado en construcción, gracias por su comprensión :)',
      icon: 'warning',
    });
  };

  async componentWillMount() {
    await this.get_vacantes();    
     this.get_cv();

  }

  get_vacantes() {
    actionsAPI.get_vacantes().then((response) => {
      this.setState({ vacantes: response.data.data.get_vacantes });
    });
  }

  get_cv() {
    actionsAPI.get_cv(localStorage.getItem("id_users_plataform")).then((response) => {
      this.setState({status_cv :  response.data.data.get_cv});
    }).catch(err=>{
      console.log("eerr",err.response)
    })
  }

  mostrarRequisitos = () => {
    this.setState({ requisitosVisible: true });
  };

  modal_inversion() {
    this.setState({ modal: true });
  }

  close_modal() {
    this.setState({ modal: false });
  }
  close_modal_progress() {
    this.setState({ modalProgress: false });
  }

  adjuntar() {
    this.setState({ button_cargar: true });
  }

  handleFileChange = (e) => {
    if (e.file ) {
      this.setState({ file: e.file});
    }
  };

  uploadCV = () => {
    this.setState({ modalProgress: true });
    const { file } = this.state;
    if (!file) {
      swal({
        text: 'Por favor selecciona un archivo primero.',
        icon: 'error',
      });
      return;
    }
    this.setState({ showUpload: true });

    this.setState({ uploading: true });
    const uploadTask = storage.ref(`cv/${file.name}`).put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({ progress });
      },
      (error) => {
        console.error('Error de carga:', error);
        this.setState({ uploading: false });
        swal({
          text: 'Hubo un problema al cargar el archivo. Por favor intenta de nuevo.',
          icon: 'error',
        });
      },
      () => {
        storage.ref('cv').child(file.name).getDownloadURL().then((url) => {
          this.setState({ uploading: false, url });
          swal({
            text: 'El archivo se cargó exitosamente.',
            icon: 'success',
          });
          this.setState({ uploading: false });
          this.setState({ modalProgress: false });
          this.setState({ button_cargar: false });

        });

      }
    );
  };

  handleCancel = () => {
   window.location.reload()
  };

  handleSend = () => {
    let id_usuario = localStorage.getItem("id_users_plataform");

    actionsAPI.sendCV(this.state.url,id_usuario).then(response=>{
      if(response.data.data.sendCV.message === "registro exitoso"){
        swal({
          text: 'El archivo fue enviado con éxito para su revisión y aprobación',
          icon: 'success',
        });
        setTimeout(()=>{
          window.location.reload()
        },2000)
      }
    })
  };

  viewRequisitos(rows) {
    window.open(rows.url_pdf, '_blank');
  }

  solicitar_vacante(rows){
    let token = localStorage.getItem("token_users_plataform");

    if(token){
    let id_users_plataform = localStorage.getItem("id_users_plataform");

    let status_cv = this.state.status_cv;
    if(status_cv[0]){
      if(status_cv[0].estatus_aprobacion === "Pendiente"){
        swal({
          title:'Aviso!',
          text: 'Antes de solicitar una vacante debe esperar la aprobación de su Curriculum',
          icon: 'error',
        });
      }
    } 
    if(status_cv[0]){
      if(status_cv[0].estatus_aprobacion === "Aprobada"){
        let nombre_plataform = localStorage.getItem("nombre_plataform");
        let apellidos_plataform = localStorage.getItem("apellidos_plataform");
        let correo_plataform = localStorage.getItem("correo_plataform");
        let telefono_plataform = localStorage.getItem("telefono_plataform");
        let cv = status_cv[0].url_cv
  
        actionsAPI.solicitar_vacante(rows.id_vacantes,id_users_plataform, nombre_plataform,apellidos_plataform,correo_plataform ,telefono_plataform,cv).then(response=>{
          if(response.data.data.solicitar_vacante.message === "registro exitoso"){
            swal({
              title:'Aviso!',
              text: 'Solicitud enviada con éxito',
              icon: 'success',
            });
          }
          if(response.data.data.solicitar_vacante.message === "vacante ya solicitada"){
            swal({
              title:'Aviso!',
              text: 'Vacante ya solicitada con anterioridad',
              icon: 'info',
            });
          }
        })
      }
    }
    
    if(!status_cv[0]){
      swal({
        title:'Aviso!',
        text: 'Antes de solicitar una vacante debe adjuntar su CV y tener aprobación del mismo',
        icon: 'error',
      });
    }
  }else{
    swal({
      title:'Aviso!',
      text: 'Primero cree una cuenta en plataforma de cursos para continuar',
      icon: 'error',
    });
  }
  }
  render() {
    let token = localStorage.getItem("token_user_plataform");
    console.log("token",token)
    let button_cargar;
    let uploadButton;
    let uploadProgress;
    

    if (this.state.button_cargar) {
      button_cargar = (
        <Button
          type="info"
          onClick={() => this.uploadCV()}
          disabled={this.state.uploading}
        >
          {this.state.uploading ? 'Subiendo...' : 'Subir documento'}
        </Button>
      );
    }

    if (this.state.showUpload) {
      uploadButton = (
        <Button
          type="link"
          href={this.state.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          Ver Documento
        </Button>
      );
    }

    if (this.state.uploading) {
      uploadProgress = (
        <Progress
          type='circle'
          percent={this.state.progress}
          format={() => `${this.state.progress}%`}
        />
      );
    }

    let modal;
    modal = (
      <Modal open={this.state.modal} onCancel={() => this.close_modal()} footer={null}>
        <Inmersion_consideraciones />
      </Modal>
    );
    let modalProgress;
    modalProgress = (
      <Modal open={this.state.modalProgress}  footer={null}>
        <center>
        {uploadProgress}
        </center>
      </Modal>
    );

    let componente_cv;
    if(token){

    if(!this.state.status_cv[0]){
      componente_cv =  <div style={{ margin: '2em' }}>
      <font color="red" className="strong">Curriculum no adjuntado</font> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img width={45} src = {pdf_not}/>

      <h4 className='strong'>Sube tu CV</h4>
      <div className='rows'>
        <Upload
          listType="picture"
          className="upload-list-inline"
          beforeUpload={() => {
            console.log("entro")
            this.adjuntar();
            return false; // Prevent automatic upload
          }}
          showUploadList={{ showRemoveIcon: false }} // Hide the remove icon
          onChange={this.handleFileChange}
        >
          <Button   icon={<UploadOutlined />} className='custom-button-upload text-white'>
            Cargar archivo
          </Button>
        </Upload>
        {button_cargar} &nbsp; &nbsp; &nbsp;
        {uploadButton}
        <Button
          className='custom-button-send text-white'
          
          onClick={this.handleSend}
          disabled={!this.state.url}
        >
          Enviar CV
        </Button>
        <Button
          className='custom-button-cancel text-white'
          onClick={this.handleCancel}
          disabled={!this.state.file}
        >
          Cancelar
        </Button>
      </div>
    </div>
    }else if(this.state.status_cv[0]){
      if(this.state.status_cv[0].estatus_aprobacion === "Pendiente"){
        componente_cv = <div style={{ margin: '2em' }}>
            <font color="orange" className="strong">CV Pendiente de aprobación</font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img width={50} src = {pdf_pending}/>
        </div>
      }
      if(this.state.status_cv[0].estatus_aprobacion === "Aprobada"){
        console.log(this.state.status_cv)
        componente_cv = <div style={{ margin: '2em' }}>
            <font color="green" className="strong">CV Aprobado</font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <a href={this.state.status_cv[0].url_cv} target='_blank'><img width={50} src = {pdf_ok}/></a> 
        </div>
      }
      if(this.state.status_cv[0].estatus_aprobacion === "Rechazada"){
        componente_cv = <div style={{ margin: '2em' }}>
            <font color="red" className="strong">CV Rechazado </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <a href={this.state.status_cv[0].url_cv} target='_blank'><img width={50} src = {pdf_not}/></a> <br/><br/>
           <font color="gray" className="strong"> <a onClick={e=>this.enConstruccion()}>Solicite ayuda aquí</a> </font>
        </div>
      }
    }
    }
    return (
      <div>
        <center>
          <Card>
            <div className="rows">
              <div className="table-container">
                <table className="custom-table">
                 {componente_cv}
                  <thead className="custom-thead">
                    <tr>
                      <th className="custom-th">
                        <h6 className="text-white">Bolsa de trabajo</h6>
                      </th>
                      <th className="custom-th"></th>
                      <th className="custom-th"></th>
                      <th className="custom-th"></th>
                      <th className="custom-th"></th>
                    </tr>
                    <tr className="custom-header-row">
                      <th className="custom-th">Nombre de la vacante</th>
                      <th className="custom-th">Fecha de publicación</th>
                      <th className="custom-th">Empresa</th>
                      <th className="custom-th">Descargar documento</th>
                      <th className="custom-th">Solicitar</th>
                    </tr>
                  </thead>
                  {this.state.vacantes.map(rows => {
                    let button_reserva = (
                      <Button
                        onClick={() => this.viewRequisitos(rows)}
                        className="text-white"
                        style={{ backgroundColor: 'rgb(108,183,215)' }}
                      >
                        Ver requisitos
                      </Button>
                    );
                    return (
                      <tbody className="custom-tbody" key={rows.id}>
                        <tr className="custom-row">
                          <td className="custom-td">{rows.titulo_vacante}</td>
                          <td className="custom-td">{rows.fecha_publicacion}</td>
                          <td className="custom-td">{rows.empresa_contratacion}</td>
                          <td className="custom-td">{button_reserva}</td>
                          <td className="custom-td">
                            <Button type='primary' onClick={(e) => this.solicitar_vacante(rows)}>
                              Solicitar
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
                <button
                  className="btn custom-table"
                  onClick={() => this.modal_inversion()}
                >
                  <i className="animation"></i>INVERSIÓN Y CONSIDERACIONES<i className="animation"></i>
                </button>
              </div>
              <div>
                <div className="social-icons">
                  <div>
                    <a
                      target="_blank"
                      className="btn3"
                      href="https://www.facebook.com/IMAImx/"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      target="_blank"
                      className="btn4"
                      href="https://x.com/imai_ac"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      target="_blank"
                      className="btn5"
                      href="https://www.linkedin.com/company/imai_ac/"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                    <a
                      target="_blank"
                      className="btn6"
                      href="https://www.youtube.com/channel/UCdyHevP0lR51a-AgKc0RjpA"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </div>
                  <div style={{ marginTop: '3em' }}></div>
                </div>
              </div>
            </div>
            <Carrusel />
            <NavbarN />
          </Card>
        </center>
        {modal}
        {modalProgress}
      </div>
    );
  }
}

export default Home;
