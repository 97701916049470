import React, { Component } from 'react';
import axios from 'axios';
import {API} from '../Graphql/Graphql';
import MUIDataTable from "mui-datatables";
import {Button} from 'antd'
import {MDBIcon} from 'mdbreact'
import { Form, Input, Select,Card,Mentions } from 'antd';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { Modal } from 'antd';
import ReactWhatsapp from 'react-whatsapp';
import './index.css'

const { Option } = Select;

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
class SoporteTecnico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSupport:[],
            tableInicio: true,
            tableAsignation:false,
            asignationData:[],
            isModalVisible:false,
            dataDetails:[],
            tablaRanking:false,
            dataCalidad:[],
            soporteCalificado:[],
            soporteCalificado2:[],
            dataSurvey:[],
            visible:false,
            visible2:false,
            dataSoporte:[],
            arrayRanking:[],
            ranking:[],
            numeroAsesor:''
        }
        this.onGenderChange = this.onGenderChange.bind(this);
    }
    formRef = React.createRef();

    componentWillMount(){
    let fk_empresa= localStorage.getItem("fk_empresa");
    axios({
        url: API,
        method: "post",
        data: {
            query: `
                query{
                getSupport(data:"${[fk_empresa]}"){  
                    id_soporte
                    fechaSoporte
                    consola
                    numeroPoliza
                    asunto
                    idTeamviewer
                    passTeamviewer
                    folio
                    status
                    fk_contacto
                    ejecutivo
                    rfc
                    razonSocial
                    fechaFinalizacion
                    statusEncuesta
                    telefonoContacto
                    } 
                }
            `
        }
        }).then((response) => {  
            let data = response.data.data.getSupport;
            let dataSort = data.sort(function(a, b) {
                return parseFloat(b.id_soporte) - parseFloat(a.id_soporte);
            });
            this.setState({dataSupport:dataSort})   
        }).catch(err=>{
            console.log("error",err.response)
            console.log("error",err)
        })
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                    query{
                    getCalidadSurvey(data:"${[]}"){  
                        id_calidadSurvey
                        respuestas
                        folio
                        fk_preguntas
                        fk_soporte
                        fk_clientes
                        fechaEvaluacion
                        } 
                    }
                `
            }
            }).then((response) => {  
                let data = response.data.data.getCalidadSurvey;
                this.setState({dataCalidad:data})
            }).catch(err=>{
                console.log("error",err.response)
                console.log("error",err)
            })
    }

    asignation(params){
        this.setState({tableInicio:false})
        this.setState({asignationData:params})
        this.setState({tableAsignation:true})
    }
    cancel(){
        this.setState({tableAsignation:false})
        this.setState({tableInicio:true})
    }
    onGenderChange(e){
        console.log(e,"e")
        this.setState({nombreAsesor:e})
        let asesor = e
        let numeroAsesor;
        if(asesor ==="Jacobo"){
            numeroAsesor = "527821174645"
            this.setState({numeroAsesor:numeroAsesor})
        }else if(asesor ==="Oscar"){
            numeroAsesor = "525587457117"
            this.setState({numeroAsesor:numeroAsesor})
        }else if(asesor ==="Diana"){
            numeroAsesor = "525564263266"
            this.setState({numeroAsesor:numeroAsesor})
        }else if(asesor ==="Lourdes"){
            numeroAsesor = "525512895745"
            this.setState({numeroAsesor:numeroAsesor})
        }else if(asesor ==="Jose"){
            numeroAsesor = "525530128135"
            this.setState({numeroAsesor:numeroAsesor})
        }
    }
    procesar(){
        let params  = this.state.asignationData; 
        let asunto  = params.asunto;
        let consola =  params.consola;
        let fechaSoporte = params.fechaSoporte;
        let folio = params.folio;
        let idTeamviewer = params.idTeamviewer;
        let id_soporte = params.id_soporte;
        let numeroPoliza = params.numeroPoliza;
        let passTeamviewer = params.passTeamviewer;
        let razonSocial = params.razonSocial;
        let telefono = params.telefonoContacto;
        let rfc = params.rfc;
        let asesor = this.state.nombreAsesor
        let correoAsesor;
        if(asesor ==="Jacobo"){
            correoAsesor = "jacobo.guerrero@ads.com.mx"
        }else if(asesor ==="Oscar"){
            correoAsesor = "oscar.diaz@ads.com.mx"
        }else if(asesor ==="Diana"){
            correoAsesor = "diana.jimenez@ads.com.mx"
        }else if(asesor ==="Lourdes"){
            correoAsesor = "lourdes.tizcareño@ads.com.mx"
        }else if(asesor ==="Jose"){
            correoAsesor = "jose.olivo@ads.com.mx"
        }
        
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                sendSupport(data:"${[asunto,consola,fechaSoporte,folio,id_soporte,idTeamviewer,passTeamviewer,numeroPoliza,razonSocial,rfc,asesor,correoAsesor,telefono]}"){  
                  message
                    } 
                }
            `
        }
        }).then((response) => { 
            let message = response.data.data.sendSupport.message
            if(message === "Asesor asignado"){
                swal({
                    title:"Aviso!",
                    text:"Asesor asignado correctamente",
                    buttons: false,
                    icon:"success"
                }); 
                setTimeout(()=>{
                    window.location.reload();
                },2000)
            } 
        }).catch(err=>{
            console.log("error",err.response)
            console.log("error",err)
        })   
    }
    details(params){
        this.setState({isModalVisible:true})
        this.setState({dataDetails:params})
    }
    onOk(){
        this.setState({isModalVisible:false})
    }
    endSupport(rows){
        let id_soporte =  rows.id_soporte;
        let fk_contacto =  rows.fk_contacto;
        let ejecutivo = rows.ejecutivo;
        let status = "Finalizado";
        let folio  = rows.folio;
        let fechaSolicitud = rows.fechaSoporte;
        let consola = rows.consola;
        let rs = rows.razonSocial
        let asunto = rows.asunto
        let rfc = rows.rfc

           function getDateTime() {
            var now     = new Date(); 
            var year    = now.getFullYear();
            var month   = now.getMonth()+1; 
            var day     = now.getDate();
            var hour    = now.getHours();
            var minute  = now.getMinutes();
            var second  = now.getSeconds(); 
            if(month.toString().length == 1) {
                 month = '0'+month;
            }
            if(day.toString().length == 1) {
                 day = '0'+day;
            }   
            if(hour.toString().length == 1) {
                 hour = '0'+hour;
            }
            if(minute.toString().length == 1) {
                 minute = '0'+minute;
            }
            if(second.toString().length == 1) {
                 second = '0'+second;
            }   
            var dateTime = day+'/'+month+'/'+year+' '+hour+':'+minute+':'+second;   
             return dateTime;
        }
        let fechaFinalizacion  =  getDateTime();
        Swal.fire({
            title: '¿Desea finalizar el proceso de soporte?',
            text: "Se le enviará la notificación al cliente junto a la encuesta de calidad",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Finalizar!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    url: API,
                    method: "post",
                    data: {
                        query: `
                            mutation{
                            endSupport(data:"${[id_soporte,fk_contacto,ejecutivo,status,fechaFinalizacion,folio,asunto,consola,fechaSolicitud,rs,rfc]}"){  
                              message
                                } 
                            }
                        `
                    }
                    }).then((response) => { 
                        let message = response.data.data.endSupport.message
                     
                        if(message === "soporte finalizado"){
                            Swal.fire(
                                'Soporte finalizado!',
                                'El proceso ha quedado concluido',
                                'success'
                            )
                            setTimeout(()=>{
                                window.location.reload();
                            },2000)
                        } 
                    }).catch(err=>{
                        console.log("error",err.response)
                        console.log("error",err)
                    })                
            }
          })
    }
 

    openTableCalidad =  async()=>{
        let soporte = this.state.dataSupport
        let filtro  = soporte.filter(function(hero){
            return hero.statusEncuesta === "Aplicada"
        })
        let dataCalidad =  this.state.dataCalidad        
        var sums = {};
        for (var i = 0; i < dataCalidad.length; i++) {
        var obj = dataCalidad[i];
        sums[obj.fk_soporte] = sums[obj.fk_soporte] === undefined ? 0 : sums[obj.fk_soporte];
        sums[obj.fk_soporte] +=parseInt(obj.respuestas)/7;
        }

        let array2 = []
        filtro.filter(rows=>{
            array2.push({"asunto":rows.asunto
            ,"consola":rows.consola,"ejecutivo":rows.ejecutivo,
            "fechaFinalizacion":rows.fechaFinalizacion,"fechaSoporte":rows.fechaSoporte,"fk_contacto":rows.fk_contacto
            ,"folio":rows.folio,"idTeamviewer":rows.idTeamviewer,"id_soporte":rows.id_soporte,"numeroPoliza":rows.numeroPoliza,
            "passTeamviewer":rows.passTeamviewer,"razonSocial":rows.razonSocial,"rfc":rows.rfc,"status":rows.status,"statusEncuesta":rows.statusEncuesta
            ,"calificacion":sums[rows.id_soporte]})
        })
        this.setState({soporteCalificado:array2})
        this.setState({soporteCalificado2:array2})

        this.setState({tableInicio:false})
        this.setState({tablaRanking:true})
    }
  
    detailsSurvey(param){
        let dataSoporte1 = this.state.dataSupport;
        let filtro = this.state.dataCalidad.filter(function(hero){
            return hero.fk_soporte === param.id_soporte
        })
        this.setState({dataSurvey:filtro});
        let id_soporte = filtro[0].fk_soporte;
        let filtroDataSoporte =  dataSoporte1.filter(function(hero){
            return hero.id_soporte === id_soporte
        })
        let arr3 = filtro.map((item, i) => Object.assign({}, item, filtroDataSoporte[i]));
        this.setState({visible:true})
        this.setState({dataSoporte:arr3})
    }
    onOk2(){
        this.setState({visible:false})
    }
    closeTable(){
        this.setState({tableInicio:true})
        this.setState({tablaRanking:false})
    }
    modalRanking(){
        let soporte = this.state.soporteCalificado2;

        
        let mes = new Date().getMonth() + 1
        let mesString = mes.toString()
        if(mes<10){
            mesString = "0" + mesString
        }

        let filtroMes = soporte.filter(function(hero){
            return hero.fechaFinalizacion.substring(3,5) === mesString
        })

        var temp = {};
        filtroMes.forEach(function(obj){
           if(!temp[obj.ejecutivo]){
             temp[obj.ejecutivo] = obj.calificacion;
           } else {
             temp[obj.ejecutivo] = Number(temp[obj.ejecutivo]) + Number(obj.calificacion);
           }
        });
        
        const resultCounter = [...filtroMes.reduce( (mp, o) => {
            if (!mp.has(o.ejecutivo)) mp.set(o.ejecutivo, { ...o, count: 0 });
            mp.get(o.ejecutivo).count++;
            return mp;
        }, new Map).values()];

        
        let resultDesglose = resultCounter.map(function(item) { 
            delete item.calificacion; 
            delete item.asunto; 
            delete item.consola; 
            delete item.fechaFinalizacion; 
            delete item.fechaSoporte; 
            delete item.fk_contacto; 
            delete item.folio; 
            delete item.idTeamviewer; 
            delete item.id_soporte; 
            delete item.numeroPoliza; 
            delete item.passTeamviewer; 
            delete item.razonSocial; 
            delete item.rfc; 
            delete item.status; 
            delete item.statusEncuesta; 
            return item; 
        });
        var result = [];
        for(var key in temp){
          result.push({
            ejecutivo: key,
            calificacion: temp[key]
          })
        }
        let resultado  = result.sort((a, b) => {
            if (a.calificacion > b.calificacion)
              return -1;
            if (a.calificacion < b.calificacion)
              return 1;
            return 0;
        });
        let merged = [];

        for(let i=0; i<resultado.length; i++) {
          merged.push({
           ...resultado[i], 
           ...(resultDesglose.find((itmInner) => itmInner.ejecutivo === resultado[i].ejecutivo))}
          );
        }

        this.setState({ranking:merged})
        this.setState({visible2:true})

    }
    closeModalRanking(){
        this.setState({visible2:false})
    }
    render() { 
    const options={ 
        filterType:"drowpdawn",
        responsive: "stacked",
        print:false,
        download:false,
        viewColumns:false,
        elevation:0,
        selectableRows:"none",
        textLabels:{
        body: {
            noMatch: "Lo sentimos, no se encontraron registros coincidentes",
            toolTip: " Ordenar",
            columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
            next: "Página siguiente",
            previous: "Página anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: " Descargar CSV",
            print: "Imprimir ",
            viewColumns: "Ver columnas",
            filterTable: "Tabla de filtros",
        },
        filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "RESET",
        },
        viewColumns: {
            title: "Mostrar columnas",
            titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
            text: "fila (s) seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar filas seleccionadas",
        },      
        }        
        }
    const columns = [ {
        name: "ID",
        options: {
          display: false,
        }
      },"Razón social","RFC","Fecha de soporte","Consola","Folio","Asignar","Status","Detalles"];  
    let data = this.state.dataSupport.map((rows)=>{ 
        let button;
        let buttonFinalizar;
        if(rows.ejecutivo){
           button = <strong><font color="red">Ya asignado</font></strong>
           if(rows.status === "En proceso"){
            buttonFinalizar = <Button type = "danger" onClick={e=>this.endSupport(rows)}>Finalizar</Button>
           }else if(rows.status === "Finalizado"){
            buttonFinalizar = <strong><font color="red">Finalizado</font></strong>
           } 
        }else{
            button = <Button type = "primary" onClick={e=>this.asignation(rows)}>Asignar</Button>
            buttonFinalizar = <strong><font color="green">{rows.status}</font></strong> 
        }
        let details = <Button type="success" className="ant-btn-primary" shape="circle" size="large" onClick={e=>this.details(rows)}><MDBIcon icon="info" /></Button>
              return([rows.id_soporte,rows.razonSocial,rows.rfc,rows.fechaSoporte,rows.consola,rows.folio,button,buttonFinalizar,details])
    })  
    let modal;
    if(this.state.dataDetails){
        let detail = this.state.dataDetails

        let fechaSoporte;
        if(detail.fechaSoporte){
            fechaSoporte = detail.fechaSoporte.substring(0,9);
        }
        modal = <Modal width={1450} title={<strong>Detalles de la solicitud de soporte</strong>} visible={this.state.isModalVisible} onOk={e=>this.onOk()} onCancel = {e=>this.onOk()}>
        <table className='table table-small table table-bordered table table-striped'>
             <tr>
                 <th><strong>ID</strong></th>
                 <th><strong>Razón S.</strong></th>
                 <th><strong>RFC</strong></th>
                 <th><strong>Fecha solicitud</strong></th>
                 <th><strong>Consola</strong></th>
                 <th><strong>Folio</strong></th>
                 <th><strong>Asesor</strong></th>
                 <th><strong>Status</strong></th>
                 <th><strong>Finalización</strong></th>
             </tr>
             <tr>
                 <th>{detail.id_soporte}</th>
                 <th>{detail.razonSocial}</th>
                 <th>{detail.rfc}</th>
                 <th>{fechaSoporte}</th>
                 <th>{detail.consola}</th>
                 <th>{detail.folio}</th>
                 <th>{detail.ejecutivo}</th>
                 <th>{detail.status}</th>
                 <th>{detail.fechaFinalizacion}</th>
             </tr>
        </table> 
     </Modal>
    }
   
    let tableAsignation;
    if(this.state.tableAsignation === true){
        let params  = this.state.asignationData; 
        let asunto  = params.asunto;
        let consola =  params.consola;
        let fechaSoporte = params.fechaSoporte;
        let folio = params.folio;
        let idTeamviewer = params.idTeamviewer;
        let numeroPoliza = params.numeroPoliza;
        let passTeamviewer = params.passTeamviewer;
        let razonSocial = params.razonSocial;
        let telefono = params.telefonoContacto;
        let rfc = params.rfc;
        let asesor = this.state.nombreAsesor
        
        let whatsapp;
        if(this.state.nombreAsesor){
            whatsapp = <ReactWhatsapp className='button1' number={this.state.numeroAsesor} message={`Asesor: ${asesor}
                Datos del soporte:
                
                Asunto: ${asunto}
                Consola: ${consola}
                Fecha de solicitud: ${fechaSoporte}
                Folio: ${folio}
                ID Teamviewer o acceso: ${idTeamviewer}
                Contraseña: ${passTeamviewer}
                Número de póliza: ${numeroPoliza}
                Razón social: ${razonSocial}
                RFC: ${rfc}
                Teléfono de contacto: ${telefono}
            `} >Enviar por whatsapp</ReactWhatsapp>
        }else{
            whatsapp = <ReactWhatsapp disabled className='button1' number={this.state.numeroAsesor} message="Hello World!!!" >Enviar por whatsapp</ReactWhatsapp>
        }
        let dataSupport = this.state.dataSupport[0]
        tableAsignation = 
        <div style={{marginTop:"2%",marginLeft:"6%"}}>
        <Card style={{width:"95%"}} title = {<div><h6><strong>Asignación de soporte técnico</strong></h6></div>} extra={<Button block type="link" danger onClick = {e=>this.cancel()}>Cancelar</Button>}>
        <center><h6><strong>{dataSupport.razonSocial}</strong></h6></center> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Fecha de solicitud</strong>&nbsp;&nbsp;&nbsp;
        <strong>{dataSupport.fechaSoporte}</strong>
        <table class="table table-bordered table table-striped border-primary" style = {{width:"90%"}}>
        <thead>
          <tr>
            <th scope="col"><strong>ID</strong></th>
            <th scope="col"><strong>Asunto</strong></th>
            <th scope="col"><strong>Folio</strong></th>
            <th scope="col"><strong>Conosola</strong></th>
            <th scope="col"><strong>Teamviewer</strong></th>
            <th scope="col"><strong>Contraseña</strong></th>
            <th scope="col"><strong>Teléfono del contacto</strong></th>
            <th scope="col"><strong>Finalización</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="col">{dataSupport.id_soporte}</th>
            <th scope="col">{dataSupport.asunto}</th>
            <th scope="col">{dataSupport.folio}</th>
            <th scope="col">{dataSupport.consola}</th>
            <th scope="col">{dataSupport.idTeamviewer}</th>
            <th scope="col">{dataSupport.passTeamviewer}</th>  
            <th scope="col">{dataSupport.telefonoContacto}</th>                    
            <th scope="col">{dataSupport.fechaFinalizacion}</th>
          </tr>
          
        </tbody>
      </table>
      <font color="black">Nota: El ejecutivo seleccionado recibirá un correo electónico con los datos de acceso</font><br/><br/><br/>
        <center>
         <Form style = {{width:"30%"}} className="text-left" {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
            <strong> Asignación de soporte <font color="red">*</font></strong>
            <Form.Item
            name="ejecutivo"
            rules={[
                {
                required: true,
                },
            ]}
            >
            <Select
                placeholder="Asignar ejecutivo"
                onChange={this.onGenderChange}
                allowClear
            >               
                <Option value="Jacobo">Jacobo Guerrero</Option>
                <Option value="Oscar">Oscar Díaz</Option>
                <Option value="Diana">Diana Jiménez</Option>
                <Option value="Lourdes">Lourdes Tizcareño</Option>
                <Option value="Jose">José Olivo</Option>
            </Select>
            </Form.Item>
            <Form.Item>
            <Button block type="primary" onClick={e=>this.procesar()}>
                Aceptar
            </Button>
            <br/>
            <br/>
            <center>{whatsapp}</center>
            <br/>
               <font color="red">No olvide seleccionar el nombre del asesor para poder enviar el soporte por Whatsapp</font>             
            <br/>            
            </Form.Item>
          </Form>
          </center>
          </Card>
      </div>
    }
    let table;
    if(this.state.tableInicio === true){
        table = <div  style={{width:"95%",marginLeft:"3%",marginTop:"2%"}} >  
        <Card type="inner" title = {<h6><strong>Solicitud de soportes activos</strong></h6>} extra={<div><Button type="primary" onClick = {e=>this.openTableCalidad()}>Encuesta de calidad</Button></div>}>          
        <MUIDataTable  
          data={data} 
          columns={columns} 
          options={options}          
        />  
        </Card>                       
        </div> 
    }
    let tablaSoporteCalificado;
    if(this.state.tablaRanking === true){
        const columnsSoporteCalificado = ["ID","Ejecutivo","Asunto","Consola","Folio","Fecha de soporte","Fecha de finalización","Calificación","Detalles"];  
        let dataSoporteCalificado = this.state.soporteCalificado.map((rows)=>{ 
            let details = <Button type="warning" className="ant-btn-primary" shape="circle" size="middle" onClick = {e=>this.detailsSurvey(rows)}><MDBIcon icon="info" /></Button>
            return([rows.id_soporte,rows.ejecutivo,rows.asunto,rows.consola,rows.folio,rows.fechaSoporte,rows.fechaFinalizacion,parseFloat(rows.calificacion).toFixed(2),details])
        })
        tablaSoporteCalificado = <div  style={{width:"95%",marginLeft:"3%",marginTop:"2%"}} >  
        <Card type="inner" title = {<h6><strong>Calificacion del soporte tecnico</strong></h6>} extra={<div><Button type="primary" onClick={e=>this.modalRanking()}><MDBIcon fas icon="star" /></Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button type="danger" onClick = {e=>this.closeTable()}><MDBIcon fas icon="times" /></Button></div>}>          
        <MUIDataTable  
          data={dataSoporteCalificado} 
          columns={columnsSoporteCalificado} 
          options={options}          
        />  
        </Card>                       
        </div>
       
    }
    let modalSurvey;
    if(this.state.dataSoporte[0]){
        modalSurvey = <Modal width={1200} onOk={e=>this.onOk2()} onCancel={e=>this.onOk2()} visible={this.state.visible}>
            <Card type ="inner" title={<h6><strong>Resultados de la evaluación de soporte técnico</strong></h6>}>
            <table className='table table-bordered table table-small table table-striped'>
                <tbody>
                    <tr>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Razón Social</strong></td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>RFC</strong></td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Ejecutivo</strong></td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Asunto</strong></td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Consola</strong></td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha de Evaluacion</strong></td>
                    </tr>
                    <tr>
                            <td >
                                {this.state.dataSoporte[0].razonSocial}
                            </td>
                            <td>
                                {this.state.dataSoporte[0].rfc}
                            </td>
                            <td>
                                {this.state.dataSoporte[0].ejecutivo}
                            </td>
                            <td>
                                {this.state.dataSoporte[0].asunto}
                            </td>
                            <td>
                                {this.state.dataSoporte[0].consola}
                            </td>
                            <td>
                                {this.state.dataSoporte[0].fechaEvaluacion}
                            </td>
                        </tr>
                    </tbody>
                </table> 
                <table className='table table-bordered table table-small table table-striped'>
                    <tbody>                
                {this.state.dataSoporte.map(rows=>{
                    let preguntas;
                    if(rows.fk_preguntas === "5"){
                        preguntas = "¿Qué tan satisfecho está usted con la calidad general del servicio que recibió?"
                    }else if(rows.fk_preguntas === "15"){
                        preguntas = "¿Se considera resuelto el problema?"
                    }else if(rows.fk_preguntas === "25"){
                        preguntas = "¿Cómo valora usted el ejecutivo asignado a su empresa?"
                    }else if(rows.fk_preguntas === "35"){
                        preguntas = "¿Que tan satisfecho del tiempo de respuesta desde el inicio hasta el final de la instalación?"
                    }else if(rows.fk_preguntas === "45"){
                        preguntas = "¿Qué tan satisfecho quedó con esta experiencia de soporte en línea?"
                    }else if(rows.fk_preguntas === "55"){
                        preguntas = "Valore el tiempo de respuesta desde la solicitud de servicio hasta la llegada del ejecutivo"
                    }else if(rows.fk_preguntas === "65"){
                        preguntas = "¿Volveria a solicitar nuestro servicio de soporte con el ejecutivo asignado?"
                    }
                    return(
                    
                        <tr>
                            <td style={{paddingTop:"2px",paddingBottom:"2px"}}>
                                <strong>{preguntas}</strong>
                            </td>
                            <td style={{paddingTop:"2px",paddingBottom:"2px"}}>
                                {rows.respuestas} <MDBIcon fas icon="star" />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>  

            </Card>
        </Modal>
    } 
    let modalRanking;
    let a = 1;
        modalRanking = <Modal width={1000} visible={this.state.visible2} onCancel={e=>this.closeModalRanking()} onOk={e=>this.closeModalRanking()}>
            <Card title={<h6>Calificacion promedio del mes</h6>} type="inner">
                <table className='table table-bordered table table-small table table-striped'>
                    <tr>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Posición</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Ejecutivo</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Calificacion</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>Soportes es el mes</td>
                    </tr>
                <tbody>
                {this.state.ranking.map(rows=>{
                    return(
                        <tr>
                            <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{a++}</td>
                            <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.ejecutivo}</td>
                            <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{(rows.calificacion/rows.count).toFixed(2)}&nbsp;&nbsp;&nbsp;<MDBIcon fas icon="star" /></td>
                            <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.count}</td>
                        </tr>
                    )
                })}
                </tbody>
                </table>
            </Card>
        </Modal>
    
        return ( 
            <div>
                {modalRanking}
                {tablaSoporteCalificado}
                {tableAsignation}
                {table}
                {modalSurvey}
                {modal}
            </div>
         );
    }
}
 
export default SoporteTecnico;
