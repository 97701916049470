import React, { Component } from 'react';
import './Carousell.css';
import swal from 'sweetalert';
import icono1 from '../../imagen/icono1.png'
import icono2 from '../../imagen/icono2.png'
import icono3 from '../../imagen/icono3.png'
import icono4 from '../../imagen/icono4.png'
import icono7 from '../../imagen/icono7.png'
import icono8 from '../../imagen/icono8.png'
import icono9 from '../../imagen/icono9.png'
import icono10 from '../../imagen/icono10.png'
import icono11 from '../../imagen/icono11.png'
import icono12 from '../../imagen/icono12.png'
import icono13 from '../../imagen/icono13.png'
import icono14 from '../../imagen/icono14.png'

import inscripcion from '../../imagen/inscripcion.png'
import renovación from '../../imagen/renovacion.png'
import beneficios from '../../imagen/beneficios.png'
import estudiantil from '../../imagen/estudiantil.png'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalClosing: false,
      isModalOpen2: false,
      modalClosing2: false,
      isModalOpen3: false,
      modalClosing3: false,
      isModalOpen4: false,
      modalClosing4: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleModal2 = this.toggleModal2.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
    this.toggleModal3 = this.toggleModal3.bind(this);
    this.closeModal3 = this.closeModal3.bind(this);
    this.toggleModal4 = this.toggleModal4.bind(this);
    this.closeModal4 = this.closeModal4.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      modalClosing: false
    }));
  }

  closeModal() {
    this.setState({ modalClosing: true });
    setTimeout(() => {
      this.setState({ isModalOpen: false, modalClosing: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  toggleModal2() {
    this.setState(prevState => ({
      isModalOpen2: !prevState.isModalOpen2,
      modalClosing2: false
    }));
  }

  closeModal2() {
    this.setState({ modalClosing2: true });
    setTimeout(() => {
      this.setState({ isModalOpen2: false, modalClosing2: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  toggleModal3() {
    this.setState(prevState => ({
      isModalOpen3: !prevState.isModalOpen3,
      modalClosing3: false
    }));
  }

  closeModal3() {
    this.setState({ modalClosing3: true });
    setTimeout(() => {
      this.setState({ isModalOpen3: false, modalClosing3: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  toggleModal4() {
    this.setState(prevState => ({
      isModalOpen4: !prevState.isModalOpen4,
      modalClosing4: false
    }));
  }

  closeModal4() {
    this.setState({ modalClosing4: true });
    setTimeout(() => {
      this.setState({ isModalOpen4: false, modalClosing4: false });
    }, 500); // Match the duration of the zoomOut animation
  }
  enConstruccion = () => {
    swal({
      text:"Apartado en construcción, gracias por su comprensión         :)",               
      icon:"warning",
    });
  }




  render() {

    let modal1 = this.state.isModalOpen && (
      <div className={`modal-overlay ${this.state.modalClosing ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal}>
        <div className={`modal-content ${this.state.modalClosing ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
          <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
            <h2>Membresía tradicional</h2>
            <center>
              <img width={670} src={beneficios} alt="Logo"></img>
            </center>
            <div>
              <h4>¿Qué representa la membresía IMAI - IIA?</h4>
              <div className='rows'>
              <img width={100} src={icono1} alt="Logo"></img>
              <p style={{margin:"1em"}}>El IIA-IMAI son fuente de capacitación de clase mundial que actualizan sobre los conocimientos técnicos y las mejores prácticas de auditoría interna y demas afines mediante cursos, seminarios, diplomados y conferencias</p>
              </div>
            </div>

            <div>
              <h4>Certificaciones avaladas por The IIA: CIA y CRMA</h4>
              <div className='rows'>
              <img width={100} src={icono7} alt="Logo"></img>
              <p style={{margin:"1em"}}>The IIA ofrece a los profesionales de la auditoría interna un programa de Certificaciones con reconocimiento internacional. Los miembros postulantes cuentan con el beneficio de pagar con costo preferencial</p>
              </div>
            </div>

            <div>
              <h4>Puntos EPC</h4>
              <div className='rows'>
              <img width={100} src={icono2} alt="Logo"></img>
              <p style={{margin:"1em"}}>A través de la capacitación el IMAI, le otorga un punto por cada hora de instrucción para mantener las Certificaciones avaladas por IIA y la certificación C.P.C. expedida por el Colegio de Contadores Públicos de México</p>
              </div>
            </div>

            <div>
              <h4>Libros de COSO ERM</h4>
              <div className='rows'>
              <img width={100} src={icono8} alt="Logo"></img>
              <p style={{margin:"1em"}}>El IMAI, le ofrece en venta estos libros en español con precio preferencial de miembro; además, el IIA cuenta con gama de libros relacionados con Control Interno, Riesgos, Auditoría, etc.</p>
              </div>
            </div>
            <div>
              <h4>Conferencias IMAI</h4>
              <div className='rows'>
              <img width={100} src={icono3} alt="Logo"></img>
              <p style={{margin:"1em"}}>Organiza anualmente el Encuentro Nacional de Auditores Internos cuyo evento es el más importante a nivel nacional, en donde se tratan temas actuales y de gran interés relacionados con: Gobierno Corporativo, Control Interno, Auditoría Interna, Riesgos, Tecnología de Información, Auditoría Gubernamental, entre otros, a precios preferenciales para los socios.</p>
              </div>
            </div>

            <div>
              <h4>Conferencias IIA</h4>
              <div className='rows'>
              <img width={100} src={icono9} alt="Logo"></img>
              <p style={{margin:"1em"}}>Ofrece una amplia variedad de eventos dinámicos enfocados en temas actuales de Auditoría Interna; además, en conferencias internacionales del IIA se tratan temas para capacitar y actualizar a los profesionales, permitiendo la creación de redes con compañeros para entablar relaciones, comerciales, laborales y profesionales para obtener una mayor comprensión de las mejores prácticas y llevar su carrera al siguiente nivel.</p>
              </div>
            </div>

            <div>
              <h4>Acceso a Blogs de InternalAuditor.org</h4>
              <div className='rows'>
              <img width={100} src={icono10} alt="Logo"></img>
              <p style={{margin:"1em"}}>El IIA se complace en presentar los nuevos blogs de InternalAuditor.org que ayudarán a conocer diferentes opiniones de nuestros colegas, así como presentar propuestas de interés para toda la comunidad.Los blogs forman parte de una nueva sección Your Voices, que contará con gran contenido sobre la profesión.</p>
              </div>
            </div>
            <div>
              <h4>Audit Career Center IIA</h4>
              <div className='rows'>
              <p style={{margin:"1em"}}>Ponga en marcha su plan de desarrollo profesional o fortalezca a su equipo con el Audit Career Center del IIA que ofrece una lista de verificación anual que ayudará a mantener su plan desarrollo y permitirá que su formación vaya más allá del requerimiento mínimo.Alinee sus objetivos personales con los de su empresa e identifique las fortalezas y debilidades de su equipo para estar en sincronía y cumpla sus metas.</p>
              </div>
            </div>
            <div>
              <h4>Bibilioteca</h4>
              <div className='rows'>
              <img width={100} src={icono11} alt="Logo"></img>

              <p style={{margin:"1em"}}>El IIA pone a su disposición la librería digital en donde podrá descargar con un costo preferencial de miembro, libros en inglés con temas afines a la Auditoria Interna.</p>
              </div>
            </div>
            <div>
              <h4>Libreria</h4>
              <div className='rows'>
              <img width={100} src={icono12} alt="Logo"></img>

              <p style={{margin:"1em"}}>El IMAI a través de su biblioteca le brinda la oportunidad de consultar libros relacionados con Auditoría Interna, Control Interno, riesgos, T.I, etc.</p>
              </div>
            </div>
            <div>
              <h4>Webinars IIA</h4>
              <div className='rows'>
              <p style={{margin:"1em"}}>Los Webinars del IIA son presentaciones educativas que cubren temas de actualidad en el mundo de la auditoría interna. En estos seminarios se puede escuchar a líderes de la industria, lo cual ayudará a impulsar y hacer crecer su conocimiento</p>
              </div>
            </div>
            <div>
              <h4>Informes Anuales IIA</h4>
              <div className='rows'>
              <p style={{margin:"1em"}}>Los informes anuales preparados por The IIA proporcionan los aspectos más destacados de cada año, muestra información a detalle, como son logros y objetivos de aspecto financieros del IIA, así como actualizaciones sobre el estado de la profesión de auditoría interna en todo el mundo.</p>
              </div>
            </div>
            <div>
              <h4>Herramientas Profesionales IIA</h4>
              <div>
              <p>Herramientas, Consejos y Recursos Relevantes</p>
                  <ul>
                      <li>Determinación de los propietarios de riesgos</li>
                      <li>Ejecutar una evaluación de riesgos</li>
                      <li>Mapeo de flujo de procesos</li>
                  </ul>              
              </div>
            </div>
            <div>
            <h4>Perspectivas y Percepciones Globales IIA</h4>
              <p>Conocimiento y dirección sobre temas clave, con perspectivas que resuenan a nivel mundial.</p>
              <ul>
                  <li>Se ofrece en diferentes idiomas</li>
                  <li>Versiones archivadas accesibles en línea</li>
              </ul>
              <p>Algunos ejemplos son:</p>
              <ul>
                  <li>Auditoría Interna y Cumplimiento: Claridad y Colaboración para una Gobernanza Más Sólida</li>
                  <li>Auditoría Remota: Desafíos, Riesgos, Fraude, Tecnología, y la Moral del Personal</li>
                  <li>El Modelo de las Tres Líneas – Una Herramienta Importante para el Éxito de Toda Organización</li>
              </ul>
            </div>
            <div>
              <h4>Boletines IIA</h4>
              <div className='rows'>
              <p style={{margin:"1em"}}>El IIA Global SmartBrief proporciona a través de sus boletines una visión instantánea mensual de las novedades y problemas mundiales que afectan a los auditores internos y las principales fuentes de noticias. Tone at the Top brinda a los auditores internos de todo el mundo información concisa y de vanguardia sobre temas como riesgo, control interno, gobernanza, ética y el rol cambiante de la auditoría interna; además orientación relativa a sus funciones y responsabilidades en el proceso de la profesión.</p>
              </div>
            </div>
            <div>
              <h4>Convertirse en Autor</h4>
              <div className='rows'>
              <img width={100} src={icono13} alt="Logo"></img>

              <p style={{margin:"1em"}}>El IIA ofrece oportunidades continuas para que los miembros se conviertan en autores. En la mayoría de los casos, los autores de libros y artículos de revistas reciben los créditos de Educación Profesional Continua (CPE) necesarios para mantener las certificaciones del IIA.</p>
              </div>
            </div>
            <div>
              <h4>Bolsa de Trabajo</h4>
              <div className='rows'>
              <img width={100} src={icono14} alt="Logo"></img>

              <p style={{margin:"1em"}}>El IMAI comprometido con apoyar a los Auditores Internos en todos los sentidos, ha creado un espacio para publicar las ofertas de empleo que requieren las Organizaciones.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )


    let modal2 = this.state.isModalOpen2 && (
      <div className={`modal-overlay ${this.state.modalClosing2 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal2}>
        <div className={`modal-content ${this.state.modalClosing2 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
        <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
            <h2>Membresía Estudiantil</h2>
            <center>
              <img width={670} src={estudiantil} alt="Logo"></img>
            </center>
            <div>
              <p>El IMAI Y The Institute of Internal Auditors (IIA Global), con el fin de promover la Auditoría Interna, cuenta con un Programa de Membresía Estudiantil que permite impulsar a los estudiantes en su desarrollo profesional. Como miembro estudiante, obtendrás acceso a numerosos recursos, como programas de liderazgo, contenido exclusivo, herramientas técnicas, informes, entre otros. Todo esto ayudará a ampliar conocimientos y en un futuro oportunidades de crecimiento.</p>
              <div className='rows'>
              <img width={260} src={icono4} alt="Logo"></img>
              <p style={{margin:"1em"}}>La Auditoría Interna es una actividad independiente y objetiva de aseguramiento y consulta, concebida para agregar valor y mejorar las operaciones de la Organización. Y a ti estudiante, te apoyamos para que refuerces tu profesionalimos y cumplas con esos objetivos.</p>
              </div>
            </div>

            <div>
              <h4>Requisitos</h4>
              <div>
              <ul>
                  <li>Estar inscrito en una universidad pública o privada en México</li>
              </ul>
              <h4>Documentos válidos</h4>
              <ul>
                  <li>Credencial de Estudiante vigente</li>
                  <li>Matrícula de Estudiante</li>
                  <li>Carta de vigencia de Estudiante emitida por la Institución</li>
              </ul>
              <ul>
                  <li>Estudiar tiempo completo o parcial</li>
                  <li>El estudiante debe renovar su membresía anualmente</li>
                  <li>El estatus del estudiante afiliado no debe exceder el periodo de 6 años</li>
              </ul>
              <ul>
                  <li>Solicitud de afiliación</li>
              </ul>
              <p>Enviar información a <a href="mailto:membresias@imai.org.mx">membresias@imai.org.mx</a></p>
              </div>
            </div>

            <div>
              <h4>Inscríbete</h4>
              <div>
              <p style={{margin:"1em"}}>A partir del 1 de diciembre de 2023 el Instituto Mexicano de Auditores Internos, abrió la convocatoria para que todos los estudiantes interesados puedan tramitar su inscripción y disfrutar los debeneficios que este ofrece</p>
              <ul>
                <li><i className="fas fa-phone"></i> 55-55254110 Ext. 101</li>
                <li><i className="fas fa-envelope"></i> <a href="mailto:membresias@imai.org.mx">membresias@imai.org.mx</a></li>
              </ul>
              </div>
            </div>
            </div>
        </div>
      </div>
    )

    let modal4 = this.state.isModalOpen3 && (
      <div className={`modal-overlay ${this.state.modalClosing3 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal3}>
        <div className={`modal-content ${this.state.modalClosing3 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
        <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
            <h2>¿Cuáles son los requisitos para ser miembro del IMAI - IIA?</h2>
            
            <div>
            <h4>Inscripción al IMAI-IIA</h4>
            <p>Para que los profesionales de la Auditoría Interna se conviertan en miembros del IMAI-IIA, es necesario:</p>
            <ul>
                <li>Llenar Solicitud de Inscripción.</li>
                <li>Cuota de inscripción por $1,000 pesos (esta cantidad está exenta de IVA).</li>
                <li>Enviar comprobante de pago y solicitud a: <a href="mailto:membresias@imai.org.mx"><i class="fas fa-envelope"></i> membresias@imai.org.mx</a></li>
            </ul>

            </div>

            <div>
              <h4>Vigencia</h4>
              <p>La vigencia de la membresía es de un año a partir de la fecha en el que realizó el pago y/o envió el comprobante correspondiente. Si el pago de la membresía se realiza al asistir a un Curso, Seminario, Diplomado, etc., la vigencia será de un año a partir de haberse concluido la capacitación.</p>
            </div>
            <div>
              <h4>Formas de Pago</h4>
              <p>El pago puede realizarse de la siguiente forma:</p>
              <ul>
                  <li><strong>Banco BANAMEX:</strong></li>
                  <ul>
                      <li>Depósito bancario a nombre de: “Instituto Mexicano de Auditores Internos, A.C.” Cuenta No.: 6803189 Sucursal 242</li>
                      <li>Transferencia bancaria, CLABE: 002180024268031896</li>
                  </ul>
                  <li><strong>PayPal:</strong></li>
                  <ul>
                      <li>Solicitar al correo <a href="mailto:membresias@imai.org.mx"><i class="fas fa-envelope"></i> membresias@imai.org.mx</a> la liga para poder realizar el pago con TC, posteriormente deberá enviar el comprobante al mismo correo para iniciar con el trámite de su renovación.</li>
                  </ul>
              </ul>
            </div>

            <div>
              <h4>Facturación</h4>
              <div>
              <p style={{margin:"1em"}}>Solicitar al correo membresias@imai.org.mx la liga para poder realizar el pago con TC, posteriormente deberá enviar el comprobante al mismo correo para iniciar con el trámite de su renovación.</p>
              <p className='strong'>Nota:</p>
              <p>Si su pago se realizará por medio de un Curso, Seminario, Diplomado, etc., los datos de facturación se incluyen en el formato de inscripción de la capacitación.</p>
              </div>
            </div>
            </div>
        </div>
      </div>
    )

    let modal3 = this.state.isModalOpen4 && (
      <div className={`modal-overlay ${this.state.modalClosing4 ? 'fade-out' : 'fade-in'}`} onClick={this.closeModal4}>
        <div className={`modal-content ${this.state.modalClosing4 ? 'zoom-out' : 'zoom-in'}`} onClick={e => e.stopPropagation()}>
        <div style={{ padding: "4em", fontFamily: "Arial, sans-serif" }}>
            <h6>El Instituto Mexicano de Auditores Internos, A.C., (IMAI) le hace una cordial invitación a renovar su Membresía para que continue disfrutando de los beneficios que este le ofrece.</h6>
            
            <div>
            <h4>Requisitos</h4>
            <ul>
                <li>Cuota de $1,000 pesos por concepto de renovación (esta cantidad está exenta de IVA).</li>
                <li>Actualizar sus Datos en la Solicitud de Renovación.</li>
                <li>Enviar comprobante de pago y solicitud a: <a href="mailto:membresias@imai.org.mx"><i class="fas fa-envelope"></i> membresias@imai.org.mx</a></li>
            </ul>

            </div>

            <div>
              <h4>Vigencia</h4>
              <p>La vigencia de la membresía es de un año a partir de la fecha en el que realizó el pago y/o envió el comprobante correspondiente. Si el pago de la membresía se realiza al asistir a un Curso, Seminario, Diplomado, etc., la vigencia será de un año a partir de haberse concluido la capacitación.</p>
            </div>
            <div>
              <h4>Formas de Pago</h4>
              <p>El pago puede realizarse de la siguiente forma:</p>
              <ul>
                  <li><strong>Banco BANAMEX:</strong></li>
                  <ul>
                      <li>Depósito bancario a nombre de: “Instituto Mexicano de Auditores Internos, A.C.” Cuenta No.: 6803189 Sucursal 242</li>
                      <li>Transferencia bancaria, CLABE: 002180024268031896</li>
                  </ul>
                  <li><strong>PayPal:</strong></li>
                  <ul>
                      <li>Solicitar al correo <a href="mailto:membresias@imai.org.mx"><i class="fas fa-envelope"></i> membresias@imai.org.mx</a> la liga para poder realizar el pago con TC, posteriormente deberá enviar el comprobante al mismo correo para iniciar con el trámite de su renovación.</li>
                  </ul>
              </ul>
            </div>

            <div>
              <h4>Facturación</h4>
              <div>
              <p style={{margin:"1em"}}>Para realizar su Factura es necesario enviar su Constancia de Situación fiscal actualizada asi como el comprobante de pago.</p>
              <p className='strong'>Nota:</p>
              <p>Si su pago se realizará por medio de un Curso, Seminario, Diplomado, etc., los datos de facturación se incluyen en el formato de inscripción de la capacitación.</p>
              </div>
            </div>
            </div>
        </div>
      </div>
    )
   
    return (
    <div className=''>
      
    <div class="membership-plans">
    <header class="header" style={{width:"60%", marginTop:"2%"}}>
        <h4>Membresía</h4>
    </header>
    </div>
    <section class="membership-plans">
      
        <div class="plan plan-highlighted">
            <h2>Membresía Tradicional</h2>
            <p class="price"></p>
            <ul>
                <li>Capacitación con costo preferencial</li>
                <li><img src = {icono1} width={100}/></li>
                <li>Puntos EPC</li>
                <li><img src = {icono2} width={100}/></li>
                <li>Conferencias IMAI</li>
                <li><img src = {icono3} width={100}/></li>

            </ul>
            <button onClick = {e=> this.toggleModal()}>Visualizar detalles</button>
        </div>
        <div class="plan plan-highlighted">
            <h2>Membresía estudiantil</h2>
            <p class="price"></p>
            <ul>
            <li><img src={icono4} width={260}/></li>
                
            </ul>
            <button onClick = {e=> this.toggleModal2()}>Visualizar detalles</button>
        </div>
        <div class="plan plan-highlighted">
            <h2>Inscripción</h2>
            <p class="price"></p>
            <ul>
            <li><img src={inscripcion} width={260}/></li>
            <p className='strong text-left'>¿Cuáles son los requisitos para ser miembro del IMAI - IIA?</p>
                
            </ul>
            <button onClick = {e=> this.toggleModal3()}>Visualizar detalles</button>
        </div>
        <div class="plan plan-highlighted">
            <h2>Renovación</h2>
            <p class="price"></p>
            <ul>
            <li><img src={renovación} width={260}/></li>
            <p className='strong text-left'>El Instituto Mexicano de Auditores Internos, A.C., (IMAI) le hace una cordial invitación a renovar su Membresía para que continue disfrutando de los beneficios que este le ofrece.</p>
                
            </ul>
            <button onClick = {e=> this.toggleModal4()}>Visualizar detalles</button>
        </div>
        
      </section>
      {modal1}
      {modal2}
      {modal3}
      {modal4}
      </div>
    );
  }
}

export default App;
