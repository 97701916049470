import React,{Component} from 'react'
import MUIDataTable from "mui-datatables";
import {MDBBtn,MDBIcon} from 'mdbreact'
import { Button,Modal,Menu, Dropdown, message, Card, Tag  } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './index.css'
import Report from './Report'
import swal from 'sweetalert'
import actions from './transactionsAPI'
import options from './tableMuiOptions'
import document from '../imagen/document.svg'
import view_users from '../imagen/details.svg'

class TablaCotizaciones extends Component{
  pdfExportComponent
  constructor(props){
    super(props)
    this.state = {
          datos:[],
          modal: false,
          detallesCotizaciones:[],
          detallesIdCotizaciones:[],
          modal12: false,
          tablaInicial:true,
          tablaCotizaciones:false,
          botonPdfExport:false,
          cotizacionesTotales:[],
          cotizacionesPorCliente:[],
          peticionCotizaciones:[],
          isModalVisible:false,
          clienteCotizacion:[],
          productoServicioByFolio:[],
          totales:[],
          statusCotizacion:'',
          isModalVisible12:false,
          folioAEditar:'',
          statusActual:'',
          dataContacto:[],
          fechaExpiracion:'',
          report:false,
          dataPdf:[],
          arrayContactos:[],
          cotizacionesPorContactos:[],
          tablaContactos:false,
          totalCotizacionesContactos:[],
          filterCotizacionesContactos:[]
        }
        this.handleMenuClick = this.handleMenuClick.bind(this);
    } 
    toggle12 = () => {
      this.setState({
        modal12:!this.state.modal12
      })
    }
   componentWillMount(){ 
          const fk_adminalfa  = localStorage.getItem("id_admin");  
          let array = []
          actions.getCotizacionesTabla(fk_adminalfa)
          .then(response=>{
              let cotizaciones = response.data.data.getCotizacionesTabla 
              //  console.log("cortizacion",cotizaciones)
              this.setState({peticionCotizaciones:cotizaciones}) 
              function getUniqueListBy(arr, key) {
                return [...new Map(arr.map(item => [item[key], item])).values()]
              }
                const arr1 = getUniqueListBy(cotizaciones, 'NumFolio')
                // console.log("arr1",arr1)
                let array2 = []
                arr1.filter(function(hero){
                array2.push(hero.fk_contacto)
                })
                let arrayContactos = [];
                array2.map(rows=>{
                  actions.getContactoId(rows)
                  .then(response=>{
                      arrayContactos.push(response.data.data.getContactoId[0])    
                      const arrCont = getUniqueListBy(arrayContactos, 'id_contacto') 
                      this.setState({arrayContactos:arrCont})
                    })
                })
                this.setState({cotizacionesTotales:arr1})
          ////////////////////////////////////////////////////
                response.data.data.getCotizacionesTabla.map(rows=>{
                  array.push(rows.fk_cliente)
                })

            function onlyUnique(value, index, self) { 
              return self.indexOf(value) === index;
            }
              
              // ejemplo de uso:
              var unique = array.filter( onlyUnique ); 
              let arrayClientes = []
              unique.map(rows=>{
                actions.getClienteId(rows).then(response=>{
                  arrayClientes.push(response.data.data.getClienteId[0])
                  this.setState({detallesCotizaciones:arrayClientes})
                })
                .catch(err=>{
                    console.log('error',err.response)
                    
                })
              })
            })
            .catch(err=>{                 
                console.log('error',err.response)
            })
    }

    datosIndividuales(id,num){   
        actions.getIdCotizacion(id)
       .then(response=>{
         let cotizaciones = response.data.data.getIdCotizacion     
         localStorage.setItem("rfc",cotizaciones[0].rfc)
         function getUniqueListBy(arr, key) {
          return [...new Map(arr.map(item => [item[key], item])).values()]
         }
          const arr1 = getUniqueListBy(cotizaciones, 'NumFolio')

          this.setState({cotizacionesPorCliente:arr1})
          if(num===1){
            this.showModal();
          }
          if(num === 2){
            this.showModal();
          }
    })
     .catch(err=>{
              console.log('error',err.response)
      }) 
      let filter = this.state.detallesCotizaciones.filter(function(hero){
        return hero.id_cliente === id
      })
      this.setState({clienteCotizacion:filter})
    }

 
  mostratTablaCotizaciones(){
    this.setState({tablaInicial:false})
    this.setState({tablaCotizaciones:true})
  }
  cerrarTablaCotizaciones(){
    this.setState({tablaInicial:true})
    this.setState({tablaCotizaciones:false})
    this.setState({tablaContactos:false})
  }
   showModal = () => {
     this.setState({isModalVisible:true})
  };

   handleOk = () => {
    this.setState({isModalVisible:false})
  };
  handleOk12 = () => {
    this.setState({isModalVisible12:false})
  };
  handleCancel = () => {
    this.setState({isModalVisible:false})
  };
  showModal2 = () => {
    this.setState({isModalVisible2:true})
 };
 showModal12 = () => {
  this.setState({isModalVisible12:true})
};

  handleOk2 = () => {
    let folio  = this.state.folioAEditar;
    let status = this.state.statusCotizacion;
    if(this.state.statusCotizacion){
      actions.updateStatusCotizacion(folio,status)
       .then(response=>{
         if(response.data.data.updateStatusCotizacion.message ===  "actualizacion exitosa"){
          swal({
            text:"Cotización actualizada",
            buttons: false,
            icon:'success'
          });  
          window.location.reload();
         }else{
          swal({
            text:"Algo salió mal, por favor inténtelo nuevamente",
            buttons: false,
            icon:'error'
          }); 
         }
       })
     .catch(err=>{
        console.log('error',err.response)
      })
     this.setState({IsModalVisible2:false})
    }else{
      swal({
        text:"Seleccione un valor",
        buttons: false,
        icon:'warning'
      }); 
    }
 };

 handleCancel2 = () => {
   this.setState({isModalVisible2:false})
 };
 handleCancel12 = () => {
  this.setState({isModalVisible12:false})
};

  pdfView = async(folios) => {
    let folio = folios.NumFolio
    let fechaExpiracion = folios.fechaExpiracion
    let id_contacto = folios.fk_contacto; 
    this.handleCancel();
    this.handleCancel12();
    let filter = this.state.peticionCotizaciones.filter(function(hero){
      return hero.NumFolio === folio      
    })  

    this.setState({fechaExpiracion:fechaExpiracion})
    this.setState({dataPdf:filter}) 

    actions.getProductoServicioByFolio(folio)
    .then(response=>{
      this.setState({productoServicioByFolio:response.data.data.getProductoServicioByFolio})
    })
    .catch(err=>{
            console.log('error',err.response)
    }) 
    actions.getTotalesByFolio(folio)
    .then(response=>{
      let totales =  response.data.data.getTotalesByFolio
      function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
      }
        const array1 = getUniqueListBy(totales, 'NumFolio')
        this.setState({totales:array1[0]})
    })
    .catch(err=>{
      console.log('error',err.response)
    })
    await actions.getCotizacionFk_Contactos(id_contacto)
     .then(response=>{
      this.setState({dataContacto:response.data.data.getCotizacionFk_Contactos})
     })
   .catch(err=>{
    console.log('error',err.response)
    }) 
    this.setState({tablaInicial:false})
    this.setState({tablaCotizaciones:false})
    this.setState({tablaContactos:false})
    this.setState({report:true})
  }


  consultarCotizaciones (NumFolio)  {

    let filter = this.state.peticionCotizaciones.filter(function(hero){
      return hero.NumFolio === NumFolio
    })
    
    this.datosIndividuales(filter[0].fk_cliente,2)
    if(this.state.cotizacionesPorCliente[0]){
      let filtrado = this.state.cotizacionesPorCliente.filter(function(data){
        return data.NumFolio === NumFolio
      })
  
      let fechaExpiracion = filtrado[0].fechaExpiracion;
      let objeto = {};
      objeto.NumFolio = NumFolio
      objeto.fk_contacto = filter[0].fk_contacto
      objeto.fechaExpiracion = fechaExpiracion
      this.pdfView(objeto)
    }
    
  }
 
    handleButtonClick(e) {
    message.info('Click on left button.');
    }
  
   handleMenuClick(e) {
     if(e.key == "1"){
      message.info('Cambiado a "Recibida"');
      this.setState({statusCotizacion:"Recibida"})
     }
     if(e.key == "2"){
      message.info('Cambiado a "Rechazada"');
      this.setState({statusCotizacion:"Rechazada"})
     }
     if(e.key == "3"){
      message.info('Cambiado a "Aceptada"');
      this.setState({statusCotizacion:"Aceptada"})
     }
    }
    editarStatus(NumFolio,statusActual){
      this.setState({folioAEditar:NumFolio})
      this.setState({statusActual:statusActual})
      this.showModal2();
    }
    mostrarContactos(){
      let arr1 = this.state.cotizacionesTotales
      let arr2 = this.state.arrayContactos

      let merged = [];

      for(let i=0; i<arr1.length; i++) {
        merged.push({
         ...arr1[i], 
         ...(arr2.find((itmInner) => itmInner.id_contacto === arr1[i].fk_contacto))}
        );
      }
      function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
       }
      const arr3 = getUniqueListBy(merged, 'correo1')
      this.setState({tablaInicial:false})
      this.setState({tablaCotizaciones:false})
      this.setState({tablaContactos:true})

      this.setState({totalCotizacionesContactos:merged})
      this.setState({cotizacionesPorContactos:arr3})

    }
    consultarCotizacionesContactos(param){
      let data  = this.state.totalCotizacionesContactos
      let filter  = data.filter(function(hero){
        return hero.id_contacto === param
      })

      this.setState({filterCotizacionesContactos:filter})
      this.datosIndividuales(filter[0].fk_cliente,2)
    }
    render(){
      const menu = (
        <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1" icon={<UserOutlined />}>
          Recibida
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          Rechazada
        </Menu.Item>
        <Menu.Item key="3" icon={<UserOutlined />}>
         Aceptada
        </Menu.Item>
      </Menu>
      );
      let dropdown =   <Dropdown  overlay={menu}>
      <Button danger>
        {this.state.statusCotizacion || "Editar"} <DownOutlined />
      </Button>
      </Dropdown>
      let modal2 =   <Modal width={700} title="Editar Status de la cotización" visible={this.state.IsModalVisible2} onOk={this.handleOk2} onCancel={this.handleCancel2}>
      <center>Status de la cotización actual: <strong>{this.state.statusActual}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{dropdown} </center> 
     </Modal>  
    let nombre_admin = localStorage.getItem("nombre");
    let apellidos_admin = localStorage.getItem("apellido"); 
    let puesto_admin = localStorage.getItem("puesto");
    let correo_admin = localStorage.getItem("correo");
    let telefono_admin = localStorage.getItem("telefono");
    let ext = localStorage.getItem("extensionTelefonica"); 

    let report;
    if(this.state.report === true){      
      let array = [];
      array.push({"nombre":nombre_admin,"apellido":apellidos_admin,"puesto":puesto_admin,"correo":correo_admin,"telefono":telefono_admin,"extensionTelefonica":ext})
      report = <Report adminAlfa = {array[0]} dataContacto = {this.state.dataContacto[0]} datosCliente = {this.state.clienteCotizacion[0]} totales = {this.state.totales} botonesAdmin={false} productoServicioByFolio = {this.state.productoServicioByFolio} cotizaciones = {this.state.dataPdf}/>
    }

    let modal;
    if(this.state.cotizacionesPorCliente[0]){     
        modal = <Modal width={700} title={<div><h4 className='h4tag'>Historial de cotizaciones realizadas</h4></div>} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
        <table className="text-bold table table-borderless table-small table-striped ">
            <tr>
            <td bgcolor="#5d9dd4" align='center' className='text-white'>ID</td>
            <td bgcolor="#5d9dd4" align='center' className='text-white'>Folio</td>
            <td bgcolor="#5d9dd4" align='center' className='text-white'>Fecha de emisión</td>            
            <td bgcolor="#5d9dd4" align='center' className='text-white'>Status</td>
            </tr >
      {this.state.cotizacionesPorCliente.map(rows=>{       
        let boton = <Button className="ant-btn-primary" shape="circle" size="large"type="primary" onClick={e=> this.pdfView(rows)}><img src={view_users}/></Button>
          return(
            <tr>
            <td  width="10%"><strong>{rows.id_cotizaciones}</strong></td>
            <td  width="20%"><strong>{rows.NumFolio}</strong></td>
            <td  width="30%"><strong>{rows.fechaEmision}</strong></td>
            {/* <td  width="15%"><strong>{"$" }</strong></td> */}
            <td  width="15%"><strong>{rows.statusCotizacion}</strong></td>
            <td  width="10%"><strong>{boton}</strong></td>
            </tr>
          ) 
        })}
      </table>

    </Modal>  

      }
      let modalContactos;
      if(this.state.filterCotizacionesContactos[0]){     
          modalContactos = <Modal width={700} title="Historial de Cotizaciones Realizadas" visible={this.state.isModalVisible12} onOk={this.handleOk12} onCancel={this.handleCancel12}>
       <table className="text-bold">
              <tr>
              <td><strong>ID</strong></td>
              <td><strong>Folio</strong></td>
              <td><strong>Fecha de emisión</strong></td>            
              <td><strong>Status</strong></td>
              </tr>
        {this.state.filterCotizacionesContactos.map(rows=>{       
          let boton = <MDBBtn size = "sm" color="primary" onClick={e=> this.pdfView(rows)}><img src = {view_users}/></MDBBtn>
            return(
              <tr>
              <td  width="10%"><strong>{rows.id_cotizaciones}</strong></td>
              <td  width="20%"><strong>{rows.NumFolio}</strong></td>
              <td  width="30%"><strong>{rows.fechaEmision}</strong></td>
              {/* <td  width="15%"><strong>{"$" }</strong></td> */}
              <td  width="15%"><strong>{rows.statusCotizacion}</strong></td>
              <td  width="10%"><strong>{boton}</strong></td>
              </tr>
            ) 
          })}
        </table>
  
      </Modal>  
  
        }
   
      let tablaCotizaciones;
      if(this.state.tablaCotizaciones === true){
      const columnsCotizaciones= ["Id","Folio","Fecha de emisión","Status","Visualizar","Cambiar status"];
      let boton;
      let dataCotizaciones;
      if(this.state.cotizacionesTotales[0]){
     
        dataCotizaciones = this.state.cotizacionesTotales.map(rows=>{
          let botonCotizacion = <MDBBtn className = "text-white" color="warning" size="md" onClick={e=>this.editarStatus(rows.NumFolio,rows.statusCotizacion)}>Editar</MDBBtn>
              boton = <Button type="primary" shape="circle" size="large" onClick={e=> this.consultarCotizaciones(rows.NumFolio)}><MDBIcon far icon="file-pdf" /></Button>
                    return([rows.id_cotizaciones,rows.NumFolio,rows.fechaEmision,rows.statusCotizacion,boton,botonCotizacion])
              })
        }
        let tituloListado = <h6><strong>Listado de cotizaciones</strong></h6>
        let botonListado = <Button type="danger" onClick={e=>this.cerrarTablaCotizaciones()}>Cancelar</Button>

        tablaCotizaciones=<div>
        <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >
          <Card title = {tituloListado} extra = {botonListado}>               
          <MUIDataTable  
            data={dataCotizaciones} 
            columns={columnsCotizaciones} 
            options={options} 
          />  
          </Card>
        </div>
    </div>
      }
      
      let tablaClientes;
      let pdf;
      if(this.state.tablaInicial===true){
      const columnsClientes= ["Empresa","RFC","Tipo","Status","Ver cotizaciones"];
      let boton;
      let dataClientes;
      // if(this.state.detallesCotizaciones[0]){
      dataClientes = this.state.detallesCotizaciones.map(rows=>{
        console.log(rows)
      boton = <Button type="primary" shape="circle" size="large" onClick={e=> this.datosIndividuales(rows.id_cliente,1)}><img src ={document}></img></Button>
              return([<Tag color='blue'>{rows.razonSocial}</Tag>,rows.rfc,"Cliente","Vigente",boton])
       } )
    //  }
    let titulo = <h4 className='h4tag'>Cotizaciones por clientes</h4>
    let botonCotizaciones = <Button  type="primary" onClick={e=>this.mostratTablaCotizaciones()}>Ver por cotizaciones</Button>
    let botonContactos = <Button  type="warning" onClick={e=>this.mostrarContactos()}>Ver por contactos</Button>

     tablaClientes=
    <div className='container27'>
    {titulo} 
    <div className='container'>
    {botonCotizaciones} {botonContactos}
    </div>  
      <MUIDataTable  
        data={dataClientes} 
        columns={columnsClientes} 
        options={options} 
      />    
    </div>
    }

      let tablaContactos;
      if(this.state.tablaContactos === true){
        const columnsContactos= ["Nombre","Apellidos","Correo","Puesto","Visualizar"];
        let boton;
        let dataContactos;
        if(this.state.cotizacionesTotales[0]){
       
          dataContactos = this.state.cotizacionesPorContactos.map(rows=>{
                boton = <Button type="primary" shape="circle" size="large" onClick={e=> this.consultarCotizacionesContactos(rows.id_contacto)}><MDBIcon far icon="file-pdf" /></Button>
                      return([rows.nombre,rows.apellidos,rows.correo1,rows.puesto,boton])
                })
          }
          let tituloListado = <h6><strong>Listado de contactos</strong></h6>
          let botonListado = <Button type="danger" onClick={e=>this.cerrarTablaCotizaciones()}>Cancelar</Button>
  
          tablaContactos=<div>
          <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >
            <Card title = {tituloListado} extra = {botonListado}>               
            <MUIDataTable  
              data={dataContactos} 
              columns={columnsContactos} 
              options={options} 
            />  
            </Card>
          </div>
      </div>
        }
        return(
            <div>     
              {modalContactos}  
              {tablaContactos}     
              {tablaClientes} 
              {report}
              {tablaCotizaciones}
              {pdf}        
              {modal}
              {modal2}
        </div>
        )
    }
} export default TablaCotizaciones