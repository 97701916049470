import React, { Component } from 'react';
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import {Card} from 'antd'
import MUIDataTable from "mui-datatables";
import {Button,Modal} from 'antd'
// import './index.css'
import { Menu, Dropdown, message} from 'antd';
import {ClockCircleFilled } from '@ant-design/icons';
import { PDFExport } from '@progress/kendo-react-pdf';
import titulo1 from  '../imagen/imagent1.jpg';
import { MDBTable} from 'mdbreact';
import './index.css'
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;


const PageTemplate = (props) => {
    return (
      <div  style={{position:"absolute",bottom:"10px",width:550}}>
      <center>  
      <p  className = "textabla3" color="black" style = {{marginBottom:10}}></p>
      </center>
      </div>
    );
  };
class Indicadores extends Component {
    pdfExportComponent
    constructor(props) {
        super(props);
        this.state = {
            dataProducto:[],
            tableInicio:true,
            dataVentas:[],
            tablaIndicadores:false,
            dataAlianza:[],
            datasProducto:[],
            dataServicio:[],
            dataCapacitacion:[],
            sumaTotalProducto:'',
            sumaTotalServicio:'',
            sumaTotalCapacitacion:'',
            fechaInicio:'',
            fechaFin:'',
            periodoInicial:'',
            periodoFinal:'',

        }
        this.capturarFechas =  this.capturarFechas.bind(this)
        this.handleMenuClick = this.handleMenuClick.bind(this)
    }

    componentDidMount(){
        let fk_adminalfa = localStorage.getItem("id_admin")
        axios({
            url:API,
            method:'post',
            data:{
              query:`
                query{   
                    getVentasTablaIndicadores(data:"${[fk_adminalfa]}"){
                    numFolio
                    cantidad
                    descuento
                    descuentoAplicado
                    TotalPrecioProducto
                    ProductoPrecioUnitario
                    TotalPrecioProductoIVA
                    fechaEmisionVenta
                    fechaPago
                    hora
                    banco
                    referenciaPago
                    tipoPago
                    importe
                    fechaInicialPoliza
                    statusPoliza
                    fk_productoServicio
                    fk_cliente
                    fk_adminalfa
                    fk_empresa
                    fk_contacto
                    id_productoServicio
                    tipo
                    concepto
                    precio
                    fk_empresa
                    consecutivo
                    message                    
                 } 
                }
                `  }           
             })
           .then(datos => { 
               this.setState({ventas:datos.data.data.getVentasTablaIndicadores})
           }).catch(err=>{
            console.log("error",err.response)

            console.log("error",err.response)
           })
           axios({
            url:API,
            method:'post',
            data:{
              query:`
                query{   
                    getTablaProductoServicio(data:"${[fk_adminalfa]}"){
                    id_productoServicio
                    tipo
                    concepto
                    precio 
                    fk_empresa
                    consecutivo 
                  } 
                }
                `  }           
             })
           .then(response => { 
               let dataProducto = response.data.data.getTablaProductoServicio
               this.setState({dataProducto:dataProducto})
           }).catch(err=>{
            console.log("error",err.response)
           })
    }
     handleButtonClick(param){
         let array = [];
        if(this.state.periodoInicial){
            let periodoInicial =  this.state.periodoInicial;
            let periodoFinal =  this.state.periodoFinal;

            if(param[0]){
                param.map(rows=>{
                    array.push({"id":rows.data[0],"tipo":rows.data[1],"concepto":rows.data[2],"precio":rows.data[3],"consecutivo":rows.data[4]})
                })
                let ventas =  this.state.ventas;

                let arrayFilter = [];
                let filter;
                array.map(rows=>{                
                        filter = ventas.filter(function(hero){
                            return hero.fk_productoServicio === rows.id
                        })
                        if(filter[0]){
                            arrayFilter.push(filter)
                        }
                })       
                
                // let dias = 0;
                // if(periodoInicial === "Una semana"){
                //     dias = 7
                // }else if(periodoInicial === "Un mes"){
                //     dias = 30
                // }else if(periodoInicial === "Un año"){
                //     dias = 365
                // }
                function sumarDias(fecha, dias){
                    fecha.setDate(fecha.getDate() - dias);
                    return fecha;
                }
                var d = new Date();

                var diferencia_FechaActual_y_Final = d.getTime() - periodoFinal.getTime();
                var diferenciaPeriodoAF = diferencia_FechaActual_y_Final / (1000 * 3600 * 24);

                let fechaFinal;
                if(diferenciaPeriodoAF>=0){
                    fechaFinal =  periodoFinal
                }else{
                    fechaFinal = d
                }

                var diferencia_fechaFinal_e_Inicial = fechaFinal.getTime() - periodoInicial.getTime();
                var diferenciaPeriodoFI = diferencia_fechaFinal_e_Inicial / (1000 * 3600 * 24);

                var restarFechas = sumarDias(d, diferenciaPeriodoFI)
                let busquedaPorDias = [];
                arrayFilter.map(rows=>{
                    if(rows[0]){
                        let filtrado =  rows.filter(function(param){
                            return new Date(param.fechaPago).getTime() >= new Date(restarFechas).getTime()
                        })
                        busquedaPorDias.push(filtrado)
                    }
                })

                const ventaPorDias = busquedaPorDias.filter(element => {
                    if (Object.keys(element).length !== 0) {
                    return true;
                    }
                    return false;
                });              

                let dataVentas = []
                ventaPorDias.map(rows=>{
                    const suma = rows.map(item => parseFloat(item.TotalPrecioProductoIVA)).reduce((prev, curr) => prev + curr, 0);
                    const cantidad = rows.map(item => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0);
                    dataVentas.push({"consecutivo":rows[0].consecutivo,"tipo":rows[0].tipo,"concepto":rows[0].concepto,"cantidad":cantidad,"monto":suma})
                })

                let filterAlianza = dataVentas.filter(function(param){
                    return param.tipo === "Alianza"
                })
                let filterProducto = dataVentas.filter(function(param){
                    return param.tipo === "Producto"
                })
                let filterServicio = dataVentas.filter(function(param){
                    return param.tipo === "Servicio"
                })
                let filterCapacitacion = dataVentas.filter(function(param){
                    return param.tipo === "Capacitación"
                })
           
                this.setState({dataVentas:dataVentas})
                this.setState({tableInicio:false})
                this.setState({tablaIndicadores:true})
                this.setState({dataAlianza:filterAlianza})
                this.setState({datasProducto:filterProducto})
                this.setState({dataServicio:filterServicio})
                this.setState({dataCapacitacion:filterCapacitacion})

                var totalProducto = filterProducto.reduce(function(prev, cur) {
                    return prev + cur.monto;
                }, 0);
                var totalServicio = filterServicio.reduce(function(prev, cur) {
                    return prev + cur.monto;
                }, 0);
                var totalCapacitacion = filterCapacitacion.reduce(function(prev, cur) {
                    return prev + cur.monto;
                }, 0);
                this.setState({sumaTotalProducto:totalProducto})  
                this.setState({sumaTotalServicio:totalServicio})
                this.setState({sumaTotalCapacitacion:totalCapacitacion})  
                // console.log("array",array)
                // console.log("fecha",periodo)

            }else{
                message.info('Seleccione un filtro válido');
            }
        }else{
            message.info('Seleccione un rango de periodo');
        } 
    }
      
    handleMenuClick(e) {
        if(e.key === "1"){
            message.info("Periodo de una semana seleccionado");
            this.setState({periodo:" "})
            this.setState({periodo:"Una semana"})
        }else if(e.key === "2"){
            this.setState({periodo:" "})
            message.info("Periodo de un mes seleccionado");
            this.setState({periodo:"Un mes"})
        }else if(e.key === "3"){
            this.setState({periodo:" "})
            message.info("Periodo de un año seleccionado");
            this.setState({periodo:"Un año"})
        }
    }
    cerrarVentas(){
        this.setState({tableInicio:true})
        this.setState({tablaIndicadores:false})
    }
    capturarFechas(e){
        let inicio = '';
        let fin = '';
        if(e){
             inicio = e[0]._d
             fin = e[1]._d
        }
        let fechaInicio = inicio.toString().substring(4,15)
        let fechaFin = fin.toString().substring(4,15)
        message.info(`Periodo seleccionado ${fechaInicio} a ${fechaFin}`);

        this.setState({fechaInicio:fechaInicio})
        this.setState({fechaFin:fechaFin})
        this.setState({periodoInicial:inicio})
        this.setState({periodoFinal:fin})
    }
    render() {
        const fecha = new Date();    
    var meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]; 
        let dia=fecha.getDate()
        let mesSubstring = fecha.getMonth() +1
        let posicionMes = parseInt(mesSubstring, 10)
        let mes  = meses[posicionMes - 1] 
        let año = fecha.getFullYear()  

        let fechaCompleta =  dia + " de " + mes + " del " + año 

    let datosEmpleados;
    let filtro;
       
        const options={ 
            print:true,
            download:true,
            filterType: 'dropdown',
            responsive: "stacked",
            elevation:0,
            selectableRows:"none",
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
                  
          }, onTableChange: (action, tableState) => {
            datosEmpleados = tableState.displayData
            // console.log("datosEmpleados",datosEmpleados)
          },
    
            onFilterChange: (action, filtroTable) => {
                filtro = filtroTable
                // console.log("filtro", filtro)
            }  
        }; 
        let tablaProducto;
        let dataGeneral = this.state.dataProducto
        
       
        if(this.state.tableInicio === true){
            const columnsProducto = [{
                name: "id",
                label: "Id",
                options: {
                 filter: false,
                 sort: false,
                }
               },"Tipo",{
                name: "concepto",
                label: "Concepto",
                options: {
                 filter: true,
                 sort: false,
                }
               },{
                name: "precio",
                label: "Precio",
                options: {
                 filter: false,
                 sort: false,
                }
               },{
                name: "consecutivo",
                label: "Consecutivo",
                options: {
                 filter: false,
                 sort: false,
                }
               }];
            let dataProducto = dataGeneral.map(rows=>{
                return([rows.id_productoServicio,rows.tipo,rows.concepto,rows.precio,rows.consecutivo])
           })
           let periodo = <font color="#B20F8D" size="3"><strong>Periodo no selecionado</strong></font>
           let periodoSeleccionado;
           if(this.state.fechaInicio && this.state.fechaFin){
               let inicio =  <font color="#470EA3" size="3">{this.state.fechaInicio}</font>
               let fin =  <font color="#660EA3" size="3">{this.state.fechaFin}</font>
               periodoSeleccionado = <font color="#B20F8D" size="3"><strong>Indicadores de {inicio} a {fin}</strong></font>
           }
            let tabla =  <MUIDataTable  
            title={periodoSeleccionado || periodo }  
            data={dataProducto} 
            columns={columnsProducto} 
            options={options} 
            />  
            let tituloProducto = <h6><strong>Indicadores mediante producto o servicio</strong></h6>;
            tablaProducto =<center> <div  style={{width:"90%",marginTop:"1%",marginBottom:"2%"}} >
              <Card  title = {tituloProducto}  extra={<div>
                <Button type="primary" onClick={e=>this.handleButtonClick(datosEmpleados)}>
                            Generar indicadores
                </Button>&nbsp;&nbsp;&nbsp; <RangePicker style={{width:"50%"}}size="middle" placeholder={["Inicio","Fin"]} onChange={this.capturarFechas}/>
              </div>}> 
              {tabla}
              </Card> 
          </div>
          </center>
        }
        let tablaVentas;
        if(this.state.tablaIndicadores === true){
            const columnsVentas = [{
                name: "noProducto",
                label: "NoProducto",
                options: {
                 filter: false,
                 sort: false,
                }
               },{
                name: "tipo",
                label: "Tipo",
                options: {
                 filter: true,
                 sort: false,
                }
               },{
                name: "concepto",
                label: "Concepto",
                options: {
                 filter: false,
                 sort: false,
                }
               },{
                name: "cantidad",
                label: "Cantidad",
                options: {
                 filter: false,
                 sort: false,
                }
               }
                ,{
                    name: "monto",
                    label: "Monto",
                    options: {
                     filter: false,
                     sort: false,
                    }
               }];
            let dataVentas = this.state.dataVentas.map(rows=>{
                let toFixedMonto = parseFloat(rows.monto.toFixed(2))
                let montoDosCeros = toFixedMonto.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
                let monto = montoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                return([rows.consecutivo,rows.tipo,rows.concepto,rows.cantidad,monto])
           })
           let tituloVentas = <h6><strong>Detalles del proceso de ventas</strong></h6>
           tablaVentas = <div  style={{width:"85%",marginLeft:"5%",marginTop:"1%",marginBottom:"2%"}} >
            <Card  title = {tituloVentas}  extra={<div><Button type = "primary" onClick={(e) =>{ this.pdfExportComponent.save();}}>Descargar reporte</Button>&nbsp;&nbsp;&nbsp;<Button type = "danger" onClick={e=>this.cerrarVentas()}>Cerrar Tabla</Button></div>}>
           
           <MUIDataTable  
           data={dataVentas} 
           columns={columnsVentas} 
           options={options} 
           /> 
           </Card>
          
           </div> 
        }

        let report;
        let montoAlianza;
        let dataAlianza;
        let cellAlianza;
        let montoProducto;
        let dataProducto;
        let cellProducto;
        let montoServicio;
        let dataServicio;
        let cellServicio;
        let montoCapacitacion;
        let cellCapacitacion;
        let dataCapacitacion;
        

        if(this.state.dataAlianza[0]){
            montoAlianza = this.state.dataAlianza[0].monto
            dataAlianza = this.state.dataAlianza
            cellAlianza = dataAlianza.map(rows=>{
                let toFixedMonto = parseFloat(rows.monto.toFixed(2))
                let montoDosCeros = toFixedMonto.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
                let monto = montoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                return (
                    <tr>
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.consecutivo}</th>          
                    <th width="20%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.tipo}</th>
                    <th width="50%"  style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.concepto}</th>
                    <th width="5%" style={{padding:"2px"}} className="textabla3 text-center fuente">{rows.cantidad}</th>
                    <th width="15%"  style={{padding:"2px"}} className="textabla3 text-right fuente">${monto}</th>
                    </tr>
                )
            })
        }
        if(this.state.dataProducto[0]){
            montoProducto = this.state.sumaTotalProducto
            dataProducto = this.state.datasProducto
            cellProducto = dataProducto.map(rows=>{
                let toFixedMonto = parseFloat(rows.monto.toFixed(2))
                let montoDosCeros = toFixedMonto.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
                let monto = montoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                return (
                    <tr>
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.consecutivo}</th>          
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.tipo}</th>
                    <th width="60%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.concepto}</th>
                    <th width="5%" style={{padding:"2px"}} className="textabla3 text-enter fuente">{rows.cantidad}</th>
                    <th width="15%" style={{padding:"2px"}} className="textabla3 text-right fuente">${monto}</th>
                    </tr>
                )
            })
        }
        if(this.state.dataServicio[0]){
            montoServicio = this.state.sumaTotalServicio
            dataServicio = this.state.dataServicio
            cellServicio = dataServicio.map(rows=>{
                let toFixedMonto = parseFloat(rows.monto.toFixed(2))
                let montoDosCeros = toFixedMonto.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
                let monto = montoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                return (
                    <tr>
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.consecutivo}</th>          
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.tipo}</th>
                    <th width="60%"  style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.concepto}</th>
                    <th width="5%" style={{padding:"2px"}} className="textabla3 text-enter fuente">{rows.cantidad}</th>
                    <th width="15%"  style={{padding:"2px"}} className="textabla3 text-right fuente">${monto}</th>
                    </tr>
                )
            })
        }
        if (this.state.dataCapacitacion){
          montoCapacitacion =this.state.sumaTotalCapacitacion        
         dataCapacitacion = this.state.dataCapacitacion        
         cellCapacitacion = dataCapacitacion.map(rows=>{        
             let toFixedCapacitacion = parseFloat(rows.monto.toFixed(2))
             let capacitacionDosCeros = toFixedCapacitacion.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
             let monto = capacitacionDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
             return (
                 <tr>
                 <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.consecutivo}</th>          
                 <th width="20%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.tipo}</th>
                 <th width="50%" style={{padding:"2px"}} className="textabla3 text-left fuente">{rows.concepto}</th>
                 <th width="5%" style={{padding:"2px"}} className="textabla3 text-center fuente">{rows.cantidad}</th>
                 <th width="15%" style={{padding:"2px"}} className="textabla3 text-right fuente">${monto}</th>
                 </tr>
             )
         })
        }

        let alianza;
        let producto;
        let servicio;
        let capacitacion;

        if(montoAlianza){
            let toFixedalianza = parseFloat(montoAlianza.toFixed(2))
            let alianzaDosCeros = toFixedalianza.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
            alianza = alianzaDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            // alianza = parseFloat(montoAlianza).toFixed(2)
          
        }if(montoProducto){
            let toFixedproducto = parseFloat(montoProducto.toFixed(2))
            let productoDosCeros = toFixedproducto.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
            producto = productoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            // producto = parseFloat(montoProducto).toFixed(2)
        }if(montoServicio){
            let toFixedservicio = parseFloat(montoServicio.toFixed(2))
            let servicioDosCeros = toFixedservicio.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
            servicio = servicioDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            // servicio = parseFloat(montoServicio).toFixed(2)
        }
        if(montoCapacitacion){
            let toFixedCapacitacion = parseFloat(montoCapacitacion.toFixed(2))
            let capacitacionDosCeros1 = toFixedCapacitacion.toLocaleString("en",{useGrouping:false, minimumFractionDigits:2})
            capacitacion = capacitacionDosCeros1.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")         
        }

        const tiempoTranscurrido = Date.now();
        const hoy = new Date(tiempoTranscurrido);

            report =  <div style={{ position: "absolute", left: "-5000px", top: 0 }}>            
            <PDFExport
                paperSize="letter"
                margin="1cm"
                forcePageBreak=".page-break"
                fileName={"Seguimiento de venta " + hoy.toLocaleDateString()}
                pageTemplate = {PageTemplate}
                 
                ref={(component) => this.pdfExportComponent = component}>
            <div>
                <div className='titleReport'>  
                    <div>
                        <img src={titulo1} alt="imagen" style={{width:"50%"}}/>
                        <br/>
                        <br/><p className='textabla3'><strong>Fecha considerada: </strong> {this.state.fechaInicio} - {this.state.fechaFin} <br/><strong>Fecha de emisión: </strong>{fechaCompleta}</p>
                    </div>    
                    <div>
                        <p className='parrafoADS'>ALFA DISEÑO DE SISTEMAS
                        <br/>Seguimiento de ventas</p>
                    </div>
                </div> 
            <br/><br/>
            <MDBTable class="table" striped borderless = "1"  small style={{width:555}} >
                <thead>
                    <tr>
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">No Producto</th>          
                    <th width="10%" style={{padding:"2px"}} className="textabla3 text-left fuente">Tipo</th>
                    <th width="60%"  style={{padding:"2px"}} className="textabla3 text-left fuente">Concepto</th>
                    <th width="5%" style={{padding:"2px"}} className="textabla3 text-center fuente">Qty</th>
                    <th width="15%"  style={{padding:"2px"}} className="textabla3 text-right fuente">Monto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente">Alianza</th>          
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="60%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="5%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="15%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-right text-white fuente">$&nbsp;{alianza}</th>
                    </tr>
                    {cellAlianza}
                    <tr>
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente">Producto</th>          
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="60%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="5%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="15%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-right text-white fuente">$&nbsp;{producto}</th>
                    </tr>
                    {cellProducto} 
                    <tr>
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente">Servicio</th>          
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="60%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="5%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="15%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-right text-white fuente">$&nbsp;{servicio}</th>
                    </tr>
                    {cellServicio}
                    <tr>
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla4 text-left text-white fuente">Capacitación</th>          
                    <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="60%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="5%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-left text-white fuente"></th>
                    <th width="15%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla1 text-right text-white fuente">$&nbsp;{capacitacion}</th>
                    </tr>
                    {cellCapacitacion}
                </tbody>
               
                </MDBTable>
                        
             </div>
             </PDFExport>
        </div>
        
      
        return ( 
            <div>
                {tablaProducto}
                {tablaVentas}
                {report}
            </div>
         );
    }
}
 
export default Indicadores;
