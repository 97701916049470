const people = [
    {
      id: 1,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959131/person-2_ipcjws.jpg',
      name: 'liz cuevas',
      title: 'Contadora',
      quote:
        ' Puntos a favor:El poder elaborar polizar contables partiendo de los aexhivos xml, economizas tiempo del proceso de captura Contras: Que hay poco personal de asistencia bien capacitado brindando el servicio',
    },
    {
      id: 2,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
      name: 'Jesús francisco',
      title: 'Sistemas',
      quote:
        'Comentarios: A sido buena lo unico es mejorar en los reportes Puntos a favor: Que puedes implementar y llevar un buen control de la contabilidad Contras: Los reportes no son faciles al momento de quererlos descargar y trabajar con ellos, o tardas mucho tu programando uno desde excel para que te arroje lo que deseas',
    },
    {
      id: 3,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959121/person-1_aufeoq.jpg',
      name: 'Gonzalo Mendóza',
      title: 'Recursos humanos',
      quote:
        'El instructor del curso tiene la facilidad de expresar muy bien los temas abordados, estoy motivado !!!',
    },
    {
      id: 4,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg',
      name: 'Valeria Santos',
      title: 'CEO de Empresa prueba',
      quote:
        'Definitivamente inscribiré al resto de mis colaboradores a todos las sesiones, enhorabuena!',
    },
  ];
  
  export default people;
  