import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { MDBRow, MDBCol, MDBTable} from 'mdbreact';
import {Button,Card} from 'antd'
import titulo1 from  '../imagen/imagent1.jpg';
import { PDFExport } from '@progress/kendo-react-pdf';
import MUIDataTable from "mui-datatables";
import './index.css'
import { DialogUtility } from '@syncfusion/ej2-popups';
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import { MDBTableBody, MDBTableHead } from 'mdbreact';

const PageTemplate = (props) => {
    return (
      <div  style={{position:"absolute",bottom:"10px",width:550}}>
      <center>  
      <p  className = "textabla3" color="black" style = {{marginBottom:10}}>Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México.  <br/>
     Información, soporte y ventas: Conmutador:55 3603 9970 - 55 5553 2049</p>
      </center>
      </div>
    );
  };

class PDFReport extends Component {
    pdfExportComponent
    constructor(props) {
        super(props);
        this.state = {
            botonPdfExport:false,
            enviarEmail:false,            
          }
        }
  toggleDenseTable = event => {
    this.setState({
      denseTable: event.target.checked,
    });
  };

  toggleDenseTable = event => {
    this.setState({
      denseTable: event.target.checked,
    });
  };
  onSubmitBtn = async (botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa)=>{   

    let array0 = []
    let array1 = []
    let array2 = []
    let array3 = []
    let array4 = []
    let array5 = []
    let array6 = []
    let array7 = []
    let tofixedDescuento;   
    let tofixedTotalPrecioProducto;   
    let idAdminAlfa = localStorage.getItem("id_admin")
    let idEmpresa = localStorage.getItem("fk_empresa")
    let folio = cotizaciones[0].NumFolio
    let subtotal = totales.subtotal.toFixed(2);
    let iva  = totales.iva.toFixed(2);
    let total = totales.total.toFixed(2);
    let idCliente = datosCliente.id_cliente 
    let fechaExpiracion = cotizaciones[0].fechaExpiracion
    let id_contacto = dataContacto.id_contacto
    let tipoSolicitud = cotizaciones[0].tipoSolicitud
    let fecha = cotizaciones[0].fechaEmision   
    let descuentoAplicado ;
    let TotalPrecioProducto;
    let cantidad;
    let descuento;
    let id_productoServicio;
   
    this.setState({botonPdfExport:true}) 

    productoServicioByFolio.map(rows=>{
      array0.push({"id":rows.id_productoServicio})
    })
    productoServicioByFolio.map(rows=>{ 
      array1.push({"tipo":rows.tipo})
    })
    productoServicioByFolio.map(rows=>{
      array2.push({"concepto":rows.concepto})
    })
    
    cotizaciones.map(rows=>{
      array3.push({"cantidad":rows.cantidad})
    }) 
    productoServicioByFolio.map(rows=>{
      array4.push({"precio":rows.precio})
    })
    cotizaciones.map(rows =>{
      array5.push({"descuento":rows.descuento})
    })
    cotizaciones.map(rows=>{
      tofixedDescuento = parseFloat(rows.descuentoAplicado).toFixed(2)      
      array6.push({"descuentoAplicado": tofixedDescuento})
    })
    cotizaciones.map(rows=>{ 
      tofixedTotalPrecioProducto = rows.TotalPrecioProducto.toFixed(2)
      array7.push({"TotalPrecioProducto":tofixedTotalPrecioProducto})
    })  
   
    let arr = array0.map((item, i) => Object.assign({}, item, array1[i]));
    let arr1 = array2.map((item, i) => Object.assign({}, item, arr[i]));
    let arr2 = array3.map((item, i) => Object.assign({}, item, arr1[i]));
    let arr3 = array4.map((item, i) => Object.assign({}, item, arr2[i]));
    let arr4 = array5.map((item, i) => Object.assign({}, item, arr3[i]));
    let arr5 = array6.map((item, i) => Object.assign({}, item, arr4[i]));
    let arr6 = array7.map((item,i) => Object.assign({}, item, arr5[i]));  
  
     arr6.map(rows=>{
        descuentoAplicado = rows.descuentoAplicado   
        TotalPrecioProducto = rows.TotalPrecioProducto
        cantidad = rows.cantidad
        descuento = rows.descuento
        id_productoServicio = rows.id
       axios({
        url:API,
        method:'post',
        data:{
            query:`
            mutation{
            insertCotizaciones(data:"${[fecha,folio,cantidad,descuento,descuentoAplicado,TotalPrecioProducto,idCliente,id_productoServicio,idAdminAlfa,idEmpresa,fechaExpiracion,id_contacto,tipoSolicitud]}"){
                 message
                } 
            }
            `
        }   
        }).then(response=>{
          if(response.data.data.insertCotizaciones.message == "registro exitoso"){
            DialogUtility.alert({
              title:'registro exitoso' ,
              content: "Cotizacion registrada",
          });  
          }else{
            DialogUtility.alert({
              title:'Aviso!' ,
              content: "Algo salio mal",
            }); 
          }
        })
        .catch(err=>{
            console.log('error',err.response)
            // console.log('error',err)
  
        })     
      })   

    axios({
      url:API,
      method:'post',
      data:{
          query:`
          mutation{
          insertTotales(data:"${[folio,subtotal,iva,total]}"){
               message
              } 
          }
          `
      }   
      }).then(response=>{
          DialogUtility.alert({
            title:'registro exitoso' ,
            content: "Cotizacion registrada",
        });  
      })
      .catch(err=>{
          console.log('error',err)
  
      })
    }
    ejecutarEnvio(){
    DialogUtility.alert({
      title:'AVISO !' ,
      content: "Estimado usuario, para que el sistema pueda enviar correctamente su cotización, el documento debe guardarse en la carpeta descargas de su computadora.",
    });    
  
    setTimeout(()=>{
        this.setState({enviarEmail:true})
      },7000)
    }
    enviarEmail(){
      const nombreAdmin = localStorage.getItem("nombre");
      const apellidoAdmin = localStorage.getItem("apellido")
      const correoAdmin = localStorage.getItem("correo")
      axios({
        url:API,
        method:'post',
        data:{
            query:`
            mutation{
              sendEmailCotizacion(data:"${[this.state.nombreCotizacion+".pdf", nombreAdmin + " " + apellidoAdmin,correoAdmin]}"){   
                message                             
               } 
            }
          `
        }   
        }).then(response=>{
          if(response.data.data.sendEmailCotizacion.message === "Correo Enviado") {
            this.showModal2();         
          }
        }).catch(err=>{
          console.log(err.response)
        })
    }
  render() {
    const options = { 
            print:false,
            filter:false,
            download:false,
            jumpToPage:false,
            pagination:false,
            search:false,
            sort:false,
            viewColumns:false,
            filterType:"drowpdawn",
            responsive: "scrollMaxHeight",
            elevation:0,
            selectableRows:"none",
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          }
        };
      
        const {botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa} = this.props

        let botonEnvio;
        let botonRegistro;
        let botonDescagar;        
        let botoncerrar;
        if(botonesAdmin === true){
         if(this.state.enviarEmail === true) {
            botonEnvio = <Button className="text-white"style={{background: "#27A584"}} type = "dashed"  onClick = {e=>this.enviarEmail()}>Enviar por correo</Button>
        }  
          botonRegistro = <Button type="primary" disabled = {this.state.botonPdfExport}  onClick = {e=> this.onSubmitBtn(botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa)}> Registrar cotización </Button>
            if(this.state.botonPdfExport === true) {
               botonDescagar = <Button type="primary" ghost onClick={(e) => { this.pdfExportComponent.save(); this.ejecutarEnvio()}}>Descargar Cotización</Button>                          
          }
        }
        if(botonesAdmin === false){
                          botoncerrar = <Button type = "dashed" danger  onClick = {e=>window.location.reload()}>Cerrar</Button>
                          botonDescagar = <Button type="primary" ghost onClick={(e) => { this.pdfExportComponent.save();}}>Descargar Cotización</Button> 
        }
        let array0 = []
        let array1 = []
        let array2 = []
        let array3 = []
        let array4 = [] 
        let array5 = []
        let array6 = []
        let array7 = []        
        let precioDosCeros;
        let precio;    
        let descuentoAplicadoDosCeros;
        let descuentoAplicado;
        let TotalPrecioProductoDosCeros;
        let TotalPrecioProducto;
        let tofixedDescuento;
        let tofixedTotalPrecioProducto;

        productoServicioByFolio.map(rows=>{
          console.log("rows",rows)
          array0.push({"id":rows.id_productoServicio})
        })
        productoServicioByFolio.map(rows=>{ 
          array1.push({"tipo":rows.tipo})
        })
        productoServicioByFolio.map(rows=>{
          array2.push({"concepto":rows.concepto})
        })
        
        productoServicioByFolio.map(rows=>{
          array3.push({"cantidad":rows.cantidad})
        }) 
        productoServicioByFolio.map(rows=>{
          precioDosCeros = rows.precio.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          precio =  precioDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          array4.push({"precio":precio})
        })
        productoServicioByFolio.map(rows =>{
          array5.push({"descuento":rows.descuento})
        })
        productoServicioByFolio.map(rows=>{
        if(botonesAdmin === false){
                        tofixedDescuento = parseFloat(rows.descuentoAplicado)
                      }else {
                        tofixedDescuento = rows.descuentoAplicado
                      }
          descuentoAplicadoDosCeros = tofixedDescuento.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          descuentoAplicado =  descuentoAplicadoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            array6.push({"descuentoAplicado":descuentoAplicado})
        })
        productoServicioByFolio.map(rows=>{
          if(botonesAdmin === false){
                        tofixedTotalPrecioProducto = parseFloat(rows.TotalPrecioProducto)
                      }else {
                        tofixedTotalPrecioProducto = rows.TotalPrecioProducto
                      }
          TotalPrecioProductoDosCeros = tofixedTotalPrecioProducto.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          TotalPrecioProducto = TotalPrecioProductoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          array7.push({"TotalPrecioProducto":TotalPrecioProducto})
        })
       
        let arr = array0.map((item, i) => Object.assign({}, item, array1[i]));
        let arr1 = array2.map((item, i) => Object.assign({}, item, arr[i]));
        let arr2 = array3.map((item, i) => Object.assign({}, item, arr1[i]));
        let arr3 = array4.map((item, i) => Object.assign({}, item, arr2[i]));
        let arr4 = array5.map((item, i) => Object.assign({}, item, arr3[i]));
        let arr5 = array6.map((item, i) => Object.assign({}, item, arr4[i]));
        let arr6 = array7.map((item,i) => Object.assign({}, item, arr5[i]));
        const columnsCotizaciones= ["Producto","Descripción","QTY","Precio","%","Descuento","Total"];

        let data  = arr6.map(rows=>{
            return([rows.tipo,rows.concepto,rows.cantidad,"$"+ rows.precio,rows.descuento+"%","$"+rows.descuentoAplicado,"$"+rows.TotalPrecioProducto])
        })
        let tablaPRueba =  <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >   
      </div>     
        // ****Fecha expiracion****
        const fechaExpiracion = new Date(productoServicioByFolio[0].fechaExpiracion);
          var letrasMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
          let diaExpiracion = fechaExpiracion.getDate()
          let letraMesSubstring = fechaExpiracion.getMonth() +1
          let numeroPosicionMes = parseInt(letraMesSubstring, 10)
          let mesExpiracion  = letrasMeses[numeroPosicionMes - 1] 
          let añoExpiracion= fechaExpiracion.getFullYear()  
        // ********
        let emision =  productoServicioByFolio[0].fechaEmision.substring(0,10)
      
        let titulo = <strong className="CardTexto">Cotización emitida el {emision} &nbsp;&nbsp;&nbsp; Expiración {diaExpiracion+"/"+mesExpiracion+"/"+añoExpiracion} </strong>
        var meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        var f=new Date();
        let dia = emision.substring(0,2)
        let mesSubstring = emision.substring(3,5)
        let posicionMes = parseInt(mesSubstring, 10)
        let mes  = meses[posicionMes - 1]
        let año = emision.substring(6,10)       

        let ivaFloat = parseFloat(totales.iva).toFixed(2)
        let ivaGlobalDosCeros = ivaFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let iva =  ivaGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let subtotalFloat = parseFloat(totales.subtotal).toFixed(2)
        let subtotalGlobalDosCeros = subtotalFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let subtotal =  subtotalGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let totalFloat = parseFloat(totales.total).toFixed(2)
        let totalGlobalDosCeros = totalFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let total =  totalGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        return ( 
          <div>
             <MDBRow>
    <MDBCol size="6">  
          {/* <Card  title={titulo} extra = {<div>{botonRegistro}&nbsp;&nbsp;&nbsp;{botonDescagar}&nbsp;&nbsp;&nbsp;{botonEnvio}&nbsp;&nbsp;&nbsp;{botoncerrar}</div>}>     */}
          {/* {tablaPRueba} */}
          <Paper  style={{ width:"100%",height:"98%", marginBottom:"2%",marginTop:"2%"}}>
            <center>
              <MDBRow style={{marginTop:"2%"}}>
                    <MDBCol size = "6">
                      <img src={titulo1} alt="imagen"  style={{width:"40%"}}/>
                    </MDBCol>  
                    <MDBCol size = "6">
                      <p style={{marginTop:"3%"}}>COTIZACIÓN NO. {productoServicioByFolio[0].NumFolio}</p> 
                    </MDBCol> 
              </MDBRow>
              </center>
              <div  style={{marginLeft:"55%",marginTop:"4%"}}>
                Ciudad de Mexico a {dia + " de " + mes + " de " + año}
              </div>
              <div style={{marginLeft:"5%",marginTop:"2%"}}>
                       <p>{datosCliente.razonSocial}</p>                     
                       <p >{dataContacto.nombre}&nbsp;{dataContacto.apellidos}</p>                    
                       <p>{dataContacto.puesto}</p>
                       <br></br>
              
              <p  face="Verdana"><strong>En base a su amable solicitud, me permito poner a su consideración nuestra propuesta referente a los productos y/o servicios de su interés.</strong></p>
              </div>
            
              <div style={{marginTop:"2%"}}>            
              {/* <center> */}
              {/* <div style={{width:"99%"}}>     */}
                <MUIDataTable  
                style={{width:"95%"}}
                data={data} 
                columns={columnsCotizaciones} 
                options={options} 
                />  
                <table style={{marginLeft:"69%"}}>
                <tr>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="left" className="totales"><strong>SUBTOTAL</strong></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="right" className="totales">$&nbsp;{subtotal}</td>
               </tr>
               <tr>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center" ></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="left" className="totales"><strong>IVA</strong></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="right" className="totales">$&nbsp;{iva}</td>
               </tr>
               <tr>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF"  align="left" className="totales"><strong>TOTAL</strong></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="right" className="totales">$&nbsp;{total}</td>
               </tr>
                </table>
              {/* </div> */}
            {/* </center>             */}
            <br/>
            <div style={{marginLeft:"5%"}}>    
                <p><strong> Nota:</strong><i> Vigencia {diaExpiracion +" de "+mesExpiracion+" del "+añoExpiracion}</i>, el costo no incluye Interfaz, Formatos, Carga de Catálogos o alguna implementación adicional a la mencionada en su cotización.</p>        
                <p><strong> No se aceptan devoluciones</strong></p>           
                <p style={{color:"#3371FF"}}><strong>Condiciones Comerciales y Formas de Pago</strong></p>
                <ul>
            <li>Todos los costos anteriormente presentados son más IVA.</li>
                        <li>Precios representados en M.N.</li>
                        <li>Pago por anticipado</li>
                        <li>Pago por depósito bancario o transferencia electrónica.
                <ul>
                    <li><b>Cuenta:</b>50020978434&nbsp;-&nbsp;<b>Clabe:</b> 036180500209784346</li>   
                    <li><b>Beneficiario:</b> ALFA DISEÑO DE SISTEMAS, S.A. de C</li>         
                    <li><b>Banco:</b> Inbursa</li> 
                </ul>
                </li>		
            </ul>     
      
            <p>Sin más por el momento y agradeciéndole por su amable atención,
                Quedo a sus órdenes para cualquier duda al respecto. </p>
            </div>
            <fort> 
                <p style= {{marginLeft:"5%"}}>               
                {adminAlfa.nombre + " " +  adminAlfa.apellido}                 
                    <br></br> 
                {adminAlfa.puesto}
                    <br></br>
                {adminAlfa.correo}
                    <br></br>
                {"Tel." + " " + adminAlfa.telefono + " " + "Ext." + adminAlfa.extensionTelefonica }                
                </p>
                
                    <p  position= "absolute" className="footert text-center py-3">Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México <br></br> Información, soporte y ventas:
                    Conmutador:55 3603 9970 - 55 5553 2049</p>             
            </fort  > 
            </div>
            </Paper>
            </MDBCol>
            {/* ************* */}
            <MDBCol size="6">
            <Paper style={{ width:"99%",height:"98%", marginBottom:"2%",marginTop:"2%"}}>
            <center>
    <MDBRow style={{marginTop:"2%"}} >
      <MDBCol size="6">
      <img src={titulo1} alt="imagen" style={{width:"40%"}} />
      </MDBCol>
    </MDBRow>
    </center>
      <br></br>                    
      <div style={{marginLeft:"5%",marginTop:"2%",marginRight:"5%"}}>
    <p>En Alfa y Diseño de Sistemas (ADS) respaldamos su inversión en los sistemas <strong>ContPAQi</strong>, por lo que
       ponemos a su disposición nuestro programa de pólizas de servicio, las cuales incluyen los siguientes
       beneficios: 
    </p>
    </div>
    <center>
    <MDBTable striped style={{width:"90%"}} small bordered>
      <MDBTableHead>
        <tr align="center" >
        <th className= "alineacion"><strong>Beneficios</strong></th>
        <th className= "alineacion"><strong>Cobertura</strong></th>
        <th className= "alineacion"><strong>Descripción</strong></th>                     
        </tr>
      </MDBTableHead>
      <MDBTableBody >
      <tr >
                <td  className= "alineacion" align="left" >Formación</td>   
                <td className= "alineacion" align="center">Incluida </td>        
                <th className= "alineacion" align="left">
                  Al ser cliente ADS podrá acceder <strong>SIN COSTO</strong> a los cursos, talleres y
                  conferencias exclusivos
                </th>                     
              </tr>              
              <tr>
                <td className= "alineacion" align="left">Soporte Remoto</td>    
                <td className= "alineacion" align="center">Ilimitada</td>        
                <th className= "alineacion" align="left">
                  Podrá utilizar nuestro servicio de soporte técnico remoto de manera ilimitada durante el periodo de su póliza, el cual consiste en ofrecer al
                  cliente la posibilidad de atender sus incidentes relacionados con los sistemas <strong>ContPAQi</strong>, por medio de internet, utilizando un software específico de control 
                  remoto, con una capacidad de veinte consolas de atención.
                </th>                            
              </tr>
              <tr>
                <td className= "alineacion" align="left">Asesoría Telefónica</td>
                <td className= "alineacion" align="center">Ilimitada</td>        
                <th className= "alineacion" align="left">
                   Podrá contactar a nuestros asesores vía telefónica, para cualquier duda relacionado con el uso de los sistemas <strong>ContPAQi</strong>. 
                </th>                                               
              </tr> 
              <tr>
                <td className= "alineacion" align="left">Boletines Informativos</td>    
                <td className= "alineacion" align="center">Mensual</td>        
                <th className= "alineacion" align="left">
                  Nuestro servicio informativo lo mantendrá actualizado de los cambios, nuevas versiones, funcionalidades, promociones, etc. Referente a los sistemas <strong>ContPAQi</strong>.
                  Así como información de los eventos, cursos, conferencias que hemos organizado para ti en ADS.
                </th>  
                                      
              </tr>  
              <tr>               
                <td className= "alineacion" align="left">Cliente ADS</td>    
                <td className= "alineacion" align="center">Incluida</td>        
                <th className= "alineacion" align="left">
                  Al contar con su póliza vigente recibirá un usuario y contraseña, para ingresar al Portal de Cliente ADS, donde encontrara contenido
                  exclusivo para clientes de ADS. 
                </th> 
                                           
              </tr>    
              <tr>               
                <td className= "alineacion" align="left">Instalaciones y/o reinstalaciones</td>  
                <td className= "alineacion" align="center">Incluida</td>        
                <th className= "alineacion" align="left">
                  Su póliza le permite realizar instalaciones o reinstalaciones de sus licencias sin costo adicional.
                </th>              
              </tr> 
                 
              <tr>               
                <td className= "alineacion" align="left">Descuentos</td>  
                <td className= "alineacion" align="center">Incluida</td>        
                <th className= "alineacion" align="left">
                  Al ser cliente ADS podrá obtener descuentos especiales en visitaspersonalizadas, talleres, cursos, diseño de reporte a la medida,
                  desarrollo especiales y todos aquellos servicios no incluidos en la póliza básica
                </th>              
              </tr>
      </MDBTableBody>
    </MDBTable>
    </center>
    {/* <div style={{width:565}}> */}
    <center>        
            <table  border ="1"  style={{width:"90%"}} >
              <tr>                
                <td className= "alineacion" style={{color:"#3371FF"}} align="center" colspan="3">PÓLIZA DE SOPORTE REMOTA BASICA</td>                
              </tr>
              <tr>
                <td className= "alineacion" align="center">Póliza Trimestral</td>   
                <td className= "alineacion" align="center">Póliza Semestral</td>        
                <td className= "alineacion" align="center">Póliza Anual</td>                     
              </tr>              
              <tr>
                <td className= "alineacion" align="center">$3000.00</td>    
                <td className= "alineacion" align="center">$5,000.00</td>        
                <td className= "alineacion" align="center">$8,000.00</td>                            
              </tr>  
              <tr>               
                <th className= "alineacion" align="left" colspan="3">
                  <strong>Notas:</strong>
                  <ul>
                    <li><strong>PRECIOS POR SISTEMA</strong></li>
                    <li><strong>PRECIOS MAS IVA</strong></li>
                    <li>Los horarios de servicio son de lunes a viernes de 8:00 a 14:00 y de 15:00 A 18:00</li>
                    <li>Guardia de 6:00 a 9:00 sábados de 10:00 a 2:00</li>
                    <li>La póliza no incluye visitas en sitio, reproceso de información, cambio de servidor, Interfaces, capacitaciones e implementaciones.</li>
                    <li style={{color:"#2E0DA8"}}><strong>No se aceptan cambios ni devoluciones</strong></li>
                 </ul>
              </th>   
              </tr>                
            </table>
            </center>
            <br></br>
            <center>
            <table  border ="1"  style={{width:"90%"}}>
              <tr>                
                <td className= "alineacion" style={{color:"#3371FF"}}  align="center" colspan="7">CAPACITACIÓN</td>                
              </tr>
              <tr>
                <td className= "alineacion" align="left" colspan="6">Curso de capacitación básico 1 persona por sistema Presencial ADS</td>   
                <td className= "alineacion" align="right">$1,400.00 </td>        
              </tr>              
              <tr>
                <td className= "alineacion" align="left" colspan="6">Membresía anual ContPAQI Kursa auto capacitación individual </td>    
                <td className= "alineacion" align="right">$1,990.00</td>        
              </tr>  
              <tr>               
                <th className= "alineacion" colspan="7" >
                  <strong>Notas:</strong>
                  <ul>                    
                    <li><strong>PRECIOS MAS IVA</strong></li>
                    <li><strong>No transferible ligada a cuenta de correo</strong></li>                    
                 </ul>
              </th>   
              </tr>                
            </table>
            </center>
            {/* </center> */}
            {/* </div> */}
            </Paper>
           </MDBCol>
            {/* </Card>         */}
            </MDBRow>          
            </div>   
        );
    }
}
 
export default PDFReport;