import React, { Component } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import './index.css';

class PDFViewerComponent extends Component {
  generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);

    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const titleFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    const text = [
      { title: 'Requerimientos técnicos para los servicios de capacitación online', y: 750 },
      { title: 'Plataforma Zoom', y: 720 },
      { title: 'Zoom es un sistema de videoconferencia o de reuniones virtuales, accesible desde computadoras tradicionales ', y: 700, font: timesRomanFont, size: 12 },
      
      { title: 'Requerimientos:', y: 680 },
      { title: 'Computadora:', y: 660 },
      { title: '• Doble núcleo de 2 GHz o superior (i3/i5/i7 o equivalente AMD).', y: 640, font: timesRomanFont, size: 12 },
      { title: '• Memoria RAM de 4 Gb exclusivos para Zoom (recomendamos 6 Gb por el uso de otros procesos).', y: 620, font: timesRomanFont, size: 12 },
      { title: 'Sistema operativo:', y: 600 },
      { title: '• Mac OS X con Mac OS 10.7 o posterior.', y: 580, font: timesRomanFont, size: 12 },
      { title: '• Windows 7 o superior (se recomienda Windows 10).', y: 560, font: timesRomanFont, size: 12 },
      { title: '• Móviles con Android o IOS con el Zoom descargado e instalado (desde la tienda de aplicaciones).', y: 540, font: timesRomanFont, size: 12 },
      { title: 'Internet:', y: 520 },
      { title: '• De bajada de 10 Mb/s como mínimo (recomendamos 20).', y: 500, font: timesRomanFont, size: 12 },
      { title: '• De subida de 2 Mb/s como mínimo Nota: Los proveedores de internet proporcionan el dato de bajada y de subida es el 10% de este.', y: 480, font: timesRomanFont, size: 12 },
      { title: 'Dispositivos:', y: 460 },
      { title: '• Cámara HD para interactuar con instructor.', y: 440, font: timesRomanFont, size: 12 },
      { title: '• Audífonos.', y: 420, font: timesRomanFont, size: 12 },
      { title: '• Micrófono.', y: 400, font: timesRomanFont, size: 12 },
      { title: 'Nota: Se recomienda los dispositivos dos en uno (audífono con micrófono) y NO usar el micrófono que viene integrado en algunas computadoras (sobre todo laptops).', y: 380, font: timesRomanFont, size: 12 },
      { title: 'TUTORIAL PARA INGRESAR COMO PARTICIPANTE AL CURSO EN VIDEO CONFERENCIA EN EL SOFTWARE ZOOM', y: 360 },
      { title: 'Una vez realizado el pago correspondiente a la capacitación en línea y máximo una hora antes del inicio del curso se te hará llegar por correo electrónico un enlace, así como usuario y contraseña de acceso a la plataforma del IMAI.', y: 340, font: timesRomanFont, size: 12 },
      { title: 'Sigue ese vínculo, al ingresar a la plataforma en el apartado contenido encontrarás los siguientes enlaces: a) “Webinar en vivo”, b) Material del participante y c) Grabación del curso.', y: 320, font: timesRomanFont, size: 12 },
      { title: 'Selecciona “Webinar en vivo” que al dar clic en el horario de la impartición del evento serás dirigido a este a través de la aplicación Zoom en la que habrá dos opciones:', y: 300, font: timesRomanFont, size: 12 },
      { title: '1) Si estás familiarizado con esta aplicación y ya la tienes instalada en tu dispositivo o computadora, ingresarás de inmediato al curso.', y: 280, font: timesRomanFont, size: 12 },
      { title: '2) En caso de que tu dispositivo no tenga instalada la aplicación Zoom, deberás hacer estos breves pasos:', y: 260, font: timesRomanFont, size: 12 },
      { title: 'Dentro de la aplicación', y: 240 },
      { title: '1) Al ingresar, te aparecerá una ventana con el siguiente mensaje “Por favor, espere, el Anfitrión le permitirá ingresar pronto”. En seguida el Instructor validará la invitación y te dará acceso a la sesión a la que fuiste invitado.', y: 220, font: timesRomanFont, size: 12 },
      { title: '2) En la parte inferior de la pantalla podrás ubicar los controles que te permitirán habilitar, ajustar o deshabilitar el audio, video y Chat de tu equipo, estos ajustes te permitirán interactuar con el instructor y con el resto de los participantes.', y: 200, font: timesRomanFont, size: 12 },
      { title: 'Con el fin de optimizar la sesión, el Instructor podría solicitar que, durante la exposición, deshabiliten los micrófonos y que estos se habiliten cuando usted vaya a intervenir o a participar.', y: 180, font: timesRomanFont, size: 12 }
    ];

    text.forEach(({ title, y, font = titleFont, size = 16 }) => {
      page.drawText(title, { x: 50, y, size, font, color: rgb(0.062, 0.184, 0.357) });
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'requisitos_curso.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  componentDidMount() {
    this.generatePDF();
  }

  render() {
    return (
      <div className="pdf-container">
        <h1 className="pdf-title">Requerimientos técnicos para los servicios de capacitación online</h1>
        <div className="pdf-content">
          <h2>Plataforma Zoom</h2>
          <p>Zoom es un sistema de videoconferencia o de reuniones virtuales, accesible desde computadoras tradicionales y desde aparatos móviles. Cabe destacar que usted se registrará por el sitio web del IMAI y no por esta plataforma por lo que sus datos estarán protegidos ya que no pasarán por ninguna otra Institución (incluido el Zoom).</p>

          <h2>Requerimientos:</h2>
          
          <h3>Computadora:</h3>
          <ul>
            <li>Doble núcleo de 2 GHz o superior (i3/i5/i7 o equivalente AMD).</li>
            <li>Memoria RAM de 4 Gb exclusivos para Zoom (recomendamos 6 Gb por el uso de otros procesos).</li>
          </ul>

          <h3>Sistema operativo:</h3>
          <ul>
            <li>Mac OS X con Mac OS 10.7 o posterior.</li>
            <li>Windows 7 o superior (se recomienda Windows 10).</li>
            <li>Móviles con Android o IOS con el Zoom descargado e instalado (desde la tienda de aplicaciones).</li>
          </ul>

          <h3>Internet:</h3>
          <ul>
            <li>De bajada de 10 Mb/s como mínimo (recomendamos 20).</li>
            <li>De subida de 2 Mb/s como mínimo Nota: Los proveedores de internet proporcionan el dato de bajada y de subida es el 10% de este.</li>
          </ul>

          <h3>Dispositivos:</h3>
          <ul>
            <li>Cámara HD para interactuar con instructor.</li>
            <li>Audífonos.</li>
            <li>Micrófono.</li>
            <li>Nota: Se recomienda los dispositivos dos en uno (audífono con micrófono) y NO usar el micrófono que viene integrado en algunas computadoras (sobre todo laptops).</li>
          </ul>

          <h2>TUTORIAL PARA INGRESAR COMO PARTICIPANTE AL CURSO EN VIDEO CONFERENCIA EN EL SOFTWARE ZOOM</h2>
          <p   >Una vez realizado el pago correspondiente a la capacitación en línea y máximo una hora antes del inicio del curso se te hará llegar por correo electrónico un enlace, así como usuario y contraseña de acceso a la plataforma del IMAI. Sigue ese vínculo, al ingresar a la plataforma en el apartado contenido encontrarás los siguientes enlaces: a) “Webinar en vivo”, b) Material del participante y c) Grabación del curso. Selecciona “Webinar en vivo” que al dar clic en el horario de la impartición del evento serás dirigido a este a través de la aplicación Zoom en la que habrá dos opciones: 1) Si estás familiarizado con esta aplicación y ya la tienes instalada en tu dispositivo o computadora, ingresarás de inmediato al curso. 2) En caso de que tu dispositivo no tenga instalada la aplicación Zoom, deberás hacer estos breves pasos: Dentro de la aplicación 1) Al ingresar, te aparecerá una ventana con el siguiente mensaje “Por favor, espere, el Anfitrión le permitirá ingresar pronto”. En seguida el Instructor validará la invitación y te dará acceso a la sesión a la que fuiste invitado. 2) En la parte inferior de la pantalla podrás ubicar los controles que te permitirán habilitar, ajustar o deshabilitar el audio, video y Chat de tu equipo, estos ajustes te permitirán interactuar con el instructor y con el resto de los participantes. Con el fin de optimizar la sesión, el Instructor podría solicitar que, durante la exposición, deshabiliten los micrófonos y que estos se habiliten cuando usted vaya a intervenir o a participar..</p>
        </div>
        <button className="pdf-button" onClick={this.generatePDF}>Descargar PDF</button>
      </div>
    );
  }
}

export default PDFViewerComponent;
