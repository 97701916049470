import React, { Component } from 'react'
import actionsAPI from './actionsAPI';
import MUIDataTable from "mui-datatables";
import {Button, Card, Tag} from 'antd'
import { Modal, Table } from 'antd';
import cv from '../imagen/pdf_ok.png'
import cv_not from '../imagen/pdf_not.png'
import cv_pending from '../imagen/pdf_pending.png'
import swal from 'sweetalert';

class Users_Plataform extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            users_plataform:[],
            user_registered:[],
            all_cv:[],
            isModalVisible: false
        }
    }
    async componentWillMount(){
        await this.get_all_cv();
        
        actionsAPI.get_users_plataform().then(response=>{
            this.setState({users_plataform:response.data.data.get_users_plataform})
        })

        actionsAPI.getAllContactos().then(response=>{
            let dataUser = this.state.users_plataform;
            let res = response.data.data.getAllContactos;
            let result = dataUser.filter((o1) => {
                return res.some((o2) => {
                    if(o2.correo1 === o1.correo){
                    o1.fk_cliente = o2.fk_clientesads
                    return o1
                    }else{
                    o1.fk_cliente = ""
                    return o1
                    }
                });
            });
            let all_cv = this.state.all_cv

           
            let arr1 = result.map((item, i) => Object.assign({}, item, all_cv[i]));

            this.setState({user_registered:arr1})            
            }).catch(err=>{
                console.log("error",err)
                console.log("error",err.response)

          })
    }
    get_all_cv() {
        actionsAPI.get_all_cv().then((response) => {
            this.setState({all_cv:response.data.data.get_all_cv})
        }).catch(err=>{
          console.log("eerr",err.response) 
    })}

    aprobar_cv(rows) {
        console.log("rows",rows)
        actionsAPI.aprobar_cv(rows.id_cv).then((response) => {
            swal({
                text: 'Acción realizada',
                icon: 'success',
              });
              setTimeout(()=>{
                window.location.reload()
              },2000)
        }).catch(err=>{
          console.log("eerr",err.response) 
    })
}
    rechazar_cv(rows) {
        actionsAPI.rechazar_cv(rows.id_cv).then((response) => {
            swal({
                text: 'Acción realizada',
                icon: 'success',
            });
            setTimeout(()=>{
                window.location.reload()
            },2000)
        }).catch(err=>{
        console.log("eerr",err.response) 
    })
    }
    showModal = (param) => {
        this.setState({ isModalVisible: true });
        this.setState({userData:param})
      };
    
      handleOk = () => {
        this.setState({ isModalVisible: false });
      };
    
      handleCancel = () => {
        this.setState({ isModalVisible: false });
      };

    
    render() { 
        let userData = this.state.userData;
        let modal;
       
        if(userData){
            const columns2 = [
                {
                  title: 'Campo',
                  dataIndex: 'campo',
                  key: 'campo',
                  render: text => <b>{text}</b>
                },
                {
                  title: 'Valor',
                  dataIndex: 'valor',
                  key: 'valor',
                  render: (text, record) => record.campo === 'URL CV' ? <a href={text} target="_blank" rel="noopener noreferrer">Ver Documento</a> : text
                }
            ];
    
            const data2 = [
                { key: '1', campo: 'Apellidos', valor: userData.apellidos },
                { key: '2', campo: 'Correo', valor: userData.correo },
                { key: '3', campo: 'CP', valor: userData.cp },
                { key: '4', campo: 'Estatus Aprobacion', valor: userData.estatus_aprobacion },
                { key: '5', campo: 'Fecha Carga', valor: userData.fecha_carga },
                { key: '8', campo: 'Genero', valor: userData.genero },
                { key: '12', campo: 'Nombre', valor: userData.nombre },
                { key: '14', campo: 'Profesion', valor: userData.profesion },
                { key: '15', campo: 'Rango Edad', valor: userData.rango_edad },
                { key: '16', campo: 'Telefono', valor: userData.telefono },
                { key: '17', campo: 'Telefono Empresa', valor: userData.telefono_empresa },
                { key: '18', campo: 'URL CV', valor: userData.url_cv }
              ];
             modal = <div>
            <Modal
                width={800}
                title="Detalles de cliente"
                visible={this.state.isModalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                <Table
                    columns={columns2}
                    dataSource={data2}
                    pagination={false}
                    bordered
                />
                </Modal>
          </div>
        }
        const options={ 
            print:false,
            download:false,
            filterType:"drowpdawn",
            responsive: "stacked",
            elevation:1,
            selectableRows:"none",
            textLabels:{
            body: {
            noMatch: "Lo sentimos, no se encontraron registros coincidentes",
            toolTip: " Ordenar",
            columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
            next: "Página siguiente",
            previous: "Página anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
            },
            toolbar: {
            search: "Buscar",
            downloadCsv: " Descargar CSV",
            print: "Imprimir ",
            viewColumns: "Ver columnas",
            filterTable: "Tabla de filtros",
            },
            filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "RESET",
            },
            viewColumns: {
            title: "Mostrar columnas",
            titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
            text: "fila (s) seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar filas seleccionadas",
            },
        }
        };  
        const columns= ["Nombre","Correo", "Estado del perfil", "Tipo de cliente", "Curriculum", "Estado del CV",  "Aprobar CV","Rechazar CV", "Detalles del cliente"];
        let data= this.state.user_registered.map(rows=>{
            let rango_edad;
            let cp;
            let telefon_empresa;
            let genero;
            let profesion;
            if(rango_edad){
                rango_edad = rows.rango_edad;
            }else{
                rango_edad = <font color="red">No proporcionado</font>
            }
            if(rows.cp){
                cp = rows.cp;
            }else{
                cp = <font color="red">No proporcionado</font>
            }
            if(rows.telefono_empresa){
                telefon_empresa = rows.telefon_empresa;
            }else{
                telefon_empresa = <font color="red">No proporcionado</font>
            }
            if(rows.genero){
                genero = rows.genero;
            }else{
                genero = <font color="red">No proporcionado</font>
            }
            if(rows.profesion){
                profesion = rows.profesion;
            }else{
                profesion = <font color="red">No proporcionado</font>
            }
            let perfil_completado;
            console.log("rows",rows)
            if(rows.perfil_completado === 'false'){
                perfil_completado = <Tag color="red">Perfil incompleto</Tag>
            }else if(rows.perfil_completado === 'true'){
                perfil_completado = <Tag color="green">Perfil completado</Tag>
            }

            let tipo_cliente

            // if(rows.fk_cliente){
            //     tipo_cliente = <font className="strong" color="green">Cliente</font>
            // }else{
            // tipo_cliente = <font className="strong" color= "gray">Nuevo usuario</font>
            // }
            tipo_cliente = <font className="strong" color= "green">Cliente</font>
            let pdf;
            let button_aprobar;
            let button_rechazar;
            let estatus_aprobacion;

            if(rows.url_cv){
                if(rows.estatus_aprobacion === "Pendiente"){
                    estatus_aprobacion = <font color="orange">Pendiente</font>
                    pdf = <a href={rows.url_cv} target='_blank'><img width={45} src = {cv_pending} /></a>
                    button_aprobar = <Button type='info' onClick={e=>this.aprobar_cv(rows)}>Aprobar &nbsp;  &nbsp;  &nbsp; <i class="fa fa-check"></i></Button>
                    button_rechazar = <Button type='danger' onClick={e=>this.rechazar_cv(rows)}>Rechazar &nbsp;  &nbsp;  &nbsp;<i class="fa fa-ban"></i></Button>
                }if(rows.estatus_aprobacion === "Aprobada"){
                    estatus_aprobacion = <font color="green">Aprobada</font>
                    pdf = <a href={rows.url_cv} target='_blank'><img width={45} src = {cv} /></a>
                    button_aprobar = <Tag color="green">Aprobada</Tag>
                    button_rechazar = <Button type='danger' onClick={e=>this.rechazar_cv(rows)}>Rechazar &nbsp;  &nbsp;  &nbsp;<i class="fa fa-ban"></i></Button>
                }
                if(rows.estatus_aprobacion === "Rechazada"){
                    estatus_aprobacion = <font color="red">Rechazada</font>
                    pdf = <a href={rows.url_cv} target='_blank'><img width={45} src = {cv_not} /></a>
                    button_aprobar = <Tag color="red">No disponible</Tag>
                    button_rechazar = <Tag color="red">No disponible</Tag>
                }
                

            }else{
                pdf = <a onClick={e=>alert("Curriculum no registrado")}><img width={45} src = {cv_not} /></a>
                button_aprobar = <Tag color="gray">No disponible</Tag>
                button_rechazar = <Tag color="gray">No disponible</Tag>
                estatus_aprobacion = <font color="gray">No adjuntado</font>

            }
            return([rows.nombre,rows.correo,perfil_completado,tipo_cliente, pdf,estatus_aprobacion,button_aprobar,button_rechazar, <Button type="primary" onClick={e=>this.showModal(rows)}><i class="fa fa-eye"></i></Button>])
        })
        return ( 
            <div className=''>
                <Card style={{width:"100%",marginTop:"2em"}} title={<h6 className='strong'>Listado de usuarios registrados a la plataforma de cursos</h6>}>
                    <MUIDataTable  
                        data={data} 
                        columns={columns} 
                        options={options} 
                    />    
                    {modal}
                </Card>


            </div>
         );
    }
}
 
export default Users_Plataform;