import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'antd/dist/antd.css';
import actionsAPI from "./actionsAPI";
import { Card,Button} from 'antd';
import {
  MDBMask,
  MDBView,
  MDBAnimation,
} from "mdbreact";
import "./index.css";
import swal from "sweetalert";
import ads from '../imagen/logo_color_azul.png'
class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            pass: '',
            nombre: '',
            apellidos: '',
            puesto: '',
            isPasswordShown: false,
        }
      }
    componentWillMount(){

    }      

    onSubmitBtn(){
        actionsAPI.register_user_course(this.state.user,this.state.pass,this.state.nombre,this.state.apellidos,this.state.puesto).then(response=>{
            console.log(response)
            if(response.data.data.register_user_course.message === "Registro exitoso"){
                swal({
                    text:`Registro exitoso`,               
                    icon:"success",
                });
                setTimeout(()=>{
                    this.props.history.push("/login_courses");
                },5000)
            }
            else{
                swal({
                    text:`Ocurrió un error`,               
                    icon:"error",
                });
            }
        }).catch(err=>{
            console.log(err.response)
        })
    }
    onChangeInput =(e)=>{
    const {id,value} = e.target;
    this.setState({
        [id]:value
    })
    }

    render() {
    let titulo = <center><h4 className="h4tag strong">Registrarme</h4></center>
    return(
    <div>
     <div id="apppage" >  
      <MDBView>
        <MDBMask >
        <div style={{marginTop:"3%"}} className="center">
            <div className="rows">
            <MDBAnimation type="fadeInRight" delay=".3s">
            <Card title = {titulo} style={{width:"120%",padding:"3em"}}>
            <div className="h5 text-center">
                <br/><br/>
                <center>
                <img  src = {ads} style={{width:180,height:60}} />
                </center>
                <br/><br/>
            </div>
                <form onSubmit={this.onSubmitBtn}>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Nombre"                                  
                        id="nombre"
                        type="nombre"
                        name="nombre"
                        onChange={this.onChangeInput}
                        value={this.state.nombre}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Apellidos"                                  
                        id="apellidos"
                        type="apellidos"
                        name="apellidos"
                        onChange={this.onChangeInput}
                        value={this.state.apellidos}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Puesto"                                  
                        id="puesto"
                        type="puesto"
                        name="puesto"
                        onChange={this.onChangeInput}
                        value={this.state.puesto}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Correo"                                  
                        id="user"
                        type="text"
                        name="user"
                        onChange={this.onChangeInput}
                        value={this.state.user}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Contraseña"                                  
                        id="pass"
                        type="password"
                        name="pass"
                        onChange={this.onChangeInput}
                        value={this.state.pass}
                    />
                    <div class="input-icon">
                    <i class="fa fa-key"></i>                  
                    </div>
                    </div>
                <div className="text-center mb-4 mt-5">
                    <Button
                    type='primary'
                    onClick={e=> this.onSubmitBtn()}
                    className='btn-block z-depth-1'
                    size="sm"
                    >
                    Registrarme
                    </Button>
                </div>
                </form>
                <center>
                <font color="green"><a href="/login_courses">Iniciar sesión</a></font>
                </center>
            </Card>
            </MDBAnimation>   
            </div>  
            </div>
        </MDBMask>
    </MDBView>
    </div>
    </div>
  )        
}
}

export default Login;