import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { Table, Input, TA } from "antd";
import "./index.css";
import options from "./tableMuiOptions";
import { Button, Modal } from "antd";
import axios from "axios";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import "./tablaCliente.css";
import swal from "sweetalert";
import AddUser from '../imagen/addUser.svg'
import ViewUsers from '../imagen/viewUsers.svg'
import DetailsUser from '../imagen/details.svg'
import UpdateUser from '../imagen/updateUser.svg'
import actions from './transactionsAPI'

class Tablas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablas: [],
      nombre: "",
      apellido: "",
      correo1: "",
      correo2: "",
      telefono1: "",
      ext: "",
      telefono2: "",
      puesto: "",
      idCliente: "",
      DatosClientes: [],
      arrayContactos: [],
      tablaInicio: true,
      signupContacto: false,
      consultarContactos: false,
      modal: false,
      datosContacto: [],
      tablaDetalles: false,
      dataDetalles: [],
      contactoSeleccionado: "",
      detallesContacto: [],
      clienteSeleccionado: [],
      tipoContacto: "",
      tipoContacto2: "",
      modal4: false,
      RFC: "",
      razonSocial: "",
      tipoCliente: "",
      tamanoEmpresa: "",
      giroEmpresarial: "",
      paginaWeb: "",
      domicilioFiscal: "",
      informacionClientes: [],
      sRT: "",
      nameSearch: "",
      dataSource: "",
      searchedText: "",
    };
    this.toggle = this.toggle.bind(this);
    this.onChangeInput2 = this.onChangeInput2.bind(this);
    this.toggle4 = this.toggle4.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle4 = () => {
    this.setState({
      modal4: !this.state.modal4,
    });
  };

  async componentWillMount() {
    let fk_empresa = localStorage.getItem("fk_empresa");
    actions.getTablaClientesAlfa(fk_empresa).then(data => {
      this.setState({ tablas: data });
      this.setState({ dataSource: data });
    }).catch(err=>{
      console.log(err.response)
    })
  }
  cerrar() {
    this.setState({ tablaInicio: true });
    this.setState({ signupContacto: false });
    this.setState({ consultarContactos: false });
  }
  datosIndividialesClientes(id) {
    this.setState({ idCliente: id });
    this.setState({ signupContacto: true });
    this.setState({ tablaInicio: false });
    this.setState({ modal: false });
  }
  getContacto(id) {
      actions.getContacto(id.id_cliente).then(data => {
      this.setState({ arrayContactos: data.data.data.getTablaContactos});
      this.setState({ tablaInicio: false });
      this.setState({ consultarContactos: true });
    }).catch(err=>{
      console.log(err.response)
    })
  }

  onChangeInput = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  onChangeInput2 = (e) => {
    const { id, value } = e.target;
    // console.log("id", id,"value",value)
    this.setState({
      [id]: value,
    });
  };

  onChangeInput3 = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  onSubmitBtn = async (e) => {
    e.preventDefault();
    let idCliente = this.state.idCliente.id_cliente;
    let nombre = this.state.nombre.toUpperCase();
    let apellidos = this.state.apellido.toUpperCase();
    let correo1 = this.state.correo1;
    let correo2 = this.state.correo2;
    let telefono1 = this.state.telefono1;
    let ext = this.state.ext;
    let telefono2 = this.state.telefono2;
    let puesto = this.state.puesto.toUpperCase();
    let tipoContacto = this.state.tipoContacto;
    var status;
    let apikey = "7c9be8cb9f2847e199cf2cdb4dd33972";

    let targetURL =
      "https://api.zerobounce.net/v1/validate?apikey=" +
      apikey +
      "&email=" +
      correo1;

    await axios
      .get(targetURL)

      .then((response) => {
        status = response.data.status;
      })
      .catch((error) => {
        console.log("error", error);
      });

      if (nombre && apellidos && correo1 && telefono1) {
        actions.insertContacto(nombre,apellidos,correo1,correo2,telefono1,ext,telefono2,puesto,tipoContacto,idCliente).then(response=>{
        if (
          response.data.data.insertContacto.message === "registro exitoso"
        ) {
          this.setState({
            nombre: "",
            apellido: "",
            correo1: "",
            correo2: "",
            telefono1: "",
            ext: "",
            telefono2: "",
            puesto: "",
            tipoContacto: "",
          });
          swal({
            text: "Registro exitoso!",
            buttons: false,
            icon: "success",
          });
        } else if (
          response.data.data.insertContacto.message === "correo existente"
        ) {
          swal({
            text: "El correo proporcionado ya fue registrado.",
            title: "",
            icon: "error",
          });
        } else {
          swal({
            text: "Algo salió mal, por favor inténtelo nuevamente",
            icon: "error",
          });
        }
      }).catch(err=>{
          console.log("error",err)
      })
    }
  };

  onSubmitBtn2 = (e) => {
    e.preventDefault();
    let nombreContacto = this.state.nombre;
    let apellidoContacto = this.state.apellido;
    let correo1Contacto = this.state.correo1;
    let correo2Contacto = this.state.correo2;
    let telefono1Contacto = this.state.telefono1;
    let extContacto = this.state.ext;
    let telefono2Contacto = this.state.telefono2;
    let puestoContacto = this.state.puesto;
    let tipoContacto2 = this.state.tipoContacto;
    if (
      nombreContacto &&
      apellidoContacto &&
      correo1Contacto &&
      telefono1Contacto
    ) {
      console.log("estado",this.state.datosContacto)

      actions.updateContacto(
        this.state.datosContacto.id_contacto,
        nombreContacto,
        apellidoContacto,
        correo1Contacto,
        correo2Contacto,
        telefono1Contacto,
        extContacto,
        telefono2Contacto,
        puestoContacto,
        tipoContacto2
        ).then(response=>{
          if (
            response.data.data.updateContacto.message ===
            "actualizacion exitosa"
          ) {
            this.setState({ modal: false });
            swal({
              text: "Los datos se actualizaron correctamente",
              buttons: false,
              icon: "success",
            });
            window.location.reload();
          } else {
            this.setState({ modal: false });
            swal({
              text: "Algo salió mal, por favor complete todos los campos  nuevamente",
              buttons: false,
              icon: "error",
            });
          }
        }).catch(err=>{
          console.log("error", err);
        })
    }
  };

  onSubmitBtn3 = (e) => {
    e.preventDefault();
    let domicilioFiscal = this.state.domicilioFiscal;
    let giroEmpresarial = this.state.giroEmpresarial;
    let razonSocial = this.state.razonSocial;
    let RFC = this.state.RFC;
    let tamanoEmpresa = this.state.tamanoEmpresa;
    let tipoCliente = this.state.tipoCliente;
    let paginaWeb = this.state.paginaWeb;
    if (RFC && razonSocial) {
      actions.updateCliente(
        this.state.informacionClientes.id_cliente,
        domicilioFiscal,
        giroEmpresarial,
        razonSocial,
        RFC,
        tamanoEmpresa,
        tipoCliente,
        paginaWeb).then(response=>{
          if (
            response.data.data.updateCliente.message == "actualizacion exitosa"
          ) {
            this.setState({ modal: false });
            this.setState({ modal4: false });
            swal({
              text: "Los datos se actualizaron correctamente",
              buttons: false,
              icon: "success",
            });
            window.location.reload();
          } else {
            this.setState({ modal: false });
            this.setState({ modal4: false });
            swal({
              text: "Algo salió mal, por favor complete todos los campos  nuevamente",
              buttons: false,
              icon: "error",
            });
          }
        }).catch(err=>{
          console.log(err)
        })
    }
  };
  editarContacto(id) {
    this.setState({ datosContacto: id });
    this.setState({
      nombre: id.nombre,
      correo1: id.correo1,
      apellido: id.apellidos,
      correo2: id.correo2,
      telefono1: id.telefono1,
      telefono2: id.telefono2,
      ext: id.extensionTelefonica,
      puesto: id.puesto,
      tipoContacto: id.tipoContacto,
    });
    this.setState({
      modal: !this.state.modal,
    });
  }

  infContacto(id) {
    this.setState({ informacionClientes: id });
    this.setState({
      domicilioFiscal: id.domicilioFiscal,
      giroEmpresarial: id.giroEmpresarial,
      paginaWeb: id.paginaWeb,
      razonSocial: id.razonSocial,
      RFC: id.rfc,
      tamanoEmpresa: id.tamanoEmpresa,
      tipoCliente: id.tipoEmpresa,
      modal4: !this.state.modal4,
    });
  }

  deleteContacto(id) {
    actions.deleteContacto(id).then(response=>{
      if (response.data.data.deliteContacto.message === "delite exitoso") {
        swal({
          text: "Los datos se eliminaron correctamente",
          buttons: false,
          icon: "success",
        });
        window.location.reload();
      } else {
        swal({
          text: "Algo salio mal,intentelo nuevamente",
          buttons: false,
          icon: "error",
        });
      }
    }).catch(err=>{
      console.log("error", err.response);
    })
  }

  enviarAcceso(id) {
    let idContacto = this.state.contactoSeleccionado;
    let dataContacto = this.state.detallesContacto;
    let filter = dataContacto.filter(function (param) {
      return param.id_contacto === idContacto;
    });
    if (filter[0]) {
      let correo = filter[0].correo1;
      actions.enviarAcceso(id,correo,this.state.contactoSeleccionado).then(response=>{
        if (
          response.data.data.accesoSistema.message === "Contacto ya asignado"
        ) {
          swal({
            title: "Aviso!",
            text: "El contacto seleccionado ya cuenta con acceso al sistema de clientes, seleccione uno diferente.",
            buttons: false,
            icon: "warning",
          });
        }
        if (response.data.data.accesoSistema.message === "acceso permitido") {
          swal({
            title: "Aviso!",
            text: `Se autorizó correctamente el acceso al sistema para ${correo}`,
            buttons: false,
            icon: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }).catch(err=>{
        console.log(err)
      })
    } else {
      swal({
        title: "Aviso!",
        text: `El contacto no fue seleccionado o no ha sido registrado previamente`,
        buttons: false,
        icon: "error",
      });
    }
  }
  quitarAcceso(id) {
    let cliente = this.state.clienteSeleccionado;
    actions.quitarAcceso(id, cliente.fk_contactoAcceso).then(response=>{
      if (
        response.data.data.quitarAccesoSistema.message === "acceso removido"
      ) {
        swal({
          title: "Aviso!",
          text: `Se bloqueó el acceso al sistema de clientes para este usuario.`,
          buttons: false,
          icon: "success",
        });
        window.location.reload();
      }else{
        swal({
          title: "Aviso!",
          text: `Ocurrio un error`,
          buttons: false,
          icon: "error",
        });
      }
    }).catch(err=>{
      console.log(err)
    })
  }
  detalles(data) {
    this.setState({ clienteSeleccionado: data });
    actions.getContacto(data.id_cliente).then(response=>{
      console.log( response.data.data.getTablaContactos)
      this.setState({
        detallesContacto: response.data.data.getTablaContactos,
      });
    }).catch(err=>{
      console.log(err.response)

      console.log(err)
    })
    this.setState({ dataDetalles: data });
    this.setState({ tablaInicio: false });
    this.setState({ tablaDetalles: true });
  }
  cancelarDetalles() {
    this.setState({ tablaInicio: true });
    this.setState({ tablaDetalles: false });
  }
  render() {
    let rolAdmistrador = localStorage.getItem("rolAdministrador");
    let tabla;
    let tituloDetalles = (
      <h6>
        <strong>Detalles del cliente</strong>
      </h6>
    );
    if (this.state.tablaDetalles === true) {
      let boton1 = (
        <Button onClick={(e) => this.cancelarDetalles()} type="danger" style={{width:"100%"}}>
          Cancelar proceso
        </Button>
      );
      let boton2;
      let boton3;
      let acceso;
      let detalle = this.state.dataDetalles;
      let contactoAsignado = (
        <form onSubmit={this.onSubmitBtn}>
          <select
            className="browser-default custom-select "
            type="select"
            name="contactoSeleccionado"
            id="contactoSeleccionado"
            onChange={this.onChangeInput}
            value={this.state.contactoSeleccionado}
            style={{width:"100%"}}
          >
            <option value="disable">Seleccione un contacto</option>
            {this.state.detallesContacto.map((rows) => {
              return (
                <option value={rows.id_contacto}>
                  {rows.nombre + " " + rows.apellidos}
                </option>
              );
            })}
          </select>
        </form>
      );
      boton2 = (
        <Button
          type="primary"
          onClick={(e) => this.enviarAcceso(detalle.id_cliente)}
          style={{width:"100%"}}
        >
          Enviar acceso
        </Button>
      );
      if (detalle.acceso === "false") {
        acceso = (
          <p style={{ color: "#FC0502", marginTop: "2%" }}>
            <strong>Sin acceso al sistema</strong>
          </p>
        );
      } else if (detalle.acceso === "true") {
        acceso = (
          <p style={{ color: "#1F8349", marginTop: "2%" }}>
            <strong>Acceso Permitido al sistema</strong>
          </p>
        );
        boton3 = (
          <Button
            type="dashed"
            danger
            onClick={(e) => this.quitarAcceso(detalle.id_cliente)}
            style={{width:"100%"}}
          >
            Quitar acceso
          </Button>
        );
      }
      tabla = <div className="container27">
          <div >
            <div className="column">
              <div class="container22">
                <h4>{tituloDetalles}</h4>
                  <div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="RFC"
                        disabled
                      />
                      <input
                        style={{width:"70%"}}
                        value={detalle.rfc}
                        disabled
                      />
                    </div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="Razón Social"
                        disabled
                      />
                      <input
                        value={detalle.razonSocial}
                        disabled
                        style={{width:"70%"}}
                      />
                    </div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="Correo"
                        disabled
                      />
                      <input
                        value={detalle.correo || "Sin datos"}
                        disabled
                        style={{width:"70%"}}
                      />
                    </div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="Tamaño"
                        disabled
                      />
                      <input
                        value={detalle.tamanoEmpresa || "Sin datos"}
                        disabled
                        style={{width:"70%"}}
                      />
                    </div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="Giro empresarial"
                        disabled
                      />
                      <input
                        value={detalle.giroEmpresarial || "Sin datos"}
                        disabled
                        style={{width:"70%"}}
                      />
                    </div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="Teléfono"
                        disabled
                      />
                      <input
                        value={detalle.telefono || "Sin datos"}
                        disabled
                        style={{width:"70%"}}
                      />
                    </div>
                    <div class="rows input-group input-group-icon">
                      <input
                        style={{width:"30%"}}
                        placeholder="Página web"
                        disabled
                      />
                      <input
                        value={detalle.paginaWeb || "Sin datos"}
                        disabled
                        style={{width:"70%"}}
                      />
                    </div>
                    <strong><label>Enviar acceso al sistema</label></strong>
                    <div class="input-group input-group-icon">
                      {contactoAsignado}
                    </div>
                    <div class="input-group input-group-icon">
                      {boton2}
                    </div>
                    <div class="input-group input-group-icon">
                      {boton3}
                    </div>
                    <div class="input-group input-group-icon">
                    </div>
                    <div class="input-group input-group-icon">
                      {boton1}
                    </div>
                    <div class="row input-group input-group-icon">
                      {acceso}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    }

     

    let data;
    let nuevoContacto;
    let misContactos;
    let data2;
    let botonEditar;
    let botonEliminar;
    let modal;
    let RegistrarContacto;
    let detalles;
    let modal4;
    let EditarClientes;

    if (this.state.signupContacto === true) {
      let titulo = <h4 className="h4tag">Registrar nuevo contacto</h4>;
      RegistrarContacto = (
        <div style={{ width: "90%", marginLeft: "5%" }}>
          <div className="container23">
          <div className="column">
              <img
                className="container26"
                src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              />
            </div>
            <div className="column">
              <div>
                <h6 className="container24">
                  {this.state.idCliente.razonSocial}
                </h6>
              </div>
              <div class="container22">
                <h4>{titulo}</h4>
                <form onSubmit={this.onSubmitBtn}>
                  <div class="row">
                    <div class="input-group input-group-icon">
                      <input
                        id="nombre"
                        type="text"
                        name="nombres"
                        onChange={this.onChangeInput}
                        value={this.state.nombre}
                        required
                        placeholder="Nombre (s) *"
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="apellidos *"
                        id="apellido"
                        type="text"
                        name="apellido"
                        onChange={this.onChangeInput}
                        value={this.state.apellido}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        type="email"
                        placeholder="Correo *"
                        id="correo1"
                        name="correo1"
                        onChange={this.onChangeInput}
                        value={this.state.correo1}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Correo alterno"
                        id="correo2"
                        type="email"
                        name="correo2"
                        onChange={this.onChangeInput}
                        value={this.state.correo2}
                      />
                      <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Teléfono *"
                        id="telefono1"
                        type="number"
                        name="telefono1"
                        onChange={this.onChangeInput}
                        value={this.state.telefono1}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Ext."
                        id="ext"
                        type="number"
                        name="ext"
                        onChange={this.onChangeInput}
                        value={this.state.ext}
                      />
                      <div class="input-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Celular"
                        id="telefono2"
                        type="number"
                        name="telefono2"
                        onChange={this.onChangeInput}
                        value={this.state.telefono2}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-mobile"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Puesto"
                        id="puesto"
                        type="text"
                        name="puesto"
                        onChange={this.onChangeInput}
                        value={this.state.puesto}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-address-card"></i>
                      </div>
                    </div>
                    <div class="col-half">
                      <div class="input-group">
                        <select
                          className="browser-default custom-select"
                          type="select"
                          name="tipoContacto"
                          id="tipoContacto"
                          onChange={this.onChangeInput}
                          value={this.state.tipoContacto}
                        >
                          <option value="disable">Tipo de Contacto *</option>
                          <option value="PAGO">Pago</option>
                          <option value="ADMINISTRATIVO">Administrativo</option>
                          <option value="TÉCNICO">Técnico</option>
                        </select>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <button
                        class="button2"
                        onClick={(e) => this.onSubmitBtn(e)}
                      >
                        <span class="text">Agregar</span>
                        <span class="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <button onClick={(e) => this.cerrar()} class="button">
                      <span class="text">Cancelar</span>
                      <span class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let campoAgregarContacto = "";
    let campoMisContactos = "";
    let campoDetalles = "";
    let campoEditarClientes = "";
    data = this.state.tablas.map((rows) => {
      if (
        rolAdmistrador === "5" ||
        rolAdmistrador === "15" ||
        rolAdmistrador === "55" ||
        rolAdmistrador === "25"
      ) {
        campoAgregarContacto = "Agregar Contacto";
        campoMisContactos = "Contactos";
        campoEditarClientes = "Editar";

        nuevoContacto = (
          <div>
            <Button
              type="success"
              className="ant-btn-primary"
              shape="circle"
              size="large"
              onClick={(e) => this.datosIndividialesClientes(rows)}
            >
              <img src={AddUser}/>
            </Button>
          </div>
        );
        misContactos = (
          <div>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={(e) => this.getContacto(rows)}
            >
              <img src={ViewUsers}/>
            </Button>
          </div>
        );

        EditarClientes = (
          <div>
            <Button
              type="info"
              shape="circle"
              size="large"
              onClick={(e) => this.infContacto(rows)}
            >
              <img src={UpdateUser}/>
            </Button>
          </div>
        );
      }

      if (
        rolAdmistrador === "5" ||
        rolAdmistrador === "15" ||
        rolAdmistrador === "55"
      ) {
        campoDetalles = "Detalles";
        detalles = (
          <Button
            className="text-white"
            type="warning"
            size="sm"
            onClick={(e) => this.detalles(rows)}
          >
            Detalles
          </Button>
        );
      }
      let tipocliente;
      if (rows.tipoEmpresa === "CLIENTE") {
        tipocliente = (
          <strong>
            <font color="#FBC02D">{rows.tipoEmpresa}</font>
          </strong>
        );
      }
      if (rows.tipoEmpresa === "PROSPECTO") {
        tipocliente = (
          <strong>
            <font color="#B71C1C">{rows.tipoEmpresa}</font>
          </strong>
        );
      }
      if (rows.tipoEmpresa === "OPORTUNIDAD DE NEGOCIO") {
        tipocliente = (
          <strong>
            <font color="#4CAF50">{rows.tipoEmpresa}</font>
          </strong>
        );
      }

      return [
        rows.id_cliente,
        tipocliente,
        rows.rfc,
        rows.razonSocial,
        nuevoContacto,
        misContactos,
        detalles,
        EditarClientes,
      ];
    });
    let CampoBotonEditar = "";
    let campoBotonEliminar = "";
    data2 = this.state.arrayContactos.map((rows) => {
      if (
        rolAdmistrador === "5" ||
        rolAdmistrador === "15" ||
        rolAdmistrador === "55"
      ) {
        CampoBotonEditar = "Editar";
        campoBotonEliminar = "Eliminar";
        botonEditar = (
          <div>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={(e) => this.editarContacto(rows)}
            >
            <img src={UpdateUser}/>
            </Button>
          </div>
        );
        botonEliminar = (
          <div>
            <Button
              type="danger"
              shape="circle"
              size="large"
              onClick={(e) => {
                if (
                  window.confirm(
                    "¿Está seguro de Eliminar este Contacto?, Los datos se perderán"
                  )
                )
                  this.deleteContacto(rows.id_contacto);
              }}
            >
              <i class="far fa-trash-alt"></i>
            </Button>
          </div>
        );
      }
      return [
        rows.id_contacto,
        rows.nombre + " " + rows.apellidos,
        rows.correo1,
        rows.correo2,
        rows.telefono1,
        rows.extensionTelefonica,
        rows.telefono2,
        rows.tipoContacto,
        botonEditar,
        botonEliminar,
      ];
    });
    const columns2 = [
      "Id",
      "Nombre",
      "Correo",
      "Correo Alterno",
      "Teléfono",
      "Ext.",
      "Celular",
      "Tipo de Contacto",
      CampoBotonEditar,
      campoBotonEliminar,
    ];
    const columnsApi = [
      {
        title: "ID",
        dataIndex: "id_cliente",
        key: "id_cliente",
        scrollToFirstRowOnChange: true,
        filterSearch: true,
        sorter: (a, b) => a.id_cliente > b.id_cliente,
        sortDirections: ["descend"],
        filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.razonSocial)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.id_cliente)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.rfc).toLowerCase().includes(value.toLowerCase())
          );
        },
      },
      {
        title: "Tipo de Cliente",
        dataIndex: "tipoEmpresa",
        key: "tipoEmpresa",
        filterSearch: true,

        sorter: (a, b) => a.tipoEmpresa > b.tipoEmpresa,
        sortDirections: ["descend"],
        filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.razonSocial)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.id_cliente)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.rfc).toLowerCase().includes(value.toLowerCase())
          );
        },
      },
      {
        title: "RFC",
        dataIndex: "rfc",
        key: "rfc",
        sorter: (a, b) => a.rfc > b.rfc,
        sortDirections: ["descend"],
        filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.razonSocial)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.id_cliente)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.rfc).toLowerCase().includes(value.toLowerCase())
          );
        },
      },
      {
        title: "Razón social",
        dataIndex: "razonSocial",
        key: "razonSocial",
        sorter: (a, b) => a.razonSocial > b.razonSocial,
        sortDirections: ["descend"],
        filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.razonSocial)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.id_cliente)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.rfc).toLowerCase().includes(value.toLowerCase())
          );
        },
      },
      {
        title: campoAgregarContacto,
        render: (text, record) => (
          <Button
            type="success"
            className="ant-btn-primary"
            shape="circle"
            size="large"
            onClick={(e) => this.datosIndividialesClientes(record)}
          >
            <img src={AddUser}/>
          </Button>
        ),
      },
      {
        title: campoMisContactos,
        render: (text, record) => (
          <Button
            type="primary"
            shape="circle"
            size="large"
            onClick={(e) => this.getContacto(record)}
          >
            <img src={ViewUsers}/>
          </Button>
        ),
      },
      {
        title: campoDetalles,
        render: (text, record) => (
          <Button
            className="text-white"
            color="warning"
            size="sm"
            type="warning"
            onClick={(e) => this.detalles(record)}
          >
            <img src={DetailsUser} />
          </Button>
        ),
      },
      {
        title: campoEditarClientes,
        render: (text, record) => (
          <Button
            type="info"
            shape="circle"
            size="large"
            onClick={(e) => this.infContacto(record)}
          >
            <img src={UpdateUser} />
          </Button>
        ),
      },
    ];
    const onChange = (pagination, filters, sorter, extra) => {
      console.log("params", pagination, filters, sorter, extra);
    };

    let tablaInicio;
    if (this.state.tablaInicio === true) {
      tablaInicio = (
        <div style={{ width: "95%" }}>
          <Input.Search
            style={{ width: "40%", marginBottom: "2%" }}
            onSearch={(value) => {
              this.setState({ searchedText: value });
            }}
            onChange={(e) => {
              this.setState({ searchedText: e.target.value });
            }}
            placeholder="Buscar elementos "
          />
          <Table
            dataSource={this.state.dataSource}
            columns={columnsApi}
            style={{ overflow: "hidden" }}
            size="small"
            onChange={onChange}
            pagination={{
              locale: {
                items_per_page: "/Páginas",
              },
              showTotal: (total, range) => (
                <div>{`${range[0]}-${range[1]} de ${total} resultados totales`}</div>
              ),
            }}
          />
        </div>
      );
    }
    let titulo2 = <h4>Contactos registrados</h4>;
    let tablaContacto;
    if (this.state.consultarContactos === true) {
      tablaContacto = (
        <div>
          <div>
            <h6 className="container23">{titulo2}</h6>
            <div className="muidatatable">
              <MUIDataTable data={data2} columns={columns2} options={options} />
            </div>
          </div>
          <center>
          <button onClick={(e) => this.cerrar()} class="button">
            <span class="text">Cancelar</span>
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
          </center>
        </div>
      );
    }
    modal = (
      <div>
          <Modal title = {<h4 className="h4tag">Editar datos del contacto</h4>} visible={this.state.modal} onCancel={this.toggle} onOk={this.toggle} size="lg">
          <div className="container22">
          <form onSubmit={this.onSubmitBtn2}>
                  <div class="row">
                    <div class="input-group input-group-icon">
                      <input
                        id="nombre"
                        type="text"
                        name="nombre"
                        onChange={this.onChangeInput}
                        value={this.state.nombre}
                        required
                        placeholder="Nombre (s) *"
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        id="apellidos"
                        type="text"
                        name="apellidos"
                        onChange={this.onChangeInput}
                        value={this.state.apellido}
                        placeholder="apellidos *"
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        id="correo1"
                        type="email"
                        name="correo1"
                        onChange={this.onChangeInput}
                        value={this.state.correo1}
                        placeholder="Correo *"
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Correo alterno"
                        id="correo2"
                        type="email"
                        name="correo2"
                        onChange={this.onChangeInput}
                        value={this.state.correo2}
                      />
                      <div class="input-icon">
                        <i class="fa fa-envelope"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Teléfono *"
                        id="telefono1"
                        type="text"
                        name="telefono1"
                        onChange={this.onChangeInput}
                        value={this.state.telefono1}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Ext."
                        id="ext"
                        type="text"
                        name="ext"
                        onChange={this.onChangeInput}
                        value={this.state.ext}
                      />
                      <div class="input-icon">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Celular"
                        id="telefono2"
                        type="number"
                        name="telefono2"
                        onChange={this.onChangeInput}
                        value={this.state.telefono2}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-mobile"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Puesto"
                        id="puesto2"
                        type="text"
                        name="puesto2"
                        onChange={this.onChangeInput}
                        value={this.state.puesto}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-address-card"></i>
                      </div>
                    </div>
                    <div class="col-half">
                      <div class="input-group">
                        <select
                        className="browser-default custom-select"
                        type="select"
                        name="tipoContacto"
                        id="tipoContacto"
                        onChange={this.onChangeInput}
                        value={this.state.tipoContacto}
                        >
                          <option value="disable">Tipo de Contacto *</option>
                          <option value="PAGO">Pago</option>
                          <option value="ADMINISTRATIVO">Administrativo</option>
                          <option value="TÉCNICO">Técnico</option>
                        </select>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <button
                        class="button2"
                        onClick={(e) => this.onSubmitBtn2(e)}
                      >
                        <span class="text">Guardar</span>
                        <span class="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <button  onClick={(e) => this.toggle()} class="button">
                      <span class="text">Cancelar</span>
                      <span class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>  
          </div> 
          </Modal>
      </div>
    );

    modal4 = (
      <div>
          <Modal title = {<h4 className="h4tag">Editar datos del cliente</h4>} visible={this.state.modal4} onCancel={this.toggle4}  onOk={this.toggle4}  size="lg">
          <div className="container22">
          <form onSubmit={this.onSubmitBtn3}>
                  <div class="row">
                    <div class="input-group input-group-icon">
                      <input
                        id="razonSocial"
                        type="text"
                        name="razonSocial"
                        onChange={this.onChangeInput3}
                        value={this.state.razonSocial}
                        placeholder="Razón Social *"
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        id="RFC"
                        type="text"
                        name="RFC"
                        onChange={this.onChangeInput3}
                        value={this.state.RFC}
                        placeholder="RFC *"
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    
                    <div class="col-half">
                      <div class="input-group">
                        <select
                          className="browser-default custom-select"
                          type="select"
                          name="tipoCliente"
                          id="tipoCliente"
                          onChange={this.onChangeInput3}
                          value={this.state.tipoCliente}
                          placeholder="Tipo de cliente"
                        >
                          <option value="disable">Tipo de Cliente</option>
                          <option value="PROSPECTO">Prospecto</option>
                          <option value="OPORTUNIDAD DE NEGOCIO">Oportunidad de negocio</option>
                          <option value="CLIENTE">Cliente</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-half">
                      <div class="input-group">
                        <select
                          className="browser-default custom-select"
                          type="select"
                          name="tamanoEmpresa"
                          id="tamanoEmpresa"
                          onChange={this.onChangeInput3}
                          value={this.state.tamanoEmpresa}
                          placeholder="Tamaño de empresa"
                        >
                          <option value="disable">Seleccione el tamaño de su Empresa</option>
                          <option value="1 - 15 EMPLEADOS">1 - 15 Empleados</option>
                          <option value="16 - 50 EMPLEADOS">16 - 50 Empleados</option>
                          <option value="51 - 100 EMPLEADOS">51 - 100 Empleados</option>
                          <option value="MÁS DE 100 EMPLEADOS"> Más de 100 Empleados</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-half">
                      <div class="input-group">
                        <select
                          className="browser-default custom-select"
                          type="select"
                          name="giroEmpresarial"
                          id="giroEmpresarial"
                          onChange={this.onChangeInput3}
                          value={this.state.giroEmpresarial}
                          placeholder="Giro empresarial"
                        >
                          <option value="disable">Tipo de industria</option>
                          <option value="AGRICULTURA GANADERÍA y PESCA">Agricultura, ganadería y pesca</option>
                          <option value="CATASTRO y GESTIÓN TERRITORIAL">Catastro y Gestión Territorial</option>
                          <option value="COMERCIO">Comercio</option>
                          <option value="COMERCIO EXTERIOR">Comercio Exterior</option>
                          <option value="CONSTRUCCIÓN">Construcción</option>
                          <option value="EDUCACIÓN">Educación</option>
                          <option value="EMPLEO Y OCUPACIÓN">Empleo</option>
                          <option value="EMPRESAS Y ESTABLECIMIENTOS">Empresas y establecimientos</option><option value="GOBIERNO">Gobierno</option>
                          <option value="HOGARES Y VIVIENDA"> Hogares y Vivienda</option><option value="IMÁGENES DEL TERRITORIO">Imágenes del Territorio</option>
                          <option value="MANUFACTURAS">Manufactura</option>
                          <option value="MAPAS">Mapas</option>
                          <option value="MARCO GEODÉSICO">Marco Geodésico</option>
                          <option value="MARCO GEOESTADÍSTICO">Marco Geoestadístico</option>
                          <option value="MEDIO AMBIENTE">Medio ambiente</option>
                          <option value="MINERÍA">Minería</option>
                          <option value="PIB Y CUENTAS NACIONALES">PIB y Cuentas Nacionales</option>
                          <option value="POBLACIÓN">Poblacion</option>
                          <option value="PRECIOS">Precios</option>
                          <option value="SALUD Y SEGURIDAD SOCIAL"> Salud y Seguridad Social</option>
                          <option value="SEGURIDAD PÚBLICA Y JUSTICIA">Seguridad pública y justicia</option>
                          <option value="SERVICIOS NO FINANCIEROS">Servicios no financieros</option>
                          <option value="TECNOLOGÍAS DE LA INFORMACIÓN Y COMUNICACIONES">Tecnología de la información y comunicaciones</option>
                          <option value="TRANSPORTE">Transporte</option>
                          <option value="TURISMO">Turismo</option>
                        </select>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Página web"
                        id="paginaWeb"
                        type="text"
                        name="paginaWeb"
                        onChange={this.onChangeInput3}
                        value={this.state.paginaWeb}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-address-card"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <input
                        placeholder="Domicilio fiscal"
                        id="domicilioFiscal"
                      type="text"
                      name="domicilioFiscal"
                      onChange={this.onChangeInput3}
                      value={this.state.domicilioFiscal}
                        required
                      />
                      <div class="input-icon">
                        <i class="fa fa-address-card"></i>
                      </div>
                    </div>
                    <div class="input-group input-group-icon">
                      <button
                        class="button2"
                        onClick={(e) => this.onSubmitBtn3(e)}
                      >
                        <span class="text">Guardar</span>
                        <span class="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <button  onClick={(e) => this.toggle4()} class="button">
                      <span class="text">Cancelar</span>
                      <span class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>  
          </div> 
          </Modal>
      </div>
    );
    return (
      <React.Fragment>
        {RegistrarContacto}
        <center>
          <div style={{ marginTop: "3%" }}>{tablaInicio}</div>
        </center>
        {tablaContacto}
        {modal}
        {modal4}
        <center>{tabla}</center>
      </React.Fragment>
    );
  }
}
export default Tablas;
