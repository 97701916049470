import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { MDBRow, MDBCol, MDBTable} from 'mdbreact';
import {Button,Card} from 'antd'
import titulo1 from  '../imagen/imagent1.jpg';
import { PDFExport } from '@progress/kendo-react-pdf';
import MUIDataTable from "mui-datatables";
import './index.css'
import {  Row,Col,} from "reactstrap";


const PageTemplate = (props) => {
    return (
      <div  style={{position:"absolute",bottom:"10px",width:550}}>
      <center>  
      <p  className = "textabla3" color="black" style = {{marginBottom:10}}>Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México.  <br/>
     Información, soporte y ventas: Conmutador:55 3603 9970 - 55 5553 2049</p>
      </center>
      </div>
    );
  };

class PDFReport extends Component {
    pdfExportComponent
    constructor(props) {
        super(props);
        this.state = {
            botonPdfExport:false,
            enviarEmail:false,            
          }
    }


  toggleDenseTable = event => {
    this.setState({
      denseTable: event.target.checked,
    });
  };


  toggleDenseTable = event => {
    this.setState({
      denseTable: event.target.checked,
    });
  };

  render() {
    const options = { 
            print:false,
            filter:false,
            download:false,
            jumpToPage:false,
            pagination:false,
            search:false,
            sort:false,
            viewColumns:false,
            filterType:"drowpdawn",
            responsive: "scrollMaxHeight",
            elevation:0,
            selectableRows:"none",
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          }
        };
      
        const {botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa} = this.props        
       
        let botonDescagar;        
        let botoncerrar;
       
        if(botonesAdmin === false){
                          botoncerrar = <Button type = "dashed" danger  onClick = {e=>window.location.reload()}>Cerrar</Button>
                          botonDescagar = <Button type="primary" ghost onClick={(e) => { this.pdfExportComponent.save();}}>Descargar </Button> 
        }
        let array0 = []
        let array1 = []
        let array2 = []
        let array3 = []
        let array4 = [] 
        let array5 = []
        let array6 = []
        let array7 = []        
        let precioDosCeros;
        let precio;    
        let descuentoAplicadoDosCeros;
        let descuentoAplicado;
        let TotalPrecioProductoDosCeros;
        let TotalPrecioProducto;
        let tofixedDescuento;
        let tofixedTotalPrecioProducto;

        productoServicioByFolio.map(rows=>{
          array0.push({"id":rows.id_productoServicio})
        })
        productoServicioByFolio.map(rows=>{ 
          array1.push({"tipo":rows.tipo})
        })
        productoServicioByFolio.map(rows=>{
          array2.push({"concepto":rows.concepto})
        })
        
        cotizaciones.map(rows=>{
          array3.push({"cantidad":rows.cantidad})
        }) 
        productoServicioByFolio.map(rows=>{
          precioDosCeros = rows.precio.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          precio =  precioDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          array4.push({"precio":precio})
        })
        cotizaciones.map(rows =>{
          array5.push({"descuento":rows.descuento})
        })
        cotizaciones.map(rows=>{
        if(botonesAdmin === false){
                        tofixedDescuento = parseFloat(rows.descuentoAplicado).toFixed(2)
                      }else {
                        tofixedDescuento = rows.descuentoAplicado.toFixed(2)
                      }
          descuentoAplicadoDosCeros = tofixedDescuento.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          descuentoAplicado =  descuentoAplicadoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            array6.push({"descuentoAplicado":descuentoAplicado})
        })
        cotizaciones.map(rows=>{
          if(botonesAdmin === false){
                        tofixedTotalPrecioProducto = parseFloat(rows.TotalPrecioProducto).toFixed(2)
                      }else {
                        tofixedTotalPrecioProducto = rows.TotalPrecioProducto.toFixed(2)
                      }
          TotalPrecioProductoDosCeros = tofixedTotalPrecioProducto.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          TotalPrecioProducto = TotalPrecioProductoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          array7.push({"TotalPrecioProducto":TotalPrecioProducto})
        })
       
        let arr = array0.map((item, i) => Object.assign({}, item, array1[i]));
        let arr1 = array2.map((item, i) => Object.assign({}, item, arr[i]));
        let arr2 = array3.map((item, i) => Object.assign({}, item, arr1[i]));
        let arr3 = array4.map((item, i) => Object.assign({}, item, arr2[i]));
        let arr4 = array5.map((item, i) => Object.assign({}, item, arr3[i]));
        let arr5 = array6.map((item, i) => Object.assign({}, item, arr4[i]));
        let arr6 = array7.map((item,i) => Object.assign({}, item, arr5[i]));
        const columnsCotizaciones= ["Producto","Descripción","QTY","Precio U.","%","Descuento","Total"];

        let data  = arr6.map(rows=>{    
            return([rows.tipo,rows.concepto,rows.cantidad,"$"+ rows.precio,rows.descuento+"%","$"+rows.descuentoAplicado,"$"+rows.TotalPrecioProducto])
        })
        let tablaPRueba =  <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >   
      </div>       
        let titulo= <strong className="CardTexto">Reporte de Historial de Venta</strong>
         // ****Fecha de registro Venta******
        let fechaEmisioVenta=cotizaciones[0].fechaEmisionVenta        
        const fechaDeposito = new Date(fechaEmisioVenta); 
        var letrasMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
        let diaDeposito = fechaDeposito.getDate()
        let letraMesSubstring = fechaDeposito.getMonth() +1
        let numeroPosicionMes = parseInt(letraMesSubstring, 10)
        let mesDeposito  = letrasMeses[numeroPosicionMes - 1] 
        let añoDeposito = fechaDeposito.getFullYear()  
      // *************  
        let ivaFloat = parseFloat(totales.IVA)
        let ivaGlobalDosCeros = ivaFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let iva =  ivaGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let subtotalFloat = parseFloat(totales.subTotal)
        let subtotalGlobalDosCeros = subtotalFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let subtotal =  subtotalGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let totalFloat = parseFloat(totales.total)
        let totalGlobalDosCeros = totalFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let total =  totalGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        return ( 
          <div style={{marginLeft:"3%"}}>
          <Card  title={titulo} extra = {<div>{botonDescagar}&nbsp;&nbsp;&nbsp;{botoncerrar}</div>}>    
          {tablaPRueba}
          <Paper  style={{padding:"1%", width:1100,height:1400, marginBottom:"2%",marginLeft:"1%",marginTop:"2%"}}>
            <center>
              <MDBRow style={{marginTop:"3%"}}>
                    <MDBCol size = "6">
                      <img src={titulo1} alt="imagen"/>
                    </MDBCol>  
                    <MDBCol size = "6">
                      <p style={{marginTop:"3%"}}>NO. DE ORDEN <strong>{cotizaciones[0].numFolio}</strong></p> 
                    </MDBCol> 
              </MDBRow>
              </center>
              <div  style={{marginLeft:"63%",marginTop:"4%"}}>
                Ciudad de Mexico a {diaDeposito + " de " + mesDeposito + " de " + añoDeposito}
              </div>
            <br></br>          
              <div style={{width:"92%", marginLeft:"4%"}}> 
                       <Row xs="2">
                       <Col  className="border text-white" style={{backgroundColor:"#05387A", padding:"4px"}}>
                         <fort>REMITENTE O EXPENDIDOR</fort>
                         </Col>
                       <Col className="border text-white" style={{backgroundColor:"#05387A", padding:"4px"}}>
                        <fort>DESTINATARIO</fort>
                         </Col>
                          <Col className="border">
                          <fort> 
                              <p>   
                              <fort><strong>{localStorage.getItem("razonSocialEmpresa")}</strong></fort> 
                              <br></br>                           
                                {adminAlfa.nombre}&nbsp;{adminAlfa.apellido}  
                              <br></br>                                 
                               {adminAlfa.puesto} 
                               <br></br>
                               {adminAlfa.correo}
                                  <br></br>
                              {"Tel." + " " + adminAlfa.telefono + " " + "Ext." + adminAlfa.extensionTelefonica }                
                              </p>                                          
                          </fort>                             
                          </Col>  
                          <Col className="border">
                            <fort>
                              <p>                              
                               <fort><strong>{datosCliente.razonSocial}</strong></fort> 
                              <br></br>
                               {datosCliente.rfc}
                              <br></br>                 
                              {dataContacto.nombre + " " + dataContacto.apellidos}                 
                              <br></br> 
                              {dataContacto.puesto}
                                  <br></br>
                              {dataContacto.correo1}
                               <br></br>
                               {"Tel. " + dataContacto.telefono1}
                            </p>
                            </fort>                           
                         </Col>              
                        </Row>        
              </div>
            
              <div style={{marginTop:"2%"}}>            
              <center>
              <div style={{width:950}}>    
                <MUIDataTable  
                data={data} 
                columns={columnsCotizaciones} 
                options={options} 
                />  
                <table style={{marginLeft:"76%"}}>
                <tr>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="left" className="totales"><strong>SUBTOTAL</strong></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="right" className="totales">$&nbsp;{subtotal}</td>
               </tr>
               <tr>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center" ></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="left" className="totales"><strong>IVA</strong></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="right" className="totales">$&nbsp;{iva}</td>
               </tr>
               <tr>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} align="center"></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF"  align="left" className="totales"><strong>TOTAL</strong></td>
                 <td  style={{padding:"5px"}} bgcolor="#D6DBDF" align="right" className="totales">$&nbsp;{total}</td>
               </tr>
                </table>
              </div>
            </center>
            <br/><br/>
            <div style={{width:"95%", marginLeft:"2%"}} > 
                <MDBTable bordered  small  striped >
                <tr>
                    <td className="textabla10" width="15%">Fecha y Hora </td>  
                    <td className="textabla10" width="15%">{diaDeposito + " de " + mesDeposito + " del " + añoDeposito + "  " + cotizaciones[0].hora}</td>                  
                  </tr>
                  <tr>
                    <td className="textabla10" width="15%">Entidad bancaria</td>  
                    <td className="textabla10" width="15%">{cotizaciones[0].banco}</td>                  
                  </tr>
                  <tr>
                    <td className="textabla10" width="15%">Número de referencia</td>  
                    <td className="textabla10" width="15%">{cotizaciones[0].referenciaPago}</td>                  
                  </tr> 
                  <tr>
                    <td className="textabla10" width="15%">Monto</td>  
                    <td className="textabla10" width="15%">$&nbsp;{cotizaciones[0].importe}</td>                  
                  </tr> 
                  <tr>
                    <td className="textabla10" width="15%">Tipo de pago</td>  
                    <td className="textabla10" width="15%">{cotizaciones[0].tipoPago}</td>                                   
                  </tr> 
                </MDBTable >
            </div>
            <br/><br/>
            <fort> 
             <p  position= "absolute" className="footert text-center py-3">Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México <br></br> Información, soporte y ventas:
                 Conmutador:55 3603 9970 - 55 5553 2049</p>             
            </fort  > 

            </div>
            </Paper>
            </Card>
            <div style={{ position: "absolute", left: "-5000px", top: 0 }}>            
            {/* <div style={{ position: "absolute",  top: 0 }}>    */}
        <PDFExport
            paperSize="letter"
            margin="1cm"
            forcePageBreak=".page-break"
            fileName={localStorage.getItem("rfc")+ "-" + cotizaciones[0].numFolio+".pdf"}
            pageTemplate = {PageTemplate}

            ref={(component) => this.pdfExportComponent = component}>
        <div> 
        <center>
        <MDBRow style={{marginTop:"2%"}} >
                            <MDBCol size="8">
                            <img src={titulo1} alt="imagen" style={{width:"40%",marginLeft:"-53%"}} />
                            </MDBCol>  
                            <MDBCol size="4">                      
                            <b className="textabla3" style={{marginTop:"2%"}}>NO. DE ORDEN <strong>{cotizaciones[0].numFolio}</strong></b> <br/>
                            </MDBCol> 
                    </MDBRow>

            </center>
             <div className="textabla" style={{marginLeft:"50%",marginTop:"5%"}}>
             Ciudad de Mexico a {diaDeposito + " de " + mesDeposito + " de " + añoDeposito}
              </div>
              <br></br>
              <div style={{width:"95%", marginLeft:"2%"}}> 
                       <Row xs="2">
                       <Col  className="border text-white textabla3" style={{backgroundColor:"#05387A", padding:"4px"}}>
                         <fort>REMITENTE O EXPENDIDOR</fort>
                         </Col>
                       <Col className="border text-white textabla3" style={{backgroundColor:"#05387A", padding:"4px"}}>
                        <fort>DESTINATARIO</fort>
                         </Col>
                          <Col className="border">
                          <fort className="textabla1"><strong>{localStorage.getItem("razonSocialEmpresa")}</strong></fort>
                          <fort className= "textabla3"> 
                              <p>  
                              {adminAlfa.nombre}&nbsp;{adminAlfa.apellido} 
                              <br></br>                                 
                              {adminAlfa.puesto} 
                               <br></br>
                               {adminAlfa.correo}
                                  <br></br>
                                  {"Tel." + " " + adminAlfa.telefono + " " + "Ext." + adminAlfa.extensionTelefonica }                
                              </p>                                          
                          </fort>                             
                          </Col>  
                          <Col className="border">
                          <fort className="textabla1" ><strong>{datosCliente.razonSocial}</strong></fort> 
                          <fort className="textabla3">
                              <p> 
                              {datosCliente.rfc}
                              <br></br>                 
                              {dataContacto.nombre + " " + dataContacto.apellidos}            
                                  <br></br> 
                              {dataContacto.puesto}
                                  <br></br>
                              {dataContacto.correo1}
                               <br></br>
                               {"Tel. " + dataContacto.telefono1}
                            </p>
                            </fort>                           
                         </Col>              
                        </Row>        
              </div>     
              <div style={{marginTop:"2%"}}>
              <center>
              <div>  
              <MDBTable class="table" striped bordered  small style={{width:555}} >
                     <thead>
                       <tr>
                         <th scope="col" width="10%"  bgcolor="#05387A" className="textabla3 text-white fuente">Producto</th>          
                         <th scope="col" width="43%"  bgcolor="#05387A" className="textabla3 text-white fuente">Descripción</th>
                         <th scope="col" width="6%"   bgcolor="#05387A" className="textabla3 text-white fuente">Cant.</th>
                         <th scope="col" width="11%"  bgcolor="#05387A" className="textabla3 text-white fuente">Precio U.</th>
                         <th scope="col" width="8%"   bgcolor="#05387A" className="textabla3 text-white fuente">%</th>
                         <th scope="col" width="10%"  bgcolor="#05387A" className="textabla3 text-white fuente">Descuento</th>
                         <th scope="col" width="12%"  bgcolor="#05387A" className="textabla3 text-white fuente">Total</th>
                        </tr>
                     </thead>
                     <tbody>
                         {arr6.map(rows=>{                       
                             return(
                                <tr>
                                    <td style={{padding:"2px"}} align="left" className="textabla3">{rows.tipo}</td>
                                    <td style={{padding:"2px"}} align="left" className="textabla3">{rows.concepto}</td>
                                    <td style={{padding:"2px"}} align="center" className="textabla3">{rows.cantidad}</td>
                                    <td style={{padding:"2px"}} align="right"  className="textabla3" >$ {rows.precio}</td>
                                    <td style={{padding:"2px"}} align="center"className="textabla3" >{rows.descuento} %</td>
                                    <td style={{padding:"2px"}} align="right" className="textabla3">$ {rows.descuentoAplicado}</td>
                                    <td style={{padding:"2px"}} align="right" className="textabla3" >$&nbsp;{rows.TotalPrecioProducto}</td>
                                </tr>  
                             )
                         })}                  

                    <tr>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="left" className="textabla3">Subtotal</td>
                      <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="center" className="textabla3">$&nbsp;&nbsp;{subtotal}</td>
                    </tr>
                    <tr>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center" ></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="left" className="textabla3">IVA</td>
                      <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="center" className="textabla3">$&nbsp;&nbsp;{iva}</td>
                    </tr>
                    <tr>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} align="center"></td>
                      <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="left" className="textabla3">Total</td>
                      <td style={{padding:"2px"}} bgcolor="#D6DBDF" align="center" className="textabla3">$&nbsp;&nbsp;{total}</td>
                    </tr>            
                     </tbody>
              </MDBTable >
              </div> 
            </center>           
         </div> 
         <div>  
            <MDBTable class="table" striped bordered  small style={{width:500}}  >
                <tr>
                  <td style={{padding:"2px"}} className="textabla3" width="40%">Fecha y Hora </td>  
                  <td style={{padding:"2px"}} className="textabla3" width="90%">{diaDeposito + " de " + mesDeposito + " del " + añoDeposito + "  " + cotizaciones[0].hora}</td>                  
                </tr>
                <tr>
                  <td style={{padding:"2px"}} className="textabla3" width="40%">Entidad bancaria</td>  
                  <td style={{padding:"2px"}} className="textabla3" width="60%">{cotizaciones[0].banco}</td>                  
                </tr>
                <tr>
                  <td style={{padding:"2px"}} className="textabla3" width="40%">Número de referencia</td>  
                  <td style={{padding:"2px"}} className="textabla3" width="60%">{cotizaciones[0].referenciaPago}</td>                  
                </tr> 
                <tr>
                  <td style={{padding:"2px"}} className="textabla3" width="40%">Monto</td>  
                  <td style={{padding:"2px"}} className="textabla3" width="60%">$&nbsp;{cotizaciones[0].importe}</td>                  
                </tr> 
                <tr>
                  <td style={{padding:"2px"}} className="textabla3" width="40%">Tipo de pago</td>  
                  <td style={{padding:"2px"}} className="textabla3" width="60%">{cotizaciones[0].tipoPago}</td>                                   
                </tr>  
            </MDBTable >
            </div>
      </div>
             </PDFExport>
                  </div>
            </div>   
        );
    }
}
 
export default PDFReport;