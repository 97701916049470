import React, { Component } from 'react';
import { Form , Input,  Card, Space,Image, Row,Col,Avatar} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
// import productos from './imagesPrue/productos2.svg'
import { MDBBtn, MDBView } from "mdbreact";
import { DialogUtility } from '@syncfusion/ej2-popups';
const { Option} = Select;


class ejemplo2 extends Component{
    constructor(props){
        super(props)
        this.state ={   
          tipo:"",
          concepto:"",
          precio:"",
          claveProducto:" ",  
          tipoLicenciamiento:"",  
          LineaProducto:"",
          size: 'small',
          tablas:[],
          modal:false,         
          productoActualizado:[],
          array2:[]      
        }   
        this.toggle = this.toggle.bind(this)  
        this.onChangeInput2 = this.onChangeInput2.bind(this)    
        this.handleChangeTipoPS = this.handleChangeTipoPS.bind(this)       
    }
    
    handleChangeTipoPS(value){
      this.setState({tipo:value})
    }

    handleChangelineaPS(value){
      this.setState({LineaProducto:value})
    }
    handleChangeLicenciamientoPS(value){
      this.setState({ipoLicenciamiento:value})
    }

    toggle = () => {
      this.setState({
        modal: !this.state.modal
      });   
    }

    onChangeInput = (e) => {
      const { id, value } = e.target;
      this.setState({
        [id]: value
      });
    };

 
   
    render(){
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 25 },
      };
       let formulario;
       let titulo = <h5>Registra Nuevo Cliente</h5>
       formulario =      
     
<center>
       <Card title={titulo} style={{width:"95%", marginTop:"1%"}}>                      
           <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish} >  
           <Col span={10}>  
              <Form.Item  labelAlign="left" required label="Concepto">
                    <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon">
                          <i className="fas fa-pencil-alt prefix"></i>
                          </span>
                      </div>
                      <textarea className="form-control" rows="5"
                                id="concepto"
                                type="text"
                                name="concepto"
                                onChange={this.onChangeInput}
                                value={this.state.concepto}
                                required
                                >
                        </textarea>
                    </div>
              </Form.Item>
              <Form.Item 
                  labelAlign="left"
                  label="Precio"
                  required>
                    <Input placeholder="$"               
                      id="precio"
                      type="number"
                      name="precio"
                      onChange={this.onChangeInput}
                      value={this.state.precio}/>
              </Form.Item>  
              <Form.Item 
                  label="Clave del Producto" labelAlign="left" required >
                      <Input
                        // placeholder="clave del producto"              		
                        id="claveProducto"
                        type="text"
                        name="claveProducto"
                        onChange={this.onChangeInput}
                        value={this.state.claveProducto}/>
              </Form.Item>    
              <Form.Item  label="Servicios" labelAlign="left" >
                   <Select  
                       onChange={this.handleChangeTipoPS}
                       defaultValue="Seleccione el tipo de Servicio"
                   >
                      <Option value="Servicio">Servicio</Option>
                      <Option value="Producto">Producto</Option>
                      <Option value="Alianza">Alianza</Option>
                      <Option value="Capacitación">Capacitación</Option>     
                   </Select>
              </Form.Item>
              <Form.Item  label="linea de productos"  labelAlign="left">
                  <Select
                    onChange={this.handleChangelineaPS}
                    defaultValue="Seleccione la linea de Producto"                    
                  >
                    <Option value="CONTABILIDAD">Contabilidad</Option>
                    <Option value="NÓMINAS">Nóminas</Option>
                    <Option value="BANCOS">Bancos</Option>            
                    <Option value="XML EN LÍNEA">XML en línea</Option>                   
                  </Select>
              </Form.Item>

              <Form.Item  label="Tipo de licenciamiento" labelAlign="left" >
                   <Select                     
                      placeholder="Seleccione el tipo de licenciamiento"
                      onChange={this.handleChangeLicenciamientoPS}
                      defaultValue="Seleccione el tipo de licenciamiento"
                   >                   
                    <Option value="lICENCIAMIENTO ANUAL">licenciamiento anual</Option>
                    <Option value="lICENCIAMIENTO TRADICIONAL">licenciamiento tradicional</Option>
                   </Select>
              </Form.Item>
              </Col>
           </Form>
            <Row>
              <Col span={8}><div></div></Col>
              <Col span={8}>
                <div className="text-center">                     
                    <MDBBtn size="sm" color="info"onClick={e=>this.onSubmitBtn()}>Registrar cliente</MDBBtn>
                    <MDBBtn size="sm" color="danger" onClick={e=>this.onClear()}>Borrar</MDBBtn>                                   
                </div> 
              </Col>
              <Col span={8}><div  className="text-center"><Image width={200} src={""}/></div></Col>
            </Row>
             
       </Card>   
</center>         

        return(
            <React.Fragment> 
              <Form>
                {formulario}
              </Form>
            </React.Fragment>   
        )
    }
}export default ejemplo2;