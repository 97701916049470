import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { Card, Input, Modal, Progress,Button } from "antd";
import { UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { storage } from "../administradorAlfa/firebase"; // Asegúrate de que la configuración de Firebase esté correctamente exportada desde firebaseConfig.js
import "./index.css";
import actionsAPI from "./actionsAPI";
import Swal from "sweetalert2";

class VacantesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vacantes: [],
      showForm: false,
      file: null,
      url: "",
      progress: 0,
      uploading: false,
      sending: false,
      modalVisible: false,
      infoModalVisible: false,
      formValues: {
        vacancyName: "",
        publicationDate: "",
        company: "",
        contact: "",
      },
      solicitud_vacantes:[],
      vacante_seleccionada:[],
      modal_vacante_seleccionada:false,
      solicitudes:[]
    };
  }

  async componentWillMount() {
    await this.get_solicitud_vacantes();

    this.getVacantes();
  }

  getVacantes = () => {
    actionsAPI.get_vacantes().then((response) => {
      this.setState({ vacantes: response.data.data.get_vacantes });
    });
  };
  get_solicitud_vacantes = () => {
    actionsAPI.get_solicitud_vacantes().then((response) => {
      this.setState({ solicitud_vacantes: response.data.data.get_solicitud_vacantes});
      console.log("solicitudes",response.data.data.get_solicitud_vacantes)
    }).catch(err=>{
        console.log("err",err.response)
    })
  }

  handleFileChange = (e) => {
    console.log("e",e)
    if (e.target.files[0]) {
      this.setState({ file: e.target.files[0] });
    }
  };
  onOk (){
    this.setState({ modal_vacante_seleccionada: false });
  }

  handleUpload = () => {
    const { file } = this.state;
    if (!file) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor selecciona un archivo primero.",
      });
      return;
    }

    this.setState({ modalVisible: true, uploading: true });
    const uploadTask = storage.ref(`documents/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        console.error("Error de carga:", error);
        this.setState({ modalVisible: false, uploading: false });
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al cargar el archivo. Por favor intenta de nuevo.",
        });
      },
      () => {
        storage
          .ref("documents")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            this.setState({ modalVisible: false, uploading: false, url });
            Swal.fire({
              icon: "success",
              title: "¡Archivo cargado!",
              text: "El archivo se cargó exitosamente.",
            });
          });
      }
    );
  };

  handleSend = () => {
    const { url, formValues } = this.state;
    if (!url) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Primero debes subir un archivo.",
      });
      return;
    }

    const { vacancyName, publicationDate, company, contact } = formValues;
    if (!vacancyName || !publicationDate || !company || !contact) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Todos los campos son obligatorios.",
      });
      return;
    }

    this.setState({ sending: true });
    const data = {
      vacancyName,
      publicationDate,
      company,
      contact,
      url,
    };

    actionsAPI
      .sendPdf(data)
      .then((response) => {
        this.setState({ sending: false });
        if (response.data.data.insertUrlPdfVacant.message === "registro exitoso") {
          Swal.fire({
            icon: "success",
            title: "¡Vacante registrada!",
            text: "La vacante se registró con éxito.",
          });
          this.setState({
            formValues: {
              vacancyName: "",
              publicationDate: "",
              company: "",
              contact: "",
            },
            showForm: false,
          });
          this.getVacantes();
        }
      })
      .catch((error) => {
        this.setState({ sending: false });
        console.error("Error al enviar el PDF:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al enviar la vacante. Por favor intenta de nuevo.",
        });
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formValues: { ...prevState.formValues, [name]: value },
    }));
  };

  handleCancel = () => {
    this.setState({
      file: null,
      url: "",
      formValues: {
        vacancyName: "",
        publicationDate: "",
        company: "",
        contact: "",
      },
      showForm: false,
    });
  };

  handleEdit = (rowData) => {
    console.log("Edit:", rowData);
  };

  handleDelete = (rowData) => {
    actionsAPI.delete_vacante(rowData[1]).then(response=>{
        Swal.fire({
            icon: "success",
            title: "Aviso",
            text: "Vacante eliminada",
          });
          setTimeout(()=>{
            window.location.reload();
          },2000)
    })
    console.log("Delete:", rowData);
  };
  info(param){
    let vacante = this.state.vacantes
    let array = []

    vacante.forEach(function(hero){
        if(hero.folio === param[1]){
            array.push(hero)
        }

    })

    let solicitud_vacante = this.state.solicitud_vacantes

    let arraySolicitud = [];
    solicitud_vacante.forEach(function(hero){
        if(hero.id_vacantes === array[0].id_vacantes){
            arraySolicitud.push(hero)
        }
    })

    this.setState({ solicitudes :  arraySolicitud });
    this.setState({vacante_seleccionada:array})
    this.setState({ modal_vacante_seleccionada: true });
    console.log("soli",arraySolicitud)

  }

  render() {
    const { vacantes, showForm, progress, uploading, url, sending, modalVisible, infoModalVisible, formValues } = this.state;


    let modal_v = <Modal width={700} title = {"Usuarios interesados"} open={this.state.modal_vacante_seleccionada} footer={null} onCancel={e=>this.onOk()}>
        <table className="table table-small table table-bordered table table-striped">
            <tr>
                <td>Nombre</td>
                <td>Apellidos</td>
                <td>Correo</td>
                <td>Telefono</td>
            </tr>
            {this.state.solicitudes.map(rows=>{
                return(<tr>
                    <td>{rows.nombre}</td>
                    <td>{rows.apellidos}</td>
                    <td>{rows.correo}</td>
                    <td>{rows.telefono}</td>

                </tr>)
            })}
            <tr></tr>
        </table>

    </Modal>

    let nota_solicitud_vacantes;

    if(this.state.solicitud_vacantes[0]){
        nota_solicitud_vacantes = <font color="blue">Nota: Se encontraron solicitudes en algunas vacantes ofertadas, mas detalles en cada vacante de la tabla.</font>
    }

    const columns = [
      { name: "titulo_vacante", label: "Vacante" },
      { name: "folio", label: "Folio" },
      { name: "fecha_publicacion", label: "Fecha de emisión" },
      { name: "empresa_contratacion", label: "Empresa que contrata" },
      { name: "correo_contacto", label: "Correo de contacto" },
      { name: "url_pdf", label: "Requisitos" },
      {
        name: "delete",
        label: "Eliminar",
        options: {
          customBodyRender: (value, tableMeta) => (
            <div className="table-action-buttons">
              <button
                className="action-button delete-button"
                onClick={() => this.handleDelete(tableMeta.rowData)}
              >
                <i className="fas fa-trash"></i> Eliminar
              </button>
            </div>
          ),
        },
      },
      {
        name: "details",
        label: "Detalles",
        options: {
          customBodyRender: (value, tableMeta) => (
            <div className="table-action-buttons">
              <button
                className="action-button "
                onClick={() => this.info(tableMeta.rowData)}
              >
            <i class="fa fa-info"></i>             
            </button>
            </div>
          ),
        },
      },
    ];

 
   let data = this.state.vacantes.map(rows=>{
    let buttonform = <a
    target="_blank"
    href={rows.url_pdf}
    >
        <span>Ver documento</span>
    </a>
    return([rows.titulo_vacante, rows.folio, rows.fecha_publicacion, rows.empresa_contratacion, rows.correo_contacto, buttonform])
    })
    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      search: false,
      responsive: "standard",
    };
    let card = <Card className="container00" type="inner">
    <h1 className="card-title">Publicar Vacante</h1>
    <div className="upload-form">
      <div className="form-item">
        <label>Título de la vacante:</label>
        <Input
          type="text"
          name="vacancyName"
          value={formValues.vacancyName}
          onChange={this.handleInputChange}
          placeholder="Ingrese el título de la vacante"
          required
        />
      </div>
      <div className="form-item">
        <label>Fecha de publicación:</label>
        <Input
          type="date"
          name="publicationDate"
          value={formValues.publicationDate}
          onChange={this.handleInputChange}
          required
          className="custom-date-input"
        />
      </div>
      <div className="form-item">
        <label>Empresa de contratación:</label>
        <Input
          type="text"
          name="company"
          value={formValues.company}
          onChange={this.handleInputChange}
          placeholder="Ingrese el nombre de la empresa que contrata"
          required
        />
      </div>
      <div className="form-item">
        <label>Correo de contacto:</label>
        <div className="rows">
        <Input
          type="email"
          name="contact"
          value={formValues.contact}
          onChange={this.handleInputChange}
          placeholder="Ingrese el correo de contacto"
          required
        />
        <InfoCircleOutlined onClick={() => this.setState({ infoModalVisible: true })} className="info-icon" />
        </div>
      </div>
      <div className="form-item" style={{marginTop:"3em"}}>
        <label>Detalles y requisitos en PDF</label>
        <input
          type="file"
          accept=".pdf"
          onChange={this.handleFileChange}
          className="file-input"
        />
        <button
        style={{marginTop:"1em"}}
          className="upload-button"
          onClick={this.handleUpload}
          disabled={uploading}
        >
          {uploading ? <span>Subiendo...</span> : <><UploadOutlined /> Subir PDF</>}
        </button>
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer" className="pdf-link">
            Visualizar PDF
          </a>
        )}
      </div>
    </div>
    <div className="form-actions">
      <button
      style={{marginTop:"1em"}}
        className="send-button"
        onClick={this.handleSend}
        disabled={sending}
      >
        {sending ? <span>Enviando...</span> : <span>Enviar</span>}
      </button>
      <button
      style={{marginTop:"1em"}}
        className="cancel-button"
        onClick={this.handleCancel}
      >
        Cancelar
      </button>
    </div>
  </Card>

    return (
      <div className="vacantes-container">
        {!showForm ? (
          <div>
            <Card  title={<div>{nota_solicitud_vacantes}</div>} type="inner" extra={
            <Button
            type="primary"
              onClick={() => this.setState({ showForm: true })}
            >
              Registrar Vacante
            </Button>}>
            <MUIDataTable
              title={<div className="strong"><h5>Listado de vacantes registradas</h5></div>}
              data={data}
              columns={columns}
              options={options}
              className="vacantes-table"
            />
            </Card>
          </div>
        ) : (
          <div className="file-upload-container">
            <Modal
              visible={modalVisible}
              title="Cargando archivo"
              footer={null}
              closable={false}
              centered
            >
                <Progress type="circle" percent={progress} />
                <p>Cargando... {progress}%</p>
            </Modal>
            <Modal
              visible={infoModalVisible}
              title="Información de contacto"
              footer={[
                <button key="close" onClick={() => this.setState({ infoModalVisible: false })} className="modal-close-button">
                  Cerrar
                </button>,
              ]}
              onCancel={() => this.setState({ infoModalVisible: false })}
            >
              <p>El correo de contacto asignado será el correo al que el cliente solicite un correo con información de la vacante.</p>
            </Modal>
              {card}
            </div>
        )}
                      {modal_v}
      </div>
    );
  }
}

export default VacantesTable;
