import React from 'react';
import './apps.css';
import Navbar from './Navbares';
import NewsTable from './NewsTable';

function DashboardNoticias() {
  return (
    <div className="DashboardNoticias">
      <Navbar />
      <header className="DashboardNoticias-header">
        <h1 className="DashboardNoticias-title">Dashboard de Noticias</h1>
        <p className="DashboardNoticias-description">Mantente actualizado con las últimas noticias del mes.</p>
      </header>
      <main className="DashboardNoticias-main">
        <NewsTable />
      </main>
      <footer className="DashboardNoticias-footer">
        <p>© 2024 Todos los derechos reservados - IMAI</p>
      </footer>
    </div>
  );
}

export default DashboardNoticias;