import React, { Component } from "react"
import MUIDataTable from "mui-datatables";
import { MDBIcon } from 'mdbreact';
import axios from 'axios'
import { Button, Card } from 'antd';


class Eventos extends Component{
  constructor(props){
    super(props)
    this.state = {
         larazonSocial:'',        
         arrayApi:[],
         arrayfilter:[]
         
        }
    } 

     async componentWillMount(){
      const fk_paquetes  = localStorage.getItem("fk_paquetes");
      const API='http://localhost:4000/graphql';       
    

    //  await axios({
    //     url:API,
    //     method:'post',
    //     data:{
    //         query:`
    //         query{
    //             getPaquetes(data:"${[fk_paquetes]}"){
    //                empresas
    //            } 
    //         }
    //         `
    //     }   
    //      }).then(response=>{
    //         // console.log("response-DASH",response)
    //         localStorage.setItem("paquetesdeAdmonGral",response.data.data.getPaquetes.empresas)
    //      })
    //      .catch(err=>{
    //          console.log('error',err)
    //      })

 // ** API *
        // axios.get(`https://www.eventbriteapi.com/v3/users/me/?token=LE7TWGVIP64TOSQ7VWHV`)
         //axios.get(`https://www.eventbriteapi.com/v3/categories/?token=LE7TWGVIP64TOSQ7VWHV`)
         //axios.get(`https://www.eventbriteapi.com/v3/events/147172170925/?token=LE7TWGVIP64TOSQ7VWHV`) encontre el id_organizations
 // ***

       
        //   axios.get(`https://www.eventbriteapi.com/v3/organizations/524103565401/events/?token=LE7TWGVIP64TOSQ7VWHV`)
        // .then(res => {        
        //   this.setState({arrayApi:res.data.events})
        //  console.log("rows",this.state.arrayApi)            
        // }).catch(err=>{
        //   console.log(err)
        // })
         
      
     axios.get(`https://www.eventbriteapi.com/v3/organizations/113651495255/events/?page=12&token=4AAAXUODUJCWFTWJ4O7F`)
       
     .then(response=>{  
       console.log("eventos",response.data.events)    
       let eventos = response.data.events;
       let eventoActivo = eventos.filter(function(hero){
        return hero.status === "live"  ||  hero.status === "started" 
      })
       this.setState({arrayApi:eventoActivo})    
      }).catch(error=>{
        console.log("error",error)
      })
        }

    render(){ 
      let cards; 
      let carousel;
      let buton;
       const columns = ["Imagen","Evento", "Finalización","Descripción","Registrarse"];
        let data =  this.state.arrayApi.map(rows=>{  
          let fechaFinal;
          if(rows.end){
            let fechaFinal3 = rows.end.local.substring(0,4)
            let fechaFinal2 = rows.end.local.substring(5,7)
            let fechaFinal1 = rows.end.local.substring(8,10)

            fechaFinal = fechaFinal1 + "/" + fechaFinal2 + "/" + fechaFinal3
          }   
          cards =  
          <td ><img src = {rows.logo.url}  style={{height:60, width: 100}}/></td>           

            buton=<div>
               <Button type="primary" shape="circle" size="large" href={rows.url} target=" _blank"  ><MDBIcon icon="chalkboard-teacher"/></Button>
             
            </div>
        return( [ cards,rows.name.text,fechaFinal,rows.description.text, buton])
      })
      
      
      const options={ 
        filterType:"drowpdawn",
        responsive: "stacked",
        responsive:"standard",
        print:false,
        download:false,
        filter:false,
        caseSensitive:false,
        selectableRows:"none",
        elevation:0,
        viewColumns:false,  
        search:false,    
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      
      }        
      } 
      let titulo  = <strong><h5>Eventos de Alfa Diseño de Sistemas</h5></strong>
      //  const rs = localStorage.getItem("razonSocial");     
        return(
        <React.Fragment>
          {/* <Navbar/> */}
             {/* <NavbarDashboard data={rs}/> */}
           <div  style={{width:"95%",marginLeft:"3%",marginTop:"2%"}} >  
             <Card title = {titulo}>        
                <MUIDataTable  
                  data={data}
                  columns={columns} 
                  options={options} 
                    
                /> 
            </Card> 
           </div> 
        </React.Fragment>
        )
    }
} export default Eventos