import React, { Component } from 'react';
import swal from 'sweetalert';
import actions from './transactionsAPI'
class ClientesADS extends Component{
    constructor(props){
        super(props)
        this.state ={
          empresa:"",
          rfc:"",
          tamañoEmpresa:'',      
          domicilioFiscal:"",
          paginaWeb:"",
          giroEmpresarial:'', 
          tipoEmpresa:''
        }    
        this.handleChangeGiroE = this.handleChangeGiroE.bind(this)
        this.handleChangeTamañoE = this.handleChangeTamañoE.bind(this)
        this.handleChangeTipoE = this.handleChangeTipoE.bind(this)
    }
    onChangeInput = (e) => {   
      const { id, value } = e.target;
      this.setState({
        [id]: value
      });
    };

    handleChangeGiroE(e) {
      if(e.target.value){
        this.setState({giroEmpresarial:e.target.value})         
      }
    }
    handleChangeTamañoE(e) {
      if(e.target.value){
        this.setState({tamañoEmpresa:e.target.value})    
      }
    }
    handleChangeTipoE(e) {
      if(e.target.value){
        this.setState({tipoEmpresa:e.target.value})    
      }
    }
    onSubmitBtn = (e) => {   
      e.preventDefault();
      let empresa = this.state.empresa.toUpperCase().replace(/,/g, "");
      let rfc = this.state.rfc.toUpperCase(); 
      let fk_empresa =  localStorage.getItem("fk_empresa")   
      let tamañoEmpresa = this.state.tamañoEmpresa;
      let giroEmpresarial = this.state.giroEmpresarial       
      let domicilioFiscal = this.state.domicilioFiscal.toUpperCase().replace(/,/g, "");
      let paginaWeb = this.state.paginaWeb
      let tipoEmpresa = this.state.tipoEmpresa      
      if( empresa && rfc ){  
        actions.insertClientesAlfa(rfc,empresa,tipoEmpresa,tamañoEmpresa,giroEmpresarial,domicilioFiscal,paginaWeb,fk_empresa).then(response=>{
            if(response.data.data.insertClientesAlfa.message === "registro exitoso"){             
              swal({
              text:"Registro exitoso!",
              title:"",
              icon:"success"
              });       
            } else if(response.data.data.insertClientesAlfa.message === "rfc ya registrado"){ 
              swal({
              text:"El rfc ya está registrado",               
              icon:"error",
              });
            }else{
            swal({
              text:"Upps algo salió mal!",               
              icon:"error",
              });
            }        
        }).catch(err=>{
          console.log("error", err.response);
        })
      this.setState({rfc:[],empresa:[],tamañoEmpresa:[],giroEmpresarial:[],domicilioFiscal:[],paginaWeb:[],tipoEmpresa:[]}) 
      }else{
      swal({
        title:"Por favor complete los campos",
        text:"Razón social, RFC",
        icon:"warning"
        }); 
      }   
    }; 

    cerrar(){
      window.location.reload();
    }
    render(){
        let formulario;
        let titulo = <h5>Registrar Empresa</h5>
        formulario =    <div className="container23">
        <div className="column">
            <img
              className="container26"
              src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            />
          </div>
          <div className="column" style={{marginTop:"10%"}}>
            <div className="container24">
                <h4>{titulo}</h4>
            </div>
            <div class="container22">
              <form onSubmit={this.onSubmitBtn}>
                <div class="row">
                  <div class="input-group input-group-icon">
                    <input
                      placeholder="Razón Social"                                  
                      id="empresa"
                      type="text"
                      name="empresa"
                      onChange={this.onChangeInput}
                      value={this.state.empresa}
                      required/>
                    <div class="input-icon">
                      <i class="fa fa-user"></i>
                    </div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input
                      placeholder="RFC"                                  
                      id="rfc"
                      type="text"
                      name="rfc"
                      onChange={this.onChangeInput}
                      value={this.state.rfc}  
                      required
                    />
                    <div class="input-icon">
                      <i class="fa fa-user"></i>
                    </div>
                  </div>
                  <div class="col-half">
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="industria"
                        id="industria"
                        onChange={this.handleChangeGiroE}         
                      >
                        <option value="">Tipo de Industria</option>
                        <option value="AGRICULTURA GANADERÍA y PESCA">Agricultura, ganadería y pesca</option>
                        <option value="CATASTRO y GESTIÓN TERRITORIAL">Catastro y Gestión Territorial</option>
                        <option value="COMERCIO">Comercio</option>            
                        <option value="COMERCIO EXTERIOR">Comercio Exterior</option>
                        <option value="CONSTRUCCIÓN">Construcción</option>
                        <option value="EDUCACIÓN">Educación</option>
                        <option value="EMPRESAS Y ESTABLECIMIENTOS">Empresas y establecimientos</option>
                        <option value="GOBIERNO">Gobierno</option>
                        <option value="HOGARES Y VIVIENDA">Hogares y Vivienda</option>
                        <option value="MANUFACTURAS">Manufactura</option>
                        <option value="MARCO GEODÉSICO">Marco Geodésico</option>
                        <option value="MARCO GEOESTADÍSTICO">Marco Geoestadístico</option>
                        <option value="MEDIO AMBIENTE">Medio ambiente</option>
                        <option value="MINERÍA">Minería</option>
                        <option value="PIB Y CUENTAS NACIONALES">PIB y Cuentas Nacionales</option>            
                        <option value="SALUD Y SEGURIDAD SOCIAL">Salud y Seguridad Social</option>
                        <option value="SEGURIDAD PÚBLICA Y JUSTICIA">Seguridad pública y justicia</option>
                        <option value="SERVICIOS NO FINANCIEROS">Servicios no financieros</option>
                        <option value="TECNOLOGÍAS DE LA INFORMACIÓN Y COMUNICACIONES">Tecnología de la información y comunicaciones</option>
                        <option value="TRANSPORTE">Transporte</option>
                        <option value="TURISMO">Turismo</option>
                        <option value="OTRO">Otro</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-half">
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="tamaño"
                        id="tamaño"
                        onChange={this.handleChangeTamañoE}
                      >
                        <option value="">Tamaño de su empresa</option>
                        <option value="AGRICULTURA GANADERÍA y PESCA">1 - 15 Empleados</option>
                        <option value="CATASTRO y GESTIÓN TERRITORIAL">16 - 50 Empleados</option>
                        <option value="COMERCIO">51 - 100 Empleados</option>            
                      </select>
                    </div>
                  </div>
                  <div class="col-half">
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="tipoCliente"
                        id="tipoCliente"
                        value="seleccione tipo de Cliente"                     
                        onChange={this.handleChangeTipoE}
                      >
                        <option value="">Tipo de Cliente</option>
                        <option value="AGRICULTURA GANADERÍA y PESCA">Oportunidad de negocio</option>
                        <option value="CATASTRO y GESTIÓN TERRITORIAL">Prospecto</option>
                        <option value="COMERCIO">Otro</option>            
                      </select>
                    </div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input
                      placeholder="Domicilio Fiscal"  
                      id="domicilioFiscal"
                      name="domicilioFiscal"
                      onChange={this.onChangeInput}
                      value={this.state.domicilioFiscal}/>
                    <div class="input-icon">
                      <i class="fa fa-user"></i>
                    </div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input
                      placeholder="Página web"  
                      id="paginaWeb"
                      name="paginaWeb"
                      onChange={this.onChangeInput}
                      value={this.state.paginaWeb}/>
                    <div class="input-icon">
                      <i class="fa fa-user"></i>
                    </div>
                  </div>
                  <div class="input-group input-group-icon">
                    <button
                      class="button2"
                      onClick={(e) => this.onSubmitBtn(e)}
                    >
                      <span class="text">Agregar</span>
                      <span class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <button onClick={(e) => this.cerrar()} class="button">
                    <span class="text">Cancelar</span>
                    <span class="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        return(
            <div> 
              <center>
                {formulario}
              </center>
            </div>   
        )
    }
}export default ClientesADS;