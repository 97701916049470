import React, { useState,useEffect} from "react";
import "./index.css";
import { DataProd, DataCom, NomProd} from "./items";
import { Card, Button,Tag,Modal,Rate, Badge,notification, DatePicker   } from "antd";
import svg from '../imagen/details.svg'
import swal from "sweetalert";
import actionsAPI from "./actionsAPI";
import { FacebookEmbed, XEmbed, YouTubeEmbed, LinkedInEmbed } from 'react-social-media-embed';
import Rating from './app_nivo'
import '../../components/administradorAlfa/index.css'
import ads from '../imagen/logo_color_azul.png'
import Content from "./home_courses";
import MUIDataTable from "mui-datatables";
import {CaretRightFilled,CalendarOutlined,UserOutlined,DesktopOutlined,StarOutlined,FieldTimeOutlined,HomeOutlined,QuestionOutlined} from '@ant-design/icons';
import { CloudOutlined } from "@material-ui/icons";

let allItems = [];
actionsAPI.getCourses().then((response)=>{
  if(response.data.data.getCourses)
      allItems.push(response.data.data.getCourses);
      allItems[0].map(rows=>{
        let indice = parseInt(rows.indice)
        let user_min = parseInt(rows.user_min)
        if(rows.habilitar === "automatico"){
          if(indice >= user_min){
              actionsAPI.activar_curso(rows.id_courses).then(response=>{
              })
          }
        }
      })
}).catch(err=>{
  console.log(err)
  console.log("err",err.response)
})


let messenger = "All";
function Menu() {
  
  const [card, setCard] =  useState(true);
  const [form, setForm] =  useState(false);
  const [array, setArray] =  useState([]);
  const [nombre, setNombre] =  useState('');
  const [apellidos, setApellidos] =  useState('');
  const [telefono, setTelefono] =  useState('');
  const [correo, setCorreo] =  useState('');
  const [cp, setCp] =  useState('');
  const [state, setState] = useState([]);
  const [fb,setfb] = useState(false)
  const [modal_open,set_modal_open] = useState(false)
  const [link_fb,set_link_fb] = useState('')
  const [tw,settw] = useState(false)
  const [modal_open_tw,set_modal_open_tw] = useState(false)
  const [link_tw,set_link_tw] = useState('')

  const [yt,setyt] = useState(false)
  const [modal_open_yt,set_modal_open_yt] = useState(false)
  const [link_yt,set_link_yt] = useState('')
  const [li,setli] = useState(false)
  const [modal_open_li,set_modal_open_li] = useState(false)
  const [link_li,set_link_li] = useState('')
  const [rating,setRating] = useState(false)
  const [args,setArgs] = useState(true)
  const [exclusivos,setExclusivos] = useState(false)
  const [anteriores,setAnteriores] = useState(false)
  const [profile,setProfile] = useState(false);
  const [nombre_,set_nombre] = useState('')
  const [apellidos_,set_apellidos] = useState('')
  const [telefono_,set_telefono] = useState('')
  const [correo_,set_correo] = useState('')
  const [fecha_nacimiento,set_fecha_nacimiento] = useState('')
  const [cp_,set_cp] = useState('')
  const [telefon_empresa,set_telefono_empresa] = useState('')
  const [profesion,set_profesion] = useState('')
  const [genero,set_genero] = useState('')

  const [coursos_anteriores1,set_cursos_anteriores1] = useState([])


  let nombre_user = localStorage.getItem("nombre_plataform") || "";
  let apellidos_user = localStorage.getItem("apellidos_plataform") || "";
  let telefono_user = localStorage.getItem("telefono_plataform") || "";
  let correo_user = localStorage.getItem("correo_plataform") || "";

  let nombre_plataform = localStorage.getItem("nombre_plataform");
  let apellidos_plataform = localStorage.getItem("apellidos_plataform");
  let correo_plataform = localStorage.getItem("correo_plataform");
  let telefono_plataform = localStorage.getItem("telefono_plataform");
  let telefono_empresa_plataform = localStorage.getItem("telefono_empresa_plataform");
  let cp_plataform = localStorage.getItem("cp_plataform");
  let profesion_plataform = localStorage.getItem("profesion_plataform");
  let rangoEdad_plataform = localStorage.getItem("rangoEdad_plataform");

  useEffect(() => {
    setState([{args:"args"}])
  }, []);
  

  const ShowItems = (arg = "All") => {
    messenger = arg;
    console.log(`messenger is ${arg}`);
    if (arg === "All") {
      setArgs(true)
      setRating(false)
      setState([])
      setExclusivos(false)
      setAnteriores(false)
      setProfile(false)
    };

    if (arg === "Exclusivos") {
      setForm(false);
      setExclusivos(true)
      setArgs(false)
      setRating(false)
      setState([])
      setAnteriores(false)
      setProfile(false)
    };
    if (arg === "Anteriores") {
      console.log("entroGet")
      setForm(false);
      setAnteriores(true)
      setExclusivos(false)
      setArgs(false)
      setRating(false)
      setState([])
      setProfile(false)
      setProfile(false)
      getMyCourses();


    };
    if (arg === "Profile") {
      setForm(false);
      setAnteriores(false)
      setExclusivos(false)
      setArgs(false)
      setRating(false)
      setState([])
      setProfile(true)
    };
    if (arg === "Productos") {
      setForm(false);
      setState(DataProd)
      setRating(false)
      setArgs(false)
      setExclusivos(false)
      setAnteriores(false)
      setProfile(false)
    };

    if (arg === "Nom 035") {
      setForm(false);
      setState(NomProd)
      setRating(false)
      setArgs(false)
      setExclusivos(false)
      setAnteriores(false)
      setProfile(false)
    };

    if (arg === "Comerciales") {
      setForm(false);
      setState(DataCom)
      setRating(false)
      setArgs(false)
      setExclusivos(false)
      setAnteriores(false)
      setProfile(false)
    };

    if (arg === "Rating") {
      setForm(false);
      setState([])
      setRating(true)
      setArgs(false)
      setExclusivos(false)
      setAnteriores(false)
      setProfile(false)
    };
  };

  const logonForm = (id) =>{
    let array = [];
    allItems[0].forEach(function(hero){
      if(hero.id_courses === id){
        array.push(hero)
      }
    })
    setCard(false);
    setForm(true);
    setArray(array[0])
  }
  const cancelar = () => {
    setCard(true);
    setForm(false);
    setArray([])
  }

  const solicitar_cotizacion = () => {
    swal({
      title:"Cotización solicitada",
      text:`Su cotización fue solicitada, en breve podrá visualizar su documento en el apartado cotizaciones`,               
      icon:"success",
    });
  }

  const onSubmitBtn = (e)=>{
    e.preventDefault();
    let fecha = new Date(); 
    let id_curse = array.id_courses;
    let index = parseInt(array.indice)
    let new_index = index + 1;

    if(nombre_plataform){
      actionsAPI.sendSubmit(array.id_courses,nombre_plataform,apellidos_plataform,telefono_plataform,correo_plataform,cp_plataform,fecha,new_index,id_curse,array.concepto,array.descripcion).then(response=>{
        swal({
          text:`Te inscribiste al curso ${array.concepto}, ${array.descripcion}, No olvides presentarte con anticipación al mismo`,               
          icon:"success",
        });
        setTimeout(()=>{
            window.location.reload();
        }, 6000) 
      }).catch(err=>{
        console.log(err)
      })
    }else{
      if(nombre && apellidos && telefono && correo && cp){
        actionsAPI.sendSubmit(array.id_courses,nombre,apellidos,telefono,correo,cp,fecha,new_index,id_curse,array.concepto,array.descripcion).then(response=>{
          swal({
            text:`Te inscribiste al curso ${array.concepto}, ${array.descripcion}, No olvides presentarte con anticipación al mismo`,               
            icon:"success",
          });
          setTimeout(()=>{
              window.location.reload();
          }, 6000) 
        }).catch(err=>{
          console.log(err)
        })
      }else{
        swal({
          text:"No deje espacios vacios",               
          icon:"error",
          }); 
      }
    }
    
  
  }

  const getMyCourses = async () =>{
    console.log("entro")
    let array = [];
    let respon;
    await actionsAPI.getRegisterCourses(localStorage.getItem("correo_plataform")).then(response =>{
       respon = response.data.data.getRegisterCourses;
      
    }).catch(err=>{
      console.log("error",err.response)
    })
    if(respon){
      for(let i = 0; i<= respon.length; i++){
        if(respon[i]){
          actionsAPI.getRegisterCoursesById(respon[i].fk_courses).then(data=>{
            let cursos = data.data.data.getRegisterCoursesById
            array.push(cursos[0])
            setTimeout(()=>{
              set_cursos_anteriores1(array);
            },1000)
          }).catch(err=>{
            console.log(err.response)
          })
        }
      }
      console.log("array",array)
    }
  }

  const onChangeNombre = e => {
    setNombre(e.target.value)  
  };
  const onChangeApellidos = e => {
    setApellidos(e.target.value)  
  };
  
  const onChangeTelefono = e => {
    setTelefono(e.target.value)  
  };
  const onChangeCorreo = e => {
    setCorreo(e.target.value)  
  };
  const onChangeCp = e => {
    setCp(e.target.value)  
  };
  const sharefb = (link) => {
    set_link_fb(link)
    setfb(true)
    set_modal_open(true)
  }
  const onOk = () => {
    set_modal_open(false)
    setfb(false)
  }

  const sharetw = (link) => {
    set_link_tw(link)
    settw(true)
    set_modal_open_tw(true)
  }
  const onOktw = () => {
    set_modal_open_tw(false)
    settw(false)
  }
  
  const shareyt = (link) => {
    set_link_yt(link)
    setyt(true)
    set_modal_open_yt(true)
  }
  const onOkyt = () => {
    set_modal_open_yt(false)
    setyt(false)
  }
  const shareli = (link) => {
    set_link_li(link)
    setli(true)
    set_modal_open_li(true)
  }
  const onOkli = () => {
    set_modal_open_li(false)
    setli(false)
  }
  const cerrar_sesion = () =>{
    localStorage.clear();
    window.location.reload()
  }

  const onSubmitBtnProfile = (e) =>{
    if(telefon_empresa && telefono_&& cp_ && fecha_nacimiento && genero && profesion){
      actionsAPI.update_profile(nombre_plataform, apellidos_plataform, telefon_empresa, telefono_plataform,correo_plataform, cp_, fecha_nacimiento, genero, profesion).then(response=>{
        if(response.data.data.update_profile.message === "perfil completado"){
          swal({
            text:"Perfil completado",               
            icon:"success",
          });
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }if(response.data.data.update_profile.message === "correo no encotrado"){
          swal({
            text:"El correo proporcionado no es válido",               
            icon:"success",
          });
        }
      }).catch(err=>{
        console.log(err.response)
      })
    }else{
      swal({
        text:"No deje espacios vacios",               
        icon:"error",
      });
    }

  }
  const onChangeInputForm_nombre = (e) => {
    set_nombre(e.target.value)
  };
  const onChangeInputForm_apellidos = (e) => {
    set_apellidos(e.target.value)
  };
  const onChangeInputForm_telefono = (e) => {
    set_telefono(e.target.value)
  };
  const onChangeInputForm_correo = (e) => {
    set_correo(e.target.value)
  };
  const onChangeInputForm_cp = (e) => {
    set_cp(e.target.value)
  };
  const onChangeInputForm_telefono_empresa= (e) => {
    set_telefono_empresa(e.target.value)
  };
  const onChangeInputForm_profesion = (e) => {
    set_profesion(e.target.value)
  };
  const onChangeInputForm_genero = (e) => {
    set_genero(e.target.value)
  };
  const dateOnChange = (date, dateString) => {
    set_fecha_nacimiento(dateString)
  };
   
const columns = [
  {
   name: "curso",
   label: "Curso",
   options: {
    filter: true,
    sort: true,
   }
  },{
    name: "Descripcion",
    label: "Descripción",
    options: {
     filter: true,
     sort: false,
    }
   },
  {
   name: "fecha",
   label: "Fecha del curso",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "imagen",
   label: "Imagen",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
    name: "instructor",
    label: "Expositor",
    options: {
     filter: true,
     sort: false,
    }
   },
  {
   name: "ver",
   label: "Volver a ver",
   options: {
    filter: true,
    sort: false,
   }
  },
 ];
 let leyenda;
  


 let rfc1 = localStorage.getItem("rfc");
 let rs1 = localStorage.getItem("rs");
 let rfc;
 let rs;
 let a=0;
 if(rfc1){
   rfc = <font color="green" className="strong">{rfc1}</font>
    notification.open({ 
      message: 'Beneficios encontrados', 
      description: 
      `Por ser cliente de Tecno 360 Consultores tendrás un descuento especial en todos los cursos con costo incluidos en tu cotización`, 
      
    })
    setTimeout(()=>{
      notification.destroy()

    },2000)
    }
 if(rs1){
   rs = <font color="green" className="strong">{rs1}</font>
 }   
 const data = coursos_anteriores1.map(rows=>{
  if(rows){
    return([rows.concepto,rows.descripcion,rows.fecha_curso,<img src={rows.imagen} width={110}></img>,rows.instructor,<Button style={{backgroundColor:"rgb(38,90,159)"}}>Ver</Button>])

  }
 })
 const optionsa = {
  print:false,
  sort:false,
  viewColumns:false,
  download:false,
   filterType: 'checkbox',
   filter:false,
   select:false,

   selectableRowsHeader:false
 };
  
  let validation1;
  let validation2;
  if(exclusivos === true){
    validation1 = 
    <div className="grid" style={{marginLeft:"26em"}}>
    <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src="https://pcpartners.com.mx/wp-content/uploads/2023/12/comercial-1.jpg" class="img-fluid rounded-start" alt="image"/>
          <center> <span className="strong">CONTPAQi contabilidad </span></center>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Temario</h5>
            <p class="card-text">1.1 – Consideraciones para crear una empresa.<br/>
            1.2 – Creación de empresas.<br/>
            1.3 – Configuración inicial de catálogo de cuentas.<br/>
            1.4 – Paseo por el sistema.<br/>
            1.5 – Catálogo de cuentas.<br/>
            1.5.1 – Cargado de catálogo desde Excel.<br/>
            1.5.2 – Alta de cientos de cuentas en minutos desde Excel.<br/>
            1.6 – Registro de cuentas contables.<br/>
            1.7 – Cuentas y estructura (Redefinición de la empresa)</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" style={{maxWidth:"540px"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src="https://idnube.com/wp-content/uploads/2020/06/respcuad.png" class="img-fluid rounded-start" alt="..."/>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">CONTPAQi Respaldos</h5>
            <p class="card-text">7.1 – ¿Qué es DIOT?<br/>
              7.2 – ¿Qué es DPIVA?<br/>
              7.3 – Configurar el control de IVA. (Para generar la DIOT/DPIVA)<br/>
              7.4 – Registrar operaciones para la DIOT/DPIVA.<br/>
              7.5 – Padrón de proveedores.<br/>
              7.6 – Crear proveedores a partir de un rango de cuentas.<br/>
              7.7 – Registro de Control de IVA a partir de los CFDI.<br/>
              7.12 – Generar el DPIVA.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src="https://www.integraconsorcio.com.mx/images/blog/single/614f74a5a6d01.jpg" class="img-fluid rounded-start" alt="image"/>
          <center> <span className="strong">Visor de documentos</span></center>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Temario</h5>
            <p class="card-text">2.1 – Nuevo visor de documentos digitales.<br/>
            2.2 – Configuración y cargado manual de XML.<br/>
            2.3 – Cargar documentos al ADD.</p>ß
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src="https://www.contpaqi.com/inicio/Banner_SUMA_2023.png" class="img-fluid rounded-start" alt="image"/>
          <center> <span className="strong">CONTPAQi Suma</span></center>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Temario</h5>
            <p class="card-text">3.3 – Conciliación de REP.<br/>
              3.1- Captura de pólizas de Diario.<br/>
              3.2 – Captura de pólizas de Ingreso y Egreso.<br/>
              3.2.1 – Pago de CFDI.3.2.2 – Documento bancario de la póliza.<br/>
              3.2.3 – Integrar REP a la póliza de pago de manera automática..</p><br/>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src="https://cpsmexico.com/wp-content/uploads/2020/04/Logo-CONTPAQi-Escritorio_Virtual_Blanco.jpg" class="img-fluid rounded-start" alt="image"/>
          <center> <span className="strong">CONTPAQi Escritorio virtual</span></center>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Temario</h5>
            <p class="card-text">4.1 – ¿Qué es el traspaso de auxiliares entre cuentas?<br/>
              4.2 – Beneficios y usos.<br/>
              4.3 – Consideraciones.</p><br/>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src="https://www.contpaqi.com/Colabora/Logo_Colabora.png" class="img-fluid rounded-start" alt="..."/>
          <center> <span className="strong">CONTPAQi Colabora</span></center>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Temario</h5>
            <p class="card-text">5.1 – Provisión de Venta.<br/>
              5.2 – Cobro a Cliente.<br/>
              5.3 – Factura a Contado.5.4 – Provisión de Gastos.<br/>
              5.5 – Provisión de Compra.</p>
          </div>
        </div>
      </div>
    </div>


  </div>
  }

  if(anteriores === true){
    validation2 = <div style={{width:"80%",marginLeft:"19em",marginTop:"3em"}}>
      <MUIDataTable
        title={"Mis cursos"}
        data={data}
        columns={columns}
        options={optionsa}
      />
    </div>
  }

  let ratingCard;
  if(rating === true){
    ratingCard = <div style={{height:"100%"}} className="margen"><Rating/></div>
  }

  let modal;
  let title_modal =<center><h6>Compartir publicación</h6></center> 
  if(fb === true){
    modal = <Modal footer={null} open={modal_open} onOk = {e=>onOk()} onCancel ={e=>onOk() }>
      {title_modal}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FacebookEmbed  url={link_fb} width={550} />
      </div>
    </Modal>
  }

  let modaltw;
  if(tw === true){
    modaltw = <Modal footer={null} open={modal_open_tw} onOk = {e=>onOktw()} onCancel ={e=>onOktw() }>
      {title_modal}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <XEmbed url={link_tw} width={325} />
      </div>
    </Modal>
  }

  let titleData ;

  let valoracion;
  let registrate;
  let profile_user;

  if(rating === true){
    valoracion =
    <div>
    <h6 className="strong">Valoración del mes 4.9 &nbsp; <i class="fa fa-star"></i> &nbsp; <Rate allowHalf defaultValue={4.9} /></h6>
    </div>
  }


  if(profile === true){
    let rs2 = localStorage.getItem("rs");
    let rfc2 = localStorage.getItem("rfc");
    let age;
    if(rangoEdad_plataform){
      const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
      age = <Tag color="green"><font color="blue" className="strong">{"Edad  " + getAge(rangoEdad_plataform) + "  Años"}</font></Tag> 
    }

    profile_user =
       <div className='container99 ' style={{marginTop:"2em",marginLeft:"48em"}}>
            <div className="rows">
              <h6 className='h6'>Completar perfil</h6><br/>
              <div>
              <font color = "violet" className="strong">{rfc2}</font> &nbsp;&nbsp;&nbsp;
              <font color = "violet" className="strong">{rs2}</font>
              </div>
              </div>

            <center>
              </center>
            <div style={{marginTop:"2%",width:"50%"}}>
            <form onSubmit={onSubmitBtnProfile}>
                <div className=''>
                <div className='rows'> 
                </div> 
                <div style={{marginTop:"2em"}} class="input-group input-group-icon">
                  <span className='strong'>Nombre (s) &nbsp;&nbsp;<font color="red">Campo fijo</font></span>
                  <input disabled placeholder={nombre_plataform} onChange={onChangeInputForm_nombre} id="nombre" type="text"required />
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span disabled className='strong'>Apellidos &nbsp;&nbsp;<font color="red">Campo fijo</font></span>
                  <input placeholder={apellidos_plataform}  onChange={onChangeInputForm_apellidos} id="apellidos" type="text"required/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">

                {age} 
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>correo &nbsp;&nbsp;<font color="red">Campo fijo</font></span>
                  <input disabled placeholder= {correo_plataform} onChange={onChangeInputForm_correo} id="correo" type="text"/>
                </div>&nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Teléfono <font color="red">*</font></span>
                  <input placeholder={telefono_plataform} onChange={onChangeInputForm_telefono} id="telefono" type="text"/>
                </div>
                <span className='strong'>Fecha de nacimiento <font color="red">*</font></span>
                <div className="input-group">
                  <DatePicker className="browser-default custom-select" onChange={dateOnChange} style={{height:"2.7rem",width:"21rem"}} required />
                </div>
                <div class="input-group input-group-icon">
                <span className='strong'>Código postal <font color="red">*</font></span>
                  <input placeholder= {cp_plataform}  onChange={onChangeInputForm_cp} id="cp" type="text"/>
                </div>
                <div class="input-group input-group-icon">
                <span className='strong'>Teléfono de la empresa <font color="red">*</font></span>
                  <input placeholder= {telefono_empresa_plataform} onChange={onChangeInputForm_telefono_empresa} id="telefono" type="text" />
                </div>
                &nbsp;&nbsp;
                <div class="input-group input-group-icon">
                <span className='strong'>Profesión <font color="red">*</font></span>
                  <input placeholder= {profesion_plataform} onChange={onChangeInputForm_profesion} id="profesion" type="text"/>
                </div>
                <div class="input-group">
                <span className='strong'>Género <font color="red">*</font></span>
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="genero"
                        id="genero"
                        required
                        onChange={onChangeInputForm_genero}
                      >
                        <option value="1">Seleccione una opción</option>
                        <option value="Hombre">Hombre</option>
                        <option value="Mujer">Mujer</option>                         
                        <option value="Otro">Otro</option> 
                      </select>
                    </div>
                </div>
               
                </div>
                <center>
                <div className='container33'>
                  <Button style={{width:"26rem"}} onClick={e=>this.cancelar()} type="danger">Cancelar</Button>&nbsp;&nbsp;&nbsp;
                  <Button
                    className="text-white"
                    onClick={e=>onSubmitBtnProfile(e)}
                    style={{width:"26rem",backgroundColor:" rgb(38,90,159)"}}
                  >
                    Aplicar
                  </Button>               
              </div>
              </center>
            </form>
    </div>
    </div>
  }

  let modalyt;
  if(yt === true){
    modalyt = <Modal footer={null} open={modal_open_yt} onOk = {e=>onOkyt()} onCancel ={e=>onOkyt() }>
      <center><h6>Comparte y no olvides suscribirte al canal</h6></center> 
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <YouTubeEmbed url={link_yt} width={525} height={220} />
      </div>
    </Modal>
  }
  let modalli;
  if(li === true){
    modalli = <Modal footer={null} open={modal_open_li} onOk = {e=>onOkli()} onCancel ={e=>onOkli() }>
      {title_modal}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <LinkedInEmbed 
        url={link_li}
        width={325}
        height={570} 
      />
    </div>

    </div>
    </Modal>
  }
  let card_inicio;
  let map;
  if(card === true && state){
    if(state[0]){
      map = state.map(({ id_courses, concepto, imagen, precio, descripcion, estatus,fb_link,insta_link,twiter_link,linked_link,youtube_link,args,hora_inicial,hora_final }) => {
        let tag;
        let fb;
        let tw;
        let yt;
        let lin;

        let post;
        if(estatus === "activo"){
          if(fb_link){
            fb =<a onClick={e=>sharefb(fb_link)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4267B2" class="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                </svg></a>
          }else{
            fb =
              <a target="blank" href="https://www.facebook.com/IMAImx/"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4267B2" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
              </svg></a>
          }
          if(twiter_link){
            tw =<a target="_blank" onClick={e=>sharetw(twiter_link)} ><svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
          }else{
            tw =<a target="_blank" href="https://twitter.com/imai_ac"><svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
          }
      
          if(youtube_link){
            yt =<a target="_blank" onClick={e=>shareyt(youtube_link)} ><svg width="25px" height="25px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="red" d="M14.712 4.633a1.754 1.754 0 00-1.234-1.234C12.382 3.11 8 3.11 8 3.11s-4.382 0-5.478.289c-.6.161-1.072.634-1.234 1.234C1 5.728 1 8 1 8s0 2.283.288 3.367c.162.6.635 1.073 1.234 1.234C3.618 12.89 8 12.89 8 12.89s4.382 0 5.478-.289a1.754 1.754 0 001.234-1.234C15 10.272 15 8 15 8s0-2.272-.288-3.367z"/><path fill="#ffffff" d="M6.593 10.11l3.644-2.098-3.644-2.11v4.208z"/></svg></a>
          }else{
            yt =<a target="_blank" href="https://www.youtube.com/channel/UCdyHevP0lR51a-AgKc0RjpA"><svg width="25px" height="25px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="red" d="M14.712 4.633a1.754 1.754 0 00-1.234-1.234C12.382 3.11 8 3.11 8 3.11s-4.382 0-5.478.289c-.6.161-1.072.634-1.234 1.234C1 5.728 1 8 1 8s0 2.283.288 3.367c.162.6.635 1.073 1.234 1.234C3.618 12.89 8 12.89 8 12.89s4.382 0 5.478-.289a1.754 1.754 0 001.234-1.234C15 10.272 15 8 15 8s0-2.272-.288-3.367z"/><path fill="#ffffff" d="M6.593 10.11l3.644-2.098-3.644-2.11v4.208z"/></svg></a>
          }
          if(linked_link){
            lin = <a onClick={e=>shareli(linked_link)}><svg fill="#000000" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"/></svg>  </a>
          }else{
            lin =<a target="_blank" href="https://www.linkedin.com/company/imai_ac/"><svg fill="#000000" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"/></svg>  </a>
          }

          tag = <Tag color="green">Curso activo</Tag>

          post = <Badge.Ribbon text="Compártelo" color="purple">
          <Card  size="small" style={{padding:"1em"}}>
            {fb}
              &nbsp;&nbsp;&nbsp;&nbsp;
            {tw}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {yt}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {lin}
          </Card>
        </Badge.Ribbon>       

        }
        if(id_courses){
        return (
          <div key={id_courses} className="itemContainer">
            <div className="imageContainer">
           
            <img className="image" src={imagen} alt=""></img>
              <Button style={{marginTop:"3rem",width:"18rem",backgroundColor:" rgb(38,90,159)"}} className="text-white" ghost  onClick={e=>logonForm(id_courses)}>Registrarme al curso</Button>
            </div>

            <div className="infoContainer">
              <div className="nameAndPriceContainer">
                    {tag}
                <h3>{concepto}</h3>
              </div>

              <div className="textContainer">{descripcion}</div>
              {post}

            </div>
          </div>
        );
        }
      })
    }
    card_inicio = <Card style={{marginLeft:"16rem",marginTop:"3rem"}}>
    <div>
    {ratingCard}
    <div className="itemsContainer" style={{width:"100rem"}}>
      {map}
    </div>
    </div>
    </Card>
  }
  let home;
  if(args === true){
    home = <div >
      <Content/>

    </div>
  }

  let logon_form;
  if(form === true){
    let fecha_nueva;
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    if(array.fecha_curso){
      let fecha = new Date(array.fecha_curso);
      fecha_nueva = fecha.toLocaleDateString("es-ES", options)
 
    }
    let youtube;
    if(array.youtube_link){
      youtube = <YouTubeEmbed url={array.youtube_link} width={440} height={220} />
    }

    let button;
    let costo;
    if(array.precio === '0'){
      costo = <Tag color="rgb(163,201,84)">Curso sin costo</Tag>
      button = <button
      class="button2"
      onClick={e=>onSubmitBtn}
      style={{width:"44rem"}} 
    >
      <span class="text">Registrarme</span>
      <span class="icon">
        <img src = {svg}/>
      </span>
    </button>
    }else{
      costo = <Tag color="rgb(24,46,88)">Costo del curso {array.precio} MXN</Tag>
      button = <button
            class="button2"
            onClick={e=>solicitar_cotizacion}
            style={{width:"44rem"}} 
          >
            <span class="text">Solicitar cotización</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="icon">
              <img src = {svg}/>
            </span>
          </button>
    }
    logon_form = <div class="container99" style={{marginTop:"7%",marginLeft:"35rem"}}>
    <h6 className="h4tag">Registrarse al {array.concepto}</h6>
   <Card title ={
    <div className="rows">
    <ul style={{marginTop:"3em"}}>

    <li className="strong">Expositor: {array.instructor}</li>
    <li className="strong">{array.descripcion}</li>

    <li className="strong">{array.add1}</li>
    <li className="strong">{array.add2}</li>
    <li className="strong">{array.add3}</li>
    </ul>
    <img src={array.imagen} width={270} />
    </div>
    } >

    <div className="rows">
    <p className="strong">Fecha del curso: <h6>{fecha_nueva} Horario del curso {array.hora_inicial} / {array.hora_final}</h6></p> {costo}
    </div>

    <div className="rows">
      <div>
      {youtube}
      <textarea className="strong" style={{marginTop:"2em",height:"350px",width:"440px"}}>{array.descripcion}</textarea>
      {costo}
      </div>
       <div style={{width:420}}>
      <form onSubmit={onSubmitBtn}>
      <div class="row">
        <div class="input-group input-group-icon">
          <input onChange={onChangeNombre} id="nombre" type="text" placeholder={nombre_plataform || "Nombre"}/>
          <div class="input-icon"><i class="fa fa-user"></i></div>
        </div>
        <div class="input-group input-group-icon">
          <input onChange={onChangeApellidos} id="apellidos" type="text" placeholder={apellidos_plataform || "Apellidos" } />
          <div class="input-icon"><i class="fa fa-user"></i></div>
        </div>
        <div class="input-group input-group-icon">
          <input onChange={onChangeCp} id="apellidos" type="number" placeholder={cp_plataform || "Código postal" }/>
          <div class="input-icon"><i class="fa fa-user"></i></div>
        </div>
        <div class="input-group input-group-icon">
          <input onChange={onChangeTelefono} id="telefono" type="number" placeholder={telefono_plataform || "Teléfono"}/>
          <div class="input-icon"><i class="fa fa-user"></i></div>
        </div>
        <div class="input-group input-group-icon">
          <input  onChange={onChangeCorreo} id="correo" type="email" placeholder="Correo"/>
          <div class="input-icon"><i class="fa fa-envelope"></i></div>
        </div>
        <div class="input-group input-group-icon">
        {button}
        </div>
        <Button style={{width:"44rem"}} onClick={e=>cancelar()} type="danger">Cancelar</Button>
      </div>
      
    </form>
    </div> 
    </div>
    
    </Card>   
  </div>
  }

  let cursos_exclusivos;
  let cursos_anteriores;

  if(nombre_user){
    cursos_exclusivos = <li class="item">
    <div href="#" class="nav_link submenu_item">
    <a href="#" class="nav_link" onClick={(e) => ShowItems("Exclusivos")}>
      <h6>
      <StarOutlined/>&nbsp;&nbsp;&nbsp;
      <span class="navlink" className="strong">Cursos exclusivos</span>
      </h6>
    </a>
    </div>
  </li>
   cursos_anteriores = <li class="item">
   <div href="#" class="nav_link submenu_item">
   <a href="#" class="nav_link" onClick={(e) => ShowItems("Anteriores")}>
    <h6>
    <FieldTimeOutlined />&nbsp;&nbsp;&nbsp;
     <span class="navlink" className="strong">Mis cursos</span>
     </h6>
   </a>
   </div>
 </li>
  }

  let sesion;
  let login;

  let perfil;

  if(nombre_user){
    sesion  = <a className="rows" target="_blank" onClick={e=>cerrar_sesion()}><h6 className="rows"><CaretRightFilled />&nbsp;&nbsp;&nbsp; 
    <p className="strong">Cerrar_sesión</p></h6>
    </a>

    perfil =<div> <a>
    <span class="navlink" className="strong">Perfil</span>
    </a>
    <hr class="dashed"/>
    <li class="item">
      <div href="#" class="nav_link submenu_item">
      <a href="#" class="nav_link" onClick={(e) => ShowItems("Profile")}>
        <h6>
        <UserOutlined /> &nbsp;&nbsp;&nbsp;
        <span class="navlink" className="strong">Perfil</span> 
        </h6>
      </a>
      </div>
    </li>
    </div> 
  }else{
    login = <a className="rows"  href="/login_plataform"><i class="fa fa-user"></i> &nbsp;&nbsp;&nbsp; 
    <p className="strong">Iniciar sesión</p>
    </a>
    titleData = <h6 className="strong">Plataforma de cursos</h6>;
    registrate = <a className="rows"  href="/signup_plataform_curses"><i class="fa fa-user"></i> &nbsp;&nbsp;&nbsp; 
    <p className="strong">Regístrate</p>
    </a> 
  }

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '/' + mm + '/' + yyyy;
  let fecha = formattedToday
  let completado =  localStorage.getItem("perfil_completado");
  let perfil_completado;

  if(completado === "false"){
    perfil_completado = <Tag color="red">Perfil sin completar</Tag>
  }if(completado === "true"){
    perfil_completado = <Tag color="rgb(171.200,101)">Perfil completado</Tag>
  }

   
  return (
    <div  style={{height:"100%"}} >
      <nav class="navbar" style={{backgroundColor:"rgb(242,240,254)"}}>
      <div class="logo_item">
        <img src={ads} width={650} alt=""/>
      </div>
      <div><h6 className="strong">{nombre_user + '' + apellidos_user}&nbsp;&nbsp;&nbsp; {correo_user}</h6></div>
      <div><h6><CalendarOutlined /> {fecha}</h6></div>
      <div><h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{rfc} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{rs}</h6></div>
      <div class="navbar_content">
        <i class="bi bi-grid"><h4 className="h4tag">{titleData} </h4></i>
      </div>
      <div>{valoracion} </div>
      <div>{perfil_completado}</div>
      <div>
  
      </div>
      <div className="rows">
      {registrate}&nbsp;&nbsp;&nbsp;
      {login}
      </div>
    
    </nav>
    <nav class="sidebar" style={{backgroundColor:"rgb(242,240,254)",marginTop:"2%"}}>
      <div class="menu_content">
      <div class="menu_title menu_dahsboard"></div>
        <ul class="menu_items">
          <li class="item">
            <div href="#" class="nav_link submenu_item">
              
              <a target="_blank" 
              onClick={(e) => ShowItems("All")}
              class="nav_link   sublink">       
                <h6>
                <HomeOutlined />  &nbsp;&nbsp;&nbsp;
                <span class="navlink" className="strong">Inicio</span>
                </h6>
              </a>
            </div>
          </li>
          <hr class="dashed"/>
          <a>
                <span class="navlink" className="strong">Cursos disponibles</span>
          </a>
          <hr class="dashed"/>
          <li class="item">
            <div href="#" class="nav_link submenu_item">
              <a target="_blank" 
              onClick={(e) => ShowItems("Productos")}
              class="nav_link   sublink"> 
                <h6> 
                <CloudOutlined />&nbsp;&nbsp;&nbsp;       
                <span class="navlink" className="strong">Nube</span>
                </h6>
              </a>
            </div>
          </li>
          <li class="item">
            <div href="#" class="nav_link submenu_item">
            <a href="#" class="nav_link" onClick={(e) => ShowItems("Comerciales")}>
              <h6>
              <DesktopOutlined />&nbsp;&nbsp;&nbsp; 
              <span class="navlink" className="strong">Escritorio</span>
              </h6>
            </a>
            </div>
          </li>
          <li class="item">
            <div href="#" class="nav_link submenu_item">
            <a href="#" class="nav_link" onClick={(e) => ShowItems("Nom 035")}>
              <h6>
              <DesktopOutlined />&nbsp;&nbsp;&nbsp; 
              <span class="navlink" className="strong">NOM 035</span>
              </h6>
            </a>
            </div>
          </li>
          {cursos_anteriores}
        </ul>
        <hr class="dashed"/>
        <a>
              <span class="navlink" className="strong">Nuestros productos</span>
        </a>
        <hr class="dashed"/>
        <ul class="menu_items">
         
          <li class="item">
            <div href="#" class="nav_link     submenu_item">
              <a target="_blank" href="https://plataforma.adscontigo.com" class="nav_link sublink">   
                <h6>    
                <QuestionOutlined />&nbsp;&nbsp;&nbsp;
                <span class="navlink" className="strong">ADSContigo</span>
                </h6>  
              </a>
            </div>
          </li>
          {/* <li class="item">
            <div href="#" class="nav_link       submenu_item">
              <a target="_blank" href="http://www.diagnostico035.com " class="nav_link   sublink"> 
                <h6> 
                <QuestionOutlined />&nbsp;&nbsp;&nbsp;
                <span class="navlink" className="strong">Diagnóstico035</span>
                </h6> 
              </a>
            </div>
          </li> */}
          {/* <hr class="dashed"/>
          <a>
              <span class="navlink" className="strong">Social</span>
          </a>
          <hr class="dashed"/>
          <li class="item">
            <div href="#" class="nav_link submenu_item">
            <a href="#" class="nav_link" onClick={(e) => ShowItems("Rating")}>
              <h6>
              <MessageOutlined /> &nbsp;&nbsp;&nbsp;
              <span class="navlink" className="strong">Comentarios</span>
              </h6>
            </a>
            </div>
          </li> */}
          <hr class="dashed"/>
          {perfil}
          <ul>
          <li class="item">
            <div href="#" class="nav_link     submenu_item">
              {sesion}
            </div>
          </li>
          </ul>
        </ul>
        
      </div>
    </nav>
      <div>
      {home}
      {card_inicio}
      <center>
      {profile_user}
      </center>
      {validation1}
      <center>
      {validation2}
      </center>
      {logon_form}
      {modal}
      {modaltw}
      {modalyt}
      {modalli}
      </div>
    </div>
  );
}

export default Menu;
