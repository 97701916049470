import axios from 'axios'
import { API, array } from '../Graphql/Graphql'

const sendSubmit = (id,nombre,apellidos,telefono,correo,cp,fecha,new_index,id_curse,concepto,descripcion) =>{
    return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    inscriptionCourse(data:"${[
                    id,
                    nombre,
                    apellidos,
                    telefono,
                    correo,
                    cp,
                    fecha,
                    new_index,
                    id_curse,
                    concepto,
                    descripcion
                    ]}"){         
                message
                } 
                }
                `,
            },
            })
            .then((response) => {
                console.log(response)
                try {
                resolve(response);
                } catch {
                reject("error");
                }
            })
            .catch((err) => {
                reject(err);
            });
    })
}

const auth_user = (user,pass) =>{
    return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    auth_user(data:"${[
                    user,
                    pass
                    ]}"){ 
                    id_auth        
                    Nombre
                    Apellidos
                    Puesto
                    email  
                    fk_empresa
                    token_admin
                    message
                } 
                }
                `,
            },
            })
            .then((response) => {
                console.log(response)
                try {
                resolve(response);
                } catch {
                reject("error");
                }
            })
            .catch((err) => {
                reject(err);
            });
    })
}

const register_user_course = (user,pass,nombre,apellidos,puesto) =>{
    return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    register_user_course(data:"${[
                    user,
                    pass,
                    nombre,
                    apellidos,
                    puesto
                    ]}"){ 
                    message
                } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
            })
            .catch((err) => {
                reject(err);
            });
    })
}
const get_courses_register = () =>{
    return new Promise((resolve,reject)=>{
    axios({
        url: API,
        method: "post",
        data: {
            query: `
            query{
                get_courses_register(data:"${[]}"){
                    id_register_courses
                    nombre
                    apellidos
                    telefono
                    correo
                    fecha_registro
                    cp    
                    id_courses
                    concepto
                    precio
                    descripcion
                    estatus
                    imagen
                    fk_contacto
                    fk_clientesads
                } 
            }
            `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
            //  this.setState({ modal:false });
        })
        .catch((err) => {
            reject(err);
        })
    })}

    const getCourses = () =>{
        return new Promise((resolve,reject)=>{

        axios({
            url: API,
            method: "post",
            data: {
                query: `
                query{
                    getCourses(data:"${[]}"){
                        id_courses
                        concepto
                        precio
                        descripcion
                        estatus
                        imagen
                        add1 
                        add2
                        add3
                        instructor
                        tipo
                        indice
                        habilitar
                        user_min
                        fb_link
                        insta_link
                        twiter_link
                        linked_link
                        youtube_link
                        fecha_curso
                        hora_inicial
                        hora_final
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const update_indice = (param,indice) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    update_indice(data:"${[indice,param]}"){
                        message
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const editar_modo = (param,id_curse) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    update_modo(data:"${[param,id_curse]}"){
                        message
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const activar_curso = (param) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    activar_curso(data:"${[param]}"){
                        message
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}

    const registrar_curso = (concepto,descripcion,url,encabezado1,encabezado2,encabezado3,instructor,tipo,modo,indice,ig,fb,tw,li,yt,fecha,rs,hora1,hora2,precio) =>{
        return new Promise((resolve,reject)=>{
            const lines = descripcion.trim().split("\n").filter(line => line.trim() !== "");
            const withoutEmptyLines = lines.join("\n");

            var arr = withoutEmptyLines.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    registrar_curso(
                        concepto:"${concepto}",descripcion:"${descripcion}",url:"${url}",
                        encabezado1:"${encabezado1}",encabezado2:"${encabezado2}",encabezado3:"${encabezado3}",
                        instructor:"${instructor}",tipo:"${tipo}",modo:"${modo}",
                        indice:"${indice}",ig:"${ig}",fb:"${fb}",
                        tw:"${tw}",li:"${li}", yt:"${yt}",
                        fecha:"${fecha}",rs:"${rs}",hora1:"${hora1}",hora2:"${hora2}",precio:"${precio}"
                    ){
                        message
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                console.log("err",err)
                console.log("error",err.response)
                reject(err);

            })
    })}
    const add_expositor = (nombre,apellidos,telefono,correo) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    add_expositor(data:"${[nombre,apellidos,telefono,correo]}"){
                        message
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}

    const get_expositor = () =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                query{
                    getExpositor(data:"${[]}"){
                        nombre
                        apellidos
                        telefono
                        correo
                        id_expositores
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const editar_expositor = (nombre,id_curso) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    editar_expositor(data:"${[nombre,id_curso]}"){
                        id_register_courses
                        nombre
                        apellidos
                        telefono
                        correo
                        fecha_registro
                        cp    
                        id_courses
                        concepto    
                        fk_courses
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const sendMailChangeExpositor = (args,concepto) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    sendMailChangeExpositor(data:"${[args,concepto]}"){
                        message    
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const addVideoPromocional = (url,descripcion) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    addVideoPromocional(data:"${[url,descripcion]}"){
                        id_video_promocional
                        url
                        descripcion
                        activo
                        message 
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}
    const desactivarVideoPromocional = (param) =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                mutation{
                    desactivarVideoPromocional(data:"${[param]}"){
                        message 
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}

    const getPromocional = () =>{
        return new Promise((resolve,reject)=>{
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                query{
                    getPromocional(data:"${[]}"){
                        url
                    } 
                }
                `,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
                //  this.setState({ modal:false });
            })
            .catch((err) => {
                reject(err);
            })
    })}

    const register_plataform_curse = (user,pass,nombre,apellidos,telefono,pass1) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        register_plataform_curse(data:"${[
                        user,
                        pass,
                        nombre,
                        apellidos,
                        telefono,
                        pass1
                        ]}"){ 
                        message
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }


    const auth_user_plataform = (user,pass) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        auth_user_plataform(data:"${[
                        user,
                        pass
                        ]}"){ 
                        id_users_plataform        
                        nombre
                        apellidos
                        telefono
                        correo 
                        telefono_empresa
                        cp
                        profesion
                        perfil_completado
                        message   
                        id_cliente
                        razonSocial
                        rfc
                        rango_edad
                        token
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    console.log("response",response)
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    const get_users_plataform = (user,pass) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_users_plataform(data:"${[
                        ]}"){ 
                        id_users_plataform        
                        nombre
                        apellidos
                        telefono
                        correo 
                        telefono_empresa
                        rango_edad
                        cp
                        genero
                        profesion
                        perfil_completado
                        message   
                        fecha_carga
                        estatus_aprobacion
                        url_cv
                        fk_users_plataform
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const finalizar_curso = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        finalizar_curso(data:"${[
                        param,
                        ]}"){ 
                        message   
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    console.log("response",response)
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const cursos_Anteriores = (user,pass) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        cursos_Anteriores(data:"${[
                        ]}"){   
                            id_courses
                            concepto
                            precio
                            descripcion
                            estatus
                            fecha_curso
                            hora_inicial
                            hora_final
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const getAllContactos = ( ) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        getAllContactos(data:"${[
                        ]}"){ 
                        id_contacto
                        nombre
                        apellidos
                        correo1
                        correo2 
                        telefono1
                        telefono2
                        puesto
                        fk_clientesads
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const getRegisterCourses = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        getRegisterCourses(data:"${[param
                        ]}"){ 
                        nombre
                        apellidos
                        telefono
                        correo
                        fk_courses    
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const getRegisterCoursesById = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        getRegisterCoursesById(data:"${[param
                        ]}"){ 
                        id_courses
                        concepto
                        descripcion
                        imagen
                        instructor
                        fecha_curso   
                         
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const update_profile = (nombre_, apellidos_, telefon_empresa, telefono_,correo_, cp_, fecha_nacimiento, genero, profesion) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        update_profile(data:"${[nombre_, apellidos_, telefono_,correo_,fecha_nacimiento,cp_, telefon_empresa, genero, profesion
                        ]}"){ 
                        message  
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
   
    const solicitar_cotizacion = (fecha_emision,fecha_Expiracion,folio,piezas,precio_unitario,iva,total_global,tipo_cotizacion,status,fk_usuario_plataforma,fk_curso,concepto) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        solicitar_cotizacion(data:"${[fecha_emision,fecha_Expiracion,folio,piezas,precio_unitario,iva,total_global,tipo_cotizacion,status,fk_usuario_plataforma,fk_curso,concepto
                        ]}"){ 
                        message  
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const get_cotizaciones = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_cotizaciones(data:"${[param]}"){ 
                            id_cotizaciones_cursos
                            fecha_emision 
                            fecha_expiracion
                            folio
                            piezas
                            precio_unitario
                            iva
                            total_global
                            tipo_cotizacion
                            estado
                            concepto
                            precio
                            imagen
                            fecha_curso
                            instructor
                            tipo
                            nombre
                            apellidos
                            correo
                            estatus_pago
                            fk_curso
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const get_cotizaciones_clientes = () =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_cotizaciones_clientes(data:"${[]}"){ 
                            id_cotizaciones_cursos
                            estatus_pago
                            fecha_emision 
                            fecha_expiracion
                            folio
                            piezas
                            precio_unitario
                            iva
                            total_global
                            tipo_cotizacion
                            estado
                            concepto
                            precio
                            imagen
                            fecha_curso
                            instructor
                            tipo
                            nombre
                            apellidos
                            correo
                            
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const ok_cotizacion = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        ok_cotizacion(data:"${[param
                        ]}"){ 
                        message  
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const registroSemblanza = (inputFolio,input1,textarea2,textarea3,input5,input6,input7,input8,input9,input10,id_course) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        registroSemblanza(folio:"${inputFolio}",titulo:"${input1}",objetivo:"${textarea2}",
                        perfil:"${textarea3}",temario1:"${input5}",temario2:"${input6}",
                        temario3:"${input7}",temario4:"${input8}",temario5:"${input9}",
                        temario6:"${input10}",id_course:"${id_course}"){ 
                        message  
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    const getSemblanza = () =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        getSemblanza(data:"${[]}"){ 
                           id
                           folio
                           titulo
                           objetivo
                           perfil
                           temario1
                           temario2
                           temario3
                           temario4
                           temario5
                           temario6
                           fk_courses
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const cursoPagado = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        cursoPagado(data:"${[param
                        ]}"){ 
                        message  
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const sendPdf = (param) =>{
        let año = new Date().getFullYear();
        function generateUUID() {
          var d = new Date().getTime();
          var uuid = "xAxxyx".replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          });
          return uuid;
        }
        let folio = (año + generateUUID()).toUpperCase();
        console.log("param",param)
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        insertUrlPdfVacant(data:"${[param.vacancyName,param.publicationDate,param.company,param.contact,param.url,folio
                        ]}"){ 
                        message  
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const get_vacantes = () =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_vacantes(data:"${[
                        ]}"){ 
                            id_vacantes
                            titulo_vacante
                            folio
                            fecha_publicacion
                            empresa_contratacion
                            correo_contacto
                            url_pdf  
                    } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    console.log("err",err.response)
                    reject(err);
                });
        })
    }
    const delete_vacante = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        delete_vacante(data:"${[param[1]
                        ]}"){ 
                        message  
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const sendCV = (param,param2) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        sendCV(data:"${[param,param2
                        ]}"){ 
                        message  
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const get_cv = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_cv(data:"${[param
                        ]}"){ 
                            fecha_carga
                            estatus_aprobacion
                            url_cv
                            fk_users_plataform
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    console.log("err",err.response)
                    reject(err);
                });
        })
    }
    const solicitar_vacante = (id_vacantes,id_users_plataform, nombre_plataform,apellidos_plataform,correo_plataform ,telefono_plataform,cv) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        solicitar_vacante(data:"${[id_vacantes,id_users_plataform, nombre_plataform,apellidos_plataform,correo_plataform ,telefono_plataform,cv
                        ]}"){ 
                        message  
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const get_all_cv = (param) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_all_cv(data:"${[param
                        ]}"){ 
                            id_cv
                            fecha_carga
                            estatus_aprobacion
                            url_cv
                            fk_users_plataform
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    console.log("err",err.response)
                    reject(err);
                });
        })
    }

    const aprobar_cv = (id_cv) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        aprobar_cv(data:"${[id_cv
                        ]}"){ 
                        message  
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const rechazar_cv = (id_cv) =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    mutation{
                        rechazar_cv(data:"${[id_cv
                        ]}"){ 
                        message  
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    const get_solicitud_vacantes = () =>{
        return new Promise((resolve,reject)=>{
            axios({
                url: API,
                method: "post",
                data: {
                    query: `
                    query{
                        get_solicitud_vacantes(data:"${[
                        ]}"){ 
                            id_solicitud_vacantes
                            fecha_solicitud
                            id_users_plataform
                            nombre
                            apellidos
                            telefono
                            correo
                            id_vacantes
                        } 
                    }
                    `,
                },
                })
                .then((response) => {
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch((err) => {
                    console.log("err",err.response)
                    reject(err);
                });
        })
    }
export default {
    get_solicitud_vacantes,
    rechazar_cv,
    aprobar_cv,
    get_all_cv,
    solicitar_vacante,
    get_cv,
    sendCV,
    delete_vacante,
    get_vacantes,
    sendPdf,
    cursoPagado,
    getSemblanza,
    registroSemblanza,
    ok_cotizacion,
    get_cotizaciones_clientes,
    get_cotizaciones,
    solicitar_cotizacion,
    update_profile,
    getRegisterCoursesById,
    getRegisterCourses,
    getAllContactos,
    cursos_Anteriores,
    finalizar_curso,
    get_users_plataform,
    auth_user_plataform,
    register_plataform_curse,
    getPromocional,
    desactivarVideoPromocional,
    addVideoPromocional,
    sendMailChangeExpositor,
    editar_expositor,
    get_expositor,
    add_expositor,
    registrar_curso,
    activar_curso,
    editar_modo,
    update_indice,
    getCourses,
    get_courses_register,
    register_user_course,
    auth_user,
    sendSubmit
}