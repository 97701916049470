    import React, { Component } from "react";
    import titulo1 from "../../imagen/imagent1.jpg";
    import { PDFExport } from "@progress/kendo-react-pdf";
    import document from "../../imagen/document.svg";

    const PageTemplate = (props) => {
    return (
        <div style={{ position: "absolute", bottom: "10px", width: 550 }}>
        <center>
            <p className="textabla3" color="black" style={{ marginBottom: 10 }}>
            Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600
            Ciudad de México. <br />
            Información, soporte y ventas: Conmutador:55 3603 9970 - 55 5553 2049
            </p>
        </center>
        </div>
    );
    };
    class ReportActualizacion extends Component {
    pdfExportComponent;
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { numActualizacion, fechaA, razonSocialEmpresa, actualizacion } =
        this.props;
        let report;
        report = (
        <div>
            <div class="input-group input-group-icon">
            <button
                style={{ width: "190px", height: "34px", margin: "2px" }}
                class="button2"
                onClick={(e) => this.pdfExportComponent.save()}
            >
                <span class="text">Descargar</span>
                <span class="icon">
                <img src={document}></img>
                </span>
            </button>
            </div>
            <div style={{ position: "absolute", left: "-5000px", top: 0 }}>
            <PDFExport
                paperSize="letter"
                margin="1cm"
                forcePageBreak=".page-break"
                fileName={
                "Reporte de productos y servicios " +
                numActualizacion +
                "° Actualización" +
                ".pdf"
                }
                pageTemplate={PageTemplate}
                ref={(component) => (this.pdfExportComponent = component)}
            >
                <div className="imageLogo">
                        <img
                        src={titulo1}
                        alt="imagen"
                        style={{ width: "25%", marginLeft: "-53%" }}
                        />
                <div
                    className="textabla3"
                    style={{ marginLeft: "60%", marginTop: "2%" }}
                >
                    <fort>
                    <strong>
                        {"Reporte de productos y servicios " +
                        numActualizacion +
                        "° Actualización"}
                    </strong>
                    </fort>
                    <br></br>
                    <fort>
                    <strong>Fecha:</strong>
                    {fechaA}
                    </fort>
                </div>
                <div style={{ width: "95%", marginLeft: "2%" }}>
                    <fort className="textabla1">
                    <strong>{razonSocialEmpresa}</strong>
                    </fort>
                </div>
                <div style={{ marginTop: "2%" }}>
                    <center>
                    <div>
                        <tabl e
                        class="table table-bordered table table-small table table-striped"
               
                        style={{ width: 555 }}
                        >
                        <thead>
                            <tr>
                            <th
                                width="10%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Clave</strong>
                            </th>
                            <th
                                width="10%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Tipo</strong>
                            </th>
                            <th
                                width="50%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Concepto</strong>
                            </th>
                            <th
                                width="10%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Precio</strong>
                            </th>
                            <th
                                width="15%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Fecha</strong>
                            </th>
                            <th
                                width="5%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Act.</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {actualizacion.map((rows) => {
                            const fechaF = new Date(rows.fechaRegistro);
                            var mesesF = new Array(
                                "Enero",
                                "Febrero",
                                "Marzo",
                                "Abril",
                                "Mayo",
                                "Junio",
                                "Julio",
                                "Agosto",
                                "Septiembre",
                                "Octubre",
                                "Noviembre",
                                "Diciembre"
                            );
                            let diaF = fechaF.getDate();
                            let mesSubstringF = fechaF.getMonth() + 1;
                            let posicionMesF = parseInt(mesSubstringF, 10);
                            let mesF = mesesF[posicionMesF - 1];
                            let añoF = fechaF.getFullYear();
                            let fechaRegistro = diaF + "/" + mesF + "/" + añoF;
                            let precioDosCeros = rows.precio.toLocaleString(
                                "en",
                                {
                                useGrouping: false,
                                minimumFractionDigits: 2,
                                }
                            );
                            let precio = precioDosCeros
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

                            return (
                                <tr>
                                <td
                                    style={{ padding: "2px" }}
                                    align="left"
                                    className="textabla3"
                                    width="10%"
                                >
                                    {rows.consecutivo}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="left"
                                    className="textabla3"
                                    width="10%"
                                >
                                    {rows.tipo}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="left"
                                    className="textabla3"
                                    width="50%"
                                >
                                    {rows.concepto}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="center"
                                    className="textabla3"
                                    width="10%"
                                >
                                    {"$" + precio}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="center"
                                    className="textabla3"
                                    width="15%"
                                >
                                    {fechaRegistro}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="center"
                                    className="textabla3"
                                    width="5%"
                                >
                                    {rows.id_actualizacion}
                                </td>
                                </tr>
                            );
                            })}
                        </tbody>
                        </tabl>
                    </div>
                    </center>
                </div>
                </div>
            </PDFExport>
            </div>
        </div>
        );
        return <div>{report}</div>;
    }
    }

    class ReportLista extends Component {
    pdfExportComponent1;
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { listactualizacionesProductos, fechaA, razonSocialEmpresa } =
        this.props;
        let reportLista = (
        <div>
            <div class="input-group input-group-icon">
            <button
                style={{ width: "190px", height: "34px", margin: "2px" }}
                class="button2"
                onClick={(e) => this.pdfExportComponent1.save()}
            >
                <span class="text">Descargar</span>
                <span class="icon">
                <img src={document}></img>
                </span>
            </button>
            </div>
            <div style={{ position: "absolute", left: "-5000px", top: 0 }}>
            <PDFExport
                paperSize="letter"
                margin="1cm"
                forcePageBreak=".page-break"
                fileName={"Reporte de Productos y Servicios Actualizados" + ".pdf"}
                pageTemplate={PageTemplate}
                ref={(component) => (this.pdfExportComponent1 = component)}
            >
                <div>
                <center>
                        <img
                        src={titulo1}
                        alt="imagen"
                        style={{ width: "40%", marginLeft: "-53%" }}
                        />
                </center>

                <div
                    className="textabla3"
                    style={{ marginLeft: "65%", marginTop: "2%" }}
                >
                    <fort>
                    <strong>
                        {"Reporte de Productos y Servicios por Actualización"}
                    </strong>
                    </fort>
                    <br></br>
                    <fort>
                    <strong>Fecha:</strong>
                    {fechaA}
                    </fort>
                </div>
                <div style={{ width: "95%", marginLeft: "2%" }}>
                    <fort className="textabla1">
                    <strong>{razonSocialEmpresa}</strong>
                    </fort>
                </div>
                <div style={{ marginTop: "2%" }}>
                    <center>
                    <div>
                        <table
                        class="table table-bordered table table-small table table-striped"
                        style={{ width: 555 }}
                        >
                        <thead>
                            <tr>
                            <th
                                width="10%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Clave</strong>
                            </th>
                            <th
                                width="10%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Tipo</strong>
                            </th>
                            <th
                                width="50%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Concepto</strong>
                            </th>
                            <th
                                width="10%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Precio</strong>
                            </th>
                            <th
                                width="15%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Fecha</strong>
                            </th>
                            <th
                                width="5%"
                                style={{ padding: "2px" }}
                                bgcolor="#05387A"
                                className="textabla3 text-white fuente"
                            >
                                <strong>Act.</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listactualizacionesProductos.map((rows) => {
                            const fechaF = new Date(rows.fechaRegistro);
                            var mesesF = new Array(
                                "Enero",
                                "Febrero",
                                "Marzo",
                                "Abril",
                                "Mayo",
                                "Junio",
                                "Julio",
                                "Agosto",
                                "Septiembre",
                                "Octubre",
                                "Noviembre",
                                "Diciembre"
                            );
                            let diaF = fechaF.getDate();
                            let mesSubstringF = fechaF.getMonth() + 1;
                            let posicionMesF = parseInt(mesSubstringF, 10);
                            let mesF = mesesF[posicionMesF - 1];
                            let añoF = fechaF.getFullYear();
                            let fechaRegistro = diaF + "/" + mesF + "/" + añoF;
                            let precioDosCeros = rows.precio.toLocaleString(
                                "en",
                                { useGrouping: false, minimumFractionDigits: 2 }
                            );
                            let precio = precioDosCeros
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                            return (
                                <tr>
                                <td
                                    style={{ padding: "2px" }}
                                    align="left"
                                    className="textabla3"
                                    width="10%"
                                >
                                    {rows.consecutivo}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="left"
                                    className="textabla3"
                                    width="10%"
                                >
                                    {rows.tipo}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="left"
                                    className="textabla3"
                                    width="50%"
                                >
                                    {rows.concepto}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="center"
                                    className="textabla3"
                                    width="10%"
                                >
                                    {"$" + precio}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="center"
                                    className="textabla3"
                                    width="15%"
                                >
                                    {fechaRegistro}
                                </td>
                                <td
                                    style={{ padding: "2px" }}
                                    align="center"
                                    className="textabla3"
                                    width="5%"
                                >
                                    {rows.id_actualizacion}
                                </td>
                                </tr>
                            );
                            })}
                        </tbody>
                        </table>
                    </div>
                    </center>
                </div>
                </div>
            </PDFExport>
            </div>
        </div>
        );
        return <div>{reportLista}</div>;
    }
    }

    export default { ReportActualizacion, ReportLista };
