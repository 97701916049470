import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'antd/dist/antd.css';
import actionsAPI from "./actionsAPI";
import { Card,Button} from 'antd';
import {
  MDBMask,
  MDBView,
  MDBAnimation,
} from "mdbreact";
import "./index.css";
import swal from "sweetalert";
import ads from '../imagen/logo_color_azul.png'
import { Apartment } from "@material-ui/icons";

class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            pass: '',
            isPasswordShown: false,
        }
      }
    componentWillMount(){
        localStorage.removeItem("id_auth")
        localStorage.removeItem("nombre_admin_courses")
        localStorage.removeItem("apellidos_admin_courses")
        localStorage.removeItem("puesto_admin_courses")
        localStorage.removeItem("email_admin_courses")
        localStorage.removeItem("fk_empresa")
        localStorage.removeItem("token_admin")
    }      

    onSubmitBtn(){
        actionsAPI.auth_user(this.state.user,this.state.pass).then(response=>{
            let dataUser = response.data.data.auth_user;
            console.log("dataUser",dataUser)

            console.log(dataUser)
            if(response.data.data.auth_user.message === "usuario encontrado"){
                localStorage.setItem("id_auth",dataUser.id_auth)
                localStorage.setItem("nombre_admin_courses",dataUser.Nombre)
                localStorage.setItem("apellidos_admin_courses",dataUser.Apellidos)
                localStorage.setItem("puesto_admin_courses",dataUser.Puesto)
                localStorage.setItem("email_admin_courses",dataUser.email)
                localStorage.setItem("fk_empresa",dataUser.fk_empresa)
                localStorage.setItem("token_admin",dataUser.token_admin)

                console.log("entro")
                swal({
                    text:`Bienvenido ${dataUser.Nombre} ${dataUser.Apellidos}`,               
                    icon:"success",
                });
                this.props.history.push("/dashboardCourses");
                
            }
            else if(response.data.data.auth_user.message === "usuario no encontrado"){
                console.log("entro")
                swal({
                    text:`Usuario y contraseña incorrectos`,               
                    icon:"error",
                });
            }
        }).catch(err=>{
            console.log(err)
            console.log(err.response)

        })
        console.log(this.state.user)
        console.log(this.state.pass)

    }
    onChangeInput =(e)=>{
    const {id,value} = e.target;
    this.setState({
        [id]:value
    })
    }

    render() {
    let titulo = <center><h4 className="h4tag strong">Iniciar sesión</h4></center>
    return(
    <div>
     <div id="apppage" >  
      <MDBView>
        <MDBMask >
        <div style={{marginTop:"3%"}} className="center">
            <div className="rows">
            <MDBAnimation type="fadeInRight" delay=".3s">
            <Card title = {titulo} style={{width:"110%",padding:"3em"}}>
            <div className="h5 text-center">
                <br/><br/>
                <center>
                <img  src ={ads} style={{width:180,height:60}}/>
                </center>
                <br/><br/>
            </div>
                <form onSubmit={this.onSubmitBtn}>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Usuario"                                  
                        id="user"
                        type="text"
                        name="user"
                        onChange={this.onChangeInput}
                        value={this.state.user}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Contraseña"                                  
                        id="pass"
                        type="password"
                        name="pass"
                        onChange={this.onChangeInput}
                        value={this.state.pass}
                    />
                    <div class="input-icon">
                    <i class="fa fa-key"></i>                  
                    </div>
                    </div>
                <div className="text-center mb-4 mt-5">
                    <Button
                    type='primary'
                    onClick={e=> this.onSubmitBtn()}
                    className='btn-block z-depth-1'
                    size="sm"
                    >
                    Ingresar
                    </Button>
                </div>
                </form>
                <font color="blue"><a href="/signup_course">No tienes una cuenta?, Créala aquí</a></font>
            </Card>
            </MDBAnimation>   
            </div>  
            </div>
        </MDBMask>
    </MDBView>
    </div>
    </div>
  )        
}
}

export default Login;