
import React, { Component} from 'react';
import {Button,Card} from 'antd'
import titulo1 from  '../imagen/imagent1.jpg';
import { PDFExport } from '@progress/kendo-react-pdf';
import './index.css'
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import swal from 'sweetalert'
import { storage } from "./firebase";
import 'antd/dist/antd.css'
import 'antd-button-color/dist/css/style.css'
import { Input, Spin  } from 'antd';
import cheque from '../imagen/cheque.png'
import not from '../imagen/cerrar.png'
import actions from './transactionsAPI'

const { TextArea } = Input;

const PageTemplate = (props) => {
    return (
      <div  style={{position:"absolute",bottom:"10px",width:550}}>
      <center>  
      <p  className = "strong textabla3" color="black" style = {{marginBottom:10,marginTop: 60}}>Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México.  <br/>
      Información, soporte y ventas: Conmutador:55 3603 9970 - 55 5553 2049</p>
      </center>
      </div>
    );
  };

class PDFReport extends Component {
    pdfExportComponent
    constructor(props) {
        super(props);
        this.state = {
            botonPdfExport:false,
            enviarEmail:false,
            valorServicio:"",
            valorProducto:[], 
            valorCapacitacion:"",
            nuevoValorArray:[],  
            fileList:[],
            uploading:false, 
            fileUopload:false,    
            image:null,
            url:"",
            imagesCot:null,
            images2:null,
            images3:null,
            images4:null,
            images5:null,
            urls2:"",
            urls4:"",
            urls5:"",
            progress:0,
            cargando:"",
            mensaje:"",
            mensaje2:"",
            adjuntar2:"",
            adjuntar3:"",
            adjuntar4:"",
            adjuntar:false,
            disabledButton:"disabled",
            renderizado:true,
            comentarios:'',
            botonCerrarEnvio:false,
            disabledInput:false,
            validarArchivos:false

          }
          this.onChangeTextArea = this.onChangeTextArea.bind(this)
    }

  toggleDenseTable = event => {
    this.setState({
      denseTable: event.target.checked,
    });
  };

  toggleDenseTable = event => {
    this.setState({
      denseTable: event.target.checked,
    });
  };
  onSubmitBtn = async (botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa)=>{   
    let array0 = []
    let array1 = []
    let array2 = []
    let array3 = []
    let array4 = []
    let array5 = []
    let array6 = []
    let array7 = []
    let tofixedDescuento;   
    let tofixedTotalPrecioProducto;   
    let idAdminAlfa = localStorage.getItem("id_admin")
    let idEmpresa = localStorage.getItem("fk_empresa")
    let folio = cotizaciones[0].NumFolio
    let subtotal = totales.subtotal.toFixed(2);
    let iva  = totales.iva.toFixed(2);
    let total = totales.total.toFixed(2);
    let idCliente = datosCliente.id_cliente 
    let fechaExpiracion = cotizaciones[0].fechaExpiracion
    let id_contacto = dataContacto.id_contacto
    let tipoSolicitud = cotizaciones[0].tipoSolicitud
    let fecha = cotizaciones[0].fechaEmision   
    let descuentoAplicado ;
    let TotalPrecioProducto;
    let cantidad;
    let descuento;
    let id_productoServicio;
   
    this.setState({botonPdfExport:true}) 

    productoServicioByFolio.map(rows=>{
      array0.push({"id":rows.id_productoServicio})
    })
    productoServicioByFolio.map(rows=>{ 
      array1.push({"tipo":rows.tipo})
    })
    productoServicioByFolio.map(rows=>{
      array2.push({"concepto":rows.concepto})
    })
    
    cotizaciones.map(rows=>{
      array3.push({"cantidad":rows.cantidad})
    }) 
    productoServicioByFolio.map(rows=>{      
      array4.push({"precio":rows.precio})
    })
    cotizaciones.map(rows =>{
      array5.push({"descuento":rows.descuento})
    })
    cotizaciones.map(rows=>{
      tofixedDescuento = parseFloat(rows.descuentoAplicado).toFixed(2)      
      array6.push({"descuentoAplicado": tofixedDescuento})
    })
    cotizaciones.map(rows=>{ 
      tofixedTotalPrecioProducto = rows.TotalPrecioProducto.toFixed(2)
      array7.push({"TotalPrecioProducto":tofixedTotalPrecioProducto})
    })  
   
    let arr = array0.map((item, i) => Object.assign({}, item, array1[i]));
    let arr1 = array2.map((item, i) => Object.assign({}, item, arr[i]));
    let arr2 = array3.map((item, i) => Object.assign({}, item, arr1[i]));
    let arr3 = array4.map((item, i) => Object.assign({}, item, arr2[i]));
    let arr4 = array5.map((item, i) => Object.assign({}, item, arr3[i]));
    let arr5 = array6.map((item, i) => Object.assign({}, item, arr4[i]));
    let arr6 = array7.map((item,i) => Object.assign({}, item, arr5[i]));  
  
    arr6.map(rows=>{
        descuentoAplicado = rows.descuentoAplicado   
        TotalPrecioProducto = rows.TotalPrecioProducto
        cantidad = rows.cantidad
        descuento = rows.descuento
        id_productoServicio = rows.id
        actions.insertCotizaciones(fecha,folio,cantidad,descuento,descuentoAplicado,TotalPrecioProducto,idCliente,id_productoServicio,idAdminAlfa,idEmpresa,fechaExpiracion,id_contacto,tipoSolicitud).then(response=>{
          if(response.data.data.insertCotizaciones.message === "registro exitoso"){
            swal({
              text:"Cotización registrada",
              buttons: false,
              icon:'success'
            });     
          }else{
            swal({
              text:"Algo salió mal",
              buttons: false,
              icon:'error'
            });      
          }
        }).catch(err=>{
          console.log('error',err)
        }) 
        })   
        actions.insertTotales(folio,subtotal,iva,total).then(response=>{

        }).catch(err=>{
          console.log(err)
        })
     
    }
    ejecutarEnvio(){
        this.pdfExportComponent.save()
        this.setState({fileUopload:true})
        this.setState({renderizado:false})
    }
    cancelarEnvioEmail(){
      this.setState({fileUopload:false})
      this.setState({renderizado:true})
      this.setState({progress:0})
      this.setState({image:null})
      this.setState({images2:null})
      this.setState({images3:null})
      this.setState({images4:null})

      this.setState({botonCerrarEnvio:false})
      this.setState({adjuntar:false})
      this.setState({adjuntar2:false})
      this.setState({adjuntar3:false})
      this.setState({adjuntar4:false})

      this.setState({enviarEmail:false})
      this.setState({disabledInput:false})
      this.setState({validarArchivos:false})
      this.setState({spin:false})
      let folio = this.props.cotizaciones[0].NumFolio
      actions.deleteFileTemporal(folio).then(response=>{
        console.log(response)
      })
    }
    handleChange = e => {
    if (e.target.files[0]) {
      this.setState({enviarEmail:true})
      this.setState({adjuntar:true})
      this.setState({image:e.target.files[0]})
    }
  };
  handleChange2 = e => {
    if (e.target.files[0]) {
      this.setState({adjuntar2:true})
      this.setState({images2:e.target.files[0]})
    }
  };
  handleChange3 = e => {
    this.setState({adjuntar3:true})
    if (e.target.files[0]) {
      this.setState({images3:e.target.files[0]})
    }
  };
  handleChange4 = e => {
    this.setState({adjuntar4:true})
    if (e.target.files[0]) {
      this.setState({images4:e.target.files[0]})
    }
  };
  handleChange5 = e => {
    if (e.target.files[0]) {
      this.setState({images5:e.target.files[0]})
    }
  };
  onChangeTextArea(e){
    this.setState({comentarios:e.target.value.replace(/,/g, "")})
  }
  handleUpload = async () => {
    this.setState({validarArchivos:true})
    this.setState({spin:true})
    if(this.state.image){
      if(this.state.images2){
        if(this.state.images3){
          if(this.state.images4){
            if(this.state.images4){
              if((this.state.image.name !== this.state.images2.name) && (this.state.image.name !== this.state.images3.name) && (this.state.images2.name !== this.state.images3.name) && (this.state.images3.name !== this.state.images4.name) && (this.state.images2.name !== this.state.images4.name) && (this.state.image.name !== this.state.images4.name)&& (this.state.image.name !== this.state.images5.name) && (this.state.images2.name !== this.state.images5.name)&& (this.state.images3.name !== this.state.images5.name)&& (this.state.images4.name !== this.state.images5.name) ){
                  const uploadTask = storage.ref(`images/${this.state.image.name}`).put(this.state.image);
                  uploadTask.on(
                    "state_changed",
                    snapshot => {
                      const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
                      this.setState({progress:progress});
                    },
                    error => {
                      console.log("error" , error);
                    },
                    () => {
                      storage
                        .ref("images")
                        .child(this.state.image.name)
                        .getDownloadURL()
                        .then(url => {
                          if(url){
                            let folio = this.props.cotizaciones[0].NumFolio
                              axios({
                                  url:  API,
                                  method:'post',
                                  data:{
                                  query:`
                                  mutation{
                                  insertUrlTemporal(data:"${[this.state.image.name,folio,url,this.state.comentarios]}"){
                                  message
                                          }
                                      }
                                      `
                                  }
                              })
                              .then(datos => {	
                                console.log("datos1",datos)
                                setTimeout(()=>{
                                  this.setState({spin:false})
                                  this.setState({disabledInput:true});
                                  this.setState({botonCerrarEnvio:true})
                                  this.setState({enviarEmail:false})
                                },5000)
                              }).catch(err=>{
                                  console.log("error" , err)
                              })
                              this.setState({url:url});
                              this.setState({disabledButton:""});
                      }
                        });
                    }
                  );
                  const uploadTasks2 = storage.ref(`images2/${this.state.images2.name}`).put(this.state.images2);
                  uploadTasks2.on(
                    "state_changed",
                    snapshot => {
                      const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
                      this.setState({progress:progress});
                    },
                    error => {
                      console.log("error" , error);
                    },
                    () => {
                      storage
                        .ref("images2")
                        .child(this.state.images2.name)
                        .getDownloadURL()
                        .then(urls2 => {
                          if(urls2){
                            let folio = this.props.cotizaciones[0].NumFolio
                            axios({
                                url:  API,
                                method:'post',
                                data:{
                                query:`
                                mutation{
                                insertUrlTemporal(data:"${[this.state.images2.name,folio,urls2,this.state.comentarios]}"){
                                message
                                        }
                                    }
                                    `
                                }
                            })
                            .then(datos => {	
                              console.log("datos2",datos)
                            }).catch(err=>{
                                console.log("error" , err)
                            })
                      }
                        });
                    }
                  );
                  const uploadTasks3 = storage.ref(`images3/${this.state.images3.name}`).put(this.state.images3);
                  uploadTasks3.on(
                    "state_changed",
                    snapshot => {
                      const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
                      this.setState({progress:progress});
                    },
                    error => {
                      console.log("error" , error);
                    },
                    () => {
                    storage
                      .ref("images3")
                      .child(this.state.images3.name)
                      .getDownloadURL()
                      .then(urls3 => {
                      if(urls3){
                        let folio = this.props.cotizaciones[0].NumFolio
                            axios({
                                url:  API,
                                method:'post',
                                data:{
                                query:`
                                mutation{
                                insertUrlTemporal(data:"${[this.state.images3.name,folio,urls3,this.state.comentarios]}"){
                                message
                                        }
                                    }
                                    `
                                }
                            })
                            .then(datos => {	
                              console.log("datos3",datos)
          
                            }).catch(err=>{
                                console.log("error" , err)
                            })
                          this.setState({cargando:""});
                          this.setState({urls3:urls3});
                          this.setState({disabledButton:""});
                        }
                      });
                  }
                );
                const uploadTasks4 = storage.ref(`images4/${this.state.images4.name}`).put(this.state.images4);
                  uploadTasks4.on(
                    "state_changed",
                    snapshot => {
                      const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
                      this.setState({progress:progress});
                    },
                    error => {
                      console.log("error" , error);
                    },
                    () => {
                    storage
                      .ref("images4")
                      .child(this.state.images4.name)
                      .getDownloadURL()
                      .then(urls4 => {
                      if(urls4){
                        let folio = this.props.cotizaciones[0].NumFolio
                            axios({
                                url:  API,
                                method:'post',
                                data:{
                                query:`
                                mutation{
                                insertUrlTemporal(data:"${[this.state.images4.name,folio,urls4,this.state.comentarios]}"){
                                message
                                        }
                                    }
                                    `
                                }
                            })
                            .then(datos => {	
                              console.log("datos3",datos)
          
                            }).catch(err=>{
                                console.log("error" , err)
                            })
                          this.setState({cargando:""});
                          this.setState({urls4:urls4});
                          this.setState({disabledButton:""});
                        }
                      });
                  }
                );
                const uploadTasks5 = storage.ref(`images5/${this.state.images5.name}`).put(this.state.images5);
                uploadTasks5.on(
                  "state_changed",
                  snapshot => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress:progress});
                  },
                  error => {
                    console.log("error" , error);
                  },
                  () => {
                  storage
                    .ref("images5")
                    .child(this.state.images5.name)
                    .getDownloadURL()
                    .then(urls5 => {
                    if(urls5){
                      let folio = this.props.cotizaciones[0].NumFolio
                          axios({
                              url:  API,
                              method:'post',
                              data:{
                              query:`
                              mutation{
                              insertUrlTemporal(data:"${[this.state.images5.name,folio,urls5,this.state.comentarios]}"){
                              message
                                      }
                                  }
                                  `
                              }
                          })
                          .then(datos => {	
                            console.log("datos5",datos)
                          }).catch(err=>{
                              console.log("error" , err)
                          })
                        this.setState({cargando:""});
                        this.setState({urls4:urls5});
                        this.setState({disabledButton:""});
                      }
                    });
                }
              );
                } else{
                  this.setState({spin:false})
                  swal({
                    text:"El archivo de cotización o el adjunto 1 no puede ser el mismo al documento adjunto 2",
                    buttons: false,
                    icon:'warning'
                  });   
                  setTimeout(()=>{
      
                    this.setState({spin:false})
                    this.setState({disabledInput:false});
                    this.setState({botonCerrarEnvio:false})
                    this.setState({validarArchivos:false})
      
                    this.setState({enviarEmail:true})
                  },5000)
                } 
              }else{
              if((this.state.image.name !== this.state.images2.name) && (this.state.image.name !== this.state.images3.name) && (this.state.images2.name !== this.state.images3.name) && (this.state.images3.name !== this.state.images4.name) && (this.state.images2.name !== this.state.images4.name) && (this.state.image.name !== this.state.images4.name) ){
                const uploadTask = storage.ref(`images/${this.state.image.name}`).put(this.state.image);
                uploadTask.on(
                  "state_changed",
                  snapshot => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress:progress});
                  },
                  error => {
                    console.log("error" , error);
                  },
                  () => {
                    storage
                      .ref("images")
                      .child(this.state.image.name)
                      .getDownloadURL()
                      .then(url => {
                        if(url){
                          let folio = this.props.cotizaciones[0].NumFolio
                            axios({
                                url:  API,
                                method:'post',
                                data:{
                                query:`
                                mutation{
                                insertUrlTemporal(data:"${[this.state.image.name,folio,url,this.state.comentarios]}"){
                                message
                                        }
                                    }
                                    `
                                }
                            })
                            .then(datos => {	
                              console.log("datos1",datos)
                              setTimeout(()=>{
                                this.setState({spin:false})
                                this.setState({disabledInput:true});
                                this.setState({botonCerrarEnvio:true})
                                this.setState({enviarEmail:false})
                              },5000)
                            }).catch(err=>{
                                console.log("error" , err)
                            })
                            this.setState({url:url});
                            this.setState({disabledButton:""});
                    }
                      });
                  }
                );
                const uploadTasks2 = storage.ref(`images2/${this.state.images2.name}`).put(this.state.images2);
                uploadTasks2.on(
                  "state_changed",
                  snapshot => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress:progress});
                  },
                  error => {
                    console.log("error" , error);
                  },
                  () => {
                    storage
                      .ref("images2")
                      .child(this.state.images2.name)
                      .getDownloadURL()
                      .then(urls2 => {
                        if(urls2){
                          let folio = this.props.cotizaciones[0].NumFolio
                          axios({
                              url:  API,
                              method:'post',
                              data:{
                              query:`
                              mutation{
                              insertUrlTemporal(data:"${[this.state.images2.name,folio,urls2,this.state.comentarios]}"){
                              message
                                      }
                                  }
                                  `
                              }
                          })
                          .then(datos => {	
                            console.log("datos2",datos)
                          }).catch(err=>{
                              console.log("error" , err)
                          })
                    }
                      });
                  }
                );
                const uploadTasks3 = storage.ref(`images3/${this.state.images3.name}`).put(this.state.images3);
                uploadTasks3.on(
                  "state_changed",
                  snapshot => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress:progress});
                  },
                  error => {
                    console.log("error" , error);
                  },
                  () => {
                  storage
                    .ref("images3")
                    .child(this.state.images3.name)
                    .getDownloadURL()
                    .then(urls3 => {
                    if(urls3){
                      let folio = this.props.cotizaciones[0].NumFolio
                          axios({
                              url:  API,
                              method:'post',
                              data:{
                              query:`
                              mutation{
                              insertUrlTemporal(data:"${[this.state.images3.name,folio,urls3,this.state.comentarios]}"){
                              message
                                      }
                                  }
                                  `
                              }
                          })
                          .then(datos => {	
                            console.log("datos3",datos)
        
                          }).catch(err=>{
                               console.log("error" , err)
                          })
                        this.setState({cargando:""});
                        this.setState({urls3:urls3});
                        this.setState({disabledButton:""});
                      }
                    });
                }
              );
              const uploadTasks4 = storage.ref(`images4/${this.state.images4.name}`).put(this.state.images4);
                uploadTasks4.on(
                  "state_changed",
                  snapshot => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress:progress});
                  },
                  error => {
                    console.log("error" , error);
                  },
                  () => {
                  storage
                    .ref("images4")
                    .child(this.state.images4.name)
                    .getDownloadURL()
                    .then(urls4 => {
                    if(urls4){
                      let folio = this.props.cotizaciones[0].NumFolio
                          axios({
                              url:  API,
                              method:'post',
                              data:{
                              query:`
                              mutation{
                              insertUrlTemporal(data:"${[this.state.images4.name,folio,urls4,this.state.comentarios]}"){
                              message
                                      }
                                  }
                                  `
                              }
                          })
                          .then(datos => {	
                            console.log("datos3",datos)
        
                          }).catch(err=>{
                               console.log("error" , err)
                          })
                        this.setState({cargando:""});
                        this.setState({urls4:urls4});
                        this.setState({disabledButton:""});
                      }
                    });
                }
              );
              } else{
                this.setState({spin:false})
                swal({
                  text:"El archivo de cotización o el adjunto 1 no puede ser el mismo al documento adjunto 2",
                  buttons: false,
                  icon:'warning'
                });   
                setTimeout(()=>{
    
                  this.setState({spin:false})
                  this.setState({disabledInput:false});
                  this.setState({botonCerrarEnvio:false})
                  this.setState({validarArchivos:false})
    
                  this.setState({enviarEmail:true})
                },5000)
              } 
            }
          }else{
            if((this.state.image.name !== this.state.images2.name) && (this.state.image.name !== this.state.images3.name) && (this.state.images2.name !== this.state.images3.name) ){
              const uploadTask = storage.ref(`images/${this.state.image.name}`).put(this.state.image);
              uploadTask.on(
                "state_changed",
                snapshot => {
                  const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  this.setState({progress:progress});
                },
                error => {
                  console.log("error" , error);
                },
                () => {
                  storage
                    .ref("images")
                    .child(this.state.image.name)
                    .getDownloadURL()
                    .then(url => {
                      if(url){
                        let folio = this.props.cotizaciones[0].NumFolio
                          axios({
                              url:  API,
                              method:'post',
                              data:{
                              query:`
                              mutation{
                              insertUrlTemporal(data:"${[this.state.image.name,folio,url,this.state.comentarios]}"){
                              message
                                      }
                                  }
                                  `
                              }
                          })
                          .then(datos => {	
                            console.log("datos1",datos)
                            setTimeout(()=>{
                              this.setState({spin:false})
                              this.setState({disabledInput:true});
                              this.setState({botonCerrarEnvio:true})
                              this.setState({enviarEmail:false})
                            },5000)
                          }).catch(err=>{
                              console.log("error" , err)
                          })
                          this.setState({url:url});
                          this.setState({disabledButton:""});
                  }
                    });
                }
              );
              const uploadTasks2 = storage.ref(`images2/${this.state.images2.name}`).put(this.state.images2);
              uploadTasks2.on(
                "state_changed",
                snapshot => {
                  const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  this.setState({progress:progress});
                },
                error => {
                  console.log("error" , error);
                },
                () => {
                  storage
                    .ref("images2")
                    .child(this.state.images2.name)
                    .getDownloadURL()
                    .then(urls2 => {
                      if(urls2){
                        let folio = this.props.cotizaciones[0].NumFolio
                        axios({
                            url:  API,
                            method:'post',
                            data:{
                            query:`
                            mutation{
                            insertUrlTemporal(data:"${[this.state.images2.name,folio,urls2,this.state.comentarios]}"){
                            message
                                    }
                                }
                                `
                            }
                        })
                        .then(datos => {	
                          console.log("datos2",datos)
                        }).catch(err=>{
                            console.log("error" , err)
                        })
                  }
                    });
                }
              );
              const uploadTasks3 = storage.ref(`images3/${this.state.images3.name}`).put(this.state.images3);
              uploadTasks3.on(
                "state_changed",
                snapshot => {
                  const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  this.setState({progress:progress});
                },
                error => {
                  console.log("error" , error);
                },
                () => {
                storage
                  .ref("images3")
                  .child(this.state.images3.name)
                  .getDownloadURL()
                  .then(urls3 => {
                  if(urls3){
                    let folio = this.props.cotizaciones[0].NumFolio
                        axios({
                            url:  API,
                            method:'post',
                            data:{
                            query:`
                            mutation{
                            insertUrlTemporal(data:"${[this.state.images3.name,folio,urls3,this.state.comentarios]}"){
                            message
                                    }
                                }
                                `
                            }
                        })
                        .then(datos => {	
                          console.log("datos3",datos)
      
                        }).catch(err=>{
                             console.log("error" , err)
                        })
                      this.setState({cargando:""});
                      this.setState({urls3:urls3});
                      this.setState({disabledButton:""});
                    }
                  });
              }
            );
            } else{
              this.setState({spin:false})
              swal({
                text:"El archivo de cotización o el adjunto 1 no puede ser el mismo al documento adjunto 2",
                buttons: false,
                icon:'warning'
              });   
              setTimeout(()=>{
  
                this.setState({spin:false})
                this.setState({disabledInput:false});
                this.setState({botonCerrarEnvio:false})
                this.setState({validarArchivos:false})
  
                this.setState({enviarEmail:true})
              },5000)
            } 
          }
        }else{
          if(this.state.images2.name !== this.state.image.name){
            const uploadTask = storage.ref(`images/${this.state.image.name}`).put(this.state.image);
              uploadTask.on(
                "state_changed",
                snapshot => {
                  const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  this.setState({progress:progress});
                },
                error => {
                  console.log("error" , error);
                },
                () => {
                  storage
                    .ref("images")
                    .child(this.state.image.name)
                    .getDownloadURL()
                    .then(url => {
                      if(url){
                        let folio = this.props.cotizaciones[0].NumFolio
                          axios({
                              url:  API,
                              method:'post',
                              data:{
                              query:`
                              mutation{
                              insertUrlTemporal(data:"${[this.state.image.name,folio,url,this.state.comentarios]}"){
                              message
                                      }
                                  }
                                  `
                              }
                          })
                          .then(datos => {	
                            console.log("datos1",datos)
                            setTimeout(()=>{
                              this.setState({spin:false})
                              this.setState({disabledInput:true});
                              this.setState({botonCerrarEnvio:true})
                              this.setState({enviarEmail:false})
                            },5000)
                          }).catch(err=>{
                              console.log("error" , err)
                          })
                          this.setState({url:url});
                          this.setState({disabledButton:""});
                  }
                    });
                }
              );
            const uploadTasks2 = storage.ref(`images2/${this.state.images2.name}`).put(this.state.images2);
            uploadTasks2.on(
              "state_changed",
              snapshot => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({progress:progress});
              },
              error => {
                console.log("error" , error);
              },
              () => {
                storage
                  .ref("images2")
                  .child(this.state.images2.name)
                  .getDownloadURL()
                  .then(urls2 => {
                    if(urls2){
                      let folio = this.props.cotizaciones[0].NumFolio
                      axios({
                          url:  API,
                          method:'post',
                          data:{
                          query:`
                          mutation{
                          insertUrlTemporal(data:"${[this.state.images2.name,folio,urls2,this.state.comentarios]}"){
                          message
                                  }
                              }
                              `
                          }
                      })
                      .then(datos => {	
                        console.log("datos2",datos)
                      }).catch(err=>{
                           console.log("error" , err)
                      })
                }
                });
              }
            );
          }else{
            this.setState({spin:false})
            swal({
              text:"El archivo de cotización no puede ser el mismo al documento adjunto 1",
              buttons: false,
              icon:'warning'
            });   
            setTimeout(()=>{
              this.setState({validarArchivos:false})
              this.setState({spin:false})
              this.setState({disabledInput:false});
              this.setState({botonCerrarEnvio:false})
              this.setState({enviarEmail:true})
            },5000)
          }
        }
        }else{  
          const uploadTask = storage.ref(`images/${this.state.image.name}`).put(this.state.image);
          uploadTask.on(
            "state_changed",
            snapshot => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              this.setState({progress:progress});
            },
            error => {
              console.log("error" , error);
            },
            () => {
              storage
                .ref("images")
                .child(this.state.image.name)
                .getDownloadURL()
                .then(url => {
                  if(url){
                    let folio = this.props.cotizaciones[0].NumFolio
                    actions.insertUrlTemporal(this.state.image.name,folio,url,this.state.comentarios).then(response=>{
                      setTimeout(()=>{
                        this.setState({spin:false})
                        this.setState({disabledInput:true});
                        this.setState({botonCerrarEnvio:true})
                        this.setState({enviarEmail:false})
                      },7000)
                    }).catch(err=>{
                      console.log("error" , err)
                    })
                      this.setState({url:url});
                      this.setState({disabledButton:""});
                }
              });
            }
          );
        }
      } 
  };


  enviarEmail(){
    const nombreAdmin = localStorage.getItem("nombre");
    const apellidoAdmin = localStorage.getItem("apellido")
    const correoAdmin = localStorage.getItem("correo")
    let folio = this.props.cotizaciones[0].NumFolio
    let rfc = this.props.datosCliente.rfc
    let correoContacto = this.props.dataContacto.correo1
    let telefono = this.props.adminAlfa.telefono
    let extension = this.props.adminAlfa.extensionTelefonica

   actions.getUrlPdfFile(folio).then(response=>{ 
        let dataUrl = response.data.data.getUrlPdfFile;
        console.log(dataUrl,"dataUrl")
        if(dataUrl[0]){
          if(dataUrl[1]){
              if(dataUrl[2]){
                if(dataUrl[3]){
                  if(dataUrl[4]){
                    let id_url1 = dataUrl[0].id_url
                    let id_url2 = dataUrl[1].id_url
                    let id_url3 = dataUrl[2].id_url
                    let id_url4 = dataUrl[3].id_url
                    let id_url5 = dataUrl[4].id_url

                    let nombreArchivo1 = dataUrl[0].nombreArchivo
                    let nombreArchivo2 = dataUrl[1].nombreArchivo
                    let nombreArchivo3 = dataUrl[2].nombreArchivo
                    let nombreArchivo4 = dataUrl[3].nombreArchivo
                    let nombreArchivo5 = dataUrl[4].nombreArchivo

                    let url1 = dataUrl[0].url
                    let url2 = dataUrl[1].url
                    let url3 = dataUrl[2].url
                    let url4 = dataUrl[3].url
                    let url5 = dataUrl[4].url
  
                    let comentarios1 = dataUrl[0].comentarios
                    let comentarios;
                    if(comentarios1 === "sin comentarios"){
                      comentarios = '';
                    }else{
                      comentarios =  comentarios1;
                    }    
                    actions.sendEmailCotizacion(5,rfc + "-" + folio +".pdf", nombreAdmin + " " + apellidoAdmin,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,id_url3,id_url4,id_url5,nombreArchivo1,nombreArchivo2,nombreArchivo3,nombreArchivo4,nombreArchivo5,url1,url2,url3,url4,url5,comentarios).then(response=>{
                      if(response.data.data.sendEmailCotizacion.message === "Correo Enviado") {
                        swal({
                          text:"Cotización enviada con éxito",
                          buttons: false,
                          icon:'success'
                        });   
                        setTimeout(()=>{
                          // window.location.reload()
                        },3000)
                      }
                    }).catch(err=>{
                      console.log(err.response)
                    })
                  }else{
                    let id_url1 = dataUrl[0].id_url
                    let id_url2 = dataUrl[1].id_url
                    let id_url3 = dataUrl[2].id_url
                    let id_url4 = dataUrl[3].id_url
  
                    let nombreArchivo1 = dataUrl[0].nombreArchivo
                    let nombreArchivo2 = dataUrl[1].nombreArchivo
                    let nombreArchivo3 = dataUrl[2].nombreArchivo
                    let nombreArchivo4 = dataUrl[3].nombreArchivo
  
                    let url1 = dataUrl[0].url
                    let url2 = dataUrl[1].url
                    let url3 = dataUrl[2].url
                    let url4 = dataUrl[3].url
  
                    let comentarios1 = dataUrl[0].comentarios
                    let comentarios;
                    if(comentarios1 === "sin comentarios"){
                      comentarios = '';
                    }else{
                      comentarios =  comentarios1;
                    }
    
                    actions.sendEmailCotizacion(4,rfc + "-" + folio +".pdf", nombreAdmin + " " + apellidoAdmin,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,id_url3,id_url4,nombreArchivo1,nombreArchivo2,nombreArchivo3,nombreArchivo4,url1,url2,url3,url4,comentarios).then(response=>{
                      console.log("response",response)
                      if(response.data.data.sendEmailCotizacion.message === "Correo Enviado") {
                        swal({
                          text:"Cotización enviada con éxito",
                          buttons: false,
                          icon:'success'
                        });   
                        setTimeout(()=>{
                          // window.location.reload()
                        },3000)
                      }
                    }).catch(err=>{
                      console.log(err.response)
                    })
                  }
    
                }else{
                  let id_url1 = dataUrl[0].id_url
                  let id_url2 = dataUrl[1].id_url
                  let id_url3 = dataUrl[2].id_url
        
                  let nombreArchivo1 = dataUrl[0].nombreArchivo
                  let nombreArchivo2 = dataUrl[1].nombreArchivo
                  let nombreArchivo3 = dataUrl[2].nombreArchivo
        
                  let url1 = dataUrl[0].url
                  let url2 = dataUrl[1].url
                  let url3 = dataUrl[2].url
        
                  let comentarios1 = dataUrl[0].comentarios
                  let comentarios;
                  if(comentarios1 === "sin comentarios"){
                    comentarios = '';
                  }else{
                    comentarios =  comentarios1;
                  }
                  actions.sendEmailCotizacion(3,rfc + "-" + folio +".pdf", nombreAdmin + " " + apellidoAdmin,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,id_url3,nombreArchivo1,nombreArchivo2,nombreArchivo3,url1,url2,url3,comentarios).then(response=>{
                    console.log("response",response)
                    if(response.data.data.sendEmailCotizacion.message === "Correo Enviado") {
                      swal({
                        text:"Cotización enviada con éxito",
                        buttons: false,
                        icon:'success'
                      });   
                      setTimeout(()=>{
                        // window.location.reload()
                      },3000)
                    }
                  }).catch(err=>{
                    console.log(err.response)
                  })
  
                  
                }
                }else{
                  let id_url1 = dataUrl[0].id_url
                  let id_url2 = dataUrl[1].id_url
                  let nombreArchivo1 = dataUrl[0].nombreArchivo
                  let nombreArchivo2 = dataUrl[1].nombreArchivo
                  let url1 = dataUrl[0].url
                  let url2 = dataUrl[1].url
                  let comentarios = dataUrl[0].comentarios
                  actions.sendEmailCotizacion(2,rfc + "-" + folio +".pdf", nombreAdmin + " " + apellidoAdmin,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,nombreArchivo1,nombreArchivo2,url1,url2,comentarios).then(response=>{
                    console.log("response",response)
                    if(response.data.data.sendEmailCotizacion.message === "Correo Enviado") {
                      swal({
                        text:"Cotización enviada con éxito",
                        buttons: false,
                        icon:'success'
                      });   
                      setTimeout(()=>{
                        // window.location.reload()
                      },3000)
                    }
                  }).catch(err=>{
                    console.log(err.response)
                  })
                }
            }else{
              let id_url1 = dataUrl[0].id_url
              let nombreArchivo1 = dataUrl[0].nombreArchivo
              let url1 = dataUrl[0].url
              let comentarios = dataUrl[0].comentarios
              actions.sendEmailCotizacion(1,rfc + "-" + folio +".pdf", nombreAdmin + " " + apellidoAdmin,correoAdmin,correoContacto,telefono,extension,folio,id_url1,nombreArchivo1,url1,comentarios).then(response=>{
                console.log("response",response)
                if(response.data.data.sendEmailCotizacion.message === "Correo Enviado") {
                  swal({
                    text:"Cotización enviada con éxito",
                    buttons: false,
                    icon:'success'
                  });   
                  setTimeout(()=>{
                    // window.location.reload()
                  },3000)
                }
              }).catch(err=>{
                console.log(err.response)
              })
            }
        }        
      }).catch(err=>{
        console.log(err.response)
      })
  }
  render() { 
    
        const {botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa} = this.props
        
        let botonEnvio;
        let botonRegistro;
        let botonDescagar;        
        let botoncerrar;
        let fileUopload;
        let botonCerrarEnvio;
        if(botonesAdmin === true){
         if(this.state.botonCerrarEnvio === true){
            botonCerrarEnvio = <Button className="text-white" style={{background: "#E74C3C "}} type = "dashed"  onClick = {e=>this.enviarEmail()}>Enviar correo</Button>
         }
         if(this.state.enviarEmail === true) {
            botonEnvio = <Button disabled={this.state.validarArchivos} className="text-white"style={{background: "#27A584"}} type = "dashed"  onClick = {e=>this.handleUpload()}>Validar archivos</Button>
        }  
          botonRegistro =
          <button
            disabled = {this.state.botonPdfExport}
            style={{ width: "200px", height: " 30px"}}
            class="button2"
            onClick = {e=> this.onSubmitBtn(botonesAdmin,cotizaciones,datosCliente,dataContacto,productoServicioByFolio,totales,adminAlfa)}
            >
            <span class="text">Registrar cotización</span>
          </button>
            if(this.state.botonPdfExport === true) {
               botonDescagar = <Button type="primary" ghost onClick={(e) => {this.ejecutarEnvio()}}>Descargar Cotización</Button>                          
          }
        }
        let spin;
        if(this.state.fileUopload === true){
            let okFile1;
            let okFile2;
            let okFile3;
            let okFile4;
            let okFile5;

            if(this.state.image){
              okFile1 =<img width={28} src = {cheque}/>
            }else{
              okFile1 =<img width={20} src = {not}/>
            }
            if(this.state.images2){
              okFile2 =<img width={28} src = {cheque}/>
            }else{
              okFile2 =<img width={20} src = {not}/>
            }
            if(this.state.images3){
              okFile3 =<img width={28} src = {cheque}/>
            }else{
              okFile3 =<img width={20} src = {not}/>
            }
            if(this.state.images4){
              okFile4 =<img width={28} src = {cheque}/>
            }else{
              okFile4 =<img width={20} src = {not}/>
            }
            if(this.state.images5){
              okFile5 =<img width={28} src = {cheque}/>
            }else{
              okFile5 =<img width={20} src = {not}/>
            }
            if(this.state.spin === true){
              spin =   <Spin tip="Validando archivos">
              <div className="content" />
            </Spin>
            }
            let botonAdjuntar;
            if(this.state.adjuntar === true){
            botonAdjuntar =    <div>
              <label for="myfile2">Archivo adjunto 1</label> &nbsp;&nbsp;&nbsp;
              {okFile2}
              <input disabled={this.state.disabledInput} style={{width:"60%"}} type="file"  accept="application/pdf" onChange={this.handleChange2} />
              </div>
            }
            let botonAdjuntar2;
            if(this.state.adjuntar2 === true){
              botonAdjuntar2 =    <div>
              <label for="myfile2">Archivo adjunto 2</label>  &nbsp;&nbsp;&nbsp;
              {okFile3}
              <input disabled={this.state.disabledInput}  style={{width:"60%"}} type="file"  accept="application/pdf" onChange={this.handleChange3} /></div>
            }

            let botonAdjuntar3;
            if(this.state.adjuntar3 === true){
              botonAdjuntar3 =<div>
              <label for="myfile2">Archivo adjunto 3</label>  &nbsp;&nbsp;&nbsp;
              {okFile4}
              <input disabled={this.state.disabledInput}  style={{width:"60%"}} type="file"  accept="application/pdf" onChange={this.handleChange4} /></div>
            }
            let botonAdjuntar4;
            if(this.state.adjuntar4 === true){
              botonAdjuntar4 =<div>
              <label for="myfile2">Archivo adjunto 4</label>  &nbsp;&nbsp;&nbsp;
              {okFile5}
              <input disabled={this.state.disabledInput}  style={{width:"60%"}} type="file"  accept="application/pdf" onChange={this.handleChange5} /></div>
            }

            fileUopload = <div style={{width:"80%",marginTop:"2%"}}>
            <Card title = {<h6><strong>Desea enviar sus documentos por correo electrónico?</strong></h6>} type="inner" extra = {<div>Progreso de validación &nbsp; &nbsp; &nbsp; &nbsp;<progress value={this.state.progress}/> &nbsp;&nbsp;&nbsp; <Button type="dashed" danger onClick = {e=> this.cancelarEnvioEmail()}>Cancelar</Button></div>}>
            <div  className='blockInput' >  
            <div  style={{width:"70%"}}>  
            <label for="myfile">Seleccione el archivo de cotización</label> &nbsp;&nbsp;&nbsp;
            {okFile1}
            <input disabled={this.state.disabledInput} style={{width:"60%"}} type="file" id="myfile" accept="application/pdf" onChange={this.handleChange} />
            <br/>
            {botonAdjuntar}
            <br/>
            {botonAdjuntar2}
            <br/>
            {botonAdjuntar3}
            <br/>
            {botonAdjuntar4}
            </div>
            <div style={{width:"30%",marginTop:"2%"}}>
            <label for="myfile">Desea añadir comentarios al correo electrónico?</label>
            <br></br>
            <TextArea disabled={this.state.disabledInput} rows={8} placeholder="Maximo de caracteres 300" onChange={this.onChangeTextArea} maxLength={300} />
            </div>
            </div>
            {spin}
            </Card>
            <br/>
            <center>
            {botonEnvio}
            {botonCerrarEnvio}
            </center>
          </div>
        }
        if(botonesAdmin === false){
                          botoncerrar = <Button type = "dashed" danger  onClick = {e=>window.location.reload()}>Cerrar</Button>
                          botonDescagar = <Button type="primary" ghost onClick={(e) => { this.pdfExportComponent.save();}}>Descargar Cotización</Button> 
        }
        let array0 = []
        let array1 = []
        let array2 = []
        let array3 = []
        let array4 = [] 
        let array5 = []
        let array6 = []
        let array7 = []     

        let array8 = []  
        let precioDosCeros;
        let precio;    
        let descuentoAplicadoDosCeros;
        let descuentoAplicado;
        let TotalPrecioProductoDosCeros;
        let TotalPrecioProducto;
        let tofixedDescuento;
        let tofixedTotalPrecioProducto;

        productoServicioByFolio.map(rows=>{
          array0.push({"id":rows.id_productoServicio})
        })
        productoServicioByFolio.map(rows=>{ 
          array1.push({"tipo":rows.tipo})
        })
        productoServicioByFolio.map(rows=>{
          array2.push({"concepto":rows.concepto})
        })
        
        cotizaciones.map(rows=>{
          array3.push({"cantidad":rows.cantidad})
        }) 
        productoServicioByFolio.map(rows=>{
          precioDosCeros = rows.precio.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          precio =  precioDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          array4.push({"precio":precio})
        })
        cotizaciones.map(rows =>{
          array5.push({"descuento":rows.descuento})
        })
        cotizaciones.map(rows=>{
        if(botonesAdmin === false){
                        tofixedDescuento = parseFloat(rows.descuentoAplicado).toFixed(2)
                      }else {
                        tofixedDescuento = rows.descuentoAplicado.toFixed(2)
                      }
          descuentoAplicadoDosCeros = tofixedDescuento.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          descuentoAplicado =  descuentoAplicadoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            array6.push({"descuentoAplicado":descuentoAplicado})
        })
        cotizaciones.map(rows=>{
          if(botonesAdmin === false){
                        tofixedTotalPrecioProducto = parseFloat(rows.TotalPrecioProducto).toFixed(2)
                      }else {
                        tofixedTotalPrecioProducto = rows.TotalPrecioProducto.toFixed(2)
                      }
          TotalPrecioProductoDosCeros = tofixedTotalPrecioProducto.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          TotalPrecioProducto = TotalPrecioProductoDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          array7.push({"TotalPrecioProducto":TotalPrecioProducto})
        })

        productoServicioByFolio.map(rows=>{
          array8.push({"lineaProducto":rows.lineaProducto})
        })
        
        let arr = array0.map((item, i) => Object.assign({}, item, array1[i]));
        let arr1 = array2.map((item, i) => Object.assign({}, item, arr[i]));
        let arr2 = array3.map((item, i) => Object.assign({}, item, arr1[i]));
        let arr3 = array4.map((item, i) => Object.assign({}, item, arr2[i]));
        let arr4 = array5.map((item, i) => Object.assign({}, item, arr3[i]));
        let arr5 = array6.map((item, i) => Object.assign({}, item, arr4[i]));
        let arr6 = array7.map((item,i) => Object.assign({}, item, arr5[i]));
        let arr7 = array8.map((item,i) => Object.assign({}, item, arr6[i]));

      let pusharray =[]
      let pusharray1 = []
      let pusharray2 = []
      let pusharray3 = []
      let pusharrayS = []
      let pusharrayC = []
      let pusharrayP = []
      let pusharrayA = []
      let renderServicios;
      let renderProductos;
      let renderCapacitaciones; 
      let renderAlianza;
      let renderServicios2;
      let renderProductos2;
      let renderCapacitaciones2; 
      let renderAlianza2;    
      let tituloServicio=<strong>SERVICIO</strong>
      let tituloProducto=<strong>PRODUCTO</strong>
      let tituloCapacitacion=<strong>CAPACITACIÓN</strong>
      let tituloAlianza = <strong>ALIANZA</strong>
      arr7.map(row=>{
        if(row.tipo === "SERVICIO" ||row.tipo === "Servicio" ||row.tipo === "servicio"){   
          pusharray.push(row) 
          renderServicios= 
          <table className='table table-bordered'>
          <thead>
            <tr>
              <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="#5d9dd4" align='center' className='text-white'>{tituloServicio}</td>
            </tr>
          </thead>
          <tbody>
          <tr>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Tipo</td>                        
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descripción</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">QTY</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Precio U.</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">%</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descuento</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Total</td>
        </tr> 
        {pusharray.map((rows,i)=>{
          return(             
            <tr>
            <td style={{padding:"2px"}} align="center" className="totales" key={i}>{rows.tipo}</td>
            <td style={{padding:"2px"}} align="left" className="totales" >{rows.concepto}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.cantidad}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.precio}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.descuento}%</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.descuentoAplicado}</td>               
            <td style={{padding:"2px"}} align="center" className="totales">{rows.TotalPrecioProducto}</td>
          </tr>
          )      
        })
        } 
        </tbody>  
        </table> 

       }else if(row.tipo === "PRODUCTO" ||row.tipo === "Producto" ||row.tipo === "producto"){
        pusharray1.push(row)
        renderProductos= 
            <table className='table table-bordered'>
            <thead>
              <tr>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="#5d9dd4" align='center' className='text-white'>{tituloProducto}</td>
              </tr>
            </thead>
            <tbody>
            <tr>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Tipo</td>                        
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descripción</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">QTY</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Precio U.</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">%</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descuento</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Total</td>
          </tr> 
          {pusharray1.map((rows,i)=>{
            return(             
              <tr>
              <td style={{padding:"2px"}} align="center" className="totales" key={i}>{rows.tipo}</td>
              <td style={{padding:"2px"}} align="left" className="totales" >{rows.concepto}</td>
              <td style={{padding:"2px"}} align="center" className="totales">{rows.cantidad}</td>
              <td style={{padding:"2px"}} align="center" className="totales">{rows.precio}</td>
              <td style={{padding:"2px"}} align="center" className="totales">{rows.descuento}%</td>
              <td style={{padding:"2px"}} align="center" className="totales">{rows.descuentoAplicado}</td>               
              <td style={{padding:"2px"}} align="center" className="totales">{rows.TotalPrecioProducto}</td>
            </tr>
            )      
          })
          } 
    </tbody>  
   </table> 
       }else if(row.tipo ==="CAPACITACIÓN" ||row.tipo === "Capacitación" || row.tipo === "capacitación"){
        pusharray2.push(row)
        renderCapacitaciones= 
        <table className='table table-bordered'>
          <thead>
            <tr>
              <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="#5d9dd4" align='center' className='text-white'>{tituloCapacitacion}</td>
            </tr>
          </thead>
          <tbody>
          <tr>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Tipo</td>                        
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descripción</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">QTY</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Precio U.</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">%</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descuento</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Total</td>
        </tr> 
        {pusharray2.map((rows,i)=>{
          return(             
            <tr>
            <td style={{padding:"2px"}} align="center" className="totales" key={i}>{rows.tipo}</td>
            <td style={{padding:"2px"}} align="left" className="totales" >{rows.concepto}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.cantidad}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.precio}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.descuento}%</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.descuentoAplicado}</td>               
            <td style={{padding:"2px"}} align="center" className="totales">{rows.TotalPrecioProducto}</td>
          </tr>
          )      
        })
        } 
        </tbody>  
        </table>
       }else if(row.tipo === "ALIANZA" ||row.tipo === "Alianza" ||row.tipo === "alianza"){   
        pusharray3.push(row) 
        renderAlianza=   
        <table className='table table-bordered'>
          <thead>
            <tr>
              <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="#5d9dd4" align='center' className='text-white'>{tituloAlianza}</td>
            </tr>
          </thead>
          <tbody>
          <tr>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Tipo</td>                        
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descripción</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">QTY</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Precio U.</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">%</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Descuento</td>
          <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="totales text-white">Total</td>
        </tr> 
        {pusharray3.map((rows,i)=>{
          return(             
            <tr>
            <td style={{padding:"2px"}} align="center" className="totales" key={i}>{rows.tipo}</td>
            <td style={{padding:"2px"}} align="left" className="totales" >{rows.concepto}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.cantidad}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.precio}</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.descuento}%</td>
            <td style={{padding:"2px"}} align="center" className="totales">{rows.descuentoAplicado}</td>               
            <td style={{padding:"2px"}} align="center" className="totales">{rows.TotalPrecioProducto}</td>
          </tr>
          )      
        })
        } 
        </tbody>  
        </table>
       }     
         })     
// ******************RENDER PDF DOWNLOAD****************************
         arr7.map(row=>{
          if(row.tipo === "SERVICIO" ||row.tipo === "Servicio" ||row.tipo === "servicio"){   
            pusharrayS.push(row) 
            renderServicios2=  
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={10} bgcolor="#5d9dd4" align='center' className='text-white textabla'>{tituloServicio}</td>
                </tr>
              </thead>
              <tbody>
              <tr>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Tipo</td>                        
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" colSpan={4} className="text-white textabla">Descripción</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">QTY</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Precio U.</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">%</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Desc</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Total</td>
            </tr> 
            {pusharrayS.map((rows,i)=>{
              return(             
                <tr>
                <td style={{padding:"2px"}} align="center" className="textabla" key={i}>{rows.tipo}</td>
                <td style={{padding:"2px"}} align="left" className="textabla" colSpan={4}>{rows.concepto}</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.cantidad}</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.precio}</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuento}%</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuentoAplicado}</td>               
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.TotalPrecioProducto}</td>
              </tr>
              )      
            })
            } 
            </tbody>  
            </table>            
           }else if(row.tipo === "PRODUCTO" ||row.tipo === "Producto" ||row.tipo === "producto"){
            pusharrayP.push(row)
            renderProductos2= 

              <table className='table table-bordered'>
              <thead>
                <tr>
                  <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={10} bgcolor="#5d9dd4" align='center' className='text-white textabla'>{tituloProducto}</td>
                </tr>
              </thead>
              <tbody>
              <tr>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Tipo</td>                        
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla" colSpan={4}>Descripción</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">QTY</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Precio U.</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">%</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Desc.</td>
              <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Total</td>
            </tr> 
            {pusharrayP.map((rows,i)=>{
              return(             
                <tr>
                <td style={{padding:"2px"}} align="center" className="textabla" key={i}>{rows.tipo}</td>
                <td style={{padding:"2px"}} align="left" className="textabla" colSpan={4}>{rows.concepto}</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.cantidad}</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.precio}</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuento}%</td>
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuentoAplicado}</td>               
                <td style={{padding:"2px"}} align="center" className="textabla">{rows.TotalPrecioProducto}</td>
              </tr>
              )      
            })
            } 
            </tbody>  
            </table> 
           }
           else if(row.tipo ==="CAPACITACIÓN" ||row.tipo === "Capacitación" || row.tipo === "capacitación"){
            pusharrayC.push(row)
            renderCapacitaciones2=          
            <table className='table table-bordered'>
            <thead>
              <tr>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={10} bgcolor="#5d9dd4" align='center' className='text-white textabla'>{tituloCapacitacion}</td>
              </tr>
            </thead>
            <tbody>
            <tr>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Tipo</td>                        
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla" colSpan={4}>Descripción</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">QTY</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Precio U.</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">%</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Desc.</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Total</td>
          </tr> 
          {pusharrayC.map((rows,i)=>{
            return(             
              <tr>
              <td style={{padding:"2px"}} align="center" className="textabla" key={i}>{rows.tipo}</td>
              <td style={{padding:"2px"}} align="left" className="textabla" colSpan={4}>{rows.concepto}</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.cantidad}</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.precio}</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuento}%</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuentoAplicado}</td>               
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.TotalPrecioProducto}</td>
            </tr>
            )      
          })
          } 
          </tbody>  
          </table>
           } 
           else if(row.tipo ==="ALIANZA" ||row.tipo === "Alianza" || row.tipo === "alianza"){
            pusharrayA.push(row)
            renderAlianza2=          
            <table className='table table-bordered'>
            <thead>
              <tr>
                <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={10} bgcolor="#5d9dd4" align='center' className='text-white textabla'>{tituloAlianza}</td>
              </tr>
            </thead>
            <tbody>
            <tr>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Tipo</td>                        
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla" colSpan={4}>Descripción</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">QTY</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Precio U.</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">%</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Desc.</td>
            <td style={{padding:"2px"}} bgcolor="#05387A" align="center" className="text-white textabla">Total</td>
          </tr> 
          {pusharrayA.map((rows,i)=>{
            return(             
              <tr>
              <td style={{padding:"2px"}} align="center" className="textabla" key={i}>{rows.tipo}</td>
              <td style={{padding:"2px"}} align="left" className="textabla" colSpan={4}>{rows.concepto}</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.cantidad}</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.precio}</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuento}%</td>
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.descuentoAplicado}</td>               
              <td style={{padding:"2px"}} align="center" className="textabla">{rows.TotalPrecioProducto}</td>
            </tr>
            )      
          })
          } 
          </tbody>  
          </table>
           }        
        }) 
       
       
        // ***********Fecha expiracion*************
        const fechaExpiracion = new Date(cotizaciones[0].fechaExpiracion);
          var letrasMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
          let diaExpiracion = fechaExpiracion.getDate()
          let letraMesSubstring = fechaExpiracion.getMonth() +1
          let numeroPosicionMes = parseInt(letraMesSubstring, 10)
          let mesExpiracion  = letrasMeses[numeroPosicionMes - 1] 
          let añoExpiracion= fechaExpiracion.getFullYear()  
        // ************************
        let emision =  cotizaciones[0].fechaEmision.substring(0,10)
      
        let titulo = <strong className="CardTexto">Previsualización</strong>
        var meses = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];
        let dia = emision.substring(0,2)
        let mesSubstring = emision.substring(3,5)
        let posicionMes = parseInt(mesSubstring, 10)
        let mes  = meses[posicionMes - 1]
        let año = emision.substring(6,10)       

        let ivaFloat = parseFloat(totales.iva).toFixed(2)
        let ivaGlobalDosCeros = ivaFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let iva =  ivaGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let subtotalFloat = parseFloat(totales.subtotal).toFixed(2)
        let subtotalGlobalDosCeros = subtotalFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let subtotal =  subtotalGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let totalFloat = parseFloat(totales.total).toFixed(2)
        let totalGlobalDosCeros = totalFloat.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
        let total =  totalGlobalDosCeros.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

        let renderizado;
        if(this.state.renderizado === true){
          renderizado = 
          <div className="center">         
          <Card  style={{padding:"1%"}}>    
            <div>
            <div className='rows'> 
            <img className='logoReport' style={{marginLeft:"3rem"}} src={titulo1} alt="imagen" />
            <div className='rows' style={{marginTop:"3%"}}>{botonRegistro}&nbsp;&nbsp;&nbsp;{botonDescagar}&nbsp;&nbsp;&nbsp;{botoncerrar}</div>
            </div> 
            <div className='container10'>
            <div className="datos-generales">
              <ul>
              <li className='strong'>ALFA DISEÑO DE SISTEMAS SA DE CV</li>
              <li className='strong'>COTIZACIÓN NO. {cotizaciones[0].NumFolio}</li>
              <li className='strong'>CIUDAD DE MEXICO A {dia + " de " + mes + " de " + año}</li>
              </ul>
            </div>
            <div>
              <table style={{width:"30em",marginTop:"12%"}} className='table-bordered'>
                <thead>
                  <tr>
                    <td bgcolor="#5d9dd4" align='center' className='text-white'>EMISIÓN</td>
                    <td bgcolor="#5d9dd4" align='center' className='text-white'>EXPIRACIÓN</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td bgcolor="#ffffff" align='center'>{emision}</td>
                    <td bgcolor="#ffffff" align='center'>{diaExpiracion+"/"+mesExpiracion+"/"+añoExpiracion}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
            <table style={{width:"30em"}} className='table24 textabla23 table-borderless'>
                <thead>
                  <tr>
                    <td colspan="2" bgcolor="#5d9dd4" align='center' className='text-white'>DATOS GENERALES</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>{datosCliente.razonSocial}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>{dataContacto.nombre}&nbsp;{dataContacto.apellidos}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>{dataContacto.correo1}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>{dataContacto.telefono1}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>{dataContacto.puesto}</td>
                  </tr>
                </tbody>
              </table>
              <center>
              <p className='strong table24' style={{marginTop:"2%"}}>En base a su amable solicitud, me permito poner a su consideración nuestra propuesta referente a los productos y/o servicios de su interés.</p>
              </center>
            </div>
            <div style={{marginTop:"2%"}}>            
            <center>
            <div style={{width:900}}>               
              {renderProductos}
              {renderServicios}
              {renderCapacitaciones} 
              {renderAlianza}

            <table className='table table-bordered'>
              <thead>
                <tr>
                  <td style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="#5d9dd4" align='center' className='text-white'>TOTALES</td>
                </tr>
              </thead>
              <tbody>
              <tr>
              <td className='strong' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong' colSpan={1}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">SUBTOTAL</td>                        
              <td className='strong' colSpan={1}  style={{padding:"2px"}} bgcolor="#ffffff" align="center">$&nbsp;{subtotal}</td>
              </tr> 
              <tr>
              <td className='strong' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">IVA</td>                        
              <td className='strong' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="center">$&nbsp;{iva}</td>
              </tr> 
              <tr>
              <td className='strong' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">TOTAL</td>                        
              <td className='strong' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="center">$&nbsp;{total}</td>
              </tr> 
            </tbody>  
            </table>          
            </div>
            </center>
            
                <table style={{width:"80em"}} className='table24 textabla23 table-borderless'>
                <thead>
                  <tr>
                    <td colspan="2" bgcolor="#5d9dd4" align='center' className='text-white'>CONDICIONES COMERCIALES Y FORMAS DE PAGO</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>Todos los costos anteriormente presentados son más IVA.</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>Precios representados en M.N.</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>Pago por anticipado</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'>Pago por depósito bancario o transferencia electrónica.</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'><b>Cuenta:</b>50020978434&nbsp;-&nbsp;<b>Clabe:</b> 036180500209784346</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'><b>Beneficiario:</b> ALFA DISEÑO DE SISTEMAS, S.A. de C</td>
                  </tr>
                  <tr>
                    <td style={{padding:"6px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong'><b>Banco:</b> Inbursa</td>
                  </tr>
                </tbody>
              </table>  
              <p style={{marginLeft:"60%",marginTop:"2%"}}><strong> Nota:</strong><i> Vigencia {diaExpiracion +" de "+mesExpiracion+" del "+añoExpiracion}</i></p>    
              <p style={{marginLeft:"60%",marginTop:"2%"}}>El costo no incluye Interfaz, Formatos, Carga de Catálogos o alguna implementación adicional a la mencionada en su cotización.</p>
              <p style={{marginLeft:"60%",marginTop:"2%"}}><strong> No se aceptan devoluciones</strong></p>           
              <p style= {{marginLeft:"5%"}} className='strong'>Sin más por el momento y agradeciéndole por su amable atención,
                  Quedo a sus órdenes para cualquier duda al respecto.</p>
              <fort> 
              <p style= {{marginLeft:"5%"}}>               
              {adminAlfa.nombre + " " +  adminAlfa.apellido}                 
              </p>
              <p style= {{marginLeft:"5%"}}>
              {adminAlfa.puesto}
              </p>
              <p style= {{marginLeft:"5%"}}>{adminAlfa.correo}</p>
              <p style= {{marginLeft:"5%"}}>{"Tel. " + adminAlfa.telefono + " Ext." + adminAlfa.extensionTelefonica }</p>             

                  <p  position= "absolute" className="footert text-center py-3 strong">Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México <br></br> Información, soporte y ventas:
                  Conmutador:55 3603 9970 - 55 5553 2049</p>             
          </fort  > 
          </div>
          </Card>
          </div>
        }
        let pdfExport = <div style={{ position: "absolute",left:"-5000px",top: 0 }}>               
        <PDFExport
            paperSize="letter"
            margin="1cm"
            // scale="0.8"
            forcePageBreak=".page-break"
            fileName={localStorage.getItem("rfc")+ "-" + cotizaciones[0].NumFolio+".pdf"}
            pageTemplate = {PageTemplate}  
            multiPage= "true "         
            ref={(component) => this.pdfExportComponent = component}>
            <div> 
              <img className='logoReportDown' src={titulo1} alt="imagen" />
            <div className='container10' style={{marginTop:"6%"}}>
              <div className="datos-generales-report textabla">
              <ul>
              <li className='strong'>ALFA DISEÑO DE SISTEMAS SA DE CV</li>
              <li className='strong'>COTIZACIÓN NO. {cotizaciones[0].NumFolio}</li>
              <li className='strong'>CIUDAD DE MEXICO A {dia+ " DE " + mes + " DE " + año}</li>
              </ul>
              </div>
              <div>
              <table style={{width:"18em",marginTop:"2%"}} className='table-bordered'>
                <thead>
                  <tr>
                    <td bgcolor="#5d9dd4" align='center' className='text-white textabla'><strong>EMISIÓN</strong></td>
                    <td bgcolor="#5d9dd4" align='center' className='text-white textabla'><strong>EXPIRACIÓN</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td bgcolor="#ffffff" align='center' className='textabla'>{emision}</td>
                    <td bgcolor="#ffffff" align='center' className='textabla'>{diaExpiracion+"/"+mesExpiracion+"/"+añoExpiracion}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
            <div className='container10'>
            <table style={{width:"25em"}}>
                <thead>
                  <tr>
                    <td colspan="2" bgcolor="#5d9dd4" align='center' className='text-white textabla'><strong>DATOS GENERALES</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>{datosCliente.razonSocial}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>{dataContacto.nombre}&nbsp;{dataContacto.apellidos}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>{dataContacto.correo1}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>{dataContacto.telefono1}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <center>
            <p className='strong textabla' style={{marginTop:"2%"}}>En base a su amable solicitud, me permito poner a su consideración nuestra propuesta referente a los productos y/o servicios de su interés.</p>
            </center>
            <div style={{marginTop:"2%"}}>

            <center>
            <div style={{width:555}}>               
              {renderProductos2}
              {renderServicios2}
              {renderCapacitaciones2} 
              {renderAlianza2}
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <td  style={{paddingTop:"2px",paddingBottom:"2px"}} colSpan={7} bgcolor="#5d9dd4" align='center' className='text-white strong textabla'><strong>TOTALES</strong></td>
                </tr>
              </thead>
              <tbody>
              <tr>
              <td className='strong textabla' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong textabla' colSpan={1}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">SUBTOTAL</td>                        
              <td className='strong textabla' colSpan={1}  style={{padding:"2px"}} bgcolor="#ffffff" align="center">$&nbsp;{subtotal}</td>
              </tr> 
              <tr>
              <td className='strong textabla' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">IVA</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="center">$&nbsp;{iva}</td>
              </tr> 
              <tr>
              <td className='strong textabla' colSpan={5}  style={{padding:"2px"}} bgcolor="#ffffff" align="left">-</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="left">TOTAL</td>                        
              <td className='strong textabla' colSpan={1} style={{padding:"2px"}}  bgcolor="#ffffff" align="center">$&nbsp;{total}</td>
              </tr> 
            </tbody>  
            </table>          
            </div>
            </center>   
            <div className='tag-p' style={{marginTop:"1%"}}>     
            <table style={{width:"80em"}} className='table-borderless'>
                <thead>
                  <tr>
                    <td colspan="2" bgcolor="#5d9dd4" align='center' className='text-white textabla'><strong>CONDICIONES COMERCIALES Y FORMAS DE PAGO</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Todos los costos anteriormente presentados son más IVA.</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Precios representados en M.N.</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Pago por anticipado</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>Pago por depósito bancario o transferencia electrónica.</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'><b>Cuenta:</b>50020978434&nbsp;-&nbsp;<b>Clabe:</b> 036180500209784346</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'><b>Beneficiario:</b> ALFA DISEÑO DE SISTEMAS, S.A. de C</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'><b>Banco:</b> Inbursa</td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'></td>
                  </tr>
                  <tr>
                    <td colspan="2" bgcolor="#ffffff" align='left' className='strong textabla'>No se aceptan devoluciones</td>
                  </tr>
                </tbody>
              </table>
              <p className='strong textabla' style={{marginLeft:"4%",marginTop:"4%"}}>El costo no incluye interfaz, formatos, carga de catálogos o alguna implementación adicional a la mencionada en su cotización. <br/><br/>
              Sin más por el momento y agradeciéndole por su amable atención,<br/>
              Quedo a sus órdenes para cualquier duda al respecto.<br/><br/>
              {adminAlfa.nombre + " " +  adminAlfa.apellido}<br/>{adminAlfa.puesto} <br/> {adminAlfa.correo} <br/>{"Tel. " + adminAlfa.telefono + " Ext." + adminAlfa.extensionTelefonica }</p>
              </div>   
              
              <div class="page-break">
              <img className='logoReportDown' src={titulo1} alt="imagen" />

              <p style={{marginTop:"2% "}} className="strong textabla">En Alfa y Diseño de Sistemas (ADS) respaldamos su inversión en los sistemas <strong>ContPAQi</strong>, por lo que
                ponemos a su disposición nuestro programa de pólizas de servicio, las cuales incluyen los siguientes
                beneficios: 
              </p>
              <table  style={{width:555}}  className='table table-small table-bordered table-striped'>
                <thead>
                  <tr>
                  <td className="textabla3" width="13%" style={{padding:"2px"}} align="left"><strong>Beneficios</strong></td>
                  <td className="textabla3" width="8%" style={{padding:"2px"}} align="center"><strong>Cobertura</strong></td>
                  <td className="textabla3" width="50%" style={{padding:"2px"}} align="center"><strong>Descripción</strong></td>                     
                  </tr>
                </thead>
                <tbody>
                <tr>
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Formación</td>   
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Incluida </td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Al ser cliente ADS podrá acceder <strong>SIN COSTO</strong> a los cursos, talleres y
                  conferencias exclusivos
                </td>                     
              </tr>              
              <tr>
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Soporte Remoto</td>    
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Ilimitada</td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Podrá utilizar nuestro servicio de soporte técnico remoto de manera ilimitada durante el periodo de su póliza, el cual consiste en ofrecer al
                  cliente la posibilidad de atender sus incidentes relacionados con los sistemas <strong>ContPAQi</strong>, por medio de internet, utilizando un software específico de control 
                  remoto, con una capacidad de veinte consolas de atención.
                </td>                            
              </tr>
              <tr>
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Asesoría Telefónica</td>
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Ilimitada</td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Podrá contactar a nuestros asesores vía telefónica, para cualquier duda relacionado con el uso de los sistemas <strong>ContPAQi</strong>. 
                </td>                                               
              </tr> 
              <tr>
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Boletines Informativos</td>    
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Mensual</td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Nuestro servicio informativo lo mantendrá actualizado de los cambios, nuevas versiones, funcionalidades, promociones, etc. Referente a los sistemas <strong>ContPAQi</strong>.
                  Así como información de los eventos, cursos, conferencias que hemos organizado para ti en ADS.
                </td>  
                                      
              </tr>  
              <tr>               
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Cliente ADS</td>    
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Incluida</td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Al contar con su póliza vigente recibirá un usuario y contraseña, para ingresar al Portal de Cliente ADS, donde encontrara contenido
                  exclusivo para clientes de ADS. 
                </td> 
              </tr>    
              <tr>               
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Instalaciones y/o reinstalaciones</td>  
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Incluida</td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Su póliza le permite realizar instalaciones o reinstalaciones de sus licencias sin costo adicional. </td>              
                </tr> 
              <tr>               
                <td className="textabla3" width="13%" style={{padding:"2px"}} align="left">Descuentos</td>  
                <td className="textabla3" width="8%" style={{padding:"2px"}} align="center">Incluida</td>        
                <td className="textabla3" width="50%" style={{padding:"2px"}} align="left">
                  Al ser cliente ADS podrá obtener descuentos especiales en visitaspersonalizadas, talleres, cursos, diseño de reporte a la medida,
                  desarrollo especiales y todos aquellos servicios no incluidos en la póliza básica</td>              
                </tr>
                
                </tbody>
              </table>
            <table className="table table-bordered table-small table-striped" small style={{width:555}}>
              <tr>                
                <td className="textabla3" style={{padding:"2px",color:"#05387A"}} align="center" colspan="3"><strong>PÓLIZA DE SOPORTE REMOTA BASICA</strong></td>                
              </tr>
              <tr>
                <td className="textabla3" style={{padding:"2px"}} align="center">Póliza Trimestral</td>   
                <td className="textabla3" style={{padding:"2px"}} align="center">Póliza Semestral</td>        
                <td className="textabla3" style={{padding:"2px"}} align="center">Póliza Anual</td>                     
              </tr>              
              <tr>
                <td className="textabla3" style={{padding:"2px"}} align="center">$3000.00</td>    
                <td className="textabla3" style={{padding:"2px"}} align="center">$5,000.00</td>        
                <td className="textabla3" style={{padding:"2px"}} align="center">$8,000.00</td>                            
              </tr>  
              <tr>               
                <td className="textabla3"  style={{padding:"2px"}} align="left" colspan="3">
                  <strong>Notas:</strong>
                  <ul>
                    <li><strong>PRECIOS POR SISTEMA</strong></li>
                    <li><strong>PRECIOS MAS IVA</strong></li>
                    <li>Los horarios de servicio son de lunes a viernes de 8:00 a 14:00 y de 15:00 A 18:00</li>
                    <li>Guardia de 6:00 a 9:00 sábados de 10:00 a 2:00</li>
                    <li>La póliza no incluye visitas en sitio, reproceso de información, cambio de servidor, Interfaces, capacitaciones e implementaciones.</li>
                    <li style={{color:"#05387A"}}><strong>No se aceptan cambios ni devoluciones</strong></li>
                </ul>
              </td>   
              </tr>                
            </table>
            <table className='table table-small table-bordered table-striped' style={{width:555}}>
              <tr>                
                <td className="textabla3" style={{padding:"2px",color:"#05387A"}} align="center" colspan="7"><strong>CAPACITACIÓN</strong></td>                
              </tr>
              <tr>
                <td className="textabla3" style={{padding:"2px"}} align="left" colspan="6">Curso de capacitación básico 1 persona por sistema Presencial ADS</td>   
                <td className="textabla3" style={{padding:"2px"}} align="right">$1,400.00 </td>        
              </tr>              
              <tr>
                <td className="textabla3" style={{padding:"2px"}} align="left" colspan="6">Membresía anual ContPAQI Kursa auto capacitación individual </td>    
                <td className="textabla3" style={{padding:"2px"}} align="right">$1,990.00</td>        
              </tr>  
              <tr>               
                <td className="textabla3"  style={{padding:"2px"}} align="left" colspan="7">
                  <strong>Notas:</strong>
                  <ul>                    
                    <li><strong>PRECIOS MAS IVA</strong></li>
                    <li><strong>No transferible ligada a cuenta de correo</strong></li>                    
                </ul>
              </td>   
              </tr>                
            </table>
            </div>
        </div>  
        </div>
        </PDFExport>
        </div>
        return ( 
        <div>
        <center>    
        {fileUopload}
        </center>
        {renderizado}  
        {pdfExport}
        </div>   
        );
    }
}
export default PDFReport;