import React, { Component } from 'react'
import actionsAPI from './actionsAPI';
import { Button, Modal, Tag } from 'antd';
import MUIDataTable from "mui-datatables";
import swal from 'sweetalert';
import Report_Cotizacion from './report';

class Solicitudes_Cotizaciones_cliente extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tabla:true,
            cotizaciones:[],
            cotizacion:[],
            open:false
        }

    }
    componentWillMount(){
        actionsAPI.get_cotizaciones_clientes().then(response=>{
            this.setState({cotizaciones:response.data.data.get_cotizaciones_clientes})
        }).catch(err=>{
            console.log(err.response)
        })
    }
    onOk(){
        this.setState({open:false})
    }
    ok_cotizacion(param){
        actionsAPI.ok_cotizacion(param).then(response=>{
            let res = response.data.data.ok_cotizacion;
            if(res.message === "cotizacion aprobada"){
                swal({
                    title:"Cotización aprobada",
                    text:`La cotización fue aprobada, el cliente puede realizar el pago`,               
                    icon:"success",
                });  
                setTimeout(()=>{
                    window.location.reload();
                },2000
                )
            }else{
                swal({
                    title:"Error",
                    text:`!!Algo salió mal`,               
                    icon:"error",
                });  
            }
        }).catch(err=>{
            console.log(err.response)
        })
    }
    showDocument(param){
        this.setState({open:true})
        this.setState({report:true});

        let array = [];
        this.state.cotizaciones.forEach(function(hero){
            if(hero.folio === param){
                array.push(hero)
            }            
        })
        this.setState({cotizacion:array})

    }
    cursoPagado(param){
        actionsAPI.cursoPagado(param).then(response=>{
            if(response.data.data.cursoPagado.message === "curso pagado"){
                swal({
                    title:"Notificación!",
                    text:`La notificación se envió al cliente, en este momento ya puede proceder el registro.`,               
                    icon:"success",
                }); 
                setTimeout(()=>{
                    window.location.reload()
                },2000)
            }
        }).catch(err=>{
            console.log("error",err.response)
        })
    }
    render() { 
        let report;

        if(this.state.cotizacion[0]){
            report = 
            <Modal onCancel={e=>this.onOk()} footer={null} open={this.state.open} title = "Reporte generado">
                <Report_Cotizacion data = {this.state.cotizacion}/>

            </Modal>
        }
        const optionsa = {
            print:false,
            sort:false,
            viewColumns:false,
            download:false,
             filterType: 'checkbox',
             filter:false,
             select:false,
          
             selectableRowsHeader:false
           };
        let columns = ["#","Ver cotización","Curso","Folio","Precio total","Emisión documento","Fecha del curso","Tipo","Imagen","Estatus","Acción","Confirmar pago"] 
        let data = this.state.cotizaciones.map((rows,i)=>{
            let button_ver = <Button type = "primary" onClick={e=> this.showDocument(rows.folio)}>Ver cotización</Button>;
            let button;
            let button_pagado;
            if(rows.estado === "Pendiente"){
                button_pagado = <font color="red" className="strong">Curso no aprobado</font>
                button =  <Button type = "primary" onClick={e=> this.ok_cotizacion(rows.folio)}>Aprobar</Button>
            }if(rows.estado === "Aprobada"){
                button = <font color = "blue" className="strong">Realizado</font>
                if(rows.estatus_pago === "Pagado"){
                    button_pagado = <font className="strong" color="green">Pagada</font>
                }
                if(rows.estatus_pago === "No pagado"){
                    button_pagado = <Button type = "success" onClick={e=> this.cursoPagado(rows.folio)}>Curso pagado</Button>
                }
            }
            return([i+1,button_ver, rows.concepto, rows.folio, rows.total_global, rows.fecha_emision,rows.fecha_curso,rows.tipo, <img style={{width:150}} src={rows.imagen}/>,<Tag color="blue">{rows.estado}</Tag>,button,button_pagado])

        })

        let tabla 
        if(this.state.tabla === true){
            tabla = 
            <div style={{width:"95%",marginTop:"6em"}}>
                <MUIDataTable 
                title={"Cotizaciones solicitadas"}
                data={data}
                columns={columns}
                options={optionsa}
                />
            </div>
        }
       
        return ( 
        <div>
            <center>
                {tabla}
                {report}
            </center>
        </div> 
        );
    }
}
 
export default Solicitudes_Cotizaciones_cliente;