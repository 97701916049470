import React, { Component } from "react";
import { Layout, Menu } from "antd";
import 'antd/dist/antd.css';
import  './sidenavbar.css';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DesktopOutlined,
  DollarOutlined,
  OrderedListOutlined,
  CloseOutlined,
  SolutionOutlined,
  ReconciliationOutlined,
  FolderOpenOutlined,
  FilePdfOutlined,
  ShoppingCartOutlined,
  YoutubeOutlined,
  HistoryOutlined,
  MailOutlined,
  DotChartOutlined,
  RadiusSettingOutlined,
  FileSyncOutlined,
  ClusterOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TablaClientes from "./tablaClientes";
import TablaEventos from "./eventosEvenbrite";
import Cotizaciones from "./cotizaciones";
import TablaCotizacion from "./TablaCotizaciones";
import Cliente from "./signupClientes";
import SignupProductoServicio from "./productoServicio";
import ComprasCarrito from "./comprasCarrito";
import VideosPrivados from "./videos";
import ADS from "../imagen/ADS.png";
import { API } from "../Graphql/Graphql";
import axios from "axios";
import HistorialPolizas from "./historialPolizas";
import TablaVentas from "./tablaVentas";
import HistorialInicioSesion from "./registroInicioSesion";
// import Base from '../depuracionBasa/index'
import Indicadores from "./indicadores";
import ListadoProductosYServicios from "./listadoProductosYServicios";
import Support from "./soporteTecnico";
import RegisterPolizas from "./registerPolizas";
import ConfigurarCorreo from "./configurarCorreo";
const { Header, Sider } = Layout;

class SiderDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      tablaInicio: true,
      tablaEventos: false,
      configurarCorreo: false,

      cotizaciones: false,
      tablaCotizaciones: false,
      nuevoCliente: false,
      registraProductoServicio: false,
      carrito: false,
      registrarVideo: false,
      historialPolizas: false,
      tablaVentas: false,
      indicadores: false,
      historialInicioSesion: false,
      // productoActualizado:[],
      listadoProductos: false,
      support: false,
      time: new Date().toLocaleString(),
      registerPolizas: false,
    };

    this.cerrar = this.cerrar.bind(this);
  }

  cerrar() {
    this.props.history.push("/loginAdmin");
  }

  componentDidMount() {
    window.location.hash = "no-back-button";
    window.location.hash = "Again-No-back-button"; //esta linea es necesaria para chrome
    window.onhashchange = function () {
      window.location.hash = "no-back-button";
    };
  }



  componentWillMount = async () => {
    let fk_adminalfa = localStorage.getItem("id_admin");
    let fk_empresa = localStorage.getItem("fk_empresa");
    let array3 = [];
    let array4 = [];
    let array = [];
    console.log(API)
    await axios({
      url: API,
      method: "post",
      data: {
        query: `
            query{
              getCotizacionesTabla(data:"${[fk_adminalfa]}"){
                id_cotizaciones 
                fechaEmision
                NumFolio
                cantidad 
                descuento
                descuentoAplicado
                TotalPrecioProducto
                statusCotizacion  
                fechaExpiracion
                fk_cliente 
              } 
            }
            `,
      },
    })
      .then((response) => {
        let cotizaciones = response.data.data.getCotizacionesTabla;
        this.setState({ peticionCotizaciones: cotizaciones });
        function getUniqueListBy(arr, key) {
          return [...new Map(arr.map((item) => [item[key], item])).values()];
        }

        const arr1 = getUniqueListBy(cotizaciones, "NumFolio");
        arr1.map((rows) => {
          if (rows.fechaExpiracion) {
            let fechaExpiracion = rows.fechaExpiracion.substring(4, 33);
            this.countdown(fechaExpiracion, rows.NumFolio);
          }
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
    let empresa = localStorage.getItem("fk_empresa");
    axios({
      url: API,
      method: "post",
      data: {
        query: `
                query{
                getPolizas(data:"${[empresa]}"){
                    fk_cliente                    
                    fechaFinal
                    rfc
                    id_polizas
                    fechaInicial
                    statusPoliza
                    razonSocial
                    tipo
                    concepto
                    lineaProducto
                  } 
                }
                `,
      },
    })
      .then((response) => {
        let polizas = response.data.data.getPolizas;
        polizas.map((rows) => {
          if (rows.fechaFinal) {
            let fechaFinal = rows.fechaFinal.substring(4, 33);
            this.countdown1(fechaFinal, rows.id_polizas);
          }
        });
      })
      .catch((err) => {
        console.log(err)
        console.log("error", err.response);
      });
  };

  countdown = (deadline, numFolio) => {
    const timerUpdate = setInterval(async () => {
      let t = this.getRemainingTime(deadline);

      if (t.remainTime <= 1) {
        clearInterval(timerUpdate);
        axios({
          url: API,
          method: "post",
          data: {
            query: `
          mutation{
            cotizacionVencida(data:"${[numFolio]}"){
                message
                  }
                }
              `,
          },
        }).then((datos) => {});
      }
    }, 1000);
  };

  countdown1 = (deadline, id_polizas) => {
    const timerUpdate = setInterval(async () => {
      let t = this.getRemainingTime(deadline);

      if (t.remainTime <= 1) {
        clearInterval(timerUpdate);
        axios({
          url: API,
          method: "post",
          data: {
            query: `
          mutation{
            polizaVencida(data:"${[id_polizas]}"){
                message
                  }
                }
              `,
          },
        }).then((datos) => {});
      }
    }, 1000);
  };
  getRemainingTime = (deadline) => {
    let now = new Date(),
      remainTime = (new Date(deadline) - now + 1000) / 1000,
      remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2),
      remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2),
      remainDays = Math.floor(remainTime / (3600 * 24));

    return {
      remainSeconds,
      remainMinutes,
      remainHours,
      remainDays,
      remainTime,
    };
  };

  

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  tablaInicio() {
    this.setState({ tablaInicio: true });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
  }
  configurarCorreo() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: true });
  }

  generarCotizaciones() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: true });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }

  tablaEventos() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: true });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }

  consultarCotizaciones() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: true });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }

  registrarCliente() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: true });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }

  registraProductoServicio() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: true });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }

  carrito() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: true });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  historialPolizas() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: true });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  registrarVideo() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: true });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  tablaVentas() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ tablaVentas: true });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  indicadores() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ tablaVentas: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ indicadores: true });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  historialInicioSesion() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ tablaVentas: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: true });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  listadoProductosYServicios() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ tablaVentas: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: true });
    this.setState({ support: false });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  support() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ tablaVentas: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: true });
    this.setState({ registerPolizas: false });
    this.setState({ configurarCorreo: false });
  }
  registerPolizas() {
    this.setState({ tablaInicio: false });
    this.setState({ cotizaciones: false });
    this.setState({ tablaEventos: false });
    this.setState({ tablaCotizaciones: false });
    this.setState({ nuevoCliente: false });
    this.setState({ registraProductoServicio: false });
    this.setState({ carrito: false });
    this.setState({ tablaVentas: false });
    this.setState({ registrarVideo: false });
    this.setState({ historialPolizas: false });
    this.setState({ indicadores: false });
    this.setState({ historialInicioSesion: false });
    this.setState({ listadoProductos: false });
    this.setState({ support: false });
    this.setState({ registerPolizas: true });
    this.setState({ configurarCorreo: false });
  }

  render() {
    let rolAdministrador = localStorage.getItem("rolAdministrador");
    let rolAdmin;
    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    let fechaHoy = dd + "/" + mm + "/" + yyyy;

    let tabla;
    let eventos;
    let cotizaciones;
    let tablaCotizaciones;
    let signupCliente;
    let signupProductoServicio;
    let carrito;
    let registrarVideo;
    let historialPolizas;
    let HistorialVentas;
    let indicadores;
    let historialInicioSesion;
    let listadoProductos;
    let support;
    let registerPolizas;
    let configuraCorreo;

    if (this.state.tablaInicio === true) {
      tabla = (
        <div>
          <TablaClientes />
        </div>
      );
    }

    if (this.state.tablaEventos === true) {
      eventos = (
        <div>
          <TablaEventos />
        </div>
      );
    }

    if (this.state.cotizaciones === true) {
      cotizaciones = (
        <div>
          <Cotizaciones />
        </div>
      );
    }
    if (this.state.tablaCotizaciones === true) {
      tablaCotizaciones = (
        <div>
          <TablaCotizacion />
        </div>
      );
    }

    if (this.state.nuevoCliente === true) {
      signupCliente = (
        <div>
          <Cliente />
        </div>
      );
    }

    if (this.state.registraProductoServicio === true) {
      signupProductoServicio = (
        <div>
          <SignupProductoServicio />
        </div>
      );
    }

    if (this.state.carrito === true) {
      carrito = (
        <div>
          <ComprasCarrito />
        </div>
      );
    }

    if (this.state.registrarVideo === true) {
      registrarVideo = (
        <div>
          <VideosPrivados />
        </div>
      );
    }
    if (this.state.historialPolizas === true) {
      historialPolizas = (
        <div>
          <HistorialPolizas />
        </div>
      );
    }
    if (this.state.tablaVentas === true) {
      HistorialVentas = (
        <div>
          <TablaVentas />
        </div>
      );
    }
    if (this.state.indicadores === true) {
      indicadores = (
        <div>
          <Indicadores />
        </div>
      );
    }
    if (this.state.historialInicioSesion === true) {
      historialInicioSesion = (
        <div>
          <HistorialInicioSesion />
        </div>
      );
    }
    if (this.state.listadoProductos === true) {
      listadoProductos = (
        <div>
          <ListadoProductosYServicios />
        </div>
      );
    }
    if (this.state.support === true) {
      support = (
        <div>
          <Support />
        </div>
      );
    }
    if (this.state.registerPolizas === true) {
      registerPolizas = (
        <div>
          <RegisterPolizas />
        </div>
      );
    }
    if (this.state.configurarCorreo === true) {
      configuraCorreo = (
        <div>
          <ConfigurarCorreo />
        </div>
      );
    }

    let menuItem;
    if (rolAdministrador === "5") {
      rolAdmin = "ADMINISTRACIÓN GENERAL";
      menuItem = (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            onClick={(e) => this.tablaInicio()}
            icon={
              <OrderedListOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Tabla Empresas
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={(e) => this.registrarCliente()}
            icon={
              <SolutionOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Registrar Empresas
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={(e) => this.registraProductoServicio()}
            icon={
              <ReconciliationOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Registrar Productos y Servicios
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={(e) => this.listadoProductosYServicios()}
            icon={
              <FileSyncOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Lista de Productos y Servicios
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={(e) => this.generarCotizaciones()}
            icon={
              <DollarOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Generar Cotizacion
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={(e) => this.consultarCotizaciones()}
            icon={
              <FolderOpenOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de Cotizaciones
          </Menu.Item>
          <Menu.Item
            key="6"
            onClick={(e) => this.tablaEventos()}
            icon={
              <DesktopOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Eventos Evenbrite
          </Menu.Item>
          <Menu.Item
            key="7"
            onClick={(e) => this.carrito()}
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Registro de Venta
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={(e) => this.tablaVentas()}
            icon={
              <FilePdfOutlined style={{ fontSize: "25px", color: "#00000" }} />
            }
          >
            Historial Ventas
          </Menu.Item>
          <Menu.Item
            key="9"
            onClick={(e) => this.historialPolizas()}
            icon={
              <HistoryOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de pólizas
          </Menu.Item>
          <Menu.Item
            key="10"
            onClick={(e) => this.registrarVideo()}
            icon={
              <YoutubeOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            registrar Videos
          </Menu.Item>
          <Menu.Item
            key="11"
            onClick={(e) => this.historialInicioSesion()}
            icon={
              <RadiusSettingOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Inicio de sesión
          </Menu.Item>
          <Menu.Item
            key="12"
            onClick={(e) => this.indicadores()}
            icon={
              <DotChartOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Indicadores
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={(e) => this.support()}
            icon={
              <ClusterOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Soporte técnico
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={(e) => this.registerPolizas()}
            icon={
              <FileAddOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Alta de pólizas
          </Menu.Item>
          <Menu.Item
            key="15"
            onClick={(e) => this.configurarCorreo()}
            icon={<MailOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            Configurar correo
          </Menu.Item>
          <Menu.Item
            key="16"
            onClick={this.cerrar}
            icon={<CloseOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            cerrar sesión
          </Menu.Item>
        </Menu>
      );
    } else if (rolAdministrador === "15") {
      rolAdmin = "ADMINISTRACIÓN";
      menuItem = (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            onClick={(e) => this.tablaInicio()}
            icon={
              <OrderedListOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Tabla Empresas
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={(e) => this.registrarCliente()}
            icon={
              <SolutionOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Registrar Empresas
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={(e) => this.registraProductoServicio()}
            icon={
              <ReconciliationOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Registrar Productos y Servicios
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={(e) => this.listadoProductosYServicios()}
            icon={
              <FileSyncOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Lista de Productos y Servicios
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={(e) => this.generarCotizaciones()}
            icon={
              <DollarOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Generar Cotizacion
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={(e) => this.consultarCotizaciones()}
            icon={
              <FolderOpenOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de Cotizaciones
          </Menu.Item>
          <Menu.Item
            key="7"
            onClick={(e) => this.carrito()}
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Registro de Venta
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={(e) => this.tablaVentas()}
            icon={
              <FilePdfOutlined style={{ fontSize: "25px", color: "#00000" }} />
            }
          >
            Historial Ventas
          </Menu.Item>
          <Menu.Item
            key="9"
            onClick={(e) => this.historialPolizas()}
            icon={
              <HistoryOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de pólizas
          </Menu.Item>
          <Menu.Item
            key="11"
            onClick={(e) => this.historialInicioSesion()}
            icon={
              <RadiusSettingOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Inicio de sesión
          </Menu.Item>
          <Menu.Item
            key="12"
            onClick={(e) => this.indicadores()}
            icon={
              <DotChartOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Indicadores
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={(e) => this.support()}
            icon={
              <ClusterOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Soporte Tecnico
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={(e) => this.registerPolizas()}
            icon={
              <FileAddOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Alta Pólizas
          </Menu.Item>
          <Menu.Item
            key="15"
            onClick={(e) => this.configurarCorreo()}
            icon={<MailOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            Configurar correo
          </Menu.Item>
          <Menu.Item
            key="16"
            onClick={this.cerrar}
            icon={<CloseOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            cerrar sesión
          </Menu.Item>
        </Menu>
      );
    } else if (rolAdministrador === "25") {
      rolAdmin = "VENTAS";
      menuItem = (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            onClick={(e) => this.tablaInicio()}
            icon={
              <OrderedListOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Tabla Empresas
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={(e) => this.generarCotizaciones()}
            icon={
              <DollarOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Generar Cotizacion
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={(e) => this.consultarCotizaciones()}
            icon={
              <FolderOpenOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de Cotizaciones
          </Menu.Item>
          <Menu.Item
            key="7"
            onClick={(e) => this.carrito()}
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Registro de Venta
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={(e) => this.tablaVentas()}
            icon={
              <FilePdfOutlined style={{ fontSize: "25px", color: "#00000" }} />
            }
          >
            Historial Ventas
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={this.cerrar}
            icon={<CloseOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            cerrar sesión
          </Menu.Item>
        </Menu>
      );
    } else if (rolAdministrador === "35") {
      rolAdmin = "SOPORTE";
      menuItem = (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            onClick={(e) => this.tablaInicio()}
            icon={
              <OrderedListOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Tabla Empresas
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={(e) => this.consultarCotizaciones()}
            icon={
              <FolderOpenOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de Cotizaciones
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={(e) => this.tablaVentas()}
            icon={
              <FilePdfOutlined style={{ fontSize: "25px", color: "#00000" }} />
            }
          >
            Historial Ventas
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={this.cerrar}
            icon={<CloseOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            cerrar sesión
          </Menu.Item>
        </Menu>
      );
    } else if (rolAdministrador === "45") {
      rolAdmin = "SEGUIMIENTO";
      menuItem = (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            onClick={(e) => this.tablaInicio()}
            icon={
              <OrderedListOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Tabla Empresas
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={(e) => this.consultarCotizaciones()}
            icon={
              <FolderOpenOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de Cotizaciones
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={(e) => this.tablaVentas()}
            icon={
              <FilePdfOutlined style={{ fontSize: "25px", color: "#00000" }} />
            }
          >
            Historial Ventas
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={this.cerrar}
            icon={<CloseOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            cerrar sesión
          </Menu.Item>
        </Menu>
      );
    } else if (rolAdministrador === "55") {
      rolAdmin = "MANTENIMIENTO";
      menuItem = (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            onClick={(e) => this.tablaInicio()}
            icon={
              <OrderedListOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Tabla Empresas
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={(e) => this.registrarCliente()}
            icon={
              <SolutionOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Registrar Cliente
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={(e) => this.registraProductoServicio()}
            icon={
              <ReconciliationOutlined
                style={{ fontSize: "25px", color: "#fff" }}
              />
            }
          >
            Registrar Productos y Servicios
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={(e) => this.listadoProductosYServicios()}
            icon={
              <FileSyncOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Lista de Productos y Servicios
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={(e) => this.consultarCotizaciones()}
            icon={
              <FolderOpenOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Historial de Cotizaciones
          </Menu.Item>
          <Menu.Item
            key="6"
            onClick={(e) => this.tablaEventos()}
            icon={
              <DesktopOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            Eventos Evenbrite
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={(e) => this.tablaVentas()}
            icon={
              <FilePdfOutlined style={{ fontSize: "25px", color: "#00000" }} />
            }
          >
            Historial Ventas
          </Menu.Item>
          <Menu.Item
            key="10"
            onClick={(e) => this.registrarVideo()}
            icon={
              <YoutubeOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
          >
            registrar Videos
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={this.cerrar}
            icon={<CloseOutlined style={{ fontSize: "25px", color: "#fff" }} />}
          >
            cerrar sesión
          </Menu.Item>
        </Menu>
      );
    }

    let nombre = localStorage.getItem("nombre");
    let apellidos = localStorage.getItem("apellido");
    let empresa = localStorage.getItem("nombre_empresa");

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div style={{ marginTop: "6%" }}>
            <a>
              <img src={ADS} style={{ width: "60%", marginLeft: "15%" }} />
            </a>
          </div>
          <br></br>
          {menuItem}
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background11"
            style={{ padding: 0, color: "#fff" }}
          >
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
            <font color="#fff" size="4">
              {empresa}&nbsp;&nbsp;&nbsp;&nbsp;DIRECTORIO DE CLIENTES
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {nombre}&nbsp;{apellidos}{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              {
                <CalendarTodayIcon
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              }
              &nbsp;{fechaHoy} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{rolAdmin}
            </font>
          </Header>

          {tabla}
          {eventos}
          {cotizaciones}
          {tablaCotizaciones}
          {signupCliente}
          {signupProductoServicio}
          {carrito}
          {registrarVideo}
          {historialPolizas}
          {HistorialVentas}
          {historialInicioSesion}
          {indicadores}
          {listadoProductos}
          {support}
          {registerPolizas}
          {configuraCorreo}
        </Layout>
      </Layout>
    );
  }
}
export default SiderDemo;
