import React, { Component } from 'react';
import actionsAPI from './actionsAPI';
import { Button,Modal } from 'antd';
import MUIDataTable from "mui-datatables";
import Report_Cotizacion from './report';

class CotizacionesCourses extends Component {
    constructor(props) {
        super(props);
        

        this.state = { 
            cursos:[],
            cotizacion:[],
            open:false

         }

    }
    componentWillMount(){
        let {id_user} = this.props;

        actionsAPI.get_cotizaciones(id_user).then(response =>{
            let respuesta = response.data.data.get_cotizaciones;           
            this.setState({cursos:respuesta})
        }).catch(err=>{
            console.log("error",err)
            console.log("error",err.response)
        })
    }
    
    showDocument(param){
        this.setState({open:true})
        this.setState({report:true});
  
        let array = [];
        this.state.cursos.forEach(function(hero){
            if(hero.folio === param){
                array.push(hero)
            }            
        })
        this.setState({cotizacion:array})
  
    }
    onOk(){
        this.setState({open:false})
    }

    render() {
        const optionsa = {
            print:false,
            sort:false,
            viewColumns:false,
            download:false,
             filterType: 'checkbox',
             filter:false,
             select:false,
          
             selectableRowsHeader:false
           };
        let columns = ["#","Ver cotización","Curso","Folio","Precio total","Emisión documento","Fecha del curso","Tipo","Expositor","Imagen","Detalles"] 
        let data = this.state.cursos.map((rows,i)=>{
            let concepto;
            let detalles;
            if(rows.estatus_pago  === "Pagado"){
                concepto = <font color="rgb(16,47,91)" className="strong">{rows.concepto}</font>
                detalles = <font color="rgb(16,47,91)" className="strong">PAGO APLICADO</font>

            }else{
                detalles = <font color="orange">PENDIENTE DE APROBACIÓN</font>
                concepto = rows.concepto
            }
            let button;
            if(rows.estado === "Pendiente"){

                button =  <Button disabled type = "primary" onClick={e=> this.showDocument()}>Ver cotización</Button>
            } 
            if(rows.estado === "Aprobada"){
                button = <Button type = "primary" onClick={e=> this.showDocument(rows.folio)}>Ver cotización</Button>
            }
            return([i+1,button,concepto, rows.folio, rows.total_global, rows.fecha_emision,rows.fecha_curso,rows.tipo,rows.instructor, <img style={{width:150}} src={rows.imagen}/>,detalles])

        })
        let tabla = 
        <div style={{width:"83%",marginLeft:"24em",marginTop:"6em"}}>
            <MUIDataTable
            title={"Cotizaciones solicitadas"}
            data={data}
            columns={columns}
            options={optionsa}
            />
        </div>

            let report;

            if(this.state.cotizacion[0]){
                report = 
                <Modal onCancel={e=>this.onOk()} footer={null} open={this.state.open} title = "Reporte generado">
                    <Report_Cotizacion data = {this.state.cotizacion}/>
                </Modal>
            }
        return ( 
            <div>
                <center>
                    {tabla}
                    {report}
                </center>

            </div>
         );
    }
}
 
export default CotizacionesCourses;