import React, { Component } from 'react';
import './index.css';
import actionsAPI from '../actionsAPI';
import MUIDataTable from "mui-datatables";
import options from '../../administradorAlfa/tableMuiOptions';

import { Button, Input } from 'antd';
import swal from 'sweetalert';
class Formulario extends Component {
    constructor(props){
        super(props)
        this.state = {
            form: false,
            data:[],
            tabla:true,
            data_form:[],
            input1:'',
            input5:'',
            input6:'',
            input7:'',
            input8:'',
            input9:'',
            input10:'',
            textarea2:'',
            textarea3:'',
            folio:''

        }
        this.onChangeInput1 = this.onChangeInput1.bind(this)
        this.onChangeInput5 = this.onChangeInput5.bind(this)
        this.onChangeInput6 = this.onChangeInput6.bind(this)
        this.onChangeInput7 = this.onChangeInput7.bind(this)
        this.onChangeInput8 = this.onChangeInput8.bind(this)
        this.onChangeInput9 = this.onChangeInput9.bind(this)
        this.onChangeInput10 = this.onChangeInput10.bind(this)
        this.onChangeInputFolio = this.onChangeInputFolio.bind(this)


        this.onChangeTextarea2 = this.onChangeTextarea2.bind(this)
        this.onChangeTextarea3 = this.onChangeTextarea3.bind(this)

    }
  handleSubmit = (e) => {
    e.preventDefault();
    // Lógica de envío del formulario
  };
  componentWillMount(){
    actionsAPI.getCourses().then(response=>{
        this.setState({data:response.data.data.getCourses})
    }).catch(err=>{
        console.log("err",err)
    })
  }
  asignar_requisitos(rows){
    this.setState({ tabla: false });
    this.setState({ form: true });
    this.setState({ data_form: rows });
  }
  onChangeInput1(e){
    this.setState({ input1: e.target.value  });
  }

  onChangeInput5(e){
    this.setState({ input5: e.target.value  });
  }
  onChangeInput6(e){
    this.setState({ input6: e.target.value  });
  }
  onChangeInput7(e){
    this.setState({ input7: e.target.value  });
  }
  onChangeInput8(e){
    this.setState({ input8: e.target.value  });
  }
  onChangeInput9(e){
    this.setState({ input9: e.target.value  });
  }
  onChangeInput10(e){
    this.setState({ input10: e.target.value  });
  }
  onChangeTextarea1(e){
    this.setState({ textarea1: e.target.value  });
  }
  onChangeTextarea2(e){
    this.setState({ textarea2: e.target.value  });
  }
  onChangeTextarea3(e){
    this.setState({ textarea3: e.target.value  });
  }

  onChangeInputFolio(e){
    this.setState({ folio: e.target.value  });
  }
  submit(data){
    let input1 = this.state.input1.replace(/[\n\r]/g, '');;
    let input5 = this.state.input5.replace(/[\n\r]/g, '');;
    let input6 = this.state.input6.replace(/[\n\r]/g, '');;
    let input7 = this.state.input7.replace(/[\n\r]/g, '');;
    let input8 = this.state.input8.replace(/[\n\r]/g, '');;
    let input9 = this.state.input9.replace(/[\n\r]/g, '');;
    let input10 = this.state.input10.replace(/[\n\r]/g, '');;
    let inputFolio = this.state.folio.replace(/[\n\r]/g, '');;

    let textarea2 = this.state.textarea2.replace(/[\n\r]/g, '');;
    let textarea3 = this.state.textarea3.replace(/[\n\r]/g, '');;
    let id_course = data.id_courses

    if(inputFolio,input1,textarea2,textarea3){
        actionsAPI.registroSemblanza(inputFolio,input1,textarea2,textarea3,input5,input6,input7,input8,input9,input10,id_course).then(response=>{
            if(response.data.data.registroSemblanza.message === "semblanza registrada"){
                swal({
                    text:`Se registró correctamente`,               
                    icon:"success",
                });
                setTimeout(()=>{
                    window.location.reload();
                },2000)
            }
        }).catch(err=>{
            console.log(err)
        })
    }else{
        swal({
            text:`No deje espacios sin completar.`,               
            icon:"error",
          });
    }

   


  }

  render() {
    let require = <font className="strong" color="red">*</font>
    const columns = ["Curso","Imagen","Descripción","Fecha del curso", "Asignar requisitos"]
    const data = this.state.data.map(rows=>{ 
        let boton_requisitos = <Button type='primary' onClick = {e=>this.asignar_requisitos(rows)}>Asignar</Button>
        return([rows.concepto,<img width={150} src= {rows.imagen}/>,rows.descripcion,rows.fecha_curso,boton_requisitos])
    })
    let tabla;
    if(this.state.tabla === true){
        tabla = 
            <div style={{width:"90%"}}>
            <MUIDataTable  
            data={data} 
            columns={columns} 
            options={options} 
            />  
        </div>
    }
    
    let form;
    if(this.state.form === true){
        form = <div className="form-container">
        <h1>Registro de Semblanza</h1>
        <form onSubmit={this.handleSubmit} className="registro-requisitos-form">
        <label className='strong'>Curso: {this.state.data_form.concepto}</label>
        <img width={300} src={this.state.data_form.imagen}></img>
            <div className="form-group">
            <label htmlFor="primerTitulo">Folio{require}</label>
            <Input onChange={this.onChangeInputFolio} placeholder='Folio' required/>
          </div>
          <div className="form-group">
            <label htmlFor="primerTitulo">Titulo{require}</label>
            <Input onChange={this.onChangeInput1} placeholder='Ej. Evaluación del Control Interno'/>
          </div>

          <div className="form-group">
            <label htmlFor="segundoTitulo">Objetivo{require}</label>
            <textarea onChange={this.onChangeTextarea2} id="segundoTitulo" name="segundoTitulo" rows="4"/>
          </div>

          <div className="form-group">
            <label htmlFor="tercerTitulo">Perfil del Participante {require}</label>
            <textarea onChange={this.onChangeTextarea3} id="tercerTitulo" name="tercerTitulo" rows="4"/>
          </div>


          <div className="form-group">
            <label htmlFor="quintoTitulo">Temario</label>
            <Input onChange={this.onChangeInput5} placeholder='Ej. Idiomas'/>
            <Input onChange={this.onChangeInput6} placeholder='Ej.  Motivación y Disposición'/>
            <Input onChange={this.onChangeInput7} placeholder='Ej.  Motivación y Disposición'/>
            <Input onChange={this.onChangeInput8} placeholder='Ej.  Motivación y Disposición'/>
            <Input onChange={this.onChangeInput9} placeholder='Ej.  Motivación y Disposición'/>
            <Input onChange={this.onChangeInput10} placeholder='Ej.  Motivación y Disposición'/>
          </div>


         

          <button className="submit-button" onClick={e=>this.submit(this.state.data_form)}>Asignar Semblanza</button>
        </form>
      </div>
    }
    return (
      <div>
        {form}
        {tabla}
      </div>
    );
  }
}

export default Formulario;
