import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { Input, Card, Tag, Button as ButtonAnt } from "antd";
import "antd/dist/antd.css";
import { MDBCol, MDBRow, } from 'mdbreact';
import { PDFExport } from '@progress/kendo-react-pdf';
import logo from '../../imagen/logoImai.jpg'
import './index.css'





let date = new Date();
var meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
let dia = date.getDate()
let letraMesSubstring = date.getMonth() + 1
let numeroPosicionMes = parseInt(letraMesSubstring, 10)
let mes = meses[numeroPosicionMes - 1]
let año = date.getFullYear()

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "18px", width: 550, align: "right" }}>
      <MDBRow style={{ align: "right" }}>
          <img src={logo} alt="imagen" style={{ width: "40%", marginLeft: "-53%" }} />


      </MDBRow>
    </div>
  );
};

const { Search } = Input;

class PDF extends Component {
  pdfExportComponent
  constructor(props) {
    super(props);
    this.state = {

    }
  }

close2 = () =>{
    this.setState({ renderizadoDom: !this.state.renderizadoDom });
    this.setState({ inicio: true }) 
    this.setState({ modal: false })
    this.setState({ informacion: false })
   }

   ejecutarEnvio() {
     console.log("entra")
    this.pdfExportComponent.save()
  }

  render() {
    let semblanza = this.props.props[0]
    let curso =  this.props.array
    console.log("semblanza",semblanza)
    console.log("array",curso)

 let render;
 let dowloadPDF;
 let DataTitulo = curso.concepto
 let DataObjetivo;
 let DataPerfil;
 let DataTemario;
  let imagen;
     
 DataObjetivo= 
 <div>
    {semblanza.objetivo}
 </div>
 DataPerfil= 
 <div>
    {semblanza.perfil}
 </div>
 DataTemario =
 <div>
* {semblanza.temario1}<br/><br/>
* {semblanza.temario2}<br/><br/>
* {semblanza.temario3}<br/><br/>
* {semblanza.temario4}<br/><br/>
* {semblanza.temario5}<br/><br/>
* {semblanza.temario6}
 </div>

render=
<div style={{marginTop: "2%" }} >
          <Card style={{ padding: "1%" }}  extra={<div><ButtonAnt onClick={e=>this.close2()}>X</ButtonAnt>   <ButtonAnt type="primary" ghost onClick={(e) => { this.ejecutarEnvio() }}>Descargar</ButtonAnt>
          </div>}>
            <center>
              <MDBRow style={{ marginTop: "3%" }}>
              <MDBCol size="8"></MDBCol>
                <MDBCol size="3">
                  <img src={logo} alt="imagen" style={{ width: 250, marginLeft: "-350%",marginBottom:"18%" }} />
                </MDBCol>
              </MDBRow>         
            </center>
            <p className="fuente3">{DataTitulo}</p>
            <p className="fuente1">Objetivos:</p>
            <p>{DataObjetivo}</p>
            <p className="fuente1">Perfil del Participante:</p>
            <p>{DataPerfil}</p>
            <p className="fuente1">Temario:</p>
            <p>{DataTemario}</p>
          </Card>
        </div>

        dowloadPDF=
        <div>
          <div style={{ position: "absolute", left: "-5000px", top: 0 }}>

            <PDFExport
              paperSize="A4"
              margin={{ left: "10mm", right: "10mm", top: "5mm", bottom: "12mm" }}
              forcePageBreak=".page-break"
              fileName={"REPORTE" + ".pdf"}
              pageTemplate={PageTemplate}
              multiPage="true "
            //   landscape="true"
              ref={(component) => this.pdfExportComponent = component}
            >
            <div>
            <center>
                <MDBRow style={{ marginTop: "2%" }} >
                    <img src={logo} alt="imagen" style={{ width: 280 }} />
                    <br />
                </MDBRow>
            </center>  <br/>    <br/>        <br/>                
            <p className="textabla strong">{DataTitulo}</p>
            <p className="textabla strong">Objetivos:</p>
            <p className="textabla3 ">{DataObjetivo}</p>
            <p className="textabla strong">Perfil del Participante:</p>
            <p className="textabla3 ">{DataPerfil}</p>
            <p className="textabla strong">Temario:</p>
            <p className="textabla3 ">{DataTemario}</p>

              </div>
            </PDFExport>
          </div>
        </div>



    return (
      <React.Fragment>               
       {render}
       {dowloadPDF}
     </React.Fragment>
    );
  }
}
export default PDF;