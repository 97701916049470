    const options = {
    elevation: 0,
    filterType: "drowpdawn",
    responsive: "stacked",
    pagination: true,
    search: true,
    print: true,
    download: true,
    filter: true,
    caseSensitive: false,
    selectableRows: "none",
    viewColumns: false,
    textLabels: {
        body: {
        noMatch: "Lo sentimos, no se encontraron registros coincidentes",
        toolTip: " Ordenar",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        pagination: {
        next: "Página siguiente",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
        },
        toolbar: {
        search: "Buscar",
        downloadCsv: " Descargar CSV",
        print: "Imprimir ",
        viewColumns: "Ver columnas",
        filterTable: "Tabla de filtros",
        },
        filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESET",
        },
        viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
        text: "fila (s) seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
        },
    },
    };

    export default options;
