import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'antd/dist/antd.css';
import actionsAPI from "./actionsAPI";
import { Card,Button} from 'antd';
import {
  MDBMask,
  MDBView,
  MDBAnimation,
} from "mdbreact";
import "./index.css";
import swal from "sweetalert";
import ads from '../imagen/logo_color_azul.png'
class Login_Plataform extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            pass: '',
            isPasswordShown: false,
        }
      }
    componentWillMount(){
        localStorage.removeItem("id_users_plataform")
        localStorage.removeItem("nombre_plataform")
        localStorage.removeItem("apellidos_plataform")
        localStorage.removeItem("telefono_plataform")
        localStorage.removeItem("correo_plataform")
        localStorage.removeItem("perfil_completado")
        localStorage.removeItem("rfc")
        localStorage.removeItem("rs")
        localStorage.removeItem("telefono_empresa_plataform")
        localStorage.removeItem("cp_plataform")
        localStorage.removeItem("profesion_plataform")
        localStorage.removeItem("rangoEdad_plataform")
        localStorage.removeItem(
            "token_user_plataform"
            
        );
    }      

    onSubmitBtn(){
        actionsAPI.auth_user_plataform(this.state.user,this.state.pass).then(response=>{

            let dataUser = response.data.data.auth_user_plataform;
            console.log("dataUSer",dataUser)
            if(response.data.data.auth_user_plataform.message === "usuario encontrado"){
                localStorage.setItem("id_users_plataform",dataUser.id_users_plataform)
                localStorage.setItem("nombre_plataform",dataUser.nombre)
                localStorage.setItem("apellidos_plataform",dataUser.apellidos)
                localStorage.setItem("telefono_plataform",dataUser.telefono)
                localStorage.setItem("correo_plataform",dataUser.correo)
                localStorage.setItem("perfil_completado",dataUser.perfil_completado)
                localStorage.setItem("rfc",dataUser.rfc)
                localStorage.setItem("rs",dataUser.razonSocial)
                localStorage.setItem("telefono_empresa_plataform",dataUser.telefono_empresa)
                localStorage.setItem("cp_plataform",dataUser.cp)
                localStorage.setItem("profesion_plataform",dataUser.profesion)
                localStorage.setItem("rangoEdad_plataform",dataUser.rango_edad)
                localStorage.setItem(
                    "token_user_plataform",
                    response.data.data.auth_user_plataform.token
                );
                swal({
                    text:`Sesión iniciada`,               
                    icon:"success",
                });
               this.props.history.push("/newCourses");
                
            }
            else if(response.data.data.auth_user_plataform.message === "usuario no encontrado"){
                console.log("entro")
                swal({
                    text:`Usuario y contraseña incorrectos`,               
                    icon:"error",
                });
            }
        }).catch(err=>{
            console.log(err.response)
            console.log(err)
        })

    }
    onChangeInput =(e)=>{
    const {id,value} = e.target;
    this.setState({
        [id]:value
    })
    }

    render() {
    let titulo = <center><h4 className="h4tag strong">Inicia sesión</h4></center>
    return(
    <div>
     <div id="apppage" >  
      <MDBView>
        <MDBMask >
            
        <div style={{marginTop:"3%"}} className="center">
            <div className="rows">
            <MDBAnimation type="fadeInRight" delay=".3s">
            <Card title = {titulo} style={{width:"110%",padding:"3em"}}>
            <center className="strong">Inicia sesión para tener más beneficios <br/> que ofecemos en nuestros cursos</center>
                <br/><br/>
                <center>
                <img  src ={ads} style={{width:180,height:60}}/>
                </center>
                <br/><br/>
                <form onSubmit={this.onSubmitBtn}>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Usuario"                                  
                        id="user"
                        type="text"
                        name="user"
                        onChange={this.onChangeInput}
                        value={this.state.user}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Contraseña"                                  
                        id="pass"
                        type="password"
                        name="pass"
                        onChange={this.onChangeInput}
                        value={this.state.pass}
                    />
                    <div class="input-icon">
                    <i class="fa fa-key"></i>                  
                    </div>
                    </div>
                <div className="text-center mb-4 mt-5">
                    <Button
                    onClick={e=> this.onSubmitBtn()}
                    style={{backgroundColor:"rgb(24,46,88)"}}
                    className='btn-block z-depth-1 text-white'
                    size="sm"
                    >
                    Ingresar
                    </Button>
                </div>
                </form>
                <center>
                <font color="blue"><a href="/signup_plataform_curses">No tienes una cuenta?, Créala aquí</a></font>
                </center>
            </Card>
            </MDBAnimation>   
            </div>  
            </div>
        </MDBMask>
    </MDBView>
    </div>
    </div>
  )        
}
}

export default Login_Plataform;