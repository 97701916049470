import React, { Component } from 'react';
import Paper from "@material-ui/core/Paper";
import { MDBRow,MDBCol,MDBCard,MDBCardImage} from "mdbreact";
import Image from '../imagen/click.png' 
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import {Modal} from 'antd'
import './clientes.css'
import swal from 'sweetalert'

import { Form, Input, Button, Select,Card,Mentions } from 'antd';
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

class SoporteTecnico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardInicio:true,
            valuesSoporte:[],
            botonSoporte:false,
            porlizaActivaEncontrada:[],
            porlizaVencidaEncontrada:[],
            polizaInactivaEncontrada:[],
            isModalVisible:false,
            array:[],
            uuidSistema:'',
            botonDisabled:false,
            disabledInput:false,
            link:'',
            contabilidad:'',
            nominas:'',
            comercialPremium:'',
            comercialStart:'',
            bancos:'',
            factura:''

        }
    }
    formRef = React.createRef();
    onGenderChange = (value) => {
    };
    onFinish = (values) => {

    let polizas = this.state.porlizaActivaEncontrada
    const value1 = 'comercial';
    const value2 = 'Comercial';
    const value3 = 'COMERCIAL';

    const value4 = 'contabilidad';
    const value5 = 'Contabilidad';
    const value6 = 'CONTABILIDAD';

    const value7 = 'nominas';
    const value8 = 'Nominas';
    const value9 = 'NOMINAS';

    const value10 = 'bancos';
    const value11 = 'Bancos';
    const value12 = 'BANCOS';

    const value13 = 'factura';
    const value14 = 'Factura';
    const value15 = 'FACTURA';

    const polizaEncontradaComercial = polizas.filter(obj => Object.values(obj).some(val => val.includes(value1) || val.includes(value2) || val.includes(value3)));
    const polizaEncontradaContabilidad = polizas.filter(obj => Object.values(obj).some(val => val.includes(value4) || val.includes(value5) || val.includes(value6)));
    const polizaEncontradaNominas = polizas.filter(obj => Object.values(obj).some(val => val.includes(value7) || val.includes(value8) || val.includes(value9)));
    const polizaEncontradaBancos = polizas.filter(obj => Object.values(obj).some(val => val.includes(value10) || val.includes(value11) || val.includes(value12)));
    const polizaEncontradaFactura = polizas.filter(obj => Object.values(obj).some(val => val.includes(value13) || val.includes(value14) || val.includes(value15)));

    if(values.departamento === "contable"){
      if(polizaEncontradaContabilidad[0]){
        this.setState({botonDisabled:true})
        this.setState({disabledInput:true})
        this.setState({valuesSoporte:values} )
        this.setState({uuidSistema:"SC"})
        this.setState({link:"https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=1&onpage=livechatimagelink&title=Live+Chat+Direct+Link"})
        localStorage.setItem("accesoSoporte",true)
      }else{
        swal({
          title:"Aviso!",
          text:"Estimado cliente, no puede aceder a la consola de Contable ya que no cuenta con póliza vigente de la misma",
          buttons: false,
          icon:"error"
      }); 
      }
    }
    if(values.departamento === "comercial"){
      if(polizaEncontradaComercial[0]){
        this.setState({link:"https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=3&onpage=livechatimagelink&title=Live+Chat+Direct+Link"})
        this.setState({botonDisabled:true})
        this.setState({disabledInput:true})
        this.setState({valuesSoporte:values} )
        this.setState({uuidSistema:"SCPS"})

        localStorage.setItem("accesoSoporte",true)
      }else{
        swal({
          title:"Aviso!",
          text:"Estimado cliente, no puede aceder a la consola de Comercial ya que no cuenta con póliza vigente de la misma",
          buttons: false,
          icon:"error"
      });
      }
    }
    if(values.departamento === "nominas"){
      if(polizaEncontradaNominas[0]){
        this.setState({link:"https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=2&onpage=livechatimagelink&title=Live+Chat+Direct+Link"})
        this.setState({botonDisabled:true})
        this.setState({disabledInput:true})
        this.setState({valuesSoporte:values} )
        this.setState({botonSoporte:true})
        this.setState({uuidSistema:"SNO"})
        localStorage.setItem("accesoSoporte",true)
      }else{
        swal({
          title:"Aviso!",
          text:"Estimado cliente, no puede aceder a la consola de Nóminas ya que no cuenta con póliza vigente de la misma",
          buttons: false,
          icon:"error"
      });
      }
    }

    if(values.departamento === "bancos"){
      if(polizaEncontradaBancos[0]){
        this.setState({link:"https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=1&onpage=livechatimagelink&title=Live+Chat+Direct+Link"})
        this.setState({botonDisabled:true})
        this.setState({disabledInput:true})
        this.setState({valuesSoporte:values} )
        this.setState({botonSoporte:true})
        this.setState({uuidSistema:"BA"})
        localStorage.setItem("accesoSoporte",true)
      }else{
        swal({
          title:"Aviso!",
          text:"Estimado cliente, no puede aceder a la consola de Bancos ya que no cuenta con póliza vigente de la misma",
          buttons: false,
          icon:"error"
      });
      }
    }
    if(values.departamento === "factura"){
      if(polizaEncontradaFactura[0]){
        this.setState({link:"https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=3&onpage=livechatimagelink&title=Live+Chat+Direct+Link"})
        this.setState({botonDisabled:true})
        this.setState({disabledInput:true})
        this.setState({valuesSoporte:values} )
        this.setState({botonSoporte:true})
        this.setState({uuidSistema:"SFA"})
        localStorage.setItem("accesoSoporte",true)
      }else{
        swal({
          title:"Aviso!",
          text:"Estimado cliente, no puede aceder a la consola de Bancos ya que no cuenta con póliza vigente de la misma",
          buttons: false,
          icon:"error"
      });
      }
    }
    };
    

    componentWillMount(){
      let id_cliente = localStorage.getItem("id_cliente")
         axios({
          url:API,
          method:'post',
          data:{
              query:`
              query{
              getPoliza(data:"${[id_cliente]}"){
                   id_polizas
                   concepto
                   fechaInicial
                   fechaFinal
                   statusPoliza
                  } 
              }
              `
          }   
          }).then(response=>{  
            let array = response.data.data.getPoliza
            let porlizaActivaEncontrada = array.filter(function(hero){
              return hero.statusPoliza === "activa"
            })
            const searchSistemaContabilidad = 'Contabilidad';
            const searchSistemaContabilidad2 = 'CONTABILIDAD';
            const searchSistemaContabilidad3 = 'contabilidad';

            const searchSistemaNominas = 'Nominas';
            const searchSistemaNominas2 = 'Nóminas';
            const searchSistemaNominas3 = 'NOMINAS';
            const searchSistemaNominas4 = 'NÓMINAS';
            const searchSistemaNominas5 = 'nominas';
            const searchSistemaNominas6 = 'nóminas';
            const searchSistemaNominas7 = 'Nòminas';
            const searchSistemaNominas8 = 'NÒMINAS';

            const searchSistemaComercialPremium = 'Premium';
            const searchSistemaComercialPremium2 = 'PREMIUM';
            const searchSistemaComercialPremium3 = 'premium';
            const searchSistemaComercialPremium4 = 'Comercial';
            const searchSistemaComercialPremium5 = 'COMERCIAL';
            const searchSistemaComercialPremium6 = 'comercial';

            const searchSistemaComercialStart = 'Start';
            const searchSistemaComercialStart2 = 'START';
            const searchSistemaComercialStart3 = 'start';

            const searchSistemaBancos = 'Bancos';
            const searchSistemaBancos2 = 'BANCOS';
            const searchSistemaBancos3 = 'bancos';

            const searchSistemaFacrura = 'Factura';
            const searchSistemaFacrura1 = 'factura';
            const searchSistemaFacrura2 = 'FACTURA';
            const searchSistemaFacrura3 = 'Factura Electronica';
            const searchSistemaFacrura4 = 'FACTURA ELECTRONICA';
            const searchSistemaFacrura5 = 'Factura electronica';

            const contabilidad = porlizaActivaEncontrada.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaContabilidad) || val.includes(searchSistemaContabilidad2) || val.includes(searchSistemaContabilidad3)));
            const nominas = porlizaActivaEncontrada.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaNominas) || val.includes(searchSistemaNominas2) || val.includes(searchSistemaNominas3) || val.includes(searchSistemaNominas4) || val.includes(searchSistemaNominas5) || val.includes(searchSistemaNominas6)|| val.includes(searchSistemaNominas7)|| val.includes(searchSistemaNominas8)));
            const comercialPremium = porlizaActivaEncontrada.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaComercialPremium) || val.includes(searchSistemaComercialPremium2) || val.includes(searchSistemaComercialPremium3) || val.includes(searchSistemaComercialPremium4) || val.includes(searchSistemaComercialPremium5) || val.includes(searchSistemaComercialPremium6)));
            const comercialStart = porlizaActivaEncontrada.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaComercialStart) || val.includes(searchSistemaComercialStart2) || val.includes(searchSistemaComercialStart3)));
            const bancos = porlizaActivaEncontrada.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaBancos) || val.includes(searchSistemaBancos2) || val.includes(searchSistemaBancos3)));
            const factura = porlizaActivaEncontrada.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaFacrura) || val.includes(searchSistemaFacrura1) || val.includes(searchSistemaFacrura2)|| val.includes(searchSistemaFacrura3) || val.includes(searchSistemaFacrura4)|| val.includes(searchSistemaFacrura5)));

            if(contabilidad[0]){
              this.setState({contabilidad:"Contabilidad"})
            }if(nominas[0]){
              this.setState({nominas:"Nóminas"})
            }if(comercialPremium[0]){
              this.setState({comercialPremium:"Comercial"})
            }if(comercialStart[0]){
              this.setState({comercialStart:"Comercial Start"})
            }if(bancos[0]){
              this.setState({bancos:"Bancos"})
            }
            if(factura[0]){
              this.setState({factura:"Factura"})
            }
  
            let porlizaVencidaEncontrada = array.filter(function(hero){
              return hero.statusPoliza === "vencida"
            })
            let polizaInactivaEncontrada = array.filter(function(hero){
              return hero.statusPoliza === "inactiva"
            })
            this.setState({array:array})
            this.setState({porlizaActivaEncontrada:porlizaActivaEncontrada})
            this.setState({porlizaVencidaEncontrada:porlizaVencidaEncontrada})
            this.setState({polizaInactivaEncontrada:polizaInactivaEncontrada})

          }).catch(err=>{
              console.log('error',err.response)    
          })  
    }
    evaluar(){
        let date = new Date()
        let fk_empresa =  localStorage.getItem("fk_empresa")
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        let values =  this.state.valuesSoporte;
        let fecha = day + "/" + month + "/" + year + " " + time
        let asunto = values.asunto
        let noFactura = "No disponible";
        let departamento = values.departamento
        let id_cliente = localStorage.getItem("id_cliente")
        let id_contacto = localStorage.getItem("id_contacto")
        let idTeamviewer = values.idAsociado
        let passTeamviewer = values.contrasenaAsociado
        let telefono = values.telefono
        var timeUUID = date.getHours()+"" + date.getMinutes()+ "" + date.getSeconds();
        let fechaUUID = day +""+ month+ "" + year + "" + timeUUID
        console.log("values",values)
            function generateUUID() {
                var d = new Date().getTime();
                var uuid = 'xAxxyx'.replace(/[xy]/g, function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            }
      let folio = (this.state.uuidSistema + fechaUUID + generateUUID() + "L").toUpperCase() ;     
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                mutation{
                registerSupport(data:"${[fecha,departamento,noFactura,asunto,id_cliente,idTeamviewer,passTeamviewer,folio,fk_empresa,id_contacto,telefono]}"){   
                 message
                } 
                }
                `
            }   
            })
            .then(response=>{ 
              setTimeout(()=>{
                swal({
                  title:"Aviso!",
                  text:"Consola Activa",
                  buttons: false,
                  icon:"success"
              }); 
              },1000)
            })
            .catch(err=>{
             console.log('error',err.response)
             console.log('error',err)
            }) 
            this.setState({cardInicio:true})
            this.setState({botonSoporte:false})        
    }
    showModal(){
      this.setState({isModalVisible:true})
    }
    handleOk(){
      this.setState({isModalVisible:false})
    }
    cerrar(){
      this.setState({disabledInput:false})
      this.setState({botonDisabled:false})
      this.setState({disabledInput:false})
      this.setState({botonSoporte:false})
    }
  
    render() { 
        let correo  = localStorage.getItem("correo")
        let telefono  = localStorage.getItem("telefono")
        let rs  = localStorage.getItem("razonSocial")
        let nombre =  localStorage.getItem("nombreRepresantante") +  " " + localStorage.getItem("apellidosRepresantante")
        let cardInicio;
        let status;
        let titulo = <h6><strong>Soporte técnico</strong></h6>
        let modal

        let buttonSupport;
        let cancelar;
        if(this.state.botonDisabled === true){
          cancelar = <Button shape='circle' size='large' type='warning' onClick ={e=>this.cerrar()}><i class="fas fa-solid fa-pen"></i></Button>
          buttonSupport = <Button block type="success">   
          <a href={this.state.link} target="_blank" onClick = {e=>this.evaluar()}>Acceder a consola de soporte</a>
         </Button>
        }
        if(this.state.botonDisabled === false){
          buttonSupport = <Button block type="primary" htmlType="submit">
                        Siguiente
                    </Button>
        }
        if(this.state.cardInicio === true && this.state.porlizaActivaEncontrada[0]) {
          let option1;
          let option2;
          let option3;
          let option4;
          let option5;
          let option6;
          if(this.state.comercialPremium){
            option1 =  <Option value="comercial">{this.state.comercialPremium}</Option>
          }
          if(this.state.comercialStart){
            option2 = <Option value="comercial start">{this.state.comercialStart}</Option>
          }
          if(this.state.contabilidad){
            option3 = <Option value="contable">{this.state.contabilidad}</Option>
          }
          if(this.state.nominas){
            option4 = <Option value="nominas">{this.state.nominas}</Option>
          }
          if(this.state.bancos){
            option5 = <Option value="bancos">{this.state.bancos}</Option>
          }
          if(this.state.factura){
            option6 = <Option value="factura">{this.state.factura}</Option>
          }

          status = <h6><strong><font color="blue">Póliza de soporte activa</font></strong></h6>
          cardInicio =   
          <Card style = {{marginTop:"1%"}}title = {titulo} extra = {status}>
          <center>
          <Form style = {{width:"65%",marginLeft:"15%"}} className="text-left" {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                    <strong><font color="red">PASO 1 DE 2</font></strong>
                    <br/>
                    <br/>
                    <strong>Departamento <font color="red">*</font></strong>
                    <Form.Item
                    disabled = {this.state.disabledInput}
                    name="departamento"
                    rules={[
                        {
                        required: true,
                        },
                    ]}
                    >
                    <Select
                        disabled = {this.state.disabledInput}
                        placeholder="Seleccione el departamento"
                        onChange={this.onGenderChange}
                        allowClear
                    >
                       {option1}
                       {option2}
                       {option3}
                       {option4}
                       {option5}
                       {option6}
                    </Select>
                    </Form.Item>
                    <strong>Teléfono de contacto<font color="red">*</font></strong><br/><br/>
                    <Form.Item rules={[
                        {
                        required: true,
                        },
                    ]} name="telefono">
                      <Input  disabled = {this.state.disabledInput} placeholder='Teléfono'/>
                    </Form.Item>
                    <strong>Asunto <font color="red">*</font></strong>
                    <Form.Item
                        name="asunto"  
                        wrapperCol={{ span: 16 }}
                        rules={[{ required: true }]}
                      >
                        <Mentions disabled = {this.state.disabledInput} rows={3} placeholder="Dejenos saber como podemos ayudarle">
                    
                        </Mentions>
                    </Form.Item>
                    <Card type="inner"style={{width:"67%", marginTop:"2%"}} title={ <strong>Teamviewer o software de acceso</strong>}>
                    <strong>Id Asociado <font color="red">*</font></strong>
                    <Form.Item rules={[
                        {
                        required: true,
                        },
                    ]} name="idAsociado">
                      <Input  disabled = {this.state.disabledInput} placeholder='Id de asociado'/>
                    </Form.Item>
                    <strong>Contraseña <font color="red">*</font></strong>
                    <Form.Item    rules={[
                        {
                        required: true,
                        },
                    ]} name="contrasenaAsociado">
                    <Input disabled = {this.state.disabledInput} placeholder='Contraseña'/>
                    </Form.Item>
                   
                    {cancelar}
                    </Card>      
                  <Form.Item>
                    
                    {buttonSupport}
                  </Form.Item>
          </Form>
          </center>   
          </Card>
        }else if(this.state.cardInicio === true && this.state.porlizaVencidaEncontrada[0]){
          status = <h6><strong><font color="red">Póliza de soporte vencida</font></strong></h6>
          modal =  <Modal okText="Aceptar" cancelText="Cancelar" title="Póliza de servicio no encontrada" visible={this.state.isModalVisible} onCancel={e=> this.handleOk()} onOk={e=>this.handleOk()}>
          <h6><font color="red">Estimado cliente su póliza de servicio ha expirado, por favor contacte a su asesor de ADS para su proceso de renovación</font></h6>
          </Modal>
          cardInicio =   
          <Card style = {{marginTop:"1%"}}title = {titulo}  extra = {status}>
          <center>
          <Form style = {{width:"60%",marginLeft:"15%"}} className="text-left" {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                    <strong>Nombre</strong>
                    <Form.Item name="nombre">
                    <Input defaultValue = {nombre} disabled/>
                    </Form.Item>
                    <strong>Razón social</strong>
                    <Form.Item name="rs">
                      <Input defaultValue = {rs} disabled />
                    </Form.Item>
                    <strong>Teléfono</strong>
                    <Form.Item name="telefono">
                     <Input defaultValue = {telefono} disabled/>
                    </Form.Item>
                    <strong>Correo</strong>
                    <Form.Item name="correo" >
                      <Input defaultValue = {correo} disabled />
                    </Form.Item>
                    <strong>Departamento <font color="red">*</font></strong>
                    <Form.Item
                    name="departamento"
                    rules={[{required: true}]}>
                    <Select
                        disabled
                        placeholder="Seleccione el departamento"
                        onChange={this.onGenderChange}
                        allowClear
                    >
                        <Option value="comercial">Comercial</Option>
                        <Option value="contable">Contable</Option>
                        <Option value="nominas">Nóminas</Option>
                    </Select>
                    </Form.Item>
                    <strong>Asunto <font color="red">*</font></strong>
                    <Form.Item
                        name="asunto"  
                        wrapperCol={{ span: 16 }}
                        rules={[{ required: true }]}
                      >
                        <Mentions disabled rows={3} placeholder="Dejenos saber como podemos ayudarle">
                        </Mentions>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                  </Form.Item>
                  <Form.Item>
                    <Button  block type="danger" onClick={e=>this.showModal()}>
                        Aceptar
                    </Button>
                    </Form.Item>
          </Form>
          </center>   
          </Card>
        }else if(this.state.cardInicio === true && this.state.polizaInactivaEncontrada[0]){
           status = <h6><strong><font color="#9E0A75">Póliza de soporte NO activada</font></strong></h6>
            modal =  <Modal okText="Aceptar" cancelText="Cancelar" title="Póliza de servicio no encontrada" visible={this.state.isModalVisible} onCancel={e=> this.handleOk()} onOk={e=>this.handleOk()}>
            <h6><font color="#9E0A75">Estimado cliente su póliza de servicio aún no ha sido activada, contacte a su asesor de ADS para empezar a utilzarla</font></h6>
            </Modal>
            cardInicio =   
            <Card style = {{marginTop:"1%"}}title = {titulo}  extra = {status}>
            <center>
            <Form style = {{width:"60%",marginLeft:"15%"}} className="text-left" {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                      <strong>Nombre</strong>
                      <Form.Item name="nombre">
                      <Input defaultValue = {nombre} disabled/>
                      </Form.Item>
                      <strong>Razón social</strong>
                      <Form.Item name="rs">
                        <Input defaultValue = {rs} disabled />
                      </Form.Item>
                      <strong>Teléfono</strong>
                      <Form.Item name="telefono">
                       <Input defaultValue = {telefono} disabled/>
                      </Form.Item>
                      <strong>Correo</strong>
                      <Form.Item name="correo" >
                        <Input defaultValue = {correo} disabled />
                      </Form.Item>
                      <strong>Departamento <font color="red">*</font></strong>
                      <Form.Item
                      name="departamento"
                      rules={[
                          {
                          required: true,
                          },
                      ]}
                      >
                      <Select
                          disabled
                          placeholder="Seleccione el departamento"
                          onChange={this.onGenderChange}
                          allowClear
                      >
                          <Option value="comercial">Comercial</Option>
                          <Option value="contable">Contable</Option>
                          <Option value="nominas">Nóminas</Option>
                      </Select>
                      </Form.Item>
                      <strong>Asunto <font color="red">*</font></strong>
                      <Form.Item
                          name="asunto"  
                          wrapperCol={{ span: 16 }}
                          rules={[{ required: true }]}
                        >
                          <Mentions disabled rows={3} placeholder="Dejenos saber como podemos ayudarle">
                          </Mentions>
                      </Form.Item>
                      <Form.Item {...tailLayout}>
                    </Form.Item>
                    <Form.Item>
                      <Button className = 'text-white' block style={{backgroundColor:"#098B87"}} onClick={e=>this.showModal()}>
                          Aceptar
                      </Button>
                      </Form.Item>
            </Form>
            </center>   
            </Card>
        }else if(!this.state.array[0]){
             status = <h6><strong><font color="#9E0A75">Póliza de soporte no encontrada</font></strong></h6>
             modal =  <Modal okText="Aceptar" cancelText="Cancelar" title="Póliza de servicio no encontrada" visible={this.state.isModalVisible} onCancel={e=> this.handleOk()} onOk={e=>this.handleOk()}>
             <h6><font color="#9E0A75">Estimado cliente usted no cuenta con una póliza de soporte, contacte a su asesor de ADS para adquirirla y acceder a los beneficios.</font></h6>
             </Modal>
             cardInicio =   
             <Card style = {{marginTop:"1%"}}title = {titulo}  extra = {status}>
             <center>
             <Form style = {{width:"60%",marginLeft:"15%"}} className="text-left" {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                       <strong>Nombre</strong>
                       <Form.Item name="nombre">
                       <Input defaultValue = {nombre} disabled/>
                       </Form.Item>
                       <strong>Razón social</strong>
                       <Form.Item name="rs">
                         <Input defaultValue = {rs} disabled />
                       </Form.Item>
                       <strong>Teléfono</strong>
                       <Form.Item name="telefono">
                        <Input defaultValue = {telefono} disabled/>
                       </Form.Item>
                       <strong>Correo</strong>
                       <Form.Item name="correo" >
                         <Input defaultValue = {correo} disabled />
                       </Form.Item>
                       <strong>Departamento <font color="red">*</font></strong>
                       <Form.Item
                       name="departamento"
                       rules={[
                           {
                           required: true,
                           },
                       ]}
                       >
                       <Select
                           disabled
                           placeholder="Seleccione el departamento"
                           onChange={this.onGenderChange}
                           allowClear
                       >
                           <Option value="comercial">Comercial</Option>
                           <Option value="contable">Contabilidad</Option>
                           <Option value="nominas">Nóminas</Option>
                       </Select>
                       </Form.Item>
                       <strong>Asunto <font color="red">*</font></strong>
                       <Form.Item
                           name="asunto"  
                           wrapperCol={{ span: 16 }}
                           rules={[{ required: true }]}
                         >
                           <Mentions disabled rows={3} placeholder="Dejenos saber como podemos ayudarle">
                           </Mentions>
                       </Form.Item>
                       <Form.Item {...tailLayout}>
                     </Form.Item>
                     <Form.Item>
                       <Button className = 'text-white' block style={{backgroundColor:"#9E0A75"}} onClick={e=>this.showModal()}>
                           Aceptar
                       </Button>
                       </Form.Item>
             </Form>
             </center>   
             </Card>
        }

        let cardSoporte;
        if(this.state.botonSoporte === true){
            cardSoporte =  <Card title = {titulo} style={{marginTop:"2%"}} extra = {<div><Button danger htmlType="submit" onClick = {e=>window.location.reload()}> Cancelar</Button></div>}>
            <Paper>
                <MDBRow>
                <MDBCol size="7">
                    <MDBCard style={{ width: "40rem"}}>
                    <MDBCardImage
                        className="img-fluid"
                        src="https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                        waves
                    />
                    </MDBCard>
                </MDBCol>
                <MDBCol size="5" style={{ width: "2rem"}}>
                <center>    
                <a href="https://t2.phplivesupport.com/wwwadscommx/phplive.php?d=0&onpage=livechatimagelink&title=Live+Chat+Image+Link" target="_blank" onClick = {e=>this.evaluar()}><img style = {{width:200 }}src="https://t2.phplivesupport.com/wwwadscommx/ajax/image.php?d=0" border="7" alt="Live Chat" title="Live Chat"/  ></a>
                <img style={{ width: "3rem",marginTop:"30%"}} src = {Image}></img>
                </center>
               
                </MDBCol>
                </MDBRow>
            </Paper>
            </Card>
        }

        

        return ( 
        <div>
           {modal}
           {cardInicio}
           {/* {cardSoporte} */}
         
        </div> );
    }
}


 
export default SoporteTecnico ;