import React, { Component } from 'react'
import axios from 'axios'
import { API } from '../Graphql/Graphql';
import MUIDataTable from "mui-datatables";
import {Button,Card, Modal,Input} from 'antd'
import {MDBIcon} from 'mdbreact'
import swal from 'sweetalert'


class MisSoportes extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            response:[],
            visible:false,
            detailsSopoerte:[],
            value:'',
            value2:''
         }
         this.onOk = this.onOk.bind(this)
         this.onCancel = this.onCancel.bind(this)

         this.onChange = this.onChange.bind(this)
         this.onChange2 = this.onChange2.bind(this)

    }
   
    onCancel(){
      this.setState({visible:false})
    }

    componentWillMount(){
        let id_cliente = localStorage.getItem("id_cliente")
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                getSoporte(data:"${[id_cliente]}"){   
                    fk_cliente
                    id_soporte
                    fk_contacto
                    fechaSoporte
                    consola
                    numeroPoliza
                    asunto
                    idTeamviewer

                    passTeamviewer
                    folio
                    status
                    ejecutivo
                    rfc
                    razonSocial
                    fechaFinalizacion
                    statusEncuesta
                } 
                }
                `
            }   
            })
            .then(response=>{
              let data = response.data.data.getSoporte
              let dataSort = data.sort(function(a, b) {
                return parseFloat(b.id_soporte) - parseFloat(a.id_soporte);
              });
            
              this.setState({response:dataSort}) 
            })
            .catch(err=>{
                    console.log('error',err.response)
            })  
    }
    openModal(param){
      this.setState({visible:true});
      this.setState({detailsSopoerte:param})
    }
    onOk(param){
      let id_soporte = param.id_soporte
      let value1 =  this.state.value
      let value2 =  this.state.value2

      axios({
        url:API,
        method:'post',
        data:{
            query:`
            mutation{
            updateSoporte(data:"${[id_soporte,value1,value2]}"){   
                message
            } 
            }
            `
        }   
        })
        .then(response=>{
          let ows = response.data.data.updateSoporte.message

          if(ows === "Se actualizaron los datos"){
            swal({
              title:"Aviso!",
              text:"Se actualizaron los datos",
              buttons: false,
              icon:"success"
          });
          }else{
            swal({
              title:"Aviso!",
              text:"Ocurrio un error, inténtelo de nuevo",
              buttons: false,
              icon:"error"
          });
          }
        })
        .catch(err=>{
          console.log('error',err.response)
        }) 
    }
    onChange(e){
      this.setState({value:e.target.value})
    }
    onChange2(e){
      this.setState({value2:e.target.value})
    }
    render() { 
      const columns = [ {
        name: "ID soporte",
        options: {
          display: false,
        }
      },"Fecha de solicitud","Folio","Consola","Ejecutivo","ID Acceso","Contraseña","Status","Editar"];
      let data =  this.state.response.map(rows=>{  
       let boton;
       if(rows.status === "Pendiente"){
         boton = <Button type="warning" onClick= {e=>{this.openModal(rows)}}><MDBIcon fas icon="pen" /></Button>
       }else if(rows.status === "Finalizado"){
        boton = <strong><font color = "red">No disponible</font></strong>
       }
      return( [ rows.id_soporte,rows.fechaSoporte,rows.folio,rows.consola, rows.ejecutivo, rows.id_soporte,rows.passTeamviewer,rows.status,boton])
    })
        const options={ 
            filterType:"drowpdawn",
            responsive: "stacked",
            print:false,
            download:false,
            filter:true,
            caseSensitive:false,
            selectableRows:"none",
            elevation:0,
            viewColumns:false,  
            search:true,    
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar evento",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          
          }        
        }
        let tablaInicio;
        if(this.state.response[0]){
          tablaInicio = 
          <Card title={<div><center><h6><strong>Listado de soportes solicitados</strong></h6></center></div>}>
              <MUIDataTable  
                data={data}
                columns={columns} 
                options={options} 
                  
              /> 
          </Card>
        }
        let modal;
        if(this.state.visible === true){
          let param = this.state.detailsSopoerte
          modal = <Modal title={<strong>Editar datos de acceso</strong>} visible = {this.state.visible} onOk={e=>this.onOk(param)} onCancel={this.onCancel}>
            <table className='table table-small tabel table-bordered table table-striped'>
              <tr>
                <td>Asunto</td>
                <td>{param.asunto}</td>
              </tr>
              <tr>
                <td>Consola</td>
                <td>{param.consola}</td>
              </tr>
              <tr>
                <td>Fecha de solicitud</td>
                <td>{param.fechaSoporte}</td>
              </tr>
              <tr>
                <td>Folio</td>
                <td>{param.folio}</td>
              </tr>
              <tr>
                <td>ID de Teamviewer</td>
                <td><Input onChange = {this.onChange} type="text" value = {this.state.value} placeholder = {"Id proporcionado: " + param.idTeamviewer}></Input></td>
              </tr>
              <tr>
                <td>Contraseña</td>
                <td><Input onChange = {this.onChange2} value = {this.state.value2} placeholder = {"Contraseña: " + param.passTeamviewer}></Input></td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{param.status}</td>
              </tr>
            </table>
          </Modal>
        }
        return ( 
            <div>
                {modal}
                {tablaInicio}
            </div>
         );
    }
}
 
export default MisSoportes ;