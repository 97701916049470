import React, { Component } from 'react';
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import {Card,Button} from  'antd'
import MUIDataTable from "mui-datatables";
import { MDBIcon, MDBBtn } from 'mdbreact';
import Report from './Report'
import { Modal, Tag, Menu, Dropdown, message, Space, Input, DatePicker, Popconfirm, Spin, Switch, Alert } from 'antd';
import { DownOutlined, SettingOutlined,TagOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom'

import { Timeline,Progress  } from 'antd';
import './clientes.css'
import { ToastComponent } from '@syncfusion/ej2-react-notifications';


class Cotizaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cotizacionesPorCliente:[],
            cotizaciones:[],
            fechaExpiracion: '',
            productoServicioByFolio:[],
            totales:[],
            dataContacto:[],
            tablaInicial:false,
            renderPDF:false,
            datosCliente:[],
            adminAlfa:[],
            peticionCotizaciones:[],
            card:true,
            statusPoliza:'',
            tipoPoliza:'',
            poliza:[],
            showText:false,
            porlizaActivaEncontrada:[],
            porlizaVencidaEncontrada:[],
            isModalVisible:false,
            isModalVisible2:false,


          }
    }

    componentWillMount = async()=>{
        let id_cliente = localStorage.getItem("id_cliente")
        await axios({
          url:API,
          method:'post',
          data:{
              query:`
              query{
              getPoliza(data:"${[id_cliente]}"){
                   id_polizas
                   concepto
                   fechaInicial
                   fechaFinal
                   statusPoliza
                  } 
              }
              `
          }   
          }).then(response=>{  
            let array = response.data.data.getPoliza
            if (!array[0]){
              console.log("array",array)
            }
            let porlizaActivaEncontrada = array.filter(function(hero){
              return hero.statusPoliza === "activa"
            })
            let porlizaVencidaEncontrada = array.filter(function(hero){
              return hero.statusPoliza === "vencida"
            })
            let polizaInactivaEncontrada = array.filter(function(hero){
              return hero.statusPoliza === "inactiva"
            })

            this.setState({porlizaActivaEncontrada:porlizaActivaEncontrada})
            this.setState({porlizaVencidaEncontrada:porlizaVencidaEncontrada})
            this.setState({polizaInactivaEncontrada:polizaInactivaEncontrada})
            if(array[array.length - 1]){
              this.setState({tablaInicial:false})
              this.setState({poliza:array})
            }else{             
              this.setState({tablaInicial:true})              
            }
          }).catch(err=>{
              console.log('error',err.response)    
          })  
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                  getIdCotizacion(data:"${[id_cliente]}"){   
                    id_cotizaciones
                    fechaEmision
                    NumFolio
                    promocion
                    cantidad
                    descuento
                    descuentoAplicado
                    TotalPrecioProducto
                    statusCotizacion 
                    subtotal
                    iva
                    total
                    empresa
                    rfc
                    fk_cliente
                    tipo
                    concepto
                    message
                    fk_contacto
                    fk_adminalfa
                    fechaExpiracion
                  } 
                }
                `
            }   
             })
           .then(response=>{           
             let cotizaciones = response.data.data.getIdCotizacion 
             this.setState({cotizaciones:cotizaciones})   
             function getUniqueListBy(arr, key) {
                return [...new Map(arr.map(item => [item[key], item])).values()]
               }            
            const arr1 = getUniqueListBy(cotizaciones, 'NumFolio')            
            this.setState({cotizacionesPorCliente:arr1})  
        })
         .catch(err=>{
                  console.log('error',err.response)
          })           
        }
      
      componentDidMount(){
        setTimeout(() => {
          this.setState({ showText: true })
        }, 4000)
      }  
      toastCreated() {
          this.toastInstance.show();
      }
    pdfView = async(folios) => {
       
        let folio = folios.NumFolio
        let fechaExpiracion = folios.fechaExpiracion
        let id_contacto = folios.fk_contacto; 
        this.setState({fechaExpiracion:fechaExpiracion})
        let idAdminAlfa =  this.state.cotizacionesPorCliente[0].fk_adminalfa
        let filter = this.state.peticionCotizaciones.filter(function(hero){
          return hero.NumFolio === folio      
        })  
        this.setState({fechaExpiracion:fechaExpiracion})
        this.setState({dataPdf:filter}) 
    
        await axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                  getCotizacionesFolio(data:"${[folio]}"){                   
                    id_cotizaciones
                    fechaEmision
                    NumFolio
                    cantidad
                    descuento
                    descuentoAplicado
                    TotalPrecioProducto
                    statusCotizacion
                    fk_cliente   
                    fk_adminalfa
                    fk_empresa
                    fechaExpiracion
                    vigencia
                    fk_contacto
                    tipoSolicitud
                   id_productoServicio
                   tipo
                   tipoLicenciamiento
                   lineaProducto
                   concepto
                   precio
                   consecutivo
                   id_actualizacion
                   asignacion
                   fechaRegistro
                   message
                } 
                }
                `
            }   
            })
            .then(response=>{
              console.log("esto es response de getCotizacionesFolio",response)
               this.setState({productoServicioByFolio:response.data.data.getCotizacionesFolio})
            })
            .catch(err=>{
                    console.log('error de getProductoServicioByFolio',err.response)
            }) 
             axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                    getTotalesByFolio(data:"${[folio]}"){   
                        subtotal
                        iva
                        total
                        message
                    } 
                    }
                    `
                }   
                })
                .then(response=>{
                let totales =  response.data.data.getTotalesByFolio
                function getUniqueListBy(arr, key) {
                return [...new Map(arr.map(item => [item[key], item])).values()]
                }
            
                const array1 = getUniqueListBy(totales, 'NumFolio')
        
                this.setState({totales:array1[0]})
                // console.log("estado", this.state.totales)
                })
            .catch(err=>{
                console.log('error',err.response)
            }) 
    
            await axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                    getCotizacionFk_Contactos(data:"${[id_contacto]}"){   
                        id_contacto
                        nombre
                        apellidos
                        correo1
                        correo2
                        telefono1
                        extensionTelefonica
                        telefono2
                        puesto
                        fk_clientesads
                    } 
                    }
                    `
                }   
                })
                .then(response=>{
                this.setState({dataContacto:response.data.data.getCotizacionFk_Contactos})
        
                })
              await axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                    getAdminAlfa(data:"${[idAdminAlfa]}"){   
                        nombre
                        correo
                        apellido
                        telefono
                        extensionTelefonica
                        celular
                        puesto
                    } 
                    }
                    `
                }   
                })
                .then(response=>{
                  this.setState({adminAlfa:response.data.data.getAdminAlfa[0]})
                })
                .catch(err=>{
                        console.log('error',err.response)
                })   
            .catch(err=>{
            console.log('error',err)
   
            }) 
        this.setState({tablaInicial:false})
        this.setState({renderPDF:true})
        }    
        consultarCotizaciones (NumFolio)  {

          let filter = this.state.cotizaciones.filter(function(hero){
            return hero.NumFolio === NumFolio
          })
          
          this.datosIndividuales(filter[0].fk_cliente,2)
          if(this.state.cotizacionesPorCliente[0]){
            let filtrado = this.state.cotizacionesPorCliente.filter(function(data){
              return data.NumFolio === NumFolio
            })
            let fechaExpiracion = filtrado[0].fechaExpiracion;
            let objeto = {};
            objeto.NumFolio = NumFolio
            objeto.fk_contacto = filter[0].fk_contacto
            objeto.fechaExpiracion = fechaExpiracion
            this.pdfView(objeto)
          }
          
        }
      mostrarCotizaciones(){
        this.setState({tablaInicial:true})
        this.setState({card:false})
      }
      cerrarCotizaciones(){
        this.setState({tablaInicial:false})
        this.setState({card:true})
      }
      showModal = () => {
        this.setState({isModalVisible:true});
      };
    
      handleOk = () => {
        this.setState({isModalVisible:false});
      };
      showModal2 = () => {
        this.setState({isModalVisible2:true});
      };
    
      handleOk2 = () => {
        this.setState({isModalVisible2:false});
      };

    render() { 
      let {cuponPolizaActiva,cuponPolizaVencida,history}  = this.props
      const options={ 
        filterType:"drowpdawn",
        responsive: "stacked",
        print:false,
        download:false,
        filter:false,
        caseSensitive:false,
        selectableRows:"none",
        elevation:0,
        viewColumns:false,  
        search:false,    
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      }        
      } 
            
      const array= this.state.poliza       
      const { showText } = this.state
      let modalPolizaActiva;
      let modalPolizaVencida;
      let b = 0;
      let descuento;
      let toastDescuento;
      if(this.state.porlizaActivaEncontrada[0]){
        if(cuponPolizaActiva[0]){
          toastDescuento = <div>
            <div id='#toast_target'/>
            <ToastComponent id='toast_target' ref={toast => this.toastInstance = toast} title="Cupones disponibles" content="Estimado cliente usted cuenta con cupones disponibles para eventos, mas info en el botón azul." created={this.toastCreated = this.toastCreated.bind(this)}></ToastComponent>
          </div>
          descuento =<Button type="primary" onClick={e=>this.showModal()}>Cupón disponible<MDBIcon style={{width:"50px"}} fab icon="wpforms" /></Button> 
        }
        
              modalPolizaActiva = <Modal  width={1100} title="Tienes Cupones disponibles" visible={this.state.isModalVisible} onOk={e=>this.handleOk()} onCancel={e=>this.handleOk()}>
              <Card type="inner" title={<div><strong><Tag color="cyan">Cupo limitado</Tag></strong> <TagOutlined /></div>}>
                <table class="table table-small table table-bordered table table-striped">
                    <thead class="bg-primary text-white">
                    <tr>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Status</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Código</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}} className="text-center">Evento</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Descuento</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Vigencia</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Ir al evento</td>
                    </tr>
              </thead>
              {cuponPolizaActiva.map(rows=>{
                b++
                return (
                    <tbody>
                    
                      <tr>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Vigente</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.codigo}</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.descripcion}</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}} className="text-center">{rows.descuento} %</td>
                        <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.fechaExpiracion.substring(3,15)}</td>
                        <td><Button type="primary" className='text-white' size="large" href={rows.url} target=" _blank"  ><MDBIcon icon="chalkboard-teacher"/></Button></td>
                      </tr>
                    
                    </tbody>

                )
              })}
              </table>
              </Card>
            <center><Tag color="purple"></Tag></center>
          </Modal>
      }else if (this.state.porlizaVencidaEncontrada[0]){
          if(cuponPolizaVencida[0]){
            toastDescuento = <div>
            <div id='#toast_target'/>
            <ToastComponent id='toast_target' ref={toast => this.toastInstance = toast} title="Cupones disponibles" content="Estimado cliente usted cuenta con cupones disponibles para eventos, mas info en el botón rojo." created={this.toastCreated = this.toastCreated.bind(this)}></ToastComponent>
            </div>
            descuento =<Button type="danger" onClick={e=>this.showModal2()}>Cupón disponible<MDBIcon style={{width:"50px"}} fab icon="wpforms" /></Button> 
          }
          modalPolizaVencida = <Modal width={1100}title="Tienes cupones disponibles" visible={this.state.isModalVisible2} onOk={e=>this.handleOk2()} onCancel={e=>this.handleOk2()}>
          <Card type="inner" title={<div><strong><Tag color="cyan">Cupo limitado</Tag></strong> <TagOutlined /></div>}>
          <table class="table table-small table table-bordered table table-striped">
                  <thead class="bg-primary text-white">
                  <tr>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Status</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Código</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}} className="text-center">Evento</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Descuento</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Vigencia</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Ir al evento</td>
                  </tr>
            </thead>
            {cuponPolizaVencida.map(rows=>{
              b++
              console.log("rows",rows)
              return (
                  <tbody>
                   
                    <tr>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>Vigente</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.codigo}</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.descripcion}</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}} className="text-center">{rows.descuento} %</td>
                      <td  style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.fechaExpiracion.substring(3,15)}</td>
                      <td><Button type="primary" className='text-white' size="large" href={rows.url} target=" _blank"  ><MDBIcon icon="chalkboard-teacher"/></Button></td>
                    </tr>
                  
                  </tbody>

              )
            })}
            </table>
          </Card>
        <center><Tag color="purple"></Tag></center>
      </Modal>
      }
      let card;
      let a = 0;
      var toastSinPoliza;
      if(this.state.card === true && array[0]){
        card = <Card title = {<h6><strong>Datos generales de Pólizas de soporte registradas</strong> &nbsp; &nbsp;  &nbsp; &nbsp; {descuento}</h6>} extra={<div><Button onClick = {e=>this.mostrarCotizaciones()} type="success">Cotizaciones recibidas</Button></div>}> 
        <Tag color="magenta">Días restantes por póliza de soporte</Tag>
        <div style={{marginTop:"2%"}} className = 'graficaClientes'>
          {array.map(rows=>{ 
            var diferenciaPeriodoAF;
            if(rows.fechaInicial === "no vigente"){
                diferenciaPeriodoAF = 0;
            }else{
                var d = new Date();
                var diferencia_FechaActual_y_Final = -d.getTime() +  new Date(rows.fechaFinal).getTime();
                diferenciaPeriodoAF = diferencia_FechaActual_y_Final / (1000 * 3600 * 24);
                var diferencia_FechaInicial_y_Final = new Date(rows.fechaFinal).getTime() -  new Date(rows.fechaInicial).getTime();
                var diferenciaPeriodoIF = diferencia_FechaInicial_y_Final / (1000 * 3600 * 24);
                let diferenciaPeriodoInicialFinal = parseInt(diferenciaPeriodoIF)
                var porcentaje =  (diferenciaPeriodoAF * 100) /diferenciaPeriodoInicialFinal
             }
            return (
              <div>
                  <Tag color="cyan">{rows.id_polizas}</Tag>
                  <Progress type="circle" percent={parseInt(porcentaje)} format={percent => `${parseInt(diferenciaPeriodoAF)} Días`} />
              </div>
            )
          })}
        </div>
        <br/><br/>
          <Timeline>
          {array.map(rows=>{
          var d = new Date();

            var diferencia_FechaActual_y_Final = -d.getTime() +  new Date(rows.fechaFinal).getTime();
            var diferenciaPeriodoAF = diferencia_FechaActual_y_Final / (1000 * 3600 * 24);
            var diferencia_FechaInicial_y_Final = new Date(rows.fechaFinal).getTime() -  new Date(rows.fechaInicial).getTime();
            var diferenciaPeriodoIF = diferencia_FechaInicial_y_Final / (1000 * 3600 * 24);
            let diferenciaPeriodoInicialFinal = parseInt(diferenciaPeriodoIF)
            var porcentajeDias =  (diferenciaPeriodoAF * 100) /diferenciaPeriodoInicialFinal

            let status;
             if(rows.statusPoliza === "inactiva"){
               status = " Póliza inactiva"
             }else if(rows.statusPoliza === "activa"){

               status = " Póliza activa"
             }else if(rows.statusPoliza === "vencida"){
              status = " Póliza Vencida"
             }
            return (
              <div className='cardClientes'>
                <Progress format={percent => `${percent} %  ${status}`} strokeLinecap="square" percent={parseInt(porcentajeDias)}/>
                  <Timeline.Item>{rows.id_polizas + " " + rows.concepto}</Timeline.Item>
              </div>
            )
          })}
          </Timeline>
         </Card>

      }else if(!array[0]){
        toastSinPoliza = 
        <div>
            <div id='#toast_target'/>
            <ToastComponent id='toast_target' ref={toast => this.toastInstance = toast}  title="Póliza de soporte técnico no adquirida" content="Estimado cliente usted no cuenta con una póliza de soporte activa, adquiera una con su asesor de preferenia en ADS para disfrutar de descuentos en eventos con costo, videos exclusivos a clientes y soporte técnico además de otros beneficios en esta plataforma." created={this.toastCreated = this.toastCreated.bind(this)}></ToastComponent>
        </div>
      }
      // if(this.state.porlizaActivaEncontrada[0]){
      //   toastSinPoliza = 
      //    <div>
      //       <div id='#toast_target1'/>
      //       <ToastComponent id='toast_target1' ref={toast => this.toastInstance = toast}  title="Póliza de soporte técnico" content="Estimado cliente no olvide revisar los cupones de descuento a eventos que ADS tiene para usted, ver los videos exclusivos subidos al canal que se emiten frecuentemente, solicitar y revisar las cotizaciones emitidas así como conectarse a nuestra consola de soporte técnico" created={this.toastCreated = this.toastCreated.bind(this)}></ToastComponent>
      //     </div>
      // }
      if(this.state.porlizaVencidaEncontrada[0]){
        toastSinPoliza = 
         <div>
            <div id='#toast_target2'/>
            <ToastComponent id='toast_target2' ref={toast => this.toastInstance = toast}  title={"Póliza de soporte técnico vencida"} content="Estimado cliente para obtener descuentos mayores en los eventos de ADS y ver los videos exclusivos que se emiten frecuentemente, renueve su póliza de soporte técnico con el asesor de su preferencia y siga disfrutando de estos beneficios." created={this.toastCreated = this.toastCreated.bind(this)}></ToastComponent>
          </div>
      }
      
      let tablaInicial;
      if(this.state.tablaInicial === true){
        let buton;
        const columns = ["Id de Cotización","Folio","Emisión", " Id del Cliente","Visualizar"];
         let data =  this.state.cotizacionesPorCliente.map(rows=>{  
          //  console.log("esto es rows",rows)       
         buton = <MDBBtn size = "md" color = "primary" onClick={e=> this.pdfView(rows)}><MDBIcon far icon="file-pdf"/></MDBBtn>    
         return( [rows.id_cotizaciones, rows.NumFolio,rows.fechaEmision,rows.fk_cliente, buton])
       })
       let titulo  = <strong><h5>Cotizaciones recibidas</h5></strong>
 
       tablaInicial = <div  style={{width:"95%",marginLeft:"2%",marginTop:"2%"}} >  
       <Card title = {titulo} extra = {<Button type = "danger" onClick={e=>this.cerrarCotizaciones()}>Cerrrar cotizaciones</Button>}>        
          <MUIDataTable  
            data={data}
            columns={columns} 
            options={options} 
              
          /> 
      </Card> 
     </div> 
      }
      let report;
      if(this.state.renderPDF === true){
        let productoServicioByFolio  =  this.state.productoServicioByFolio;
        let dataContacto =  this.state.dataContacto[0];
        let datosCliente = this.state.datosCliente;
        let cotizaciones =  this.state.cotizaciones;
        let totales = this.state.totales
        let adminAlfa = this.state.adminAlfa 
        report = <Report cotizaciones = {cotizaciones} datosCliente = {datosCliente} dataContacto = {dataContacto} productoServicioByFolio = {productoServicioByFolio} totales = {totales} adminAlfa = {adminAlfa}/> 
      }
      return (
          <div>
            {card}
            {tablaInicial}
            {report}
            {showText && toastSinPoliza}
            {modalPolizaActiva}
            {modalPolizaVencida}
            {toastDescuento}
            </div>

        );
    }
}
 
export default Cotizaciones;