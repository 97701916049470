import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { Input, Card, Tag, Button as ButtonAnt } from "antd";
import "antd/dist/antd.css";
import { MDBCol, MDBRow, } from 'mdbreact';
import { PDFExport } from '@progress/kendo-react-pdf';
import logo from '../../imagen/logoImai.jpg'
import './index.css'
import QRCode from "react-qr-code";

let date = new Date();
var meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
let dia = date.getDate()
let letraMesSubstring = date.getMonth() + 1
let numeroPosicionMes = parseInt(letraMesSubstring, 10)
let mes = meses[numeroPosicionMes - 1]
let año = date.getFullYear()

let fecha = dia + " " + mes + " " + año

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "18px", width: 550, align: "right" }}>
      <MDBRow style={{ align: "right" }}>
          <img src={logo} alt="imagen" style={{ width: "40%", marginLeft: "-53%" }} />
      </MDBRow>
    </div>
  );
};

const { Search } = Input;

class PDF extends Component {
  pdfExportComponent
  constructor(props) {
    super(props);
    this.state = {

    }
  }

close2 = () =>{
    this.setState({ renderizadoDom: !this.state.renderizadoDom });
    this.setState({ inicio: true }) 
    this.setState({ modal: false })
    this.setState({ informacion: false })
   }

   ejecutarEnvio() {
     console.log("entra")
    this.pdfExportComponent.save()
  }

  render() {
 let render;
 let dowloadPDF;
 let DataTitulo = "Inversión y consideraciones para cada curso online"

render=
<div style={{marginTop: "2%" }} >
          <Card style={{ padding: "1%" }}  extra={<div><ButtonAnt onClick={e=>this.close2()}>X</ButtonAnt>   <ButtonAnt type="primary" ghost onClick={(e) => { this.ejecutarEnvio() }}>Descargar</ButtonAnt>
          </div>}>
            <center>
              <MDBRow style={{ marginTop: "3%" }}>
              <MDBCol size="8"></MDBCol>
                <MDBCol size="3">
                  <img src={logo} alt="imagen" style={{ width: 250, marginLeft: "-350%",marginBottom:"18%" }} />
                </MDBCol>
              </MDBRow>         
            </center>
            <center>
            <p className="textabla">Ciudad de México a {fecha}</p>
            </center>
            <p className="fuente3">{DataTitulo}</p>
            <p className="fuente1">Cuota por participante</p>
            <p className="fuente1">Socio</p>

            <p>{"$4,640.00" +  " IVA incluido" }</p>
            <p className="fuente1">No Socio* </p>
            <p>$5,640.00 IVA incluido (también incluye membresía anual al IMAI desglosada en la factura) </p>
            <p className="fuente1">Duración</p>
            <p>12 horas (divididas en 3 días) </p>
            <p>Horario</p>
            <p>De 9:00 a 13:00 hrs</p>
            <p>Los Participantes tendrán derecho a:</p>
            <ul>
              <li>Capacitación online en tiempo real </li>
              <li>Plataforma sencilla </li>
              <li>Material de apoyo (acceso electrónico)</li>
              <li>La grabación de cada sesión estará disponible por 48 horas para consulta </li>
              <li>Constancia de participación (digital)</li>
              <li>Membresía del IMAI con acceso a las páginas Web del IMAI y del IIA*</li>
            </ul>
            <p>Forma de pago</p>

            <center>
            <QRCode style={{width:100,height:100, marginBottom:"5%"}} value={"Adjuntamos nuevamente los datos bancarios que solicitamos del API :)"} />  
            </center>
              <p className="strong">Nota: En caso de fuerza mayor o de no cubrir el número de participantes requerido, el Instituto se
                reserva el derecho de reprogramar un curso hasta con 24 horas de anticipación</p>
          </Card>
        </div>

        dowloadPDF=
        <div>
          <div style={{ position: "absolute", left: "-5000px", top: 0 }}>

            <PDFExport
              paperSize="A4"
              margin={{ left: "10mm", right: "10mm", top: "5mm", bottom: "12mm" }}
              forcePageBreak=".page-break"
              fileName={"REPORTE" + ".pdf"}
              pageTemplate={PageTemplate}
              multiPage="true "
            //   landscape="true"
              ref={(component) => this.pdfExportComponent = component}
            >
            <div>
            <center>
                <MDBRow style={{ marginTop: "2%" }} >
                    <img src={logo} alt="imagen" style={{ width: 280 }} />
                    <br />
                </MDBRow>
            </center>  <br/>    <br/>        <br/>   
            <center>
            <p className="textabla">Ciudad de México a {fecha}</p>
            </center>             
            <p className="textabla">{DataTitulo}</p>
            <p className="textabla3">Cuota por participante</p>
            <p className="textabla">Socio</p>

            <p className="textabla3">{"$4,640.00" +  " IVA incluido" }</p>
            <p className="textabla">No Socio* </p>
            <p className="textabla3">$5,640.00 IVA incluido (también incluye membresía anual al IMAI desglosada en la factura) </p>
            <p className="textabla">Duración</p>
            <p className="textabla3">12 horas (divididas en 3 días) </p>
            <p className="textabla">Horario</p>
            <p className="textabla3">De 9:00 a 13:00 hrs</p>
            <p className="textabla3">Los Participantes tendrán derecho a:</p>
            <ul>
              <li className="textabla">Capacitación online en tiempo real </li>
              <li className="textabla">Plataforma sencilla </li>
              <li className="textabla">Material de apoyo (acceso electrónico)</li>
              <li className="textabla">La grabación de cada sesión estará disponible por 48 horas para consulta </li>
              <li className="textabla">Constancia de participación (digital)</li>
              <li className="textabla">Membresía del IMAI con acceso a las páginas Web del IMAI y del IIA*</li>
            </ul>
            <p className="textabla"> Forma de pago</p>

            <center>
            <QRCode style={{width:100,height:100,marginBottom:"5%"}} value={"Adjuntamos nuevamente los datos bancarios que solicitamos del API :)"} />  
            </center>  
              <p className=" textabla strong">Nota: En caso de fuerza mayor o de no cubrir el número de participantes requerido, el Instituto se
                reserva el derecho de reprogramar un curso hasta con 24 horas de anticipación</p>
              </div>
            </PDFExport>
          </div>
        </div>



    return (
      <React.Fragment>               
       {render}
       {dowloadPDF}
     </React.Fragment>
    );
  }
}
export default PDF;