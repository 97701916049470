import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {  Form } from "reactstrap";
import { MDBRow,MDBCol,MDBBtn,MDBInput,MDBCard } from "mdbreact";
import { MDBCardImage } from "mdbreact";
import axios from "axios";
import { API } from "../Graphql/Graphql";
import { Card,DatePicker} from 'antd';
import { Input, Space ,} from 'antd';
import swal from 'sweetalert'

class signupVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descripcion:"", 
      autor:"",        
      urlVideos:"",
      fecha:"",
      fechaExpiracion:""           
    };
    this.onClear = this.onClear.bind(this)  
    this.capturarFecha = this.capturarFecha.bind(this);
    this.capturarFechaFinal = this.capturarFechaFinal.bind(this);
  }

  onChangeInput = (e) => {   
    const { id, value } = e.target;  
    this.setState({
      [id]: value
    });
  };
  onClear = () => {
    this.setState({
      descripcion:"", 
      autor:"",        
      urlVideos:"",
      fecha:"",
      fechaExpiracion:""     
    });
  } 

  capturarFecha=(e)=>{
        if(e){         
        let fechaInicio = e._d.toString();         
          this.setState({fecha:fechaInicio})                
        }
      }
      capturarFechaFinal=(e)=>{
        if(e){
          let fechaFinal = e._d.toString();
          this.setState({fechaExpiracion:fechaFinal})
        }
      }

  onSubmitBtn = (e) => {   
     let descripcion = this.state.descripcion.toUpperCase();
     let autor = this.state.autor.toUpperCase(); 
     let urlVideos = this.state.urlVideos
     let fecha = this.state.fecha;
     let fechaExpiracion = this.state.fechaExpiracion  
     let fk_empresa =  localStorage.getItem("fk_empresa")     
     
if( fecha && fechaExpiracion && descripcion && autor && urlVideos){  
       axios({
      url: API,
      method: "post",
      data: {
        query: `
                mutation{
                  insertURLVideos(data:"${[descripcion,autor,urlVideos,fecha,fechaExpiracion,fk_empresa]}"){  
                    message
                     } 
                }
                `
      }
    })
      .then((response) => {        
       if(response.data.data.insertURLVideos.message === "registro exitoso"){
        swal({
          text:"Registro exitoso",
          buttons: false,
          icon:'success'
      }); 
        this.setState({descripcion:[],autor:[],urlVideos:[],fecha:[],fechaExpiracion:[],fk_empresa:[]})          
      }  else{
        swal({
          text:"Algo salio mal, inténtalo nuevamente",
          buttons: false,
          icon:'error'
        }); 
      }          
      }).catch((err) => {
        console.log("error", err.response);
      });     

    }else{
      swal({
        text:"Por favor llene los campos obligatorios",
        buttons: false,
        icon:'warning'
      }); 
    }   

  };
  


  render() {
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    let formulario;
    let titulo =  <strong><h4>Registrar Videos</h4></strong>
    formulario=
    <div>
    <MDBRow style={{marginLeft:"15%"}}>             
    <MDBCol md="5">  
    <label htmlFor="defaultFormLoginEmailEx">
      <strong>Fecha de inicio: *</strong>
      </label><br/> 
      <Space direction="vertical" >
      <DatePicker  placeholder="Fecha de Inicio" onChange={this.capturarFecha} format={dateFormatList}/>                    
    </Space>                    
    </MDBCol>               
    
    <MDBCol md="6">  
    <label htmlFor="defaultFormLoginEmailEx">
      <strong>Fecha de finalización: *</strong>
      </label><br/> 
      <Space direction="vertical" >
      <DatePicker onChange={this.capturarFechaFinal}  placeholder="Fecha de Finalización"  format={dateFormatList} />                    
    </Space>              
    </MDBCol>
    </MDBRow>
    </div>
    return (
      <React.Fragment>         
           <div style={{width:"90%",marginTop:"1%",marginLeft:"5%"}}>
            <Card title={titulo}>
            <MDBRow >           
              <MDBCol size="5">
              <MDBCard style={{ width: "25rem"}}>
                <MDBCardImage className="img-fluid"
                 src="https://images.pexels.com/photos/5077064/pexels-photo-5077064.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                 waves />
              </MDBCard>
              </MDBCol>
              <MDBCol>
                          
              <Form  onSubmit={this.onSubmitBtn}> 
               
              {formulario}  
               
              <MDBCol md="12" style= {{marginTop:"2%"}}>
             <center> <label><strong>Descripción *</strong></label></center>              
              <div className="input-group">
             <div className="input-group-prepend">             
                 <span className="input-group-text" id="basic-addon">
                 <i className="fas fa-pencil-alt prefix"></i>
                 </span>
             </div>
             <textarea
              className="form-control" 
              id="descripcion"
              name="descripcion"
              value={this.state.descripcion}
              rows="5"
              onChange={this.onChangeInput} 
              required
              >
              </textarea>
         </div>           
          </MDBCol>
            <MDBCol md="12"> 
            <MDBInput
            label="Autor *"
            icon="user"
            id="autor"
            type="text"
            name="autor"
            onChange={this.onChangeInput}
            value={this.state.autor}  
            required                     
            /> 
          </MDBCol>          
            <MDBCol md="12" >  
            <MDBInput
            label="URL Video *"
            icon="globe"
            id="urlVideos"
            type="text"
            name="urlVideos"
            onChange={this.onChangeInput}
            value={this.state.urlVideos}  
            required                     
            /> 
            </MDBCol>
          
          <div className="text-center">
          <MDBBtn size="md" color="info"onClick={e=>this.onSubmitBtn()}>Registrar Video</MDBBtn>
          <MDBBtn size="md" color="danger" onClick={e=>this.onClear()}         
          >Borrar</MDBBtn>                   
          </div>    
            </Form>
              </MDBCol>           
            </MDBRow>
            </Card> 
            </div>          
      </React.Fragment>
    );
  }
}
export default signupVideo;
