
import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { DialogUtility } from '@syncfusion/ej2-popups';
import {  Label, Form, Row} from "reactstrap";
import { MDBContainer,MDBRow,MDBCol,MDBBtn,MDBInput,MDBCard,MDBAlert,MDBIcon } from "mdbreact";
import { MDBCardImage } from "mdbreact";
import axios from "axios";
import { API } from "../Graphql/Graphql";
import {Card} from 'antd'
import { Divider } from "@material-ui/core";
import Navbar from '../paneldeConection/navbar'
import { Menu, Dropdown, Button, message, Space, Tooltip } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

class signupAdminAlfa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rfc:"",
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      ext:"",
      celular:"",
      puesto:"",
      contrasena: "",
      tablas:[],
      viewSearch:true,
      viewForm:false,
      fk_empresa:[],
      rs:'',
      administrador:'',
      idRolAdministrador:''     
    };  
    this.handleMenuClick  =  this.handleMenuClick.bind(this)
    this.onClear = this.onClear.bind(this)
  }

  onChangeInput = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value
    });
  };

 componentWillUnmount(){
  localStorage.removeItem("id_empresa")
  localStorage.removeItem("rfc")
  localStorage.removeItem("telefono")
  localStorage.removeItem("correo")
  localStorage.removeItem("razonSocial")    
  localStorage.removeItem("telefono") 
  localStorage.removeItem("ext") 
  localStorage.removeItem("celular") 
  localStorage.removeItem("puesto") 
  }

 
  consultarDatos(){    
    let rfc=this.state.rfc
    axios({
      url:API,
      method:'post',
      data:{
          query:`
          query{
              getEmpresas(data:"${[rfc]}"){
                id_empresa
                rfc
                razonSocial
                correo
                telefono 
                message        
             } 
          }
          `
      }   
  
       }).then(response=>{      
        if(response.data.data.getEmpresas[0]){
          localStorage.setItem("empresa",response.data.data.getEmpresas[0].id_empresa)
          localStorage.setItem("razonSocial",response.data.data.getEmpresas[0].razonSocial)
          this.setState({rs:response.data.data.getEmpresas[0].razonSocial})
          this.setState({fk_empresa:response.data.data.getEmpresas[0].id_empresa})   
        }else{
           DialogUtility.alert({
                
                  title:'AVISO!' ,
                  content:'El RFC no fue encontrado'
                  
              });
        }       
       })
       .catch(err=>{
           console.log('error',err)
       })   
  }

  onSubmitBtn = (e) => {
    // e.preventDefault();
    let nombre = this.state.nombre.toUpperCase();
    let apellido = this.state.apellido.toUpperCase();
    let puestoAdmin = this.state.puesto.toUpperCase();
    let correo = this.state.correo
    let telefono = this.state.telefono
    let ext = this.state.ext   
    let celular = this.state.celular 
    let contrasena = this.state.contrasena
    let rolAministrador = this.state.idRolAdministrador 

    if(nombre && apellido && correo && telefono && contrasena && rolAministrador){
      if(this.state.fk_empresa[0]){
        axios({
          url: API,
          method: "post",
          data: {
            query: `
                    mutation{
                        signupAlfa(data:"${[nombre,apellido,correo,telefono,ext,celular,puestoAdmin,contrasena,this.state.fk_empresa,rolAministrador]}"){  
                        message
                         } 
                    }
                    `
          }
        })
          .then((response) => {    
            if(response.data.data.signupAlfa.message === "el registro en signup fue exitoso"){
              DialogUtility.alert({
                title: "Registro exitoso"
              });
              this.setState({nombre:''})
              this.setState({apellido:''})
              this.setState({puesto:''})
              this.setState({correo:''})
              this.setState({telefono:''})
              this.setState({ext:''})
              this.setState({celular:''})
              this.setState({contrasena:''})
              this.setState({rfc:''})
              this.setState({rs:''})

            } 
          })
          .catch((err) => {
            console.log("error", err.response);
          });    
      }else{
        DialogUtility.alert({
          title: "Aviso!",
          content: "Por favor Asigne una Razón Social"
        });
      }
    }else {
      DialogUtility.alert({
        title: "Aviso!",
        content: "No deje campos vacíos"
      });
    }      
  };
  
  onClear = () => {
    this.setState({
      rfc:"",
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      ext:"",
      celular:"",
      puesto:"",
      contrasena: "",  
      rs:'',    
      administrador:'',
    });
  }

  handleMenuClick(e) {
    let administrador;
    if(e.key === "5"){
      administrador = "Administrador general";
    }else if(e.key === "15"){
      administrador = "Administrador";
    }else if(e.key === "25"){
      administrador = "Ventas";
    }else if(e.key === "35"){
      administrador = "Soporte";
    }
    else if(e.key === "45"){
      administrador = "Seguimiento";
    }
    else if(e.key === "55"){
      administrador = "Mantenimiento";
    }
    this.setState({administrador:administrador})
    this.setState({idRolAdministrador:e.key})

    message.info(`Campo ${administrador} Seleccionado`);
  }

  render() {
    const menu = (
      <Menu onClick={e=>this.handleMenuClick()}>
        <Menu.Item key="5" icon={<UserOutlined />}>
          Administración general
        </Menu.Item>
        <Menu.Item key="15" icon={<UserOutlined />}>
          Administración
        </Menu.Item>
        <Menu.Item key="25" icon={<UserOutlined />}>
          Ventas
        </Menu.Item>
        <Menu.Item key="35" icon={<UserOutlined />}>
          Soporte
        </Menu.Item>
         <Menu.Item key="45" icon={<UserOutlined />}>
          Seguimiento
        </Menu.Item>
        <Menu.Item key="55" icon={<UserOutlined />}>
          Mantenimiento
        </Menu.Item>
      </Menu>
    );

    let formulario;
    let search;
 if(this.state.viewSearch===true){
  search=   
            <div style={{width:"150%",marginLeft:"30%"}}> 
            <Row>  
            <input  type="input-form" id="rfc" value={this.state.rfc} name="rfc"  onChange={this.onChangeInput}   placeholder="RFC de la Empresa" />
            <MDBBtn gradient="aqua" rounded size="sm" onClick={e=> this.consultarDatos()}  >                        
              <MDBIcon icon="search" />
            </MDBBtn>  
            </Row>
            </div>
        
  }

 let titulo = <strong><h5>Registrar Administrador</h5></strong>

    return (
      <React.Fragment>
        <Navbar/>
        <div style={{width:"90%",marginTop:"1%",marginLeft:"5%"}}>
          <Card title ={titulo}>
          <Paper>
            <MDBRow>
              <MDBCol size="5">
                <MDBCard style={{ width: "20rem"}}>
                  <MDBCardImage
                    className="img-fluid"
                    src="https://images.pexels.com/photos/3178818/pexels-photo-3178818.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                    waves
                  />
                </MDBCard>
              </MDBCol>
              <MDBCol size="6" style={{ marginTop: "2%"}}>
                <Form onSubmit={this.onSubmitBtn}>
                  <div>
                      {search}
                    <MDBCol style={{marginTop:"2%"}}>
                    <Row>        
                    <MDBCol md="6"> <h6><strong>Razón Social:</strong></h6></MDBCol>
                    <strong><Label>{this.state.rs || "Razon social no ingresada"}</Label></strong>
                        <MDBCol md="6">
                          <MDBInput
                            label="Nombre (s) *"
                            icon="user"
                            id="nombre"
                            type="text"
                            name="nombres"
                            onChange={this.onChangeInput}
                            value={this.state.nombre}
                            required
                          />
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBInput
                            label="Apellidos *"
                            id="apellido"
                            type="text"
                            name="apellido"
                            onChange={this.onChangeInput}
                            value={this.state.apellido}
                            required
                          />
                        </MDBCol>
                      </Row> 
                      <Row>
                        <MDBCol md="6">
                          <MDBInput
                            label="Correo *"
                            icon="envelope"
                            id="correo"
                            type="email"
                            name="correo"
                            onChange={this.onChangeInput}
                            value={this.state.correo}
                            required
                          />
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBInput
                            label="Telefono *"
                            icon="phone"
                            id="telefono"
                            type="number"
                            name="telefono"
                            onChange={this.onChangeInput}
                            value={this.state.telefono}
                            required
                          />                          
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBInput
                            label="Ext."                           
                            id="ext"
                            type="number"
                            name="ext"
                            onChange={this.onChangeInput}
                            value={this.state.ext}
                            required
                          />                          
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBInput
                            label="Celular"                           
                            id="celular"
                            type="number"
                            name="celular"
                            icon="mobile-alt"
                            onChange={this.onChangeInput}
                            value={this.state.celular}
                            required
                          />                          
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBInput
                            label="Puesto"
                            icon="id-card-alt"
                            id="puesto"
                            type="text"
                            name="puesto"
                            onChange={this.onChangeInput}
                            value={this.state.puesto}
                            required
                          />                          
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBInput
                            label="Contraseña *"
                            icon="lock"
                            id="contrasena"
                            type="password"
                            name="contrasena"
                            onChange={this.onChangeInput}
                            value={this.state.contrasena}
                            validate
                            required
                          />
                        </MDBCol>    
                        <Dropdown overlay={menu}>
                          <Button>
                            {this.state.administrador || "Tipo de usuario" }<DownOutlined />
                          </Button>
                        </Dropdown>                  
                    </Row> 
                  <div className="text-center">
                        <MDBBtn color="info" onClick={e=>this.onSubmitBtn()}>                   
                          Guardar
                        </MDBBtn>
                        <MDBBtn
                          color="danger"
                          onClick={e=>this.onClear()}
                          // type="submit"
                        >
                          limpiar
                        </MDBBtn>                   
                    </div> 
                     </MDBCol>                    
                  </div>                  
                </Form>
              </MDBCol>
            </MDBRow>
          </Paper>
          </Card>
          </div>
      </React.Fragment>
    );
  }
}
export default signupAdminAlfa;