import React, { Component } from 'react';
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import {Card, Checkbox,Button,Popconfirm, message, Divider, Select, Input, Cascader} from 'antd'
import MUIDataTable from "mui-datatables";
import { DialogUtility } from '@syncfusion/ej2-popups';
import swal from 'sweetalert'
const { Option } = Select;

class SolicitudCotizacion extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            productoActualizado:[],
            inputFields: [] ,
            tablaInicial:true,
            tablaCalculo:false,
            arrayProductos:false,
            folioCotizacion:'',
            asesor:'',
            nombreAsesor:''
         }

         this.selectAsesor = this.selectAsesor.bind(this)
    }

    componentWillMount = async()=>{
        let fk_empresa =  localStorage.getItem("fk_empresa")
        var data;
        await axios({
        url:API,
        method:'post',
        data:{
            query:`
            query{   
                getTablaProductoServicio(data:"${[fk_empresa]}"){
                id_productoServicio
                tipo
                tipoLicenciamiento
                lineaProducto
                concepto
                precio
                consecutivo
                id_actualizacion
                asignacion
                fechaRegistro
                fk_empresa
                message
                } 
            }
            `  }           
            })
            .then((datos) => {   
                let dataArray = []
                data = datos.data.data.getTablaProductoServicio
                data.map(rows=>{
                  if(rows.asignacion && rows.id_actualizacion){
                    dataArray.push(rows)
                  }
                })
             
                let filtered = dataArray.reduce((acc, item) => {
                  if (!acc.some((e, i) => {
                      if (item.asignacion === e.asignacion) {
                        if (item.id_actualizacion > e.id_actualizacion) {
                          acc.splice(i, 1, item);
                        }
                        return true;
                      }
                    })) {
                    acc.push(item);
                  }
                  return acc;
                }, []);
                console.log("filtered",filtered)
                this.setState({productoActualizado:filtered})
            }).catch(err=>{
                console.log("err",err.response)
                console.log("err",err)  
            })
        }
    onChange = async (e,event) => {
      const values = [...this.state.inputFields];
      values.push( e );
      let array = this.state.inputFields
      await array.indexOf(values.map(rows=>rows)) === -1 ?  this.setState({inputFields:values})  : console.log("elemento eliminado del carrito");
      let seleccion = event.target.checked;
      if(seleccion === false){
        this.eliminarEstado(e,array)
      }
    }
     eliminarEstado(e,array){
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({inputFields: array});
     }
    }

    handleSubmit = () => {
      let array = [];
      this.state.inputFields.map(rows=>{
        let filtrado = this.state.productoActualizado.filter(function(hero){
          return hero.id_productoServicio === rows
        })
        array.push(filtrado[0])
      })
      this.setState({arrayProductos:array})
      this.setState({tablaInicial:false})
      this.setState({tablaCalculo:true})
      let año  = new Date().getFullYear()
      function generateUUID() {
          var d = new Date().getTime();
          var uuid = 'xAxxyxyxxxyx'.replace(/[xy]/g, function (c) {
              var r = (d + Math.random() * 16) % 16 | 0;
              d = Math.floor(d / 16);
              return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
          });
          return uuid;
      }
      let folio = (año + generateUUID()).toUpperCase();
      this.setState({folioCotizacion:folio})
      message.info('Productos Agregados con éxito');
    };
    regresar(){
      message.error('Operación cancelada');
      this.setState({inputFields:[]})
      this.setState({tablaInicial:true})
      this.setState({tablaCalculo:false})
    }

    selectAsesor(e){
      if(e === "1"){
        this.setState({asesor:"carmen.rivera@ads.com.mx"})
        this.setState({nombreAsesor:"Carmen Rivera"})
      }else if(e === "2"){
        this.setState({asesor:"victor.gonzalez@ads.com.mx"})
        this.setState({nombreAsesor:"Victor Gonzalez"})
      }else if(e === "3"){
        this.setState({asesor:"antonio.paz@ads.com.mx"})
        this.setState({nombreAsesor:"Antonio Paz"})
      }else if(e === "4"){
        this.setState({asesor:"javier.rodriguez@ads.com.mx"})
        this.setState({nombreAsesor:"Javier Rodriguez"})
      }
    }
    generarSolicitud(){
    function sumarDias(fecha, dias){
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }
      let a = 0;

      let folio = this.state.folioCotizacion
      let asesor = 'correoprueba@ads.com.mx'
      let fechaEmision = new Date()
      let fechaValiación  = "Sin fecha asignada"
      let fechaExpiracion = sumarDias(new Date(),10);
      let statusSolicitud = "En proceso"
      let productos =  this.state.arrayProductos
      let fk_cliente =  localStorage.getItem("id_cliente");
      let nombreAsesor = this.state.nombreAsesor
      let rs = localStorage.getItem("razonSocial");
      let rfc = localStorage.getItem("rfc")
      let fk_empresa =  localStorage.getItem("fk_empresa");

      const formatDateUTC = (dateString) => {
        let fecha = new Date(dateString);
        const dia = fecha.getUTCDate().toString().padStart(2,'0');
        const mes = (fecha.getUTCMonth() + 1).toString().padStart(2,'0');
        const year = fecha.getUTCFullYear().toString();
        return `${dia}/${mes}/${year}`;
      }      
      let fecha = "-- " + formatDateUTC(fechaExpiracion)
     if(asesor){
        productos.map(rows=>{
          axios({
            url:API,
            method:'post',
            data:{
                query:`
                mutation{   
                    registerSolictudCotizacion(data:"${[folio,asesor,fechaEmision,fechaValiación,fechaExpiracion,statusSolicitud,rows.id_productoServicio,fk_cliente,asesor,rs,rfc,fecha,fk_empresa]}"){
                    message
                    } 
                }
          ` }           
          }).then((datos) => {  
            if(datos.data.data.registerSolictudCotizacion.message === 'registro exitoso' && a === 0){
              DialogUtility.alert({    
                okButton: {  text: 'aceptar', click: e=> window.location.reload()},        
                title:'Notificación del sistema!' ,
                content:`Solicitud enviada. \n El folio ${folio} esta en proceso de revisión, posteriormente el ejecutivo ${nombreAsesor} se pondrá en contacto con usted.`    
              }); 
              a++;  
            }else if(datos.data.data.registerSolictudCotizacion.message !== 'registro exitoso' && a === 0){
              swal({
                title:"Aviso!",
                text:'El proceso puede no haberse realizado correctamente, por favor revise el apartado "Solicitudes" para comprobar que la solicitud se haya enviado, de lo contrario inténtelo nevamente.',
                buttons: false,
                icon:"error"
            }); 
              a++;  
            }
          }).catch(err=>{
            console.log("err",err.response)
          })
      })
      axios({
        url:API,
        method:'post',
        data:{
            query:`
            mutation{   
                sendMailSolicitudCotizacion(data:"${[folio,asesor,fechaEmision,fechaValiación,fechaExpiracion,fk_empresa,asesor,rs,rfc,fecha]}"){
                message
                } 
            }
      ` }           
      }).then((datos) => { 
        console.log("datos",datos)
       })
    }else{
      swal({
        title:"Aviso!",
        text:"Seleccione al asesor para procesar su solicitud",
        buttons: false,
        icon:"warning"
    }); 
    }
    }
    render(){ 
  
        const options3={ 
            elevation:0,
            filterType:"drowpdawn",
            responsive: "stacked",
            pagination:false,
            search:true,
            print:false,
            download:false,
            filter:false,
            caseSensitive:false,
            selectableRows:"none",
            viewColumns:false,      
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          }        
        } 

        const text = <font color="blue"><br/>¿Los producto seleccionados son correctos?.<br/>Si continúa no podrá modificar su catálogo de selección.<br/><br/></font>;
        const columnsProductoActualizado = ["Serie", "Tipo", "Concepto" , "Linea del producto", "Licenciamiento" , "Agregar"];
        const dataProductoActualizado = this.state.productoActualizado.map(rows=>{
          let select = <Checkbox onChange = {e=>this.onChange(rows.id_productoServicio,e)}></Checkbox>
          return([rows.id_productoServicio,rows.tipo,rows.concepto,rows.lineaProducto,rows.tipoLicenciamiento,select])
        })
       let tituloCatalogo = <h6><strong>Solicitud de productos a cotizar</strong></h6> 
       var tablaInicial;
       if(this.state.tablaInicial === true){
         tablaInicial= 
        <div style={{width:"85%",marginTop:"2%"}}>   
            <Card title = {tituloCatalogo} extra = {
              <div>
                <Popconfirm placement="bottom" title={text} onConfirm={e=>this.handleSubmit()} okText="Avanzar" cancelText="Editar">
                  <Button disabled={!this.state.inputFields[0]}size='small' type="success">Agregar producto</Button>
                </Popconfirm>
              </div>
              }>       
            <MUIDataTable  
                title = {<font size = "2" color ="red">Nota: Los productos mostrados no incluyen IVA</font>}
                data={dataProductoActualizado}
                columns={columnsProductoActualizado} 
                options={options3}                     
            /> 
            </Card>
        </div> 
        }
        let tablaCalculo;
        let tituloProductos = <h6><strong>Productos seleccionados para solicitud de cotización</strong></h6>;
        if(this.state.tablaCalculo === true){
          let empresa =  localStorage.getItem("razonSocial");
          let rfc = localStorage.getItem("rfc")
          let correo = localStorage.getItem("correo");
          let cliente = localStorage.getItem("nombreRepresantante");
          let apellidos = localStorage.getItem("apellidosRepresantante");
          tablaCalculo  = <div style={{width:"90%",marginTop:"2%"}}>   
          <Card title = {tituloProductos} extra ={
          <div>
              <Button size='small' type="danger" onClick={e=>this.regresar()}>Cancelar</Button>
          </div>}>       
            <table className="table table-bordered table table-small table table-striped">
              <thead>
              <tr>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>{empresa}</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>{rfc}</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>{correo}</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>{cliente + " " + apellidos}</td>

              </tr>
              </thead>

            </table>
            <Divider><strong><font size="2">Productos solicitados</font></strong></Divider>
             <strong>Folio de solicitud: {this.state.folioCotizacion} </strong>
             <Divider><strong><font size="2"></font></strong></Divider>

            <table className="table table-bordered table table-small">
              <thead>
              <tr>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>Serie</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>Tipo</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>Concepto</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>Linea del P.</td>
                <td style={{paddingTop:"2px",paddingBottom:"2px",backgroundColor:"#EDE9EC"}}>Licenciamiento</td>
              </tr>
              </thead>
              <tbody>
                  {this.state.arrayProductos.map(rows=>{
                    return(
                      <tr>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.id_productoServicio}</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.tipo}</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.concepto}</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.lineaProducto}</td>
                        <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.tipoLicenciamiento}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Input.Group compact>
                <Select onChange={this.selectAsesor} style={{ width: '30%' }} defaultValue="Seleccione su ejecutivo">
                  <Option value="1">Carmen rivera</Option>
                  <Option value="2">Victor Gonzalez</Option>
                  <Option value="3">Antonio Paz</Option>
                  <Option value="4">Javier Rodriguez</Option>
                </Select>
              </Input.Group>
          </Card>
          <Divider><strong><font size="2">Nota: Se enviará su solicitud de forma inmediata, cuando la cotización sea generada el asesor seleccionado se comunicará con usted.</font></strong></Divider>
          <Divider></Divider>
          <Button type="primary" onClick = {e=>this.generarSolicitud()} >Generar solicitud de cotización</Button>
          </div>
        }
        return ( 
            <div>
              <center>
               {tablaInicial}
               {tablaCalculo}
              </center>

            </div>
         );
    }
}
 
export default SolicitudCotizacion;