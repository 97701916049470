import axios from 'axios'
import React,{Component} from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
 import'bootstrap-css-only/css/bootstrap.min.css'; 
import'mdbreact/dist/css/mdb.css';
import {  MDBRow, MDBCol, MDBInput, MDBBtn, MDBCard,MDBCardBody, MDBView} from 'mdbreact';
import { Form } from 'reactstrap';
import './index.css'
import {API} from '../Graphql/Graphql'
import { Avatar,Card } from 'antd';
import { Modal, Button as Boton } from 'antd';


class loginAdminAlfa extends Component{
    constructor(props){
        super(props)
        this.state ={
            user:"",
            pass:"",
            dataClientes:[]

        }
    }
     componentWillMount(){
     
    }
    
    onChangeInput =(e)=>{
        const {id,value} = e.target;
        this.setState({
            [id]:value
        })
    }
    
    validacion(){
        if(this.state.user){
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getClienteByCorreo(data:"${[this.state.user]}"){
                            id_cliente
                            rfc
                            razonSocial
                            tamanoEmpresa
                            giroEmpresarial
                            domicilioFiscal
                            fk_contactoAcceso
                       } 
                    }
                    `
                }   
                 }).then(datos=>{
                     if(datos.data.data.getClienteByCorreo[0]){
                         this.setState({dataClientes:datos.data.data.getClienteByCorreo[0]})
                     }else{
                         alert("El correo proporcionado no ha sido encontrado")
                     }
                 })
                 .catch(err=>{
                     console.log('error',err)
                     console.log('error',err.response)
           })
        }else{
            alert("Por favor ingrese un correo electrónico")
        }
     
    }

    onSubmitBtn = ()=>{  
        if(this.state.pass){
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                        updatePasswordCliente(data:"${[this.state.dataClientes.fk_contactoAcceso,this.state.pass]}"){
                           message 
                       } 
                    }
                    `
                }   
                 }).then(datos=>{
                     if(datos.data.data.updatePasswordCliente.message === "actualización exitosa"){
                        alert(`Contraseña para ${this.state.dataClientes.razonSocial} Actualizada`);
                        window.location.reload()
                     }else{
                         alert("Algo salió mal, por favor inténtelo nuevamente")
                     }
                 })
                 .catch(err=>{
                     console.log('error',err)
                     console.log('error',err.response)
           })
        }else{
            alert("Por favor ingrese una contraseña válida")

        }
 
    }
    cancelar(){
        this.props.history.push("/")
    }
  

     render(){
         let datosClientes;
         if(this.state.dataClientes.id_cliente){
        let rfc =  <strong>RFC</strong>
           datosClientes =  <Card style={{width:"70%"}}>
            <table>
                <tr>
                    <td width="30%">{rfc}</td>
                    <td width="70%">{this.state.dataClientes.rfc}</td>
                </tr>
                <tr>
                    <td><strong>Razón S</strong></td>
                    <td>{this.state.dataClientes.razonSocial}</td>
                </tr>
            </table>   
            <center>
            <MDBBtn  style={{marginTop:"10%"}} color='success' size="sm" onClick={e=> this.onSubmitBtn()}>
             Actualizar contraseña                        
            </MDBBtn>
            </center>
            </Card>
         }
         let titulo = <center><strong><h4>Actualizar contraseña</h4></strong></center>
         return(
            <React.Fragment>
                <div id="apppages">
                    <MDBView>
                <div style={{marginTop:"3%", marginLeft:"8%"}} >
                <MDBCol md="5">
                <Card title = {titulo} style={{width:"70%"}}>       
                          
                <div className="h5 text-center mb-2">
                    <Avatar size={80} style={{ backgroundColor: '#043D76' }} ><font size="20" >ADS</font></Avatar>     
                </div>
                                
                <Form onSubmit={this.onSubmitBtn}> 
                <center>
                <MDBRow style={{ marginLeft:"3%"}}>
                    <MDBCol md="11">
                        <MDBInput                          
                        id="user"
                        type="email"
                        name="user"
                        onChange={this.onChangeInput}
                        value={this.state.user}
                        required
                        label="Ingrese su correo"
                        />                       
                        <MDBInput                         
                          id="pass"
                          type="password"
                          name="contrasena"
                          onChange={this.onChangeInput}
                          value={this.state.pass}
                          required                         
                          label="Nueva contraseña"    
                        />
                      
                        
                        <div className="text-center"  style= {{marginTop:"3%",marginLeft:"6%",display:"flex",alignContent:"space-between"}}>
                        <MDBBtn color='primary' size="sm" onClick={e=> this.validacion()}>
                         Avanzar                            
                        </MDBBtn>
                        <MDBBtn  color='danger' size="sm" onClick={e=> this.cancelar()}>
                          Salir                        
                        </MDBBtn>
                        </div> 
                    </MDBCol>
                    {/* </MDBCol> */}
                </MDBRow>    
                </center>                         
                </Form> 
              
            </Card>
            </MDBCol> 
            <MDBCol md="5">
            {datosClientes}     
            </MDBCol>    
              
            </div>
            </MDBView>
            
            </div>               
        </React.Fragment>
        )
    }
}
export default loginAdminAlfa