import actionsAPI from "./actionsAPI";


  let Productos = [];
  let Comerciales = [];
  let Nom035 = [];

  let DataProd;
  let DataCom;
  let NomProd;

  actionsAPI.getCourses().then((response)=>{
    const data = response.data.data.getCourses;
    let prod = data.filter(hero => {
      return hero.tipo === "Presencial";
    })
    Productos.push(prod)
    DataProd = Productos[0];


    let nom = data.filter(hero => {
      return hero.tipo === "Nom035";
    })
    Nom035.push(nom)
    NomProd = Nom035[0];


    let com = data.filter(hero => {
      return hero.tipo === "Remoto";
    })
    Comerciales.push(com)
    DataCom = Comerciales[0];
  }).catch(err=>{
    console.log("err",err.response)
  })

  export {
    DataProd,
    DataCom,
    NomProd
  }


  // const Comerciales = [
  //   {
  //     id: 5,
  //     name: "CONTPAQi® Comercial",
  //     img: "https://tamti.com.mx/wp-content/uploads/2020/10/CONTPAQi_submarca_Comercial_Premium_RGB_D.png",
  //     text:
  //       "El software administrativo que te ayudará a simplificar tus procesos nuestro sistema administrativo para negocios te ayudará a integrar y simplificar tus actividades. Además, al contratar tu licencia anual obtienes gratis Wopen Estándar.",
  //     add:"Garantía de satisfacción.",
  //     add2:"Acceso a grupo de WhatsApp para atención de dudas.",
  //     add3:"Certifición del Sistema",
  //     instructor:"Marco Antonio Sánchez Álvarez"
  //   },
  //   {
  //     id: 6,
  //     name: "CONTPAQi® Facturación electrónica",
  //     img: "https://http2.mlstatic.com/D_NQ_NP_728826-MLM45981580837_052021-O.webp",
  //     text:
  //       "¡FACTURAR EN LÍNEA NUNCA FUE TAN SENCILLO! Emite todo tipo de factura electrónica y comprobante fiscal en línea para que cumplas con tus obligaciones fiscales.",
  //       add:"Garantía de satisfacción.",
  //       add2:"Acceso a grupo de WhatsApp para atención de dudas.",
  //       add3:"Certifición del Sistema",
  //       instructor:"Marco Antonio Sánchez Álvarez",
  //       instructor:"Marco Antonio Sánchez Álvarez"
  //   },
  //   {
  //     id: 7,
  //     name: "CONTPAQi® Punto de venta",
  //     img: "https://compusaltillo.mx/wp-content/uploads/2020/06/logo-punto-de-venta-contpaqi.png",
  //     text:
  //       "TOMA EL CONTROL DE TODO TU NEGOCIO, CONOCE NUESTRO SOFTWARE DE PUNTO DE VENTA Digitaliza tu punto de venta gracias a nuestro software, con el cual podrás mejorar la experiencia de tus clientes y agilizar el control de tus ingresos.",
  //     add:"Garantía de satisfacción.",
  //     add2:"Acceso a grupo de WhatsApp para atención de dudas.",
  //     add3:"Certifición del Sistema",
  //     instructor:"Marco Antonio Sánchez Álvarez"
  //   },
  //   {
  //     id:8,
  //     name: "CONTPAQi® Producción",
  //     img: "https://idnube.com/wp-content/uploads/2020/06/CONTPAQi_submarca_Produccion_RGB_C.png",
  //     text:
  //       "EXPANDE TU NEGOCIO CON UN SISTEMA DE CONTROL DE PRODUCCIÓN. Mantén el control de tu planta productiva y organiza sus operaciones con nuestro software para procesos de producción, el cual te ayudará a ahorrar tiempo para que te dediques a lo que es importante.",
  //       add:"Garantía de satisfacción.",
  //       add2:"Acceso a grupo de WhatsApp para atención de dudas.",
  //       add3:"Certifición del Sistema" ,
  //       instructor:"Marco Antonio Sánchez Álvarez"
  //   }
  // ];