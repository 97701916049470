import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'antd/dist/antd.css';
import actionsAPI from "./actionsAPI";
import { Card,Button} from 'antd';
import {
  MDBMask,
  MDBView,
  MDBAnimation,
} from "mdbreact";
import "./index.css";
import swal from "sweetalert";
import ads from '../imagen/logo_color_azul.png'
class Signup_Plataform extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            pass: '',
            nombre: '',
            apellidos: '',
            telefono:'',
            pass1:'',
            isPasswordShown: false,
        }
      }
    componentWillMount(){
        localStorage.clear()
    }      

    onSubmitBtn(){
        if(this.state.user,this.state.pass,this.state.nombre,this.state.apellidos,this.state.telefono,this.state.pass1){
            if(this.state.pass1 === this.state.pass){              
            actionsAPI.register_plataform_curse(this.state.user,this.state.pass,this.state.nombre,this.state.apellidos,this.state.telefono,this.state.pass1).then(response=>{
                console.log(response)
                if(response.data.data.register_plataform_curse.message === "signup exitoso"){
                    swal({
                        title:"Te has registrado exitosamente",
                        text:`Te has registrado a la plataforma de cursos, ahora tendrás nuevas promociones y beneficios que nuestro sistema tiene para tí.`,               
                        icon:"success",
                    });
                    setTimeout(()=>{
                        this.props.history.push("/courses");
                    },5000)
                }
                else{
                    swal({
                        text:`Ocurrió un error`,               
                        icon:"error",
                    });
                }
            }).catch(err=>{
                console.log(err.response)
            })
            }else{
                swal({
                    text:`Las contraseñas no coinciden`,               
                    icon:"error",
                });
            }
        }else{
            swal({
                text:`No deje espacios vacios`,               
                icon:"error",
            });
        }
        
    }
    onChangeInput =(e)=>{
    const {id,value} = e.target;
    this.setState({
        [id]:value
    })
    }

    render() {
    let titulo = <center><h4 className="h4tag strong">Plataforma de cursos</h4><br/><h4 className="h4tag strong">Registrarme</h4></center>
    return(
    <div>
     <div id="apppage" >  
      <MDBView>
        <MDBMask >
        <div style={{marginTop:"3%"}} className="center">
            <div className="rows">
            <MDBAnimation type="fadeInRight" delay=".3s">
            <Card title = {titulo} style={{width:"120%",padding:"3em"}}>
                <br/><br/>
                <center>
                <img  src ={ads} style={{width:180,height:60}}/>
                </center>
                <br/><br/>
                <form onSubmit={this.onSubmitBtn}>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Nombre"                                  
                        id="nombre"
                        type="nombre"
                        name="nombre"
                        onChange={this.onChangeInput}
                        value={this.state.nombre}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Apellidos"                                  
                        id="apellidos"
                        type="apellidos"
                        name="apellidos"
                        onChange={this.onChangeInput}
                        value={this.state.apellidos}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Teléfono"                                  
                        id="telefono"
                        type="telefono"
                        name="telefono"
                        onChange={this.onChangeInput}
                        value={this.state.puesto}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Correo"                                  
                        id="user"
                        type="text"
                        name="user"
                        onChange={this.onChangeInput}
                        value={this.state.user}
                    />
                    <div class="input-icon">
                    <i class="fa fa-user"></i>                   
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Contraseña"                                  
                        id="pass"
                        type="password"
                        name="pass"
                        onChange={this.onChangeInput}
                        value={this.state.pass}
                    />
                    <div class="input-icon">
                    <i class="fa fa-key"></i>                  
                    </div>
                </div>
                <div class="input-group input-group-icon">
                    <input
                        placeholder="Confirmar contraseña"                                  
                        id="pass1"
                        type="password"
                        name="pass1"
                        onChange={this.onChangeInput}
                        value={this.state.pass1}
                    />
                    <div class="input-icon">
                    <i class="fa fa-key"></i>                  
                    </div>
                </div>
                <div className="text-center mb-4 mt-5">
                    <Button
                    type='primary'
                    onClick={e=> this.onSubmitBtn()}
                    className='btn-block z-depth-1'
                    size="sm"
                    >
                    Registrarme
                    </Button>
                </div>
                </form>
                <center>
                <font color="green"><a href="/login_plataform">Iniciar sesión</a></font>
                </center>
            </Card>
            </MDBAnimation>   
            </div>  
            </div>
        </MDBMask>
    </MDBView>
    </div>
    </div>
  )        
}
}

export default Signup_Plataform;