import React from 'react'
import {MDBBtn,MDBCardBody,MDBAlert,MDBContainer,MDBView,MDBModalHeader,MDBCol} from 'mdbreact';
import {Grid  } from '@material-ui/core';
import '@fortawesome/fontawesome-free/css/all.min.css';
 import'bootstrap-css-only/css/bootstrap.min.css'; 
import'mdbreact/dist/css/mdb.css';
import { DialogUtility } from '@syncfusion/ej2-popups';
import Paper from '@material-ui/core/Paper';
import './style.css'
import { Button } from 'reactstrap';
import Navbar from '../paneldeConection/navbar'
import {Input} from 'antd'
  function onSubmit (values) {
  };
    class Validacion extends React.Component {
        constructor(props) {
          super(props);
          this.state = {
         
          }
          this.cerrar = this.cerrar.bind(this) 
        }
        
        cerrar(){
          this.props.history.push("/")
        }


    ingresar(values){       
        if(values.contraseña === "admin"){
           this.props.history.push("/RegistrarEmpresa")     
        }else if(!values.contraseña){  
            DialogUtility.alert({
            title: "Por favor ingrese algun valor"                       
             });       
        }else{
            
            DialogUtility.alert({
            title: "Contraseña incorrecta"                       
             });
        }
    }    

     render() {    
  
          return (
            <React.Fragment>
           <Navbar/>
             <form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, submitting,values }) => (
                      <form onSubmit={handleSubmit}>
                      <div id="apppages2">
                         <MDBView>
                            <MDBContainer  style={{ marginTop:"5%"}} >                             
                              <MDBCol md="6">
                            <Paper >  
                              <Button  onClick={this.cerrar} close />
                                <MDBModalHeader >Ingrese la Contraseña </MDBModalHeader>
                                <MDBCardBody> 
                                 <center>  
                                  <MDBAlert color="primary"  >
                                <strong><i>Ingrese la Contraseña  para Registrar su Empresa</i>  </strong> 
                                </MDBAlert>                     
                                <Grid item xs={7}>                                                             
                                <Input
                                
                                required
                                name="contraseña"
                                type="password"
                                placeholder="Clave de acceso"
                                /> 
                                </Grid>                               
                                <MDBBtn 
                                style={{marginTop:"5%"}}
                                color="info"
                                size="md"
                                type="submit"
                                disabled={submitting}
                                onClick={(e) =>this.ingresar(values)}>Ingresar </MDBBtn>
                               </center>
                                </MDBCardBody>                                                           
                            </Paper> 
                            </MDBCol > 
                            </MDBContainer>
                             </MDBView>
                            </div>
                      </form>
                    )}
                  />                 
            </React.Fragment>
          );
        }
      }
      export default Validacion