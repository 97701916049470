import React, {Component}from 'react'
import 'antd/dist/antd.css';
import  './clientes.css';
import { Layout} from 'antd';
import { DesktopOutlined,OrderedListOutlined,CloseOutlined,FilePdfOutlined, SendOutlined,ClusterOutlined } from '@ant-design/icons';
  import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ADS from '../imagen/ADS.png'
import TablaEventos from './eventosEvenbrite'
import SoporteTecnico from './SoporteTecnico'
import Cotizaciones from './cotizaciones'
import MisSolicitudes from './misSolicitudes'
import Iframe from './viewVideosPrivados'
import DepuracionBase from '../depuracionBasa/index'
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import factura from '../imagen/boleto.png'
import {MDBIcon} from 'mdbreact'
import { Button, Card, Modal, Tag, Menu, Dropdown, message, Space, Input, DatePicker, Popconfirm, Spin, Switch, Alert } from 'antd';
import { DownOutlined, SettingOutlined,TagOutlined } from '@ant-design/icons';
import SolicitudCotizacion from './solicitudCotizacion';
import MisSoportes from './misSoportes'
// import ChatSoporte from '../clientChat/index'

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

class SiderDemo extends Component {
  constructor(props){
    super(props)
    this.state = {      
      collapsed: false, 
      tablaInicio:true,      
      tablaEventos:false,     
      cotizaciones:false,
      misSolicitudes:false,     
      solicitudCotizacion:false,
      misSoportes:false,
      soporte:false,
      iframe:false,
      base:false, 
      poliza:[],
      cuponDisponible:false,
      cuponPolizaActiva:[],
      cuponPolizaVencida:[]
    };
    this.cerrar = this.cerrar.bind(this) 
  }


  componentWillMount = async () => {
    let filter;
    let cuponPolizaActiva;
    let cuponPolizaVencida;
    let fk_empresa = localStorage.getItem("fk_empresa")

    axios({
      url:API,
      method:'post',
      data:{
          query:`
          query{
          getCupones(data:"${[fk_empresa]}"){   
            id_cupones
            codigo
            descripcion
            descuento
            polizaActivaVencida
            cuponActivo
            url
            id_Evento
            fechaExpiracion
          } 
          }
          `
      }   
      })
      .then(response=>{
         filter = response.data.data.getCupones.filter(function(data){
          return data.cuponActivo === "true"
        })
         cuponPolizaActiva = filter.filter(function(hero){
          return hero.polizaActivaVencida === "1"
        })
         cuponPolizaVencida = filter.filter(function(hero){
          return hero.polizaActivaVencida === "2"
        })
        this.setState({cuponPolizaActiva:cuponPolizaActiva})  
        this.setState({cuponPolizaVencida:cuponPolizaVencida})  
      })
      .catch(err=>{
              console.log('error',err.response)
      })  
  }

  cerrar(){
    this.props.history.push("/")
  } 
 
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  tablaSoporte(){
    this.setState({tablaInicio:false});
    this.setState({soporte:true});
    this.setState({tablaEventos:false});
    this.setState({iframe:false});
    this.setState({base:false});
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:false});
    this.setState({misSoportes:false});
  }
  iframe(){
    this.setState({tablaInicio:false});
    this.setState({soporte:false});
    this.setState({tablaEventos:false});
    this.setState({iframe:true});
    this.setState({base:false});
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:false});
    this.setState({misSoportes:false});

  }

  generarCotizaciones(){
    this.setState({tablaInicio:true});
    this.setState({soporte:false});
    this.setState({tablaEventos:false});
    this.setState({iframe:false});
    this.setState({base:false})
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:false});
    this.setState({misSoportes:false});

  }

   tablaEventos(){
    this.setState({tablaInicio:false});
    this.setState({soporte:false});
    this.setState({tablaEventos:true});
    this.setState({iframe:false});
    this.setState({base:false});
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:false});
  } 
  depuracionBase(){
    this.setState({tablaInicio:false});
    this.setState({soporte:false});
    this.setState({tablaEventos:false});
    this.setState({iframe:false});
    this.setState({base:true});
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:false});
  }
  solicitudCotizacion(){
    this.setState({tablaInicio:false});
    this.setState({soporte:false});
    this.setState({tablaEventos:false});
    this.setState({iframe:false});
    this.setState({base:false});
    this.setState({solicitudCotizacion:true});
    this.setState({misSolicitudes:false});
    this.setState({misSoportes:false});

  }

  misSolicitudes(){
    this.setState({tablaInicio:false});
    this.setState({soporte:false});
    this.setState({tablaEventos:false});
    this.setState({iframe:false});
    this.setState({base:false});
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:true});
    this.setState({misSoportes:false});
  }
  misSoportes(){
    this.setState({tablaInicio:false});
    this.setState({soporte:false});
    this.setState({tablaEventos:false});
    this.setState({iframe:false});
    this.setState({base:false});
    this.setState({solicitudCotizacion:false});
    this.setState({misSolicitudes:false});
    this.setState({misSoportes:true});

  }

  render() {   
    let tabla;
    let eventos;  
    let iframe;
    let cotizaciones; 
    let misSoportes; 
    let base;  
    let solicitudCotizacion;    
    let misSolicitudes;    
    let cuponPolizaActiva = this.state.cuponPolizaActiva;
    let cuponPolizaVencida = this.state.cuponPolizaVencida;
    if(this.state.soporte === true) {  
      tabla=
      <div>
          <SoporteTecnico/>
      </div>
    }
       
    if(this.state.tablaEventos === true){ 
      eventos=  
      <div>
          <TablaEventos/>
      </div>
    }
   
     if(this.state.tablaInicio === true){
     cotizaciones=   
     <div>
       <Cotizaciones cuponPolizaActiva = {cuponPolizaActiva} cuponPolizaVencida = {cuponPolizaVencida}/>  
     </div>   
     }

    if(this.state.iframe === true){
      iframe=
       <div>
         <Iframe/>
       </div>
     }
     
     if(this.state.base === true){
      base=
       <div>
         <DepuracionBase/>
       </div>
     }
     if(this.state.solicitudCotizacion === true){
      solicitudCotizacion=
       <div>
         <SolicitudCotizacion/>
       </div>
     }
     if(this.state.misSolicitudes === true){
      misSolicitudes =
       <div>
         <MisSolicitudes/>
       </div>
     }
     if(this.state.misSoportes === true){
      misSoportes =
       <div>
         <MisSoportes/>
       </div>
     }
    let rs = localStorage.getItem("razonSocial")
    let rfc = localStorage.getItem("rfc")
    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    date = mm + '/' + dd + '/' + yyyy; 


    return (  
    <Layout>
    <Header className="header ant-layout-header12"> 
          <img href="/dashboard" src={ADS} style={{width:"10%"}}/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       
           <font color="#fff" size="4">{rfc}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {rs} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {<CalendarTodayIcon style={{ fontSize: '25px', color: '#fff' }}/>}&nbsp; {date}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</font> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </Header>
    <Content>
      <Layout className="site-layout-background22" >
        <Sider className="site-layout-background32" width={220}>        
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%' }}
          >
            <Menu.Item key="1" onClick={e=>this.generarCotizaciones()} icon={<OrderedListOutlined style={{ fontSize: '25px', color: '#00000' }}/>}>
              Inicio           
            </Menu.Item> 
            <Menu.Item key="2" onClick={e=>this.tablaSoporte()} icon={<OrderedListOutlined style={{ fontSize: '25px', color: '#00000' }}/>}>
              Soporte Técnico           
            </Menu.Item>    
            <Menu.Item key="3" onClick={e=>this.tablaEventos()} icon={<DesktopOutlined style={{ fontSize: '25px', color: '#00000' }} />}>           
               Eventos de ADS              
             </Menu.Item> 
             <Menu.Item key="4" onClick={e=>this.iframe()} icon={<DesktopOutlined style={{ fontSize: '25px', color: '#00000' }} />}>           
               Videos exclusivos             
             </Menu.Item> 
             <Menu.Item key="5" onClick={e=>this.solicitudCotizacion()} icon={<FilePdfOutlined style={{ fontSize: '25px', color: '#00000' }} />}>           
               Solicitar cotizaciones             
             </Menu.Item>
             <Menu.Item key="6" onClick={e=>this.misSolicitudes()} icon={<SendOutlined style={{ fontSize: '25px', color: '#00000' }} />}>           
               Mis solicitudes             
             </Menu.Item>
             <Menu.Item key="7" onClick={e=>this.misSoportes()} icon={<ClusterOutlined  style={{ fontSize: '25px', color: '#00000' }} />}>           
               Mis soportes             
             </Menu.Item>
             <Menu.Item key="8" onClick={e=>this.cerrar()} icon={<CloseOutlined  style={{ fontSize: '25px', color: '#00000' }} />}>
              cerrar sesión
            </Menu.Item>
            {/* <Menu.Item key="8" onClick={this.DepuracionBase()} icon={<CloseOutlined  style={{ fontSize: '25px', color: '#00000' }} />}>
              DEPURACION bASE
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Content style={{ padding: '0 24px', minHeight: 580 }}>
        {eventos}
        {tabla}
        {cotizaciones}
        {iframe}
        {base}
        {solicitudCotizacion}
        {misSolicitudes}
        {misSoportes}
        {/* <ChatSoporte></ChatSoporte> */}
        </Content>
      </Layout>
    </Content>
  </Layout>
    );
  }
}

export default SiderDemo;