import React, { Component } from 'react';
import { Input,Card,Button } from 'antd';
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import swal from 'sweetalert';
import { Select,Alert  } from 'antd';
import {MDBBtn, MDBModal, MDBModalHeader,MDBCardImage,MDBCard,MDBCol,MDBContainer,MDBRow,MDBIcon} from 'mdbreact'
import {Row,Col,Form} from 'reactstrap';
import {MDBInput } from "mdbreact";
import { DialogUtility } from '@syncfusion/ej2-popups';
import MUIDataTable from "mui-datatables";
import { InputNumber, Tag, Modal, DatePicker} from 'antd';
import moment from 'moment';
import SignupCliente from './signupClientes'

const { Search } = Input;

const { Option } = Select;

class RegisterPolizas extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value:"",
            cardInicial:true,
            cardDataCliente:false,
            dataCliente:[],
            contactoCliente:[],
            signupContacto:false,
            nombre: "",
            apellido: "",
            correo1: "",
            correo2: "",
            telefono1: "",
            ext: "",
            telefono2: "",
            puesto:"", 
            tipoContacto:'',
            cardSearch:false,
            id_contacto:'',
            busqueda:'',
            tablaBusqueda:[],
            tablaProductoServicio:[],
            array:[], 
            polizasGenerales:[],
            tablaPolizas:false,
            inputFields:[],
            arrayPiezas:[],
            deletePiezas:[],
            buttonTablePolizas:true,
            tableVerifyData:false,
            selectionProducts:[],
            productsSelect:[],
            changeCantidad:false,
            valueInputNumber:'',
            todos: [],
            disabled: [],
            disableInputNumber:true,
            textCantidadesModificadas:'',
            notaText:'',
            buttonCancelSearch:false,
            finalTableProcess:false,
            dataRegister:[],
            activarPoliza:false,
            fechaInicial:'',
            fechaFinal:'',
            isModalVisible:false,
            maxid:'',
            arrayPoliza:[],
            param:'',
            buttonCancelventa:true,
            signupCliente:false,
        }
        this.onChangeOptionContact = this.onChangeOptionContact.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.onChangeInputNumber = this.onChangeInputNumber.bind(this)
        this.capturarFecha = this.capturarFecha.bind(this);
        this.capturarFechaFinal = this.capturarFechaFinal.bind(this)
    }

    componentDidMount(){
        this.setState({tablaBusqueda:this.state.tablaProductoServicio});
    }
    componentWillMount = async()=>{
         window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        localStorage.removeItem("id")
        localStorage.removeItem("tipo")
        localStorage.removeItem("concepto")
        localStorage.removeItem("precio") 
        localStorage.removeItem("consecutivo")
        let fk_empresa =  localStorage.getItem("fk_empresa")
        var data;
        await axios({
        url:API,
        method:'post',
        data:{
            query:`
            query{   
                getTablaProductoServicio(data:"${[fk_empresa]}"){
                id_productoServicio
                tipo
                tipoLicenciamiento
                lineaProducto
                concepto
                precio
                consecutivo
                id_actualizacion
                asignacion
                fechaRegistro
                fk_empresa
                message
                } 
            }
            `  }           
            })
            .then((datos) => {   
                data = datos.data.data.getTablaProductoServicio
                let dataArray = []
                data.map(rows=>{
                  if(rows.asignacion && rows.id_actualizacion){
                    dataArray.push(rows)
                  }
                })
             
                let filtered = dataArray.reduce((acc, item) => {
                  if (!acc.some((e, i) => {
                      if (item.asignacion === e.asignacion) {
                        if (item.id_actualizacion > e.id_actualizacion) {
                          acc.splice(i, 1, item);
                        }
                        return true;
                      }
                    })) {
                    acc.push(item);
                  }
                  return acc;
                }, []);
                this.setState({array:filtered})
            }).catch(err=>{
                console.log("err",err.response)
                console.log("err",err)  
            })
          }
    filtrarElementos  = () => {
        this.setState({buttonCancelSearch: true})
        this.setState({buttonTablePolizas: false})
        var search = this.state.array.filter((item)=>{   
          if(item.consecutivo.toString().includes(this.state.busqueda) || item.concepto.replace(/[.*`+\-?^${}()|[\]\\]/g,'\\$&').includes(this.state.busqueda)|| item.concepto.toUpperCase().includes(this.state.busqueda) || item.concepto.toLowerCase().includes(this.state.busqueda)){
            return item
          }
          // const tipoTrimestral = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchTipoTrimestral) || val.includes(searchTipoTrimestral2) || val.includes(searchTipoTrimestral3)));
        })
        const searchPoliza  = 'Poliza';
        const searchPoliza2 = 'POLIZA';
        const searchPoliza3 = 'PÓLIZA';
        const searchPoliza4 = 'Póliza';
        const searchPoliza5 = 'poliza';
        const searchPoliza6 = 'póliza';
        const polizas = search.filter(obj => Object.values(obj).some(val => {
            if(val){
                return val.includes(searchPoliza) || val.includes(searchPoliza2) || val.includes(searchPoliza3) || val.includes(searchPoliza4) || val.includes(searchPoliza5) || val.includes(searchPoliza6)
            }
        }));
        this.setState({tablaBusqueda: polizas})
      }
    onChange(e){
        this.setState({value:e.target.value})
    }
    onChangeValue = async (e) => {
        e.persist();
        await this.setState({busqueda:e.target.value})
        this.filtrarElementos()
    }
    onChangeInput = (e) => {
        const { id, value } = e.target;
        this.setState({
          [id]: value
        });
    }; 
    handleCancel = () => {
        this.setState({isModalVisible:false})
    };
    process(){
        let value = this.state.value
        axios({
            url: API,
            method: "post",
            data: {
                query: `
                    query{
                        getClienteRFC(data:"${[value]}"){
                            id_cliente
                            razonSocial
                            rfc
                            telefono
                            correo
                            nombreRepresentante            
                           } 
                    }
                `
            }
            }).then((response) => {  
                let data = response.data.data.getClienteRFC;
                if(data[0]){
                    this.setState({cardInicial:false})   
                    this.setState({cardDataCliente:true})    
                    this.setState({dataCliente:data[0]})
                    axios({
                        url:API,
                        method:'post',
                        data:{
                            query:`
                            query{
                              getContactosId(data:"${[data[0].id_cliente]}"){
                                id_contacto
                                nombre
                                apellidos
                                correo1
                                correo2
                                telefono1
                                extensionTelefonica
                                telefono2
                                puesto 
                                fk_clientesads                               
                               } 
                            }
                            `
                        }   
                         }).then(response=>{
                            let values = response.data.data.getContactosId
                            this.setState({contactoCliente:values})
                          })
                         .catch(err=>{
                             console.log('error',err)
                         }) 
                }else{
                    swal({
                        title:"Aviso!",
                        text:"Cliente no encontrado",
                        buttons: false,
                        icon:"error"
                    });
                }
            }).catch(err=>{
                console.log("error",err.response)
                console.log("error",err)
            })
    }
    newSearch(){
        this.setState({dataCliente:[]})
        this.setState({cardDataCliente:false})
        this.setState({cardInicial:true})
        this.setState({contactoCliente:[]})
        this.setState({value:''})

    }
    registerContacto(){
        this.setState({cardDataCliente:false})
        this.setState({signupContacto:true})
    }
    cerrar(){
        this.setState({cardDataCliente:true})
        this.setState({signupContacto:false})
    }
    
    onSubmitBtn = () => {
        let dataCliente = this.state.dataCliente
        let idCliente = dataCliente.id_cliente
         let nombre = this.state.nombre.toUpperCase();
         let apellidos = this.state.apellido.toUpperCase();
         let correo1 = this.state.correo1
         let correo2 = this.state.correo2
         let telefono1 = this.state.telefono1
         let ext = this.state.ext
         let telefono2 = this.state.telefono2
         let puesto = this.state.puesto.toUpperCase();
         let tipoContacto = this.state.tipoContacto
  
         if( nombre && apellidos && correo1 && telefono1 ){
           axios({
          url: API,
          method: "post",
          data: {
            query: `
             mutation{
              insertContacto(data:"${[nombre,apellidos,correo1,correo2,telefono1,ext,telefono2,puesto,tipoContacto,idCliente]}"){         
            message
            } 
        }
        `
          }
        })
          .then((response) => { 
            if(response.data.data.insertContacto.message === "registro exitoso"){
              this.setState({
                nombre: "",
                apellido: "",
                correo1: "",
                correo2: "",
                telefono1: "",
                ext: "",
                telefono2: "",
                puesto:"", 
                tipoContacto:""   
                });
                swal({
                  title:"Aviso!",
                  text:"Registro exitoso",
                  buttons: false,
                  icon:"success"
                });
            }else{
              swal({
                title:"Aviso!",
                text:"Algo salió mal, por favor inténtelo nuevamente",
                buttons: false,
                icon:"error"
              });
            }
          })
          .catch((err) => {
            console.log("error", err.response);
          }); 
        }else{
          swal({
            title:"Aviso!",
            text:"Complete los campos: Nombre,Apellidos,Correo,Teléfono",
            buttons: false,
            icon:"warning"
          });
            }      
      };
    nextProcess1(){
        if(this.state.id_contacto){
            this.setState({cardDataCliente:false})
            this.setState({signupContacto:false})
            this.setState({cardInicial:false})
            this.setState({cardSearch:true})
        }else{
            swal({
                title:"Aviso!",
                text:"Seleccione un contacto",
                buttons: false,
                icon:"warning"
            });
        }
    }
    onChangeOptionContact(e){
    this.setState({id_contacto:e})
    }

    tablePolizas(){
        this.setState({cardDataCliente:false})
        this.setState({signupContacto:false})
        this.setState({cardInicial:false})
        this.setState({cardSearch:false})
        this.setState({tablaPolizas:true})

        const searchPoliza  = 'Poliza';
        const searchPoliza2 = 'POLIZA';
        const searchPoliza3 = 'PÓLIZA';
        const searchPoliza4 = 'Póliza';
        const searchPoliza5 = 'poliza';
        const searchPoliza6 = 'póliza';
        let search = this.state.array;
        const polizas = search.filter(obj => Object.values(obj).some(val => {
            if(val){
                return val.includes(searchPoliza) || val.includes(searchPoliza2) || val.includes(searchPoliza3) || val.includes(searchPoliza4) || val.includes(searchPoliza5) || val.includes(searchPoliza6)
            }
        }));
        this.setState({polizasGenerales:polizas})
    }
    closeTablePolizas(){
        this.setState({cardDataCliente:false})
        this.setState({signupContacto:false})
        this.setState({cardInicial:false})
        this.setState({cardSearch:true})
        this.setState({tablaPolizas:false})
        this.setState({buttonTablePolizas:true})
        this.setState({busqueda:''})
        this.setState({inputFields:[]})
        this.setState({buttonCancelSearch:false})
    }

    handleAddFields = async(rows) => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth',
        // }); scrollear hacia arriba
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
        });
    const values = [...this.state.inputFields];
    values.push({rows});

    let arrayProductsSelect = [];
    values.map(rows=>{
            arrayProductsSelect.push(rows)
    })
    this.setState({inputFields:arrayProductsSelect})
    }

    deletePiezas  = async(id_productoServicio) =>{
        const values = [...this.state.deletePiezas];
        values.push({id_productoServicio});
        await this.setState({deletePiezas:values})

        var result = values.reduce((unique, o) => {
            if(!unique.some(obj => obj.id === o.id && obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
        },[]);

        var counter = {}
             values.forEach(function(obj) {
                var key = obj.id
                counter[key] = (counter[key] || 0) + 1
            })
    }
    nextProcess2(array){
        this.setState({cardDataCliente:false})
        this.setState({signupContacto:false})
        this.setState({cardInicial:false})
        this.setState({cardSearch:false})
        this.setState({tablaPolizas:false})
        this.setState({buttonTablePolizas:false})
        this.setState({busqueda:''})
        this.setState({tableVerifyData:true})
        this.setState({selectionProducts:array})
            window.scrollTo({
            top: 0,
            behavior: 'smooth',
            }); 
        let newArray = [];
        array.forEach((param)=>
        newArray.push({
            "asignacion":param.asignacion,
            "PrecioTotal":(param.precio*param.piezas),
            "concepto":param.concepto,
            "consecutivo":param.consecutivo,
            "fechaRegistro":param.fechaRegistro,
            "fk_empresa":param.fk_empresa,
            "id_actualizacion":param.id_actualizacion,
            "id_productoServicio":param.id_productoServicio,
            "lineaProducto":param.lineaProducto,
            "piezas":param.piezas,
            "precio":param.precio,
            "tipo":param.tipo,
            "tipoLicenciamiento":param.tipoLicenciamiento,
        })
        );
        this.setState({productsSelect:newArray})
    }
    onChangeInputNumber = async(piezas,id,precio) => {
        let precioTotal = (piezas * precio)
        const values = [...this.state.arrayPiezas];
        values.push({"PrecioTotal":precioTotal,"id_productoServicio":id,"piezas":piezas})
        await this.setState({arrayPiezas:values})
      };
    changeCantidad  = ()=>{
        this.setState({changeCantidad:true})
        this.setState({disableInputNumber:false})
    }  
    cancelarCambios(){
        this.setState({disableInputNumber:true,
            textCantidadesModificadas:"Cantidades modificadas el nuevo cálculo se reflejará en el siguiente proceso"
        })
        this.setState({changeCantidad:false})
        this.setState({todos:[]})
        this.setState({disabled:[]})
    }
    returnVerifyData(){
        this.setState({cardDataCliente:false})
        this.setState({signupContacto:false})
        this.setState({cardInicial:false})
        this.setState({cardSearch:false})
        this.setState({tablaPolizas:true})
        this.setState({buttonTablePolizas:false})
        this.setState({busqueda:''})
        this.setState({tableVerifyData:false,
        valueInputNumber:''
        })  
        this.setState({changeCantidad:false}) 
        this.setState({disableInputNumber:true})
    }
    nextProcess3(){
        this.setState({finalTableProcess:true})
        this.setState({tableVerifyData:false})

        let param = this.state.productsSelect
        let array  =  this.state.arrayPiezas;
        let arrayFiltered = [];
        array.forEach(obj => {
            const item = arrayFiltered.find(thisItem => thisItem.id_productoServicio === obj.id_productoServicio);
            if (item) {
                if (item.PrecioTotal < obj.PrecioTotal) {
                        item.PrecioTotal = obj.PrecioTotal;
                        item.piezas = obj.piezas;
                }
                return;
            }
            
            arrayFiltered.push(obj);
        });
        let arr3 = param.map((item, i) => Object.assign({}, item, arrayFiltered[i]));
        let  arr4 = arr3.filter(item => !(item.piezas < 1));
        this.setState({dataRegister:arr4})
    }
    appliChangesCantidad(){
        this.setState({
            disableInputNumber:true,
        })
    }
    returnContactoSelect(){
        this.setState({cardDataCliente:true})
        this.setState({cardSearch:false}) 
    }
    buttonCancelSearch(){
        this.setState({buttonCancelSearch:false})
        this.setState({buttonTablePolizas:true})
        this.setState({tablaPolizas:false})
        this.setState({busqueda:''})
    }
    RegisterPoliza(){
        this.setState({buttonCancelventa:false})
        var d = new Date();
        var hh = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds(); 
        if (hh >= 12) {
          hh = hh - 12;    
        }
        if (hh == 0) {
          hh = 12;
        }
        let horas= hh + ":" + m + ":" + s   
          var f = new Date();
          var dd = f.getDate();
          var mm = f.getMonth()+1;
          var yyyy = f.getFullYear()
          if(dd<10) {
            dd='0'+dd;
          } 
          if(mm<10) {
            mm='0'+mm;
          }    
        let fechaEmision =  dd+"/"+mm+"/"+yyyy + "/" + horas

        let año  = new Date().getFullYear()
        function generateUUID() {
            var d = new Date().getTime();
            var uuid = 'xAxxyx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        }
        let array = []

        let folio = (año + generateUUID()).toUpperCase();
        let idAdminAlfa = localStorage.getItem("id_admin")
        let idEmpresa = localStorage.getItem("fk_empresa")
        let fechaExpiracion = new Date()
        let id_contacto = this.state.contactoCliente[0].id_contacto
        let id_cliente = this.state.dataCliente.id_cliente
        let descuento = 0;
        let descuentoAplicado = 0;
        let tipoSolicitud ="Prospecto"
        this.state.dataRegister.map(rows=>{
        let  id_productoServicio  = rows.id_productoServicio
        let cantidad = rows.piezas
        let totalPrecio = rows.PrecioTotal
        let ProductoPrecioUnitario = rows.precio
        let calculodeIVAMoreTotalPrecio = totalPrecio * 0.16; 
        let importe = totalPrecio + calculodeIVAMoreTotalPrecio;
        let statusPoliza ="inactiva"
        let banco = "Sin banco asignado";
        let tipoPago = "Sin tipo de pago asignado";
        let referencia = "referencia no asignada";
        let arrayPoliza = [];
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                mutation{
                insertCotizaciones(data:"${[fechaEmision,folio,cantidad,descuento,descuentoAplicado,totalPrecio,id_cliente,id_productoServicio,idAdminAlfa,idEmpresa,fechaExpiracion,id_contacto,tipoSolicitud]}"){
                    message
                    } 
                }
                `
            }   
            }).then(response=>{
            if(response.data.data.insertCotizaciones.message === "registro exitoso"){
            swal({
                title:"Aviso!",
                text:"Se registró correctamente la póliza",
                buttons: false,
                icon:"success"
            });
            }else{
            swal({
                title:"Aviso!",
                text:"Upps Algo salió mal.",
                buttons: false,
                icon:"error"
            });
            }
            })
            .catch(err=>{
                console.log('error',err.response)
                // console.log('error',err)
    
            })   
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{              
                      ventas(data:"${[folio,cantidad,descuento,descuentoAplicado,totalPrecio,ProductoPrecioUnitario,calculodeIVAMoreTotalPrecio,fechaEmision,horas,banco,referencia,tipoPago,importe,fechaEmision,statusPoliza,id_productoServicio,id_cliente,idAdminAlfa,idEmpresa,id_contacto]}"){
                         message
                        } 
                    }
                    `
                }              
              }).then(response=>{
                if(response.data.data.ventas.message === "registro exitoso"){
                    swal({
                        title:"Aviso!",
                        text:"Se registó correctamente la póliza",
                        buttons: false,
                        icon:"success"
                    });
                    }else{
                    swal({
                        title:"Aviso!",
                        text:"Upps Algo salió mal.",
                        buttons: false,
                        icon:"error"
                    });
                    }
                    
                }).catch(err=>{
                  console.log('error',err.response)  
              }) 
              axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                    registerPoliza(data:"${["no vigente","no asignado","inactiva",id_productoServicio,id_cliente,id_contacto]}"){
                        message
                        maxid
                        } 
                    }
                    `
                }   
                }).then(response=>{  
                    let maxid =  response.data.data.registerPoliza.maxid
                    array.push(maxid)
                    array.map(rows=>{
                    axios({
                        url:API,
                        method:'post',
                        data:{
                            query:`
                            query{
                                getPolizasById(data:"${[rows]}"){
                                    fk_cliente
                                    id_polizas
                                    fechaInicial
                                    fechaFinal
                                    statusPoliza
                                    razonSocial
                                    tipo
                                    concepto
                                    lineaProducto
                                    rfc
                                    message
                                    maxid
                                } 
                            }
                            `
                        }   
                        }).then(response=>{  
                            let polizas =  response.data.data.getPolizasById;
                            arrayPoliza.push(polizas);
                            this.setState({arrayPoliza:arrayPoliza})
                        }).catch(err=>{
                            console.log('error',err);  
                            console.log('error',err.response);    
                        })     
                    })
                    console.log("array",array)
                }).catch(err=>{
                    console.log('error',err)  
                    console.log('error',err.response)    
  
                })
            })
            let suma = 0;  
            for (let i = 0; i < this.state.dataRegister.length; i++) {
                suma += parseFloat(this.state.dataRegister[i].PrecioTotal);    
            }
            let IVA= .16;  
            let sumaDosCeros =  suma.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
            let calcularIVA =((suma) * (IVA)).toFixed(2) 
            let sumarValor=suma + parseFloat(calcularIVA)        
            let sumarValorDosCeros = sumarValor.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2}) 
          
          axios({
            url:API,
            method:'post',
            data:{
                query:`
                mutation{
                insertTotalesVenta(data:"${[sumaDosCeros,calcularIVA,sumarValorDosCeros,folio]}"){
                     message
                    } 
                }
                `
            }   
            }).then(response=>{  
                swal({
                    title:"Aviso!",
                    text:"Registro correcto de totales",
                    buttons: false,
                });            
            }).catch(err=>{
                console.log('error',err)    
            })  
            this.setState({activarPoliza:true})
    }

    capturarFecha(e){
        if(e){  
        let fechaInicio = e._d.toString();   
          this.setState({fechaInicial:fechaInicio})                       
        }
    } 
    capturarFechaFinal(e){
        if(e){  
        let fechaFinal = e._d.toString();   
          this.setState({fechaFinal:fechaFinal})                       
        }
    } 
    modalPoliza(){
        this.setState({isModalVisible:true})
    }
    activarPoliza(param){
        let fechaInicial = this.state.fechaInicial 
        let fechaFinal = this.state.fechaFinal
        if(fechaInicial && fechaFinal){
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                    activarPoliza(data:"${[fechaInicial,param,fechaFinal]}"){
                         message
                        } 
                    }
                    `
                }   
                }).then(response=>{ 
                    this.setState({fechaInicial:''})
                    this.setState({fechaFinal:''})
                    swal({
                        title:"Póliza activada correctamente!",
                        text:`La póliza con el id ${param} ha sido activada, los cambios estarán disponibles al finalizar el proceso de venta`,
                        buttons: true,
                        icon:"success"
                    });   
                }).catch(err=>{
                    console.log('error',err)    
            })  
        }else{
            swal({
                title:"Aviso!",
                text:`Por favor complete todos los campos`,
                buttons: false,
                icon:"warning"
            });
        }
    }
    registerCliente(){
    this.setState({signupCliente:true});
    this.setState({cardInicial:false})
    }
    closeRegiterClient(){
      this.setState({signupCliente:false});
      this.setState({cardInicial:true})
    }
    retornar(){
      window.location.reload();
    }
    render() {
        const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
        const options3={ 
            elevation:0,
            filterType:"drowpdawn",
            responsive: "stacked",
            pagination:false,
            search:false,
            print:false,
            download:false,
            filter:false,
            caseSensitive:false,
            selectableRows:"none",
            viewColumns:false,      
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          }        
          } 
    
        let signupCliente;
        let buttonCerrarCliente;
        if(this.state.signupCliente === true){
          buttonCerrarCliente = <center><Button style={{width:"90%"}}type="danger" onClick={e=>this.closeRegiterClient()}>Cerrar formulario</Button></center> 
          signupCliente = <SignupCliente/>
        }

        let cardInicial;
        if(this.state.cardInicial === true){
            cardInicial = <center>
            <Card style={{width:"35%",marginTop:"2%"}} title={<h6><strong>Busqueda del cliente</strong></h6>}>
                <Input placeholder="RFC del cliente" onChange={this.onChange}/>
                <Button style={{marginTop:"2%"}} type="primary" onClick={e=>this.process()}>Buscar cliente</Button>&nbsp;&nbsp;&nbsp;
                <Button style={{marginTop:"2%"}} type="success" onClick={e=>this.registerCliente()}>Registrar cliente</Button>
            </Card>
        </center>
        }
        let cardDataCliente;
        let contactoCliente = this.state.contactoCliente
        let rs = this.state.dataCliente.razonSocial
        let rfc = this.state.dataCliente.rfc
        if(this.state.cardDataCliente === true){
        let select;
           if(contactoCliente[0]){
             select = <div><div>
             <Select
             onChange={this.onChangeOptionContact} 
             showSearch
             style={{
             width: 400,
             }}
             placeholder="Seleccione el contato a asignar"
             optionFilterProp="children"
             filterOption={(input, option) => option.children.includes(input)}
             filterSort={(optionA, optionB) =>
             optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
             }
            > {contactoCliente.map(rows=>{
                    return(
                        <Option value={rows.id_contacto}>{rows.nombre +  " " + rows.apellidos}</Option>
                    )
            })}
            </Select>
            </div>
                <Button style ={{marginTop:"2%" }} type ="danger" onClick={e=>this.newSearch()}>Nueva búsqueda</Button>&nbsp;&nbsp;&nbsp;
                <Button style ={{marginTop:"2%" }} type ="primary" onClick = {e=>this.nextProcess1()}>Siguiente</Button>

            </div>
           }else{
            select =
            <div>
                <Alert style ={{width:"50%" }}message="Cliente sin contactos registrados" type="warning" />
                <Button style ={{marginTop:"2%" }} type ="primary" onClick={e=>this.newSearch()}>Nueva búsqueda</Button>&nbsp;&nbsp;&nbsp;
                <Button style ={{marginTop:"2%" }} type ="warning" onClick = {e=>this.registerContacto()}>Registrar contacto</Button>
                </div> 
           } 
        cardDataCliente = 
        <div> 
            <center>
            <Card type="inner" style={{width:"80%",marginTop:"2%"}} title={<strong>{rs}&nbsp;&nbsp;&nbsp;{rfc}</strong>}>
                {select}
            </Card>
            </center>
        </div>
        }
        let buttonTablePolizas;
        if(this.state.buttonTablePolizas === true){
            buttonTablePolizas = <Button type="primary" onClick={e=>this.tablePolizas()}>Ver tabla de pólizas</Button>
        }
        let buttonCancelSearch;
        if(this.state.buttonCancelSearch === true){
            buttonCancelSearch = <Button type='dashed' danger onClick={e=>this.buttonCancelSearch()}>Cerrar</Button>
        }
        let cardSearch;
        if(this.state.cardSearch === true){
            cardSearch = 
            <div>
                <center>
                    <Card style={{width:"80%",marginTop:"2%"}} type='inner' title={<div><h6><strong>Selección de póliza a registrar como venta</strong></h6><strong>{rs}&nbsp;&nbsp;&nbsp;{rfc}</strong></div>} extra = {<Button type="dashed" danger onClick = {e=>this.returnContactoSelect()}>Cambiar contacto</Button>}>
                     <Search 
                        type="text"            
                        name = "busqueda"
                        placeholder = "Buscar clave del producto:"
                        required
                        className="textField"
                        value = {this.state.busqueda}
                        onChange ={this.onChangeValue}
                        style={{ width: 250}}
                    />&nbsp; &nbsp;&nbsp;{buttonTablePolizas}{buttonCancelSearch}
                    </Card>
                </center>
            </div>
            }
        let registrarContacto;
        if(this.state.signupContacto === true){     
        let titulo =  <strong><h4>Registrar Nuevo Contacto</h4></strong>  
        registrarContacto =               
          <div style={{width:"90%",marginTop:"1%",marginLeft:"5%"}}>
           <Card title={titulo}>
           <MDBRow >           
             <MDBCol size="6">
             <MDBCard style={{ width: "20rem"}}>
               <MDBCardImage className="img-fluid" 
                src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                waves />
             </MDBCard>
             </MDBCol>
             <MDBCol  size="6" style ={{marginTop:"1%"}} >
             <Form  onSubmit={this.onSubmitBtn}> 
          <Row>      
              <MDBCol md="6">
                <MDBInput label="Nombre (s) *" icon="user" id="nombre"  type="text" name="nombres" onChange={this.onChangeInput} value={this.state.nombre} required />
              </MDBCol>         
              <MDBCol md="6">
                <MDBInput label="apellidos *"
                  // icon="user"
                  id="apellido"
                  type="text"
                  name="apellido"
                  onChange={this.onChangeInput}
                  value={this.state.apellido}
                  required
                />
              </MDBCol>
            </Row> 
            <Row>
              <MDBCol md="6">
                <MDBInput
                  label="Correo *"
                  icon="envelope"
                  id="correo1"
                  type="email"
                  name="correo1"
                  onChange={this.onChangeInput}
                  value={this.state.correo1}
                  required
                />
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  label="Correo alterno"
                  icon="envelope"
                  id="correo2"
                  type="email"
                  name="correo2"
                  onChange={this.onChangeInput}
                  value={this.state.correo2}              
                />
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  label="Teléfono *"
                  icon="phone"
                  id="telefono1"
                  type="number"
                  name="telefono1"
                  onChange={this.onChangeInput}
                  value={this.state.telefono1}
                  required
                />
              </MDBCol> 
              <MDBCol md="6">
                <MDBInput
                  label="Ext."              
                  id="ext"
                  type="number"
                  name="ext"
                  onChange={this.onChangeInput}
                  value={this.state.ext}              
                />
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  label="Celular"
                  icon="mobile-alt"
                  id="telefono2"
                  type="number"
                  name="telefono2"
                  onChange={this.onChangeInput}
                  value={this.state.telefono2}              
                />
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  label="Puesto"
                  icon="id-card-alt"
                  id="puesto"
                  type="text"
                  name="puesto"
                  onChange={this.onChangeInput}
                  value={this.state.puesto}              
                />
              </MDBCol>
          </Row>
          <Row>
            <br/>
          <MDBCol md="6">   
            <select
                className="browser-default custom-select"
                type="select"
                name="tipoContacto"
                id="tipoContacto"
                onChange={this.onChangeInput}
                value={this.state.tipoContacto}
            >
              <option value="disable">Tipo de Contacto *</option>
              <option value="PAGO">Pago</option>
              <option value="ADMINISTRATIVO">Administrativo</option>
              <option value="TÉCNICO">Técnico</option>
            </select>      
              </MDBCol>
          </Row>   
          <br/>   
        <div className="text-center" >
              <MDBBtn  size = "sm" color="success" onClick={e=>this.onSubmitBtn()}>                   
                Guardar
              </MDBBtn>
              <MDBBtn
               color="danger"
               size = "sm"
               onClick={e=>this.cerrar()}
               >                   
                Cancelar
              </MDBBtn>                 
          </div>      
           </Form>
             </MDBCol>           
           </MDBRow>
           </Card> 
           </div>      
          }
          let dataTablaPolizas
          let tablaPoliza;
          let tableSelect;
          let array = [];
          let nextProcess2;
          if(this.state.inputFields[0]){
            this.state.inputFields.map(rows=>{
                array.push(rows.rows)
            })
            var result = array.reduce((unique, o) => {
                if(!unique.some(obj => obj.id_productoServicio === o.id_productoServicio && obj.id_productoServicio === o.id_productoServicio)) {
                  unique.push(o);
                }
                return unique;
            },[]);

            var counter = {}
             array.forEach(function(obj) {
                var key = obj.id_productoServicio
                counter[key] = (counter[key] || 0) + 1
            })
            let array3 = []
            let arr;
            arr = result.map((item,i) => {
                array3.push({"piezas":counter[item.id_productoServicio]})
                return Object.assign({}, item, array3[i])
            });
            nextProcess2 = <Button style={{marginTop:"2%"}} type='primary' onClick = {e=>this.nextProcess2(arr)}>Siguiente</Button>

            tableSelect = 
            <center>
                <div style={{marginTop:"3%"}}>
                    <Card type='inner' title={<h6><strong>Productos seleccionados a procesar como venta</strong></h6>}>
                    <table className='table table-bordered table table-striped table table-small'>
                        <tr>
                            <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Id</strong></th>
                            <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Tipo</strong></th>
                            <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Concepto</strong></th>
                            <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Precio</strong></th>
                            <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Consecutivo</strong></th>
                            <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Cantidad</strong></th>
                        </tr>
                    
                        {arr.map(rows=>{
                                return(
                                    <tr>
                                        <th style={{paddingTop:"2px",paddingBottom:"2px"}} className='text-center'><strong>{rows.id_productoServicio}</strong></th>
                                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>{rows.tipo}</strong></th>
                                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>{rows.concepto}</strong></th>
                                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>{rows.precio}</strong></th>
                                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>{rows.consecutivo}</strong></th>
                                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>{rows.piezas}</strong></th>
                                        {/* <th><Button danger onClick = {e=>this.deletePiezas(rows.id_productoServicio)}> -- </Button></th> */}
                                    </tr>
                                    )
                        })}
                    </table>
                    </Card>
                </div>
            </center>
          }
          let data;
          let tabla;
          if(this.state.busqueda){
              const columns = ["Id_ProductoServicio", "Tipo", "Concepto", "Precio", "Consecutivo","Agregar"];
              data= this.state.tablaBusqueda.map((rows,i)=>{          
                let boton = <Button shape="circle" size="large" type="success" onClick={(e) => this.handleAddFields(rows)}><i class="fas fa-plus"></i>    </Button>
                return([rows.id_productoServicio,rows.tipo,rows.concepto,"$" + rows.precio,rows.consecutivo,boton])
              })
              let tituloCatalogo = <h6><strong>Catálogo de producto y servicio</strong></h6>
              tabla= 
              <center>
              <div style={{width:"85%",marginTop:"2%"}}>
                      <Card title = {tituloCatalogo}>       
                          <MUIDataTable  
                              data={data}
                              columns={columns} 
                              options={options3}                     
                          /> 
                             <Button style={{marginTop:"2%"}} type='danger' onClick = {e=>this.closeTablePolizas()}>Cerrar tabla</Button>&nbsp;&nbsp;&nbsp;&nbsp;{nextProcess2}
                          {tableSelect}
                      </Card>
              </div> 
              </center>     
            }
          if(this.state.tablaPolizas === true){
            const columnsTablaPolizas = ["Id_ProductoServicio", "Tipo", "Concepto", "Precio", "Consecutivo","Agregar"];
            dataTablaPolizas= this.state.polizasGenerales.map((rows,i)=>{          
              let boton = <Button shape="circle" size="large" type="success" onClick={(e) => this.handleAddFields(rows)}><i class="fas fa-plus"></i></Button>
              return([rows.id_productoServicio,rows.tipo,rows.concepto,"$" + rows.precio,rows.consecutivo,boton])
            })
            let tituloCatalogo = <h6><strong>Catálogo de producto y servicio</strong></h6>
            tablaPoliza= 
            <center>
            <div style={{width:"85%",marginTop:"2%"}}>
                    <Card title = {tituloCatalogo} extra = {<strong>{rs}&nbsp;&nbsp;&nbsp;{rfc}</strong>}>       
                        <MUIDataTable  
                            data={dataTablaPolizas}
                            columns={columnsTablaPolizas} 
                            options={options3} /> 
                        <Button style={{marginTop:"2%"}} type='danger' onClick = {e=>this.closeTablePolizas()}>Cerrar tabla</Button>&nbsp;&nbsp;&nbsp;&nbsp;{nextProcess2}
                        {tableSelect}
                    </Card>
            </div> 
            </center>     
          }
        let tableVerifyData;  
        let buttonActivarPoliza;
        let notaPolizaRegistrada;
        if(this.state.activarPoliza === true && this.state.arrayPoliza[0]){
            notaPolizaRegistrada = <Tag color="magenta">Nota la póliza de soporte ha sido registrada correctamente, si desea activarla puede hacerlo en este momento o de lo contrario puede consultarlo en el apartado Historial de pólizas de soporte.</Tag>
            buttonActivarPoliza = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.modalPoliza()}>¿Desea activar la póliza?</MDBBtn>
        }
        if(this.state.tableVerifyData === true)  {
            let inputNumber;
            let butonChangeCantidad;
            let butonCancelCantidad;
            let textoUnidades;
            let butonApplyChanges;
            let notaText;
            let textCantidadesModificadas
            if(this.state.changeCantidad === true){
                butonCancelCantidad = <Button type="dashed" danger  onClick={e=>{this.cancelarCambios()}}>Cancelar Cambios</Button>
            }
            let subtotal;
            subtotal = this.state.productsSelect.reduce((n,{PrecioTotal}) => n+PrecioTotal,0)
            let iva = subtotal*0.16
           
            tableVerifyData = 
            <center>
            <div style={{width:"95%",marginTop:"2%"}}>
            <Card type="inner" title={<div><h6><strong>Verificación de datos</strong></h6><strong>{rs}&nbsp;&nbsp;&nbsp;{rfc}</strong></div>} extra={<div><Button type="danger" onClick = {e=>this.returnVerifyData()}>Regresar</Button>&nbsp;&nbsp;&nbsp;&nbsp;{butonCancelCantidad}&nbsp;&nbsp;&nbsp;&nbsp;<Button type='primary' onClick={e=>this.nextProcess3()}>Siguiente</Button></div>}>
                <table class="table table-bordered table table-small table table-striped">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Concepto</th>
                        <th>Linea</th>
                        <th>Precio</th>
                        <th>Tipo</th>
                        <th>Licenciamiento</th>
                        <th>Unidades</th>
                        <th>{textoUnidades}</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                {this.state.productsSelect.map(rows=>{   
                    if(this.state.changeCantidad === false){
                        butonChangeCantidad = <Button type="warning" onClick={e=>{this.changeCantidad()}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg></Button>
                        textoUnidades = "Cambiar"
                    }else{
                    //     butonApplyChanges= <Button  shape="circle" size="large" type="primary" key={rows.id_productoServicio} //this id is coming from the mapped array "parks" state
                    //     disabled={this.state.disabled.indexOf(rows.id_productoServicio)!==-1} onClick={e=>{this.setState({
                    //         todos: [...this.state.todos, rows.concepto], 
                    //         disabled: [...this.state.disabled, rows.id_productoServicio],
                    //     })}
                    //     }><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    //     <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                    //   </svg></Button>
                        textCantidadesModificadas = "Cantidades modificadas el nuevo cálculo se reflejará en el siguiente proceso"

                        notaText = <Tag color="magenta">Nota: Debe aplicar los cambios para modificar las cantidades</Tag>
                        butonApplyChanges= <Button  shape="circle" size="large" type="primary" key={rows.id_productoServicio} //this id is coming from the mapped array "parks" state
                        disabled={this.state.disableInputNumber} onClick={e=>{this.appliChangesCantidad()}
                        }><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                      </svg></Button>
                        textoUnidades = "Aplicar"
                    }
                    inputNumber = <td><InputNumber disabled={this.state.disableInputNumber} key={rows.id_productoServicio}size="small" min={0} max={100000} defaultValue={this.state.valueInputNumber || rows.piezas} onChange={e=>this.onChangeInputNumber(e,rows.id_productoServicio,rows.precio)} /></td>
                    return(
                    <tr class="success">
                        <td>{rows.id_productoServicio}</td>
                        <td>{rows.concepto}</td>
                        <td>{rows.lineaProducto}</td>
                        <td>${rows.precio}</td>
                        <td>{rows.tipo}</td>
                        <td>{rows.tipoLicenciamiento}</td>
                        {inputNumber}
                        <td>{butonChangeCantidad}</td> 
                        <td>${rows.PrecioTotal}</td>
                    </tr>
                    )                     
                })}
                <tr>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"><strong>Subtotal</strong></td>
                    <td style={{padding:"2px"}} align="center">${subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <th style={{padding:"2px"}} align="center"><strong>IVA</strong></th>
                    <th style={{padding:"2px"}} align="center">${iva.toFixed(2)}</th>
                </tr>
                    <tr>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <td style={{padding:"2px"}} align="center"></td>
                    <th style={{padding:"2px"}} align="center"><strong>Total</strong></th>
                    <th style={{padding:"2px"}} align="center">${(subtotal + iva).toFixed(2)}</th>
                    </tr>
                </tbody>
                </table>
                <br/>
                {textCantidadesModificadas}
            </Card>
            Nota: Verifique correctamente los datos antes de avanzar ya que en el proceso de registro no podrá cancelar.
            </div>
            </center>
        }
        let buttonCancelventa;
        let buttonRegisterVenta;
        if(this.state.buttonCancelventa === true){
            buttonCancelventa = <Button type="danger" onClick={e=>this.retornar()}>Cancelar venta</Button>
            buttonRegisterVenta = <Button type="primary" onClick={e=>{this.RegisterPoliza()}}>Registrar</Button>
        }else{
            buttonCancelventa = <Button type="success" onClick={e=>window.location.reload()}>Finalizar</Button>
        }
        let finalTableProcess;
        if(this.state.finalTableProcess === true){
            let subtotal;
            subtotal = this.state.dataRegister.reduce((n,{PrecioTotal}) => n+PrecioTotal,0)
            let iva = subtotal*0.16
            finalTableProcess = 
            <center>
            <div style={{width:"95%",marginTop:"2%"}}>
            <Card type="inner" title={<div><h6><strong>Productos a registrar</strong></h6></div>}extra= {buttonCancelventa}>
                <table style={{width:"40%"}} className='table table-bordered table tale-small table table-striped'>
                    <tr>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Razón social</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>RFC</th>
                    </tr>
                    <tr>
                        <th  style={{paddingTop:"2px",paddingBottom:"2px"}}>{<strong>{rs}</strong>}</th>
                        <th  style={{paddingTop:"2px",paddingBottom:"2px"}}>{<strong>{rfc}</strong>}</th>
                    </tr>
                </table>
                <table class="table table-bordered table table-small table table-striped">
                    <thead>
                      <tr>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Id</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Concepto</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Linea</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Precio</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Tipo</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Licenciamiento</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Unidades</th>
                        <th style={{paddingTop:"2px",paddingBottom:"2px"}}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                {this.state.dataRegister.map(rows=>{   
                        return(
                            <tr class="success">
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.id_productoServicio}</td>
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.concepto}</td>
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.lineaProducto}</td>
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>${rows.precio}</td>
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.tipo}</td>
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.tipoLicenciamiento}</td>
                                <td style={{paddingTop:"2px",paddingBottom:"2px"}}>{rows.piezas}</td>
        
                                <td>${rows.PrecioTotal.toFixed(2)}</td>
                            </tr>
                            )      
                })}
                <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"><strong>Subtotal</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center">${subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <th style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"><strong>IVA</strong></th>
                    <th style={{paddingTop:"2px",paddingBottom:"2px"}} align="center">${iva.toFixed(2)}</th>
                </tr>
                    <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"></td>
                    <th style={{paddingTop:"2px",paddingBottom:"2px"}} align="center"><strong>Total</strong></th>
                    <th style={{paddingTop:"2px",paddingBottom:"2px"}} align="center">${(subtotal + iva).toFixed(2)}</th>
                    </tr>
                </tbody>
                </table>
                {buttonRegisterVenta} &nbsp;&nbsp;&nbsp; {buttonActivarPoliza}
            </Card>
            <center>{notaPolizaRegistrada}</center>
            </div>
            </center>
        }
        let modalActivar =  
        <Modal okText="Finalzar venta" cancelText="Cerrar" width={1300} title="Activar fecha de inicio de la póliza" visible={this.state.isModalVisible} onOk={e=>window.location.reload()} onCancel={e=>this.handleCancel()}>
            <table className='table table-bordered table table-small table table-striped'>
                <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Razón S</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>RFC</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Id</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Concepto</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Tipo</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Status</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha Inicial</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha Final</strong></td>
                </tr>
                {this.state.arrayPoliza.map(rows=>{
                    return(
                    <tr>
                    <td>{rs}</td>
                    <td>{rfc}</td>
                    <td>{rows[0].id_polizas}</td>
                    <td>{rows[0].concepto}</td>
                    <td>{rows[0].tipo}</td>
                    <td>Inactiva</td>
                    <td>{<DatePicker onChange={this.capturarFecha} defaultValue={moment(new Date(), dateFormatList[0])} format={dateFormatList} />}</td>
                    <td>{<DatePicker onChange={this.capturarFechaFinal} defaultValue={moment(new Date(), dateFormatList[0])} format={dateFormatList} />}</td>
                    <td><Button type='primary' onClick={e=>this.activarPoliza(rows[0].id_polizas)}>Activar poliza</Button></td>
                    </tr>  
                    )
                })}
            </table>
        </Modal>
        return ( 
            <div>
                {tablaPoliza}
                {cardSearch}
                {cardInicial}
                {cardDataCliente}
                {registrarContacto}
                {tabla}
                {tableVerifyData}
                {finalTableProcess}
                {modalActivar}
                {buttonCerrarCliente}
                {signupCliente}
                
            </div>
         );
    }
}
 
export default RegisterPolizas;