import React, { Component } from 'react';
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import {Card} from 'antd'
import MUIDataTable from "mui-datatables";
import {Button,Modal} from 'antd'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';


class RegistroInicioSesion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataInicioSesion:[],
            dataGeneralInicioSesion:[],
            detallesInicioSesion:[],
            detallesCliente:[],
            isModalVisible:false,
            clientesAcceso:[],
            tablePrincipal:true,
            tablaClientesSinAcceso:false
        }
    }

    componentWillMount(){
        let fk_empresa =  localStorage.getItem("fk_empresa")
        let resultados =  []   
        let resultado = []
        let clientes = []
        var foundMonth = 0;
        const month = ["01","02","03","04","05","06","07","08","09","10","11","12"];

        const d = new Date();
        let name = month[d.getMonth()];
        let mesActual = name.toString()
        console.log("mesActual",name)

        axios({
            url:API,
            method:'post',
            data:{
              query:`
                query{   
                  getTablaClientesAlfa(data:"${[fk_empresa]}"){
                    id_cliente
                    rfc
                    razonSocial
                    tamanoEmpresa
                    giroEmpresarial
                    correo
                    telefono
                    paginaWeb
                    domicilioFiscal
                    acceso
                    fk_contactoAcceso
                    } 
                }
                `  }           
             })
           .then(datos => { 
            let dataClientes = datos.data.data.getTablaClientesAlfa
            dataClientes.map(rows=>{
              axios({
                url:API,
                method:'post',
                data:{
                  query:`
                    query{   
                      getTableInicioSesion(data:"${[rows.id_cliente]}"){
                        id_transacciones
                        id_cliente
                        rfc
                        fecha
                        hora
                        nombreRepresantante
                        apellidosRepresantante
                        rfc
                        razonSocial
                        } 
                    }
                    `  }           
                 })
               .then(datos => { 
                let length = []
                let arr = datos.data.data.getTableInicioSesion

                resultados.push(arr)

                function getUniqueListBy(arr, key) {
                  return [...new Map(arr.map(item => [item[key], item])).values()]
                 }
               const arr1 = getUniqueListBy(arr, 'id_cliente')
               resultado.push(arr1)
               var arrayClientes =  resultado.filter(e => e.length);
                const arrayTransacciones = resultados.filter(element => {
                    if (Object.keys(element).length !== 0) {
                    return true;
                    }
                    return false;
                });

                this.setState({dataInicioSesion:arrayTransacciones})

                let accesosMes = [];
                arrayTransacciones.map(rows=>{
                    let accesosEnElMes = rows.filter(function(param){
                        return param.fecha.substring(0,2) === mesActual
                    })
                    accesosMes.push({"accesosMes":accesosEnElMes.length})
                    length.push({"accesos":rows.length})
                })
                arrayClientes.map(rows =>{
                    clientes.push(rows[0])
                })
                const arr2 = getUniqueListBy(clientes, 'id_cliente')
                let arr3 = arr2.map((item, i) => Object.assign({}, item, length[i]));
                let arr4 = arr3.map((item, i) => Object.assign({}, item, accesosMes[i]));

                let arrayOrdenado = arr4.sort(function(a, b) {
                    return parseFloat(b.accesos) - parseFloat(a.accesos);
                });
                this.setState({dataGeneralInicioSesion:arrayOrdenado})
                let id_clientes = [];

                arr2.map(params=>{
                  id_clientes.push({"clientesAcceso":params.id_cliente})
                })

                let clientesTotales = dataClientes
                for( var i=clientesTotales.length - 1; i>=0; i--){
                  for( var j=0; j<id_clientes.length; j++){
                      if(clientesTotales[i] && (clientesTotales[i].id_cliente === id_clientes[j].clientesAcceso)){
                        clientesTotales.splice(i, 1);
                      }
                  }
              }
              this.setState({clientesAcceso:clientesTotales})
              }).catch(err=>{
                console.log("err",err.response)
                console.log("err",err)
      
              })
             })
            })
    }
    detallesInicioSesion(param){
      let arrayFiltros = [];
      let data = this.state.dataInicioSesion
      data.map(rows=>{
      let filtros =  rows.filter(function(hero){
        return hero.id_cliente === param
      })
      arrayFiltros.push(filtros)
      })
      
      const arrayTransacciones = arrayFiltros.filter(element => {
        if (Object.keys(element).length !== 0) {
        return true;
        }
        return false;
    });
      this.setState({detallesCliente:arrayTransacciones})
      this.setState({isModalVisible:true})
    }
    showModal = () => {
      this.setState({isModalVisible:true})
    };  
    handleCancel = () => {
      this.setState({isModalVisible:false})
    };
    
    clientesSinAcceso(){
      this.setState({tablaClientesSinAcceso:true})
      this.setState({tablePrincipal:false})
    }
    cerrarTablaSinAcceso(){
      this.setState({tablaClientesSinAcceso:false})
      this.setState({tablePrincipal:true})
    }
    render() { 
        const options={ 
            print:false,
            download:false,
            filterType:"drowpdawn",
            responsive: "stacked",
            elevation:0,
            selectableRows:"none",
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          }
        };
        let dataGeneral =  this.state.dataGeneralInicioSesion
        
        const columnsInicioSesion = ["Id","RFC","Razón Social","Accesos en el mes","Accesos totales","Detalles"];
        let dataInicioSesion = dataGeneral.map(rows=>{
            let boton = <Button type='primary' onClick={e=>this.detallesInicioSesion(rows.id_cliente)}>Ver</Button>
            let accesos = <strong><font color = "blue">{rows.accesos + " accesos"}</font></strong>
            let accesosMes = <strong><font color = "green">{rows.accesosMes + " accesos"}</font></strong>

             return([rows.id_cliente,rows.rfc,rows.razonSocial,accesosMes,accesos,boton])
        })
        let titulo =  <h6><strong><font color="#1B4F72">Detalles del acceso al sistema de clientes</font></strong></h6>
        let modal;
        if(this.state.detallesCliente[0]){
           modal = <Modal okText="Aceptar" cancelText="Cerrar" width ={1000} title={<h6><strong>Detalles de los movimientos en el acceso al sistema de clientes</strong></h6>} visible={this.state.isModalVisible} onOk={e=>this.handleCancel()} onCancel={e=>this.handleCancel()}>
          <MDBTable striped bordered small>
           <MDBTableHead color="primary-color" textWhite>
             <tr>
               <th>Id</th>
               <th>Razón Social</th>
               <th>Id de transación</th>
               <th>Fecha</th>
               <th>Hora</th>
             </tr>
           </MDBTableHead>
           <MDBTableBody>
            {this.state.detallesCliente[0].map(rows=>{
              return(
                <tr>
                  <th>{rows.id_cliente}</th>
                  <th>{rows.razonSocial}</th>
                  <th>{rows.id_transacciones}</th>
                  <th>{rows.fecha}</th>
                  <th>{rows.hora}</th>
                </tr>
              )
            })}
           </MDBTableBody>
         </MDBTable>
         </Modal>
        }
       let tablePrincipal;
        if(this.state.tablePrincipal=== true){
          tablePrincipal = <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >
            <Card title = {titulo} extra = {<Button type="danger" onClick={e=> this.clientesSinAcceso()}>Clientes sin accesar</Button>}>               
            <MUIDataTable  
                data={dataInicioSesion} 
                columns={columnsInicioSesion} 
                options={options} 
            />  
          </Card>
          </div>
        }
        let tablaClientesSinAcceso;
        let tituloSinAcceso = <h6><strong><font color="#632440">Clientes sin accesar al sistema</font></strong></h6>;

        if(this.state.tablaClientesSinAcceso === true){
          let sinAcceso = this.state.clientesAcceso
          const columnsSinAcceso = ["Id","RFC","Razón Social","Giro Empresarial","Domicilio fiscal","paginaWeb"];
          let dataSinAccesos = sinAcceso.map(rows=>{
            let id_cliente = <font color="black">{rows.id_cliente}</font>
            let rfc = <font color="red">{rows.rfc}</font>
            let razonSocial = <font color="red">{rows.razonSocial}</font>
            let giroEmpresarial = <font color="black">{rows.giroEmpresarial}</font>
            let domicilioFiscal = <font color="black">{rows.domicilioFiscal}</font>
            let paginaWeb = <font color="black">{rows.paginaWeb}</font>

             return([id_cliente,rfc,razonSocial,giroEmpresarial,domicilioFiscal,paginaWeb])
          })
            tablaClientesSinAcceso = <div  style={{width:"95%",marginLeft:"3%",marginTop:"1%",marginBottom:"2%"}} >
            <Card title = {tituloSinAcceso} extra = {<Button danger onClick={e=> this.cerrarTablaSinAcceso()}>Cerrar tabla</Button>}>               
              <MUIDataTable  
                  data={dataSinAccesos} 
                  columns={columnsSinAcceso} 
                  options={options} 
              />  
          </Card>
          </div>
        }
        return ( 
            <div>
              {tablePrincipal}
              {modal} 
              {tablaClientesSinAcceso}
            </div>
         );
    }
}
 
export default RegistroInicioSesion;