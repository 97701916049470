import React,{Component} from "react";
import "./index.css";
import { DataProd, DataCom, NomProd} from "./items";
import { Card, Button,Tag,Modal,Rate, Badge,notification, DatePicker   } from "antd";
import svg from '../imagen/details.svg'
import swal from "sweetalert";
import actionsAPI from "./actionsAPI";
import { FacebookEmbed, XEmbed, YouTubeEmbed, LinkedInEmbed } from 'react-social-media-embed';
import Rating from './app_nivo'
import '../../components/administradorAlfa/index.css'
import ads from '../imagen/logo_color_azul.png'
import Content from "./home_courses";
import MUIDataTable from "mui-datatables";
import {CaretRightFilled,FormOutlined,CalendarOutlined,UserOutlined,DesktopOutlined,StarOutlined,FieldTimeOutlined,HomeOutlined,QuestionOutlined} from '@ant-design/icons';
import {CloudOutlined } from "@material-ui/icons";
import CotizacionesCourses from "./cotizaciones_courses";
import Requisitos from '../demo/complementoCursos/index'
import Semblanza from '../demo/complementoCursos/Semblanza'

class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            card:true,
            form:false,
            array:[],
            nombre:'',
            apellidos:'',
            telefono:'',
            correo:'',
            cp:'',
            state:[],
            fb:false,
            modal_open:false,
            link_fb:'',
            tw:false,
            modal_open_tw:false,
            link_tw:'',
            yt:false,
            modal_open_yt:false,
            link_yt:'',
            li:false,
            modal_open_li:false,
            link_li:'',
            rating:false,
            args:true,
            exclusivos:false,
            anteriores:false,
            profile:false,
            nombre_:'',
            apellidos_:'',
            telefono_:'',
            correo_:'',
            fecha_nacimiento:'',
            cp_:'',
            telefon_empresa:'',
            profesion:'',
            genero:'',
            coursos_anteriores1:[],
            allItems:[],
            cotizaciones:false,
            allItems_data:[],
            solicitud:true,
            requisitos:false,
            modal_requisitos2:false,
            semblanza:[],
            cotizaciones_totales:[],
            cotizacion_seleccionada:[],
            acceso_card_curso:false
        }
    }

    async componentWillMount(){
        await this.get();
        this.getSemblanza();
        this.get_cotizaciones();
        this.getMyCourses();
       

    }
    componentDidMount(){
      let tokenUsers = localStorage.getItem("token_user_plataform")
      if(!tokenUsers){
        localStorage.clear()
      }
    }
 

    
    open_modal_semblanza(data){
      let filter = this.state.semblanza.filter(function(hero){
        return hero.fk_courses === data
      })
    
      if(filter[0]){
        this.setState({filterSemblanza : filter });
        this.setState({requisitos:true})
      }else{
        swal({
          title:"Aviso",
          text:`Aún no hay una semblanza registrada`,               
          icon:"info",
      });
      }
      
    }
    close_modal_requisitos(){
      this.setState({ requisitos: false });
    }

    get_cotizaciones(){
      let id_users_plataform = localStorage.getItem("id_users_plataform");
      actionsAPI.get_cotizaciones(id_users_plataform).then(response=>{
        this.setState({ cotizaciones_totales :  response.data.data.get_cotizaciones });
      }).then(response=>{
      }).catch(err=>{
        console.log("err",err.response)
        console.log(err)
      })
    }
  
    close_modal_requisitos2(){
      this.setState({ modal_requisitos2: false });
    }
    open_modal_requisitos(){
      this.setState({ modal_requisitos2: true });
    }
   

    getSemblanza(){
      actionsAPI.getSemblanza().then(response=>{
        this.setState({semblanza:response.data.data.getSemblanza})
      }).catch(err=>{
        console.log(err.response)
      })
    }

    get(){
      let allItems = [];
        actionsAPI.getCourses().then((response)=>{
        if(response.data.data.getCourses)
            allItems.push(response.data.data.getCourses);
            this.setState({allItems_data:allItems})
            allItems[0].map(rows=>{
                let indice = parseInt(rows.indice)
                let user_min = parseInt(rows.user_min)
                if(rows.habilitar === "automatico"){
                if(indice >= user_min){
                    actionsAPI.activar_curso(rows.id_courses).then(response=>{
                    })
                }
                }
            })
            this.setState({allItems:allItems})
        }).catch(err=>{
        console.log(err)
        console.log("err",err.response)
        })
    }
    ShowItems = (arg = "All") => {
      let token = localStorage.getItem("token");
      
      if(this.state.acceso_card_curso === false){
        let messenger = "All";

        messenger = arg;
        console.log(`messenger is ${arg}`);
        if (arg === "All") {

          this.setState({args:true}) 
          this.setState({rating:false}) 
          this.setState({state:[]}) 
          this.setState({exclusivos:false}) 
          this.setState({anteriores:false}) 
          this.setState({profile:false}) 
          this.setState({form:false}) 
          this.setState({cotizaciones:false})
        };
    
        if (arg === "Exclusivos") {
            this.setState({args:false}) 
            this.setState({rating:false}) 
            this.setState({state:[]}) 
            this.setState({exclusivos:true}) 
            this.setState({anteriores:false}) 
            this.setState({profile:false}) 
            this.setState({form:false}) 
            this.setState({cotizaciones:false})
        };
        if (arg === "Anteriores") {
            this.setState({form:false}) 
            this.setState({args:false}) 
            this.setState({rating:false}) 
            this.setState({state:[]}) 
            this.setState({exclusivos:false}) 
            this.setState({anteriores:true}) 
            this.setState({profile:false}) 
            this.setState({cotizaciones:false})
        };
        if (arg === "Profile") {
            this.setState({form:false}) 
            this.setState({args:false}) 
            this.setState({rating:false}) 
            this.setState({state:[]}) 
            this.setState({exclusivos:false}) 
            this.setState({anteriores:false}) 
            this.setState({profile:true}) 
            this.setState({cotizaciones:false})
        };
        if (arg === "Productos") {
            this.setState({form:false}) 
            this.setState({args:false}) 
            this.setState({rating:false}) 
            this.setState({state: DataProd}) 
            this.setState({exclusivos:false}) 
            this.setState({anteriores:false}) 
            this.setState({profile:false}) 
            this.setState({cotizaciones:false})
        };
    
        if (arg === "Nom 035") {
            this.setState({form:false}) 
            this.setState({args:false}) 
            this.setState({rating:false}) 
            this.setState({state: NomProd}) 
            this.setState({exclusivos:false}) 
            this.setState({anteriores:false}) 
            this.setState({profile:false}) 
            this.setState({cotizaciones:false})
        };
    
        if (arg === "Comerciales") {
            this.setState({form:false}) 
            this.setState({args:false}) 
            this.setState({rating:false}) 
            this.setState({state: DataCom}) 
            this.setState({exclusivos:false}) 
            this.setState({anteriores:false}) 
            this.setState({profile:false})
            this.setState({cotizaciones:false})
        };
    
        if (arg === "Rating") {
            this.setState({form:false}) 
            this.setState({args:false}) 
            this.setState({rating:true}) 
            this.setState({state: []}) 
            this.setState({exclusivos:false}) 
            this.setState({anteriores:false}) 
            this.setState({profile:false})
            this.setState({cotizaciones:false})

        };
        if (arg === "Cotizaciones") {
          this.setState({form:false}) 
          this.setState({args:false}) 
          this.setState({rating:false}) 
          this.setState({state: []}) 
          this.setState({exclusivos:false}) 
          this.setState({anteriores:false}) 
          this.setState({profile:false})
          this.setState({cotizaciones:true})
      };
      }else{
        swal({
          title:"Aviso",
          text:`Por favor cancele el registro oprimiendo el boton cancelar para poder salir`,               
          icon:"info",
      });
      }
        
      };
    
    logonForm = (id) =>{
        console.log("entro")
        this.setState({acceso_card_curso:true})
        let cot  =  this.state.cotizaciones_totales.filter(function(hero){
          return hero.fk_curso === id
        })

        this.setState({cotizacion_seleccionada:cot})

        let array = [];
        this.state.allItems[0].forEach(function(hero){
          if(hero.id_courses === id){
            array.push(hero)
          }
        })
        this.setState({card:false})
        this.setState({form:true})
        this.setState({array:array[0]})

    }
    cancelar = () => {
        this.setState({acceso_card_curso : false });
        this.setState({card:true})
        this.setState({form:false})
        this.setState({array:[]})
      }
    
    solicitar_cotizacion = () => {
        this.setState({solicitud:false})
        let array = this.state.array;
        let fecha_emision = array.fecha_curso;
        let fecha_Expiracion  = array.fecha_curso;
        let año = new Date().getFullYear();
        function generateUUID() {
          var d = new Date().getTime();
          var uuid = "xxyxAxBxByDxxxyy".replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          });
          return uuid;
        }
        let folio = (año + generateUUID()).toUpperCase();
        let piezas = "1";
        let precio_unitario  = parseFloat(array.precio);
        let iva = parseFloat(array.precio) * 1.16;
        let total_global = precio_unitario + iva;
        let tipo_cotizacion = "Solicitada";
        let status = "Pendiente";
        let fk_usuario_plataforma = localStorage.getItem("id_users_plataform");
        let fk_curso = array.id_courses;

        actionsAPI.solicitar_cotizacion(fecha_emision,fecha_Expiracion,folio,piezas,precio_unitario,iva.toFixed(2),total_global,tipo_cotizacion,status,fk_usuario_plataforma,fk_curso,array.concepto).then(response=>{
          if(response.data.data.solicitar_cotizacion.message === "cotizacion encontrada"){
            swal({
                title:"Aviso",
                text:`Ya solicitaste la cotización a este curso`,               
                icon:"info",
            });
        }if(response.data.data.solicitar_cotizacion.message === "registro exitoso"){
          swal({
            title:"Cotización solicitada",
            text:`Su cotización fue solicitada, en breve podrá visualizar su documento en el apartado cotizaciones`,               
            icon:"success",
          });  
        }
          
        }).catch(err=>{
          console.log("err",err)
          console.log("err",err.response)
        })
       
    }
    
    
    onSubmitBtn = (e)=>{
        let {nombre,apellidos,telefono,correo,cp} = this.state;
        let nombre_plataform = localStorage.getItem("nombre_plataform");
        let apellidos_plataform = localStorage.getItem("apellidos_plataform");
        let correo_plataform = localStorage.getItem("correo_plataform");
        let telefono_plataform = localStorage.getItem("telefono_plataform");
        let telefono_empresa_plataform = localStorage.getItem("telefono_empresa_plataform");
        let cp_plataform = localStorage.getItem("cp_plataform");
        let profesion_plataform = localStorage.getItem("profesion_plataform");
        let rangoEdad_plataform = localStorage.getItem("rangoEdad_plataform");

        let array = this.state.array;
        let fecha = new Date(); 
        let id_curse = array.id_courses;
        let index = parseInt(array.indice)
        let new_index = index + 1;
    
        if(nombre_plataform){
          actionsAPI.sendSubmit(array.id_courses,nombre_plataform,apellidos_plataform,telefono_plataform,correo_plataform,cp_plataform,fecha,new_index,id_curse,array.concepto,array.descripcion).then(response=>{
            if(response.data.data.inscriptionCourse.message === "curso ya inscrito"){
              swal({
                text:`Ya estas inscrito a este curso`,               
                icon:"info",
              });
            }else{
              swal({
                text:`Te inscribiste al curso ${array.concepto}, ${array.descripcion}, No olvides presentarte con anticipación al mismo`,               
                icon:"success",
              });
              setTimeout(()=>{
                window.location.reload();
              }, 4000) 
            }
            
          }).catch(err=>{
            console.log(err)
          })
        }else{
          if(nombre && apellidos && telefono && correo && cp){
            actionsAPI.sendSubmit(array.id_courses,nombre,apellidos,telefono,correo,cp,fecha,new_index,id_curse,array.concepto,array.descripcion).then(response=>{
              swal({
                text:`Te inscribiste al curso ${array.concepto}, ${array.descripcion}, No olvides presentarte con anticipación al mismo`,               
                icon:"success",
              });
              setTimeout(()=>{
                  window.location.reload();
              }, 6000) 
            }).catch(err=>{
              console.log(err)
            })
          }else{
            swal({
              text:"No deje espacios vacios",               
              icon:"error",
              }); 
          }
        }
        
      
      }
    
    getMyCourses = async () =>{
        let array = [];
        let respon;
        await actionsAPI.getRegisterCourses(localStorage.getItem("correo_plataform")).then(response =>{
           respon = response.data.data.getRegisterCourses;
        }).catch(err=>{
          console.log("error",err.response)
        })
        if(respon){
          for(let i = 0; i<= respon.length; i++){
            if(respon[i]){
              actionsAPI.getRegisterCoursesById(respon[i].fk_courses).then(data=>{
                let cursos = data.data.data.getRegisterCoursesById
                array.push(cursos[0])
                setTimeout(()=>{
                    this.setState({coursos_anteriores1:array})
                },1000)
              }).catch(err=>{
                console.log(err.response)
              })
            }
          }
        }
      }
    
    onChangeNombre = e => {
        this.setState({nombre:e.target.value})
    };
    onChangeApellidos = e => {
        this.setState({apellidos:e.target.value})
    };
      
    onChangeTelefono = e => {
        this.setState({telefono:e.target.value})
    };
    onChangeCorreo = e => {
        this.setState({correo:e.target.value})
    };
    onChangeCp = e => {
        this.setState({cp:e.target.value})
    };
    sharefb = (link) => {
        this.setState({link_fb:link})
        this.setState({fb:true})
        this.setState({modal_open:true})
    }
    onOk = () => {
        this.setState({modal_open:false})
        this.setState({fb:false})
    }
    
    sharetw = (link) => {
        this.setState({link_tw:link})
        this.setState({tw:true})
        this.setState({modal_open_tw:true})
    }
    onOktw = () => {
        this.setState({modal_open_tw:false})
        this.setState({tw:false})
    }
      
    shareyt = (link) => {
        this.setState({link_yt:link})
        this.setState({yt:true})
        this.setState({modal_open_yt:false})
    }
    onOkyt = () => {
        this.setState({modal_open_yt:false})
        this.setState({yt:false})
    }

    
    shareli = (link) => {
        this.setState({link_li:link})
        this.setState({li:true})
        this.setState({modal_open_li:true})
    }
    onOkli = () => {
        this.setState({modal_open_li:false})
        this.setState({li:false})
    }
    cerrar_sesion = () =>{
        localStorage.removeItem("id_users_plataform")
        localStorage.removeItem("nombre_plataform")
        localStorage.removeItem("apellidos_plataform")
        localStorage.removeItem("telefono_plataform")
        localStorage.removeItem("correo_plataform")
        localStorage.removeItem("perfil_completado")
        localStorage.removeItem("rfc")
        localStorage.removeItem("rs")
        localStorage.removeItem("telefono_empresa_plataform")
        localStorage.removeItem("cp_plataform")
        localStorage.removeItem("profesion_plataform")
        localStorage.removeItem("rangoEdad_plataform")
        localStorage.removeItem(
            "token_user_plataform"
            
        );
        this.props.history.push("/newCourses")
        swal({
          text:"Sesión finalizada",               
          icon:"success",
        });
    }
    
    onSubmitBtnProfile = () =>{
        let {telefon_empresa,telefono_,cp_,fecha_nacimiento,genero,profesion} = this.state;
        let nombre_plataform = localStorage.getItem("nombre_plataform");
        let apellidos_plataform = localStorage.getItem("apellidos_plataform");
        let correo_plataform = localStorage.getItem("correo_plataform");
        let telefono_plataform = localStorage.getItem("telefono_plataform");
        let telefono_empresa_plataform = localStorage.getItem("telefono_empresa_plataform");
        let cp_plataform = localStorage.getItem("cp_plataform");
        let profesion_plataform = localStorage.getItem("profesion_plataform");
        let rangoEdad_plataform = localStorage.getItem("rangoEdad_plataform");


        if(telefon_empresa && telefono_&& cp_ && fecha_nacimiento && genero && profesion){
          actionsAPI.update_profile(nombre_plataform, apellidos_plataform, telefon_empresa, telefono_plataform,correo_plataform, cp_, fecha_nacimiento, genero, profesion).then(response=>{
            if(response.data.data.update_profile.message === "perfil completado"){
              swal({
                text:"Perfil completado",               
                icon:"success",
              });
              setTimeout(()=>{
                window.location.reload()
              },3000)
            }if(response.data.data.update_profile.message === "correo no encotrado"){
              swal({
                text:"El correo proporcionado no es válido",               
                icon:"success",
              });
            }
          }).catch(err=>{
            console.log(err.response)
          })
        }else{
          swal({
            text:"No deje espacios vacios",               
            icon:"error",
          });
        }
    
    }
    onChangeInputForm_nombre = (e) => {
        this.setState({nombre_:e.target.value})
    };
    onChangeInputForm_apellidos = (e) => {
        this.setState({apellidos_:e.target.value})
    };
    onChangeInputForm_telefono = (e) => {
        this.setState({telefono_:e.target.value})
    };
    onChangeInputForm_correo = (e) => {
        this.setState({correo_:e.target.value})
    };
    onChangeInputForm_cp = (e) => {
        this.setState({cp_:e.target.value})
    };
    onChangeInputForm_telefono_empresa= (e) => {
        this.setState({telefon_empresa:e.target.value})
    };
    onChangeInputForm_profesion = (e) => {
        this.setState({profesion:e.target.value})
    };
    onChangeInputForm_genero = (e) => {
        this.setState({genero:e.target.value})
    };
    dateOnChange = (date, dateString) => {
        this.setState({fecha_nacimiento:dateString})
    };

    
       render() { 
        let nombre_user = localStorage.getItem("nombre_plataform") || "";
        let apellidos_user = localStorage.getItem("apellidos_plataform") || "";
        let telefono_user = localStorage.getItem("telefono_plataform") || "";
        let correo_user = localStorage.getItem("correo_plataform") || "";
      
        let nombre_plataform = localStorage.getItem("nombre_plataform");
        let apellidos_plataform = localStorage.getItem("apellidos_plataform");
        let correo_plataform = localStorage.getItem("correo_plataform");
        let telefono_plataform = localStorage.getItem("telefono_plataform");
        let telefono_empresa_plataform = localStorage.getItem("telefono_empresa_plataform");
        let cp_plataform = localStorage.getItem("cp_plataform");
        let profesion_plataform = localStorage.getItem("profesion_plataform");
        let rangoEdad_plataform = localStorage.getItem("rangoEdad_plataform");
        let token = localStorage.getItem("token_user_plataform");

        const columns = [
            {
             name: "curso",
             label: "Curso",
             options: {
              filter: true,
              sort: true,
             }
            },{
              name: "Descripcion",
              label: "Descripción",
              options: {
               filter: true,
               sort: false,
              }
             },
            {
             name: "fecha",
             label: "Fecha del curso",
             options: {
              filter: true,
              sort: false,
             }
            },
            {
             name: "imagen",
             label: "Imagen",
             options: {
              filter: true,
              sort: false,
             }
            },
            {
              name: "instructor",
              label: "Expositor",
              options: {
               filter: true,
               sort: false,
              }
             },
            {
             name: "ver",
             label: "Volver a ver",
             options: {
              filter: true,
              sort: false,
             }
            },
           ];            
           let rfc1 = localStorage.getItem("rfc");
           let rs1 = localStorage.getItem("rs");
           let rfc;
           let rs;
           if(rfc1){
             rfc = <font className="strong text-white">{rfc1}</font>
              notification.open({ 
                message: 'Beneficios encontrados', 
                description: 
                `Por ser cliente de Tecno 360 Consultores tendrás un descuento especial en todos los cursos con costo incluidos en tu cotización`, 
                
              })
              setTimeout(()=>{
                notification.destroy()
          
              },2000)
              }
           if(rs1){
             rs = <font className="strong text-white">{rs1}</font>
           }   
           const data = this.state.coursos_anteriores1.map(rows=>{
            if(rows){
              return([rows.concepto,rows.descripcion,rows.fecha_curso,<img src={rows.imagen} width={110}></img>,rows.instructor,<Button style={{backgroundColor:"rgb(40,95,148)"}}  className="text-white strong">Ver</Button>])
            }
           })
           const optionsa = {
            print:false,
            sort:false,
            viewColumns:false,
            download:false,
             filterType: 'checkbox',
             filter:false,
             select:false,
          
             selectableRowsHeader:false
           };

           let modalrequisitos;
           if(this.state.requisitos === true){
             modalrequisitos = <Modal open={this.state.requisitos} footer={null} onCancel={e=>this.close_modal_requisitos()}>
              <Semblanza props = {this.state.filterSemblanza} array = {this.state.array}/>
            </Modal>
           }
            
           let modalrequisitos2;
           if(this.state.modal_requisitos2 === true){
             modalrequisitos2 = <Modal open={this.state.modal_requisitos2} footer={null} onCancel={e=>this.close_modal_requisitos2()}>
              <Requisitos/>
            </Modal>
           }
            let validation1;
            let validation2;
            if(this.state.exclusivos === true){
              validation1 = 
              <div className="grid" style={{marginLeft:"26em"}}>
              <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="https://pcpartners.com.mx/wp-content/uploads/2023/12/comercial-1.jpg" class="img-fluid rounded-start" alt="image"/>
                    <center> <span className="strong">CONTPAQi contabilidad </span></center>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Temario</h5>
                      <p class="card-text">1.1 – Consideraciones para crear una empresa.<br/>
                      1.2 – Creación de empresas.<br/>
                      1.3 – Configuración inicial de catálogo de cuentas.<br/>
                      1.4 – Paseo por el sistema.<br/>
                      1.5 – Catálogo de cuentas.<br/>
                      1.5.1 – Cargado de catálogo desde Excel.<br/>
                      1.5.2 – Alta de cientos de cuentas en minutos desde Excel.<br/>
                      1.6 – Registro de cuentas contables.<br/>
                      1.7 – Cuentas y estructura (Redefinición de la empresa)</p>
                      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3" style={{maxWidth:"540px"}}>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="https://idnube.com/wp-content/uploads/2020/06/respcuad.png" class="img-fluid rounded-start" alt="..."/>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">CONTPAQi Respaldos</h5>
                      <p class="card-text">7.1 – ¿Qué es DIOT?<br/>
                        7.2 – ¿Qué es DPIVA?<br/>
                        7.3 – Configurar el control de IVA. (Para generar la DIOT/DPIVA)<br/>
                        7.4 – Registrar operaciones para la DIOT/DPIVA.<br/>
                        7.5 – Padrón de proveedores.<br/>
                        7.6 – Crear proveedores a partir de un rango de cuentas.<br/>
                        7.7 – Registro de Control de IVA a partir de los CFDI.<br/>
                        7.12 – Generar el DPIVA.</p>
                      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="https://www.integraconsorcio.com.mx/images/blog/single/614f74a5a6d01.jpg" class="img-fluid rounded-start" alt="image"/>
                    <center> <span className="strong">Visor de documentos</span></center>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Temario</h5>
                      <p class="card-text">2.1 – Nuevo visor de documentos digitales.<br/>
                      2.2 – Configuración y cargado manual de XML.<br/>
                      2.3 – Cargar documentos al ADD.</p>ß
                      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="https://www.contpaqi.com/inicio/Banner_SUMA_2023.png" class="img-fluid rounded-start" alt="image"/>
                    <center> <span className="strong">CONTPAQi Suma</span></center>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Temario</h5>
                      <p class="card-text">3.3 – Conciliación de REP.<br/>
                        3.1- Captura de pólizas de Diario.<br/>
                        3.2 – Captura de pólizas de Ingreso y Egreso.<br/>
                        3.2.1 – Pago de CFDI.3.2.2 – Documento bancario de la póliza.<br/>
                        3.2.3 – Integrar REP a la póliza de pago de manera automática..</p><br/>
                      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="https://cpsmexico.com/wp-content/uploads/2020/04/Logo-CONTPAQi-Escritorio_Virtual_Blanco.jpg" class="img-fluid rounded-start" alt="image"/>
                    <center> <span className="strong">CONTPAQi Escritorio virtual</span></center>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Temario</h5>
                      <p class="card-text">4.1 – ¿Qué es el traspaso de auxiliares entre cuentas?<br/>
                        4.2 – Beneficios y usos.<br/>
                        4.3 – Consideraciones.</p><br/>
                      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3" style={{maxWidth:"540px",marginTop:"2em"}}>
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="https://www.contpaqi.com/Colabora/Logo_Colabora.png" class="img-fluid rounded-start" alt="..."/>
                    <center> <span className="strong">CONTPAQi Colabora</span></center>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Temario</h5>
                      <p class="card-text">5.1 – Provisión de Venta.<br/>
                        5.2 – Cobro a Cliente.<br/>
                        5.3 – Factura a Contado.5.4 – Provisión de Gastos.<br/>
                        5.5 – Provisión de Compra.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          
            if(this.state.anteriores === true){
              validation2 = <div style={{width:"80%",marginLeft:"23em",marginTop:"5em"}}>
                <MUIDataTable
                  title={"Mis cursos"}
                  data={data}
                  columns={columns}
                  options={optionsa}
                />
              </div>
            }
          
            let ratingCard;
            if(this.state.rating === true){
              ratingCard = <div style={{height:"100%"}} className="margen"><Rating/></div>
            }
          
            let modal;
            let title_modal =<center><h6>Compartir publicación</h6></center> 
            if(this.state.fb === true){
              modal = <Modal footer={null} open={this.state.modal_open} onOk = {e=>this.onOk()} onCancel ={e=>this.onOk() }>
                {title_modal}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FacebookEmbed  url={this.state.link_fb} width={550} />
                </div>
              </Modal>
            }


          
            let modaltw;
            if(this.state.tw === true){
              modaltw = <Modal footer={null} open={this.state.modal_open_tw} onOk = {e=>this.onOktw()} onCancel ={e=>this.onOktw() }>
                {title_modal}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <XEmbed url={this.state.link_tw} width={325} />
                </div>
              </Modal>
            }
          
            let titleData ;
          
            let valoracion;
            let registrate;
            let profile_user;
          
            if(this.state.rating === true){
              valoracion =
              <div>
              <h6 className="strong">Valoración del mes 4.9 &nbsp; <i class="fa fa-star"></i> &nbsp; <Rate allowHalf defaultValue={4.9} /></h6>
              </div>
            }
          
          
            if(this.state.profile === true){
              let rs2 = localStorage.getItem("rs");
              let rfc2 = localStorage.getItem("rfc");
              let age;
              if(rangoEdad_plataform){
                const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
                age = <Tag color="green"><font color="blue" className="strong">{"Edad  " + getAge(rangoEdad_plataform) + "  Años"}</font></Tag> 
              }
          
              profile_user =
                 <div className='container99 ' style={{marginTop:"2em",marginLeft:"48em"}}>
                      <div className="rows">
                        <h6 className='h6'>Completar perfil</h6><br/>
                        <div>
                        <font className="strong text-white">{rfc2}</font> &nbsp;&nbsp;&nbsp;
                        <font className="strong text-white">{rs2}</font>
                        </div>
                        </div>
          
                      <center>
                        </center>
                      <div style={{marginTop:"2%",width:"50%"}}>
                      <form>
                          <div className=''>
                          <div className='rows'> 
                          </div> 
                          <div style={{marginTop:"2em"}} class="input-group input-group-icon">
                            <span className='strong'>Nombre (s) &nbsp;&nbsp;<font color="red">Campo fijo</font></span>
                            <input disabled placeholder={nombre_plataform} onChange={this.onChangeInputForm_nombre} id="nombre" type="text"required />
                          </div>&nbsp;&nbsp;
                          <div class="input-group input-group-icon">
                          <span disabled className='strong'>Apellidos &nbsp;&nbsp;<font color="red">Campo fijo</font></span>
                            <input placeholder={apellidos_plataform}  onChange={this.onChangeInputForm_apellidos} id="apellidos" type="text"required/>
                          </div>&nbsp;&nbsp;
                          <div class="input-group input-group-icon">
          
                          {age} 
                          </div>&nbsp;&nbsp;
                          <div class="input-group input-group-icon">
                          <span className='strong'>correo &nbsp;&nbsp;<font color="red">Campo fijo</font></span>
                            <input disabled placeholder= {correo_plataform} onChange={this.onChangeInputForm_correo} id="correo" type="text"/>
                          </div>&nbsp;&nbsp;
                          <div class="input-group input-group-icon">
                          <span className='strong'>Teléfono <font color="red">*</font></span>
                            <input placeholder={telefono_plataform} onChange={this.onChangeInputForm_telefono} id="telefono" type="text"/>
                          </div>
                          <span className='strong'>Fecha de nacimiento <font color="red">*</font></span>
                          <div className="input-group">
                            <DatePicker className="browser-default custom-select" onChange={this.dateOnChange} style={{height:"2.7rem",width:"21rem"}} required />
                          </div>
                          <div class="input-group input-group-icon">
                          <span className='strong'>Código postal <font color="red">*</font></span>
                            <input placeholder= {cp_plataform}  onChange={this.onChangeInputForm_cp} id="cp" type="text"/>
                          </div>
                          <div class="input-group input-group-icon">
                          <span className='strong'>Teléfono de la empresa <font color="red">*</font></span>
                            <input placeholder= {telefono_empresa_plataform} onChange={this.onChangeInputForm_telefono_empresa} id="telefono" type="text" />
                          </div>
                          &nbsp;&nbsp;
                          <div class="input-group input-group-icon">
                          <span className='strong'>Profesión <font color="red">*</font></span>
                            <input placeholder= {profesion_plataform} onChange={this.onChangeInputForm_profesion} id="profesion" type="text"/>
                          </div>
                          <div class="input-group">
                          <span className='strong'>Género <font color="red">*</font></span>
                              <div class="input-group">
                                <select
                                  className="browser-default custom-select"
                                  type="select"
                                  name="genero"
                                  id="genero"
                                  required
                                  onChange={this.onChangeInputForm_genero}
                                >
                                  <option value="1">Seleccione una opción</option>
                                  <option value="Hombre">Hombre</option>
                                  <option value="Mujer">Mujer</option>                         
                                  <option value="Otro">Otro</option> 
                                </select>
                              </div>
                          </div>
                         
                          </div>
                          <center>
                          <div className='container33'>
                            <Button style={{width:"26rem"}} onClick={e=>this.cancelar()} type="danger">Cancelar</Button>&nbsp;&nbsp;&nbsp;
                            <Button
                              type="info"
                              onClick={e=>this.onSubmitBtnProfile(e)}
                              style={{width:"26rem"}}
                            >
                              Aplicar
                            </Button>               
                        </div>
                        </center>
                      </form>
              </div>
              </div>
            }
          
            let modalyt;
            if(this.state.yt === true){
              modalyt = <Modal footer={null} open={this.state.modal_open_yt} onOk = {e=>this.onOkyt()} onCancel ={e=>this.onOkyt() }>
                <center><h6>Comparte y no olvides suscribirte al canal</h6></center> 
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <YouTubeEmbed url={this.state.link_yt} width={525} height={220} />
                </div>
              </Modal>
            }
            let modalli;
            if(this.state.li === true){
              modalli = <Modal footer={null} open={this.state.modal_open_li} onOk = {e=>this.onOkli()} onCancel ={e=>this.onOkli() }>
                {title_modal}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LinkedInEmbed 
                  url={this.state.link_li}
                  width={325}
                  height={570} 
                />
              </div>
          
              </div>
              </Modal>
            }
            let card_inicio;
            let map;
            if(this.state.card === true && this.state.state){
              if(this.state.state[0]){
                map = this.state.state.map(({ id_courses, concepto, imagen, precio, descripcion, estatus,fb_link,insta_link,twiter_link,linked_link,youtube_link,args,hora_inicial,hora_final }) => {
                  let tag;
                  let fb;
                  let tw;
                  let yt;
                  let lin;
                  let post;
                  if(estatus === "activo"){
                    if(fb_link){
                      fb =<a onClick={e=>this.sharefb(fb_link)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4267B2" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                          </svg></a>
                    }else{
                      fb =
                        <a target="blank" href="https://www.facebook.com/IMAImx/"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4267B2" class="bi bi-facebook" viewBox="0 0 16 16">
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                        </svg></a>
                    }
                    if(twiter_link){
                      tw =<a target="_blank" onClick={e=>this.sharetw(twiter_link)} ><svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
                    }else{
                      tw =<a target="_blank" href="https://twitter.com/imai_ac"><svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
                    }
                
                    if(youtube_link){
                      yt =<a target="_blank" onClick={e=>this.shareyt(youtube_link)} ><svg width="25px" height="25px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="red" d="M14.712 4.633a1.754 1.754 0 00-1.234-1.234C12.382 3.11 8 3.11 8 3.11s-4.382 0-5.478.289c-.6.161-1.072.634-1.234 1.234C1 5.728 1 8 1 8s0 2.283.288 3.367c.162.6.635 1.073 1.234 1.234C3.618 12.89 8 12.89 8 12.89s4.382 0 5.478-.289a1.754 1.754 0 001.234-1.234C15 10.272 15 8 15 8s0-2.272-.288-3.367z"/><path fill="#ffffff" d="M6.593 10.11l3.644-2.098-3.644-2.11v4.208z"/></svg></a>
                    }else{
                      yt =<a target="_blank" href="https://www.youtube.com/channel/UCdyHevP0lR51a-AgKc0RjpA"><svg width="25px" height="25px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="red" d="M14.712 4.633a1.754 1.754 0 00-1.234-1.234C12.382 3.11 8 3.11 8 3.11s-4.382 0-5.478.289c-.6.161-1.072.634-1.234 1.234C1 5.728 1 8 1 8s0 2.283.288 3.367c.162.6.635 1.073 1.234 1.234C3.618 12.89 8 12.89 8 12.89s4.382 0 5.478-.289a1.754 1.754 0 001.234-1.234C15 10.272 15 8 15 8s0-2.272-.288-3.367z"/><path fill="#ffffff" d="M6.593 10.11l3.644-2.098-3.644-2.11v4.208z"/></svg></a>
                    }
                    if(linked_link){
                      lin = <a onClick={e=>this.shareli(linked_link)}><svg fill="#000000" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"/></svg>  </a>
                    }else{
                      lin =<a target="_blank" href="https://www.linkedin.com/company/imai_ac/"><svg fill="#000000" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"/></svg>  </a>
                    }
          
                    tag = <Tag color="rgb(24,46,88)">Curso activo</Tag>
          
                    post = <Badge.Ribbon text="Compártelo" color="purple">
                    <Card  size="small" style={{padding:"1em"}}>
                      {fb}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      {tw}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {yt}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {lin}
                    </Card>
                  </Badge.Ribbon>       
                  }
                  if(id_courses){
                  return (
                    <div key={id_courses} className="itemContainer">
                    <a onClick={e=>this.logonForm(id_courses)}>
                    <div class="card5">
                        <div class="card5-image">
                        {tag}
                            <img style={{width:"30em",height:"15em"}} src={imagen} alt="Curso de ejemplo"/>
                        </div>
                        <div class="card5-content">
                            <h2>{concepto}</h2>
                            <p>{descripcion}.</p>

                            <div class="social-links">
                                {post}
                            </div>
                            <div class="price">${precio}.00</div>
                        </div>
                        
                        </div>
                        </a>
                    </div>
                  )
                  }
                })
              }
              card_inicio = <Card style={{marginLeft:"16rem",marginTop:"3rem"}}>
              <div>
              {ratingCard}
              <div className="itemsContainer" style={{width:"100rem"}}>
                {map}
              </div>
              </div>
              </Card>
            }
            let home;
            if(this.state.args === true){
              home = <div >
                <Content/>
              </div>
            }

            let param = localStorage.getItem("id_users_plataform");
             let cotizaciones;
              if(this.state.cotizaciones === true){
                cotizaciones = <CotizacionesCourses id_user = {param}/>
              }
          
            let logon_form;
            if(this.state.form === true){
              let fecha_nueva;
              var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
              if(this.state.array.fecha_curso){
                let fecha = new Date(this.state.array.fecha_curso);
                fecha_nueva = fecha.toLocaleDateString("es-ES", options)
           
              }
              let youtube;
              if(this.state.array.youtube_link){
                youtube = <YouTubeEmbed url={this.state.array.youtube_link} width={340} height={240} />
              }

              let button;
              let costo;
              if(this.state.array.precio === '0'){
                costo = <Tag color="green">Curso sin costo</Tag>
                button = <button
                class="button2"
                onClick={e=>this.onSubmitBtn()}
                style={{width:"44rem"}} 
              >
                <span class="text">Registrarme</span>
                <span class="icon">
                  <img src = {svg}/>
                </span>
              </button>
              }if(this.state.array.precio !== '0'){
                if(localStorage.getItem("id_users_plataform")){
                  let cotizacion_seleccionada = this.state.cotizacion_seleccionada;
                  if(cotizacion_seleccionada[0]){
                    if(cotizacion_seleccionada[0].estatus_pago === "Pagado"){
                      button = <button
                        class="button2"
                        onClick={e=>this.onSubmitBtn()}
                        style={{width:"44rem"}} 
                      >
                        <span class="text">Registrarme</span>
                        <span class="icon">
                          <img src = {svg}/>
                        </span>
                      </button>
                    }else{
                      button = <button
                      class="button2"
                      onClick={e=>this.solicitar_cotizacion()}
                      style={{width:"44rem"}} 
                      >
                        <span class="text">Solicitar cotización</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="icon">
                          <img src = {svg}/>
                        </span>
                      </button>
                    }
                  }else{
                    button = <button
                      class="button2"
                      onClick={e=>this.solicitar_cotizacion()}
                      style={{width:"44rem"}} 
                    >
                      <span class="text">Solicitar cotización</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="icon">
                        <img src = {svg}/>
                      </span>
                    </button>
                  }
                costo = <Tag color="rgb(40, 95, 148)">Costo del curso {this.state.array.precio} MXN</Tag>
                
                }else{
                  swal({
                    text:`No olvides registrarte para poder solicitar tu cotización y obtener más beneficios que el IMAI tiene para ti`,               
                    icon:"warning",
                  });
                }
              }
              logon_form = <div class="container99" style={{marginTop:"4%",marginLeft:"35rem"}}>
              <h5 className="h4tag">Registrarse al {this.state.array.concepto}</h5>
              <div>
                <Card>
                <div className="rows">
                 <div style={{width:420}}>
                <form>
                <div class="row">
                  <div class="input-group input-group-icon">
                    <input onChange={this.onChangeNombre} id="nombre" type="text" placeholder={nombre_plataform || "Nombre"}/>
                    <div class="input-icon"><i class="fa fa-user"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input onChange={this.onChangeApellidos} id="apellidos" type="text" placeholder={apellidos_plataform || "Apellidos" } />
                    <div class="input-icon"><i class="fa fa-user"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input onChange={this.onChangeCp} id="apellidos" type="number" placeholder={cp_plataform || "Código postal" }/>
                    <div class="input-icon"><i class="fa fa-user"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input onChange={this.onChangeTelefono} id="telefono" type="number" placeholder={telefono_plataform || "Teléfono"}/>
                    <div class="input-icon"><i class="fa fa-user"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input  onChange={this.onChangeCorreo} id="correo" type="email" placeholder={correo_plataform || "Correo"}/>
                    <div class="input-icon"><i class="fa fa-envelope"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                  {button}
                  </div>
                      <button className="elegant-button2" onClick = {e=>this.open_modal_requisitos()}>
                          Requisitos
                      </button>
                      <button className="elegant-button" onClick = {e=>this.open_modal_semblanza(this.state.array.id_courses)}>
                          Semblanza
                      </button>
                      <Button className=" strong text-white" style={{marginTop:"4em",width:"44rem",backgroundColor:"rgba(214, 56, 39)"}} onClick={e=>this.cancelar()} >Cancelar</Button>

                </div>
                
              </form>
              <div style={{marginTop:"4em"}}>
                <ul>
          
                <li className="strong">Expositor: {this.state.array.instructor}</li>
            
                <li className="strong">{this.state.array.add1}</li>
                <li className="strong">{this.state.array.add2}</li>
                <li className="strong">{this.state.array.add3}</li>
                </ul>
                <img src={this.state.array.imagen} width={430} />
                </div>
              </div> 
              <Card className="card1">
              <p className="strong">Fecha del curso: <h5>{fecha_nueva} </h5></p> {costo}
               <header class="card1__thumb">
                {youtube}
                </header>
                <date class="card1__date">
                    <span class="card1__date__day">{this.state.array.hora_inicial}</span>
                    <span class="card1__date__month"> {this.state.array.hora_final}</span>
                </date>
                <div class="card1__body">
                    <div class="card1__category"><a href="#">{costo}</a></div>
                    <textarea class="card1__category" style={{marginTop:"2em",height:"350px"}}>{this.state.array.descripcion}</textarea>
                   
                </div>
                    
                </Card>
                
                </div>
              </Card>   
              
              </div>
              
            </div>
            }
          
            let cursos_exclusivos;
            let cursos_anteriores;
            let modulo_cotizaciones;
            if(token){
              cursos_exclusivos = <li class="item">
              <div href="#" class="nav_link submenu_item">
              <a href="#" class="nav_link" onClick={(e) => this.ShowItems("Exclusivos")}>
                <h6>
                <StarOutlined/>&nbsp;&nbsp;&nbsp;
                <span  className="strong">Cursos exclusivos</span>
                </h6>
              </a>
              </div>
            </li>
             cursos_anteriores = <li class="item">
             <div href="#" class="nav_link submenu_item">
             <a href="#"  onClick={(e) => this.ShowItems("Anteriores")}>
              <h6>
              <FieldTimeOutlined className="strong text-white" />&nbsp;&nbsp;&nbsp;
               <span className="strong text-white">Mis cursos</span>
               </h6>
             </a>
             </div>
           </li>
            modulo_cotizaciones = <li class="item">
                <div href="#" class="nav_link submenu_item">
                <a href="#" onClick={(e) => this.ShowItems("Cotizaciones")}>
                <h6>
                <FormOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; 
                <span className="strong text-white">Cotizaciones</span>
                </h6>
                </a>
                </div>
            </li>
            }
          
            let sesion;
            let login;
          
            let perfil;
          
            if(nombre_user){
              sesion  = <a className="rows" target="_blank" onClick={e=>this.cerrar_sesion()}><h6 className="rows"><CaretRightFilled className="strong text-white"/>&nbsp;&nbsp;&nbsp; 
              <p className="strong text-white">Cerrar_sesión</p></h6>
              </a>
          
              perfil =<div> <a>
              <span class="navlink" className="strong text-white">Perfil</span>
              </a>
              <hr class="dashed"/>
              <li class="item">
                <div href="#" class="nav_link submenu_item">
                <a href="#" class="nav_link" onClick={(e) => this.ShowItems("Profile")}>
                  <h6>
                  <UserOutlined className="strong text-white"/> &nbsp;&nbsp;&nbsp;
                  <span class="navlink" className="strong text-white">Perfil</span> 
                  </h6>
                </a>
                </div>
              </li>
              </div> 
            }else{
              login = <a className="rows"  href="/login_plataform"><i class="fa fa-user"></i> &nbsp;&nbsp;&nbsp; 
              <p className="strong text-white">Iniciar sesión</p>
              </a>
              titleData = <h6 className="strong text-white">Plataforma de cursos</h6>;
              registrate = <a className="rows"  href="/signup_plataform_curses"><i class="fa fa-user"></i> &nbsp;&nbsp;&nbsp; 
              <p className="strong text-white">Regístrate</p>
              </a> 
            }
          
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
          
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
          
            const formattedToday = dd + '/' + mm + '/' + yyyy;
            let fecha = formattedToday
            let completado =  localStorage.getItem("perfil_completado");
            let perfil_completado;
          
            if(completado === "false"){
              perfil_completado = <Tag color="rgb(163,201,84)" className="strong">Perfil sin completar</Tag>
            }if(completado === "true"){
              perfil_completado = <Tag color="rgb(163,201,84)" className="strong">Perfil completado</Tag>
            }
        return ( 
            <div>
                <div  style={{height:"100%"}} >
                    <nav  class="navbar" style={{backgroundColor:"rgb(16,47,91)"}}>
                    
                    <div><h6 className="strong text-white">&nbsp;&nbsp;&nbsp;{rfc} &nbsp;&nbsp;&nbsp;{rs}</h6></div>
                    <div><h6 className="strong text-white">{nombre_user + '' + apellidos_user}&nbsp;&nbsp;&nbsp; {correo_user}</h6></div>
                    <div class="navbar_content text-white">
                        <i class="bi bi-grid text-white"><h4 className="h4tag">{titleData} </h4></i>
                    </div>
                    <div><h6 className="strong text-white"><CalendarOutlined /> {fecha}</h6></div>
                    <div>{valoracion} </div>
                    <div>{perfil_completado}</div>
                    <div>
                
                    </div>
                    <div className="rows">
                    {registrate}&nbsp;&nbsp;&nbsp;
                    {login}
                    </div>
                    
                    </nav>
                    <nav class="sidebar" style={{backgroundColor:"rgb(16,47,91)",marginTop:"2%"}}>
                    <div class="logo_item">
                        <img src={ads} width={220} alt=""/>
                    </div>
                    <div style={{marginTop:"6%"}}></div>
                    <div class="menu_content">
                    <ul class="menu_items">
                        <li class="item">
                            <div href="#" class="nav_link submenu_item">
                            
                            <a target="_blank" 
                            onClick={(e) => this.ShowItems("All")}
                            >       
                                <h6>
                                <HomeOutlined className="strong text-white" />  &nbsp;&nbsp;&nbsp;
                                <span className="strong text-white">Inicio</span>
                                </h6>
                            </a>
                            </div>
                        </li>
                        <hr class="dashed"/>
                        <a>
                                <span className="strong text-white">Cursos disponibles</span>
                        </a>
                        <hr class="dashed"/>
                        <li class="item">
                            <div href="#" class="nav_link submenu_item">
                            <a target="_blank" 
                            onClick={(e) => this.ShowItems("Productos")}
                            > 
                                <h6> 
                                <CloudOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp;       
                                <span className="strong text-white">Presencial</span>
                                </h6>
                            </a>
                            </div>
                        </li>
                        <li class="item">
                            <div href="#" class="nav_link submenu_item">
                            <a href="#"  onClick={(e) => this.ShowItems("Comerciales")}>
                            <h6>
                            <DesktopOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp; 
                            <span  className="strong text-white">En Línea</span>
                            </h6>
                            </a>
                            </div>
                        </li>
                      
                        {cursos_anteriores}
                        {modulo_cotizaciones}
                        </ul>
                        <hr class="dashed"/>
                        <a>
                            <span className="strong text-white">Nuestros productos</span>
                        </a>
                        <hr class="dashed"/>
                        <ul class="menu_items">
                        
                        <li class="item">
                            <div href="#">
                            <a target="_blank" href="https://plataforma.adscontigo.com" class="nav_link sublink">   
                                <h6>    
                                <QuestionOutlined className="strong text-white"/>&nbsp;&nbsp;&nbsp;
                                <span className="strong text-white">ADSContigo</span>
                                </h6>  
                            </a>
                            </div>
                        </li>
                        <hr class="dashed"/>
                        {perfil}
                        <ul>
                        <li class="item">
                            <div href="#" class="nav_link     submenu_item">
                            {sesion}
                            </div>
                        </li>
                        </ul>
                        </ul>
                        
                    </div>
                    </nav>
                    <div>
                    {home}
                    {card_inicio}
                    {cotizaciones}
                    <center>
                    {profile_user}
                    </center>
                    {validation1}
                    <center>
                    {validation2}
                    </center>
                    {logon_form}
                    {modal}
                    {modaltw}
                    {modalyt}
                    {modalli}
                    {modalrequisitos}
                    {modalrequisitos2}
                    </div>
                    </div>
            </div>
         );
    }
 }
  
 export default Courses;