import React, { Component } from "react"
import MUIDataTable from "mui-datatables";
import { MDBIcon } from 'mdbreact';
import axios from 'axios'
import { Button, Card,Input,DatePicker} from 'antd';
import moment from 'moment';
import swal from "sweetalert";
import { API } from "../Graphql/Graphql";

class Eventos extends Component{
  constructor(props){
    super(props)
    this.state = {
         larazonSocial:'',        
         arrayApi:[],
         arrayfilter:[],
         cardInicial:true,
         cardCupones:false,         
         dataCuponSelect:[],
         inputValue:'',
         inputValue2:'',
         inputValue3:'',
         dropdownValue:'',
         tipoPoliza:'',
         fechaFinal:''
         
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)

        this.capturarFechaFinal = this.capturarFechaFinal.bind(this)
    } 
    changeHandler = event => {
      this.setState({tipoPoliza: event.target.value });
    };
     async componentWillMount(){
 // ************ API *********
        // axios.get(`https://www.eventbriteapi.com/v3/users/me/?token=LE7TWGVIP64TOSQ7VWHV`)
         //axios.get(`https://www.eventbriteapi.com/v3/categories/?token=LE7TWGVIP64TOSQ7VWHV`)   
         //axios.get(`https://www.eventbriteapi.com/v3/events/27493361384/?token=LE7TWGVIP64TOSQ7VWHV`) encontre el id_organizations  186333758287
 // *********************

       
        //   axios.get(`https://www.eventbriteapi.com/v3/organizations/524103565401/events/?token=LE7TWGVIP64TOSQ7VWHV`)
        // .then(res => {        
        //   this.setState({arrayApi:res.data.events})
        //  console.log("rows",this.state.arrayApi)            
        // }).catch(err=>{
        //   console.log(err)
        // })
           
        
      
     axios.get(`https://www.eventbriteapi.com/v3/organizations/113651495255/events/?page=12&token=4AAAXUODUJCWFTWJ4O7F`)
   
     .then(response=>{ 
       let eventos = response.data.events;
       let eventoActivo = eventos.filter(function(hero){
         return hero.status === "live" ||  hero.status === "completed" 
       })
       this.setState({arrayApi:eventoActivo})    
      }).catch(error=>{
        console.log("error",error)
      })
        }

      cupones(){
        this.setState({cardInicial:false})
        this.setState({cardCupones:true})
      }
      cuponSelect(param){
        this.setState({cardInicial:false})
        this.setState({cardCupones:false})
        let array = []
        array.push(param)
        this.setState({dataCuponSelect:array})
        console.log("array",array)
      }
      handleChange(e){
        this.setState({inputValue:e.target.value})
      }
      handleChange2(e){
        this.setState({inputValue2:e.target.value})
      }
      handleChange3(e){
        this.setState({inputValue3:e.target.value})
      }
      registerCupon(){
        swal({
          title: "Los datos ingresados son correctos?",
          text: "Nota: Asegúrese que los campos esten completos.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            let param = this.state.dataCuponSelect[0]
            let fecha = new Date()
            let codigo = this.state.inputValue;
            let descripcion = this.state.inputValue2
            let descuento = this.state.inputValue3
            let tipoPoliza = this.state.tipoPoliza
            let fechaFinal = this.state.fechaFinal
            let idEvento = param.id
            let url = param.url
            let fk_empresa = localStorage.getItem("fk_empresa")
            if(descuento && codigo && descripcion && tipoPoliza && fechaFinal){

              axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                      registerCupones(data:"${[descripcion,descuento,tipoPoliza,fecha,fechaFinal,idEvento,url,fk_empresa,codigo]}"){
                        message                             
                       } 
                    }
                    `
                }   
                 }).then(response=>{
                    let values = response.data.data.registerCupones.message
                    if(values === "registro exitoso"){
                      swal("Cupón registrado correctamente", {
                        icon: "success",
                      });
                      this.setState({cardInicial:false})
                      this.setState({cardCupones:true})
                      this.setState({dataCuponSelect:[]})
                      this.setState({inputValue:""})
                      this.setState({inputValue:""})
                      this.setState({inputValue:""})
                      this.setState({fechaFinal:""})                       
                      this.setState({tipoPoliza:""});
                    }
                  })
                 .catch(err=>{
                     console.log('error',err)
                 }) 
            }else{
              swal("Complete todos los campos", {
                icon: "warning",
              });
            }
           
          } else {
            swal("Operación cancelada", {
              icon: "error",
            });
          }
        });
        

      }
      capturarFechaFinal(e){
        if(e){  
        let fechaFinal = e._d.toString();   
          this.setState({fechaFinal:fechaFinal})                       
        }
    } 
    closeSelect(){
      this.setState({cardInicial:false})
      this.setState({cardCupones:true})
      this.setState({dataCuponSelect:[]})
      this.setState({inputValue:""})
      this.setState({inputValue:""})
      this.setState({inputValue:""})
      this.setState({fechaFinal:""})                       
      this.setState({tipoPoliza:""});
    }
    cancelCupones(){
      this.setState({cardInicial:true})
      this.setState({cardCupones:false})
    }
    render(){ 
      let cards; 
      let carousel;
      let buton;
           
      let buton2;

       const columns = ["Imagen","Evento","Fecha Final","Descripción","Registrarse"];
       const columns2 = ["Imagen","Evento","Fecha Final","Descripción","Seleccionar evento"];

        let data =  this.state.arrayApi.map(rows=>{  
          let fechaFinal;
          if(rows.end){
            let fechaFinal3 = rows.end.local.substring(0,4)
            let fechaFinal2 = rows.end.local.substring(5,7)
            let fechaFinal1 = rows.end.local.substring(8,10)
            fechaFinal = fechaFinal1 + "/" + fechaFinal2 + "/" + fechaFinal3
          }
              
          cards =  
          <td > <img src={rows.logo.url} style={{height:60,width:100}} /></td>           

            buton=<div>
               <Button type="primary" shape="circle" size="large" href={rows.url} target=" _blank"><MDBIcon icon="chalkboard-teacher"/></Button>
             
            </div>
        return( [ cards,rows.name.text,fechaFinal,rows.description.text, buton])
      })
      let data2 =  this.state.arrayApi.map(rows=>{  
        let fechaFinal;
        if(rows.end){
          let fechaFinal3 = rows.end.local.substring(0,4)
          let fechaFinal2 = rows.end.local.substring(5,7)
          let fechaFinal1 = rows.end.local.substring(8,10)
          fechaFinal = fechaFinal1 + "/" + fechaFinal2 + "/" + fechaFinal3
        }
            
        cards =  
        <td > <img src={rows.logo.url} style={{height:60,width:100}} /></td>           

          buton2=<div>
             <Button type="success" shape="circle" size="large" onClick = {e=>this.cuponSelect(rows)}><MDBIcon fas icon="check" /></Button>
           
          </div>
      return( [ cards,rows.name.text,fechaFinal,rows.description.text, buton2])
    })
      

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
      const options={ 
        filterType:"drowpdawn",
        responsive: "stacked",
        responsive:"standard",
        print:false,
        download:false,
        filter:true,
        caseSensitive:false,
        selectableRows:"none",
        elevation:0,
        viewColumns:false,  
        search:false,    
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      
      }        
      } 
      const options2={ 
        filterType:"drowpdawn",
        responsive: "stacked",
        print:false,
        download:false,
        filter:true,
        caseSensitive:false,
        selectableRows:"none",
        elevation:0,
        viewColumns:false,  
        search:true,    
        textLabels:{
        body: {
          noMatch: "Lo sentimos, no se encontraron registros coincidentes",
          toolTip: " Ordenar",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar evento",
          downloadCsv: " Descargar CSV",
          print: "Imprimir ",
          viewColumns: "Ver columnas",
          filterTable: "Tabla de filtros",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar / Ocultar columnas de tabla",
        },
        selectedRows: {
          text: "fila (s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      
      }        
    }
   
      let titulo  = <strong><h5>Tabla de Eventos de Eventbrite</h5></strong>
      let tituloCupones  = <strong><h5>Asignar cupones a eventos de Eventbrite</h5></strong>

      let cardCupones;
      if(this.state.cardCupones === true){
        cardCupones = <div  style={{width:"95%",marginLeft:"3%",marginTop:"2%"}} >  
        <Card title = {tituloCupones} extra = {<div>
          {/* <Button type="link">Cupón general</Button>&nbsp;&nbsp;&nbsp; */}
          <Button shape="circle" size="large" type="danger"  onClick={e=>this.cancelCupones()}><MDBIcon fas icon="times" /></Button>
          
        </div>}>        
           <MUIDataTable  
             data={data2}
             columns={columns2} 
             options={options2} 
               
           /> 
       </Card> 
      </div>
      }
      let cardInicial;
      if(this.state.cardInicial ===true){
        cardInicial = <div  style={{width:"95%",marginLeft:"3%",marginTop:"2%"}} >  
        <Card title = {titulo} extra = {<div><strong>Cupones </strong><Button shape="circle" size="large" type="primary" onClick = {e=>this.cupones()}><MDBIcon fas icon="barcode" /></Button></div>}>        
           <MUIDataTable  
             data={data}
             columns={columns} 
             options={options} 
               
           /> 
       </Card> 
      </div> 
      }
      let tableSelect;
      if(this.state.dataCuponSelect[0]){
        let param = this.state.dataCuponSelect[0]
        let tituloSelect =<strong>Evento seleccionado : {param.name.text}</strong>
        tableSelect = 
        <div  style={{width:"95%",marginLeft:"3%",marginTop:"2%"}} >  
        <Card type="inner" title = {tituloSelect} extra = {<div><Button shape="circle" size="large" type="danger" onClick = {e=>this.closeSelect()}><MDBIcon fas icon="times" /></Button></div>}>        
        <table className="table table-borderless table table-small table table-striped">
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Evento en Eventbrite</strong></td>
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%"><img style={{width:"200px"}} src={param.logo.url}></img></td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Id Evento</strong></td>
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%">{param.id}</td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Descuento %</strong></td>
            <td style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%"><Input placeholder="Ingrese el descuento en porcentaje" type="number" onChange={this.handleChange3}></Input></td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Código</strong></td>
            <td style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%"><Input placeholder="Ingrese el código de descuento" type="text" onChange={this.handleChange}></Input></td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Descripción</strong></td>
            <td style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%"><Input placeholder="Descripción del cupón" type="text" onChange={this.handleChange2}></Input></td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Tipo de póliza</strong></td>
            <td style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%">   
            <select  style={{paddingTop:"2px",paddingBotton:"2px"}} value={this.state.tipoPoliza} required onChange={this.changeHandler}  class="custom-select mr-sm-1" name="tipoPoliza">
                <option value="0"> Seleccione el tipo de póliza</option>
                <option value="1">Póliza activa</option>
                <option value="2">Póliza vencida</option>
            </select>
            </td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>Fecha de expiración</strong></td>
            <td style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%">{<DatePicker style={{ width: '100%'}} onChange={this.capturarFechaFinal} defaultValue={moment(new Date(), dateFormatList[0])} format={dateFormatList} />}</td>
          </tr>
          <tr >
            <td className="strong" style={{paddingTop:"2px",paddingBotton:"2px"}} width="20%"><strong>URL</strong></td>
            <td style={{paddingTop:"2px",paddingBotton:"2px"}} width="80%">{param.url}</td>
          </tr>
        </table>
        <center>
        <Button  style={{ width: '30%' }} block type="warning" onClick={e=>this.registerCupon()}> Registrar cupón </Button>
        </center> 
        </Card>
        </div>
      }
      //  const rs = localStorage.getItem("razonSocial");     
        return(
        <React.Fragment>
          {cardInicial}
          {cardCupones}
          {tableSelect}
          {/* <Navbar/> */}
             {/* <NavbarDashboard data={rs}/> */}
           
        </React.Fragment>
        )
    }
} export default Eventos