import React, { Component } from 'react'
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import MUIDataTable from "mui-datatables";
import { Card,Button,DatePicker,Input,Select,Space,InputNumber,Modal } from 'antd';
import moment from 'moment';
import { Tabs, Radio } from 'antd';
import {MDBBtn,MDBTable,MDBTableHead,MDBTableBody,MDBRow,MDBCol,MDBIcon} from 'mdbreact'
import { PDFExport } from '@progress/kendo-react-pdf';
import titulo1 from  '../imagen/imagent1.jpg';
import { Form, Row,Col,} from "reactstrap";
import swal from 'sweetalert'
const { TabPane } = Tabs;

const PageTemplate = (props) => {
    return (
      <div  style={{position:"absolute",bottom:"10px",width:550}}>
      <center>  
      <p  className = "textabla3" color="black" style = {{marginBottom:10}}>Av. Chapultepec N° 473, Piso 3 Col. Juárez, Del. Cuauhtémoc C.P. 06600 Ciudad de México.  <br/>
     Información, soporte y ventas: Conmutador:55 3603 9970 - 55 5553 2049</p>
      </center>
      </div>
    );
  };

 class HistorialPolizas extends Component{
    constructor(props){
        super(props)
        this.state = {
            polizas:[],
            isModalVisible:false,
            isModalVisible2:false,
            isModalVisible3:false,
            activarPoliza:[],
            editarPoliza:[],
            fecha:'',
            fechaFinal:'',
            fechaEditar:'',
            fechaEditarFinal:'',
            polizasRS:[],
            verPolizas:false,
            verPolizasRS:true,
            verTipo:false,
            filtradoPolizas:[],
            size: 'small',
            tipoTrimestral:[],
            tipoSemestral:[],
            tipoAnual:[],
            contabilidad:[],
            nominas:[],
            comercialPremium:[],
            comercialStart:[],
            bancos:[],
            verSistema:false,
            colsultar:false,
            agrupacion:[],
        }
        this.capturarFecha = this.capturarFecha.bind(this)
        this.capturarFechaFinal = this.capturarFechaFinal.bind(this)
        this.capturarFechaEditar = this.capturarFechaEditar.bind(this)
        this.capturarFechaEditarFinal = this.capturarFechaEditarFinal.bind(this)
    }
    componentWillMount(){
        let empresa  = localStorage.getItem("fk_empresa")
        axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                getPolizas(data:"${[empresa]}"){
                    fk_cliente
                    fechaInicial
                    fechaFinal
                    rfc
                    id_polizas
                    fechaInicial
                    statusPoliza
                    razonSocial
                    tipo
                    concepto
                    lineaProducto
                  } 
                }
                `
            }   
            }).then(response=>{
                let polizas = response.data.data.getPolizas

                // console.log("polizas",polizas);

                function group(arr, key) {
                    return [...arr.reduce( (acc, o) => 
                        acc.set(o[key], (acc.get(o[key]) || []).concat(o)), new Map).values()];
                }               
             const result = group(polizas, 'fk_cliente');
             this.setState({agrupacion:result})
             
            // console.log("result",result)

                function getUniqueListBy(arr, key) {
                    return [...new Map(arr.map(item => [item[key], item])).values()]
                }
                const arr1 = getUniqueListBy(polizas, 'razonSocial')
                this.setState({polizas:response.data.data.getPolizas})
                this.setState({polizasRS:arr1})
                const searchTipoTrimestral = 'Trimestral';
                const searchTipoTrimestral2 = 'TRIMESTRAL';
                const searchTipoTrimestral3 = 'trimestral';
                const searchTipoSemestral1 = 'Semestral';
                const searchTipoSemestral2 = 'SEMESTRAL';
                const searchTipoSemestral3 = 'semestral';
                const searchTipoAnual1 = 'Anual';
                const searchTipoAnual2 = 'ANUAL';
                const searchTipoAnual3 = 'anual';

                const searchSistemaContabilidad = 'Contabilidad';
                const searchSistemaContabilidad2 = 'CONTABILIDAD';
                const searchSistemaContabilidad3 = 'contabilidad';

                const searchSistemaNominas = 'Nominas';
                const searchSistemaNominas2 = 'Nóminas';
                const searchSistemaNominas3 = 'NOMINAS';
                const searchSistemaNominas4 = 'NÓMINAS';
                const searchSistemaNominas5 = 'nominas';
                const searchSistemaNominas6 = 'nóminas';
                const searchSistemaNominas7 = 'Nòminas';
                const searchSistemaNominas8 = 'NÒMINAS';

                const searchSistemaComercialPremium = 'Premium';
                const searchSistemaComercialPremium2 = 'PREMIUM';
                const searchSistemaComercialPremium3 = 'premium';

                const searchSistemaComercialStart = 'Start';
                const searchSistemaComercialStart2 = 'START';
                const searchSistemaComercialStart3 = 'start';

                const searchSistemaBancos = 'Bancos';
                const searchSistemaBancos2 = 'BANCOS';
                const searchSistemaBancos3 = 'bancos';

                const tipoTrimestral = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchTipoTrimestral) || val.includes(searchTipoTrimestral2) || val.includes(searchTipoTrimestral3)));
                const tipoSemestral = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchTipoSemestral1) || val.includes(searchTipoSemestral2) || val.includes(searchTipoSemestral3)));
                const tipoAnual = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchTipoAnual1) || val.includes(searchTipoAnual2) || val.includes(searchTipoAnual3)));
                
                const contabilidad = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaContabilidad) || val.includes(searchSistemaContabilidad2) || val.includes(searchSistemaContabilidad3)));
                const nominas = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaNominas) || val.includes(searchSistemaNominas2) || val.includes(searchSistemaNominas3) || val.includes(searchSistemaNominas4) || val.includes(searchSistemaNominas5) || val.includes(searchSistemaNominas6)|| val.includes(searchSistemaNominas7)|| val.includes(searchSistemaNominas8)));
                const comercialPremium = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaComercialPremium) || val.includes(searchSistemaComercialPremium2) || val.includes(searchSistemaComercialPremium3)));
                const comercialStart = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaComercialStart) || val.includes(searchSistemaComercialStart2) || val.includes(searchSistemaComercialStart3)));
                const bancos = polizas.filter(obj => Object.values(obj).some(val => val.includes(searchSistemaBancos) || val.includes(searchSistemaBancos2) || val.includes(searchSistemaBancos3)));
                
                // console.log("contabilidad",contabilidad)
                // console.log("nominas",nominas)
                // console.log("comercialPremium",comercialPremium)
                // console.log("comercialStart",comercialStart)
                // console.log("bancos",bancos)

                this.setState({tipoTrimestral:tipoTrimestral})
                this.setState({tipoSemestral:tipoSemestral})
                this.setState({tipoAnual:tipoAnual})
                this.setState({contabilidad:contabilidad})
                this.setState({nominas:nominas})
                this.setState({comercialPremium:comercialPremium})
                this.setState({comercialStart:comercialStart})
                this.setState({bancos:bancos})

            }).catch(err=>{
                console.log('error',err.response)    
        })      
     }
    
    handleOk = () => {
        this.setState({isModalVisible:false})
    };
    
    handleCancel = () => {
        this.setState({isModalVisible:false})
    };
    handleCancel2 = () => {
        this.setState({isModalVisible2:false})
    };
    handleCancel3 = () => {
        this.setState({isModalVisible3:false})
    };
    activar(param){
        this.setState({activarPoliza:param})
        this.setState({isModalVisible:true})
        this.setState({isModalVisible3:false})
    }
    editar(param){
        this.setState({editarPoliza:param})
        this.setState({isModalVisible2:true})
        this.setState({isModalVisible3:false})
    }
    capturarFecha(e){
        if(e){  
        let fechaInicio = e._d.toString();   
          this.setState({fecha:fechaInicio})                       
        }
    } 
    capturarFechaFinal(e){
        if(e){  
        let fechaFinal = e._d.toString();   
          this.setState({fechaFinal:fechaFinal})                       
        }
    } 
    capturarFechaEditar(e){
        if(e){  
        let fechaEditar = e._d.toString();   
          this.setState({fechaEditar:fechaEditar})                       
        }
    } 
    capturarFechaEditarFinal(e){
        if(e){  
        let fechaEditarFinal = e._d.toString();   
          this.setState({fechaEditarFinal:fechaEditarFinal})                       
        }
    } 
    activarPoliza(){
        let data = this.state.activarPoliza
        let fechaEmision = this.state.fecha
        let fechaFinal = this.state.fechaFinal
        if(fechaEmision  && fechaFinal){
            this.setState({isModalVisible:false})
            this.setState({isModalVisible3:true})
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                    activarPoliza(data:"${[fechaEmision,data.id_polizas,fechaFinal]}"){
                         message
                        } 
                    }
                    `
                }   
                }).then(response=>{ 
                  this.setState({isModalVisible:false})
                swal({
                    text:"La póliza se activó correctamente",
                    buttons: false,
                    icon:'success'
                }); 
                }).catch(err=>{
                    console.log('error',err)    
            })  
        }else{
            swal({
                text:"Ingrese la fecha de inicio de la póliza",
                buttons: false,
                icon:'warning'
            });
        }
    }
   
    editarPoliza(){
        let data = this.state.editarPoliza
        let fechaEdicion= this.state.fechaEditar
        let fechaEdicionFinal= this.state.fechaEditarFinal

        if(fechaEdicion && fechaEdicionFinal){
            this.setState({isModalVisible2:false})
            this.setState({isModalVisible3:true})
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                    editarPoliza(data:"${[fechaEdicion,data.id_polizas,fechaEdicionFinal]}"){
                         message
                        } 
                    }
                    `
                }   
                }).then(response=>{ 
                this.setState({isModalVisible2:false})
                swal({
                    text:"La fecha de la póliza se actualizó correctamente",
                    buttons: false,
                    icon:'success'
                });
                }).catch(err=>{
                    console.log('error',err)    
            })  
        }else{
            swal({
                text:"Ingrese una fecha válida de la póliza",
                buttons: false,
                icon:'warning'
            }); 
        }
    }
    verPolizas(){
        this.setState({verPolizas:true})
        this.setState({verPolizasRS:false})
        this.setState({verTipo:false})
        this.setState({verSistema:false})
        this.setState({consultar:false})

    }
    cerrarPolizas(){
        this.setState({verPolizas:false})
        this.setState({verPolizasRS:true})
        this.setState({verTipo:false})
        this.setState({verSistema:false})
        this.setState({consultar:false})

    }
    modalPolizas(param){
        let estado =  this.state.polizas
        
        let filtrado = estado.filter(function(rows){
           return param.fk_cliente === rows.fk_cliente
        })
        this.setState({filtradoPolizas:filtrado})
        this.setState({isModalVisible3:true})
    }
    verTipo(){
        this.setState({verPolizas:false})
        this.setState({verPolizasRS:false})
        this.setState({verTipo:true})
        this.setState({verSistema:false})
        this.setState({consultar:false})
    }
    verSistema(){
        this.setState({verPolizas:false})
        this.setState({verPolizasRS:false})
        this.setState({verTipo:false})
        this.setState({verSistema:true})
        this.setState({consultar:false})
    }
    consultarPolizas(){
        this.setState({verPolizas:false})
        this.setState({verPolizasRS:false})
        this.setState({verTipo:false})
        this.setState({verSistema:false})
        this.setState({consultar:true})
    }
    handleOkFinalizar(){
        window.location.reload();
    }
     render(){
        const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
        const { size } = this.state;

        const option ={ 
            elevation:0,
            filterType:"drowpdawn",
            responsive: "stacked",
            pagination:true,
            search:true,
            print:false,
            download:false,
            filter:false,
            caseSensitive:false,
            selectableRows:"none",
            viewColumns:false,      
            textLabels:{
            body: {
              noMatch: "Lo sentimos, no se encontraron registros coincidentes",
              toolTip: " Ordenar",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Página siguiente",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: " Descargar CSV",
              print: "Imprimir ",
              viewColumns: "Ver columnas",
              filterTable: "Tabla de filtros",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESET",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar / Ocultar columnas de tabla",
            },
            selectedRows: {
              text: "fila (s) seleccionadas",
              delete: "Eliminar",
              deleteAria: "Eliminar filas seleccionadas",
            },
          }            
        } 
        
    
        let activarPoliza = this.state.activarPoliza
        let status;
        if(activarPoliza.statusPoliza === "inactiva"){
            status = <font color="blue">Inactiva</font>
        }else if(activarPoliza.statusPoliza === "activa"){
            status = <font color="green">Activa</font>
        }else if(activarPoliza.statusPoliza === "vencida"){
            status = <font color="red">Vencida</font>
        }
        let modalActivar =  
        <Modal okText="Activar póliza" cancelText="Cancelar" width={1300} title="Activar fecha de inicio de la póliza" visible={this.state.isModalVisible} onOk={e=>this.activarPoliza()} onCancel={e=>this.handleCancel()}>
            <table className='table table-bordered table table-small table table-striped'>
                <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Razón social</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Id</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Concepto</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Tipo</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Status</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha Inicial</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha Final</strong></td>
                </tr>
                <tr>
                    <td>{activarPoliza.razonSocial}</td>
                    <td>{activarPoliza.id_polizas}</td>
                    <td>{activarPoliza.concepto}</td>
                    <td>{activarPoliza.tipo}</td>
                    <td>{status}</td>
                    <td>{<DatePicker onChange={this.capturarFecha}  placeholder='DD/MM/YYYY' format={dateFormatList} />}</td>
                    <td>{<DatePicker onChange={this.capturarFechaFinal}  placeholder='DD/MM/YYYY' format={dateFormatList} />}</td>
                </tr>  
            </table>
        </Modal>
        let fechaInicial;
        let fechaFinal;
        let stringFechaInicial;
        let stringFechaFinal;
        let editarPoliza = this.state.editarPoliza
        let statusEditar;
        let mes;
        let dia;
        let año;
        let hora;
        let mesEspañol;
        let mesFinal;
        let diaFinal;
        let añoFinal;
        let horaFinal;
        let mesEspañolFinal;
        if(editarPoliza.fechaInicial){
             mes = editarPoliza.fechaInicial.substring(4,7)
             dia = editarPoliza.fechaInicial.substring(8,10)
             año = editarPoliza.fechaInicial.substring(11,15)
             hora = editarPoliza.fechaInicial.substring(16,25)
            if(mes == "Jan"){
                mesEspañol = "Enero"
            }else if(mes == "Feb"){
                mesEspañol = "Febrero"
            }else if(mes == "Mar") {
                mesEspañol = "Marzo"
            }else if(mes == "Apr") {
                mesEspañol = "Abril"
            }else if(mes == "May") {
                mesEspañol = "Mayo"
            }else if(mes == "Jun") {
                mesEspañol = "Junio"
            }else if(mes == "Jul") {
                mesEspañol = "Julio"
            }else if(mes == "Aug") {
                mesEspañol = "Agosto"
            }else if(mes == "Sep") {
                mesEspañol = "Septiembre"
            }else if(mes == "Oct") {
                mesEspañol = "Octubre"
            }else if(mes == "Nov") {
                mesEspañol = "Noviembre"
            }else if(mes == "Dec") {
                mesEspañol = "Diciembre"
            }      

            mesFinal = editarPoliza.fechaFinal.substring(4,7)
            diaFinal = editarPoliza.fechaFinal.substring(8,10)
            añoFinal = editarPoliza.fechaFinal.substring(11,15)
            horaFinal = editarPoliza.fechaFinal.substring(16,25)
           if(mesFinal == "Jan"){
            mesEspañolFinal = "Enero"
           }else if(mesFinal == "Feb"){
            mesEspañolFinal = "Febrero"
           }else if(mesFinal == "Mar") {
            mesEspañolFinal = "Marzo"
           }else if(mesFinal == "Apr") {
            mesEspañolFinal = "Abril"
           }else if(mesFinal == "May") {
            mesEspañolFinal = "Mayo"
           }else if(mesFinal == "Jun") {
            mesEspañolFinal = "Junio"
           }else if(mesFinal == "Jul") {
            mesEspañolFinal = "Julio"
           }else if(mesFinal == "Aug") {
            mesEspañolFinal = "Agosto"
           }else if(mesFinal == "Sep") {
            mesEspañolFinal = "Septiembre"
           }else if(mesFinal == "Oct") {
            mesEspañolFinal = "Octubre"
           }else if(mesFinal == "Nov") {
            mesEspañolFinal = "Noviembre"
           }else if(mesFinal == "Dec") {
            mesEspañolFinal = "Diciembre"
           }      
        }
        if(editarPoliza.statusPoliza === "inactiva"){
            statusEditar = <font color="blue">Inactiva</font>
        }else if(editarPoliza.statusPoliza === "activa"){
            stringFechaInicial = <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha establecida</strong></td>
            fechaInicial = <td>{dia + " de " + mesEspañol + " de " + año + " " + hora}</td>
            statusEditar = <font color="green">Activa</font>
        }else if(editarPoliza.statusPoliza === "vencida"){
            // stringFechaInicial = <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Fecha registro</strong></td>
            // fechaInicial = <td>{dia + " de " + mesEspañol + " de " + año + " " + hora}</td>
            statusEditar = <font color="red">Vencida</font>
        }
        let modalEditar =  
        <Modal okText="Editar vigencia" cancelText="Cancelar" width={1300} title="Editar vigencia de la póliza" visible={this.state.isModalVisible2} onOk={e=>this.editarPoliza()} onCancel={e=>this.handleCancel2()}>
            <table className='table table-bordered table table-small table table-striped'>
                <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Razón social</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Id</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Concepto</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Tipo</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Status</strong></td>
                    {stringFechaInicial}
                    {stringFechaFinal}
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Nueva fecha Inicial</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Nueva fecha Final</strong></td>
                </tr>
                <tr>
                    <td>{editarPoliza.razonSocial}</td>
                    <td>{editarPoliza.id_polizas}</td>
                    <td>{editarPoliza.concepto}</td>
                    <td>{editarPoliza.tipo}</td>
                    <td>{statusEditar}</td>
                    {fechaInicial}
                    {fechaFinal}
                    <td>{<DatePicker placeholder='DD/MM/YYYY' onChange={this.capturarFechaEditar}  format={dateFormatList} />}</td>
                    <td>{<DatePicker placeholder='DD/MM/YYYY' onChange={this.capturarFechaEditarFinal} format={dateFormatList} />}</td>
                </tr>  
            </table>
        </Modal>
       
        let tablaPolizasRS;
        let titulo;
        if(this.state.verPolizasRS === true){
            titulo = <h6><strong>Historial de pólizas por Razón Social</strong></h6>    
            const columnRS = ["Id","Razón Social","RFC","Visualizar"];  
            const dataRS= this.state.polizasRS.map((rows)=>{  
                let boton = <MDBBtn size="sm" color="success" onClick={e=>this.modalPolizas(rows)}>Ver</MDBBtn>

                return([rows.fk_cliente,rows.razonSocial,rows.rfc,boton])
            });
            tablaPolizasRS = <div style={{width:"90%",marginLeft:"5%"}}> 
            <MUIDataTable  
                data={dataRS}
                columns={columnRS} 
                options={option}                     
                /> 
           </div>
        }
        let tablaPoliza;
        let butonCerrarPolizas;
        if(this.state.verPolizas === true){
            titulo = <h6><strong>Vista general de pólizas de soporte</strong></h6>    
            butonCerrarPolizas = <MDBBtn color="danger" size="sm" onClick={e=>this.cerrarPolizas()}>Cerrar tabla</MDBBtn>
            const data= this.state.polizas.map((rows)=>{  
                let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                  } else if(rows.fechaInicial){
                      const fechaI = new Date(rows.fechaInicial);    
                      var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                      let dia=fechaI.getDate()
                      let mesSubstring = fechaI.getMonth() +1
                      let posicionMes = parseInt(mesSubstring, 10)
                      let mes  = meses[posicionMes - 1] 
                      let año = fechaI.getFullYear()  
                       fechaInicial= dia+"/"+mes+"/"+año
                  }
                let statusPoliza;
                let boton;
                if(rows.statusPoliza === "inactiva"){
                    boton = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusPoliza = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusPoliza = <font color="green">Activa</font>
                    boton = <MDBBtn size = "sm" className="text-white" color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusPoliza = <font color="red">Vencida</font>                    
                    boton = <font>No disponible</font>
                }
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusPoliza,boton])
            });
            const column = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  
            tablaPoliza = <div style={{width:"90%",marginLeft:"5%"}}> 
            <MUIDataTable  
                data={data}
                columns={column} 
                options={option}                     
                /> 
           </div>
        }
        let modalPolizas =  
        <Modal okText="Aplicar cambios" cancelText="Cancelar" width={1300} title="Histórico de pólizas" visible={this.state.isModalVisible3} onOk={e=>this.handleOkFinalizar()} onCancel={e=>this.handleCancel3()}>
          <table className='table table-bordered table table-small table table-striped'>
                <tr>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Razón social</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Id</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Concepto</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Tipo</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Status</strong></td>
                    <td style={{paddingTop:"2px",paddingBottom:"2px"}}><strong>Administrar</strong></td>
                </tr>
                {this.state.filtradoPolizas.map(rows=>{
                    let statusPorPoliza;
                    let botonPorPoliza;
                    if(rows.statusPoliza === "inactiva"){
                        botonPorPoliza = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                        statusPorPoliza = <font color="blue">Inactiva</font>
                    }else if(rows.statusPoliza === "activa"){
                        statusPorPoliza = <font color="green">Activa</font>
                        botonPorPoliza = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                    }else if(rows.statusPoliza === "vencida"){
                        statusPorPoliza = <font color="red">Vencida</font>
                        botonPorPoliza = <font>No disponible</font>
                    }
                    return(
                    <tr>
                    <td>{rows.razonSocial}</td>
                    <td>{rows.id_polizas}</td>
                    <td>{rows.concepto}</td>
                    <td>{rows.tipo}</td>
                    <td>{statusPorPoliza}</td>
                    <td>{botonPorPoliza}</td>
                </tr> 
                    )
                })}
                 
            </table>
        </Modal>
        let tipo;
        let botonCerrarTipo;
        if(this.state.verTipo === true){
            titulo = <h6><strong>Vista general por tipo de pólizas de soporte</strong></h6>    
            botonCerrarTipo = <MDBBtn color="danger" size="sm" onClick={e=>this.cerrarPolizas()}>Cerrar tabla</MDBBtn>
            let botonTrimestral;
            let statusPolizaTrimestral;
            const dataTrimestral= this.state.tipoTrimestral.map((rows)=>{  
                let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                    } else if(rows.fechaInicial){
                        const fechaI = new Date(rows.fechaInicial);    
                        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                        let dia=fechaI.getDate()
                        let mesSubstring = fechaI.getMonth() +1
                        let posicionMes = parseInt(mesSubstring, 10)
                        let mes  = meses[posicionMes - 1] 
                        let año = fechaI.getFullYear()  
                        fechaInicial= dia+"/"+mes+"/"+año
                    }
                if(rows.statusPoliza === "inactiva"){
                    botonTrimestral = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusPolizaTrimestral = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusPolizaTrimestral = <font color="green">Activa</font>
                    botonTrimestral = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusPolizaTrimestral = <font color="red">Vencida</font>
                    botonTrimestral = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusPolizaTrimestral,botonTrimestral])
            });
            const columnTrimestral = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  
           
            let botonSemestral;
            let statusSemestral;
            const dataSemestral= this.state.tipoSemestral.map((rows)=>{  
                let fechaInicial
                    if (rows.fechaInicial === "no vigente"){                         
                        fechaInicial= "No asignado "
                    } else if(rows.fechaInicial){
                        const fechaI = new Date(rows.fechaInicial);    
                        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                        let dia=fechaI.getDate()
                        let mesSubstring = fechaI.getMonth() +1
                        let posicionMes = parseInt(mesSubstring, 10)
                        let mes  = meses[posicionMes - 1] 
                        let año = fechaI.getFullYear()  
                        fechaInicial= dia+"/"+mes+"/"+año
                    }
                if(rows.statusPoliza === "inactiva"){
                    botonSemestral = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusSemestral = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusSemestral = <font color="green">Activa</font>
                    botonSemestral = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusSemestral = <font color="red">Vencida</font>
                    botonSemestral = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusSemestral,botonSemestral])
            });
            const columnSemestral = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  
           
            let botonAnual;
            let statusAnual;
            const dataAnual= this.state.tipoAnual.map((rows)=>{  
                let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                    } else if(rows.fechaInicial){
                        const fechaI = new Date(rows.fechaInicial);    
                        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                        let dia=fechaI.getDate()
                        let mesSubstring = fechaI.getMonth() +1
                        let posicionMes = parseInt(mesSubstring, 10)
                        let mes  = meses[posicionMes - 1] 
                        let año = fechaI.getFullYear()  
                        fechaInicial= dia+"/"+mes+"/"+año
                    }
                if(rows.statusPoliza === "inactiva"){
                    botonAnual = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusAnual = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusAnual = <font color="green">Activa</font>
                    botonAnual = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusAnual = <font color="red">Vencida</font>
                    botonAnual = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusAnual,botonAnual])
            });
            const columnAnual = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  
           
            tipo = <Tabs defaultActiveKey="1" type="card" size={size}>
              <TabPane tab=" Póliza Trimestral" key="1">
                <div style={{width:"90%",marginLeft:"5%"}}> 
                    <MUIDataTable  
                        data={dataTrimestral}
                        columns={columnTrimestral} 
                        options={option}                     
                    /> 
                </div>
              </TabPane>
              <TabPane tab="Póliza Semestral" key="2">
                <div style={{width:"90%",marginLeft:"5%"}}> 
                    <MUIDataTable  
                        data={dataSemestral}
                        columns={columnSemestral} 
                        options={option}                     
                    /> 
                </div>
              </TabPane>
              <TabPane tab="Póliza anual" key="3">
                 <div style={{width:"90%",marginLeft:"5%"}}> 
                    <MUIDataTable  
                        data={dataAnual}
                        columns={columnAnual} 
                        options={option}                     
                    /> 
                </div>
              </TabPane>
            </Tabs>
        }

        let sistema;
        let botonCerrarSistema;

        if(this.state.verSistema === true){
            titulo = <h6><strong>Vista general por sistema de pólizas de soporte</strong></h6>    
            let botonSistemaContabilidad;
            let statusSistemaContabilidad;
            const dataContabilidad= this.state.contabilidad.map((rows)=>{  
                let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                    } else if(rows.fechaInicial){
                        const fechaI = new Date(rows.fechaInicial);    
                        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                        let dia=fechaI.getDate()
                        let mesSubstring = fechaI.getMonth() +1
                        let posicionMes = parseInt(mesSubstring, 10)
                        let mes  = meses[posicionMes - 1] 
                        let año = fechaI.getFullYear()  
                        fechaInicial= dia+"/"+mes+"/"+año
                    }
                if(rows.statusPoliza === "inactiva"){
                    botonSistemaContabilidad = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusSistemaContabilidad = <font color="red">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusSistemaContabilidad = <font color="green">Activa</font>
                    botonSistemaContabilidad = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusSistemaContabilidad = <font color="red">Vencida</font>
                    botonSistemaContabilidad = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusSistemaContabilidad,botonSistemaContabilidad])
            });
            const columnContabilidad = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"]; 

            let botonSistemaNominas;
            let statusSistemaNominas;
            const dataNominas= this.state.nominas.map((rows)=>{   let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                    } else if(rows.fechaInicial){
                        const fechaI = new Date(rows.fechaInicial);    
                        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                        let dia=fechaI.getDate()
                        let mesSubstring = fechaI.getMonth() +1
                        let posicionMes = parseInt(mesSubstring, 10)
                        let mes  = meses[posicionMes - 1] 
                        let año = fechaI.getFullYear()  
                        fechaInicial= dia+"/"+mes+"/"+año
                    }

                if(rows.statusPoliza === "inactiva"){
                    botonSistemaNominas = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusSistemaNominas = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusSistemaNominas = <font color="green">Activa</font>
                    botonSistemaNominas = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "Vencida"){
                    statusSistemaNominas = <font color="red">Vencida</font>
                    botonSistemaNominas = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusSistemaNominas,botonSistemaNominas])
            });
            const columnNominas = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  

            let botonSistemaPremuim;
            let statusSistemaPremium;
            const dataPremium= this.state.comercialPremium.map((rows)=>{  
                let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                  } else if(rows.fechaInicial){
                      const fechaI = new Date(rows.fechaInicial);    
                      var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                      let dia=fechaI.getDate()
                      let mesSubstring = fechaI.getMonth() +1
                      let posicionMes = parseInt(mesSubstring, 10)
                      let mes  = meses[posicionMes - 1] 
                      let año = fechaI.getFullYear()  
                       fechaInicial= dia+"/"+mes+"/"+año
                  }

                if(rows.statusPoliza === "inactiva"){
                    botonSistemaPremuim = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusSistemaPremium = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusSistemaPremium = <font color="green">Activa</font>
                    botonSistemaPremuim = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusSistemaPremium = <font color="red">Vencida</font>
                    botonSistemaPremuim = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusSistemaPremium,botonSistemaPremuim])
            });
            const columnPremium = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  

            let botonSistemaStart;
            let statusSistemaStart;
            const dataStart= this.state.comercialStart.map((rows)=>{  
                let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                    } else if(rows.fechaInicial){
                        const fechaI = new Date(rows.fechaInicial);    
                        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                        let dia=fechaI.getDate()
                        let mesSubstring = fechaI.getMonth() +1
                        let posicionMes = parseInt(mesSubstring, 10)
                        let mes  = meses[posicionMes - 1] 
                        let año = fechaI.getFullYear()  
                        fechaInicial= dia+"/"+mes+"/"+año
                    }
                if(rows.statusPoliza === "inactiva"){
                    botonSistemaStart = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusSistemaStart = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusSistemaStart = <font color="green">Activa</font>
                    botonSistemaStart = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusSistemaStart = <font color="red">Vencida</font>
                    botonSistemaStart = <font>No disponible</font>
                }
                
                return([rows.razonSocial,rows.id_polizas,rows.concepto,fechaInicial,rows.tipo,statusSistemaStart,botonSistemaStart])
            });
            const columnStart = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  

            let botonSistemaBancos;
            let statusSistemaBancos;
            const dataBancos= this.state.bancos.map((rows)=>{ 
             let fechaInicial
                if (rows.fechaInicial === "no vigente"){                         
                    fechaInicial= "No asignado "
                  } else if(rows.fechaInicial){
                      const fechaI = new Date(rows.fechaInicial);    
                      var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                      let dia=fechaI.getDate()
                      let mesSubstring = fechaI.getMonth() +1
                      let posicionMes = parseInt(mesSubstring, 10)
                      let mes  = meses[posicionMes - 1] 
                      let año = fechaI.getFullYear()  
                       fechaInicial= dia+"/"+mes+"/"+año
                  }
                if(rows.statusPoliza === "inactiva"){
                    botonSistemaBancos = <MDBBtn size = "sm" className="text-white" color = "primary" onClick={e=>this.activar(rows)}>Activar</MDBBtn>
                    statusSistemaBancos = <font color="blue">Inactiva</font>
                }else if(rows.statusPoliza === "activa"){
                    statusSistemaBancos = <font color="green">Activa</font>
                    botonSistemaBancos = <MDBBtn size = "sm" className="text-white"color = "warning" onClick={e=>this.editar(rows)}>Editar</MDBBtn>
                }else if(rows.statusPoliza === "vencida"){
                    statusSistemaBancos = <font color="red">Vencida</font>
                    botonSistemaBancos = <font>No disponible</font>
                } 
                return([rows.razonSocial,rows.id_polizas,rows.concepto,rows.fechaInicial,rows.tipo,statusSistemaBancos,botonSistemaBancos])
            });
            const columnBancos = ["Razón S.","Id","Concepto","fecha inicial","Tipo","Status","Activar o editar"];  

            botonCerrarSistema = <MDBBtn color="danger" size="sm" onClick={e=>this.cerrarPolizas()}>Cerrar tabla</MDBBtn>
            sistema = <Tabs defaultActiveKey="1" type="card" size={size}>
              <TabPane tab="CONTPAQi Contabilidad" key="1">
                <div style={{width:"90%",marginLeft:"5%"}}> 
                   <MUIDataTable  
                        data={dataContabilidad}
                        columns={columnContabilidad} 
                        options={option}                     
                    /> 
                </div>
              </TabPane>
              <TabPane tab="CONTPAQi Nòminas" key="2">
                <div style={{width:"90%",marginLeft:"5%"}}> 
                <MUIDataTable  
                        data={dataNominas}
                        columns={columnNominas} 
                        options={option}                     
                /> 
                </div>
              </TabPane>
              <TabPane tab="CONTPAQi Comercial PREMIUM" key="3">
                 <div style={{width:"90%",marginLeft:"5%"}}> 
                 <MUIDataTable  
                        data={dataPremium}
                        columns={columnPremium} 
                        options={option}                     
                /> 
                </div>
              </TabPane>
              <TabPane tab="CONTPAQi Comercial Start FE" key="4">
                 <div style={{width:"90%",marginLeft:"5%"}}> 
                 <MUIDataTable  
                        data={dataStart}
                        columns={columnStart} 
                        options={option}                     
                /> 
                </div>
              </TabPane>
              <TabPane tab="CONTPAQi Bancos" key="5">
                 <div style={{width:"90%",marginLeft:"5%"}}> 
                 <MUIDataTable  
                        data={dataBancos}
                        columns={columnBancos} 
                        options={option}                     
                /> 
                </div>
              </TabPane>
            </Tabs>
        }

        
        let tabla; 
        let a = 1; 
        if(this.state.consultar === true){
            let columnPolizas = ["#","ID","Razón Social","Tipo","Producto","Fecha I.","Fecha F.","Vigencia","Status Pólizas"]
            let dataPolizas
           if(this.state.polizas){    
                 
            dataPolizas = this.state.polizas.map(rows=>{   
                let fechaInicial
                let fechaFinal
                let time2 = new Date(rows.fechaFinal)                       
                let time1 = new Date(rows.fechaInicial)                      
                let diaPresente = new Date() 
                let DifferenceTime                       
                let DifferenceDays    
                let statusPoliza  
                 if(rows.statusPoliza === "activa"){
                    statusPoliza = <font color="blue">Activa</font>
                 }else if(rows.statusPoliza === "inactiva"){
                    statusPoliza = <font color="black">Inactiva</font>                     
                 }else if(rows.statusPoliza === "vencida"){
                    statusPoliza = <font color="red">Vencida</font>                     
                 }
               
                    if(diaPresente >= time1){
                        DifferenceTime= time2 - diaPresente
                        DifferenceDays =parseInt(DifferenceTime / (1000 * 3600 * 24));                                   
                        if(DifferenceDays <= 0) {
                            DifferenceDays= "0"
                        }               
                       
                     } else if (diaPresente <= time1){
                        DifferenceTime= time2 - time1
                        DifferenceDays =parseInt(DifferenceTime / (1000 * 3600 * 24));                              
                        }else{
                        DifferenceDays= "-"
                     }          
                
                if (rows.fechaInicial === "no vigente"){                         
                  fechaInicial= "No asignado "
                } else if(rows.fechaInicial){
                    const fechaI = new Date(rows.fechaInicial);    
                    var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                    let dia=fechaI.getDate()
                    let mesSubstring = fechaI.getMonth() +1
                    let posicionMes = parseInt(mesSubstring, 10)
                    let mes  = meses[posicionMes - 1] 
                    let año = fechaI.getFullYear()  
                     fechaInicial= dia+"/"+mes+"/"+año
                }
                if(rows.fechaFinal ==="no asignado"){
                    fechaFinal= "No asignado"
    
                }else{
                    const fechaF = new Date(rows.fechaFinal);    
                    var mesesF = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
                    let diaF=fechaF.getDate()
                    let mesSubstringF = fechaF.getMonth() +1
                    let posicionMesF = parseInt(mesSubstringF, 10)
                    let mesF  = mesesF[posicionMesF - 1] 
                    let añoF = fechaF.getFullYear()  
                    fechaFinal= diaF+"/"+mesF+"/"+añoF
    
                } 
              return([a++,rows.fk_cliente,rows.razonSocial,rows.tipo,rows.concepto,fechaInicial,fechaFinal,DifferenceDays,statusPoliza])
            })
           }            
            
            tabla =          
            <div style={{width:"95%",marginTop:"3%",marginLeft:"2%"}} >
                 <Card  extra = {<div>
                    <Button style={{backgroundColor:"cornsilk"}} shape="circle"  onClick={(e) => { this.pdfExportComponent.save();}} size="large"><MDBIcon fas icon="file-download"/></Button>
                    &nbsp;&nbsp;
                    <Button type="danger" className="ant-btn-primary" shape="circle" size="large" onClick = {e=>this.cerrarPolizas()}><MDBIcon fas icon="times"/></Button>
                      
                      </div>}>
                 <MUIDataTable  
                    data={dataPolizas} 
                    columns={columnPolizas} 
                    options={option} 
                />  
                </Card>
            </div>
        }
        let razonSocialEmpresa = localStorage.getItem("razonSocialEmpresa")
        let fechaA = new Date();
        let meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"); 
        let diaA = fechaA.getDate()
        let mesSubstring = fechaA.getMonth() +1
        let posicionMes = parseInt(mesSubstring, 10)
        let mesA  = meses[posicionMes - 1] 
        let añoA = fechaA.getFullYear()  
        let fechaActual= diaA +" de "+mesA+" del "+añoA        // 
        let razonSocialEmpresaProvedor = localStorage.getItem("razonSocialEmpresa")
        let pdfExport =
        
         <div style={{ position: "absolute", left: "-5000px", top: 0 }}>
                    {/* <div style={{ position: "absolute",  top: 0 }}>    */}
          <PDFExport
              paperSize="letter"
              margin="1cm"
              forcePageBreak=".page-break"
              fileName={"HISTORIAL DE PÓLIZAS "+ razonSocialEmpresa +".pdf"}
              pageTemplate = {PageTemplate}
  
              ref={(component) => this.pdfExportComponent = component}
              >
          <div> 
          <center>
          <MDBRow style={{marginTop:"2%"}} >
                              <MDBCol size="8">
                              <img src={titulo1} alt="imagen" style={{width:"40%",marginLeft:"-53%"}}/>
                              </MDBCol> 
                      </MDBRow>  
              </center>

               <div className="textabla3" style={{marginLeft:"72%",marginTop:"2%"}}>
               <fort><strong>{"Reporte Historial de Pólizas"}</strong></fort>
               <br></br>
               <fort><strong>Fecha:</strong>{fechaActual}</fort> 
                </div>  
                   <div style={{width:"95%", marginLeft:"2%"}}>                    
                    <fort className="textabla1"><strong>{razonSocialEmpresaProvedor}</strong></fort>
              </div>              
                <div style={{marginTop:"2%"}}>
                <center>
                <div>  
                <MDBTable class="table" striped bordered  small style={{width:555}} >
                       <thead>
                           <tr>
                           <th width="5%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">#</th>          
                           <th width="30%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Razón Social</th>
                           <th width="7%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Tipo</th>
                           <th width="35%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente" >Producto</th>
                           <th width="10%"  style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Fecha I.</th>
                           <th width="10%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Fecha F.</th>
                           <th width="7%" style={{padding:"2px"}} bgcolor="#05387A" className="textabla3 text-white fuente">Vigencia</th>
                           </tr>
                       </thead>
                       <tbody>
                           {this.state.polizas.map(rows=>{ 
                                  let fechaInicial
                                  let fechaFinal
                                  let time2 = new Date(rows.fechaFinal)                       
                                  let time1 = new Date(rows.fechaInicial)                      
                                  let diaPresente = new Date() 
                                  let DifferenceTime                       
                                  let DifferenceDays    
                                  let statusPoliza = rows.statusPoliza     
                                 
                                 
                                   if(diaPresente >= time1){
                                      DifferenceTime= time2 - diaPresente
                                      DifferenceDays =parseInt(DifferenceTime / (1000 * 3600 * 24));                                   
                                      if(DifferenceDays <= 0) {
                                          DifferenceDays= "0"
                                      }                 
                                     
                                   } else if (diaPresente <= time1){
                                      DifferenceTime= time2 - time1
                                      DifferenceDays =parseInt(DifferenceTime / (1000 * 3600 * 24));                              
                                   }
                                   else{
                                      DifferenceDays= "-"
                                   }                       
                                  
                                  if (rows.fechaInicial === "no vigente"){                         
                                    fechaInicial= "No asignado "
                                  } else if(rows.fechaInicial){
                                      var fechaI = new Date(rows.fechaInicial);
                                      var dd = fechaI.getDate();
                                      var mm = fechaI.getMonth()+1;
                                      var yyyy = fechaI.getFullYear()
                                      if(dd<10) {
                                        dd='0'+dd;
                                      } 
                                      if(mm<10) {
                                        mm='0'+mm;
                                      }    
                                    fechaInicial =  dd+"/"+mm+"/"+yyyy
                                  }
                                  if(rows.fechaFinal ==="no asignado"){
                                      fechaFinal= "No asignado"
          
                                  }else{ 
                                      var fechaF = new Date(rows.fechaFinal);
                                      var ddF = fechaF.getDate();
                                      var mmF = fechaF.getMonth()+1;
                                      var yyyyF = fechaF.getFullYear()
                                      if(ddF<10) {
                                        ddF='0'+ddF;
                                      } 
                                      if(mmF<10) {
                                        mmF='0'+mmF;
                                      }    
                                    fechaFinal =  ddF+"/"+mmF+"/"+yyyyF
          
                                  }                                           
                               return(
                                  <tr>
                                      <td style={{padding:"2px"}} align="center" className="textabla3" width="5%" >{a++}</td>
                                      <td style={{padding:"2px"}} align="left" className="textabla3" width="30%">{rows.razonSocial}</td>
                                      <td style={{padding:"2px"}} align="center" className="textabla3" width="7%">{rows.tipo}</td>
                                      <td style={{padding:"2px"}} align="left"  className="textabla3" width="35%">{rows.concepto}</td>
                                      <td style={{padding:"2px"}} align="center" className="textabla3" width="10%">{fechaInicial}</td>
                                      <td style={{padding:"2px"}} align="center" className="textabla3"width="10%">{fechaFinal}</td>
                                      <td style={{padding:"2px"}} align="center" className="textabla3" width="7%">{DifferenceDays}</td>
                                      {/* <td style={{padding:"2px"}} align="right" className="textabla3">{statusPoliza}</td> */}
                                  </tr> 
                                                                    
                               )                      
                            }
                            )} 
                       </tbody>
                </MDBTable >
                </div> 
              </center>                
           </div>  
        </div>
        </PDFExport>
        </div>     
        
        let boton2 = <MDBBtn color="success" size = "sm" onClick={e=>this.verSistema()} >Ver por Sistema</MDBBtn>
        let boton3 = <MDBBtn color="orange" className='text-white' size = "sm" onClick={e=>this.verTipo()} >Ver por Tipo</MDBBtn> 
        let boton1 = <MDBBtn color="primary" size = "sm" onClick={e=>this.verPolizas()} >Ver por pólizas</MDBBtn>        
        let boton4 = <MDBBtn color="primary" size = "sm" onClick={e=>this.consultarPolizas()} >Historial pólizas</MDBBtn>   
        return(
            <React.Fragment> 
            <center>
            <Card type="inner" style={{ width:"90%", marginTop:"2%"}} title={<div>{titulo}</div>} extra={<div>{boton3}&nbsp;&nbsp;&nbsp;{boton2}&nbsp;&nbsp;&nbsp;{boton1} &nbsp;&nbsp;&nbsp;{boton4}&nbsp;&nbsp;&nbsp; {butonCerrarPolizas}{botonCerrarTipo}{botonCerrarSistema}</div>}>
            {tipo}  
            {sistema} 
            {tablaPolizasRS}
            {tablaPoliza}
            {modalActivar}
            {modalEditar}            
            {modalPolizas}
            {tabla}
           </Card>
           </center>
           {pdfExport}
            
           </React.Fragment>
         )
     }
 }

 export default HistorialPolizas