import React, {Component} from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import ReactPlayer from 'react-player'
import {MDBIcon} from 'mdbreact'
import "./sidenavbar.css"
import axios from 'axios'
import {API} from '../Graphql/Graphql'
import {Card, Button} from 'antd'

class ModalImagesPreview extends Component{
  constructor(props){
    super(props);
    this.state={
      video1:[],
      video2:[],
      video3:[],
      videosActivos:[],
      carruselInicio:false,
      reproductorSeleccionado:false,
      videoSeleccionado:[],
      porlizaActivaEncontrada:[],
      porlizaVencidaEncontrada:[],
      polizaInactivaEncontrada:[],
      carouselInactivo:false,
      carouselVencido:false,
      array:[],
      videosEncontrados:[],
      sinPolizaActiva:false,
      polizasInactivas:'',
      polizasVencidas:''
    }
  }

 
  async componentWillMount(){  
    let empresa =localStorage.getItem("fk_empresa")
    await  axios({
           url:API,
           method:'post',
           data:{
             query:`
               query{   
                   getURLVideos(data:"${[empresa]}"){
                   id_videos 
                   descripcion 
                   autor 
                   urlVideos
                   fechaInicio,
                   fechaExpiracion
                   statusVideo
                   fk_empresa
                   message  
                   } 
               }
               `
               }           
            })
          .then(response => {
           // array.push(datos.data.data.getTablaClientesAlfa)
             let totalVideos =  response.data.data.getURLVideos;
             console.log("totalVideos",totalVideos)
            this.setState({videosEncontrados:totalVideos})
           })
           .catch(err=>{
              console.log('error' ,err.response)
           })    
           let id_cliente = localStorage.getItem("id_cliente")
          axios({
            url:API,
            method:'post',
            data:{
                query:`
                query{
                getPoliza(data:"${[id_cliente]}"){
                    id_polizas
                    concepto
                    fechaInicial
                    fechaFinal
                    statusPoliza
                    } 
                }
                `
            }   
            }).then(response=>{  
              let array = response.data.data.getPoliza
              this.setState({array:array})
              let porlizaActivaEncontrada = array.filter(function(hero){
                return hero.statusPoliza === "activa"
              })
              if(porlizaActivaEncontrada[0]){
                this.setState({carouselVencido:false})
                this.setState({carruselInicio:true})
                this.setState({carouselInactivo:false})
                this.setState({sinPolizaActiva:false})
              }else{
                this.setState({carouselVencido:false})
                this.setState({carruselInicio:false})
                this.setState({carouselInactivo:false})
                this.setState({sinPolizaActiva:true})
              }

              let porlizaVencidaEncontrada = array.filter(function(hero){
                return hero.statusPoliza === "vencida"
              })

              let polizaInactivaEncontrada = array.filter(function(hero){
                return hero.statusPoliza === "inactiva"
              })
              if(polizaInactivaEncontrada[0]){
                this.setState({polizasInactivas:polizaInactivaEncontrada.length})
                this.setState({carouselInactivo:true})
              }
              if(porlizaVencidaEncontrada[0]){
                this.setState({polizasVencidas:porlizaVencidaEncontrada.length})

                this.setState({carouselVencido:true})
              }

              this.setState({porlizaActivaEncontrada:porlizaActivaEncontrada})
              this.setState({porlizaVencidaEncontrada:porlizaVencidaEncontrada})
              this.setState({polizaInactivaEncontrada:polizaInactivaEncontrada})

            }).catch(err=>{
                console.log('error',err.response)    
            })  
   }

   seleccionarVideo = async(data) =>{
     await  this.setState({videoSeleccionado:[]})

     this.setState({videoSeleccionado:data})
     this.setState({carruselInicio:false})
     this.setState({carouselInactivo:false})
     this.setState({carouselVencido:false})

     this.setState({reproductorSeleccionado:true})
     window.scrollTo(0, 0)

   }
   cerrarVideo(){
    this.setState({videoSeleccionado:[]})
    this.setState({carruselInicio:true})
    this.setState({reproductorSeleccionado:false})
    window.scrollTo(0, 0)
   }
    render(){
      const { Meta } = Card;
      let cards;
      if(this.state.videosEncontrados[0] && this.state.carruselInicio === true){
        cards = <div className="divelement" style={{ width:"100%"}}>
        {this.state.videosEncontrados.map((rows,i)=>{
          console.log("index",i)
          return(
            <div className="element">
            <Card
              hoverable
              style={{ width: 320 }}
              cover={ <ReactPlayer
                onContextMenu={e =>e.preventDefault()}
                url={rows.urlVideos}
                volume='1'
                width='100%'
                height="100%"
                playing={false}
              />}
            >
            <Meta title={rows.descripcion} description={rows.autor} />
            <div id="button">
            <Button shape="circle" size="large" type="danger" onClick={e=>this.seleccionarVideo(rows)}><MDBIcon icon="angle-double-right" /></Button>
            </div>
            </Card>
            </div>
          )
        })}
        </div>
      }
      let carruselLabelInactivo;
      let carruselLabelVencido;
      let carruselLabel;

      if(this.state.carouselInactivo === true ){
        carruselLabelInactivo = <font color = "green"> <strong>Cuenta con {this.state.polizasInactivas} póliza(s) inactiva(s), solicite su activación para poder visualizar los videos exclusivos o acceder a soporte técnico del sistema solicitado</strong></font>
      } 
      if(this.state.carouselVencido === true){
        carruselLabelVencido = <font color = "warning"> <strong>Cuenta con  {this.state.polizasVencidas} póliza(s) vencidas(s), solicite su renovación para poder visualizar los videos exclusivos o acceder a soporte técnico del sistema solicitado</strong></font>
      }
      if(this.state.sinPolizaActiva === true){
        carruselLabel = <font color = "red"> <strong>No cuenta con póliza activa</strong></font>
      }
      let carrusel;
      if(this.state.videosEncontrados[0]  && this.state.carruselInicio === true){
        carrusel=   <Carousel style={{marginTop:"2%"}} infiniteLoop={true} autoPlay={true} width="80%" height="50%">
        {this.state.videosEncontrados.map((rows,i)=>{
          return(
            <div>
              <p className="legend">{rows.descripcion} <br/> {rows.autor}</p>
              <ReactPlayer
                onContextMenu={e => e.preventDefault()}
                url={rows.urlVideos}
                volume='1'
                width='100%'
                playing={false}
                
              />
            </div>
          )
        })}
      </Carousel>
      }

      if(this.state.sinPolizaActiva === true){
        carrusel = <Carousel infiniteLoop={true} autoPlay={true} width="65%" >
        <div>  
        <div>
        <p width="55%" height="30%" className="legend"><font color = "white">{"Video Blockeado"} <br/> {"Suscripción no disponible, póliza no encontrada."}</font></p>
        <img src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
        </div>
        </div>
      </Carousel>
       cards = <div>
       <center>
       <div className="iframe">
       <Carousel showThumbs={false} infiniteLoop={false} autoPlay={false} width="65%" >
         <div>
         <p width="55%" height="30%" className="legend"><font color = "white">{"Video blockeado"} <br/>  {"Suscripción no disponible, póliza no encontrada."}</font></p>
             <img width={320} src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
         </div> 
       </Carousel>
       <Carousel showThumbs={false} infiniteLoop={false} autoPlay={false} width="65%" >
         <div>
         <p width="55%" height="30%" className="legend"><font color = "white">{"Video blockeado"} <br/>  {"Suscripción no disponible, póliza no encontrada."}</font></p>
             <img width={320} src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
         </div> 
       </Carousel>
       <Carousel showThumbs={false} infiniteLoop={false} autoPlay={false} width="65%" >
         <div>
         <p width="55%" height="30%" className="legend"><font color = "white">{"Video blockeado"} <br/>  {"Suscripción no disponible, póliza no encontrada."}</font></p>
             <img width={320} src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
         </div> 
       </Carousel>
       </div>
       <div style={{marginTop:"2%"}} className="iframe">
       <Carousel showThumbs={false} infiniteLoop={false} autoPlay={false} width="65%" >
         <div>
         <p width="55%" height="30%" className="legend"><font color = "white">{"Video blockeado"} <br/>  {"Suscripción no disponible, póliza no encontrada."}</font></p>
             <img width={320} src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
         </div> 
       </Carousel> 
       <Carousel showThumbs={false} infiniteLoop={false} autoPlay={false} width="65%" >
         <div>
         <p width="55%" height="30%" className="legend"><font color = "white">{"Video blockeado"} <br/>  {"Suscripción no disponible, póliza no encontrada."}</font></p>
             <img width={320} src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
         </div> 
       </Carousel>
       <Carousel showThumbs={false} infiniteLoop={false} autoPlay={false} width="65%" >
         <div>
         <p width="55%" height="30%" className="legend"><font color = "white">{"Video blockeado"} <br/>  {"Suscripción no disponible, póliza no encontrada."}</font></p>
             <img width={320} src = "https://i.pinimg.com/originals/e7/06/fb/e706fb50517b1d98d5058337ae3ef70b.gif"></img>
         </div> 
       </Carousel>
       </div>
       </center>  
       </div>
      }

      
      let reproductorSeleccionado;

      if(this.state.reproductorSeleccionado === true){

        let videoSeleccionado = this.state.videoSeleccionado;
        reproductorSeleccionado = <Carousel infiniteLoop={true} autoPlay={false} width="80%" height="50%">
        <div>
        <Button style={{width:"91%", marginBottom:"1%",background: "#C94A4A"}}  type="danger"  onClick = {e=> this.cerrarVideo()}>Cerrar video</Button>  

          <ReactPlayer
            onContextMenu={e => e.preventDefault()}
            url={videoSeleccionado.urlVideos}
            volume='1'
            width='100%'
            playing
          />
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p className="legend">{videoSeleccionado.descripcion} <br/> {videoSeleccionado.autor}</p>

        </div>      
      </Carousel>
      }

  return (
    <div  style={{marginTop:"2%"}}>
      <center>
      <div width="100%"> 
      {carruselLabel}
      <br/>
      {carruselLabelInactivo}
      <br/>
      {carruselLabelVencido}
      <br/>
      </div> 
      {carrusel}
      {reproductorSeleccionado}

      </center>
      {cards}
    </div>
  )
}
}
export default ModalImagesPreview