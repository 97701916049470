import React, { Component } from 'react';
import { Card, Row,Col } from 'antd';
import { MDBView, MDBMask } from "mdbreact";
import imagen1 from '../imagen/imagen1.svg'
import imagen2 from '../imagen/imagen2.svg'
import imagen3 from '../imagen/imagen3.svg'
import imagen4 from '../imagen/imagen4.svg'
import '@fortawesome/fontawesome-free/css/all.min.css';
 import'bootstrap-css-only/css/bootstrap.min.css'; 
import'mdbreact/dist/css/mdb.css';
import './style.css'

 class Dashboard extends Component{
     constructor(props){
         super(props)
         this.state = {
         }
     }
 render(){
     let menu;
     let titulo1 = <h5 className='text-center'>Registra Empresa</h5>
     let titulo2 = <h5 className='text-center'>Registrar Administrador</h5>
     let titulo3 = <h5 className='text-center'>Login Administrador</h5>
     let titulo4 = <h5 className='text-center'>Login Cliente</h5>
     menu= 
<Row align='center'  style={{marginTop:"15%"}} gutter={16}>
   <Col>
     <a href="/loginEmpresa">
            <MDBView hover zoom>              
              <div className="site-card-border-less-wrapper" >        
                <Card title={titulo1}   bordered={true} style={{ width: 300 }}>                
                    <img style={{ width: 170 }} alt="example" src={imagen1}/>               
                </Card>    
              </div>
                <MDBMask className="flex-center">
                    <p>Registrar Empresa</p>
                </MDBMask>
            </MDBView>
     </a>       
   </Col>
   <Col>
     <a href="/loginAdministrador">
            <MDBView hover zoom>              
              <div className="site-card-border-less-wrapper" >        
                <Card title={titulo2}   bordered={true} style={{ width: 300 }}>                
                    <img style={{ width: 200 }} alt="example" src={imagen2}/>               
                </Card>    
              </div>
                <MDBMask className="flex-center">
                    <p>Registrar Administrador</p>
                </MDBMask>
            </MDBView>
     </a>       
   </Col>
   <Col>
     <a href="/">
            <MDBView hover zoom>              
              <div className="site-card-border-less-wrapper" >        
                <Card title={titulo3}   bordered={true} style={{ width: 300 }}>                
                    <img style={{ width: 290 }} alt="example" src={imagen3}/>               
                </Card>    
              </div>
                <MDBMask className="flex-center">
                    <p>Login Administrador</p>
                </MDBMask>
            </MDBView>
     </a>       
   </Col>
   <Col>
     <a  href="/loginCliente">
            <MDBView hover zoom>              
              <div className="site-card-border-less-wrapper" >        
                <Card title={titulo4}   bordered={true} style={{ width: 300 }}>                
                    <img style={{ width: 250 }} alt="example" src={imagen4}/>               
                </Card>    
              </div>
                <MDBMask className="flex-center">
                    <p>Login Cliente</p>
                </MDBMask>
            </MDBView>
     </a>       
   </Col>
</Row>   
  



    return(
        <React.Fragment>
            <div  id="apppages3" >  
                <MDBView>
                {menu} 
                </MDBView>
            </div>        
         </React.Fragment>
        )

 }
     
 }export default Dashboard