import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Género", "Distribución por género"],
  ["Hombre", 11],
  ["Mujer", 12],
  ["Otros", 2],
];

export const data4 = [
    ["Populares", "Distribución por cursos populares"],
    ["Contabilidad", 50],
    ["Nóminas", 30],
    ["Comercial", 20],
  ];


export const data2 = [
    ["Clicks", "Número de clicks"],
    ["Contabilidad", 11],
    ["Nóminas", 124],
    ["Comercial", 22],
    ["Bancos", 332],    
    ["XML", 42],
    ["Factura electrónica", 52],
    ["Punto de venta", 12],
    ["Producción", 22],
    ["Informática", 42],
  ];

  export const data3 = [
    ["Red social", "posts"],
    ["Facebook", 444],
    ["Twiter", 24],
    ["Instagram", 222],
    ["Linkedin", 132],    
    
  ];

export const options = {
  title: "Distribución de participantes por género",
  pieHole: 0.4,
  is3D: false,
};
export const options2 = {
    title: "Distribución por clicks dados al abrir el modal de registro",
    pieHole: 0.4,
    is3D: false,
};
export const options3 = {
title: "Red social más compartida",
is3D:true
};
export const options4 = {
    title: "Cursos más populares",
    is3D:true
};
function App() {
  return (
    <div >
    <div className="rows">
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data2}
      options={options2}
    />
    </div>   
    <div className="rows">
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data3}
      options={options3}
    />
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data4}
      options={options4}
    />
    </div> 
    </div>
    
  );
}

export default App;
