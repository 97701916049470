import React, { Component } from 'react'
import { Button, Modal, Card } from 'antd';
import actionsAPI from './actionsAPI';
import swal from 'sweetalert';
class VideoPromocional extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modal:false,
            url:"",
            descripcion:'',
            data_video_promocional:[],
            status_video:false
        }
        this.onChangeInputForm = this.onChangeInputForm.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
    modal(){
        this.setState({modal:true})
    }
    onOk(){
        this.setState({modal:false})
    }
    handleChange(e){
        if(e.target.value ){
            this.setState({url:e.target.value})
        }
    }
    onChangeInputForm = (e) => {
        const { id, value } = e.target; 
        this.setState({
            [id]: value
        });
    };
    addVideoPromocional(){
        let url = this.state.url;
        let descripcion = this.state.descripcion 
        if(url && descripcion){
        let array = [];
        actionsAPI.addVideoPromocional(url,descripcion).then(response=>{
            if(response.data.data.addVideoPromocional.message === "curso activo"){
                array.push(response.data.data.addVideoPromocional)
                this.setState({data_video_promocional:array});
                this.setState({status_video:true});
                swal({
                    text:"Ya hay un video promocional activo, por favor desactívelo e inténtelo nuevamente.",               
                    icon:"warning",
                }); 
            }else if(response.data.data.addVideoPromocional.message === "registro exitoso"){
                swal({
                    text:"Registro exitoso",               
                    icon:"success",
                }); 
                this.setState({modal:false})
                this.setState({url:''})
                this.setState({descripcion:''})
            }else{
                swal({
                    text:"Algo salió mal !",               
                    icon:"error",
                }); 
            }
        }).catch(err=>{
            console.log(err)
            console.log(err.response)
        })
    }else{
        swal({
            text:"Complete todos los campos",               
            icon:"error",
        }); 
    }
    }
    desactivar(param){
        actionsAPI.desactivarVideoPromocional(param).then(response=>{
            if(response.data.data.desactivarVideoPromocional.message === "video desactivado"){
                swal({
                    text:"Desactivado",               
                    icon:"success",
                }); 
                this.setState({status_video:false});
                this.setState({data_video_promocional:[]});
            }
        }).catch(err=>{
            console.log(err)
            console.log(err.response)
        })
    }    
    render() { 
        let video_activo;
        if(this.state.data_video_promocional[0] && this.state.status_video === true){
            console.log("url",this.state.data_video_promocional[0].url)
            let urls = this.state.data_video_promocional[0].url
            video_activo = <Card tile={<p className='strong'>Video activo</p>}>
                <table className='table table-bordered table-small table-striped'>
                    <tr>
                        <td className='strong'>Descripción</td>
                        <td>{this.state.data_video_promocional[0].descripcion}</td>
                    </tr>
                    <tr>
                        <td className='strong'>Video</td>
                        <td><a target='_blank' href={urls}>{urls}
                        </a></td>
                    </tr>
                </table>
                <center>
                    <Button type='danger' onClick={e=>this.desactivar(this.state.data_video_promocional[0].id_video_promocional)}>Desactivar</Button>
                </center>
            </Card>
        }
        let modal = <Modal width={750}footer={null} title={<h4 className='h4tag'>Registrar video promocional</h4>} open={this.state.modal} onOk={e=>this.onOk()} onCancel={e=>this.onOk()}>
            {video_activo}
            <form onSubmit={this.editar_expositor}>
                <center>
                <div style={{padding:"3rem"}}>
                    <div class="input-group input-group-icon">
                        <input onChange={this.handleChange} id="url" type="text" value={this.state.url} placeholder="Url del video" required/>
                        <div class="input-icon"><i class="fa fa-check"></i></div>
                    </div>
                    <div class="input-group input-group-icon">
                        <input onChange={this.onChangeInputForm} id="descripcion" value={this.state.descripcion} type="text" placeholder="Descripción" required/>
                        <div class="input-icon"><i class="fa fa-check"></i></div>
                    </div>
                </div>
                <Button
                    
                    onClick={e=>this.addVideoPromocional(e)}
                    className='text-white'
                    style={{width:"17rem",backgroundColor:"rgb(38,90,159)"}}
                    >
                    Aceptar
                    </Button>   
                </center>  
            </form>

        </Modal>
        return ( 
            <div>
                <Button onClick = {e=>this.modal()}  style={{backgroundColor:"rgb(38,90,159)"}} className='text-white'>Video promocional</Button>
                {modal}
            </div>

        );
    }
}
 
export default VideoPromocional;