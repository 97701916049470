    import { API } from "../Graphql/Graphql";
    import axios from "axios";
    const getTablaClientesAlfa = (fk_empresa) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
            query{   
            getTablaClientesAlfa(data:"${[fk_empresa]}"){
                id_cliente
                rfc
                razonSocial
                tipoEmpresa
                tamanoEmpresa
                giroEmpresarial
                correo
                telefono
                paginaWeb
                domicilioFiscal
                acceso
                fk_contactoAcceso
                } 
            }
            `,
        },
        })
        .then((datos) => {
            let getApi = datos.data.data.getTablaClientesAlfa;
            try {
            resolve(getApi);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };

    const getContacto = (id) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
            query{
            getTablaContactos(data:"${[id]}"){
                id_contacto
                nombre
                apellidos
                correo1
                correo2
                telefono1
                extensionTelefonica
                telefono2
                puesto
                tipoContacto              
                } 
            }
            `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
            //  this.setState({ modal:false });
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const insertContacto = (
    nombre,
    apellidos,
    correo1,
    correo2,
    telefono1,
    ext,
    telefono2,
    puesto,
    tipoContacto,
    idCliente
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
            mutation{
                insertContacto(data:"${[
                nombre,
                apellidos,
                correo1,
                correo2,
                telefono1,
                ext,
                telefono2,
                puesto,
                tipoContacto,
                idCliente,
                ]}"){         
            message
            } 
            }
            `,
        },
        })
        .then((response) => {
            console.log(response)
            // try {
            // resolve(response);
            // } catch {
            // reject("error");
            // }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };

    const updateContacto = (
    id_contacto,
    nombreContacto,
    apellidoContacto,
    correo1Contacto,
    correo2Contacto,
    telefono1Contacto,
    extContacto,
    telefono2Contacto,
    puestoContacto,
    tipoContacto2
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                updateContacto(data:"${[
                id_contacto,
                nombreContacto.toUpperCase(),
                apellidoContacto.toUpperCase(),
                correo1Contacto,
                correo2Contacto,
                telefono1Contacto,
                extContacto,
                telefono2Contacto,
                puestoContacto.toUpperCase(),
                tipoContacto2,
                ]}"){         
                        message
                } 
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };

    const deleteContacto = (id) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                    deliteContacto(data:"${[id]}"){
                    id_contacto
                    nombre
                    apellidos
                    correo1
                    correo2
                    telefono1
                    extensionTelefonica
                    telefono2
                    puesto 
                    tipoContacto 
                    fk_clientesads 
                    message
                    } 
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const enviarAcceso = (id, correo, contactoSeleccionado) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                    accesoSistema(data:"${[
                    id,
                    correo,
                    contactoSeleccionado,
                    ]}"){
                        message
                    } 
                } 
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const quitarAcceso = (id, fk_contactoAcceso) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                        mutation{
                        quitarAccesoSistema(data:"${[id, fk_contactoAcceso]}"){
                            message
                            } 
                        } 
                        `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const updateCliente = (
    id_cliente,
    domicilioFiscal,
    giroEmpresarial,
    razonSocial,
    RFC,
    tamanoEmpresa,
    tipoCliente,
    paginaWeb
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                    updateCliente(data:"${[
                    id_cliente,
                    domicilioFiscal,
                    giroEmpresarial,
                    razonSocial,
                    RFC,
                    tamanoEmpresa,
                    tipoCliente,
                    paginaWeb,
                    ]}"){  
                    message
                    } 
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const signupEmpresa = (
    rfc,
    razonSocial,
    correo,
    telefono,
    paginaWeb,
    domicilioFiscal
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                    signupEmpresas(data:"${[
                    rfc,
                    razonSocial,
                    correo,
                    telefono,
                    paginaWeb,
                    domicilioFiscal,
                    ]}"){
                    message
                    } 
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const insertClientesAlfa = (
    rfc,
    empresa,
    tipoEmpresa,
    tamañoEmpresa,
    giroEmpresarial,
    domicilioFiscal,
    paginaWeb,
    fk_empresa
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                insertClientesAlfa(data:"${[
                rfc,
                empresa,
                tipoEmpresa,
                tamañoEmpresa,
                giroEmpresarial,
                domicilioFiscal,
                paginaWeb,
                fk_empresa,
                ]}"){  
                    message
                    } 
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const insertProductoServicio = (
    tipo,
    concepto,
    precio,
    consecutivo,
    tipoLicenciamiento,
    lineaProducto,
    id_actualizacion,
    asignacion,
    fecha,
    fk_empresa
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                    insertProductoServicio(data:"${[
                    tipo,
                    concepto,
                    precio,
                    consecutivo,
                    tipoLicenciamiento,
                    lineaProducto,
                    id_actualizacion,
                    asignacion,
                    fecha,
                    fk_empresa,
                    ]}"){
                    message
                    } 
                }`,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const getAllTablaProductoServicio = (fk_empresa) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                query{
                    getAllTablaProductoServicio(data:"${[fk_empresa]}"){
                    id_productoServicio
                    tipo
                    tipoLicenciamiento
                    lineaProducto
                    concepto
                    precio 
                    consecutivo
                    id_actualizacion
                    asignacion
                    fechaRegistro
                    fk_empresa
                    }
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };
    const updateInsertProductoServicio = (
    tipo2,
    concepto2,
    precio2,
    consecutivo2,
    tipoLicenciamiento2,
    LineaProducto2,
    id_actualizacion2,
    asignacion2,
    fecha2,
    fk_empresa2
    ) => {
    return new Promise((resolve, reject) => {
        axios({
        url: API,
        method: "post",
        data: {
            query: `
                mutation{
                updateInsertProductoServicio(data:"${[
                tipo2,
                concepto2,
                precio2,
                consecutivo2,
                tipoLicenciamiento2,
                LineaProducto2,
                id_actualizacion2,
                asignacion2,
                fecha2,
                fk_empresa2,
                ]}"){
                    message
                    } 
                }
                `,
        },
        })
        .then((response) => {
            try {
            resolve(response);
            } catch {
            reject("error");
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
    };

    const insertCotizaciones = (
        fecha,folio,cantidad,descuento,descuentoAplicado,TotalPrecioProducto,idCliente,id_productoServicio,idAdminAlfa,idEmpresa,fechaExpiracion,id_contacto,tipoSolicitud
        ) => {
        return new Promise((resolve, reject) => {
            axios({
            url: API,
            method: "post",
            data: {
                query: `
                    mutation{
                        insertCotizaciones(data:"${[
                            fecha,folio,cantidad,descuento,descuentoAplicado,TotalPrecioProducto,idCliente,id_productoServicio,idAdminAlfa,idEmpresa,fechaExpiracion,id_contacto,tipoSolicitud
                        ]}"){
                            message
                        } 
                    }`,
            },
            })
            .then((response) => {
                try {
                resolve(response);
                } catch {
                reject("error");
                }
            })
            .catch((err) => {
                reject(err);
            });
        });
    };
    const insertTotales = (
        folio,subtotal,iva,total        
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                    insertTotales(data:"${[folio,subtotal,iva,total]}"){
                        message
                        } 
                    }
                    `
                }   
                }).then(response=>{
                    try {
                    resolve(response);
                    } catch {
                    reject("error");
                    }
                })
                .catch(err=>{
                    reject(err)
                })
        });
    };

    const sendEmailCotizacion = (
        param,args,names,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,id_url3,id_url4,id_url5,nombreArchivo1,nombreArchivo2,nombreArchivo3,nombreArchivo4,nombreArchivo5,url1,url2,url3,url4,url5,comentarios 
        ) => {
            console.log(        param,args,names,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,id_url3,id_url4,id_url5,nombreArchivo1,nombreArchivo2,nombreArchivo3,nombreArchivo4,nombreArchivo5,url1,url2,url3,url4,url5,comentarios 
                )
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                    sendEmailCotizacion(data:"${[param,args,names,correoAdmin,correoContacto,telefono,extension,folio,id_url1,id_url2,id_url3,id_url4,id_url5,nombreArchivo1,nombreArchivo2,nombreArchivo3,nombreArchivo4,nombreArchivo5,url1,url2,url3,url4,url5,comentarios]}"){   
                        message                             
                    } 
                    }
                `
                }   
                }).then(response=>{   
                    try {
                        resolve(response);
                        } catch {
                        reject("error");
                    }
                }).catch(err=>{                    
                    reject(err)
                })
        });
    };
    const insertUrlTemporal = (
        name,folio,url,comentarios        
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:  API,
                method:'post',
                data:{
                query:`
                mutation{
                insertUrlTemporal(data:"${[name,folio,url,comentarios]}"){
                message
                        }
                    }
                    `
                }
            })
            .then(datos => {	
                try {
                    resolve(datos);
                } catch {
                    reject("error");
                }
            }).catch(err=>{
                reject(err)
            })
        });
    };

    const getUrlPdfFile = (
        folio     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getUrlPdfFile(data:"${[folio]}"){   
                        id_url
                        nombreArchivo
                        folio
                        url
                        comentarios                             
                        } 
                    }
                    `
                }   
                }).then(response=>{
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const deleteFileTemporal = (
        folio     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                        deleteFileTemporal(data:"${[folio]}"){   
                        message                             
                        } 
                    }
                    `
                }   
                }).then(response=>{ 
                    resolve(response)
                })
        });
    };
    const getTablaProductoServicio = (
        fk_empresa     
        ) => {
        return new Promise((resolve, reject) => {
                axios({
                    url: API,
                    method: "post",
                    data: {
                    query: `
                        query{   
                            getTablaProductoServicio(data:"${[fk_empresa]}"){
                            id_productoServicio
                            tipo
                            tipoLicenciamiento
                            lineaProducto
                            concepto
                            precio
                            consecutivo
                            id_actualizacion
                            asignacion
                            fechaRegistro
                            fk_empresa
                            message
                            } 
                        }
                        `,
                    },
                }).then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getContactosId = (
        id_cliente     
        ) => {
        return new Promise((resolve, reject) => {
                axios({
                    url: API,
                    method: "post",
                    data: {
                    query: `
                        query{
                        getContactosId(data:"${[id_cliente]}"){
                            id_contacto
                            nombre
                            apellidos
                            correo1
                            correo2
                            telefono1
                            extensionTelefonica
                            telefono2
                            puesto 
                            fk_clientesads                               
                        } 
                        }
                        `,
                    },
                }).then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getClienteRFC = (
        rfc     
        ) => {
        return new Promise((resolve, reject) => {
                axios({
                    url: API,
                    method: "post",
                    data: {
                    query: `
                    query{
                        getClienteRFC(data:"${[rfc]}"){
                        id_cliente
                        razonSocial
                        rfc
                        telefono
                        correo
                        nombreRepresentante            
                        } 
                    }
                    `,
                    },
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };

    const getCotizacionesTabla = (
        fk_adminalfa     
        ) => {
        return new Promise((resolve, reject) => {
                axios({
                    url:API,
                    method:'post',
                    data:{
                        query:`
                        query{
                        getCotizacionesTabla(data:"${[fk_adminalfa]}"){
                            id_cotizaciones
                            fechaEmision
                            NumFolio               
                            cantidad
                            descuento
                            descuentoAplicado
                            TotalPrecioProducto
                            statusCotizacion 
                            fk_cliente
                            fk_contacto
                            fechaExpiracion                    
                        } 
                        }
                        `
                    }   
                 })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getContactoId = (
        rows     
        ) => {
        return new Promise((resolve, reject) => {
                axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getContactoId(data:"${[rows]}"){
                        id_contacto
                        nombre
                        apellidos
                        correo1
                        telefono1
                        puesto
                        tipoContacto
                        } 
                    }
                    `
                }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getClienteId = (
        rows     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                    getClienteId(data:"${[rows]}"){
                        id_cliente
                        razonSocial
                        rfc
                        } 
                    }
                    `
                }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getIdCotizacion = (
        id     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getIdCotizacion(data:"${[id]}"){   
                        id_cotizaciones
                        fechaEmision
                        NumFolio               
                        cantidad
                        descuento
                        descuentoAplicado
                        TotalPrecioProducto
                        statusCotizacion                
                        empresa
                        rfc
                        fk_cliente
                        tipo
                        concepto
                        message
                        fk_contacto
                        fechaExpiracion
                        } 
                    }
                    `
                    }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const updateStatusCotizacion = (
        folio,status     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    mutation{
                        updateStatusCotizacion(data:"${[folio,status]}"){   
                        message
                        } 
                    }
                    `
                }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getProductoServicioByFolio = (
        folio     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getProductoServicioByFolio(data:"${[folio]}"){   
                        id_productoServicio
                        tipo
                        concepto
                        precio
                        lineaProducto
                        } 
                    }
                    `
                }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getTotalesByFolio = (
        folio     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getTotalesByFolio(data:"${[folio]}"){   
                        subtotal
                        iva
                        total
                        message
                        } 
                    }
                    `
                }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    const getCotizacionFk_Contactos = (
        id_contacto     
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getCotizacionFk_Contactos(data:"${[id_contacto]}"){   
                        id_contacto
                        nombre
                        apellidos
                        correo1
                        correo2
                        telefono1
                        extensionTelefonica
                        telefono2
                        puesto
                        fk_clientesads
                        } 
                    }
                    `
                }   
            })
        });
    };
    const getIdClientesAlfa
    = ( param
        ) => {
        return new Promise((resolve, reject) => {
            axios({
                url:API,
                method:'post',
                data:{
                    query:`
                    query{
                        getIdClientesAlfa(data:"${[param]}"){   
                            id_cliente
                            rfc
                            razonSocial
                        } 
                    }
                    `
                }   
                })
                .then(response=>{ 
                    try {
                        resolve(response);
                    } catch {
                        reject("error");
                    }
                }).catch(err=>{
                    reject(err)
                })
        });
    };
    export default {
    getIdClientesAlfa,
    getCotizacionFk_Contactos,
    getTotalesByFolio,
    getProductoServicioByFolio,
    updateStatusCotizacion,
    getIdCotizacion,
    getClienteId,
    getContactoId,
    getCotizacionesTabla,
    getClienteRFC,
    getContactosId,
    getTablaProductoServicio,
    deleteFileTemporal,
    getUrlPdfFile,
    insertUrlTemporal,
    sendEmailCotizacion,
    insertTotales,
    insertCotizaciones,
    updateInsertProductoServicio,
    getAllTablaProductoServicio,
    insertProductoServicio,
    insertClientesAlfa,
    signupEmpresa,
    updateCliente,
    quitarAcceso,
    enviarAcceso,
    deleteContacto,
    updateContacto,
    getTablaClientesAlfa,
    getContacto,
    insertContacto,
    };
