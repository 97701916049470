import React, { Component } from 'react';
import Navbar from './Navbares';
import './NewTable.css';

class NewsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNews: null,
      news: [
        {
          id: 1,
          title: "La Revolución de la Inteligencia Artificial",
          date: "2024-06-01",
          summary: "La inteligencia artificial está cambiando el mundo a un ritmo acelerado...",
          content: "La inteligencia artificial (IA) se ha convertido en una fuerza disruptiva en una amplia gama de industrias...",
          imageUrl: "https://via.placeholder.com/800x500",
          videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ",
          details: "La inteligencia artificial está transformando sectores como la salud, manufactura y más. Este artículo explora cómo la IA está revolucionando nuestras vidas."
        },
        {
          id: 2,
          title: "Avances en la Exploración Espacial",
          date: "2024-06-10",
          summary: "Nuevas misiones espaciales están llevando a la humanidad más lejos que nunca...",
          content: "La exploración espacial ha avanzado significativamente en los últimos años...",
          imageUrl: "https://via.placeholder.com/800x500",
          videoUrl: null,
          details: "Los avances en la exploración espacial están permitiendo descubrimientos emocionantes sobre planetas y lunas. Este artículo profundiza en los logros recientes y futuros en la exploración del espacio."
        },
        {
          id: 3,
          title: "Sostenibilidad y el Futuro de la Energía",
          date: "2024-06-15",
          summary: "La transición hacia fuentes de energía sostenibles es crucial para el futuro del planeta...",
          content: "La sostenibilidad se ha convertido en un tema central en la conversación sobre el futuro de la energía...",
          imageUrl: "https://via.placeholder.com/800x500",
          videoUrl: null,
          details: "La transición hacia la energía sostenible es esencial para mitigar el cambio climático. Este artículo discute las tecnologías y políticas clave en la promoción de un futuro energético más limpio."
        }
      ],
      currentSlide: 0,
      intervalId: null
    };
  }

  componentDidMount() {
    // Iniciar el carrusel automáticamente
    const intervalId = setInterval(this.nextSlide, 5000); // Cambiar cada 5 segundos
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    // Limpiar el intervalo cuando el componente se desmonta
    clearInterval(this.state.intervalId);
  }

  selectNews = (id) => {
    this.setState({ selectedNews: id });
  };

  closeModal = () => {
    this.setState({ selectedNews: null });
  };

  nextSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide + 1) % this.state.news.length
    }));
  };

  render() {
    const { selectedNews, news, currentSlide } = this.state;
    return (
      <div>
        <Navbar />
        <div className="news-container">
          <h1 className="news-title">Noticias del Mes</h1>
          <div className={`news-table-container ${selectedNews !== null ? 'collapsed' : ''}`}>
            <table className="news-table">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Título</th>
                  <th>Resumen</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {news.map((item) => (
                  <tr key={item.id}>
                    <td>{item.date}</td>
                    <td>{item.title}</td>
                    <td>{item.summary}</td>
                    <td>
                      <button className="view-button" onClick={() => this.selectNews(item.id)}>
                        Ver Detalles
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {selectedNews !== null && (
            <div className="news-modal active" onClick={this.closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={this.closeModal}>
                  X
                </button>
                <h2 className="modal-title">{news[selectedNews - 1].title}</h2>
                <img src={news[selectedNews - 1].imageUrl} alt={news[selectedNews - 1].title} className="modal-image" />
                <p className="modal-date">Fecha: {news[selectedNews - 1].date}</p>
                <p>{news[selectedNews - 1].content}</p>
                {news[selectedNews - 1].videoUrl && (
                  <div className="video-container">
                    <iframe
                      title="Video de la Noticia"
                      width="100%"
                      height="315"
                      src={news[selectedNews - 1].videoUrl}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                <p className="modal-details">{news[selectedNews - 1].details}</p>
              </div>
            </div>
          )}
          <div className="news-carousel">
            {news.map((item, index) => (
              <div key={item.id} className={`news-slide ${index === currentSlide ? 'active' : ''}`}>
                <img src={item.imageUrl} alt={item.title} className="news-image" />
                <div className="news-info">
                  <h3 className="news-slide-title">{item.title}</h3>
                  <p className="news-slide-summary">{item.summary}</p>
                  <button className="view-button" onClick={() => this.selectNews(item.id)}>
                    Ver Detalles
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default NewsTable;
