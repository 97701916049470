import React, { Component } from "react";
import { Button, Modal } from "antd";
import { Card } from "antd";
import MUIDataTable from "mui-datatables";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import "./index.css";
import swal from "sweetalert";
import actions from "./transactionsAPI";
import options from "./tableMuiOptions";
import ReportActualizacion from "./reportProductoServicio/actualizacion";

class listadoProductosYServicios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      asignacion2: " ",
      concepto2: " ",
      consecutivo2: "",
      fechaRegistro2: " ",
      fk_empresa2: " ",
      id_actualizacion2: " ",
      id_productoServicio2: " ",
      lineaProducto2: " ",
      precio2: " ",
      tipo2: " ",
      tipoLicenciamiento2: "",
      asignacion: "",
      agrupacion: [],
      arrayIndice: [],
      listactualizacionesProductos: [],
      actualizacion: [],
      tablaActualizacion: false,
      tablaInicial: true,
      ConsultarListaProducto: true,
      productoServicioActualizado: [],
      productoActualizado: [],
      listaTotalProductosActualizados: false,
      indexListado:''
    };
    this.toggle = this.toggle.bind(this);
    this.onChangeInput2 = this.onChangeInput2.bind(this);
    this.mostrarReporte = this.mostrarReporte.bind(this);
  }
  componentWillMount() {
    let fk_empresa = localStorage.getItem("fk_empresa");
    actions
      .getAllTablaProductoServicio(fk_empresa)
      .then((response) => {
        let data = response.data.data.getAllTablaProductoServicio;
        let dataArray = [];
        data.map((rows) => {
          if (rows.asignacion && rows.id_actualizacion) {
            dataArray.push(rows);
          }
        });
        let filtered = dataArray.reduce((acc, item) => {
          if (
            !acc.some((e, i) => {
              if (item.asignacion === e.asignacion) {
                if (item.id_actualizacion > e.id_actualizacion) {
                  acc.splice(i, 1, item);
                }
                return true;
              }
            })
          ) {
            acc.push(item);
          }
          return acc;
        }, []);
        // console.log("filtered",filtered)
        this.setState({ productoActualizado: filtered });

        const list = dataArray
          .sort((a, b) => a.id_actualizacion - b.id_actualizacion)
          .map((exemple, index, array) => exemple);
        this.setState({ listactualizacionesProductos: list });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  toggle = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };
  onChangeInput2 = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  editarContacto(id) {
    this.setState({ datosContacto: id });
    this.setState({
      asignacion2: id.asignacion,
      concepto2: id.concepto,
      consecutivo2: id.consecutivo,
      fechaRegistro2: id.fechaRegistro,
      fk_empresa2: id.fk_empresa,
      id_actualizacion2: id.id_actualizacion,
      id_productoServicio2: id.id_productoServicio,
      lineaProducto2: id.lineaProducto,
      precio2: id.precio,
      tipo2: id.tipo,
      tipoLicenciamiento2: id.tipoLicenciamiento,
    });
    this.setState({
      modal: !this.state.modal,
    });
  }
  cerrar() {
    this.setState({ tablaInicial: true });
    this.setState({ tablaActualizacion: false });
    this.setState({ ConsultarListaProducto: false });
    this.setState({ listaTotalProductosActualizados: false });
    // this.setState({modal:true})
  }
  onSubmitBtn2 = (e) => {
    e.preventDefault();
    let tipo2 = this.state.tipo2;
    let concepto2 = this.state.concepto2;
    let precio2 = this.state.precio2;
    let consecutivo2 = this.state.consecutivo2;
    let tipoLicenciamiento2 = this.state.tipoLicenciamiento2;
    let LineaProducto2 = this.state.lineaProducto2;
    let fk_empresa2 = localStorage.getItem("fk_empresa");
    let convertirActualizacion = parseInt(this.state.id_actualizacion2) + 1;
    let id_actualizacion2 = convertirActualizacion;
    // let id_productoServicio2 = this.state.id_productoServicio2
    let asignacion2 = this.state.asignacion2;
    let fecha2 = new Date();
    // if( tipo2 && concepto2 && precio2 && consecutivo2 ){
    actions
      .updateInsertProductoServicio(
        tipo2,
        concepto2,
        precio2,
        consecutivo2,
        tipoLicenciamiento2,
        LineaProducto2,
        id_actualizacion2,
        asignacion2,
        fecha2,
        fk_empresa2
      )
      .then((response) => {
        if (
          response.data.data.updateInsertProductoServicio.message ===
          "registro exitoso"
        ) {
          swal({
            text: "Producto actualizado",
            buttons: false,
            icon: "success",
          });
          this.setState({ modal: false }); // this.setState({ tipo2:" ",concepto:" ", precio:" ", claveProducto:" " })
          this.toggle();
        } else {
          swal({
            text: "Algo salio mal,intentelo nuevamente",
            buttons: false,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  consultarDatos = async () => {
    this.setState({indexListado:1})
    let arrayIndice = [];
    let list = this.state.listactualizacionesProductos;
    list.map((rows) => {
      arrayIndice.push({ id_actualizacion: rows.id_actualizacion });
    });
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }
    const arr1 = getUniqueListBy(arrayIndice, "id_actualizacion");
    this.setState({ arrayIndice: arr1 });
  };

  mostrarActualizacion = async (param) => {
    await this.setState({ tablaActualizacion: false });
    let actualizacion = this.state.listactualizacionesProductos.filter(
      function (rows) {
        return rows.id_actualizacion === param;
      }
    );
    this.setState({ actualizacion: actualizacion });
    this.setState({ tablaActualizacion: true });
    this.setState({ tablaInicial: false });
    this.setState({ ConsultarListaProducto: false });
  };
  mostrarReporte = () => {
    const list = this.state.listactualizacionesProductos;
    this.setState({ tablaActualizacion: false });
    this.setState({ tablaInicial: false });
    this.setState({ ConsultarListaProducto: false });
    this.setState({ listaTotalProductosActualizados: true });
  };
  cancelarListado (){
    this.setState({arrayIndice:[]})
    this.setState({indexListado:0})
  }

  render() {
    let fecha = new Date();
    var meses = new Array(
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    );
    let dia = fecha.getDate();
    let mesSubstringF = fecha.getMonth() + 1;
    let posicionMesF = parseInt(mesSubstringF, 10);
    let mes = meses[posicionMesF - 1];
    let año = fecha.getFullYear();
    let fechaA = dia + " de " + mes + " del " + año;
    let razonSocialEmpresa = localStorage.getItem("razonSocialEmpresa");
    let numActualizacion;
    this.state.actualizacion.map((rows) => {
      numActualizacion = rows.id_actualizacion;
    });
    let productoActualizadoLista = this.state.productoActualizado;
    let modal = (
      <div>
        <Modal
          title={
            <h4 className="h4tag">Editar datos de productos y servicios</h4>
          }
          open={this.state.modal}
          onCancel={this.toggle}
          onOk={this.toggle}
          size="lg"
        >
          <div className="container22">
            <form onSubmit={this.onSubmitBtn2}>
              <div class="row">
                Nota: El unico campo editable corresponde al precio del
                producto.
                <div class="input-group input-group-icon">
                  <input
                    id="claveProducto2"
                    type="text"
                    name="claveProducto2"
                    onChange={this.onChangeInput2}
                    value={this.state.consecutivo2}
                    className="textoInput"
                    disabled
                  />
                  <div class="input-icon">
                    <i class="fa fa-grip-lines"></i>
                  </div>
                </div>
                <div class="input-group input-group-icon">
                  <input
                    type="text"
                    name="tipo2"
                    id="tipo2"
                    onChange={this.onChangeInput2}
                    value={this.state.tipo2}
                    className="textoInput"
                    disabled
                  />
                  <div class="input-icon">
                    <i class="fa fa-grip-lines"></i>
                  </div>
                </div>
                <div class="input-group input-group-icon">
                  <input
                    type="text"
                    name="LineaProducto2"
                    id="LineaProducto2"
                    onChange={this.onChangeInput2}
                    value={this.state.lineaProducto2}
                    className="textoInput"
                    disabled
                  />
                  <div class="input-icon">
                    <i class="fa fa-grip-lines"></i>
                  </div>
                </div>
                <div class="input-group input-group-icon">
                  <input
                    id="precio2"
                    type="number"
                    name="precio2"
                    onChange={this.onChangeInput2}
                    value={this.state.precio2}
                  />
                  <div class="input-icon">$</div>
                </div>
                <div class="input-group input-group-icon">
                  <input
                    id="actualizacion2"
                    type="number"
                    name="actualizacion2"
                    onChange={this.onChangeInput2}
                    value={this.state.id_actualizacion2}
                    disabled
                  />
                  <div class="input-icon">
                    <i class="fa fa-grip-lines"></i>
                  </div>
                </div>
                <div class="input-group input-group-icon">
                  <input
                    size="sm"
                    id="productoServicio2"
                    type="number"
                    name="productoServicio2"
                    onChange={this.onChangeInput2}
                    value={this.state.asignacion2}
                    disabled
                  />
                  <div class="input-icon">
                    <i class="fa fa-grip-lines"></i>
                  </div>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon">
                      <i className="fas fa-pencil-alt prefix "></i>
                    </span>
                  </div>
                  <textarea
                    className="form-control"
                    rows="5"
                    id="concepto2"
                    type="text"
                    name="concepto2"
                    onChange={this.onChangeInput2}
                    value={this.state.concepto2}
                    disabled
                  ></textarea>
                </div>
                <div class="input-group input-group-icon">
                  <button class="button2" onClick={(e) => this.onSubmitBtn2(e)}>
                    <span class="text">Guardar</span>
                    <span class="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                      </svg>
                    </span>
                  </button>
                </div>
                <button onClick={(e) => this.toggle(e)} class="button">
                  <span class="text">Cancelar</span>
                  <span class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
    let ListaProductosYServicios;
    let data;
    const columns2 = [
      "Id",
      "Tipo",
      "Línea",
      "Concepto",
      "Precio",
      "Consecutivo",
      "Actualización",
      "Editar",
    ];
    if (this.state.tablaInicial === true) {
      data = productoActualizadoLista.map((rows) => {
        let id_actualizacion = (
          <font>
            <strong>{rows.id_actualizacion}</strong>
          </font>
        );
        let botonEditar = (
          <div>
            <div class="input-group input-group-icon">
              <button
                class="button23"
                onClick={(e) => this.editarContacto(rows)}
              >
                <label style={{ marginTop: "10%" }} className="text-white">
                  Editar
                </label>
                <span class="icon">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4001 18.1612L11.4001 18.1612L18.796 10.7653C17.7894 10.3464 16.5972 9.6582 15.4697 8.53068C14.342 7.40298 13.6537 6.21058 13.2348 5.2039L5.83882 12.5999L5.83879 12.5999C5.26166 13.1771 4.97307 13.4657 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.05445 20.6042C1.92743 20.9852 2.0266 21.4053 2.31063 21.6894C2.59466 21.9734 3.01478 22.0726 3.39584 21.9456L7.47918 20.5844C8.25351 20.3263 8.6407 20.1973 9.00498 20.0237C9.43469 19.8189 9.84082 19.5679 10.2162 19.2751C10.5343 19.0269 10.823 18.7383 11.4001 18.1612Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M20.8482 8.71306C22.3839 7.17735 22.3839 4.68748 20.8482 3.15178C19.3125 1.61607 16.8226 1.61607 15.2869 3.15178L14.3999 4.03882C14.4121 4.0755 14.4246 4.11268 14.4377 4.15035C14.7628 5.0875 15.3763 6.31601 16.5303 7.47002C17.6843 8.62403 18.9128 9.23749 19.85 9.56262C19.8875 9.57563 19.9245 9.58817 19.961 9.60026L20.8482 8.71306Z"
                      fill="#1C274C"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        );
        return [
          rows.id_productoServicio,
          rows.tipo,
          rows.lineaProducto,
          rows.concepto,
          rows.precio,
          rows.consecutivo,
          id_actualizacion,
          botonEditar,
        ];
      });
      let tituloTabla = (
        <h4>Listado de productos y servicios ultima actualización</h4>
      );
      let consultarListado = (
        <Button type="primary" onClick={(e) => this.consultarDatos()} size="sm">
          Ver por actualización
        </Button>
      );
      let cancelarListado;
      if(this.state.indexListado === 1){
        cancelarListado =  (
        <button
          style={{ width: "170px", height: "27px", margin: "2px" }}
          onClick={(e) => this.cancelarListado()}
          class="button"
        >
          <span class="text">Cancelar</span>
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
            </svg>
          </span>
        </button>
        )
      }
      let botonDescargar = (
        <Button
          type="success"
          className="ant-btn-primary"
          onClick={(e) => this.mostrarReporte()}
          size="sm"
        >
          Ver registro de productos
        </Button>
      );

      ListaProductosYServicios = (
        <center>
          <Card style={{ width: "85%" }}>
            <Card
              type="inner"
              title="Consultar Actualizaciones de Productos"
              extra={
                <div>
                  {consultarListado}&nbsp;{botonDescargar}&nbsp;
                </div>
              }
            >
              <div className="buttonIndice">
                {this.state.arrayIndice.map((rows) => {
                  return (
                    <div>
                      <Button
                        onClick={(e) =>
                          this.mostrarActualizacion(rows.id_actualizacion)
                        }
                        type="link"
                      >
                        <strong>{rows.id_actualizacion}° Actualización</strong>
                      </Button>
                    </div>
                  );
                })}
                {cancelarListado}
              </div>
            </Card>
            <MUIDataTable
              title={tituloTabla}
              data={data}
              columns={columns2}
              options={options}
            />
          </Card>
        </center>
      );
    }

    let ListaActualizada;
    let actualizacion;
    let lista;
    const columns3 = [
      "Tipo",
      "Linea de Producto",
      "Concepto",
      "Consecutivo",
      "precios",
      "Actualización",
    ];
    if (this.state.tablaActualizacion === true) {
      console.log("entro");
      let botonCerrar2 = (
        <button
          style={{ width: "170px", height: "34px", margin: "2px" }}
          onClick={(e) => this.cerrar()}
          class="button"
        >
          <span class="text">Cancelar</span>
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
            </svg>
          </span>
        </button>
      );

      lista = this.state.actualizacion.map((rows) => {
        actualizacion = rows.id_actualizacion;
        const fechaF = new Date(rows.fechaRegistro);
        var mesesF = new Array(
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        );
        let diaF = fechaF.getDate();
        let mesSubstringF = fechaF.getMonth() + 1;
        let posicionMesF = parseInt(mesSubstringF, 10);
        let mesF = mesesF[posicionMesF - 1];
        let añoF = fechaF.getFullYear();
        let fechaRegistro = diaF + "/" + mesF + "/" + añoF;
        let precioDosCeros = rows.precio.toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        });
        let precio = precioDosCeros
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        return [
          rows.tipo,
          rows.lineaProducto,
          rows.concepto,
          rows.consecutivo,
          "$" + precio,
          rows.id_actualizacion,
        ];
      });
      let botonDescargraIndividual2 = (
        <ReportActualizacion.ReportActualizacion
          numActualizacion={numActualizacion}
          fechaA={fechaA}
          razonSocialEmpresa={razonSocialEmpresa}
          actualizacion={this.state.actualizacion}
          numReport={1}
        />
      );
      let titulo = (
        <h4 className="h4tag">
          {actualizacion + "° Actualización de productos y servicios"}
        </h4>
      );
      ListaActualizada = (
        <div>
          <Card
            title={titulo}
            style={{ width: "85%" }}
            extra={
              <div className="rows">
                {botonCerrar2}
                {botonDescargraIndividual2}
              </div>
            }
          >
            <MUIDataTable data={lista} columns={columns3} options={options} />
          </Card>
        </div>
      );
    }
    // *******************Lista de productos totales actualizados***************************
    let tablaLista;
    let listaTotalActualizada;
    const columns5 = [
      "Tipo",
      "Linea de Producto",
      "Concepto",
      "Consecutivo",
      "precios",
      "Núm. de actualización",
      "Asignación",
    ];
    if (this.state.listaTotalProductosActualizados === true) {
      let botonCerrar = (
        <button
          style={{ width: "170px", height: "34px", margin: "2px" }}
          onClick={(e) => this.cerrar()}
          class="button"
        >
          <span class="text">Cancelar</span>
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
            </svg>
          </span>
        </button>
      );

      let botonDescargraIndividual = (
        <ReportActualizacion.ReportLista
          listactualizacionesProductos={this.state.listactualizacionesProductos}
          numActualizacion={numActualizacion}
          fechaA={fechaA}
          razonSocialEmpresa={razonSocialEmpresa}
          numReport={2}
        />
      );

      listaTotalActualizada = this.state.listactualizacionesProductos.map(
        (rows) => {
          const fechaF = new Date(rows.fechaRegistro);
          var mesesF = new Array(
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          );
          let diaF = fechaF.getDate();
          let mesSubstringF = fechaF.getMonth() + 1;
          let posicionMesF = parseInt(mesSubstringF, 10);
          let mesF = mesesF[posicionMesF - 1];
          let añoF = fechaF.getFullYear();
          let fechaRegistro = diaF + "/" + mesF + "/" + añoF;
          let precioDosCeros = rows.precio.toLocaleString("en", {
            useGrouping: false,
            minimumFractionDigits: 2,
          });
          let precio = precioDosCeros
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          return [
            rows.tipo,
            rows.lineaProducto,
            rows.concepto,
            rows.consecutivo,
            "$" + precio,
            rows.id_actualizacion,
            rows.asignacion,
          ];
        }
      );

      let titulo = <h5>Lista total de productos y servicios actualizada</h5>;
      tablaLista = (
        <center>
          <div className="muidatatable" style={{ width: "85% " }}>
            <Card
              title={titulo}
              extra={
                <div className="rows">
                  {botonCerrar}
                  {botonDescargraIndividual}
                </div>
              }
            >
              <MUIDataTable
                data={listaTotalActualizada}
                columns={columns5}
                options={options}
              />
            </Card>
          </div>
        </center>
      );
    }
    return (
      <div>
        <center>
          {modal}
          {ListaProductosYServicios}
          {ListaActualizada}
          {tablaLista}
        </center>
      </div>
    );
  }
}
export default listadoProductosYServicios;
