import React, { useState } from "react";
import { storage } from "../administradorAlfa/firebase";
import {MDBBtn} from 'mdbreact'
import {Card,Button} from 'antd'
import 'antd/dist/antd.css'
import 'antd-button-color/dist/css/style.css'

const ReactFirebaseFileUpload = () => {
    const [image, setImage] = useState(null);
    const [imageValidation, setImageValidation] = useState(false);

    const [url, setUrl] = useState("");
    const [progress, setProgress] = useState(0);
    const [cargando,setCargando]= useState("");
    const [mensaje,setMensaje] = useState("")
    const [mensaje2,setMensaje2] = useState("")
    const [disabledButton,setDisabledButton] = useState("disabled")


    const handleChange = e => {
      if (e.target.files[0]) {
        setImage(e.target.files[0]);
        setImageValidation(true)
      }
    };

    const handleclear = e => {
          setImage('');
          document.getElementById("uploadCaptureInputFile").value = "";
      };
  
    var name;
    if(image){
        name = image.name
    }else{
    name= "Imagen no adjuntada"
    }
    const handleUpload = () => {
      setCargando("Cargando por favor espere ....")
   
        name = image.name
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
        console.log("error" , error);
        },
        () => {
        storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
            if(url){
                setCargando("")
                                
                setUrl(url);
                localStorage.setItem("url_image",url)
                setMensaje(`Imagen disponible`); 
                setImageValidation(false)
                }
            });
        }
      );
    };
    let imagen;
    if(url){
        imagen = <img src={url} width={150}/>
    }
  
    return (
      <div>
        <Card type="inner">
        <div>
        <progress style={{marginTop:"5%",width:"14em"}} value={progress} max="100" /><br/><br/>
         <div class="file-upload">
            <div class="file-select">
                <div class="file-select-button" id="fileName">Cargar imagen</div>
                <div class="file-select-name" id="noFile">{name}  </div> 
                <input type="file" name="chooseFile" id="chooseFile" accept="image/x-png,image/gif,image/jpeg/,image/png"  class="btn btn-info btn-file inputs" onChange={handleChange}/>
            </div>
            </div>
        </div>
        {cargando}  {mensaje}
        <center>
          <Button style={{marginTop:"5%",width:"14em"}} disabled={!imageValidation} size="sm" type="primary" onClick={handleUpload}>Cargar</Button>&nbsp;&nbsp;&nbsp;
          <br/>
          <br/>
          {mensaje2}
        </center>
        {imagen}
        </Card>
      </div>
    );
  };
  
  export default ReactFirebaseFileUpload