import React, { Component } from 'react'
import actionsAPI from './actionsAPI';
import options from '../administradorAlfa/tableMuiOptions';
import MUIDataTable from "mui-datatables";
import {Card,Button} from 'antd'
class Cursos_Anteriores extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            cursos_anteriores:[]
         }

    }

    componentWillMount(){
        actionsAPI.cursos_Anteriores().then(response => {
            this.setState({cursos_anteriores:response.data.data.cursos_Anteriores})
        })
    }
    render() { 
        const columns = ["Sistema","Descripción","Estatus","Fecha de publicación", "Administrar"]
        const data = this.state.cursos_anteriores.map(rows=>{
            return([rows.concepto,rows.descripcion,"Curso finalizado", rows.fecha_curso + " " + rows.hora_inicial + " " + rows.hora_final, <Button style={{backgroundColor:"rgb(24,46,88)"}}className='text-white'><i class="fa fa-bars"></i></Button>])
        })
        return ( 
            <div>
                <div  style={{width:"95%",marginTop:"1%",marginBottom:"2%"}} >
                <Card title ={ <h6 className=''>Cursos que ya no están activos</h6> }>               
                <MUIDataTable  
                    data={data} 
                    columns={columns} 
                    options={options} 
                />  
                </Card>
                </div>
            </div>
         );
    }
}
 
export default Cursos_Anteriores;