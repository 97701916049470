
import React, { Component } from "react";
import swal from 'sweetalert';
import actions from './transactionsAPI';
class RegistrarProductoServicio extends Component{
    constructor(props){
        super(props)
        this.state ={   
          tipo:"",
          concepto:"",
          precio:"",
          claveProducto:"",  
          tipoLicenciamiento:"",  
          LineaProducto:"",
        }   
        this.handleChangeTipoPS = this.handleChangeTipoPS.bind(this) 
        this.handleChangelineaPS = this.handleChangelineaPS.bind(this)
        this.handleChangeLicenciamientoPS = this.handleChangeLicenciamientoPS.bind(this)              
    }
    
    handleChangeTipoPS(e){
      if(e.target.value){
        this.setState({tipo:e.target.value})
      }
    }

    handleChangelineaPS(e){
      if(e.target.value ){
        this.setState({LineaProducto:e.target.value})
      }
    }
    handleChangeLicenciamientoPS(e){
      if(e.target.value){
        this.setState({tipoLicenciamiento:e.target.value})
      }
    }
  
    onChangeInput = (e) => {
      const { id, value } = e.target; 
      this.setState({
        [id]: value
      });
    };
    cerrar(){
      window.location.reload();
    }
    onSubmitBtn = (e) => {    
      e.preventDefault();
      let tipo = this.state.tipo
      let concepto = this.state.concepto.toUpperCase();
      let precio = this.state.precio
      let consecutivo = this.state.claveProducto.toUpperCase()
      let tipoLicenciamiento = this.state.tipoLicenciamiento
      let LineaProducto = this.state.LineaProducto
      let fk_empresa= localStorage.getItem("fk_empresa")
      let fecha = new Date()
      let id_actualizacion = "1";
      let asignacion = "";
      if(  concepto && precio && consecutivo && LineaProducto && tipoLicenciamiento && tipo){
        actions.insertProductoServicio(tipo,concepto,precio,consecutivo,tipoLicenciamiento,LineaProducto,id_actualizacion,asignacion,fecha,fk_empresa)
        .then(response=>{
          if(response.data.data.insertProductoServicio.message === "registro exitoso"){              
            swal({
              text:"Registro exitoso!",
              title:"",
              icon:"success"
              }); 
            this.setState({ concepto:" ", precio:" ", claveProducto:" " }) 
          }else if(response.data.data.insertProductoServicio.message === "clave existente"){
            swal({
              text:"El consecutivo ya fue registrado",               
              icon:"error",
              }); 
          }else{              
            swal({
              text:"El concepto ya fue registrado.",               
              icon:"error",
              }); 
          }             
        }).catch(err=>{
            console.log(err)
        })
      }else{
        swal({
          title:"Por favor complete los campos",
          text:"Concepto, Precio, Clave de producto, Tipo de Servicio",
          icon:"warning"
          }); 
      }       
    };
    render(){
      let formulario;
      let titulo = <h5>Registro de productos o servicios</h5>
      formulario =    <div className="container23">
          <div className="column">
            <img
              className="container26"
              src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            />
          </div>
          <div className="column" style={{marginTop:"10%"}}>
            <div className="container24">
                <h4>{titulo}</h4>
            </div>
            <div class="container22">
              <form onSubmit={this.onSubmitBtn}>
                <div class="row">
                  <div class="input-group input-group-icon">
                      <textarea className="form-control" rows="5"
                              placeholder="Concepto"
                              id="concepto"
                              type="text"
                              name="concepto"
                              onChange={this.onChangeInput}
                              value={this.state.concepto}
                              required
                              >
                      </textarea>
                  </div>
                  <div class="input-group input-group-icon">
                    <input
                      placeholder="Precio"                                  
                      id="precio"
                      type="number"
                      name="precio"
                      onChange={this.onChangeInput}
                      value={this.state.precio}
                    />
                    <div class="input-icon">
                    <i class="fa fa-dollar-sign"></i>                    </div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input
                      placeholder="Clave del producto"                                  
                      id="claveProducto"
                      type="text"
                      name="claveProducto"
                      onChange={this.onChangeInput}
                      value={this.state.claveProducto}
                    />
                    <div class="input-icon">
                    <i class="fa fa-barcode"></i>                    </div>
                  </div>
                  <div class="col-half">
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="tipoServicio"
                        id="tipoServicio"
                        onChange={this.handleChangeTipoPS}
                        defaultValue="Seleccione el tipo de Servicio"
                      >
                        <option value="1">Tipo de servicio</option>
                        <option value="Servicio">Servicio</option>
                        <option value="Producto">Producto</option>
                        <option value="Alianza">Alianza</option>
                        <option value="Capacitación">Capacitación</option>     
                        <option value="Otro">Otro</option>    
                      </select>
                    </div>
                  </div>
                  <div class="col-half">
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="lineaProducto"
                        id="lineaProducto"
                        onChange={this.handleChangelineaPS}
                      >
                        <option value="2">Línea del Producto</option>
                        <option value="CONTABILIDAD">Contabilidad</option>
                        <option value="NÓMINAS">Nóminas</option>
                        <option value="BANCOS">Bancos</option>   
                        <option value="COMERCIAL">Comercial</option>                            
                        <option value="XML EN LÍNEA">XML en línea</option>  
                        <option value="Otro">Otro</option>         
                      </select>
                    </div>
                  </div>
                  <div class="col-half">
                    <div class="input-group">
                      <select
                        className="browser-default custom-select"
                        type="select"
                        name="tipoLicenciamiento"
                        id="tipoLicenciamiento"
                        onChange={this.handleChangeLicenciamientoPS}
                      >
                        <option value="3">Tipo de licenciamiento</option>
                        <option value="lICENCIAMIENTO ANUAL">licenciamiento anual</option>
                        <option value="lICENCIAMIENTO TRADICIONAL">licenciamiento tradicional</option>
                        <option value="NO APLICA">NO APLICA</option>         
                      </select>
                    </div>
                  </div>
                  <div class="input-group input-group-icon">
                    <button
                      class="button2"
                      onClick={(e) => this.onSubmitBtn(e)}
                    >
                      <span class="text">Agregar</span>
                      <span class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <button onClick={(e) => this.cerrar()} class="button">
                    <span class="text">Cancelar</span>
                    <span class="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>   
        return(
          <div>
            <center>
            {formulario}
            </center>
          </div>
        )
    }
}export default RegistrarProductoServicio;
